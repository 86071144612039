import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material-UI Core Components
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

//Material UI Icons
import AddIcon from "@material-ui/icons/Add";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EditIcon from "@material-ui/icons/Edit";
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PersonIcon from '@material-ui/icons/Person';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';


// Custom Components
import TableSearchBar from "UI/Table/TableSearchBar";
import TableHeaderButton from "UI/Table/TableHeaderButton";
import TableHeaderButtonExport from "UI/Table/HeaderButtons/TableHeaderButtonExport";
import TableHeaderButtonFilter from "UI/Table/HeaderButtons/TableHeaderButtonFilter";

import TableHeading from "UI/Table/TableHeading";
import ToolbarFillContent from "UI/Toolbar/ToolbarFillContent";
import { exportTableToExcel } from "Lib/exportExcel";

import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

function KomitentiList(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { records, title, allowAdd, handleAdd, handleEdit, addLabel, allowExport, dc, tableName } = props;
  const [filteredRecords, setFilteredRecords] = useState(records);
  const [globalFilter, setGlobalFilter] = useState(null);

  useEffect(() => {
    console.log(globalFilter)
    const fRecords = records.filter(r => {
      return globalFilter && globalFilter.length ?
        Object.keys(r).some(k => {
          return r[k] ? (r[k] + "").toUpperCase().indexOf(globalFilter.toUpperCase()) >= 0 : r[k];
        }) : true;
    })
    console.log(fRecords)
    setFilteredRecords(fRecords);
  }, [globalFilter]);

  const handleFilterChange = (a, b, c) => {
    console.log(a, b, c);
    setGlobalFilter(a !== null && a !== undefined ? (a + "") : "");
  }

  return (
    <Fragment>
      <Toolbar variant="regular" disableGutters={true}>
        {title ? <TableHeading>{title}</TableHeading> : null}
        <TableSearchBar
          count={filteredRecords ? filteredRecords.length : 0}
          value={globalFilter}
          onChange={handleFilterChange}
        />
        <ToolbarFillContent />
        { allowExport ?
            <TableHeaderButtonExport dc={dc} rows={filteredRecords} records={records} tableName={tableName} />
          : null }
        { allowAdd ? (
          <TableHeaderButton onClick={handleAdd} startIcon={<AddIcon />} variant="contained">
            {addLabel}
          </TableHeaderButton>
        ) : null}
      </Toolbar>
      <GridContainer>
        {
          filteredRecords.map((r, i) => (
            <GridItem sm={12} md={6} key={i}>
              <Card className={classes.root}>
                <div className={classes.details}>
                  <CardActionArea onClick={() => handleEdit(null, [i])}>
                    <CardContent className={classes.content}>
                      <GridContainer>
                        <GridItem sm={12} md={4}>
                          <CardMedia />
                          SLIKA
                        </GridItem>
                        <GridItem sm={12} md={8}>
                          <GridContainer>
                            <GridItem xs={12} sm={6} md={12}>
                              <Typography variant="body2">
                                <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
                                <LocationOnIcon fontSize="small"/>
                                </div>
                              </Typography>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={12}>
                              <Typography variant="body2">
                                <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
                                <LocationOnIcon fontSize="small"/>
                                </div>
                              </Typography>
                            </GridItem>
                            <GridItem sm={3}>
                              <Typography variant="body2">
                                <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
                                <PersonIcon fontSize="small"/>
                                {r.vozilo_kapacitet}
                                </div>
                              </Typography>
                            </GridItem>
                            <GridItem sm={4}>
                              <Typography variant="body2">
                                <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
                                <AttachMoneyIcon fontSize="small"/>
                                {r.cijena_ponudjena}
                                </div>
                              </Typography>
                            </GridItem>
                            <GridItem sm={5}>
                              <Typography variant="body2">
                                <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
                                <EventAvailableIcon fontSize="small"/>
                                {r.rok_ponude}
                                </div>
                              </Typography>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                    </CardContent>
                  </CardActionArea>
                </div>
              </Card>
            </GridItem>
          ))
        }
      </GridContainer>
    </Fragment>
  );
}

KomitentiList.propTypes = {
  records: PropTypes.array,
  dc: PropTypes.object,
  title: PropTypes.string,
  viewName: PropTypes.string,
  allowSelection: PropTypes.oneOf(["none", "one", "many"]),
  allowExport: PropTypes.bool,
  allowFilter: PropTypes.bool,
  allowAdd: PropTypes.bool,
  addLabel: PropTypes.string,
  handleAdd: PropTypes.func
};

export default KomitentiList;