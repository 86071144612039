import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import { makeStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";

//Custom Components
import DialogContext from "UI/DialogContext/DialogContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import ImageDialog from "./ImageDialog";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "200px",
    height: "200px",
    borderRadius: "8px",
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    objectFit: "cover",
    cursor: "pointer"
  }
}));

function ImageThumbnail(props) {
  const dialogContext = useContext(DialogContext);
  const loaderContext = useContext(LoaderContext);
  const classes = useStyles();
  const {t} = useTranslation();

  const {thumbnailBase64, imageUUID, mimetype, title, onView, onDelete, onClose} = props;

  const showImageDialog = () => {
    if (imageUUID && onView) {
      loaderContext.toggleLoading(true);
      onView(imageUUID)
        .then((resp) => {
          if (resp.data && resp.data.image) {
            dialogContext.showDialog(ImageDialog, {
              image: resp.data.image,
              onDelete: onDelete,
              uuid: imageUUID,
              onClose: onClose
            })
          }
        })
        .finally(() => {
          loaderContext.toggleLoading(false);
        });
    }
  };

  return (
      <GridContainer>
        <GridItem>
          <CardMedia
              component="img"
              src={thumbnailBase64 ? `data:${mimetype};base64, ` + thumbnailBase64 : null}
              title={title}
              onClick={showImageDialog}
              classes={classes}
          />
        </GridItem>
      </GridContainer>
  );
};

ImageThumbnail.propTypes = {
  thumbnailBase64: PropTypes.string,
  imageUUID: PropTypes.string
};

export default ImageThumbnail;
