import api from "Lib/api";

export const adminService = {
  claimMediation,
  assignMediation,
  stornirajPosao,
  takeAgentura,
  resolvePanic
}

function claimMediation(table_path, object_id) {
  const apiInstance = new api();
  const path = table_path + "/" + object_id + "/claim-mediation";
  return apiInstance.Call(path, "patch", {});
}

function assignMediation(table_path, object_id, data) {
  const apiInstance = new api();
  const path = table_path + "/" + object_id + "/assign-mediation";
  return apiInstance.Call(path, "patch", data);
}

function stornirajPosao(id){
  const apiInstance = new api();
  const path = "admin/voznje/" + id + "/storno";
  return apiInstance.Call(path, "patch");
}

function takeAgentura(id, data) {
  const apiInstance = new api();
  const path = "admin/voznje/" + id + "/agentura";
  return apiInstance.Call(path, "patch", data);
}

function resolvePanic(id, data) {
  const apiInstance = new api();
  const path = "admin/voznje/" + id + "/resolve-panic";
  return apiInstance.Call(path, "patch", data)
}
