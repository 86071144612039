import React from "react";

//Custom components
import { GridContainer } from "UI/Grid";
import FormContent from "Components/FormContent";

function AdminVoznjaTabOsnovniPodaci(props) {
  const { record, validation, fields, onFieldChange, mode, subModels } = props;

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    mode: "view",
    subModels: subModels
  };

  return (
    <GridContainer>
      <FormContent
        fieldNames={[
          "from_ts",
          "to_ts",
          "relacija_opis_skraceni",
          "narucitelj_naziv",
          "narucitelj_kontakt",
          "vozilo_naziv",
          "vozac_ime_i_prezime",
          "kooperant_naziv",
          "kooperant_napomena",
          "vozilo_broj_putnika",
          "vozilo_broj_vodica",
          "napomena_interna"
        ]}
        {...commonProps}
        columns={2}
      />
    </GridContainer>
  );
}

export default AdminVoznjaTabOsnovniPodaci;
