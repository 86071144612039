import React from "react";

//Material-UI Core Components
import Box from "@material-ui/core/Box";

//Custom Components
import AdminPage from "UI/AppPage/AdminPage";
import AdminStatisticsView from "Views/Statistics/AdminStatisticsView";

const AdminHomePage = React.memo((props) => {

  return (
    <AdminPage>
      <Box m={2}>
        <AdminStatisticsView/>
      </Box>
    </AdminPage>
  );
});

export default AdminHomePage;
