import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

//Material UI
import Box from "@material-ui/core/Box";

//Custom components
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import UserContext from "Components/UserContext/UserContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import AdminPage from "UI/AppPage/AdminPage";
import dataController from "Lib/dataController";
import modelUpiti from "Models/upiti";

import AdminUpitForm from "Views/Administration/AdminUpiti/AdminUpitForm";

const AdminUpitView = React.memo((props) => {
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);
  const userContext = useContext(UserContext);

  const [formInfo, setFormInfo] = useState({ component: undefined, form: undefined, dc: undefined });

  const dc = new dataController(modelUpiti, "admin/upiti");

  const { id } = useParams();
  const recordId = parseInt(id, 10);

  const getFormInfo = (status) => {
    return { component: AdminUpitForm, form: "status11", dc: dc};
  };

  const refreshRecord = () => {
    if (userContext.subjectId) {
      loaderContext.toggleLoading(true);
      dc.GetDataSingle(recordId)
        .then((resp) => {
          if (resp.success) {
            const record = resp.data;
            const status = record.upit_state_id;
            const markNew = record.mark_new;
            if (markNew === true) {
              markRead();
            }
            const fi = getFormInfo(status);
            setFormInfo({...fi, record: record});
          }
        })
        .catch((result) => {
          snackbarContext.showNotification(result.error, "error");
        })
        .finally(() => {
          loaderContext.toggleLoading(false);
        });
    }
  };

  const markRead = () => {
    dc.MarkReadRecord(recordId)
    .then((resp) => {
      //do nothing
    })
    .catch((result) => {
      //also do nothing
    })
  }

  useEffect(() => {
    refreshRecord();
  }, [userContext.subjectId, recordId]);

  return (
    <AdminPage>
      <Box m={2}>
        {formInfo.component
          ? React.createElement(formInfo.component, {
              dc: formInfo.dc,
              mode: "view",
              isAdmin: true,
              form: formInfo.form,
              record: formInfo.record,
              subModelDefaultValues: {
                posao_id: recordId
              }
            })
          : null}
      </Box>
    </AdminPage>
  );
})

export default AdminUpitView;
