import React, { useContext, useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

//Material-UI Icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

//Custom components
import { GridContainer, GridItem } from "UI/Grid";
import FormContent from "Components/FormContent";
import dataController from "Lib/dataController";
import modelPosebnaPonuda from "Models/posebnePonude";
import modelUpit from "Models/upiti";
import modelPonuda from "Models/ponuda";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import IconView from "UI/Form/IconView";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

function TabPlatforma(props) {
  const { record, validation, fields, onFieldChange, mode, subModels } = props;

  const [posebnaPonuda, setPosebnaPonuda] = useState(undefined);
  const [upit, setUpit] = useState(undefined);
  const [ponuda, setPonuda] = useState(undefined);

  const { t } = useTranslation();
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);

  const dcPosebnaPonuda = new dataController(modelPosebnaPonuda);
  const dcUpit = new dataController(modelUpit);
  const dcPonuda = new dataController(modelPonuda);

  useEffect(() => {
    loaderContext.toggleLoading(true);
    if (record.posebna_ponuda_id) {
      loadPosebnaPonuda(record.posebna_ponuda_id)
        .then(resp => {
          if (resp.success) {
            setPosebnaPonuda(resp.data);
          }
        }).catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      });
    } else {
      setPosebnaPonuda(null);
    }

    if (record.upit_id) {
      loadUpit(record.upit_id)
        .then(resp => {
          if (resp.success) {
            setUpit(resp.data);
          }
        }).catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
    } else {
      setUpit(null);
    }

    if (record.ponuda_id) {
      loadPonuda(record.ponuda_id)
        .then(resp => {
          if (resp.success) {
            setPonuda(resp.data);
          }
        }).catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      });
    } else {
      setPonuda(null);
    }
    loaderContext.toggleLoading(false);
  }, [record]);

  const loadPosebnaPonuda = (id) => {
    return dcPosebnaPonuda.GetDataSingle(id);
  };

  const loadUpit = (id) => {
    return dcUpit.GetDataSingle(id);
  };

  const loadPonuda = (id) => {
    return dcPonuda.GetDataSingle(id);
  };

  const upitFieldNames = ["published_on", "from_ts", "to_ts", "from_loc", "to_loc", "cijena_hrk", "opis", "klasa_motora_id", "vozilo_klasa_id", "vozilo_oprema_ids", "vozilo_tip_id", "vozilo_broj_putnika"];
  // rok_za_ponude: "2021-07-05T00:00:00+02:00"
  // ruta: "LINESTRING(1777501.76160595 5749512.92011746,17759"
  // stajalista: null
  // trajanje_voznje: 2.2822222222222224
  // udaljenost_povratak: 180
  // udaljenost_voznje: 180

  const commonUpitProps = {
    record: upit,
    fields: dcUpit.getFields(upitFieldNames),
    mode: "simpleView",
    columns: 1,
    subModels: subModels
  };

  const ponudaFieldNames = ["submitted_on", "datum_ponude", "rok_potvrda", "broj_ponude", "iznos_fincalc", "iznos_hrk", "iznos_s_cbc_provizijom", "from_ts", "to_ts", "relacija_opis_skraceni", "vozilo_naziv", "vozilo_opis", "relacija_opis_skraceni", "napomena"];

  const posebnaPonudaTransferFieldNames = ["price_per_distance", "vozilo_naziv", "akcijska_cijena_uk",
    "iznos_fincalc", "iznos_fincalc_manual", "pdv_iznos", "pdv_stopa_manual", "cijena_s_pdv_iznos", "cijena_s_fincalc_iznos", "cbc_provizija_iznos",
    "uk_bruto_hrk", "uk_bruto_hrk_manual", "rok_potvrda", "interna_napomena", "opis",
    "from_ts", "to_ts", "from_loc_name", "from_loc_radius", "to_loc_name", "to_loc_radius",
    "trajanje_voznje", "neto_udaljenost_voznje", "udaljenost_povratka_vozila", "round_trip"];
  const posebnaPonudaKruznoFieldNames = ["distance_for_day", "distance_for_half_day", "distance_for_transfer", "duration_for_day", "duration_for_half_day", "duration_for_transfer", "price_for_day", "price_for_half_day", "price_for_transfer"];

  const commonPonudaProps = {
    record: ponuda,
    fields: dcPonuda.getFields(ponudaFieldNames),
    mode: "simpleView",
    columns: 1
  };

  const commonPosebnaPonudaTransferProps = {
    record: posebnaPonuda,
    fields: dcPosebnaPonuda.getFields(posebnaPonudaTransferFieldNames),
    mode: "simpleView",
    columns: 1
  };

  const commonPosebnaPonudaKruzniProps = {
    record: posebnaPonuda,
    fields: dcPosebnaPonuda.getFields(posebnaPonudaKruznoFieldNames),
    mode: "simpleView",
    columns: 1
  };

  return (
    <Box m={2}>
      {posebnaPonuda ?
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <IconView icon={"stars"} title={t("titles.posebna_ponuda")} />
          </AccordionSummary>
          <AccordionDetails>
            <GridContainer direction="row">
              <GridItem xs={12} sm={3}>
                <GridContainer>
                  <FormContent
                    title={t("titles.osnovni_podaci")}
                    {...commonPosebnaPonudaTransferProps}
                    fieldNames={["round_trip", "price_per_distance", "vozilo_naziv", "interna_napomena", "rok_potvrda", "opis"]}
                  />
                </GridContainer>
              </GridItem>
              {posebnaPonuda["round_trip"] ? (
                <Fragment>
                  <GridItem xs={12} sm={3}>
                    <GridContainer>
                      <FormContent
                        title={t("cjenici.sections.kratkotrajni_najam")}
                        fieldNames={["duration_for_transfer", "distance_for_transfer", "price_for_transfer"]}
                        {...commonPosebnaPonudaKruzniProps}
                      />
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} sm={3}>
                    <GridContainer>
                      <FormContent
                        title={t("cjenici.sections.poludnevni_najam")}
                        fieldNames={["duration_for_half_day", "distance_for_half_day", "price_for_half_day"]}
                        {...commonPosebnaPonudaKruzniProps}
                      />
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} sm={3}>
                    <GridContainer>
                      <FormContent
                        title={t("cjenici.sections.cijelodnevni_najam")}
                        fieldNames={["duration_for_day", "distance_for_day", "price_for_day"]}
                        {...commonPosebnaPonudaKruzniProps}
                      />
                    </GridContainer>
                  </GridItem>
                </Fragment>
              ) : null}
              <GridItem xs={12} sm={3}>
                <GridContainer>
                  <FormContent
                    title={t("posebna_ponuda.sections.termin")}
                    fieldNames={["from_ts", "to_ts"]}
                    {...commonPosebnaPonudaTransferProps}
                  />
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <GridContainer>
                  <FormContent
                    title={t("posebna_ponuda.sections.location")}
                    fieldNames={posebnaPonuda["round_trip"] ? ["from_loc_name", "from_loc_radius"] : ["from_loc_name", "from_loc_radius", "to_loc_name", "to_loc_radius"]}
                    {...commonPosebnaPonudaTransferProps}
                  />
                </GridContainer>
              </GridItem>
              {posebnaPonuda["round_trip"] ? null : (
                <GridItem xs={12} sm={3}>
                  <GridContainer>
                    <FormContent
                      title={t("posebna_ponuda.sections.izracun")}
                      fieldNames={["akcijska_cijena_uk", "pdv_iznos", "pdv_stopa_manual", "cijena_s_pdv_iznos", "iznos_fincalc",
                        "cijena_s_fincalc_iznos", "cbc_provizija_iznos", "uk_bruto_hrk", "uk_bruto_hrk_manual"]}
                      {...commonPosebnaPonudaTransferProps}
                    />
                  </GridContainer>
                </GridItem>
              )}
              {posebnaPonuda["round_trip"] ? null : (
                <GridItem xs={12} sm={3}>
                  <GridContainer>
                    <FormContent
                      {...commonPosebnaPonudaTransferProps}
                      title={t("upit.sections.podaci_o_putovanju")}
                      fieldNames={["trajanje_voznje", "neto_udaljenost_voznje", "udaljenost_povratka_vozila"]}
                    />
                  </GridContainer>
                </GridItem>
              )}
            </GridContainer>
          </AccordionDetails>
        </Accordion>
        : null}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel2a-header">
          <IconView icon={"rate_review"} title={t("titles.upit")} />
        </AccordionSummary>
        <AccordionDetails>
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <GridContainer>
                <FormContent title={t("titles.osnovni_podaci")} {...commonUpitProps} fieldNames={["published_on", "from_ts", "to_ts", "from_loc", "to_loc", "cijena_hrk", "opis"]} />
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <GridContainer>
                <FormContent title={"Odabrani parametri"} {...commonUpitProps} fieldNames={["klasa_motora_id", "vozilo_klasa_id", "vozilo_oprema_ids", "vozilo_tip_id", "vozilo_broj_putnika"]} />
              </GridContainer>
            </GridItem>
            {/* <GridItem xs={12} sm={4}>
              <GridContainer>
                <GridItem xs={12}>
                  <Typography variant="h6" component="h2" paragraph={true}>{t("trazilica.ruta_putovanja")}</Typography>
                </GridItem>
              </GridContainer>
            </GridItem> */}
          </GridContainer>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel3a-header">
          <IconView icon={"local_offer"} title={t("titles.ponuda")} />
        </AccordionSummary>
        <AccordionDetails>
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <GridContainer>
                <FormContent title={t("titles.osnovni_podaci")} {...commonPonudaProps} fieldNames={ponudaFieldNames} />
              </GridContainer>
            </GridItem>
            {/* <GridItem xs={12} sm={4}>
              <GridContainer>
                <FormContent title={"Vozilo"} {...commonPonudaProps} fieldNames={["klasa_motora_id", "vozilo_klasa_id", "vozilo_oprema_ids", "vozilo_tip_id", "vozilo_broj_putnika"]} />
              </GridContainer>
            </GridItem> */}
          </GridContainer>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default TabPlatforma;
