import React from "react";
import { useTranslation } from "react-i18next";

//Custom components
import GridContainer from "UI/Grid/GridContainer";
import FormContent from "Components/FormContent";

function TabPotvrda(props) {
  const { record, validation, fields, onFieldChange, mode, subModels } = props;

  const { t } = useTranslation();

  const renderMode = mode === "update" ? (record["posao_state_id"] > 24 ? "view" : mode) : "view";

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    mode: renderMode,
    subModels: subModels
  };

  return (
    <GridContainer>
      <FormContent
        fieldNames={record["is_interni_posao"] ? ["kupac_id"] : ["narucitelj_id"]}
        {...commonProps}
        mode="view"
        columns={2}
      />
      <FormContent fieldNames={["vodic_ime_prezime"]} {...commonProps} columns={2} />
      <FormContent fieldNames={["potvrda_napomena"]} {...commonProps} columns={1} />
    </GridContainer>
  );
}

export default TabPotvrda;
