import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//Material UI
import { Typography } from "@material-ui/core";

//Custom components
import DialogContext from "UI/DialogContext/DialogContext";
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import ModelTable from "UI/Table/ModelTable";
import dataController from "Lib/dataController";
import model from "Models/financijskeObveze";
import FinObvezaDialog from "Views/Prijevoznici/Voznje/VoznjaFormTabDialogs/FinObvezaDialog";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import UserContext from "Components/UserContext/UserContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";

function AdminTabFinancijskeObaveze(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const userContext = useContext(UserContext);
  const loaderContext = useContext(LoaderContext);
  const { t } = useTranslation();

  const [records, setRecords] = useState([]);
  const [finalCalculationRecords, setFinalCalculationRecords] = useState([]);
  const { recordId, record, tabValue, currentTab, isPanic, mode } = props;

  const dc = new dataController(model, `admin/voznje/${recordId}/financijske-obveze`);

  const handleAdd = (evt) => {
    dialogContext.showDialog(FinObvezaDialog, {
      dc: dc,
      mode: "insert",
      form: "admin_popis_financ_obveze",
      onClose: handleDialogClose,
      subModelDefaultValues: {
        posao_id: recordId
      }
    });
  };

  const handleRowAction = function(action, id) {
    if (action === "edit" || action === "view") {
      dialogContext.showDialog(FinObvezaDialog, {
        dc: dc,
        mode: isPanic ? "view" : "update",
        form: "admin_popis_financ_obveze",
        recordId: id,
        onClose: handleDialogClose,
        subModelDefaultValues: {
          posao_id: recordId
        }
      });
    }
  };

  const handleDialogClose = (result) => {
    if (result && result.dataChanged) {
      refreshRecords();
    }
    dialogContext.hideDialog();
  };

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData()
      .then((resp) => {
        if (resp.success) {
          setRecords(Array.isArray(resp.data) ? resp.data : []);
          calculateTotals(resp.data);
        } else {
          snackbarContext.showNotification(resp.error, "error");
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const calculateTotals = (items) => {
    if (items && items.length > 0) {
      const hash = {};

      items.forEach(r => {
        const kid = r.komitent_id;
        const duguje_val = r["owner_duguje_hrk"];
        const potrazuje_val = r["owner_potrazuje_hrk"];
        const saldo_val = potrazuje_val - duguje_val;
        if (!hash.hasOwnProperty(kid)) {
          hash[kid] = {
            komitent_id: kid,
            vl_duguje_hrk: duguje_val,
            vl_potrazuje_hrk: potrazuje_val,
            vl_saldo_hrk: saldo_val
          };
        } else {
          const c = hash[kid];
          hash[kid] = {
            komitent_id: kid,
            vl_duguje_hrk: c.vl_duguje_hrk + duguje_val,
            vl_potrazuje_hrk: c.vl_potrazuje_hrk + potrazuje_val,
            vl_saldo_hrk: c.vl_saldo_hrk + saldo_val
          };
        }
      });

      const rows = [];
      Object.keys(hash).forEach(key => {
        rows.push(hash[key]);
      });

      setFinalCalculationRecords(rows);
    }
  };

  useEffect(() => {
    if (currentTab === tabValue) {
      refreshRecords();
    }
  }, [currentTab]);

  return (
    <GridContainer>
      <GridItem md={12} sm={12}>
        <ModelTable
          records={records}
          dc={dc}
          viewName={ isPanic ? "admin_readonly" : "admin"}
          allowExport={true}
          allowFilter={true}
          allowAdd={mode === "update" && !isPanic}
          addLabel={t("buttons.new_f")}
          handleAdd={handleAdd}
          allowSelection={"none"}
          onRowAction={handleRowAction}
          subModelDefaultValues={{
            posao_id: recordId
          }}
        />
      </GridItem>
      <GridItem md={12} sm={12}>
        <Typography component="h4" variant="h5">
          {t("titles.ukupni_izracun")}
        </Typography>
        <ModelTable
          records={finalCalculationRecords}
          dc={dc}
          viewName={"admin_kalkulacija"}
          allowExport={false}
          allowFilter={false}
          allowAdd={false}
          allowSelection={"none"}
          pagination={false}
          search={false}
          subModelDefaultValues={{
            posao_id: recordId
          }}
        />
      </GridItem>
    </GridContainer>
  );
}

export default AdminTabFinancijskeObaveze;
