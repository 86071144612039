export function fieldFormat(obj, format, t) {
  let keys = Object.keys(obj);

  if (format) {
    let label = format;
    if (keys.length > 0) {
      keys.forEach((k) => {
        const isEvaluation = k === "evaluation";
        let re = new RegExp('{' + k + '}');
        label = label.replace(re, isEvaluation ? ` (${obj[k]})` : obj[k] != null ? obj[k]: '');
      })
      return t(label);
    } else {
      return null;
    }
  } else {
    return keys.map(x => t(obj[x])).join(', ');
  }
}
