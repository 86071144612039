import React, { useContext } from "react";

//Custom Components
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

function useMarkRead(props) {
  const snackbarContext = useContext(SnackbarContext);

  const { dc, recordId } = props;

  const markRead = (id) => {
    dc.MarkReadRecord(id)
    .then((resp) => {
      //do nothing
    })
    .catch((result) => {
      //also do nothing
    })
  }

  const handleMarkUnread = () => {
    dc.MarkReadRecord(recordId, false)
      .then((resp) => {
        if (resp.success) {
          snackbarContext.showNotification("action", "success");
        }
      })
      .catch((resp) => {
        snackbarContext.showNotification(resp.error.message, "error");
      });
  };

  return {
    markRead,
    handleMarkUnread
  };
}

export default useMarkRead;
