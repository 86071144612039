import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Icons
import DeleteIcon from "@material-ui/icons/Delete";

//Custom components
import CardToolbarMenuItem from "UI/Card/CardToolbarMenuItem";

const CardToolbarMenuItemDelete = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return <CardToolbarMenuItem label={t("buttons.delete")} icon={<DeleteIcon />} {...props} ref={ref} />;
});

export default CardToolbarMenuItemDelete;
