import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

//Icons
import AgenturaIcon from "Icons/AgenturaIcon";

//Custom Components
import FormButton from "UI/Form/FormButton";

function CloseButton(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = props;

  const handleClick = () => {
    history.push(`/p/voznje/${id}#osnovni-podaci`)
  }

  return id ? (
    <FormButton variant="contained" startIcon={<AgenturaIcon />} onClick={handleClick}>
      {t("buttons.agentura_open_internal")}
    </FormButton>
  ) : null;
}

export default CloseButton;
