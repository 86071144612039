import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";

//Material-UI Icons
import SearchIcon from "@material-ui/icons/Search";

const customButtonStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.black,
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      height: "56px",
      width: "100%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  }
}));

function BigSearchButton(props) {
  const { t } = useTranslation();
  const { label, onClick } = props;

  const classes = customButtonStyles();

  return (
    <Button
      variant="contained"
      startIcon={<SearchIcon />}
      classes={classes}
      color="secondary"
      size="medium"
      onClick={onClick}
      classes={classes}
    >
      {label ? label : t("buttons.search")}
    </Button>
  );
}

export default BigSearchButton;
