import React, { Fragment, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

//Material-UI Core Components
import Toolbar from "@material-ui/core/Toolbar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

//Material-UI Icons
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import PublicIcon from "@material-ui/icons/Public";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";

//Custom Components
import withFormController from "Components/withFormController";
import { CardHeading, CardToolbarButtonAlarm, CardToolbarButtonBack } from "UI/Card";
import { CardToolbarMenuButton, CardToolbarMenuItemMarkUnread, CardToolbarMenuItemClaimMediation, CardToolbarMenuItemAssignMediation } from "UI/Card";
import { CardActionButtonClose } from "UI/Card";
import { FormHeading, FormHeadingMediator, FormFillContent } from "UI/Form";
import { FormTabs, FormTab } from "UI/Form";
import UpitAvatar from "UI/Form/Avatars/UpitAvatar";

import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

import TabPanelUpitPrivatno from "Views/Upiti/Tabs/TabPanelUpitPrivatno";
import TabPanelUpitJavno from "Views/Upiti/Tabs/TabPanelUpitJavno";
import TabPanelUpitPrijevoznici from "Views/Upiti/Tabs/TabPanelUpitPrijevoznici";
import TabPanelPonude from "Views/Upiti/Tabs/TabPanelPonude";

import UserContext from "Components/UserContext/UserContext";
import dataController from "Lib/dataController";
import prijevoznikModel from "Models/a_prijevoznici";
import withRedirect from "Components/withRedirect";

import useMediation from "Components/useMediation";
import useMarkRead from "Components/useMarkRead";
import EuroConversionComment from "Components/EuroConversionComment";

function AdminUpitForm(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const userContext = useContext(UserContext);

  const { handleClaimMediation, handleAssignMediation} = useMediation({dc: props.dc, record: props.record, doRefresh: props.doRefresh})
  const { handleMarkUnread} = useMarkRead({dc: props.dc, recordId: props.record.id});

  const history = useHistory();
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState(0);

  const { record, validation, fields, subModels, statuses, dc } = props; //HOC withFormController
  const { onFieldChange, doUpdate, doRefresh } = props; //HOC withFormController
  const { refreshRecord } = props;
  const { redirect } = props;

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleTabChange = (evt, value) => {
    setCurrentTab(value);
  };

  const prijevozniciDc = new dataController(prijevoznikModel);

  const handleClickBack = () => {
    history.goBack();
  };

  const handleDialogClose = (result) => {
    if (result.id) {
      const id = result.id;
      redirect(`/a/upiti/${id}`);
    }
  };

  const handleConfirmPonuda = () => {
    if (refreshRecord) {
      refreshRecord();
    }
  };

  const isLocked = false;
  const panelMode = isLocked ? "view" : "update";
  const interniPanelMode = isLocked ? "view" : "update";
  const javniPanelMode = "view";

  const commonTabProps = {
    currentTab: currentTab,
    recordId: record.id,
    subModels: subModels,
    record: record,
    validation: validation,
    onFieldChange: handleFieldChange,
    fields: fields,
    mode: panelMode
  };

  const privatniTabProps = {
    mode: interniPanelMode,
    ...commonTabProps
  };

  const header = `${t("titles.upit")} (${t("upit.statuses.11")})`;

  const recordStatus = record ? record.upit_state_id : 11;

  return (
    <Card>
      <CardHeading recordStatus={recordStatus} statuses={statuses}>
        <Toolbar variant="dense" disableGutters={true}>
          <UpitAvatar />
          <FormHeading header={header} subheaderContent={<FormHeadingMediator record={record} />} />
          <FormFillContent />
          <CardToolbarButtonAlarm record={record} type="upit" onFieldChange={onFieldChange}/>
          <CardToolbarMenuButton>
            <CardToolbarMenuItemClaimMediation onClick={handleClaimMediation} />
            <CardToolbarMenuItemAssignMediation onClick={handleAssignMediation} />
            <CardToolbarMenuItemMarkUnread onClick={handleMarkUnread} />
          </CardToolbarMenuButton>
          <CardToolbarButtonBack onClick={handleClickBack} />
        </Toolbar>
      </CardHeading>

      <CardContent style={{ padding: 0 }}>
        <FormTabs value={currentTab} onChange={handleTabChange}>
          <FormTab
            value={0}
            id="javni-podaci"
            label={t("upit.tabs.javni_podaci")}
            icon={<PublicIcon />}
            validation={validation}
            fields={[
              "from_ts",
              "to_ts",
              "vozilo_tip_id",
              "vozilo_klasa_id",
              "vozilo_broj_putnika",
              "opis",
              "vozilo_oprema_ids",
              "trajanje_voznje",
              "neto_udaljenost_voznje",
              "udaljenost_povratka_vozila",
              "cijena_hrk",
              "rok_za_ponude"
            ]}
          />
          <FormTab
            value={1}
            id="odabir-prijevoznika"
            label={t("upit.tabs.odabir_prijevoznika")}
            icon={<AirportShuttleIcon />}
            validation={validation}
            fields={["burza_visible"]}
          />
          <FormTab
            value={2}
            id="ponude"
            label={t("upit.tabs.ponude")}
            icon={<LocalOfferIcon />}
            validation={validation}
            fields={[]}
          />
        </FormTabs>
        <TabPanelUpitJavno tabIndex={0} {...commonTabProps} mode="view" showNarucitelj={true}/>
        <TabPanelUpitPrijevoznici
          tabIndex={1}
          {...commonTabProps}
          dc={prijevozniciDc}
          mode="view"
          disableRemove={true}
        />
        <TabPanelPonude tabIndex={2} {...privatniTabProps} recordId={record.id} onConfirm={handleConfirmPonuda} isAdmin={true}/>
      </CardContent>

      <CardActions>
        <EuroConversionComment />
        <FormFillContent />
        <CardActionButtonClose variant="contained" onClick={handleClickBack} />
      </CardActions>
    </Card>
  );
}

export default withRedirect(withFormController(AdminUpitForm));
