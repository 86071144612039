import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import PropTypes from "prop-types";

//Material UI
import Toolbar from "@material-ui/core/Toolbar";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";

//Material-UI Icons
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import PublicIcon from "@material-ui/icons/Public";
import PeopleIcon from "@material-ui/icons/People";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import PoslovniSubjektIcon from "Icons/PoslovniSubjektIcon";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

//Custom components
import withFormController from "Components/withFormController";
import FormContent from "Components/FormContent";
import { GridContainer, GridItem } from "UI/Grid";
import { CardActionButtonSave, CardHeading, CardToolbarButtonBack, CardToolbarMenuButton, CardToolbarMenuItemActivate, CardToolbarMenuItemDeactivate, CardToolbarMenuItemDelete } from "UI/Card";
import { FormAvatar, FormFillContent, FormHeading, FormHeadingID, FormTab, FormTabPanel, FormTabs } from "UI/Form";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import Vozila from "./Tabs/Vozila";
import Korisnici from "./Tabs/Korisnici";
import DialogContext from "UI/DialogContext/DialogContext";
import AddButton from "UI/Buttons/AddButton";
import dataController from "Lib/dataController";
import imageModel from "Models/poslovniSubjektiImages";
import { businessSubjectService } from "Services/businessSubjectService";
import { businessSubjectMemoService } from "Services/businessSubjectMemoService";
import ImageThumbnail from "UI/Image/ImageThumbnail";
import ImageUploadDialog from "UI/Dialog/ImageUploadDialog";
import SwapButton from "UI/Buttons/SwapButton";
import withRedirect from "Components/withRedirect";

function PoslovniSubjektForm(props) {
  const { t } = useTranslation();
  const history = useHistory();

  const snackbarContext = useContext(SnackbarContext);
  const dialogContext = useContext(DialogContext);

  const [currentTab, setCurrentTab] = useState(0);

  const { dc, record, recordId, validation, fields, subModels, dataChanged } = props; //HOC withFormController
  const { doUpdate, doDelete, doRefresh, doClose } = props; //HOC withFormController
  const { onFieldChange } = props; //HOC withFormController
  const { redirect } = props;

  const image_path = `business-subjects/${recordId}/images`;
  const image_dc = new dataController(imageModel, image_path);

  const memo_path = `business-subjects/${recordId}/memo-headers`;
  const memo_dc = new dataController(imageModel, memo_path);

  const mode = record && record.active ? "update" : "view";

  const handleClickBack = () => {

    if (doClose) {
      doClose().then(resp => {
        if (resp.success) {
          history.goBack();
        } else if (resp.shouldsave) {
          if (doUpdate) {
            doUpdate().then((result) => {
              if (result.success) {
                snackbarContext.showNotification("update", "success");
                history.goBack();
              }
            }).catch((result) => {
              if (result.success === false && result.validationPass === false) {
                //do nothing
              } else {
                snackbarContext.showNotification(result.error, "error");
              }
            });
          }
        }
      }).catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      });
    }
  };

  const handleTabChange = (evt, value) => {
    setCurrentTab(value);
  };

  const handleUpdate = (evt) => {
    if (doUpdate) {
      doUpdate().then((result) => {
        if (result.success) {
          snackbarContext.showNotification("update", "success");
        }
      }).catch((result) => {
        if (result.success === false && result.validationPass === false) {
          //do nothing
        } else {
          snackbarContext.showNotification(result.error, "error");
        }
      });
    }
  };

  const handleDelete = (evt) => {
    if (doDelete) {
      doDelete().then((result) => {
        if (result.success) {
          snackbarContext.showNotification("delete", "success");
          history.goBack();
        }
      }).catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      });;
    }
  };

  const handleActivate = (evt) => {
    businessSubjectService
      .activateBusinessSubject(recordId)
      .then((res) => {
        if (res.success) {
          snackbarContext.showNotification("notifications.activate", "success");
          if (doRefresh) {
            doRefresh();
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDeactivate = (evt) => {
    businessSubjectService
      .deactivateBusinessSubject(recordId)
      .then((res) => {
        if (res.success) {
          snackbarContext.showNotification("notifications.deactivate", "success");
          history.goBack();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleUploadButton = () => {
    dialogContext.showDialog(ImageUploadDialog, {
      dc: image_dc,
      baseRecordId: recordId,
      service: businessSubjectService,
      onClose: handleCloseDialog
    });
  };

  const handleUploadMemoButton = () => {
    dialogContext.showDialog(ImageUploadDialog, {
      dc: memo_dc,
      baseRecordId: recordId,
      service: businessSubjectMemoService,
      onClose: handleCloseDialog
    });
  }

  const viewLogo = (uuid) => {
    return businessSubjectService.getFullImage(recordId, uuid);
  };

  const viewMemo = (uuid) => {
    return businessSubjectService.getFullImage(recordId, uuid);
  };

  const deleteLogo = (uuid) => {
    return businessSubjectService.deleteImage(recordId, uuid);
  };

  const deleteMemo = (uuid) => {
    return businessSubjectMemoService.deleteImage(recordId, uuid);
  };

  const handleCloseDialog = (result) => {
    if (result.dataChanged) {
      if(doRefresh){
        doRefresh();
      }
    }
    dialogContext.hideDialog();
  };

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels
  };

  const title = record.naziv;

  const showSaveButton = record.active && (currentTab === 0 || currentTab === 1);
  const disableSaveButton = currentTab !== 0 && currentTab !== 1;
  const hasLogo = record.thumb && record.uuid && record.image_mime_type && record.file_name;
  const hasMemo = (record.memo_image !== null && record.memo_image !== undefined ) ? true : false;

  return (
    <Card>
      <CardHeading>
        <Toolbar variant="dense" disableGutters={true}>
          <FormAvatar ariaLabel="poslovni_subjekt" icon={<PoslovniSubjektIcon />} />
          <FormHeading header={title} subheaderContent={<FormHeadingID id={recordId} />} dataChanged={dataChanged} />
          <FormFillContent />
          <CardToolbarMenuButton>
            {record.active ? (
              <CardToolbarMenuItemDeactivate onClick={handleDeactivate} />
            ) : (
              <CardToolbarMenuItemActivate onClick={handleActivate} />
            )}
            <CardToolbarMenuItemDelete onClick={handleDelete} />
          </CardToolbarMenuButton>
          <CardToolbarButtonBack onClick={handleClickBack} />
        </Toolbar>
      </CardHeading>
      <CardContent style={{ paddingTop: 0 }}>
        <FormTabs value={currentTab} onChange={handleTabChange} identifier={dc.getSource()}>
          <FormTab id="osnovni-podaci" label={t("bs.tabs.osnovni_podaci")} icon={<BusinessCenterIcon />} aria-label="osnovni_podaci" />
          <FormTab id="javni-podaci" label={t("bs.tabs.javni_podaci")} icon={<PublicIcon />} aria-label="javni_podaci" />
          <FormTab id="korisnici" label={t("bs.tabs.korisnici")} icon={<PeopleIcon />} aria-label="users" />
          <FormTab id="vozila" label={t("bs.tabs.vozila")} icon={<AirportShuttleIcon />} aria-label="vozilo" />
        </FormTabs>
        <FormTabPanel value={currentTab} index={0}>
          <GridContainer direction="row" spacing={3}>
            <GridItem xs={12} md={6}>
              <GridContainer direction="row">
                <FormContent
                  title={t("bs.sections.basic")}
                  fieldNames={["naziv", "type_id", "oib", "napomena"]}
                  {...commonProps}
                  columns={2}
                />
                <FormContent
                  title={t("bs.sections.adresa")}
                  fieldNames={["adr_drzava", "adr_grad", "adr_ulica", "adr_pbr"]}
                  {...commonProps}
                  columns={2}
                />
                <FormContent
                  title={t("bs.sections.contact")}
                  fieldNames={["contact_email", "contact_tel", "contact_mobile", "contact_person"]}
                  {...commonProps}
                  columns={2}
                />
              </GridContainer>
            </GridItem>
            <GridItem xs={12} md={6}>
              <GridContainer direction="row">
                <FormContent
                  title={t("bs.sections.billing")}
                  fieldNames={["vat", "swift", "banka", "iban", "cbc_provizija", "dozvoli_avans", "faktura_rok_uplate_dani", "faktura_napomene"]}
                  {...commonProps}
                  columns={2}
                />
                <GridItem xs={12} md={6}>
                  <Typography variant="h6" component="h2" paragraph={true}>{t("bs.sections.memo")}</Typography>
                  <GridContainer direction="row" spacing={1} alignItems="flex-end">
                    <GridItem>
                      {hasMemo ? (
                        <ImageThumbnail
                          thumbnailBase64={record.memo_image.thumb}
                          imageUUID={record.memo_image.uuid}
                          mimetype={record.memo_image.image_mime_type}
                          onView={viewMemo}
                          onDelete={deleteMemo}
                          recordId={recordId}
                          title={record.memo_image.file_name}
                          onClose={handleCloseDialog}
                        />) : (
                        <CardMedia component="img" src={"https://via.placeholder.com/500x90.png"} style={{ height: "90px", width: "500px", borderRadius: "8px" }} />
                      )}
                    </GridItem>
                    <GridItem>
                      {hasMemo ? (
                        <SwapButton onClick={handleUploadMemoButton} label={t("buttons.update_image")} />
                      ) : (
                        <AddButton onClick={handleUploadMemoButton} label={t("buttons.add_image")} />
                      )}
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} md={6}>
                  <Typography variant="h6" component="h2" paragraph={true}>{t("bs.sections.logo")}</Typography>
                  <GridContainer direction="row" spacing={1} alignItems="flex-end">
                    <GridItem>
                      {hasLogo ? (
                        <ImageThumbnail
                          thumbnailBase64={record.thumb}
                          imageUUID={record.uuid}
                          mimetype={record.image_mime_type}
                          onView={viewLogo}
                          onDelete={deleteLogo}
                          recordId={recordId}
                          title={record.file_name}
                          onClose={handleCloseDialog}
                        />) : (
                        <CardMedia component="img" src={"https://via.placeholder.com/200.png"} style={{ height: "200px", width: "200px", borderRadius: "8px" }} />
                      )}
                    </GridItem>
                    <GridItem>
                      {hasLogo ? (
                        <SwapButton onClick={handleUploadButton} label={t("buttons.update_image")} />
                      ) : (
                        <AddButton onClick={handleUploadButton} label={t("buttons.add_image")} />
                      )}
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={1}>
          <GridContainer>
            <FormContent
              title={t("bs.sections.public_contact")}
              fieldNames={["public_naziv", "public_adr_full", "public_email", "public_tel", "public_mobile", "public_web"]}
              {...commonProps}
              columns={2}
            />
            <FormContent
              title={t("bs.sections.opis")}
              fieldNames={["public_desc_hr", "public_desc_en"]}
              {...commonProps}
              columns={2}
            />
          </GridContainer>
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={2}>
          <Korisnici tabIndex={2} currentTab={currentTab} recordId={recordId} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={3}>
          <Vozila tabIndex={3} currentTab={currentTab} recordId={recordId} />
        </FormTabPanel>
      </CardContent>
      <CardActions>
        <FormFillContent />
        {showSaveButton ? (
          <CardActionButtonSave onClick={handleUpdate} variant="contained" disabled={disableSaveButton} />
        ) : null}
      </CardActions>
    </Card>
  );
}

PoslovniSubjektForm.propTypes = {
  dc: PropTypes.object,
  recordId: PropTypes.number,
  mode: PropTypes.string,
  form: PropTypes.string
};

export default withRedirect(withFormController(PoslovniSubjektForm));
