import React, { Fragment, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";

// Material UI Components
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

// Custom Components
import { formats } from "Lib/formats";
import numbro from "numbro";
import { IconView } from "UI/Form";
import VoziloPublicDialog from "Views/Agencije/Trazilica/VoziloPublicDialog";
import DialogContext from "UI/DialogContext/DialogContext";

function ViewControl(props) {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const dialogContext = useContext(DialogContext);

  const { field, model, record } = props;

  useEffect(() => {
    const { value } = props;
    const isSelectedValue = value && value.label || (value && value[0] && value[0].label);
    if (model && items.length === 0 && !isSelectedValue) {
      model.GetData()
        .then(resp => {
          setItems(resp.data);
        });
    }
  }, [model]);

  const handleLinkAction = (recType, recId) => {
    switch (recType) {
      case "vozilo":
        handleLinkVozilo(recId)
        break;
      default:
        break;
    }
  }

  const handleLinkVozilo = (id) => {
    dialogContext.showDialog(VoziloPublicDialog, {
      recordId: id
    });
  };

  const getLabel = () => {
    const { field } = props;

    return (
      field.hasOwnProperty("ttoken") && field.ttoken ?
        t(field.ttoken) : field.hasOwnProperty("title") && field.title ?
        t(field.title) :
        ""
    );
  };

  const getValue = () => {
    const { field, value } = props;

    // TODO
    // multiline support
    if (value === null || value === "") {
      return "N/A";
    } else {
      switch (field.type) {
        case "date":
          return moment.utc(value).local().format(formats.date);
        case "datetime":
          return moment.utc(value).local().format(formats.datetime);
        case "boolean":
        case "picker":
        case "radio":
          return getObjectValue();
        case "checkbox":
          if (value !== null || items !== null) {
            const isSelectedValue = value && value.label || (value && value[0] && value[0].label);
            const _items = isSelectedValue ? value : filterItems(value, items);
            const _labels = _items.map(x => x ? x.label : "");
            const translatedLabels = _labels.map(x => t(x));
            const displayValue = translatedLabels.join("; ");
            return displayValue;
          } else {
            return "N/A";
          }
        case "text":
        case "float":
        case "multiline":
          return value && (typeof value === "string" || typeof value === "number") ? value : "N/A";
        case "wholenum":
          if (field.suffix) {
            return value + " " + field.suffix;
          } else {
            return value;
          }
        case "numeric":
          if (field.suffix) {
            return value + " " + field.suffix;
          } else {
            return value;
          }
        case "currency":
          if (value) {
            const currency = field && field.currency ? field.currency : "€";
            const main = numbro(value).formatCurrency({
              mantissa: 2,
              currencySymbol: currency,
              currencyPosition: "postfix",
              spaceSeparated: true
            });
            return main;
          } else {
            return "N/A";
          }
        case "HEREautocomplete":
        case "GooglePlaces":
          if (value) {
            return value.label;
          }
        case "HEREautocompleteViaStops":
        case "GooglePlacesViaControl":
          if (value) {
            const _labels = value.map(x => x ? x.value.label : "");
            return (
              <ol>
                {_labels.map((label) => (
                  <li>{label}</li>
                ))}
              </ol>
            );
          }
        case "link":
          if (value) {
            const recIdField = field.link ? field.link.recordIdField : null;
            const recType = field.link ? field.link.recordType : null;
            const recId = record.hasOwnProperty(recIdField) ? record[recIdField] : null;
            return (
              <div type="text" value={value} style={{display: "inline-block"}}>
                {value}
                <IconButton
                  aria-label={"link"}
                  size="small"
                  onClick={(evt) => {
                    handleLinkAction(recType, recId);
                  }}
                >
                  <Icon color="secondary">link</Icon>
                </IconButton>
              </div>
            );
          }
        default:
          return "";
      }
    }
  };

  const getObjectValue = () => {
    const { field, value } = props;

    if (value === null || value === undefined) {
      return "N/A";
    }

    if (typeof value === "number" || typeof value === "boolean") {
      if (model && items.length > 0 && value) {
        const _value = items.find((item) => item.value === value);
        return t(_value.label);
      } else if (field.hasOwnProperty("items") && typeof field.items === "object" &&
        field.items.hasOwnProperty("labels") && Array.isArray(field.items.labels) &&
        field.items.hasOwnProperty("values") && Array.isArray(field.items.values)
      ) {
        const valuePos = field.items.values.indexOf(value);
        if (valuePos === -1) {
          console.warn("Value has no label");
          return value.hastranslationvalue ? t(String(value)) : String(value);
        }

        if (valuePos >= field.items.labels) {
          console.error("Not enough labels to match the value position");
          console.error("Current value: " + value + ". All values: " + field.items.values + ". All labels: " + field.items.labels + ".");
        }
        //console.log(field.items.labels[valuePos]);
        return t(field.items.labels[valuePos]);
      }
      // } else {
      //   console.error("Unknown form of model object");
      // } //View control will show N/A if there is no model object
    } else if (typeof value === "object") {
      if (value.hasOwnProperty("label") && value.label) {
        return t(value.label);
      } else if (value.hasOwnProperty("value") && value.value) {
        return t(value.value);
      } else {
        console.error("Value form is not expected!");
        console.error("Expected value form: { value: some_value, label: some_lable }! Instead got: ", value);
        return "ERROR";
      }
    } else if (typeof value === "string") {
      return t(value);
    } else {
      console.error("Undefined type of value!");
      console.error("Expected one of [string, number, object]! Instead got: " + typeof value + "!");
      return "ERROR";
    }
  };

  const filterItems = (value, items) => {
    return items.filter(x => value.find(f => f === x.value));
  };

  const label = getLabel();
  const value = getValue();


  return (
    <Fragment>
      {field.icon ? (
        <IconView icon={field.icon} title={label} value={value} />
      ) : (
        <Typography variant="caption" align="right" display="inline">
          {label}:
          <b>
            {" "}
            {value}
          </b>
        </Typography>
      )}
    </Fragment>
  );
}

ViewControl.defaultProps = {
  multiline: false,
  rowsMax: 5,
  isValueCalculated: false,
  showHelper: true
};

ViewControl.propTypes = {
  field: PropTypes.object.isRequired
  , multiline: PropTypes.bool
};

export default ViewControl;
