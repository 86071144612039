import { coreFields } from "./helpers/coreFields";
import { alarmFields } from "./helpers/alarmFields";
import { buttonFields } from "./helpers/buttonFields";

export default {
  title: "rezervacije",
  exportFileName: "Rezervacije",
  source: "rezervacije",
  apiPath: "rezervacije/",
  forms: {
    default: {
      fields: [
        "alarm_ts",
        "alarm_active",
        "tip_id",
        "vozilo_id",
        "vozac_id",
        "voznja_id",
        "ts_from",
        "ts_to",
        "opis",
        "empty_picker"
      ],
      validator: "validateRezervacija"
    }
  },
  listViews: {
    default: {
      fields: ["id", "tip_id", "ts_from", "ts_to", "opis", "resurs_label", "alarm_ts", "alarm_active", "edit"],
      hidden: ["id", "alarm_ts", "alarm_active"]
    }
  },
  fields: [
    coreFields.id,
    {
      title: "Title",
      source: "title",
      type: "text"
    },
    {
      title: "Rezervacija",
      source: "tip_id",
      type: "picker",
      ttoken: "rezervacije.tip_id",
      translate: true,
      subModel: {
        apiPath: "rezervacije/tipovi",
        format: "{label}",
        default: 2
      },
      validation: {
        required: true
      }
    },
    {
      title: "resurs_id",
      source: "resurs_id",
      ttoken: "rezervacije.resurs",
      type: "picker",
      subModel: {}
    },
    {
      title: "resurs_label",
      source: "resurs_label",
      ttoken: "rezervacije.resurs",
      type: "text",
      readonly: true
    },
    {
      title: "vozac_id",
      source: "vozac_id",
      ttoken: "rezervacije.vozac_id",
      type: "picker",
      subModel: {
        apiPath: "prijevoznici/{prijevoznik_id}/vozaci?need_for=picker",
        format: "{label}"
      }
    },
    {
      title: "vozilo_id",
      source: "vozilo_id",
      ttoken: "rezervacije.vozilo_id",
      type: "picker",
      subModel: {
        apiPath: "prijevoznici/{prijevoznik_id}/vozila?need_for=picker",
        format: "{label}, {registracija}"
      }
    },
    {
      title: "voznja_id",
      source: "voznja_id",
      ttoken: "rezervacije.voznja_id",
      type: "picker",
      subModel: {
        apiPath: "prijevoznici/{prijevoznik_id}/voznje?need_for=picker",
        format: "{label}"
      }
    },
    {
      title: "empty_picker",
      source: "empty_picker",
      ttoken: "rezervacije.empty_picker",
      type: "picker",
      readonly: true,
      placeholder: "rezervacije.choose_reservation"
    },
    {
      title: "Pocetak",
      source: "ts_from",
      ttoken: "rezervacije.ts_from",
      type: "datetime",
      validation: {
        required: true
      }
    },
    {
      title: "Kraj",
      source: "ts_to",
      ttoken: "rezervacije.ts_to",
      type: "datetime",
      validation: {
        required: true
      }
    },
    {
      title: "Opis",
      source: "opis",
      type: "multiline",
      ttoken: "rezervacije.opis",
      width: "full",
      rowsMax: 3,
      validation: {
        required: true,
        maxLength: 100
      }
    },
    {
      title: "Alarm timestamp",
      source: "alarm_ts",
      ttoken: "common.alarm_ts",
      type: "datetime",
      validation: {
        required: true
      }
    },
    alarmFields.alarm_active,
    buttonFields.edit
  ]
};
