import React, { useState } from "react";
import numbro from "numbro";

//Material UI
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";

function TooltipComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

export default function CurrencyColumnFilter(props) {
  const [sliderValue, setSliderValue] = useState(undefined);

  const { id, filterValue, setFilter, preFilteredRows, onFilterChange } = props;

  const defaultValue = [-1, -1];

  const rowsWithValueCount = preFilteredRows.filter((x) => x.values[id]).length;

  preFilteredRows.forEach((row, r) => {
    if (row.values[id]) {
      const unformatedvalue = row.values[id].props.unformatedvalue;
      if (defaultValue[0] === -1 && defaultValue[1] === -1) {
        defaultValue.splice(0, 1, unformatedvalue);
        defaultValue.splice(1, 1, unformatedvalue);
      } else {
        if (unformatedvalue < defaultValue[0]) {
          defaultValue.splice(0, 1, unformatedvalue);
        } else if (unformatedvalue > defaultValue[1]) {
          defaultValue.splice(1, 1, unformatedvalue);
        }
      }
    }
  });

  const minMaxDiff = defaultValue[1] - defaultValue[0];

  const step = minMaxDiff < 10 ? 0.1 : minMaxDiff < 100 ? 1 : minMaxDiff < 1000 ? 10 : 100;
  if (!sliderValue) {
    setSliderValue(filterValue ? filterValue : defaultValue);
  }

  const handleSliderValue = (evt, newValue) => {
    setSliderValue(newValue);
  };

  return sliderValue ? (
    <Slider
      value={sliderValue}
      onChange={handleSliderValue}
      onChangeCommitted={(e, newValue) => {
        onFilterChange(newValue || undefined);
      }}
      min={defaultValue[0]}
      max={defaultValue[1]}
      valueLabelDisplay="auto"
      ValueLabelComponent={TooltipComponent}
      color="secondary"
      valueLabelFormat={(x) => {
        return numbro(x).format({ thousandSeparated: true, mantissa: 2 });
      }}
      // marks={true}
      step={step}
      disabled={rowsWithValueCount === 0}
    />
  ) : null;
}
