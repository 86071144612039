import React from "react";
import { useTranslation } from "react-i18next";

//Materual-UI Icons
import CloseIcon from "@material-ui/icons/Close";

//Custom Components
import DialogActionButton from "UI/Dialog/DialogActionButton";

function DialogActionButtonClose(props) {
  const { t } = useTranslation();

  return (
    <DialogActionButton startIcon={<CloseIcon />} {...props} aria-label="close" aria-controls="close">
      {t("buttons.close")}
    </DialogActionButton>
  );
}

export default DialogActionButtonClose;
