import React, { Fragment, useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import numbro from "numbro";

//Material-UI Core components
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import DialogActions from "@material-ui/core/DialogActions";

//Material-UI Icons
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

//Custom Components
import withFormController from "Components/withFormController";
import DynamicDialog from "UI/Dialog/DynamicDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarCreationDetails from "UI/Dialog/DialogToolbarCreationDetails";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButton from "UI/Dialog/DialogActionButton";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogActionButtonAdd from "UI/Dialog/ActionButtons/DialogActionButtonAdd";
import DialogActionButtonSave from "UI/Dialog/ActionButtons/DialogActionButtonSave";
import DialogActionButtonDelete from "UI/Dialog/ActionButtons/DialogActionButtonDelete";
import FormContent from "Components/FormContent";
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import GridContainer from "UI/Grid/GridContainer";
import CjenikTemplateDialog from "./CjenikTemplateDialog";
import { vozilaService } from "Services/vozilaService";
import { mapIcons } from "Lib/mapIcons";
import { conversions } from "Lib/conversions";
import GridItem from "UI/Grid/GridItem";
import GoogleMap from "Components/Google/GoogleMap";
import EuroConversionComment from "Components/EuroConversionComment";

function CjenikDialog(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const {t} = useTranslation();

  const [pinPoint, setPinPoint] = useState(null);

  const {dc, record, mode, validation, fields, subModels, voziloId} = props; //HOC withFormController
  const {doClose} = props; //HOC withFormController
  const {onFieldChange, onClose, doValidate} = props; //HOC withFormController

  const fPricePerKm = "price_per_distance";
  const fFullDayDistance = "distance_for_day";
  const fFullDayPrice = "price_for_day";
  const fVoziloPicker = "vozilo_id";
  const fLocation = "lokacija";

  const handleSetMarker = (data) => {
    const {value, label, coords} = data;

    const icon = mapIcons.pinPointIcon();
    const marker = {id: value, label: label, coords: coords, icon: icon};

    setPinPoint(marker);
  }

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      let price = null;

      if (source === fLocation) {
        handleSetMarker(value)
      }

      if (source === fPricePerKm) {
        if (value && record[fFullDayDistance]) {
          price = numbro(value).value() * numbro(record[fFullDayDistance]).value()
          onFieldChange(price, fFullDayPrice)
        } else {
          onFieldChange(null, fFullDayPrice)
        }
      }

      if (source === fFullDayDistance) {
        if (value && record[fPricePerKm]) {
          price = numbro(value).value() * numbro(record[fPricePerKm]).value()
          onFieldChange(price, fFullDayPrice)
        } else {
          onFieldChange(null, fFullDayPrice)
        }
      }

      onFieldChange(value, source);
    }
  };

  const handleInsert = (evt) => {
    if (doValidate) {
      const isValid = doValidate();
      if (isValid) {
        const preparedRecord = dc.prepareSendingData(record);
        vozilaService.createCjenik(voziloId, preparedRecord).then((result) => {
          if (result.success) {
            close({dataChanged: true, action: "insert"});
            snackbarContext.showNotification("insert", "success");
          }
        }).catch((result) => {
          snackbarContext.showNotification(result.error, "error");
        });
      } else {
      }
    }
  };

  const handleUpdate = (evt) => {
    if (doValidate) {
      const isValid = doValidate();
      if (isValid) {
        const preparedRecord = dc.prepareSendingData(record);
        vozilaService.updateCjenik(voziloId, record["id"], preparedRecord).then((result) => {
          if (result.success) {
            close({dataChanged: true, action: "update"});
            snackbarContext.showNotification("update", "success");
          }
        }).catch((result) => {
          snackbarContext.showNotification(result.error, "error");
        });
      } else {
      }
    }
  };

  const handleDelete = (evt) => {
    if (doValidate) {
      const isValid = doValidate();
      if (isValid) {
        vozilaService.deleteCjenik(voziloId, record["id"]).then((result) => {
          console.log(result)
          if (result.success) {
            close({dataChanged: true, action: "delete"});
            snackbarContext.showNotification("delete", "success");
          }
        }).catch((result) => {
          snackbarContext.showNotification(result.error, "error");
        });
      } else {
      }
    }
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({dataChanged: false});
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const close = (result) => {
    if (result.dataChanged) {
      onClose({dataChanged: true, action: result.action});
    }

    dialogContext.hideDialog();
  };

  const closeTemplatesDialog = () => {
    dialogContext.hideDialog(2);
  }

  const handleLoadTemplate = (evt) => {
    dialogContext.showDialog(CjenikTemplateDialog,{
      handleClose: closeTemplatesDialog,
      handleLoad: populateData
    }, 2)
  }

  const populateData = (data) => {
    Object.keys(data).forEach((key) => {
      onFieldChange(data[key], key)
    })
  }

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: handleFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels
  };

  useEffect(() => {
    if(record['loc_geo'] && record['loc_id'] && record['loc_name']) {
      const marker = {
        value: record['loc_id'],
        label: record['loc_name'],
        coords: conversions.wkt_to_latLng(record['loc_geo'])
      }
      handleFieldChange(marker, "lokacija")
    }
  }, [record])

  if (voziloId) {
    record[fVoziloPicker] = voziloId;
  }

  return (
      <DynamicDialog onClose={handleClose}>
        <DialogHeader>
          <Toolbar variant="dense" disableGutters={true}>
            <DialogAvatar ariaLabel="cjenik" icon={<MonetizationOnIcon/>}/>
            <DialogToolbarHeading>{t("titles.cjenik")}</DialogToolbarHeading>
            <DialogToolbarFillContent/>
            <DialogToolbarCreationDetails record={record}/>
            <DialogToolbarButtonClose onClick={handleClose}/>
          </Toolbar>
        </DialogHeader>
        <DialogBody>
          <Box m={2}>
            <GridContainer>
              <GridItem md={12} sm={12}>
                <GridContainer direction="row" spacing={2}>
                  <GridItem md={3} sm={3}>
                    <GridContainer direction="column" spacing={0}>
                      <FormContent
                          {...commonProps}
                          title={t("cjenici.sections.lokacija")}
                          fieldNames={["lokacija"]}
                          columns={1}
                      />
                      <FormContent
                          {...commonProps}
                          title={t("cjenici.sections.osnovno")}
                          fieldNames={["price_per_distance"]}
                          columns={1}
                      />
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={9} md={9}>
                    <GoogleMap markers={pinPoint ? [pinPoint] : null} height={280} allowInteraction={true} zoomIn={true}/>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <FormContent
                  {...commonProps}
                  title={t("cjenici.sections.cjenik")}
                  fieldNames={["naziv", "kategorija_cjenika_id", "vozilo_id"]}
                  columns={3}
              />
              <FormContent
                  {...commonProps}
                  title={t("cjenici.sections.kratkotrajni_najam")}
                  fieldNames={["duration_for_transfer", "distance_for_transfer", "price_for_transfer"]}
                  columns={3}
              />
              <FormContent
                  {...commonProps}
                  title={t("cjenici.sections.poludnevni_najam")}
                  fieldNames={["duration_for_half_day", "distance_for_half_day", "price_for_half_day"]}
                  columns={3}
              />
              <FormContent
                  {...commonProps}
                  title={t("cjenici.sections.cijelodnevni_najam")}
                  fieldNames={["duration_for_day", "distance_for_day", "price_for_day"]}
                  columns={3}
              />
              {mode === "update" ? (
                  <FormContent
                      {...commonProps}
                      title={t("cjenici.sections.change_all_allocations")}
                      fieldNames={["change_all_allocations"]}
                      columns={3}
                  />
              ) : null}
            </GridContainer>
          </Box>
        </DialogBody>
        <DialogActions>
          <EuroConversionComment />
          <DialogToolbarFillContent />
          <DialogActionButtonClose variant="outlined" onClick={handleClose}/>
          <DialogActionButton variant="outlined" onClick={handleLoadTemplate} startIcon={<CloudDownloadIcon/>}>{t("buttons.load_from_template")}</DialogActionButton>
          {mode === "insert" ? <DialogActionButtonAdd variant="contained" onClick={handleInsert}/> : null}
          {mode === "update" ? (
              <Fragment>
                <DialogActionButtonDelete variant="outlined" onClick={handleDelete}/>
                <DialogActionButtonSave variant="contained" onClick={handleUpdate}/>
              </Fragment>
          ) : null}
        </DialogActions>
      </DynamicDialog>
  );
}

export default withFormController(CjenikDialog);
