import React, { useContext, Fragment } from 'react';
import { useParams } from "react-router";

//Material UI
import Box from "@material-ui/core/Box";

//Custom components
import UserContext from "Components/UserContext/UserContext";
import model from "Models/komitenti";
import dataController from "Lib/dataController";
import PrijevozniciPage from "UI/AppPage/PrijevozniciPage";
import KomitentForm from "./KomitentForm";


const KomitentiView = React.memo((props) => {
  const userContext = useContext(UserContext);

  const {id} = useParams();

  const path =
      "prijevoznici/" + userContext.subjectId + "/komitenti/";
  const dc = new dataController(model, path);
  const recordId = parseInt(id, 10);

  return (
      <Fragment>
        <PrijevozniciPage>
          <Box m={2}>
            <KomitentForm dc={dc} recordId={recordId} mode="update" form="default"/>
          </Box>
        </PrijevozniciPage>
      </Fragment>
  );
})

export default KomitentiView;
