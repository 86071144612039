import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

//Material UI
import Toolbar from "@material-ui/core/Toolbar";
import ImageIcon from "@material-ui/icons/Image";
import Box from "@material-ui/core/Box";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";

//Custom components
import FormContent from "Components/FormContent";
import withFormController from "Components/withFormController";
import DraggableDialog from "UI/Dialog/DraggableDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogBody from "UI/Dialog/DialogBody";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import DialogActionButtonUpload from "UI/Dialog/ActionButtons/DialogActionButtonUpload";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import DialogContext from "UI/DialogContext/DialogContext";

function ImageUploadDialog(props) {
  const snackbarContext = useContext(SnackbarContext);
  const loaderContext = useContext(LoaderContext);
  const dialogContext = useContext(DialogContext);
  const { t } = useTranslation();

  const { dc, record, validation, mode, baseRecordId } = props; //HOC withFormController
  const { doClear } = props; //HOC withFormController
  const { onFieldChange, onClose } = props; //HOC withFormController
  const { service } = props;

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
    if (value && value.length === 0) {
      doClear();
    }
  };

  const handleInsert = () => {
    const img = record.image[0];
    const data = {
      file_name: img.name,
      file_size: img.size,
      mime_type: img.type,
      base64: img.content,
      width: img.width,
      height: img.height
    };
    loaderContext.toggleLoading(true);
    service.createImage(baseRecordId, data)
      .then((resp) => {
        if (resp.success) {
          if (onClose) {
            onClose({dataChanged: true, action: "insert"});
          }
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  return (
    <DraggableDialog open={true} maxWidth="md" onClose={onClose}>
      <DialogHeader>
        <Toolbar variant="regular" disableGutters>
          <DialogAvatar ariaLabel="image" icon={<ImageIcon />} />
          <Typography>{t("vozilaImages.image")}</Typography>
          <DialogToolbarFillContent />
          <DialogToolbarButtonClose onClick={onClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <FormContent
            record={record}
            validation={validation}
            onFieldChange={handleFieldChange}
            fields={dc.getFields(["image"])}
            mode={mode}
            fieldNames={["image"]}
            column={1}
          />
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonUpload onClick={() => handleInsert()} variant="contained" />
      </DialogActions>
    </DraggableDialog>
  );
}

export default withFormController(ImageUploadDialog);
