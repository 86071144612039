import googleApi from "Lib/googleApi";
import moment from "moment";

import polyline from "@mapbox/polyline";

export const googleService = {
  route: route
};

function route(origin, destination, via, avoidTolls = false) {
  const googleApiInstance = new googleApi();

  // let url = "https://maps.googleapis.com/maps/api/directions/json?" +
  // "mode=driving" +
  // "&origin=place_id%3A" + origin.value +
  // "&destination=place_id%3A" + destination.value

  // if (Array.isArray(via) && via.length > 0) {
  //   url += "&waypoints=" + Array.map(x => "place_id%3A" + via.value).join("%7C")
  // }

  // if (avoidTolls) {
  //   url += "&avoid=tolls"
  // }

  return googleApiInstance.CallDirections(origin, destination, via, avoidTolls).then((response) => {
    if (response.status === "OK") {
      return _parseRoutes(response.routes);
    } else {
      return { success: false };
    }
  });
}

function _parseRoutes(routes) {
  if (Array.isArray(routes)) {
    const route = routes[0];

    let duration = 0;
    let length = 0;
    let routePoints = [];

    if (Array.isArray(route.legs)) {
      route.legs.forEach((leg) => {
        duration += leg.duration.value;
        length += leg.distance.value;

        if (Array.isArray(leg.steps)) {
          leg.steps.forEach((step) => {
            routePoints = routePoints.concat(
              polyline.decode(step.polyline.points).map((x) => {
                return { lat: x[0], lng: x[1] };
              })
            );
          });
        }
      });
    }

    const time = moment().startOf("day").seconds(duration);
    const secondsInDay = 24 * 60 * 60;

    const timeFormatted =
      duration < secondsInDay
        ? time.format("H") + " h " + time.format("m") + " min"
        : Math.floor(duration / secondsInDay) + " d " + time.format("H") + " h " + time.format("m") + " min";

    return {
      success: true,
      data: {
        duration: { value: duration, label: timeFormatted },
        length: { value: length, label: Math.round(length / 1000).toString() + " km" },
        routes: [
          {
            id: route.summary,
            points: routePoints
          }
        ]
      }
    };
  } else {
    return {
      success: false
    };
  }
}
