import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
//Custom components
import withRedirect from "Components/withRedirect";
import dataController from "Lib/dataController";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import UserContext from "Components/UserContext/UserContext";
import DialogContext from "UI/DialogContext/DialogContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import model from "Models/poslovi";
import modelPonude from "Models/ponuda";
import modelUpiti from "Models/upiti";
import PonudaForm11 from "Views/Prijevoznici/Ponude/PonudaForm11";
import VoznjaForm20 from "Views/Prijevoznici/Voznje/VoznjaForms/VoznjaForm20";
import VoznjaForm21 from "Views/Prijevoznici/Voznje/VoznjaForms/VoznjaForm21";
import VoznjaForm22 from "Views/Prijevoznici/Voznje/VoznjaForms/VoznjaForm22";
import VoznjaForm23 from "Views/Prijevoznici/Voznje/VoznjaForms/VoznjaForm23";
import VoznjaForm24 from "Views/Prijevoznici/Voznje/VoznjaForms/VoznjaForm24";
import VoznjaForm25 from "Views/Prijevoznici/Voznje/VoznjaForms/VoznjaForm25";
import VoznjaForm26 from "Views/Prijevoznici/Voznje/VoznjaForms/VoznjaForm26";
import VoznjaForm29 from "Views/Prijevoznici/Voznje/VoznjaForms/VoznjaForm29";
import confirmDialog from "Lib/confirmDialog";

import PlatformskiPosaoForm24 from "Views/Prijevoznici/Voznje/VoznjaForms/PlatformskiPosaoForm24";
import PlatformskiPosaoForm25 from "Views/Prijevoznici/Voznje/VoznjaForms/PlatformskiPosaoForm25";
import PlatformskiPosaoForm26 from "Views/Prijevoznici/Voznje/VoznjaForms/PlatformskiPosaoForm26";
import PlatformskiPosaoForm29 from "Views/Prijevoznici/Voznje/VoznjaForms/PlatformskiPosaoForm29";
import VoznjaDialog from "./VoznjaDialog";
import { posloviService } from "Services/posloviService";

function VoznjaForm(props) {
  const { t } = useTranslation();
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);
  const userContext = useContext(UserContext);
  const dialogContext = useContext(DialogContext);

  const [formInfo, setFormInfo] = useState({
    component: undefined, form: undefined, record: undefined, dc: undefined, isStorno: undefined, isPanic: undefined
  });
  const [upitId, setUpitId] = useState(null);
  const [DCUpiti, setDCUpiti] = useState(null);

  const dc = new dataController(model);
  const dcPonude = new dataController(modelPonude);

  const { id } = props;
  const recordId = parseInt(id, 10);
  const { redirect } = props;
  1;

  const fTipVoznje = "tip_voznje_id";
  const fUpitId = "upit_id";
  const fUpitDirekniId = "upiti_direktni_id";

  const getFormInfo = (status, tipVoznjeId) => {
    switch (status) {
      case 11:
        return { component: PonudaForm11, form: "status11", dc: dcPonude };
      case 20:
        return { component: VoznjaForm20, form: "status20", dc: dc };
      case 21:
        return { component: VoznjaForm21, form: "status21", dc: dc };
      case 22:
        return { component: VoznjaForm22, form: "status22", dc: dc };
      case 23:
        return { component: VoznjaForm23, form: "status23", dc: dc };
      case 24:
        if (tipVoznjeId === 50) {
          return { component: VoznjaForm24, form: "status24", dc: dc };
        } else if (tipVoznjeId === 20) {
          return { component: PlatformskiPosaoForm24, form: "PPosaoStatus24", dc: dc };
        } else {
          return null;
        }
      case 25:
        if (tipVoznjeId === 50) {
          return { component: VoznjaForm25, form: "status25", dc: dc };
        } else if (tipVoznjeId === 20) {
          return { component: PlatformskiPosaoForm25, form: "PPosaoStatus25", dc: dc };
        } else {
          return null;
        }
      case 26:
        if (tipVoznjeId === 50) {
          return { component: VoznjaForm26, form: "status26", dc: dc };
        } else if (tipVoznjeId === 20) {
          return { component: PlatformskiPosaoForm26, form: "PPosaoStatus26", dc: dc };
        } else {
          return null;
        }
      case 29:
        if (tipVoznjeId === 50) {
          return { component: VoznjaForm29, form: "status29", dc: dc };
        } else if (tipVoznjeId === 20) {
          return { component: PlatformskiPosaoForm29, form: "PPosaoStatus29", dc: dc };
        } else {
          return null;
        }
    }
  };

  const refreshRecord = () => {
    if (userContext.subjectId) {
      loaderContext.toggleLoading(true);
      dc.GetDataSingle(recordId)
        .then((resp) => {
          if (resp.success) {
            const record = resp.data;
            const status = record.posao_state_id;
            const storno = record.storno;
            const isPanic = record.panic;
            const tipVoznjeId = record[fTipVoznje];
            if (status === 11) {
              const isDirektanUpit = record[fUpitDirekniId] !== null;
              setUpitId(isDirektanUpit ? record[fUpitDirekniId] : record[fUpitId]);
              setDCUpiti(new dataController(modelUpiti, isDirektanUpit ? "direktni-upiti" : "burza/upiti"));
            }
            const fi = getFormInfo(status, tipVoznjeId);
            setFormInfo({ ...fi, record: record, isStorno: storno, isPanic: isPanic });
          }
        })
        .catch((result) => {
          snackbarContext.showNotification(result.error, "error");
        })
        .finally(() => {
          loaderContext.toggleLoading(false);
        });
    }
  };

  const handleCopy = (record) => {
    openNewVoznjaDialog(record);
  };

  const openNewVoznjaDialog = (record) => {
    dialogContext.showDialog(VoznjaDialog, {
      dc: dc,
      mode: "insert",
      form: "internalPosao",
      onClose: handleDialogClose,
      subModelDefaultValues: {
        prijevoznik_id: userContext.subjectId
      },
      defaultValues: {
        posao_state_id: 20
        , from_ts: record.from_ts
        , to_ts: record.to_ts
        , from_loc: record.from_loc
        , to_loc: record.to_loc
        , ruta: record.ruta
        , relacija_opis_skraceni: record.relacija_opis_skraceni
        , kupac_id: record.kupac_id
        , kupac_kontakt: record.kupac_kontakt
        , narucitelj_id: record.narucitelj_id
        , narucitelj_kontakt: record.narucitelj_kontakt
        , vozilo_id: record.vozilo_id
        , vozac_id: record.vozac_id
        , kooperant_id: record.kooperant_id
        , kooperant_napomena: record.kooperant_napomena
        , vozilo_broj_putnika: record.vozilo_broj_putnika
        , vozilo_broj_vodica: record.vozilo_broj_vodica
        , napomena_interna: record.napomena_interna
        , stajalista: record.stajalista
        , trajanje_voznje: record.trajanje_voznje
        , neto_udaljenost_voznje: record.neto_udaljenost_voznje
        , udaljenost_povratka_vozila: record.udaljenost_povratka_vozila
      }
    });
  };

  const confirmStorniraj = () => {
    const title = t("confirm_voznje_status.storniraj.title");
    const text = t("confirm_voznje_status.storniraj.text");
    return confirmDialog(title, text, "warning", t("cdialogs.confirm"), t("buttons.cancel"))
  }

  const handleStorniraj = (id) => {
    confirmStorniraj().then((result) => {
      if(result.confirmed) {
        posloviService.stornirajInterniPosao(id).then((resp) => {
          if (resp.success) {
            snackbarContext.showNotification("notifications.storniraj", "success");
            redirect("/p/voznje");
          }
        }).catch((result) => {
          snackbarContext.showNotification(result.error, "error");
        });
      }
    })
  };

  const handleStornirajCopy = (id, record) => {
    confirmStorniraj().then((result) => {
      if(result.confirmed) {
        posloviService.stornirajInterniPosao(id).then((resp) => {
          if (resp.success) {
            snackbarContext.showNotification("notifications.storniraj", "success");
            setFormInfo(prevState => {
              return {
                ...prevState,
                isStorno: true
              };
            });
            openNewVoznjaDialog(record);
          } else {
            snackbarContext.showNotification(resp.error.message, "error");
          }
        }).catch((err) => {
          console.error(err);
        });
      }
    })
  };

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecord();
    }
  };

  useEffect(() => {
    refreshRecord();
  }, [userContext.subjectId, recordId]);

  useEffect(() => {
    if(formInfo.record) {
      if(recordId && formInfo.record.id !== recordId) {
        setFormInfo({
          component: undefined, form: undefined, record: undefined, dc: undefined, isStorno: undefined, isPanic: undefined
        })
        refreshRecord()
      }
    }
  }, [id, formInfo.record])


  return formInfo.component
    ? React.createElement(formInfo.component, {
      dc: formInfo.dc,
      dcUpiti: DCUpiti,
      upitId: upitId,
      mode: formInfo.isStorno || formInfo.isPanic ? "view" : "update",
      form: formInfo.form,
      record: formInfo.record,
      isPanic: formInfo.isPanic,
      handleCopy: (record) => handleCopy(record),
      handleStorniraj: (id) => handleStorniraj(id),
      handleStornirajCopy: (id, record) => handleStornirajCopy(id, record),
      subModelDefaultValues: {
        prijevoznik_id: userContext.subjectId
      },
      handleRefreshForm: refreshRecord
    })
    : null;
}

export default withRedirect(VoznjaForm);
