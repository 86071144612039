import React, { useEffect, useState, useContext } from 'react';

// Custom Components
import { komunikatorService } from "Services/komunikatorService";
import { sifrarnici } from "Lib/sifrarnici";
import ChatContent from "UI/Chat/ChatContent";
import KomunikatorContext from "Components/KomunikatorContext/KomunikatorContext";

function KomunikatorChatMessages(props) {
	const { channel, height } = props;
	const KC = useContext(KomunikatorContext);

	const getOlderMessages = () => {
		KC.getOlderMessages(channel.id)
	}

	useEffect(() => {
		KC.getMessages(channel.id)
	}, [channel.id]);

	return (
		<ChatContent 
			channelUsers={channel.users}
			channelId={channel.id}
			isReadOnly={Boolean(channel.panic && channel.channel_type_id === sifrarnici.channel_types.main)}
			height={height} 
			tab={true}
			// messages={KC.messagesPerConversation[channel.id]}
			messages={KC[channel.id]}
			getOlderMessages={KC.EOM[channel.id] ? () => {} : getOlderMessages}
		/>
	);
}

export default KomunikatorChatMessages;