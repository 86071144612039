import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import numbro from "numbro";

//Material UI
import Toolbar from "@material-ui/core/Toolbar";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Box from "@material-ui/core/Box";
import DialogActions from "@material-ui/core/DialogActions";

//Custom Components
import withFormController from "Components/withFormController";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogActionButtonAdd from "UI/Dialog/ActionButtons/DialogActionButtonAdd";
import DialogActionButtonSave from "UI/Dialog/ActionButtons/DialogActionButtonSave";
import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItemDelete from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemDelete";
import GridContainer from "UI/Grid/GridContainer";
import FormContent from "Components/FormContent";
import DynamicDialog from "UI/Dialog/DynamicDialog";
import EuroConversionComment from "Components/EuroConversionComment";

function FakturaStavkaDialog(props) {
  const { t } = useTranslation();

  const { dc, mode, record, validation, fields, subModels } = props; //HOC withFormController
  const { onFieldChange, onClose, doValidate } = props; //HOC withFormController

  const fIznosNeto = "iznos_neto";
  const fPdvStopa = "pdv_stopa";
  const fPdvIznos = "pdv_iznos";
  const fIznosBruto = "iznos_bruto";

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      onFieldChange(value, source);

      switch (source) {
        case fIznosNeto:
          calculateTotals(value, record[fPdvStopa]);
          break;
        case fPdvStopa:
          calculateTotals(record[fIznosNeto], value);
          break;
      }
    }
  };

  const calculateTotals = (neto_iznos, pdv_stopa) => {
    //input values
    const netoIznos = neto_iznos ? numbro(neto_iznos).value() : null;
    const pdvStopa = pdv_stopa ? (pdv_stopa.hasOwnProperty("value") ? pdv_stopa.value : pdv_stopa) : null;

    if (netoIznos !== null && pdvStopa !== null) {
      //calculated values
      const pdvIznos = netoIznos * (pdvStopa / 100);
      const iznosBruto = netoIznos + pdvIznos;

      //chnage
      onFieldChange(pdvIznos, fPdvIznos);
      onFieldChange(iznosBruto, fIznosBruto);
    } else {
      //clear
      onFieldChange(null, fPdvIznos);
      onFieldChange(null, fIznosBruto);
    }
  };

  const handleDelete = () => {
    close({dataChanged: true, action: "delete", id: record.id});
  };

  const handleUpdate = () => {
    const isValid = doValidate();
    if (isValid) {
      close({dataChanged: true, action: "update", id: record.id, record: record})
    }
  };

  const handleInsert = () => {
    const isValid = doValidate();
    if (isValid) {
      close({dataChanged: true, action: "insert", record: record})
    }
  };

  const handleClose = (evt) => {
    close({dataChanged: false});
  };

  const close = (result) => {
    if (result.dataChanged) {
      onClose(result);
    } else {
      onClose({ dataChanged: false });
    }
  };

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: handleFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels
  };

  return (
    <DynamicDialog onClose={handleClose} maxWidth={"md"}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogAvatar ariaLabel="" icon={<MonetizationOnIcon />} />
          <DialogToolbarHeading>
            {mode == "insert" ? t("titles.faktura_stavka_new") : t("titles.faktura_stavka")}
          </DialogToolbarHeading>
          <DialogToolbarFillContent />
          {mode === "update" ? (
            <DialogToolbarMenuButton>
              <DialogToolbarMenuItemDelete onClick={handleDelete} />
            </DialogToolbarMenuButton>
          ) : null}
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer>
            <FormContent
              fieldNames={["opis", fIznosNeto, fPdvStopa, fPdvIznos, fIznosBruto]}
              {...commonProps}
              columns={2}
            />
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <EuroConversionComment />
        <DialogToolbarFillContent />
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
        {mode === "insert" ? <DialogActionButtonAdd variant="contained" onClick={handleInsert} /> : null}
        {mode === "update" ? <DialogActionButtonSave variant="contained" onClick={handleUpdate} /> : null}
      </DialogActions>
    </DynamicDialog>
  );
}

export default withFormController(FakturaStavkaDialog);
