import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { upitService } from "Services/upitService";
import moment from "moment";

//Material-UI Core Components
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";

//Material-UI Icons
import CheckIcon from "@material-ui/icons/Check";

//Custom Components
import withFormController from "Components/withFormController";
import DynamicDialog from "UI/Dialog/DynamicDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogActionButton from "UI/Dialog/DialogActionButton";
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import FormContent from "Components/FormContent";
import GridContainer from "UI/Grid/GridContainer";
import DialogActions from "@material-ui/core/DialogActions";
import withRedirect from "Components/withRedirect";
import EuroConversionComment from "Components/EuroConversionComment";

function PonudaJavneInformacijeDialog(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();

  const [ponuda, setPonuda] = useState({});

  const { upitId, ponudaId, locked, onClose, isAdmin } = props;
  const { validation, fields, subModels, mode } = props; //HOC withFormController
  const { onFieldChange } = props; //HOC withFormController

  const handleClose = () => {
    if (onClose) {
      onClose({ confirmed: false });
    }
  };

  const acceptPonuda = () => {
    upitService
      .acceptPonuda(upitId, ponudaId)
      .then((resp) => {
        if (resp.success) {
          snackbarContext.showNotification("notifications.accepted_ponuda", "success");
          if (onClose) {
            onClose({ confirmed: true });
          }
        }
      }).catch((result) => {
      snackbarContext.showNotification(result.error, "error");
    });
  };

  useEffect(() => {
    upitService.getQuoteForUpit(upitId, ponudaId).then((resp) => {
      if (resp.success) {
        setPonuda(resp.data);
      }
    }).catch((result) => {
      snackbarContext.showNotification(result.error, "error");
    });
  }, []);

  const now = moment.utc();
  const rok_potvrda = ponuda && ponuda.rok_potvrda ? moment.utc(ponuda.rok_potvrda) : null;
  const active = rok_potvrda && rok_potvrda.isAfter(now);

  return (
    <DynamicDialog onClose={handleClose} maxWidth={"md"}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogAvatar ariaLabel="ponuda" icon_word="local_offer" />
          <DialogToolbarHeading>{t("titles.ponuda")}</DialogToolbarHeading>
          <DialogToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer spacing={1}>
            <FormContent
              record={ponuda}
              validation={validation}
              onFieldChange={onFieldChange}
              fields={fields}
              mode={mode}
              subModels={subModels}
              fieldNames={["iznos_s_cbc_provizijom", "rok_potvrda", "relacija_opis_skraceni", "napomena", "vozilo_naziv", "vozilo_opis"]}
              columns={2}
            />
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <EuroConversionComment />
        <DialogToolbarFillContent />
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
        {locked === false && !isAdmin ? (
          <DialogActionButton variant="contained" onClick={acceptPonuda} startIcon={<CheckIcon />} disabled={!active}>
            {t("buttons.choose_offer")}
          </DialogActionButton>
        ) : null}
      </DialogActions>
    </DynamicDialog>
  );
}

export default withRedirect(withFormController(PonudaJavneInformacijeDialog));
