import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//Custom components
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import ModelTable from "UI/Table/ModelTable";
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import dataController from "Lib/dataController";
import model from "Models/upiti";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import { FormTabPanel } from "UI/Form";
import withRedirect from "Components/withRedirect";

function TabPanelPosebnePonudeUpiti(props) {
  const { t } = useTranslation();
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const loaderContext = useContext(LoaderContext);
  const [records, setRecords] = useState([]);

  const { currentTab, tabIndex } = props;
  const { recordId, isUgovoren } = props;
  const { redirect } = props;

  const dc = new dataController(model, `posebne-ponude/${recordId}/upiti`);

  const openUpitDialog = (id) => {
    redirect(`/p/direktni-upiti/${id}`)
  }

  const handleRowAction = function(action, id) {
    if (action === "view") {
      openUpitDialog(id);
    }
  };

  const allowRowAction = function(action, id) {
    return true;
  };

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData().then((resp) => {
      if (resp.success) {
        setRecords(resp.data ? resp.data : []);
      }
    }).catch((result) => {
      snackbarContext.showNotification(result.error, "error");
    }).finally(() => {
      loaderContext.toggleLoading(false);
    });
  };

  useEffect(() => {
    if (currentTab === tabIndex) {
      refreshRecords();
    }
  }, [currentTab]);

  return (
    <FormTabPanel value={currentTab} index={tabIndex}>
      <GridContainer>
        <GridItem md={12} sm={12}>
          <ModelTable
            records={records}
            dc={dc}
            viewName={"posebne_ponude"}
            allowExport={false}
            allowFilter={false}
            allowAdd={false}
            allowSelection="none"
            onRowAction={handleRowAction}
            allowRowAction={allowRowAction}
            onDoubleClick={(id) => handleRowAction("view", id)}
          />
        </GridItem>
      </GridContainer>
    </FormTabPanel>
  );
}

export default withRedirect(TabPanelPosebnePonudeUpiti);
