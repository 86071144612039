import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import numbro from "numbro";

//Material-UI Core components
import useTheme from "@material-ui/core/styles/useTheme";
import Toolbar from "@material-ui/core/Toolbar";
import StarsIcon from "@material-ui/icons/Stars";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import SendIcon from "@material-ui/icons/Send";
import { DialogActions } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

//Custom Components
import DynamicDialog from "UI/Dialog/DynamicDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarCreationDetails from "UI/Dialog/DialogToolbarCreationDetails";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import { FormTab, FormTabs } from "UI/Form";
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import withFormController from "Components/withFormController";
import TabPanelDetalji from "Views/Prijevoznici/PosebnePonude/Tabs/TabPanelDetalji";
import TabPanelPosebniCjenik from "Views/Prijevoznici/PosebnePonude/Tabs/TabPanelPosebniCjenik";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogActionButton from "UI/Dialog/DialogActionButton";
import upitModel from "Models/upiti";
import dataController from "Lib/dataController";
import TabPanelFinancijskaKalkulacija from "./Tabs/TabPanelFinancijskaKalkulacija";
import TabPanelPosebnePonudeUpiti from "./Tabs/TabPanelPosebnePonudeUpiti";
import { deprecationHandler } from "moment";
import EuroConversionComment from "Components/EuroConversionComment";

function PosebnaPonudaKruznaDialog(props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);

  const [currentTab, setCurrentTab] = useState(1);

  const { record, mode, validation, fields, subModels, dc } = props; //HOC withFormController
  const { doInsert, doValidate, doDelete, doClose } = props; //HOC withFormController
  const { onFieldChange, onClose } = props; //HOC withFormController

  const upitDC = new dataController(upitModel);

  const fPricePerDistance = "price_per_distance";

  const fPriceForTransfer = "price_for_transfer";
  const fPriceForDay = "price_for_day";
  const fPriceForHalfDay = "price_for_half_day";
  const fDistanceForDay = "distance_for_day";
  const fDistanceForHalfDay = "distance_for_half_day";
  const fDistanceForTransfer = "distance_for_transfer";
  const fDurationForDay = "duration_for_day";
  const fDurationForHalfDay = "duration_for_half_day";
  const fDurationForTransfer = "duration_for_transfer";

  const fCBCProvizijaDay = "cbc_provizija_day";
  const fCBCProvizijaHalfDay = "cbc_provizija_half_day";
  const fCBCProvizijaTransfer = "cbc_provizija_transfer";

  record.round_trip = true;

  const handleTabChange = (evt, value) => {
    setCurrentTab(value);
  };

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      onFieldChange(value, source);

      switch (source) {
        case fPricePerDistance:
          calculateDayTotals(value, record[fDistanceForDay]);
          break;
        case fDistanceForDay:
          calculateDayTotals(record[fPricePerDistance], value);
          break;
        case fPriceForTransfer:
          claculateTransferTotals(value);
          break;
        case fPriceForHalfDay:
          calculateHalfDayTotals(value);
          break;
      }
    }
  };

  const claculateTransferTotals = (price) => {
    //input value
    const priceTransfer = numbro(price).value();
    //other constant values
    const cbcProvizijaPerc = record && record.cbc_provizija_perc ? record.cbc_provizija_perc : 0;
    //calculated value
    const cbcProvizijaTransfer = priceTransfer ? priceTransfer * (1 + (cbcProvizijaPerc / 100)) : null;
    onFieldChange(cbcProvizijaTransfer, fCBCProvizijaTransfer);
  };

  const calculateHalfDayTotals = (price) => {
    //input value
    const priceTransfer = numbro(price).value();
    //other constant values
    const cbcProvizijaPerc = record && record.cbc_provizija_perc ? record.cbc_provizija_perc : 0;
    //calculated value
    const cbcProvizijaHalfDay = priceTransfer ? priceTransfer * (1 + (cbcProvizijaPerc / 100)) : null;
    onFieldChange(cbcProvizijaHalfDay, fCBCProvizijaHalfDay);
  };


  const calculateDayTotals = (price_per_distance, distance) => {
    //input values
    const akcijskaCijena = numbro(price_per_distance).value();
    const distancePerDay = distance;

    //other constant values
    const cbcProvizijaPerc = record && record.cbc_provizija_perc ? record.cbc_provizija_perc : 0;

    //calculated values
    const pricePerDay = (akcijskaCijena && distancePerDay) ? akcijskaCijena * distancePerDay : null;
    const cbcProvizijaDay = pricePerDay ? pricePerDay * (1 + (cbcProvizijaPerc / 100)) : null;

    onFieldChange(pricePerDay, fPriceForDay);
    onFieldChange(cbcProvizijaDay, fCBCProvizijaDay);
  };

  const handleInsert = (evt) => {
    if (doInsert) {
      if (doValidate) {
        const validated = doValidate();
        if (validated) {
          const preparedRecord = dc.prepareSendingData(record);
          dc.InsertRecord(preparedRecord, "posebne-ponude/kruzna")
            .then((result) => {
              if (result.success) {
                close({ dataChanged: true, action: "insert", id: result.id });
                snackbarContext.showNotification("insert", "success");
              }
            })
            .catch((resp) => {
              snackbarContext.showNotification(resp.error, "error");
            });
        }
      }
    }
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const close = (result) => {
    if (result.dataChanged) {
      onClose({ dataChanged: true, action: result.action, id: result.id });
    }

    dialogContext.hideDialog();
  };

  const handleContinue = (evt) => {
    setCurrentTab((prevState) => prevState + 1);
  };

  const commonProps = {
    currentTab: currentTab,
    record: record,
    validation: validation,
    onFieldChange: handleFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels
  };

  return (
    <DynamicDialog onClose={handleClose}>
      <DialogHeader color={theme.palette.custom && theme.palette.custom.pp}>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogAvatar ariaLabel="posebna-ponuda" icon={<StarsIcon />} />
          <DialogToolbarHeading>{t("titles.posebna_ponuda_new_kruzna")}</DialogToolbarHeading>
          <DialogToolbarFillContent />
          <DialogToolbarCreationDetails record={record} />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <FormTabs value={currentTab} onChange={handleTabChange}>
          <FormTab
            value={1}
            label={t("posebna_ponuda.tabs.detalji")}
            icon={<ViewModuleIcon />}
            validation={validation}
            fields={["vozilo_id", "from_ts", "to_ts", "from_loc", "from_loc_radius", "price_per_distance"]}
          />
          <FormTab
            value={2}
            label={t("posebna_ponuda.tabs.cjenik")}
            icon={<MonetizationOnIcon />}
            validation={validation}
            fields={[
              "price_for_transfer",
              "distance_for_transfer",
              "duration_for_transfer",
              "price_for_half_day",
              "distance_for_half_day",
              "duration_for_half_day",
              "price_for_day",
              "distance_for_day",
              "duration_for_day"
            ]}
          />
        </FormTabs>
        <TabPanelDetalji tabIndex={1} {...commonProps} />
        <TabPanelPosebniCjenik tabIndex={2} {...commonProps} />
        <TabPanelFinancijskaKalkulacija tabIndex={3} {...commonProps} recordId={record.id} dialog={true} kruzni={true} />
        {mode === "view" && !isAgency ? (
          <TabPanelPosebnePonudeUpiti tabIndex={4} {...commonProps} recordId={record.id} />
        ) : null}
      </DialogBody>
      <DialogActions>
        <EuroConversionComment />
        <DialogToolbarFillContent />
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
        {currentTab === 2 ? (
          <DialogActionButton variant="contained" onClick={handleInsert} startIcon={<SendIcon />}>
            {t("buttons.publish")}
          </DialogActionButton>
        ) : (
          <DialogActionButton variant="contained" onClick={handleContinue} startIcon={<ArrowForwardIcon />}>
            {t("buttons.continue")}
          </DialogActionButton>
        )}
      </DialogActions>
    </DynamicDialog>
  );
}

export default withFormController(PosebnaPonudaKruznaDialog);
