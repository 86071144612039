import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";

//Custom Components
import withFormController from "Components/withFormController";
import DynamicDialog from "UI/Dialog/DynamicDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItemDelete from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemDelete";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogActionButtonAdd from "UI/Dialog/ActionButtons/DialogActionButtonAdd";
import DialogActionButtonSave from "UI/Dialog/ActionButtons/DialogActionButtonSave";
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import FormContent from "Components/FormContent";
import GridContainer from "UI/Grid/GridContainer";
import { posloviService } from "Services/posloviService";
import { ponudeService } from "Services/ponudeService";

function PojedinacnaVoznjeDialog(props) {
  const { t } = useTranslation();

  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);

  const [service, setService] = useState(null);

  const {
    dc,
    record,
    validation,
    fields,
    subModels,
    mode,
    form,
    posaoId,
    external,
    ponuda
  } = props; //HOC withFormController
  const { doClose, doValidate } = props; //HOC withFormController
  const { onFieldChange, onClose } = props; //HOC withFormController

  useEffect(() => {
    setService(ponuda ? ponudeService : posloviService);
  }, [])

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    } else {
      close({ dataChanged: false });
    }
  };

  const handleInsert = (evt) => {
    if (doValidate) {
      const isValid = doValidate();
      if (isValid) {
        const preparedRecord = dc.prepareSendingData(record);
        service.createPojedinacneVoznje(posaoId, preparedRecord).then((resp) => {
          if (resp.success) {
            close({ dataChanged: true, action: "insert" });
            snackbarContext.showNotification("insert", "success");
          }
        }).catch((result) => {
          snackbarContext.showNotification(result.error, "error");
        });
      }
    }
  };

  const handleUpdate = (evt) => {
    const preparedRecord = dc.prepareSendingData(record);
    service.updatePojedinacneVoznje(posaoId, record['id'], preparedRecord).then((resp) => {
      if (resp.success) {
        close({ dataChanged: true, action: "update" });
        snackbarContext.showNotification("update", "success");
      }
    }).catch((result) => {
      snackbarContext.showNotification(result.error, "error");
    });
  };

  const handleDelete = () => {
    service.deletePojedinacneVoznje(posaoId, record['id']).then((resp) => {
      if (resp.success) {
        close({ dataChanged: true, action: "delete" });
        snackbarContext.showNotification("delete", "success");
      } else if (resp.error) {
        snackbarContext.showNotification(resp.error.message, "error");
      }
    }).catch((err) => {
      console.error(err);
    });
  };

  const close = (result) => {
    if (result.dataChanged) {
      onClose({ dataChanged: true, action: result.action });
    }

    dialogContext.hideDialog(external ? 2 : 1);
  };

  const commonFormProps = {
    dc: dc,
    record: record,
    validation: validation,
    onFieldChange: handleFieldChange,
    form: form,
    fields: fields,
    mode: mode,
    subModels: subModels,
  };

  return (
    <DynamicDialog onClose={handleClose} maxWidth={external ? "sm": "md"}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogAvatar icon={<AirportShuttleIcon />} />
          <DialogToolbarHeading>
            {t("titles.pojedinacna_voznja")}
          </DialogToolbarHeading>
          <DialogToolbarFillContent />
          {mode === "update" ? (
            <DialogToolbarMenuButton>
              <DialogToolbarMenuItemDelete onClick={handleDelete} />
            </DialogToolbarMenuButton>
          ) : null}
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer>
            <FormContent
              fieldNames={["opis"]}
              columns={1}
              {...commonFormProps}
            />
            <FormContent
              fieldNames={["ts_from", "ts_to", "vozilo_id", "vozac_1_id", "vozac_2_id", "vozac_3_id","neto_km","neto_min", "kooperant_id"]}
              columns={2}
              {...commonFormProps}
            />
            <FormContent
              fieldNames={["napomena"]}
              columns={1}
              {...commonFormProps}
            />
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
        {mode === "insert" ? (
            <DialogActionButtonAdd variant="contained" onClick={handleInsert} />
        ) : mode === "update" ? (
            <DialogActionButtonSave variant="contained" onClick={handleUpdate} />
        ) : null}
      </DialogActions>
    </DynamicDialog>
  );
}

export default withFormController(PojedinacnaVoznjeDialog);
