import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

//Custom Components
import dataController from "Lib/dataController";
import model from "Models/upiti";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import UpitForm10 from "Views/Upiti/UpitForm10";
import UpitForm11 from "Views/Upiti/UpitForm11";
import UpitForm13 from "Views/Upiti/UpitForm13";
import UpitForm16 from "Views/Upiti/UpitForm16";
import UpitForm19 from "Views/Upiti/UpitForm19";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import { upitStatusesLost, upitStatusesOK } from "Lib/statuses";

function UpitForm(props) {
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);
  const [formInfo, setFormInfo] = useState({
    component: undefined, form: undefined, record: undefined, isPanic: undefined, statuses: undefined
  });

  const { recordId } = props;

  const dc = new dataController(model);

  const getFormComponent = (recordStatus) => {
    switch (recordStatus) {
      case 10:
        return { component: UpitForm10, form: "status10", statuses: upitStatusesOK };
      case 11:
        return { component: UpitForm11, form: "status11", statuses: upitStatusesOK };
      case 13:
      case 14:
      case 15:
        return { component: UpitForm13, form: "status13", statuses: upitStatusesOK };
      case 16:
        return { component: UpitForm16, form: "status13", statuses: upitStatusesOK };
      case 18:
        return { component: UpitForm13, form: "status13", statuses: upitStatusesLost };
      case 19:
        return { component: UpitForm19, form: "status13", statuses: upitStatusesOK };
      default:
        return { component: UpitForm13, form: "status13", statuses: upitStatusesOK };
    }
  };

  const refreshRecord = () => {
    loaderContext.toggleLoading(true);
    dc.GetDataSingle(recordId).then((resp) => {
      if (resp.success) {
        const record = resp.data;
        const status = record.upit_state_id;
        const panic = record.panic;
        const markNew = record.mark_new;
        if (markNew === true) {
          markRead();
        }
        const form = getFormComponent(status);
        setFormInfo({ ...form, record: record, isPanic: panic });
      }
    }).catch((result) => {
      snackbarContext.showNotification(result.error, "error");
    }).finally(() => {
      loaderContext.toggleLoading(false);
    });
  };

  useEffect(() => {
    refreshRecord();
  }, []);

  useEffect(() => {
    refreshRecord();
  }, [recordId])

  const markRead = () => {
    dc.MarkReadRecord(recordId)
    .then((resp) => {
      //do nothing
    })
    .catch((result) => {
      //also do nothing
    })
  }


  return formInfo.component
    ? React.createElement(formInfo.component, {
      dc: dc,
      mode: formInfo.isPanic ? "view" : "update",
      form: formInfo.form,
      record: formInfo.record,
      recordId: formInfo.record.id,
      isPanic: formInfo.isPanic,
      statuses: formInfo.statuses,
      refreshRecord: refreshRecord
    })
    : null;
}

UpitForm.propTypes = {
  recordId: PropTypes.number
};

export default UpitForm;
