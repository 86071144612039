import createReport from "docx-templates";
import moment from "moment";
import numbro from "numbro";
import { formats } from "Lib/formats";

import api from "Lib/api";
import { loadWordTemplate, saveDataToFile } from "Lib/downloadTemplate";

const handlePrint = (record, t) => {
  const apiInstance = new api();
  const recordId = record.id;
  const path = `poslovi/${recordId}/ugovor`;
  apiInstance.Call(path, "get").then((resp) => {
    const data = resp.data;
    const wordTemplatePath = getWordTemplatePath();
    const fillFunction = getFillFunction(t);

    loadWordTemplate(wordTemplatePath, fillFunction(data));
  });
};

const getWordTemplatePath = () => {
  return "/templates/ugovor_template.docx";
};

const getFillFunction = (t) => {
  return (record) =>
    function (error, content) {
      if (error) {
        console.warn(error);
        throw error;
      }

      fillReport(t, content, record);
    }.bind(this);
};

const fillReport = (t, content, record) => {
  const report = createReport({
    template: content,
    data: {
      datum_ugovora: record.datum_ugovora ? moment(record.datum_ugovora).format(formats.date) : ""
      , nar_naziv: record.nar_naziv
      , nar_adr_full: record.nar_adr_full
      , nar_oib: record.nar_oib
      , nar_vat: record.nar_vat
      , pr_naziv: record.pr_naziv
      , pr_adr_full: record.pr_adr_full
      , pr_adr_grad: record.pr_adr_grad
      , pr_oib: record.pr_oib
      , pr_vat: record.pr_vat
      , from_datum: record.from_ts ? moment(record.from_ts).local().format(formats.date) : ""
      , to_datum: record.to_ts ? moment(record.to_ts).local().format(formats.date) : ""
      , from_loc: record.from_loc_name
      , loc_stajalista: ""
      , to_loc: record.to_loc_name
      , ugovorna_relacija: record.relacija_opis_skraceni
      , iznos: record.iznos ? "(" + numbro(record.iznos).format({ thousandSeparated: true, mantissa: 2 }) +  " €) " : ""
    }
  });
  report.then((data) => {
    let filename = `ugovor_${record.id}.docx`;

    saveDataToFile(data, filename, "application/vnd.openxmlformats-officedocument.wordprocessingml.document");
  });
};

export const ugovorPrint = {
  handlePrint
};
