import React, { useContext, Fragment } from "react";
import PropTypes from "prop-types";

//Custom Components
import { GridContainer } from "UI/Grid";
import FormContent from "Components/FormContent";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FormButton from "UI/Form/FormButton";
import Typography from "@material-ui/core/Typography";
import UserContext from "Components/UserContext/UserContext";

export default function OsnovniKomitentiForm(props) {

    const { recordId, form, record, validation, onFieldChange, fields, mode, subModels } = props;


  const commonProps = {
    recordId: recordId,
    record: record,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels,
    form: form
  };

  return (
    <Fragment>
      <GridContainer>
        <FormContent
          {...commonProps}
          fieldNames={["naziv", "adr_ulica", "tag", "adr_grad", "oib", "adr_pbr", "vat", "adr_drzava"]}
          columns={2}
        />
        <FormContent
          {...commonProps}
          fieldNames={["f_kupac", "f_dobavljac"]}
        />
      </GridContainer>
      <FormContent
        {...commonProps}
        fieldNames={["napomena"]}
        columns={1}
      />
    </Fragment>
  );
}
