import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Icons
import RefreshIcon from "@material-ui/icons/Refresh";

//Custom Components
import TableButton from "UI/Table/TableButton";

function TableButtonRefresh(props) {
  const { t } = useTranslation();

  return (
    <TableButton variant="contained" allow="always" {...props}>
      <RefreshIcon />
    </TableButton>
  );

  // return (
  //   <TableButton variant="contained" allow="always" startIcon={<RefreshIcon />} {...props}>
  //     {t("buttons.refresh")}
  //   </TableButton>
  // );
}

export default TableButtonRefresh;
