import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

//Custom Components
import dataController from "Lib/dataController";
import model from "Models/posebnePonude";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import UserContext from "Components/UserContext/UserContext";
import PosebnaPonudaTransferFormPublic from "Views/Prijevoznici/PosebnePonude/PosebnaPonudaTransferFormPublic";
import PosebnaPonudaKruzniFormPublic from "Views/Prijevoznici/PosebnePonude/PosebnaPonudaKruzniFormPublic";

function PosebnaPonudaFormPublic(props) {
  const loaderContext = useContext(LoaderContext);
  const userContext = useContext(UserContext);

  const [form, setForm] = useState({ component: undefined, formName: undefined, record: undefined });

  const { id } = props;

  const dc = new dataController(model);

  const handleFormComponent = (round_trip, record) => {
    if (round_trip) {
      setForm({ component: PosebnaPonudaKruzniFormPublic, formName: "agencijeKruzni", record: record });
    } else {
      setForm({ component: PosebnaPonudaTransferFormPublic, formName: "agencijeTransfer", record: record });
    }
  };

  const retrieveRecord = () => {
    loaderContext.toggleLoading(true);
    dc.GetDataSingle(id)
      .then((resp) => {
        if (resp.success) {
          const record = resp.data;
          handleFormComponent(record.round_trip, record);
        }
      }).catch((err) => {
      console.error(err);
    })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  useEffect(() => {
    retrieveRecord();
  }, []);

  return form.component
    ? React.createElement(form.component, {
      dc: dc,
      mode: "view",
      form: form.formName,
      record: form.record,
      subModelDefaultValues: {
        prijevoznik_id: userContext.subjectId
      }
    })
    : null;
}

PosebnaPonudaFormPublic.propTypes = {
  recordId: PropTypes.number
};

export default PosebnaPonudaFormPublic;
