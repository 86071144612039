export default {
    title: "Potvrda Rezervacije",
    exportFileName: "potvrda_rezervacije",
    apiPath: "potvrda-rezervacije/",
    forms: {
        default: {
            fields: ["cbc_avans_iznos", "cbc_iban", "cbc_model", "cbc_poziv_na_broj", "datum_potvrde", "from_loc_name", "from_ts", "to_loc_name",
            "to_ts", "narucitelj_naziv", "ponuda_avans_iznos", "ponuda_avans_rok_uplata", "ponuda_iznos", "ponuda_napomena", "vozilo_name",
            "vozilo_opis", "prijevoznik_name", "stajalista_names", "upit_broj_putnika", "upit_cijena_hrk", "upit_opis",
            "upit_tip_vozila_name", "ponuda_vozilo_opis"]
        }
    },
    fields: [
        {
          title: "Id",
          source: "id",
          type: "numeric",
          idattr: true
        },
        {
          title: "CBC avans iznos",
          source: "cbc_avans_iznos",
          type: "currency",
          ttoken: "potvrda_rezervacije.cbc_avans_iznos",
          validation: {
            maxValue: 99999999
          }
        },
        {
          title: "CBC iban",
          source: "cbc_iban",
          type: "numeric",
          ttoken: "potvrda_rezervacije.cbc_iban"
        },
        {
          title: "CBC model",
          source: "cbc_model",
          type: "text",
          ttoken: "potvrda_rezervacije.cbc_model"
        },
        {
          title: "CBC poziv na broj",
          source: "cbc_poziv_na_broj",
          type: "numeric",
          ttoken: "potvrda_rezervacije.cbc_poziv_na_broj"
        },
        {
          title: "Datum Potvrde",
          source: "datum_potvrde",
          type: "datetime",
          ttoken: "potvrda_rezervacije.datum_potvrde"
        },
        {
          title: "From Loc",
          source: "from_loc_name",
          type: "text",
          ttoken: "upit.from_loc"
        },
        {
          title: "From Date",
          source: "from_ts",
          type: "datetime",
          ttoken: "upit.from_ts"
        },
        {
          title: "To Loc",
          source: "to_loc_name",
          type: "text",
          ttoken: "upit.to_loc"
        },
        {
          title: "To Date",
          source: "to_ts",
          type: "datetime",
          ttoken: "upit.to_ts"
        },
        {
          title: "Narucitejl naziv",
          source: "narucitelj_naziv",
          type: "text",
          ttoken: "common.agencija"
        },
        {
          title: "Ponuda avans iznos",
          source: "ponuda_avans_iznos",
          type: "currency",
          ttoken: "potvrda_rezervacije.ponuda_avans_iznos",
          validation: {
            maxValue: 99999999
          }
        },
        {
          title: "Ponuda avans rok uplate",
          source: "ponuda_avans_rok_uplata",
          type: "date",
          ttoken: "potvrda_rezervacije.ponuda_avans_rok_uplata"
        },
        {
          title: "Ponuda iznos",
          source: "ponuda_iznos",
          type: "currency",
          ttoken: "potvrda_rezervacije.ponuda_iznos",
          validation: {
            maxValue: 99999999
          }
        },
        {
          title: "Napomena",
          source: "ponuda_napomena",
          type: "text",
          ttoken: "potvrda_rezervacije.ponuda_napomena"
        },
        {
          title: "Vozilo Id",
          source: "vozilo_id",
          type: "numeric"
        },
        {
          title: "Vozilo name",
          source: "vozilo_name",
          type: "text",
          ttoken: "common.vozilo"
        },
        {
          title: "Opis",
          source: "vozilo_opis",
          type: "multiline",
          ttoken: "ponuda.vozilo_opis"
        },
        {
          title: "Prijevoznik Id",
          source: "prijevoznik_id",
          type: "numeric"
        },
        {
          title: "Prijevoznik name",
          source: "prijevoznik_name",
          type: "text",
          ttoken: "common.prijevoznik"
        },
        {
          title: "Rezervacija Id",
          source: "rezervacija_id",
          type: "numeric"
        },
        {
          title: "Stajalista names",
          source: "stajalista_names",
          type: "text",
          ttoken: "trazilica.medjustajalista"
        },
        {
          title: "Upit broj putnika",
          source: "upit_broj_putnika",
          type: "numeric",
          ttoken: "upit.vozilo_broj_putnika"
        },
        {
          title: "Upit cijena eur",
          source: "upit_cijena_eur",
          type: "currency",
          currency: "€",
          ttoken: "upit.cijena_eur",
          validation: {
            maxValue: 99999999
          }
        },
        {
          title: "Upit cijena",
          source: "upit_cijena_hrk",
          type: "currency",
          ttoken: "upit.cijena",
          validation: {
            maxValue: 99999999
          }
        },
        {
          title: "Upit opis",
          source: "upit_opis",
          type: "text",
          ttoken: "upit.opis"
        },
        {
          title: "Upit tip vozila name",
          source: "upit_tip_vozila_name",
          type: "text"
        },
        {
          title: "Ponuda vozilo opis",
          source: "ponuda_vozilo_opis",
          type: "multiline",
          ttoken: "ponuda.vozilo_opis"
        },
        {
          title: "Posao Id",
          source: "posao_id",
          type: "numeric"
        }
    ]
}
