import React, { useContext } from 'react'

//Custom Components
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import DialogContext from "UI/DialogContext/DialogContext";

import { adminService } from "Services/adminService";
import AgenturaDialog from "./AgenturaDialog";

function useAgentura(props) {

  const snackbarContext = useContext(SnackbarContext);
  const dialogContext = useContext(DialogContext);

  const { dc, record, doRefresh } = props;

  const handleTakeOverAgentura = () => {

    dialogContext.showDialog(AgenturaDialog, {
      dc: dc,
      mode: "insert",
      form: "agentura",
      onClose: handleCancel,
      onSubmit: handleSubmit
    });
  }

  const handleSubmit = (agenturaRecord) => {
    const data = {
      agentura_napomena: agenturaRecord.agentura_napomena
    }

    adminService
      .takeAgentura(record.id, data)
      .then((resp) => {
        if (resp.success) {
          snackbarContext.showNotification("action", "success");
          if (doRefresh) {
            doRefresh();
          }
          dialogContext.hideDialog();
        }
      })
      .catch((resp) => {
        snackbarContext.showNotification(resp.error.message, "error");
      });

  }

  const handleCancel = () => {
    dialogContext.hideDialog();
  }

  return {
    handleTakeOverAgentura
  }

}

export default useAgentura