import React from "react";

//Custom Components
import AdminPage from "UI/AppPage/AdminPage";
import ResponsiveBox from "UI/Box/ResponsiveBox";
import FinancijeTable from "Views/Administration/Financije/FinancijeTable";

const FinancijePage = React.memo((props) => {
  return (
    <AdminPage>
      <ResponsiveBox fillHeight>
        <FinancijeTable />
      </ResponsiveBox>
    </AdminPage>
  );
});

export default FinancijePage;
