import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Components
import Switch from "@material-ui/core/Switch";

//Material-UI Icons
import MoneyIcon from "@material-ui/icons/Money";

//Custom Components
import CardActionButton from "UI/Card/CardActionButton";

function CardActionButtonShowFinCalc(props) {
  const { t } = useTranslation();

  const { showTab, onToggle, ...otherProps } = props;

  const isOn = showTab ? true : false;

  return (
    <CardActionButton
    onClick={onToggle}
    startIcon={<MoneyIcon />}
    endIcon={<Switch checked={isOn} size="small" color="secondary" />}
    aria-label="toggle"
    aria-controls="Toggle"
    variant={"outlined"}
    {...otherProps}
    >
      {isOn ? t("buttons.hide_fin_calc") : t("buttons.show_fin_calc")}
    </CardActionButton>
  );
}

export default CardActionButtonShowFinCalc;
