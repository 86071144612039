import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";

//Material-UI Icons
import InfoIcon from "@material-ui/icons/Info";
import PublicIcon from "@material-ui/icons/Public";

//CBC Icons
import RezervacijaIcon from "Icons/RezervacijaIcon";

//Custom Components
import withFormController from "Components/withFormController";
import DynamicDialog from "UI/Dialog/DynamicDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButton from "UI/Dialog/DialogToolbarButton";
import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItemDelete from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemDelete";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarCreationDetails from "UI/Dialog/DialogToolbarCreationDetails";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogActionButtonAdd from "UI/Dialog/ActionButtons/DialogActionButtonAdd";
import DialogActionButtonSave from "UI/Dialog/ActionButtons/DialogActionButtonSave";
import DialogActionButtonDelete from "UI/Dialog/ActionButtons/DialogActionButtonDelete";
import FormContent from "Components/FormContent";
import { GridContainer } from "UI/Grid";

import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

import { sifrarnici } from "Lib/sifrarnici";

const pickerList = ["vozac_id", "voznja_id", "vozilo_id"];
const pickerIntervals = {
  voznja_id: [20, 30],
  vozilo_id: [30, 40],
  vozac_id: [40, 50]
};

function RezervacijaDialog(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();

  const { dc, record, form, mode, validation, fields, subModels, recordId } = props; //HOC withFormController
  const { doInsert, doUpdate, doDelete, doClose } = props; //HOC withFormController
  const { onFieldChange, onClose } = props; //HOC withFormController

  const [pickerField, setPickerField] = useState(0);
  const initialPickerField = mode === "update" && record.tip_id ? Math.floor(record.tip_id / 10) : null;

  const handleFieldChange = (value, source) => {
    if (source === "tip_id") {
      const val = value && (value.value || value);
      // since value of this picker is either 0, [20, 30), [30, 40) or [40, 50) its corresponding picker equals `val // 10`
      setPickerField(Math.floor(val / 10));
    }
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleInsert = (evt) => {
    if (doInsert) {
      doInsert().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "insert" });
          snackbarContext.showNotification("insert", "success");
        }
      }).catch((result) => {
        if (result.success === false && result.validationPass === false) {
          //do nothing
        } else {
          snackbarContext.showNotification(result.error, "error");
        }
      });
    }
  };

  const handleUpdate = (evt) => {
    if (doUpdate) {
      doUpdate().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "update" });
          snackbarContext.showNotification("update", "success");
        }
      }).catch((result) => {
        if (result.success === false && result.validationPass === false) {
          //do nothing
        } else {
          snackbarContext.showNotification(result.error, "error");
        }
      });
    }
  };

  const handleDelete = (evt) => {
    if (doDelete) {
      doDelete().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "" });
        }
      }).catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      });
    }
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const close = (result) => {
    if (result.dataChanged) {
      onClose({ dataChanged: true, action: result.action });
    }
    dialogContext.hideDialog();
  };

  const getPickerField = (pFieldVal) => {
    const keys = Object.keys(sifrarnici.rezervacija_picker);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (sifrarnici.rezervacija_picker[key] === pFieldVal) {
        if (key == "none") {
          return 0;
        }
        return key;
      }
    }
    return 0;
  };

  const commonProps = {
    recordId: recordId,
    mode: mode,
    record: record,
    validation: validation,
    fields: fields,
    subModels: subModels,
    onFieldChange: handleFieldChange
  };

  const pField = getPickerField(pickerField || initialPickerField);
  pickerList.forEach((p) => {
    const currPicker = fields.find((f) => f.source === p);
    //return;
    if (currPicker.source === pField) {
      currPicker.validation = { required: true };
    } else {
      currPicker.validation = { required: false };
    }
  });

  return (
    <DynamicDialog onClose={handleClose} fixedHeight={false} maxWidth={"md"}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogAvatar ariaLabel="pregled_rezervacije" icon={<RezervacijaIcon />} />
          <DialogToolbarHeading>{t("titles.rezervacija")}</DialogToolbarHeading>
          <DialogToolbarFillContent />
          {mode === "update" ? (
            <DialogToolbarMenuButton>
              <DialogToolbarMenuItemDelete onClick={handleDelete} />
            </DialogToolbarMenuButton>
          ) : null}
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer>
            <FormContent {...commonProps} fieldNames={["alarm_ts", "alarm_active", "tip_id"]} />
            <FormContent {...commonProps} fieldNames={[pField ? pField : "empty_picker"]} />
            <FormContent {...commonProps} fieldNames={["ts_from", "ts_to", "opis"]} />
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
        {mode === "insert" ? (
          <DialogActionButtonAdd variant="contained" onClick={handleInsert} />
        ) : (
          <DialogActionButtonSave variant="contained" onClick={handleUpdate} />
        )}
      </DialogActions>
    </DynamicDialog>
  );
}

export default withFormController(RezervacijaDialog);
