import React from "react";
import PropTypes from "prop-types";

//Custom Controls
import TextControl from "Controls/TextControl";

function WholeNumberControl(props) {
  const { formMode, controlMode, field, value, onChange, label, helperText, validation, ...other } = props;

  const tryParseInt = (str, defaultValue) => {
    let ret = defaultValue;
    if (str !== null && str !== undefined) {
      if (str.length > 0 && !isNaN(str) && str.match(/^\d+$/) !== null) {
        ret = parseInt(str, 10);
      }
    }
    return ret;
  };

  const handleChange = (newValue, source) => {
    let valid = false;
    let num = null;
    if (newValue === null || newValue === undefined || newValue.length === 0) {
      valid = true;
    } else if (newValue.length > 0) {
      num = tryParseInt(newValue, null);
      if (num !== null) {
        valid = true;
      }
    }

    if (onChange) {
      if (valid) {
        onChange(num, source);
      } else {
        onChange(value, source);
      }
    }
  };

  return (
    <TextControl
      {...props}
      onChange={handleChange}
    />
  );
}

WholeNumberControl.defaultProps = {
  formMode: "form",
  controlMode: "edit"
};

WholeNumberControl.propTypes = {
  formMode: PropTypes.oneOf(["form", "table"]),
  controlMode: PropTypes.oneOf(["edit", "view"]),
  field: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  helperText: PropTypes.string,
  validation: PropTypes.object,
  onChange: PropTypes.func
};

export default WholeNumberControl;
