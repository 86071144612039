import React, { Fragment } from 'react'
import PropTypes from "prop-types";

//Material-UI Core Components
import makeStyles from '@material-ui/core/styles/makeStyles';
import withTheme from '@material-ui/core/styles/withTheme';
import DialogTitle from '@material-ui/core/DialogTitle';

const customDialogClasses = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  }
}));

function DialogHeader(props) {
  const { showStatus, recordStatus, theme, children, color, ...other} = props;
  const classes = customDialogClasses();

  return (
    <Fragment>
      <DialogTitle classes={classes} className="dialogTitle grabbable" style={{backgroundColor: color}} {...other}>
        {children}
      </DialogTitle>
    </Fragment>
  );
}

DialogHeader.propsTypes = {
  recordStatus: PropTypes.number
}

export default withTheme(DialogHeader);
