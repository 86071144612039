import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

//Material UI
import Toolbar from "@material-ui/core/Toolbar";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import CheckIcon from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Box from "@material-ui/core/Box";
import DialogActions from "@material-ui/core/DialogActions";
import DialogHeader from "UI/Dialog/DialogHeader";
import DynamicDialog from "UI/Dialog/DynamicDialog";

//Custom components
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogBody from "UI/Dialog/DialogBody";
import ConfirmButton from "UI/Buttons/ConfirmButton";
import CloseButton from "UI/Buttons/CloseButton";
import model from "Models/vozilaCjenici";
import dataController from "Lib/dataController";
import ModelTable from "UI/Table/ModelTable";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import UserContext from "Components/UserContext/UserContext";
import TableButton from "UI/Table/TableButton";
import DialogActionButton from "UI/Dialog/DialogActionButton";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import EuroConversionComment from 'Components/EuroConversionComment';

function CjenikTemplateDialog(props) {
  const {t} = useTranslation();
  const loaderContext = useContext(LoaderContext);
  const userContext = useContext(UserContext);
  const snackbarContext = useContext(SnackbarContext);

  const [records, setRecords] = useState([]);
  const [chosenTemplate, setChosenTemplate] = useState(null);

  const path = "prijevoznici/" + userContext.subjectId + "/predlosci-cjenika";
  const dc = new dataController(model);

  const {handleLoad, handleClose} = props;

  useEffect(() => {
    refreshRecords();
  }, [])

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData(path).then((resp) => {
      if (resp.success) {
        setRecords(resp.data)
      }
    }).catch((result) => {
      snackbarContext.showNotification(result.error, "error");
    }).finally(() => {
      loaderContext.toggleLoading(false);
    })
  };

  const handleChooseTemplate = (evt, sel) => {
    const selId = parseInt(sel[0], 10);
    const record = records[selId];
    dc.GetData(path + "/" + record.id).then((resp) => {
      if(resp.success) {
        setChosenTemplate(resp.data)
      }
    }).catch((result) => {
      snackbarContext.showNotification(result.error, "error");
    });
  }

  const handleConfirm = (evt) => {
    handleLoad(chosenTemplate)
    handleClose()
  }

  const tableProps = {
    records: records,
    dc: dc,
    viewName: "predlosci",
    allowExport: false,
    allowFilter: false,
    allowAdd: false,
    pagination: false,
    allowSelection: "one",
  }

  return (
      <DynamicDialog onClose={handleClose} maxWidth={"md"}>
        <DialogHeader>
          <Toolbar variant="dense" disableGutters={true}>
            <DialogAvatar ariaLabel="cjenik_templates" icon={<MonetizationOnIcon/>}/>
            <DialogToolbarHeading>{t("titles.predlosci_cjenika")}</DialogToolbarHeading>
            <DialogToolbarFillContent/>
            <DialogToolbarButtonClose onClick={handleClose}/>
          </Toolbar>
        </DialogHeader>
        <DialogBody>
          <Box m={2}>
            <ModelTable {...tableProps}>
              <TableButton
                  variant="outlined"
                  allow="one"
                  onClick={handleChooseTemplate}
                  startIcon={<CheckIcon/>}
              >
                {t("buttons.choose")}
              </TableButton>
            </ModelTable>
          </Box>
        </DialogBody>
        <DialogActions>
          <EuroConversionComment />
          <DialogToolbarFillContent />
          <CloseButton onClick={handleClose}/>
          <DialogActionButton
              variant="contained"
              startIcon={<DoneAllIcon />}
              onClick={handleConfirm}
              disabled={chosenTemplate === null}
          >
            {t('buttons.confirm')}
          </DialogActionButton>
        </DialogActions>
      </DynamicDialog>
  );
}

export default CjenikTemplateDialog;
