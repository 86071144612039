import { coreFields } from "./helpers/coreFields";
import { commonFields } from "./helpers/commonFields";
import { addressFields } from "./helpers/addressFields";
import { buttonFields } from "./helpers/buttonFields";
import { buttonFieldsFav } from "./helpers/buttonFieldsFav";
import { userFields } from "./helpers/userFields";

export default {
  title: "Prijevoznici",
  exportFileName: "CBC_APrijevoznici",
  apiPath: "prijevoznici/",
  forms: {
    default: {
      fields: ["naziv", "oib", "tel", "mobile", "email", "web", "adr_full", "desc_hr", "desc_en"]
    },
    insert: {
      fields: ["naziv", "oib", "vat", "adr_ulica", "adr_grad", "adr_pbr", "adr_drzava", "napomena"]
    },
    update: {
      fields: ["naziv", "oib", "vat", "adr_ulica", "adr_grad", "adr_pbr", "adr_drzava", "napomena"]
    },
    public: {
      fields: [
        { source: "adr_full", icon: "home" },
        { source: "email", icon: "email" },
        { source: "web", icon: "language"},
        { source: "tel", icon: "call" },
        { source: "mobile", icon: "smartphone" }
        ]
    }
  },
  listViews: {
    default: {
      fields: [
        "id",
        "naziv",
        "adr_full",
        "evaluation",
        "favorite_p",
        "view",
        "send_upit"
      ]
    },
    exportExcel: {
      fields: [
        { source: "naziv", width: 25 },
        { source: "adr_full", width: 100 }
      ]
    },
    odabir_prijevoznika: {
      fields: ["id", "label", "oib", "adr_full", "evaluation"]
    }
  },
  fields: [
    coreFields.id,
    buttonFieldsFav.favorite_p,
    {
      title: "Tip Poslovni Subjekt",
      source: "type_id",
      ttoken: "bs.type_id",
      type: "picker",
      subModel: {
        apiPath: "business-subject-types/",
        format: "{label}"
      }
    },
    {
      title: "Naziv",
      source: "naziv",
      ttoken: "bs.naziv",
      type: "text",
      filter: true,
      validation: {
        required: true,
        maxLength: 100
      }
    },
    commonFields.oib,
    commonFields.vat,
    commonFields.email,
    commonFields.tel,
    commonFields.mobile,
    addressFields.adr_ulica,
    addressFields.adr_grad,
    addressFields.adr_pbr,
    addressFields.adr_drzava,
    {
      title: "Napomena",
      source: "napomena",
      ttoken: "bs.napomena",
      type: "text",
      filter: true,
      validation: {
        required: false,
        maxLength: 2000
      }
    },
    coreFields.created_on,
    {
      title: "Label Odabir Prijevoznik",
      source: "label",
      ttoken: "common.prijevoznik",
      type: "text"
    },
    {
      title: "Evaluacija Odabir Prijevoznik",
      source: "evaluation",
      ttoken: "common.ocjena",
      type: "rating"
    },
    {
      title: "Address Odabir Prijevoznik",
      source: "adr_full",
      ttoken: "common.adresa",
      type: "text"
    },
    {
      title: "Web stranica",
      source: "web",
      ttoken: "bs.public_web",
      type: "text",
      width: "full",
      validation: {
        maxLength: 100
      }
    },
    {
      title: "Opis HR",
      source: "desc_hr",
      ttoken: "bs.public_desc_hr",
      type: "multiline",
      rows: 5,
      validation: {
        maxLength: 1000
      }
    },
    {
      title: "Opis EN",
      source: "desc_en",
      ttoken: "bs.public_desc_en",
      type: "multiline",
      rows: 5,
      validation: {
        maxLength: 1000
      }
    },
    buttonFields.view,
    buttonFields.send_upit
  ]
};
