import React, {Fragment} from 'react'
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import ToolTip from "@material-ui/core/Tooltip";

const customButtonStyles = makeStyles(theme => ({
  root: {
    //marginLeft: 10,
    marginRight: 0,
    // color: theme.palette.common.white,
    // "&:hover": {
    //   backgroundColor: theme.palette.secondary.dark
    // },
  }
}));


function HeaderButton(props) {
  const {t} = useTranslation();
  const {desc} = props;
  const classes = customButtonStyles();

  return (
      <Fragment>
        {desc ? (
            <ToolTip disableFocusListener placement="bottom" arrow title={t(`tooltips.header.${desc}`)}>
              <Button classes={classes} color="primary" size="small" {...props}>
                {props.children}
              </Button>
            </ToolTip>
        ) : (
            <Button classes={classes} color="primary" size="small" {...props}>
              {props.children}
            </Button>
        )}
      </Fragment>
  );
}

export default HeaderButton;
