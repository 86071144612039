import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function VozacIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M 9.6983897,1.1026524e-4 A 4.8820588,4.8820588 0 0 0 4.8820054,4.8821192 4.8820588,4.8820588 0 0 0 9.7640138,9.7641283 4.8820588,4.8820588 0 0 0 14.646023,4.8821192 4.8820588,4.8820588 0 0 0 9.7640138,1.1026524e-4 a 4.8820588,4.8820588 0 0 0 -0.065624,0 z M 9.7640174,12.206304 C 4.3693426,12.206304 0,14.390978 0,17.088313 v 2.439833 h 4.3570114 c -0.1647604,-2.78216 2.014486,-5.611158 4.9265403,-5.774973 1.2205983,-0.112236 2.4773213,0.196579 3.4968583,0.878901 1.69124,1.033302 2.504402,2.99825 2.390615,4.896072 h 4.357007 v -2.439833 c 0,-2.697335 -4.369342,-4.882009 -9.7640182,-4.882009 z m 0,2.085928 c -2.6806257,0 -4.8538841,2.173257 -4.8538841,4.853884 0,2.680627 2.1732584,4.853884 4.8538841,4.853884 2.6806266,0 4.8538846,-2.173257 4.8538846,-4.853884 0,-2.680627 -2.173258,-4.853884 -4.8538846,-4.853884 z m 0,1.214057 c 1.5796766,0 2.9132646,1.016288 3.4171726,2.42577 H 6.3468459 c 0.5039065,-1.409482 1.8374952,-2.42577 3.4171715,-2.42577 z m 0,3.032799 c 0.3352306,0 0.6070296,0.271799 0.6070296,0.607028 0,0.335231 -0.271799,0.607028 -0.6070296,0.607029 -0.3352292,0 -0.607028,-0.271798 -0.607028,-0.607029 0,-0.335229 0.2717988,-0.607028 0.607028,-0.607028 z m -3.6398269,0.607028 c 1.6579472,0 2.9998975,1.597544 3.0281118,3.57889 C 7.4364097,22.431035 6.1241905,20.94392 6.1241905,19.146116 Z m 7.2796535,0 c 0,1.797804 -1.312218,3.284919 -3.02811,3.57889 0.02821,-1.981346 1.370163,-3.57889 3.02811,-3.57889 z" />
    </SvgIcon>
  );
}

export default VozacIcon;
