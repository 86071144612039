import React, { useState } from "react"
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

//Custom Components
import PublicPage from "UI/PublicPage/PublicPage";
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import PasswordResetRequestForm from "Views/Auth/PasswordResetRequestForm";

import model from "Models/user";
import dataController from "Lib/dataController";

function PasswordResetRequestPage() {

  const [status, setStatus] = useState({completed: false, success: null});
  const { t } = useTranslation();

  const dc = new dataController(model);

  const handleCompleted = (result) => {
    if (result.success) {
      setStatus({
        completed: true,
        success: true
      })
    } else {
      setStatus({
        completed: true,
        success: false
      })
    }
  }

  return (
    <PublicPage>
      <Box m={2}>
        <Container maxWidth={"sm"}>
          { !status.completed ?
            <PasswordResetRequestForm dc={dc} form="password_reset_request" onCompleted={handleCompleted}/>
            : <Card>
              <CardContent>
              <GridContainer>
                  <GridItem xs={12}>
                    <Typography variant="h5" component="h3">
                      { status.success ? t("titles.password_reset_request_success") : t("titles.password_reset_request_fail")}
                    </Typography>
                  </GridItem>
                  <GridItem xs={12}>
                    <Typography variant="body1" component="span">
                      { status.success ? t("messages.password_reset_request_success") : t("messages.password_reset_request_fail")}
                    </Typography>
                  </GridItem>
                </GridContainer>

              </CardContent>
            </Card>
          }
        </Container>
      </Box>
    </PublicPage>
  )

}

export default PasswordResetRequestPage;
