import React from "react";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { euroExchangeRate } from "Lib/euro";

const EuroConversionComment = () => {
  const { t } = useTranslation();
  return (
    <Box p={1}>
      <Typography variant="caption">{t("euro.comment", { rate: euroExchangeRate })}</Typography>
    </Box>
  )
}

export default EuroConversionComment;
