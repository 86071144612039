import React from 'react';

//Custom components
import PrijevozniciPage from "UI/AppPage/PrijevozniciPage";
import KomunikatorSinglePage from "./KomunikatorSinglePage";

const PrijevozniciKomunikatorSinglePage = React.memo((props) => {

  return (
      <PrijevozniciPage>
        <KomunikatorSinglePage isOnUnseen={props.isOnUnseen}/>
      </PrijevozniciPage>
  );
})

export default PrijevozniciKomunikatorSinglePage;
