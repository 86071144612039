import React from 'react';
import { useTranslation } from "react-i18next";

// Material-UI Core Components
import { makeStyles } from "@material-ui/core/styles";
import {
	Divider
} from "@material-ui/core";


function ChatMessageSystem(props) {
	const { message } = props;
	const classes = useStyle();
	const { t } = useTranslation();

	const text = message && message.body ?
		(message.body.startsWith("###") ? 
			t("comm.channels.user_added", { user: message.body.slice(3) })
			: t(message.body))
		: "";

	return (
		<div className={classes.wrapper}>
			<div className={classes.msg}>
				{ text }
			</div>
			<Divider />
		</div>
	)
}

const useStyle = makeStyles(theme => ({
	wrapper: {
		width: "100%",
		margin: "16px 0",
		height: "12px"
	},
	msg: {
		// fontSize: "10px",
		textAlign: "center"
	}
}))

export default ChatMessageSystem;