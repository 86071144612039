import React from "react";
import { useTranslation } from "react-i18next";

// Material UI Core
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";
import VerticalAlignTopIcon from "@material-ui/icons/VerticalAlignTop";
import RemoveIcon from "@material-ui/icons/Remove";

// Custom Components
import GridContainer from "UI/Grid/GridContainer";
import { GridItem } from "UI/Grid";
import IconButton from "@material-ui/core/IconButton";

const customSwitchStyles = makeStyles(theme => ({
    root: {
      marginLeft: 10
    }
  })
);

function TrazilicaSortFilter(props) {
  const { t } = useTranslation();
  const { state, fieldIds, onToggleSort } = props;

  const classes = customSwitchStyles();

  return (
    <Card>
      <CardHeader title={t("trazilica.sort")} style={{ textAlign: "center", paddingBottom: 0 }} />
      <CardContent>
        <GridContainer direciton="column" justify="flex-start" align="flex-start" spacing={0}>
          {
            fieldIds.map((fId, i) => (
              <GridItem xs={12} md={12} key={i}>
                <FormControlLabel
                  style={{ marginLeft: "5px" }}
                  key={i}
                  id={fId}
                  control={state[fId] === "ascending" ? (
                      <IconButton className="nodrag" color="inherit" onClick={(evt) => onToggleSort(evt, fId, "descending")}>
                        <VerticalAlignTopIcon />
                      </IconButton>
                    ) :
                    state[fId] === "descending" ? (
                        <IconButton className="nodrag" color="inherit" onClick={(evt) => onToggleSort(evt, fId, null)}>
                          <VerticalAlignBottomIcon />
                        </IconButton>
                      ) :
                      (
                        <IconButton className="nodrag" color="inherit" onClick={(evt) => onToggleSort(evt, fId, "ascending")}>
                          <RemoveIcon />
                        </IconButton>
                      )}
                  label={t(`trazilica.sorts.${fId}`)}
                />
              </GridItem>
            ))
          }
        </GridContainer>
      </CardContent>
    </Card>
  );
}

export default TrazilicaSortFilter;
