import { handleResponse } from "Services/helpers/handleResponse";
import { standardHeadersWithAuth } from 'Services/helpers/standardHeadersWithAuth';
import { Role } from "Lib/role";

import api from "Lib/api";

export const komunikatorService = {
  getJobMembers
, getInternalMembers
, addMember
, getChannels
, getChannelData
, getJobs
, getFile
, setPanicMode
, lockChannel
, getAllUnread
, markAsRead
}

const apiFnc = new api();

function getJobMembers(jobId) {
	const path = `poslovi/${jobId}/users`;
	return apiFnc.Call(path, 'get');
}

function getInternalMembers(bsId) {
	const path = `business-subjects/${bsId}/my-bs-users`;
	return apiFnc.Call(path, 'get');
}

function addMember(id, channelId) {
	const path = 'communicator/channel/user';
	return apiFnc.Call(path, 'post', {
		user_id: id,
		channel_id: channelId
	});
}

function getChannels(id) {
	const suffix = parseInt(id, 10) === 0 ? 'type/98' : `posao/${id}`;
  const path = `communicator/channel/${suffix}`;
  return apiFnc.Call(path, 'get');
}

function getChannelData(id) {
	const path = `communicator/channel/${id}`;
	return apiFnc.Call(path, 'get');
}

function getJobs() {
	return apiFnc.Call('communicator/channel/jobchannels', 'get');
}

function getFile(uuid) {
  return apiFnc.Call(`communicator/files/${uuid}`, 'get')
}

function setPanicMode(channelId, record) {
	return apiFnc.Call(`communicator/channel/${channelId}/panic`, 'post', record);
}

function lockChannel(channelId, lock) {
	return apiFnc.Call(`communicator/channel/${channelId}/lock`, 'post', { lock: lock });
}

function markAsRead(messageIds) {
	return apiFnc.Call('communicator/channel/mark-as-read', 'post', { msg_ids: messageIds });
}

function getAllUnread() {
	return apiFnc.Call('communicator/channel/unread', 'get');	
}