import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Elements
import withTheme from "@material-ui/core/styles/withTheme";
import { grey } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";



function BreadCrumb(props) {
  const { length, theme, isFinished, isActive, onClick } = props;

  const { t } = useTranslation();

  const breadCrumbStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: (100 / length).toString() + "%",
    float: "left",
    background: isFinished ? theme.palette.secondary.light : isActive ? theme.palette.secondary.dark : grey[500],
    color: "#FFF"
  };

  return (
    <div className={"breadcrumb"} style={breadCrumbStyle} onClick={onClick}>
      {props.label ? (
        <Typography
          variant="caption"
          component="span"
          align="center"
          className="noselect"
        >
          {t(props.label)}
        </Typography>
      ) : (
        ""
      )}
    </div>
  );
}

function FormHeaderStatusBreadCrumbs(props) {
  const { theme, statuses, recordStatus, showLabels, onClick } = props;

  const statusLength = Array.isArray(statuses) ? statuses.length : 0;

  if (Array.isArray(statuses) && statusLength > 0) {
    return (
      <Fragment>
        {statuses.map((status, i) => (
          <BreadCrumb
            key={i}
            length={statusLength}
            theme={theme}
            isFinished={status.id < recordStatus}
            isActive={status.id === recordStatus}
            onClick={onClick}
            label={showLabels ? status.label : null}
          />
        ))}
      </Fragment>
    );
  } else {
    return null;
  }

}

export default withTheme(FormHeaderStatusBreadCrumbs);
