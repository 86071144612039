import React from "react";

//Custom Components
import AgencijePage from "UI/AppPage/AgencijePage";
import ResponsiveBox from "UI/Box/ResponsiveBox";
import AgencijePrijevozniciTable from "Views/Agencije/Prijevoznici/AgencijePrijevozniciTable";

const AgencijeEvaluacijaPage = React.memo((props) => {
  return (
    <AgencijePage>
      <ResponsiveBox fillHeight>
        <AgencijePrijevozniciTable />
      </ResponsiveBox>
    </AgencijePage>
  );
});

export default AgencijeEvaluacijaPage;
