import React, { Fragment } from 'react';
import moment from 'moment';

// Material-UI Icons
import CachedIcon from '@material-ui/icons/Cached';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ErrorIcon from '@material-ui/icons/Error';
import VisibilityIcon from '@material-ui/icons/Visibility';

// Material-UI Core Components
import { makeStyles } from "@material-ui/core/styles";

// Custom Components
import { sifrarnici } from "Lib/sifrarnici";


function ChatMessageLastRow(props) {
	const { body, time, deliveryType, owner } = props;
	const classes = statusStyle(owner)();

	const getState = (deliveryType) => {
		const commonProps = {
			style: {
				fontSize: "0.8rem"
			}
		}
		const { msg_delivery_types: dType } = sifrarnici
		switch (deliveryType) {
			case dType.queued:		return <CachedIcon {...commonProps} />;
			case dType.sent:		return <DoneIcon {...commonProps} />;
			case dType.delivered:	return <DoneAllIcon {...commonProps} />;
			case dType.seen:		return <VisibilityIcon {...commonProps} />;
			case dType.accepted: 	return null; // should't be here
			case dType.declined: 	return null; // should't be here
			case dType.deleted: 	return null; // should't be here
			default: 
				console.log(deliveryType, "Impossibruuu!");
				return null
		}	
	}
	
	return (
		<div >
			<div className={classes.display}>{body}</div>
			<div className={`${classes.status} ${classes.display}`}>
				<p className={classes.time}>
					{moment(time).format("HH:mm")}
				</p>
				{
					owner && deliveryType ?
					<div className={`${classes.display} ${classes.icon}`}>
						 {getState(deliveryType)}
					</div>
				 : null
				}
			</div>
		</div>
	);
}

/*
display: inline-block;
    padding: 0 3px;
    font-size: 10px;
*/

const statusStyle = owner => makeStyles((theme) => ({
	display: {
		display: "inline-block",
	},
	status: {
		padding: "4px",
		fontSize: "10px",
		minWidth: owner ? "50px" : "38px",
		height: "12px",
		position: "relative",
		float: "right"
	},
	time: {
		margin: 0,
		position: "absolute"
	},
	icon: {
		float: "right"
	}
}));

export default ChatMessageLastRow;