import { Permissions } from "Lib/permissions";

const menuAdministration = [
	{ path: "/admin", name: "Home", ttoken: "menu.home", icon: "home" },
  { path: "/admin/poslovni-subjekti", name: "Poslovni subjekti", ttoken: "menu.poslovni_subjekti", svgicon: "poslovni-subjekt" },
  { path: "/admin/users", name: "Users", ttoken: "menu.users", svgicon: "korisnik" },
  { path: "/admin/upiti", name: "Upiti", ttoken: "menu.admin_upiti", icon: "live_help" },
  { path: "/admin/voznje", name:"Ugovoreni voznje", ttoken: "menu.admin_voznje", svgicon: "voznja" },
  { path: "/admin/vozila", name:"Vozila", ttoken: "menu.admin_vozila", icon: "directions_bus" },
  { path: "/admin/komunikator", name:"", ttoken: "menu.komunikator", icon: "chat" },
  { divider: true },
  { path: "/admin/financije", name:"Financijsko praćenje", ttoken: "menu.financije", icon: "account_balance" },
  { path: "/admin/troskovi", name:"Troskovi", ttoken: "menu.troskovi", icon: "money", needPermission: [Permissions.AdminExternalCosts] }
  // { path: "/admin/ura", name: "ura", ttoken: "menu.ura", icon: "receipt" },
  // { path: "/admin/ira", name: "ira", ttoken: "menu.ira", icon: "receipt" }
];

export default menuAdministration;
