import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Icons
import DraftsIcon from "@material-ui/icons/Drafts";

//Custom components
import CardToolbarMenuItem from "UI/Card/CardToolbarMenuItem";

const CardToolbarMenuItemDraft = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return <CardToolbarMenuItem label={t("buttons.save_draft")} icon={<DraftsIcon />} {...props} ref={ref} />;
});

export default CardToolbarMenuItemDraft;
