import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

//Material UI
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import DialogActions from "@material-ui/core/DialogActions";

//Custom components
import DynamicDialog from "UI/Dialog/DynamicDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogActionButtonAdd from "UI/Dialog/ActionButtons/DialogActionButtonAdd";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogContext from "UI/DialogContext/DialogContext";
import GridContainer from "UI/Grid/GridContainer";

import FormContent from "Components/FormContent";
import withFormController from "Components/withFormController";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import UserContext from "Components/UserContext/UserContext";

function KomunikatorDialog(props) {
  const { id, title } = props;
  const { dc, record, validation, onFieldChange, fields, mode, subModels, form } = props;
  const { doInsert, doClose, onClose, doValidate } = props;
  const { t } = useTranslation();

  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const userContext = useContext(UserContext);

  const handleAdd = (evt) => {
    if (doValidate) {
      const isValid = doValidate();
      if (isValid) {
        const { record } = props;
        const { userId } = userContext;
        dc.InsertRecord(Object.assign(record, {
          user_ids: (record['job_users_internal'] || record['job_users']).concat(userId).map(u => u.value || u)
        }), "communicator/channel")
          .then((resp) => {
            if (resp.success) {
              close({ dataChanged: true });
            } else {
              // handle error
            }
          }).catch((result) => {
          snackbarContext.showNotification(result.error, "error");
        });
      }
    }
  }

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const close = (result) => {
    if (result.dataChanged) {
      onClose({ dataChanged: true, action: result.action });
    }
    dialogContext.hideDialog();
  };

  const commonProps = {
    recordId: id,
    record: record,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels
  };

  return (
      <DynamicDialog maxWidth={"sm"} onClose={handleClose}>
        <DialogHeader>
          <Toolbar variant="dense" disableGutters={true}>
            <DialogAvatar ariaLabel="" icon_word="group"/>
            <DialogToolbarHeading>
              {title}
            </DialogToolbarHeading>
            <DialogToolbarFillContent/>
            {/*<DialogToolbarMenuButton>*/}

            {/*</DialogToolbarMenuButton>*/}
            <DialogToolbarButtonClose onClick={handleClose}/>
          </Toolbar>
        </DialogHeader>
        <DialogBody>
          <Box m={2}>
            <GridContainer spacing={0} direction="column" justify="flex-start">
              <FormContent
                {...commonProps}
                fieldNames={dc.getFormFieldsNames(form)}
                columns={1}
              />
            </GridContainer>
          </Box>
        </DialogBody>
        <DialogActions>
          <DialogActionButtonClose variant="outlined" onClick={handleClose} />
          <DialogActionButtonAdd variant="contained" onClick={handleAdd} />
        </DialogActions>
      </DynamicDialog>
  );
}

export default withFormController(KomunikatorDialog);
