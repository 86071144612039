import createReport from "docx-templates";
import moment from "moment";
import { formats } from "Lib/formats";

import api from "Lib/api";
import { loadWordTemplate, saveDataToFile } from "Lib/downloadTemplate";

const handlePrint = (record, t) => {
  const apiInstance = new api();
  const recordId = record.id;
  const path = `poslovi/${recordId}/potvrda-usluge`;
  apiInstance.Call(path, "get").then((resp) => {
    const data = resp.data;
    const wordTemplatePath = getWordTemplatePath();
    const fillFunction = getFillFunction(t);

    loadWordTemplate(wordTemplatePath, fillFunction(data));
  });
};

const getWordTemplatePath = () => {
  return "/templates/potvrda_usluge_template.docx";
};

const getFillFunction = (t) => {
  return (record) =>
    function (error, content) {
      if (error) {
        console.warn(error);
        throw error;
      }

      fillReport(t, content, record);
    }.bind(this);
};

const fillReport = (t, content, record) => {
  const report = createReport({
    template: content,
    data: {
      nar_naziv: record.nar_naziv
      , nar_adr_full: record.nar_adr_full
      , nar_contact_tel: record.nar_contact_tel
      , nar_contact_email: record.nar_contact_email
      , vodic_ime_prezime: record.vodic_ime_prezime
      , pr_naziv: record.pr_naziv
      , pr_adr_full: record.pr_adr_full
      , pr_contact_tel: record.pr_contact_tel
      , pr_contact_email: record.pr_contact_email
      , vozilo_marka_tip: record.vozilo_marka_tip
      , vozilo_reg_oznaka: record.vozilo_reg_oznaka
      , vozilo_br_sjedala: record.vozilo_br_sjedala
      , vozac_podaci: record.vozac_podaci
      , vozilo_broj_putnika: record.vozilo_broj_putnika
      , from_datum: record.from_ts ? moment(record.from_ts).local().format(formats.date) : ""
      , from_time: record.from_ts ? moment(record.from_ts).local().format(formats.time) : ""
      , to_datum: record.to_ts ? moment(record.to_ts).local().format(formats.date) : ""
      , from_loc: record.from_loc_name
      , to_loc: record.to_loc_name
      , napomena: record.napomena
    }
  });
  report.then((data) => {
    let filename = `potvrdausluge_${record.id}.docx`;

    saveDataToFile(data, filename, "application/vnd.openxmlformats-officedocument.wordprocessingml.document");
  });
};

export const potvrdaUslugePrint = {
  handlePrint
};
