import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Icons
import PrintIcon from "@material-ui/icons/Print";

//Custom Components
import FormButton from "UI/Form/FormButton";

//Print handlers
import { ponudaPrint } from "Lib/print/ponudaPrint";
import { ugovorPrint } from "Lib/print/ugovorPrint";
import { potvrdaUslugePrint } from "Lib/print/potvrdaUslugePrint";
import { putniNalogPrint } from "Lib/print/putniNalogPrint";
import { fakturaPrint } from "Lib/print/fakturaPrint";
import { potvrdaRezervacijePrint } from "Lib/print/potvrdaRezervacijePrint";

function PrintButton(props) {
  const { t } = useTranslation();
  const { needPermission, text, disabled, target, record, dc, platformskiPosao, upitId } = props;

  const handlePrintWord = () => {
    switch (target) {
      case "ponuda":
        ponudaPrint.handlePrint(record, t);
        break;
      case "ugovor":
        ugovorPrint.handlePrint(record, t);
        break;
      case "potvrda_usluge":
        potvrdaUslugePrint.handlePrint(record, t);
        break;
      case "putni_nalog":
        putniNalogPrint.handlePrint(record, t);
        break;
      case "faktura":
        fakturaPrint.handlePrint(platformskiPosao, record, t);
        break;
      case "admin-faktura":
        fakturaPrint.handleAdminPrint(record, t);
        break;
      case "agencija-faktura":
        fakturaPrint.handleAgencijaPrint(record, t);
        break;
      case "potvrda_rezervacije":
        potvrdaRezervacijePrint.handlePrint(record, t);
        break;
      default:
        break;
    }
  };

  return (
    <FormButton
      variant="outlined"
      startIcon={<PrintIcon />}
      onClick={handlePrintWord}
      needPermission={needPermission}
      disabled={disabled}
      download="download"
    >
      {text}
    </FormButton>
  );
}

export default PrintButton;
