import React from 'react';
import { useTranslation } from "react-i18next";

//Material-UI
import AddIcon from "@material-ui/icons/Add";

//Custom Components
import FormButton from "../Form/FormButton";

function AddButton(props) {
  const {t} = useTranslation();
  const { label, needPermission, onClick, disabled} = props;

  return (
      <FormButton
          variant="contained"
          startIcon={<AddIcon/>}
          onClick={onClick}
          needPermission={needPermission}
          disabled={disabled}
      >
        { label ? label : t("buttons.add")}
      </FormButton>
  );
}

export default AddButton;
