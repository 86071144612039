import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import DialogActions from "@material-ui/core/DialogActions";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import ViewModuleIcon from "@material-ui/icons/ViewModule";

//Custom Components
import DynamicDialog from "UI/Dialog/DynamicDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButton from "UI/Dialog/DialogActionButton";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogContext from "UI/DialogContext/DialogContext";
import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItem from "UI/Dialog/DialogToolbarMenuItem";
import DialogActionButtonSave from "UI/Dialog/ActionButtons/DialogActionButtonSave";

// Context
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import UserContext from "Components/UserContext/UserContext";
import GridContainer from "UI/Grid/GridContainer";
import withFormController from "Components/withFormController";
import FormContent from "Components/FormContent";
import GridItem from "UI/Grid/GridItem";
import ModelTable from "UI/Table/ModelTable";
import modelVoznje from "Models/pojedinacneVoznje";
import dataController from "Lib/dataController";
import withRedirect from "Components/withRedirect";
import { posloviService } from "Services/posloviService";
import PojedinacnaVoznjaDialog from "./VoznjaFormTabDialogs/PojedinacnaVoznjaDialog";

function VoznjaSazetakDialog(props) {
  const dialogContext = useContext(DialogContext);
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);
  const userContext = useContext(UserContext);

  const [records, setRecords] = useState([]);

  const { t } = useTranslation();

  const dcVoznje = new dataController(modelVoznje);

  const { mode, dc, record, validation, fields, subModels } = props; //HOC withFormController
  const { doClose } = props; //HOC withFormController
  const { onFieldChange, onClose } = props; //HOC withFormController
  const { redirect, external, recordId } = props;

  const handleUpdate = (evt) => {
    const preparedRecord = dc.prepareSendingData(record);
    posloviService.updateInterniPosaoSazetak(record.id, preparedRecord).then((resp) => {
      if (resp.success) {
        snackbarContext.showNotification("notifications.updated", "success");
        close({ dataChanged: false });
        refreshRecords();
      }
    }).catch((result) => {
      snackbarContext.showNotification(result.error, "error");
    });
  };

  const handleView = (evt) => {
    redirect(`/p/voznje/${record.id}`);
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const handleRowAction = function (action, id) {
    if (action === "edit") {
      const record = records.find((x) => x.id === id);
      if (record) {
        dialogContext.showDialog(
          PojedinacnaVoznjaDialog,
          {
            dc: dcVoznje,
            mode: "update",
            form: "default",
            record: record,
            posaoId: recordId,
            onClose: handleSummaryDialogClose,
            subModels: subModels,
            external: external,
            subModelDefaultValues: {
              prijevoznik_id: userContext.subjectId
            }
          },
          2
        );
      }
    }
  };

  const handleSummaryDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords();
    }
    dialogContext.hideDialog(2);
  };

  const allowRowAction = function (action, id) {
    return true;
  };

  useEffect(() => {
    if (record["id"]) {
      refreshRecords();
    }
  }, [record]);

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    posloviService
      .getPojedinacneVoznje(record["id"])
      .then((resp) => {
        if (resp && resp.success) {
          setRecords(resp.data ? resp.data : []);
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const close = (result) => {
    if (result.dataChanged) {
      onClose({ dataChanged: true, action: result.action });
    }

    dialogContext.hideDialog();
  };

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels
  };

  return (
    <DynamicDialog onClose={handleClose} fixedHeight={false}>
      <DialogHeader recordStatus={record.upit_state_id}>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogAvatar ariaLabel="assessment" icon_word="assessment" />
          <DialogToolbarHeading header={t("titles.sazetak")} subheader={`ID: ${record["id"]}`} />
          <DialogToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer>
            <FormContent {...commonProps} fieldNames={["from_ts", "to_ts"]} columns={2} />
            <FormContent {...commonProps} fieldNames={["relacija_opis_skraceni"]} columns={1} />
            <FormContent {...commonProps} fieldNames={record["is_interni_posao"] ? ["kupac_id", "kooperant_id"] : ["narucitelj_id" , "kooperant_id"]} columns={2} />
            <FormContent {...commonProps} fieldNames={["vozilo_id", "vozac_id"]} columns={2} />
            <GridItem md={12} sm={12}>
              <ModelTable
                records={records}
                dc={dcVoznje}
                viewName={"default"}
                allowExport={false}
                allowFilter={false}
                allowAdd={false}
                allowSelection={"none"}
                pagination={false}
                search={false}
                subModelDefaultValues={{
                  prijevoznik_id: userContext.subjectId
                }}
                onRowAction={handleRowAction}
                allowRowAction={allowRowAction}
              />
            </GridItem>
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
        {mode === "update" ? <DialogActionButtonSave variant="contained" onClick={handleUpdate} /> : null}
        <DialogActionButton variant="contained" onClick={handleView} startIcon={<ViewModuleIcon />}>
          {t("buttons.details")}
        </DialogActionButton>
      </DialogActions>
    </DynamicDialog>
  );
}

export default withRedirect(withFormController(VoznjaSazetakDialog));
