import React from "react";

//Custom Components
import AdminPage from "UI/AppPage/AdminPage";
import ResponsiveBox from "UI/Box/ResponsiveBox";
import TroskoviTable from "Views/Administration/Troskovi/TroskoviTable";
import { Permissions } from "Lib/permissions";

const TroskoviPage = React.memo((props) => {
  return (
    <AdminPage needPermission={[Permissions.AdminExternalCosts]}>
      <ResponsiveBox fillHeight>
        <TroskoviTable />
      </ResponsiveBox>
    </AdminPage>
  );
});

export default TroskoviPage;
