import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

//Material UI
import Toolbar from "@material-ui/core/Toolbar";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Box from "@material-ui/core/Box";
import { DialogActions } from "@material-ui/core";

//Custom components
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import { vozilaService } from "Services/vozilaService";
import DynamicDialog from "UI/Dialog/DynamicDialog";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItemDelete from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemDelete";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogBody from "UI/Dialog/DialogBody";
import FormContent from "Components/FormContent";
import CloseButton from "UI/Buttons/CloseButton";
import AddButton from "UI/Buttons/AddButton";
import SaveButton from "UI/Buttons/SaveButton";
import withFormController from "Components/withFormController";
import GridContainer from "UI/Grid/GridContainer";

function AlokacijaCjenikaDialog(props) {
  const {t} = useTranslation();
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const [chosenCjenik, setChosenCjenik] = useState(null);

  const {dc, voziloId, start_date, end_date} = props;

  const {mode, record, validation, fields, subModels} = props; //HOC withFormController
  const {onFieldChange, doValidate, onClose} = props; //HOC withFormController

  const fDateTo = "end_date";
  const fDateFrom = "start_date";
  const fCjenikId = "cjenik_id";

  const close = (result) => {
    if (result && result.dataChanged) {
      onClose(result);
    }

    dialogContext.hideDialog();
  };

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      if (source === fCjenikId && value !== null) {
        const cjenikId = value.value;
        vozilaService.getCjenik(voziloId, cjenikId).then((resp) => {
          if (resp.success) {
            setChosenCjenik(resp.data);
          }
        }).catch((result) => {
          snackbarContext.showNotification(result.error, "error");
        });
      }
      onFieldChange(value, source);
    }
  };

  const handleInsert = (evt) => {
    if (doValidate) {
      const isValid = doValidate();
      if (isValid) {
        const preparedRecord = dc.prepareSendingData(record);
        vozilaService.createAllocatedCjenik(voziloId, preparedRecord).then((resp) => {
          if (resp.success) {
            close({dataChanged: true, action: "insert"});
            snackbarContext.showNotification("insert", "success");
          }
        }).catch((result) => {
          snackbarContext.showNotification(result.error, "error");
        });
      }
    }
  };

  const handleUpdate = (evt) => {
    if (doValidate) {
      const isValid = doValidate();
      if (isValid) {
        const preparedRecord = dc.prepareSendingData(record);
        vozilaService.updateAllocatedCijenik(voziloId, record["id"], preparedRecord).then((resp) => {
          if (resp.success) {
            close({dataChanged: true, action: "update"});
            snackbarContext.showNotification("update", "success");
          }
        }).catch((result) => {
          snackbarContext.showNotification(result.error, "error");
        });
      }
    }
  };

  const handleDelete = (evt) => {
    vozilaService.deleteAllocatedCijenik(voziloId, record["id"]).then((resp) => {
      if (resp.success) {
        close({dataChanged: true, action: "delete"});
        snackbarContext.showNotification("delete", "success");
      }
    }).catch((result) => {
      snackbarContext.showNotification(result.error, "error");
    });
  }

  const alokcijaFormProps = {
    record: record,
    validation: validation,
    onFieldChange: handleFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels,
    columns: 2
  }

  const cjenikFormProps = {
    record: chosenCjenik,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    mode: "simpleView",
    subModels: subModels,
    columns: 3
  }

  if (start_date && end_date) {
    record[fDateFrom] = start_date;
    record[fDateTo] = end_date;
  }

  useEffect(() => {
    if (mode === "update") {
      setChosenCjenik(record);
    }
  }, [])

  const title = mode == "insert" ? t("titles.allocate_cjenik") : `${t("titles.cjenik") + " ID:" + record.id}`;

  return (
      <DynamicDialog onClose={close} maxWidth="sm">
        <DialogHeader>
          <Toolbar variant="dense" disableGutters={true}>
            <DialogAvatar ariaLabel="" icon={<DateRangeIcon/>}/>
            <DialogToolbarHeading header={title} subheader={record.loc_name}>
            </DialogToolbarHeading>
            <DialogToolbarFillContent/>
            {mode === "update" || mode === "view" ? (
                <DialogToolbarMenuButton>
                  <DialogToolbarMenuItemDelete onClick={handleDelete}/>
                </DialogToolbarMenuButton>
            ) : null}
            <DialogToolbarButtonClose onClick={close}/>
          </Toolbar>
        </DialogHeader>
        <DialogBody>
          <Box m={2}>
            <GridContainer>
              <FormContent
                  {...alokcijaFormProps}
                  fieldNames={["cjenik_id", "start_date", "end_date"]}
              />
              {chosenCjenik ? (
                  <GridContainer direciton="column" alignItems="center">
                    <FormContent
                        {...cjenikFormProps}
                        fieldNames={[
                          "price_per_distance",
                          "loc_name"]}
                    />
                    <FormContent
                        title={t("cjenici.sections.kratkotrajni_najam")}
                        {...cjenikFormProps}
                        fieldNames={[
                          "price_for_transfer",
                          "distance_for_transfer",
                          "duration_for_transfer",]}
                    />
                    <FormContent
                        title={t("cjenici.sections.poludnevni_najam")}
                        {...cjenikFormProps}
                        fieldNames={[
                          "price_for_half_day",
                          "distance_for_half_day",
                          "duration_for_half_day"]}
                    />
                    <FormContent
                        title={t("cjenici.sections.cijelodnevni_najam")}
                        {...cjenikFormProps}
                        fieldNames={[
                          "price_for_day",
                          "distance_for_day",
                          "duration_for_day"]}
                    />
                  </GridContainer>
              ) : null}
            </GridContainer>
          </Box>
        </DialogBody>
        <DialogActions>
          <CloseButton onClick={close}/>
          {mode === "insert" ? <AddButton onClick={handleInsert}/> : null}
          {mode === "update" ? <SaveButton onClick={handleUpdate} text={t("buttons.save")}/> : null}
        </DialogActions>
      </DynamicDialog>
  );
}

export default withFormController(AlokacijaCjenikaDialog);
