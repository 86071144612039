import React from 'react';
import PropTypes from "prop-types";

import FormButton from "UI/Form/FormButton";
import { useTranslation } from "react-i18next";
import SaveIcon from "@material-ui/icons/Save";

function SaveButton(props) {
  const {t} = useTranslation();
  const { needPermission, onClick, text, disabled} = props;

  return (
      <FormButton
          variant="contained"
          startIcon={<SaveIcon/>}
          onClick={onClick}
          needPermission={needPermission}
          disabled={disabled}
      >
        {text}
      </FormButton>
  );
}

export default SaveButton;
