import React from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PrivateRoute from "Components/PrivateRoute";

import privateRoutes from "Routes/private";
import publicRoutes from "Routes/public";

//Custom Components
import ScrollToTop from "Components/ScrollToTop";
import UserProvider from "Components/UserContext/UserProvider";
import ThemeProvider from "Components/ThemeContext/ThemeProvider";
import SnackbarProvider from "UI/SnackbarContext/SnackbarProvider";
import SnackbarContainer from "UI/SnackbarContext/SnackbarContainer";
import DialogProvider from "UI/DialogContext/DialogProvider";
import DialogContainer from "UI/DialogContext/DialogContainer";
import LoaderProvider from "Components/LoaderContext/LoaderProvider";
import LoaderContainer from "Components/LoaderContext/LoaderContainer";
import KomunikatorProvider from "Components/KomunikatorContext/KomunikatorProvider";
import KomunikatorContainer from "Components/KomunikatorContext/KomunikatorContainer";

var history = createBrowserHistory();

class App extends React.Component {
  render() {
    return (
      <ThemeProvider>
        <UserProvider>
          <SnackbarProvider>
            <LoaderProvider>
              <DialogProvider>
                <KomunikatorProvider>
                  <BrowserRouter history={history} basename={process.env.REACT_APP_DEPLOYFOLDER}>
                    <ScrollToTop>
                      <Switch>
                        {publicRoutes.map((prop, key) => (
                          <Route path={prop.path} key={key} component={prop.component} />
                        ))}
                        {privateRoutes.map((prop, key) => (
                          <PrivateRoute path={prop.path} key={key} component={prop.component} />
                        ))}
                      </Switch>
                    </ScrollToTop>
                    <DialogContainer />
                  </BrowserRouter>
                  <KomunikatorContainer />
                </KomunikatorProvider>
              </DialogProvider>
              <LoaderContainer />
              <SnackbarContainer />
            </LoaderProvider>
          </SnackbarProvider>
        </UserProvider>
      </ThemeProvider>
    );
  }
}

export default App;
