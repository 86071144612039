import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import numbro from "numbro";

//Material-UI Core Components
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Toolbar from "@material-ui/core/Toolbar";

//Material-UI Icons
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import MoneyIcon from "@material-ui/icons/Money";
import AssignmentIcon from "@material-ui/icons/Assignment";

//Custom Components
import withFormController from "Components/withFormController";
import {
  CardActionButtonClose,
  CardActionButtonSend,
  CardActionButtonShowFinCalc,
  CardActionButtonShowVoznje,
  CardHeading,
  CardToolbarButtonAlarm,
  CardToolbarButtonBack,
  CardToolbarMenuButton,
  CardToolbarMenuItemDraft,
  CardToolbarMenuItemKorekcija
} from "UI/Card";
import { FormFillContent, FormHeading, FormTab, FormTabPanel, FormTabs } from "UI/Form";

import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import UserContext from "Components/UserContext/UserContext";
import DialogContext from "UI/DialogContext/DialogContext";
import PonudaAvatar from "UI/Form/Avatars/PonudaAvatar";
import GridContainer from "UI/Grid/GridContainer";
import FormContent from "Components/FormContent";

import { ponudeService } from "Services/ponudeService";
import TabPanelUpit from "Views/Ponude/Tabs/TabPanelUpit";
import TabPanelPojedinacneVoznje from "Views/Ponude/Tabs/TabPanelPojedinacneVoznje";
import TabPanelInternaKalkulacija from "Views/Ponude/Tabs/TabPanelInternaKalkulacija";
import PonudaKorekcijaProvizijeDialog from "Views/Ponude/PonudaKorekcijaProvizijeDialog";
import EuroConversionComment from "Components/EuroConversionComment";

function PonudaForm10(props) {
  const dialogContext = useContext(DialogContext);
  const userContext = useContext(UserContext);
  const snackbarContext = useContext(SnackbarContext);


  const history = useHistory();
  const { t } = useTranslation();
  const { handleSendOffer } = props;
  const { upitId, dcUpiti, dc, statuses } = props;
  const { posebnaPonuda } = props;
  const { record, validation, fields, subModels, mode, recordId } = props; //HOC withFormController
  const { onFieldChange, doValidate } = props; //HOC withFormController

  const identifier = dc.getSource();
  const [currentTab, setCurrentTab] = useState(1);

  const [showTabVoznje, setShowTabVoznje] = useState(userContext.getRecent("toggleTabVoznje", identifier, false));
  const [showTabFinCalc, setShowTabFinCalc] = useState(userContext.getRecent("toggleTabFinCalc", identifier, false));

  const recordActive = record && record.upit_active ? record.upit_active : false;

  const handleClickBack = () => {
    history.goBack();
  };

  const handleTabChange = (evt, value) => {
    if (value > tabIndexPonuda) {
      setCurrentTab(1);
    } else {
      setCurrentTab(value);
    }
  };

  const handleToggleTabVoznje = (evt) => {
    userContext.setRecent("toggleTabVoznje", dc.getSource(), !showTabVoznje);
    setShowTabVoznje(!showTabVoznje);
    if (currentTab > 0) {
      if (!showTabVoznje) {
        setCurrentTab(currentTab + 1);
      } else {
        setCurrentTab(currentTab - 1);
      }
    }
  };

  const handleToggleTabFinCalc = (evt) => {
    userContext.setRecent("toggleTabFinCalc", dc.getSource(), !showTabFinCalc);
    setShowTabFinCalc(!showTabFinCalc);
    if (currentTab > 0 && (currentTab !== 1 && showTabVoznje)) {
      if (!showTabFinCalc) {
        setCurrentTab(currentTab + 1);
      } else {
        setCurrentTab(currentTab - 1);
      }
    }
  };

  const saveDraft = () => {
    const preparedRecord = dc.prepareSendingData(record);
    ponudeService.updateDraft(recordId, preparedRecord).then((resp) => {
      if (resp.success) {
        snackbarContext.showNotification("notifications.updated_draft", "success");
      }
    }).catch((result) => {
      snackbarContext.showNotification(result.error, "error");
    });
  };

  const handleKorekcija = () => {
    dialogContext.showDialog(
      PonudaKorekcijaProvizijeDialog,
      {
        dc: dc,
        form: "update",
        mode: mode,
        recordId: recordId,
        doClose: handleCloseKorekcija,
        dialogContext: dialogContext
      }
    );
  };

  const handleCloseKorekcija = () => {
    dialogContext.hideDialog();
  };

  const sendQuote = () => {
    const isValid = doValidate();
    if (isValid) {
      const preparedRecord = dc.prepareSendingData(record);
      ponudeService
        .submit(recordId, preparedRecord)
        .then((resp) => {
          if (resp.success) {
            snackbarContext.showNotification("notifications.create_quote", "success");
            history.goBack();
          }
        }).catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      });
    }
  };

  const avansAllowed = userContext.subjectAllowAvans;

  const handleFieldChange = (value, source) => {
    const fCijenaBruto = "iznos_hrk";
    const fCBCProvizijaIznos = "cbc_provizija_iznos";
    const fCijenaSCBCProvizijom = "iznos_s_cbc_provizijom";

    if (source === fCijenaBruto) {
      const decValue = numbro(value);

      const provizija_iznos = decValue * (record && record.cbc_provizija_perc ? record.cbc_provizija_perc * 0.01 : 0);
      const cijena_s_provizijom = decValue + provizija_iznos;

      onFieldChange(provizija_iznos, fCBCProvizijaIznos);
      onFieldChange(cijena_s_provizijom, fCijenaSCBCProvizijom);
    }
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: handleFieldChange,
    fields: fields,
    mode: recordActive ? mode : "view",
    subModels: subModels,
    recordId: recordId,
    columns: 2
  };
  const tabIndexFinCalc = 1 + (showTabVoznje ? 1 : 0);
  const tabIndexPonuda = tabIndexFinCalc + (showTabFinCalc ? 1 : 0);

  const header = t("titles.ponuda");
  const subheader = t("ponuda.statuses.10");

  const recordStatus = record ? record.ponuda_state_id : 10;

  const tabPonudaFields = [
    "from_ts",
    "to_ts",
    "relacija_opis_skraceni",
    "broj_ponude",
    "datum_ponude",
    "rok_potvrda",
    "vozilo_id",
    "vozilo_opis",
    "napomena",
    "iznos_hrk",
    "cbc_provizija_perc",
    "cbc_provizija_iznos",
    "iznos_s_cbc_provizijom",
    "avans_iznos",
    "rok_avans_uplate"
  ];

  const basedOnPosebnaPonuda = record && record.posebna_ponuda_id;

  return (
    <Card>
      <CardHeading recordStatus={recordStatus} statuses={statuses} color={basedOnPosebnaPonuda ? "#D4AF37" : null}>
        <Toolbar variant="dense" disableGutters={true}>
          <PonudaAvatar />
          <FormHeading header={header} subheader={subheader} />
          <FormFillContent />
          <CardToolbarButtonAlarm record={record} type="ponudaskica" onFieldChange={onFieldChange} />
          <CardToolbarMenuButton>
            <CardToolbarMenuItemDraft onClick={saveDraft} />
            {/* <CardToolbarMenuItemKorekcija onClick={handleKorekcija} /> */}
          </CardToolbarMenuButton>
          <CardToolbarButtonBack onClick={handleClickBack} />
        </Toolbar>
      </CardHeading>
      <CardContent style={{ padding: 0 }}>
        <FormTabs value={currentTab} onChange={handleTabChange} identifier={dc.getSource()}>
          <FormTab id="upit" label={t("ponuda.tabs.upit")} icon={<LiveHelpIcon />} aria-label="upit" />
          {showTabVoznje ? (
            <FormTab id="pojedinacne-voznje" label={t("ponuda.tabs.voznje")} icon={<AirportShuttleIcon />} aria-label="pojedinacne_voznje" />
          ) : null}
          {showTabFinCalc ? (
            <FormTab id="interna-kalkulacija" label={t("ponuda.tabs.kalkulacija")} icon={<MoneyIcon />} aria-label="interna_kalkulacija" />
          ) : null}
          <FormTab
            id="ponuda"
            label={t("ponuda.tabs.ponuda")}
            icon={<AssignmentIcon />}
            aria-label="ponuda"
            fields={tabPonudaFields}
            validation={validation}
          />
        </FormTabs>
        <TabPanelUpit currentTab={currentTab} tabIndex={0} dc={dcUpiti} mode="view" recordId={upitId} form="ponuda_tab" />
        {showTabVoznje ? (
          <TabPanelPojedinacneVoznje
            currentTab={currentTab}
            tabIndex={1}
            record={record}
            recordId={recordId}
            subModels={subModels}
            validation={validation}
            mode={mode}
            fields={fields}
            onFieldChange={onFieldChange}
          />
        ) : null}
        {showTabFinCalc ? (
          <TabPanelInternaKalkulacija
            posebnaPonuda={posebnaPonuda}
            currentTab={currentTab}
            tabIndex={tabIndexFinCalc}
            record={record}
            recordId={recordId}
            validation={validation}
            mode={mode}
            fields={fields}
            onFieldChange={onFieldChange}
          />
        ) : null}
        <FormTabPanel value={currentTab} index={tabIndexPonuda}>
          <GridContainer>
            <FormContent
              {...commonProps}
              title={t("ponuda.sections.osnovno")}
              fieldNames={["from_ts", "to_ts", "relacija_opis_skraceni"]}
            />
            <FormContent {...commonProps} fieldNames={["broj_ponude", "datum_ponude", "rok_potvrda"]} columns={3} />
            <FormContent {...commonProps} fieldNames={["vozilo_id", "vozilo_opis", "napomena"]} />
            <FormContent
              {...commonProps}
              title={t("ponuda.sections.financije")}
              fieldNames={["iznos_hrk", "cbc_provizija_perc", "cbc_provizija_iznos", "iznos_s_cbc_provizijom"]}
              columns={4}
            />
            {avansAllowed ? <FormContent {...commonProps} fieldNames={["avans_iznos", "rok_avans_uplate"]} /> : null}
          </GridContainer>
        </FormTabPanel>
      </CardContent>

      <CardActions>
        <EuroConversionComment />
        <FormFillContent />
        <CardActionButtonClose variant="outlined" onClick={handleClickBack} />
        <CardActionButtonShowVoznje showTab={showTabVoznje} onToggle={handleToggleTabVoznje} />
        <CardActionButtonShowFinCalc showTab={showTabFinCalc} onToggle={handleToggleTabFinCalc} />
        <CardActionButtonSend variant="contained" onClick={sendQuote} disabled={!recordActive} />
      </CardActions>
    </Card>
  );
}

export default withFormController(PonudaForm10);
