import React from "react";
import { useParams } from "react-router";

//Material UI
import Box from "@material-ui/core/Box";

//Custom components
import PrijevozniciPage from "UI/AppPage/PrijevozniciPage";
import BurzaUpitForm from "Views/Prijevoznici/Burza/BurzaUpitForm";
import dataController from "Lib/dataController";
import model from "Models/upiti";

const BurzaUpitPage = React.memo((props) => {
  const { id } = useParams();
  const recordId = parseInt(id, 10);

  const dc = new dataController(model, "burza/upiti");

  return (
    <PrijevozniciPage>
      <Box m={2}>
        <BurzaUpitForm dc={dc} mode="view" form="burza_javno" recordId={recordId} />
      </Box>
    </PrijevozniciPage>
  );
})

export default BurzaUpitPage;