import React from "react";
import { useTranslation } from "react-i18next";

// Material UI Core
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";

// Custom Components
import GridContainer from "UI/Grid/GridContainer";
import { GridItem } from "UI/Grid";

const customSwitchStyles = makeStyles(theme => ({
    root: {
      marginLeft: 10
    }
  })
);

function TrazilicaShowFilter(props) {
  const { t } = useTranslation();
  const { state, fieldIds, onToggleShow } = props;

  const classes = customSwitchStyles();

  return (
    <Card>
      <CardHeader title={t("trazilica.filter")} style={{ textAlign: "center", paddingBottom: 0 }} />
      <CardContent>
        <GridContainer direciton="column" justify="flex-start" align="flex-start" spacing={0}>
          {
            fieldIds.map((fId, i) => (
              <GridItem xs={12} md={12} key={'show-filter-'+fId}>
                <FormControlLabel
                  key={i}
                  id={fId}
                  control={
                    <Switch
                      checked={state[fId]}
                      onChange={(evt) => onToggleShow(evt, fId)}
                      value={fId}
                      defaultValue={false}
                      color="secondary"
                      classes={classes}
                    />
                  }
                  label={t(`trazilica.filters.${fId}`)}
                />
              </GridItem>
            ))
          }
        </GridContainer>
      </CardContent>
    </Card>
  );
}

export default TrazilicaShowFilter;
