import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";

//Material-UI Core Components
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";

import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import FormButton from "UI/Form/FormButton";

import FormContent from "Components/FormContent";
import withFormAuthController from "Components/withFormAuthController";
import withRedirect from "Components/withRedirect";
import withSnackbar from "Components/withSnackbar";
import UserConsumer from "Components/UserContext/UserConsumer";
import SnackbarConsumer from "UI/SnackbarContext/SnackbarConsumer";
import {withStyles} from "@material-ui/core/styles";

const PasswordResetCard = withStyles((theme) => ({
  root: {
    borderRadius: "0.5rem",
    backgroundColor: theme.overrides.MuiCard.root.backgroundColor,
    color: "#000"
  }
}))(Card);

class PasswordResetForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleFieldChange(value, source) {
    const { onFieldChange } = this.props;

    if (onFieldChange) {
      onFieldChange(value, source);
    }
  }

  handleSubmit(setUserData, showGlobalNotification) {
    const { doResetPassword } = this.props; //HOC withFormAuthController
    const { redirect } = this.props; //HOC withRedirect
    const { showNotification } = this.props; //HOC withSnackbar

    if (doResetPassword) {
      doResetPassword().then((result) => {
        if (result.success) {
          setUserData(result.data);
          showGlobalNotification("users.password_set", "success");
          redirect("/");
        }
      }).catch((result) => {
        showNotification(result.error, "error");
      });
    }
  }

  render() {
    const { validation, mode, record, fields, userContext } = this.props;
    const { t, form } = this.props;
    let err_msg = null;
    let lbl_submit = t("login.submit");
    return (
      <UserConsumer>
        {(userContext) => (
          <SnackbarConsumer>
            {({ showNotification }) => (
              <PasswordResetCard>
                <CardContent>
                  <GridContainer>
                    <GridItem xs={12}>
                      <Typography variant="h3" component="h3">
                        {t("titles.password_reset")}
                      </Typography>
                    </GridItem>
                    <FormContent
                      fieldNames={["email","passwordreset_token","password_new","password_new_confirm"]}
                      record={record}
                      fields={fields}
                      mode={"insert"}
                      columns={1}
                      validation={validation}
                      onFieldChange={this.handleFieldChange}
                      onSubmit={() => this.handleSubmit(userContext.setUserData, showNotification)}
                    />
                  </GridContainer>
                </CardContent>
                <CardActions>
                  <FormButton
                    color="primary"
                    variant="contained"
                    onClick={(evt) => this.handleSubmit(userContext.setUserData, showNotification)}
                  >
                    {lbl_submit}
                  </FormButton>
                </CardActions>
              </PasswordResetCard>
            )}
          </SnackbarConsumer>
        )}
      </UserConsumer>
    );
  }
}

export default withTranslation()(withFormAuthController(withRedirect(withSnackbar(PasswordResetForm))));
