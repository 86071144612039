import Swal from 'sweetalert2';

export default function confirm(title, text, type, confirmButtonText, cancelButtonText) {
    return Swal.fire({
      title: title,
      text: text,
      icon: type,
      showCancelButton: true,
      cancelButtonText: cancelButtonText,
      confirmButtonText: confirmButtonText,
      allowOutsideClick: false,
      allowEscapeKey: true,
      keydownListenerCapture: true,
      showCloseButton: true,
      onClose: Swal.close()
    }).then((result) => {
      Swal.close();
      if (result.value) {
        return Promise.resolve({ confirmed: true });
      } else if (result.dismiss === 'cancel') {
        return Promise.resolve({ confirmed: false });
      } else {
        return Promise.resolve({ canceled: true });
      }
    })
  }
