import React, { Fragment, useState, useEffect, useContext, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useTable, useRowSelect, useSortBy, useFilters, useGlobalFilter, usePagination } from "react-table";

//Material UI Core Components
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MaterialUITable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Toolbar from "@material-ui/core/Toolbar";
import TablePagination from "@material-ui/core/TablePagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";

//Custom Components
import TableCheckbox from "UI/Table/TableCheckbox";
import TableRadio from "UI/Table/TableRadio";
import TableHeading from "UI/Table/TableHeading";
import TableSearchBar from "UI/Table/TableSearchBar";
import TableButtonRefresh from "UI/Table/ActionButtons/TableButtonRefresh";
import TableHeaderButtonExport from "UI/Table/HeaderButtons/TableHeaderButtonExport";
import TableHeaderButtonFilter from "UI/Table/HeaderButtons/TableHeaderButtonFilter";
import TableHeaderButtonColumnPicker from "UI/Table/HeaderButtons/TableHeaderButtonColumnPicker";
import ToolbarFillContent from "UI/Toolbar/ToolbarFillContent";
import { localStorageAPI } from "Lib/localStorageAPI";
import ThemeContext from "Components/ThemeContext/ThemeContext";
import TableHeaderButton from "./TableHeaderButton";
import UserContext from "Components/UserContext/UserContext";

const useStyle = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down("md")]: {
      overflowX: "scroll"
    }
  }
}));

function Table(props) {
  // Use the state and functions returned from useTable to build your UI
  const themeContext = useContext(ThemeContext);
  const userContext = useContext(UserContext);

  const theme = useTheme();

  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  const [rowId, setRowId] = useState(-1);
  const [recordsNumber, setRecordsNumber] = useState(0);
  const [firstEntry, setFirstEntry] = useState(false);
  const [pageCurrent, setPageCurrent] = useState(0);
  const [sorts, setSorts] = useState([]);


  const { t } = useTranslation();

  const classes = useStyle();

  const {
    columns,
    data,
    records,
    hiddenColumnNames,
    onToggleColumnVisibility,
    onRefresh,
    children,
    allowColumnPicker,
    allowSelection,
    allowFilter,
    allowExport,
    allowAdd,
    addLabel,
    handleAdd,
    topAlignedSearch,
    dc,
    tableName,
    viewName,
    title,
    pagination,
    search,
    headerContent,
    identifier,
    onRowClick,
    onDoubleClick,
    afterRowsChange
  } = props;

  const [filters, setFiltersToggle] = useState(userContext.getRecent("toggle_filter", identifier, false));


  const selectOnlyOne = function(evt, value, onChange, toggleAllRowsSelected) {
    toggleAllRowsSelected(false);
    onChange(evt, value);
  };

  const handleRowClick = function(evt, toggleRowSelected, toggleAllRowsSelected, recordId) {
    switch (allowSelection) {
      case "none":
        return;
      case "one":
        if (recordId !== rowId) {
          setRowId(recordId);
          toggleAllRowsSelected(false);
          toggleRowSelected();
          if (onRowClick) {
            onRowClick(recordId);
          }
        }
        return;
      case "many":
        toggleRowSelected();
        break;
    }
  };

  const handleDoubleClick = function(evt, toggleRowSelected, toggleAllRowsSelected, recordId) {
    if (recordId !== rowId) {
      toggleAllRowsSelected(false);
      if (onDoubleClick) {
        onDoubleClick(recordId);
      }
    }
  };

  const initialPageSizeKey = "initialPageSize";
  const src = dc.getSource();

  const initialPageSize = localStorageAPI.getValue(initialPageSizeKey, identifier, 25);

  // If a new type has a return value that is a div, please define value={value} in its props in order for the column to be searchable
  // If that isn't enough for the new type, define a new case for the new type created here in globalFilter
  const globalFilter = (rows, ids, filterValue) => {
    if (rows && ids && filterValue) {
      return rows.filter(row => {
        return ids.some((id) => {
          let rowValue = "";
          const type = dc.getField(id).type;

          if (type === "button" || !row.values[id]) {
            return false;
          }

          switch (type) {
            case "date":
            case "datetime":
              rowValue = row.values[id].props.children;
              return String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase());
            case "currency":
              rowValue = row.values[id].props.children[2];
              break;
            case "active":
              rowValue = row.values[id]();
              break;
            case "text":
              rowValue = row.values[id];
              break;
            default:
              rowValue = row.values[id] instanceof Object ? row.values[id].props ? row.values[id].props.value : row.values[id](0) : row.values[id];
              break;
          }
          return String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase());
        });
      });
    }
  };

  const initialSorts = useMemo(() => {
    return userContext.getRecent("sorts", identifier, []);
  }, [src]);

  const initialFilters = useMemo(() => {
    const filters_array = [];
    const filters_ = userContext.getRecent("filters", identifier, []);
    if (filters && filters_) {
      for (const id in filters_) {
        filters_array.push({ id: id, value: filters_[id] });
      }
      return filters_array;
    }
    return filters_array;
  }, [src]);

  const userDefinedColumnVisibility = userContext.getRecent("columns", identifier, []);

  const userHiddenFieldNames = userDefinedColumnVisibility.filter(x => x.hidden === true).map(x => x.source);
  const userVisibleFieldNames = userDefinedColumnVisibility.filter(x => x.hidden === false).map(x => x.source);
  const uniqueHiddenFieldNames = [...new Set(userHiddenFieldNames.concat(hiddenColumnNames))];

  const finalHiddenColumnNames = uniqueHiddenFieldNames.filter(x => {
    if (userVisibleFieldNames.indexOf(x) >= 0) {
      return false;
    } else {
      return true;
    }
  });

  const {
    getTableProps,
    toggleAllRowsSelected,
    headerGroups,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    prepareRow,
    setAllFilters,
    setFilter,
    setHiddenColumns,
    state: { selectedRowIds, sortBy, pageIndex, pageSize, hiddenColumns }
  } = useTable(
    {
      columns: columns,
      autoResetFilters: false,
      data: data,
      initialState: {
        pageSize: initialPageSize,
        hiddenColumns: finalHiddenColumnNames,
        sortBy: initialSorts,
        filters: initialFilters
      },
      globalFilter: globalFilter
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (instance) => {
      instance.allColumns.push((columns) => {
        switch (allowSelection) {
          case "none":
            return [...columns];
          case "one":
          case "many":
            return [
              ...columns,
              {
                id: "selection",
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <div>
                    {allowSelection === "many" ? (
                      <TableCheckbox {...getToggleAllRowsSelectedProps()} title={"Toggle"} />
                    ) : null}
                  </div>
                ),
                Filter: () => (null),
                Cell: ({ row, toggleAllRowsSelected }) => {
                  const { onChange, checked, indeterminate, style } = row.getToggleRowSelectedProps();

                  switch (allowSelection) {
                    case "one":
                      return (
                        <div>
                          <TableRadio
                            // onClick={(evt, val) => { selectOnlyOne(evt, val, onChange, toggleAllRowsSelected) }}
                            checked={checked}
                            title={"Toggle"}
                            style={style}
                          />
                        </div>
                      );
                    case "many":
                    default:
                      return (
                        <div>
                          <TableCheckbox {...row.getToggleRowSelectedProps()} title={"Toggle"} />
                        </div>
                      );
                  }
                }
              }
            ];
          default:
            return [...columns];
        }
      });
    }
  );

  const arrSelectedRowsIds = Object.keys(selectedRowIds).filter((x) => selectedRowIds[x] === true);

  // Try me and remove this boy one more time!
  console.log;

  if (!firstEntry && rows.length !== recordsNumber) {
    setRecordsNumber(rows.length);
    setFirstEntry(true);
  }

  if (pageIndex !== pageCurrent) {
    if (rows.length !== recordsNumber) {
      gotoPage(0);
      setPageCurrent(0);
      setRecordsNumber(rows.length);
    } else {
      gotoPage(pageCurrent);
      setRecordsNumber(rows.length);
    }
  }

  if (Math.floor(rows.length / 10) < pageCurrent || Math.floor(rows.length / 10) < pageIndex) {
    return null;
  }

  const handleToggleFilter = function(evt) {
    if (filters) {
      setAllFilters([]);
      userContext.setRecentFilters(identifier, null, null, filters);
    }
    userContext.setRecent("toggle_filter", identifier, !filters);
    setFiltersToggle(!filters);
  };

  const setSort = (evt, onClick, key, isDesc) => {
    if (evt.shiftKey) {
      let _sorts = userContext.getRecent("sorts", identifier, []);
      const _item = _sorts.find(x => x.id === key);

      if (isDesc === undefined) {
        if (!_item) {
          _sorts.push({ id: key, desc: false });
        }
      } else if (!isDesc) {
        if (_item) {
          _item.desc = true;
        }
      } else if (isDesc) {
        if (_item) {
          const newSorts = _sorts.filter(x => x.id !== key);
          userContext.setRecent("sorts", identifier, newSorts);
        }
        onClick(evt);
        return;
      }
      userContext.setRecent("sorts", identifier, _sorts);
    } else {
      let singleSorts = [{ id: null, desc: null }];
      if (isDesc === undefined) {
        singleSorts[0].id = key;
        singleSorts[0].desc = false;
      } else if (!isDesc) {
        singleSorts[0].id = key;
        singleSorts[0].desc = true;
      } else if (isDesc) {
        singleSorts = [];
      }
      userContext.setRecent("sorts", identifier, singleSorts);
    }
    onClick(evt);
  };

  const paginate = function() {
    if (rows.length >= 30 && pagination) {
      return page;
    } else {
      return rows;
    }
  };

  const calcTextColor =(isActive, isPanic, isStorno, isAgentura, lightTheme) => {
    if (isStorno) {
      return theme.palette.custom.storno;
    } else if (isPanic) {
      return theme.palette.custom.panic;
    } else if (isAgentura) {
      return theme.palette.custom.agentura;
    } else if (!isActive) {
      return lightTheme ? "rgba(0,0,0,0.4)" : "rgba(250,250,250,0.5)";
    } else {
      return "";
    }
  }

  const textFilter = (isActive, lightTheme) => {
    if (!isActive) {
      if (lightTheme) {
        return "rgba(0,0,0,0.4)";
      } else {
        return "rgba(250,250,250,0.5)";
      }
    } else {
      return "";
    }
  };

  const handleToggleColumnVisibility = useCallback((source, isVisible) => {
    let _columns = userContext.getRecent("columns", identifier, []);
    const _item = _columns.find(x => x.source === source);

    let newHidden;
    if (isVisible) {
      newHidden = hiddenColumns.filter(x => x !== source);
      if (_item) {
        _item.hidden = false;
      } else {
        _columns.push({ source: source, hidden: false });
      }
    } else {
      newHidden = hiddenColumns.concat([source]);
      if (!_item) {
        _columns.push({ source: source, hidden: true });
      } else {
        _item.hidden = true;
      }
    }

    const cleanArray = [...new Set(newHidden)];

    userContext.setRecent("columns", identifier, _columns);
    setHiddenColumns(cleanArray);
    // NOTE: Does not work

    // setUserFieldVisibility(prevState => {
    //   return {
    //     ...prevState,
    //     [source]: isVisible
    //   }
    // })
  }, [hiddenColumns]);

  // useEffect(() => {
  //   setHiddenColumns(hiddenColumnNames);
  // }, [hiddenColumnNames])

  useEffect(() => {
    if (typeof afterRowsChange == 'function')
    afterRowsChange(rows);
  }, [rows]);

  return (
    <Fragment>
      <Toolbar variant={xlUp ? "regular" : "dense"} disableGutters={true}>
        {title ? <TableHeading>{title}</TableHeading> : null}
        {search ?
          <TableSearchBar
            // count={preGlobalFilteredRows ? preGlobalFilteredRows.length : 0}
            // value={globalFilter}
            // onChange={setGlobalFilter}
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          : null
        }
        {onRefresh ? <TableButtonRefresh onClick={onRefresh} /> : null}
        {/*{*/}
        {/*  specificFilterField ? (*/}
        {/*      <TableSpecificFilters*/}
        {/*          setFilter={setFilter}*/}
        {/*          specificFilterField={specificFilterField}*/}
        {/*      />*/}
        {/*  ) : null*/}
        {/*}*/}
        <ToolbarFillContent />
        {allowColumnPicker ?
          <TableHeaderButtonColumnPicker columns={columns} hiddenColumnNames={hiddenColumns} onChange={handleToggleColumnVisibility} />
          : null}
        {allowFilter ?
          <TableHeaderButtonFilter filters={filters} onToggleFilter={handleToggleFilter} />
          : null}
        {allowExport ?
          <TableHeaderButtonExport dc={dc} rows={rows} records={records} tableName={tableName} />
          : null}
        {allowAdd ? (
          <TableHeaderButton onClick={handleAdd} startIcon={<AddIcon />} variant="contained">
            {addLabel}
          </TableHeaderButton>
        ) : null}
      </Toolbar>
      <TableContainer classes={{ root: classes.root }}>
        <MaterialUITable {...getTableProps()} size="small" stickyHeader={true} style={{height: "inherit"}}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, c) => {
                  const { onClick, ...headerProps } = column.getHeaderProps(column.getSortByToggleProps());
                  const id = column["id"];

                  return (
                    <TableCell padding={column.id === "selection" ? "checkbox" : "default"} {...headerProps}>

                      {column.canSort ? (
                        <TableSortLabel
                          active={column.isSorted}
                          direction={column.isSortedDesc ? "desc" : "asc"}
                          onClick={(evt) => setSort(evt, onClick, id, column.isSortedDesc)}
                        >
                          <Typography variant="caption" style={{ textTransform: "uppercase", fontWeight: 800 }}>{column.render("Header")}</Typography>
                        </TableSortLabel>
                      ) : (
                        column.render("Header")
                      )}
                      {filters && column.canFilter ? (
                        <div className="table-filter" key={c + "-filter"}>
                          {column.render("Filter")}
                        </div>
                      ) : null}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {paginate().map((row, i) => {
              prepareRow(row);
              // this comment fixes wrong selection on single select tables but what with multi select? -Piero
              const isItemSelected = /*selectedRowIds[pageIndex * pageSize + i] === true ||*/ rowId === row.original.id;
              // this piece of work ensures that children of table get selection property. Is it useful here? Nobody knows
              if (isItemSelected && arrSelectedRowsIds.length === 0) {
                arrSelectedRowsIds.push(row.id);
                row.toggleRowSelected();
              }

              // if this row has active as a property it will be a function which will return its boolean value before translation if true is passed to it
              const isActive = !row.original.hasOwnProperty("active") || row.original.active(true);
              const isPanic = row.original.hasOwnProperty("panic") && row.original.panic(true);
              const isNew = row.original.hasOwnProperty("mark_new") && row.original.mark_new(true);
              const isStorno = row.original.hasOwnProperty("storno") && row.original.storno(true);
              const isAgentura = row.original.hasOwnProperty("agentura") && row.original.agentura(true);
              const lightTheme = themeContext.theme === "light" ? true : false;
              return (
                <TableRow
                  hover={true}
                  selected={isItemSelected}
                  role="check"
                  aria-checked={isItemSelected}
                  {...row.getRowProps()}
                  onDoubleClick={(evt) => handleDoubleClick(evt, row.toggleRowSelected, toggleAllRowsSelected, row.original.id)}
                  onClick={(evt) => handleRowClick(evt, row.toggleRowSelected, toggleAllRowsSelected, row.original.id ? row.original.id : row.original.trjobid)}
                >
                  {row.cells.map((cell) => {
                    return <TableCell {...cell.getCellProps({
                      style: {
                        overflowWrap: "break-word"
                        , cursor: "pointer"
                        , color: calcTextColor(isActive, isPanic, isStorno, isAgentura, lightTheme)
                        , fontWeight: isNew ? "bold" : "normal"
                      }
                    })}>{cell.render("Cell")}</TableCell>;
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MaterialUITable>
      </TableContainer>
      {pagination ? (
        <Toolbar variant={xlUp ? "regular" : "dense"}>
          <div style={{ flexGrow: 1 }}/>
          <TablePagination
            component="div"
            onChangePage={(evt, page) => setPageCurrent(page)}
            page={pageIndex}
            rowsPerPage={pageSize}
            count={rows.length}
            onChangeRowsPerPage={(evt) => {
              localStorageAPI.setValue(initialPageSizeKey, identifier, evt.target.value);
              setPageSize(evt.target.value);
            }}
          />
        </Toolbar>
      ) : null}
        <Toolbar variant={xlUp ? "regular" : "dense"} disableGutters={true}>
          <div style={{ flexGrow: 1 }} />
          {React.Children.map(children, (child) => {
            return React.cloneElement(child, { selection: arrSelectedRowsIds });
          })}
        </Toolbar>
    </Fragment>
  );
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  allowSelection: PropTypes.oneOf(["none", "one", "many"])
};

export default Table;
