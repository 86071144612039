import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI
import ControlCameraIcon from "@material-ui/icons/ControlCamera";

//Custom components
import DialogActionButton from "UI/Dialog/DialogActionButton";

function DialogActionButtonSendUpit(props) {
  const { t } = useTranslation();

  return (
    <DialogActionButton startIcon={<ControlCameraIcon />} {...props} aria-label="Send Upit" aria-controls="Send Upit">
      {t("buttons.upit_create")}
    </DialogActionButton>
  );
}

export default DialogActionButtonSendUpit;
