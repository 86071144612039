import React, { useContext } from "react";
import { useParams } from "react-router";

//Material UI
import Box from "@material-ui/core/Box";

//Custom components
import UserContext from "Components/UserContext/UserContext";
import PrijevozniciPage from "UI/AppPage/PrijevozniciPage";
import DirektanUpitForm from "Views/Prijevoznici/DirektniUpiti/DirektanUpitForm";
import dataController from "Lib/dataController";
import model from "Models/upiti";

const DirektanUpitPage = React.memo((props) => {
  const userContext = useContext(UserContext);
  const { id } = useParams();
  const recordId = parseInt(id, 10);

  const dc = new dataController(model, "direktni-upiti");

  return (
    <PrijevozniciPage>
      <Box m={2}>
        <DirektanUpitForm
          dc={dc}
          mode="view"
          form="direktan_upit_javno"
          subModelDefaultValues={{
            prijevoznik_id: userContext.subjectId
          }}
          recordId={recordId}
        />
      </Box>
    </PrijevozniciPage>
  );
})

export default DirektanUpitPage;
