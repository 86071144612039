import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Icons
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";

//Custom components
import CardToolbarMenuItem from "UI/Card/CardToolbarMenuItem";
import { Permissions } from "Lib/permissions";

const CardToolbarMenuItemAssignMediation = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return (
    <CardToolbarMenuItem
      label={t("buttons.mediation_assign")}
      icon={<SupervisedUserCircleIcon />}
      {...props}
      ref={ref}
      needPermission={[Permissions.AdminMediationGlobal]}
    />
  );
});

export default CardToolbarMenuItemAssignMediation;
