import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

//Custom Components
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import FormTabPanel from "UI/Form/FormTabPanel";
import FormContent from "Components/FormContent";
import FormButton from "UI/Form/FormButton";
import UserContext from "Components/UserContext/UserContext";

function TabPanelUpitPrivatno(props) {
  const { currentTab, tabIndex } = props;
  const { recordId, record, validation, fields, onFieldChange, mode, subModels } = props;

  const { t } = useTranslation();

  const fieldPonudaIznos = fields.find((f) => f.source === "ponuda_bruto_hrk");
  if (fieldPonudaIznos) {
    fieldPonudaIznos.readonly = true;
  }

  const commonProps = {
    recordId: recordId,
    record: record,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels
  };

  return (
    <FormTabPanel value={currentTab} index={tabIndex}>
      <GridContainer>
        <FormContent
          {...commonProps}
          title={t("upit.sections.internal")}
          fieldNames={["narucitelj_interni", "napomena_interna"]}
        />
      </GridContainer>
    </FormTabPanel>
  );
}

TabPanelUpitPrivatno.defaultProps = {
  mode: "update"
};

TabPanelUpitPrivatno.propTypes = {
  currentTab: PropTypes.number,
  tabIndex: PropTypes.number,
  recordId: PropTypes.number,
  record: PropTypes.object,
  validation: PropTypes.object,
  onFieldChange: PropTypes.func,
  fields: PropTypes.array,
  mode: PropTypes.oneOf(["view", "update"])
};

export default TabPanelUpitPrivatno;
