import React from 'react'
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import ToolTip from "@material-ui/core/Tooltip";

const customButtonStyles = makeStyles(theme => ({
  root: {
    //marginLeft: 10,
    marginRight: 0,
    // color: theme.palette.common.white,
    // "&:hover": {
    //   backgroundColor: theme.palette.secondary.dark
    // },
  }
}));


function HeaderIconButton(props) {
  const {t} = useTranslation();
  const {desc} = props;
  const classes = customButtonStyles();

  return (
      <ToolTip disableFocusListener placement="bottom" arrow title={t(`tooltips.header.${desc}`)}>
        <IconButton size="small" classes={classes} color="primary" {...props}>
          {props.children}
        </IconButton>
      </ToolTip>
  );
}

export default HeaderIconButton;
