import React, { useContext } from "react";
import { useParams, useHistory } from "react-router-dom";

//Custom components
import UserContext from "Components/UserContext/UserContext";
import PrijevozniciPage from "UI/AppPage/PrijevozniciPage";
import dataController from "Lib/dataController";
import model from "Models/rezervacije";
import RezervacijaForm from "Views/Prijevoznici/Rezervacije/RezervacijaForm";
import Box from "@material-ui/core/Box";

const RezervacijaPage = React.memo((props) => {
  const userContext = useContext(UserContext);
  const history = useHistory();
  const { id } = useParams();

  const recordId = parseInt(id, 10);

  const dc = new dataController(model);

  const handleClose = () => {
    history.goBack();
  };

  return (
    <PrijevozniciPage>
      <Box m={2}>
        <RezervacijaForm dc={dc} recordId={recordId} form="update" mode="update" onClose={handleClose} subModelDefaultValues={{
            prijevoznik_id: userContext.subjectId
          }} />
      </Box>
    </PrijevozniciPage>
  );
})

export default RezervacijaPage;
