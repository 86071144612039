import React from "react";
import { useTranslation } from "react-i18next";

//Custom components
import AdminPage from "UI/AppPage/AdminPage";
import ResponsiveBox from "UI/Box/ResponsiveBox";
import PoslovniSubjektiTable from "Views/Administration/PoslovniSubjekti/PoslovniSubjektiTable";

const PoslovniSubjektiPage = React.memo((props) => {
  const { t } = useTranslation();

  return (
    <AdminPage>
      <ResponsiveBox fillHeight>
        <PoslovniSubjektiTable />
      </ResponsiveBox>
    </AdminPage>
  );
});

export default PoslovniSubjektiPage;
