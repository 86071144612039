import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation, withTranslation } from "react-i18next";


//Material UI
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";

//Custom components
import Table from "UI/Table/Table";
import DateColumnFilter from "UI/Table/TableFilters/DateColumnFilter";
import CurrencyColumnFilter from "UI/Table/TableFilters/CurrencyColumnFilter";
import UserContext from "Components/UserContext/UserContext";
import ThemeContext from "Components/ThemeContext/ThemeContext";
import tableGenerator from "Lib/tableGenerator";
import dataControllerSubModel from "Lib/dataControllerSubModel";
import dataController from "Lib/dataController";
import radio from "Models/submodels/radio";
import checkbox from "Models/submodels/checkbox";
import picker from "Models/submodels/picker";
import { retrieveIconColor } from "Lib/statuses";

function ModelTable(props) {
  const {t} = useTranslation();
  const userContext = useContext(UserContext);
  const themeContext = useContext(ThemeContext);
  const [subModelsWithData, setSubModelsWithData] = useState([]);
  const [columnInfo, setColumnInfo] = useState({columns: [], hiddenColumnNames: []});
  const [data, setData] = useState([]);

  // specific
  // kad ti dam specific...
  const favPrijevozniciLength = userContext && userContext.userSettings && userContext.userSettings.fav_prijevoznici && Array.isArray(userContext.userSettings.fav_prijevoznici)
      ? userContext.userSettings.fav_prijevoznici.length
      : 0;
  const favAgencijeLength = userContext && userContext.userSettings && userContext.userSettings.fav_agencije && Array.isArray(userContext.userSettings.fav_agencije)
      ? userContext.userSettings.fav_agencije.length
      : 0;

  const {
    dc,
    viewName,
    records,
    children,
    allowColumnPicker,
    allowSelection,
    allowFilter,
    allowExport,
    onSelectRecord,
    onRowAction,
    allowRowAction,
    topAlignedSearch,
    searchFilter,
    tableName,
    pagination = true,
    search = true,
    sidebarpane,
    allowAdd,
    addLabel,
    handleAdd,
    title,
    onRowClick,
    onDoubleClick,
    handleRefresh,
    subModelDefaultValues,
    afterRowsChange
  } = props;

  const viewFields = dc.getViewFields(viewName);

  const src = dc.getSource();
  const identifier = src + viewName;

  const setFilterLocalStorage = (id, filter, setFilter) => {
    userContext.setRecentFilters(identifier, id, filter);
    // Imamo neke vrijednosti za filter koji su 0, a to ne prolazi na filter || undefined
    // uvijek će to bacit na undefined, pa nek provjeri if statement da li je jedan od ovih vrijednosti osim 0
    if(filter !== undefined && filter !== null && filter !== "") {
      setFilter(filter);
    } else {
      setFilter(undefined);
    }
  }

  const dateColumnFilter = ({column: {filterValue, setFilter, preFilteredRows, id}}) => {
    return (
      <DateColumnFilter filterValue={filterValue} setFilter={setFilter} id={id} onFilterChange={(value) => setFilterLocalStorage(id, value, setFilter)} />
    );
  }

  const currencyColumnFilter =  ({column: {filterValue, setFilter, preFilteredRows, id}}) => {
    return (
      <CurrencyColumnFilter filterValue={filterValue} setFilter={setFilter} id={id} preFilteredRows={preFilteredRows} onFilterChange={(value) => setFilterLocalStorage(id, value, setFilter)} />
    )
  }

  const elementPropertyColumnFilter = ({column: {filterValue, setFilter, preFilteredRows, id}}) => {
    const {t} = useTranslation();

    const _field = dc.getField(id);
    const _fieldItems = _field.items;

    const options = React.useMemo(() => {
      const options = new Set();
      _fieldItems.labels.filter((label, i) => {
        const found_value = preFilteredRows.find((pfr, r) => pfr.values[id].props.property === _fieldItems.values[i]);
        if(found_value !== undefined) {
          options.add({
            color: retrieveIconColor(_fieldItems.icons[i]),
            icon: _fieldItems.icons[i],
            value: _fieldItems.values[i],
            label: label
          })
        }
      });
      return [...options.values()];
    }, [id, preFilteredRows]);
    return (

        <Select
            value={filterValue}
            onChange={(e) => {
              setFilterLocalStorage(id, e.target.value, setFilter);
            }}
            MenuProps={{
              getContentAnchorEl: null
            }}
            variant="outlined"
            defaultValue=""
            margin="dense"
            displayEmpty
        >
          <MenuItem value="">{t("common.all")}</MenuItem>
          {options.map((option, i) => (option !== "" ?
                  <MenuItem key={i} value={option.value}>
                    <Icon size="small" style = {{color: option.color}} >{option.icon}</Icon>
                    {t(option.label)}
                  </MenuItem> : null
          ))}
        </Select>
    );
  }

  const MultiColumnFilter = ({column: {filterValue, setFilter, id}}) => {
    const {t} = useTranslation();

    const options = React.useMemo(() => {
      if (subModelsWithData) {
        if(subModelsWithData[id]) {
          const subModelData = subModelsWithData[id].records;
          const options = new Set();
          subModelData.forEach((row) => {
            options.add(row);
          });
          return [...options.values()];
        }
        return []
      }
    }, [id, subModelsWithData]);
    return (

        <Select
            value={Array.isArray(filterValue) ? filterValue : []}
            onChange={(e) => {
              const _selected = e.target.value.map((option) => {
                return parseInt(option);
              })
              setFilterLocalStorage(id, _selected || undefined, setFilter);
            }}
            multiple
            variant="outlined"
            margin="dense"
        >
          {options.map((option, i) => (option !== "" ?
                  <MenuItem key={i} value={option.value}>
                    {t(option.label)}
                  </MenuItem> : null
          ))}
        </Select>
    );
  }

  const SelectColumnFilter = ({column: {filterValue, setFilter, preFilteredRows, id}}) => {
    const {t} = useTranslation();

    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(id === 'active' || id === 'panic' ? row.values[id]() : row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    return (

        <Select
            value={filterValue}
            onChange={(e) => {
              setFilterLocalStorage(id, e.target.value || undefined, setFilter);
            }}
            MenuProps={{
              getContentAnchorEl: null
            }}
            variant="outlined"
            defaultValue=""
            margin="dense"
            displayEmpty
        >
          <MenuItem value="">{t("common.all")}</MenuItem>
          {options.map((option, i) => (option !== "" ?
                  <MenuItem key={i} value={option}>
                    {option}
                  </MenuItem> : null
          ))}
        </Select>
    );

  }



  const TextColumnFilter = function ({column: {filterValue, setFilter, preFilteredRows, id}}) {
    return (
        <TextField
            value={filterValue}
            onChange={(e) => {
              setFilterLocalStorage(id, e.target.value || undefined, setFilter);
            }}
        />
    );
  }

  const NoFilter = function () {
    return (null);
  }

  const compareDateTime = (rowA, rowB, columnId, desc) => {
    const divA = rowA.values[columnId];
    const divB = rowB.values[columnId];
    const momA = divA && divA.props && divA.props.value !== "" ? moment(divA.props.value) : null;
    const momB = divB && divB.props && divB.props.value !== "" ? moment(divB.props.value) : null;
    if (moment.isMoment(momA) && moment.isMoment(momB)) {
      if (momA.isAfter(momB)) {
        return 1;
      } else {
        return -1;
      }
    } else if (momA !== null && momB === null) {
      return -1;
    } else if (momA === null && momB !== null) {
      return 1;
    } else {
      return 1;
    }
  }

  const sortMulti = (rowA, rowB, columnId) => {
    const valA = rowA.values[columnId] ? rowA.values[columnId].props.property.length : null;
    const valB = rowB.values[columnId] ? rowB.values[columnId].props.property.length : null;

    return (Number(valB) - Number(valA));
  }

  const sortElementProperty= (rowA, rowB, columnId) => {
    const valA = rowA.values[columnId] ? rowA.values[columnId].props.property : null;
    const valB = rowB.values[columnId] ? rowB.values[columnId].props.property : null;

    return (Number(valB) - Number(valA));
  }

  const sortFavorite = (rowA, rowB, columnId, desc) => {
    // By design they both must have props.value otherwise this will crash
    const valA = rowA.values[columnId] ? rowA.values[columnId].props.value : null;
    const valB = rowB.values[columnId] ? rowB.values[columnId].props.value : null;

    // Number(false) = 0, Number(true) = 1
    // a - b (difference) -> sorts elements in ascending order, since we want descending (true before false or 1 before 0) we do it the other way around
    return (Number(valB) - Number(valA));
  }

  const sortBasic = (rowA, rowB, columnId, desc) => {
    const valA = rowA.values[columnId] ? rowA.values[columnId] : null;
    const valB = rowB.values[columnId] ? rowB.values[columnId] : null;

    // There two ifs are here to ensure that falsey values always end up at the end of the table
    // Curious reader will ask why not just check if(valA) well because 0 is falsey value so it will end up somewhere between the empty values
    //    which is not what we want since 0 is indeed a falsey value but it is nonetheless a VALUE
    if (valA === null || valA === undefined || valA === "") {
      return desc ? -1 : 1;
    }
    if (valB === null || valB === undefined || valB === "") {
      return desc ? 1 : -1;
    }

    if (typeof valA === "number" && typeof valB === "number") {
      return valA - valB;
    } else if (typeof valA === "string" && typeof valB === "string") {
      return valA.toUpperCase() > valB.toUpperCase() ? 1 : -1;
    } else if (columnId === "active") {
      return valA() > valB() ? 1 : -1;
    } else {
      // Not sure what to do with arrays and objects so lets just do this and see what happens
      return valA > valB ? 1 : -1;
    }
  }

  const sortLink =( rowA, rowB, columnId, desc) => {
    // By design they both must have props.value otherwise this will crash
    const valA = rowA.values[columnId] ? rowA.values[columnId].props.value : null;
    const valB = rowB.values[columnId] ? rowB.values[columnId].props.value : null;

    // Number(false) = 0, Number(true) = 1
    // a - b (difference) -> sorts elements in ascending order, since we want descending (true before false or 1 before 0) we do it the other way around
    return valA.toUpperCase() > valB.toUpperCase() ? 1 : -1;

  }

  const filterDate = (toMatch, currValue) => {
    const compare_date = moment()
    const seven_days = currValue === "last_7_days" ? moment().subtract(7, "days") : moment().add(7, "days");
    const thirty_days = currValue === "last_30_days" ? moment().subtract(30, "days") : moment().add(30, "days");
    const week_start = moment().startOf("week");
    const week_end = moment().endOf("week");

    if(Array.isArray(currValue)) {
      const start = moment(currValue[0]);
      const end = moment(currValue[1]);
      return moment(toMatch).isBetween(start, end, "days", "[]");
    }

    if(toMatch, currValue) {
      switch(currValue) {
      case "today":
      case "yesterday":
      case "tomorrow":
        if(currValue === "tomorrow") {
          compare_date.add(1, "day");
        } else if(currValue === "yesterday") {
          compare_date.subtract(1, "day");
        }
        return compare_date.isSame(toMatch, "day");
      case "this_week":
        return moment(toMatch).isSame(compare_date, "week");
      case "next_week":
        compare_date.add(1, "weeks");
        return moment(toMatch).isSame(compare_date, "week");
      case "last_week":
        compare_date.subtract(1, "weeks");
        return moment(toMatch).isSame(compare_date, "week");
      case "next_2_weeks":
      return moment(toMatch).isBetween(moment(week_start).add(1, "weeks"), moment(week_end).add(2, "weeks"), "days", "[]");
      case "last_2_weeks":
        return moment(toMatch).isBetween(moment(week_start).subtract(2, "weeks"), moment(week_end).subtract(1, "weeks"), "days", "[]");
      case "next_7_days":
        return moment(toMatch).isBetween(compare_date, seven_days, "days", "[]");
      case "last_7_days":
        return moment(toMatch).isBetween(seven_days, compare_date, "days", "[]");
      case "this_month":
      case "next_month":
      case "last_month":
        if(currValue === "next_month") {
          compare_date.add(1, "months");
        } else if(currValue === "last_month") {
          compare_date.subtract(1, "months");
        }
        return moment(toMatch).isSame(compare_date, "month");
      case "next_30_days":
        return moment(toMatch).isBetween(compare_date, thirty_days, "days", "[]");
      case "last_30_days":
        return moment(toMatch).isBetween(thirty_days, compare_date, "days", "[]");
      case "this_year":
      case "last_year":
      case "next_year":
        if(currValue === "last_year") {
          compare_date.subtract(1, "years");
        } else if(currValue === "next_year") {
          compare_date.add(1, "years");
        }
        return moment(toMatch).isSame(compare_date, "year");
      default: return true
    }
    }
    return true;
  }

  const filterMulti = (toMatch, currValue) => {
    if (toMatch && currValue) {
      const _res = currValue.map((val) => {
        if (toMatch.indexOf(val) !== -1) {
          return true;
        }else{
          return false;
        }
      });
      if (_res.every((val) => val)) {
        return true;
      }
    }
  }

  const filterElementProperty = (toMatch, currValue) => {
    if(toMatch && currValue){
      if(toMatch === parseInt(currValue)){
        return true;
      }
    }
  }

  const filterCurrency = (toMatch, currValue) => {
    if(currValue[0] <= toMatch && toMatch <= currValue[1]) {
      return true
    } else {
      return false
    }
  }

  const sortFncs = {
    // READ THIS: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#description
    // If you didnt read this go back and read it
    // If you still didnt read this then DO NOT touch this object
    // If you decide to change it in any way before you read the above we WILL have a serious talk
    multiSort: sortMulti,
    elementProperty: sortElementProperty,
    favorite: sortFavorite,
    basic: sortBasic,
    link: sortLink
  }

  const filterFncs = {
    multi: filterMulti,
    elementProperty: filterElementProperty,
    date: filterDate,
    currency: filterCurrency
  }

  useEffect(() => {
    const fieldsWithModel = viewFields.filter((f) => f.subModel);
    const subModels = {};

    fieldsWithModel.forEach((f) => {
      const modelType =
          f.type === "picker"
              ? picker
              : f.type === "radio"
              ? radio
              : f.type === "checkbox"
                  ? checkbox
                  : f.type === "link"
                      ? require(`Models/${f.subModel.name}`)
                      : null;

      if (modelType !== null) {
        if (f.type === "link") {
          Object.assign(subModels, {
            [f.source]: new dataController(modelType, f)
          });
        } else {
          Object.assign(subModels, {
            [f.source]: new dataControllerSubModel(modelType, f, subModelDefaultValues)
          });
        }
      }
    });

    const getDataPromises = Object.keys(subModels).map((f) => subModels[f].GetData());
    Promise.all(getDataPromises).then((data) => {
      setSubModelsWithData(subModels);
    });
  }, []);

  const getSortType = (field) => {
    if (field.type === "date" || field.type === "datetime") {
      return compareDateTime;
    } else if (field.type === "link") {
      return sortFncs["link"]
    } else if (field.customSort) {
      return sortFncs[field.customSort]
    } else {
      return sortFncs["basic"];
    }
  }

  useEffect(() => {
    const viewFields = dc.getViewFields(viewName);
    const columns = viewFields.map((f) => {
      const canSort = f.hasOwnProperty("sort") && f.sort === false ? false : true;
      const sortType = getSortType(f);
      let obj = {
        Header: f.ttoken ? t(f.ttoken) : t(f.title),
        accessor: f.source,
        disableSortBy: !canSort,
        sortType: canSort && sortType,
        disableFilters: f.filter === false,
        Filter: sidebarpane === undefined ? f.type === "text" || f.type === "multiline" ? TextColumnFilter
                : f.type === "radio" || f.type === "boolean" || f.type === "picker" || f.type === "active" || f.type === "panic" || f.type === "mark_new" ? SelectColumnFilter
                    : f.multi ? MultiColumnFilter
                        : f.type === "iconStatus" || f.type === "statusstats"  || f.type === "status" ? elementPropertyColumnFilter
                        : f.type === "date" || f.type === "datetime" ? dateColumnFilter
                        : f.type === "currency" ? currencyColumnFilter
                        : NoFilter : NoFilter,
        filter: (arrValues, filterField, currValue) => {
          const [fField] = filterField;
          return arrValues.filter((field) => {
            // if(f.type === "currency") console.log(field.original[fField])
            return f.type === "radio" || f.type === "boolean" || f.type === "picker" ? field.original[fField] === currValue // no need to change the case, they will always be the same
                  : f.type === "active" || f.type === "panic" || f.type === "mark_new" ? currValue === "all" || field.original[fField]() === currValue// same as above
                  : f.type === "iconStatus" || f.type === "statusstats" ? field.original[fField].props.property === currValue
                  : f.multi ? filterFncs['multi'](field.original[fField] ? field.original[fField].props.property : null, currValue)
                  : f.type === "status" ? filterFncs[f.customFilter](field.original[fField] ? field.original[fField].props.property : null, currValue)
                  : f.type === "date" || f.type === "datetime" ? filterFncs["date"](field.original[fField].props.value, currValue)
                  : f.type === "currency" ? filterFncs["currency"](field.original[fField]?.props.unformatedvalue ?? null, currValue)
                  : (field.original[fField] + "").toUpperCase().indexOf(currValue.toUpperCase()) >= 0;
          });
        },
        padding: f.padding ? f.padding : "default"
      };

      // if the column type is active value is a function which sill return value to be rendered
      return Object.assign(obj, f.type === "active" || f.type === "panic" || f.type === "mark_new" ?
        {Cell: ({value}) => String(value())} : f.type === "link"  ? {Cell: ({value}) => value()}
        : {});
    },);

    const hiddenColumnNames = dc.getHiddenViewFieldsNames(viewName);

    setColumnInfo({
      columns: columns,
      hiddenColumnNames: hiddenColumnNames
    })

  }, [viewName, t, subModelsWithData.length])

  useEffect(() => {
    const tg = new tableGenerator(t, onRowAction, allowRowAction);
    const viewFields = dc.getViewFields(viewName);
    tg.generateDataRows(records, viewFields, subModelsWithData, userContext, themeContext)
        .then(data => {
          setData(data);
        })
  }, [viewName, records, records.length, t, themeContext.theme, subModelsWithData.length])

  useEffect(() => {
    const tg = new tableGenerator(t, onRowAction, allowRowAction);
    const viewFields = dc.getViewFields(viewName);
    tg.generateDataRows(records, viewFields, subModelsWithData, userContext, themeContext)
        .then(data => {
          setData(data);
        })
  }, [favAgencijeLength, favPrijevozniciLength])

  return (
      <Table
          title={title}
          data={data}
          topAlignedSearch={topAlignedSearch}
          records={records}
          columns={columnInfo.columns}
          hiddenColumnNames={columnInfo.hiddenColumnNames}
          allowColumnPicker={allowColumnPicker}
          allowSelection={allowSelection}
          allowFilter={allowFilter}
          allowExport={allowExport}
          allowAdd={allowAdd}
          addLabel={addLabel}
          handleAdd={handleAdd}
          dc={dc}
          tableName={tableName}
          viewName={viewName}
          pagination={pagination}
          search={search}
          onRowClick={onRowClick}
          onDoubleClick={onDoubleClick}
          onRefresh={handleRefresh}
          identifier={identifier}
          afterRowsChange={afterRowsChange}
      >
        {children}
      </Table>
  );
}

ModelTable.defaultProps = {
  viewName: "default",
  allowColumnPicker: true,
  allowSelection: "one",
  allowFilter: false,
  allowExport: false
};

ModelTable.propTypes = {
  records: PropTypes.array,
  dc: PropTypes.object,
  viewName: PropTypes.string,
  allowSelection: PropTypes.oneOf(["none", "one", "many"]),
  allowFilter: PropTypes.bool,
  allowExport: PropTypes.bool
};

export default withTranslation()(ModelTable);
