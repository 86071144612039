import React from 'react';
import IconButton from "@material-ui/core/IconButton";

function CardToolbarButton(props) {
  return (
      <IconButton className="nodrag" color="inherit" {...props}>
        {props.children}
      </IconButton>
  );
}

export default CardToolbarButton;
