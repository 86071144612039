import { coreFields } from "./helpers/coreFields";
import { commonFields } from "./helpers/commonFields";
import { addressFields } from "./helpers/addressFields";
import { buttonFields } from "./helpers/buttonFields";

export default {
  title: "Komitenti",
  exportFileName: "Komitenti",
  apiPath: "prijevoznici/{prijevoznik_id}/komitenti",
  forms: {
    default: {
      fields: [
        "naziv",
        "tag",
        "oib",
        "adr_ulica",
        "adr_grad",
        "adr_pbr",
        "adr_drzava",
        "f_kupac",
        "f_dobavljac",
        "napomena",
        "ukupno_duguje_hrk",
        "ukupno_potrazuje_hrk",
        "ukupno_saldo_hrk",
        "vat"
      ]
    }
  },
  listViews: {
    default: {
      fields: ["id", "oib", "f_kupac", "f_dobavljac", "naziv", "tag", "adr_ulica", "adr_grad", "adr_pbr", "adr_drzava", "ukupno_duguje_hrk", "ukupno_potrazuje_hrk", "ukupno_saldo_hrk", "edit"],
      hidden: ["id"]
    }
  },
  fields: [
    coreFields.id,
    coreFields.created_on,
    coreFields.created_by,
    coreFields.modified_on,
    coreFields.modified_by,
    {
      title: "owner_bs_id",
      source: "owner_bs_id",
      ttoken: "komitenti.owner_bs_id",
      type: "numeric"
    },
    {
      title: "OIB",
      source: "oib",
      ttoken: "common.oib",
      type: "text",
      validation: {
        required: true,
        maxLength: 20,
      },
      width: 20
    },
    {
      title: "kupac",
      source: "f_kupac",
      ttoken: "komitenti.f_kupac",
      type: "boolean",
      items: {
        labels: ["common.ne", "common.da"],
        values: [false, true],
        default: false
      },
      translate: true
    },
    {
      title: "dobavljac",
      source: "f_dobavljac",
      ttoken: "komitenti.f_dob",
      type: "boolean",
      items: {
        labels: ["common.ne", "common.da"],
        values: [false, true],
        default: false
      },
      translate: true
    },
    {
      title: "naziv",
      source: "naziv",
      type: "text",
      ttoken: "komitenti.naziv",
      validation: {
        required: true,
        maxLength: 100
      }
    },
    {
      title: "tag",
      source: "tag",
      type: "text",
      ttoken: "komitenti.tag",
      validation: {
        required: true,
        maxLength: 15
      }
    },
    commonFields.vat,
    addressFields.adr_ulica,
    addressFields.adr_grad,
    addressFields.adr_pbr,
    addressFields.adr_drzava,
    {
      title: "napomena",
      source: "napomena",
      type: "text",
      ttoken: "komitenti.napomena",
      validation: {
        maxLength: 2000
      }
    },
    {
      title: "Platform",
      source: "platform",
      ttoken: "komitenti.platform",
      type: "boolean",
      items: {
        labels: ["common.ne", "common.da"],
        values: [false, true],
        default: false
      },
      readonly: true
    },
    {
      title: "Owner Ukupno Duguje",
      source: "ukupno_duguje_hrk",
      type: "currency",
      ttoken: "financijskeObveze.komitent_potrazuje",
      currency: "€"
    },
    {
      title: "Owner Ukupno Potrazuje",
      source: "ukupno_potrazuje_hrk",
      type: "currency",
      ttoken: "financijskeObveze.komitent_duguje",
      currency: "€"
    },
    {
      title: "Ukupno Saldo",
      source: "ukupno_saldo_hrk",
      type: "currency",
      ttoken: "financijskeObveze.vl_saldo",
      currency: "€"
    },
    buttonFields.edit
  ]
};
