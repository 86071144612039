import React from "react";

//Material-UI Core Components
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

//Custom Components
import PublicPage from "UI/PublicPage/PublicPage";
import LoginForm from "Views/Auth/LoginForm";

import model from "Models/user";
import dataController from "Lib/dataController";

function LoginPage() {

  const dc = new dataController(model)

  return (
    <PublicPage>
      <Box m={2}>
        <Container maxWidth={"sm"}>
          <LoginForm dc={dc} form="login" />
        </Container>
      </Box>
    </PublicPage>
  )
}

export default LoginPage;