import { coreFields } from "./helpers/coreFields";
import { buttonFields } from "./helpers/buttonFields";

export default {
  title: "Putni nalozi",
  exportFileName: "Putni_nalozi",
  apiPath: "poslovi/{posao_id}/putni-nalozi",
  forms: {
    default: {
      fields: ["tag", "ts_from", "ts_to", "vozilo_id", "vozac_id", "km_from", "km_to", "opis"]
    }
  },
  listViews: {
    default: {
      fields: ["id", "tag", "ts_from", "ts_to", "vozilo_id", "vozac_id", "km_from", "km_to", "opis", "print", "edit"],
      hidden: ["id"]
    },
    readonly: {
      fields: ["id", "tag", "ts_from", "ts_to", "vozilo_id", "vozac_id", "km_from", "km_to", "opis", "print", "view"],
      hidden: ["id"]
    }
  },
  fields: [
    coreFields.id,
    {
      title: "tag",
      source: "tag",
      ttoken: "common.tag",
      type: "text",
      validation: {
        required: true,
        maxLength: 20
      },
      width: "full"
    },
    {
      title: "ts_from",
      source: "ts_from",
      ttoken: "putni_nalozi.ts_from",
      type: "datetime",
      validation: {
        required: true
      }
    },
    {
      title: "ts_to",
      source: "ts_to",
      ttoken: "putni_nalozi.ts_to",
      type: "datetime",
      validation: {
        required: true,
        isAfter: "ts_from",
        isAfterToken: "putni_nalozi.ts_from"
      }
    },
    {
      title: "vozilo_id",
      source: "vozilo_id",
      ttoken: "putni_nalozi.vozilo",
      type: "picker",
      subModel: {
        apiPath: "prijevoznici/{prijevoznik_id}/vozila?need_for=picker",
        format: "{label}, {registracija}"
      },
      validation: {
        required: true
      }
    },
    {
      title: "vozac_id",
      source: "vozac_id",
      ttoken: "putni_nalozi.vozac",
      type: "picker",
      subModel: {
        apiPath: "prijevoznici/{prijevoznik_id}/vozaci?need_for=picker",
        format: "{label}"
      },
      validation: {
        required: true
      }
    },
    {
      title: "km_from",
      source: "km_from",
      ttoken: "putni_nalozi.km_from",
      type: "wholenum",
      validation:{
        maxValue: 2147483647
      }
    },
    {
      title: "km_to",
      source: "km_to",
      ttoken: "putni_nalozi.km_to",
      type: "wholenum",
      validation:{
        maxValue: 2147483647
      }
    },
    {
      title: "opis",
      source: "opis",
      ttoken: "putni_nalozi.opis",
      type: "text",
      width: "full",
      rowsMax: 3,
      validation: {
        maxLength: 250
      }
    },
    buttonFields.edit,
    buttonFields.view,
    buttonFields.print
  ]
}
