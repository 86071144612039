import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import numbro from "numbro";

//Material UI
import Toolbar from "@material-ui/core/Toolbar";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Box from "@material-ui/core/Box";
import DialogActions from "@material-ui/core/DialogActions";

//Material-UI Icons
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";

//Custom Components
import withFormController from "Components/withFormController";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButton from "UI/Dialog/DialogActionButton";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogActionButtonCancel from "UI/Dialog/ActionButtons/DialogActionButtonCancel";
import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItemDelete from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemDelete";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import { GridContainer, GridItem } from "UI/Grid";
import FormContent from "Components/FormContent";
import DynamicDialog from "UI/Dialog/DynamicDialog";
import PrintButton from "UI/Buttons/PrintButton";

import DialogContext from "UI/DialogContext/DialogContext";
import ModelTable from "UI/Table/ModelTable";
import dataController from "Lib/dataController";
import modelFakture from "Models/fakture";
import finCalcmodel from "Models/fincalc";

function UpitFakturaDialog(props) {
  const snackbarContext = useContext(SnackbarContext);
  const dialogContext = useContext(DialogContext);
  const { t } = useTranslation();

  const [recordsStavke, setRecordsStavke] = useState([]);

  const { dc, mode, form, record, recordId, validation, fields, subModels, allowSkica, title, stavkeApiPath, upitId } = props; //HOC withFormController
  const { onFieldChange, onClose, doValidate } = props; //HOC withFormController
  const { doInsert, doUpdate, doClose, doDelete } = props; //HOC withFormController

  const dcFakturaStavke = new dataController(finCalcmodel, stavkeApiPath);

  const fNeoporezivo = "calc_neoporezivo";
  const fOporezivo = "calc_oporezivo";
  const fPorez = "calc_porez";
  const fUkupno = "calc_ukupno";
  const fStavke = "stavke";

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  useEffect(() => {
    if (mode === "view" || mode === "update") {
      if (recordId) {
        refreshStavke();
      }
    }

  }, [recordId])

  useEffect(() => {
    calculateTotals();
    if (onFieldChange) {
      onFieldChange(recordsStavke, fStavke);
    }
  }, [recordsStavke]);

  const refreshStavke = () => {
    dcFakturaStavke.GetData().then((resp) => {
      if (resp.success) {
        const stavke = Array.isArray(resp.data) ? resp.data : [];
        setRecordsStavke(stavke);
        if (onFieldChange) {
          onFieldChange(stavke, fStavke);
        }
      }
    })

  }

  const calculateTotals = () => {
    if (Array.isArray(recordsStavke) && recordsStavke.length > 0) {
      const calc_neoporezivo = recordsStavke
        .filter((x) => x.pdv_stopa === 0 || x.pdv_stopa.value === 0)
        .map((x) => numbro(x.iznos_neto).value())
        .reduce((a, b) => a + b, 0);
      const calc_oporezivo = recordsStavke
        .filter((x) => x.pdv_stopa === 25 || x.pdv_stopa.value === 25)
        .map((x) => numbro(x.iznos_neto).value())
        .reduce((a, b) => a + b, 0);

      const calc_porez = recordsStavke.map((x) => numbro(x.pdv_iznos).value()).reduce((a, b) => a + b, 0);
      const calc_ukupno = recordsStavke.map((x) => numbro(x.iznos_bruto).value()).reduce((a, b) => a + b, 0);

      if (onFieldChange) {
        onFieldChange(calc_neoporezivo, fNeoporezivo);
        onFieldChange(calc_oporezivo, fOporezivo);
        onFieldChange(calc_porez, fPorez);
        onFieldChange(calc_ukupno, fUkupno);
      }
    } else {
      if (onFieldChange) {
        onFieldChange(null, fNeoporezivo);
        onFieldChange(null, fOporezivo);
        onFieldChange(null, fPorez);
        onFieldChange(null, fUkupno);
      }
    }
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
        }
      });
    }
  };

  const close = (result) => {
    if (result.dataChanged) {
      onClose(result);
    } else {
      onClose({ dataChanged: false });
    }
  };

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: handleFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels
  };

  return (
    <DynamicDialog onClose={handleClose} maxWidth={"lg"}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogAvatar ariaLabel="" icon={<MonetizationOnIcon />} />
          <DialogToolbarHeading>{title ? title : t("titles.faktura")}</DialogToolbarHeading>
          <DialogToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer>
            <FormContent
              fieldNames={[
                "izdavatelj_bs_id",
                form === "ip" ? "kupac_komitent_id" : "kupac_bs_id",
                "oznaka_fakture",
                "poziv_na_broj",
                "datum_isporuke",
                "ts_izdavanja"
              ]}
              {...commonProps}
              columns={2}
            />
            <GridItem xs={12}>
              <ModelTable
                title={t("titles.faktura_stavke")}
                records={recordsStavke}
                dc={dcFakturaStavke}
                viewName={"readonly_noview"}
                allowExport={false}
                allowFilter={false}
                allowAdd={false}
                allowSelection={"none"}
              />
            </GridItem>
            <FormContent
              fieldNames={["calc_neoporezivo", "calc_oporezivo", "calc_porez", "calc_ukupno", "faktura_napomena"]}
              {...commonProps}
              columns={2}
            />
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <PrintButton
          dc={dc}
          target={"agencija-faktura"}
          record={Object.assign({upit_id: upitId}, record)}
          text={t("buttons.print")}
          disabled={false}
        />
        <DialogActionButtonClose variant="contained" onClick={handleClose} />
      </DialogActions>
    </DynamicDialog>
  );
}

export default withFormController(UpitFakturaDialog);
