//import patchedevent from 'Libs/patchedevent';
import moment from "moment";
import { formats } from "Lib/formats";
import api from "Lib/api";

import model from "Components/Scheduler/schedulerModel";
import dataController from "Lib/dataController";

//class SchedulerModel extends patchedevent {
class schedulerDataController {
  constructor(start_date) {
    //super();
    this.debug = false;

    const interval = schedulerDataController.calcInterval(start_date, 2);
    this.leftEdge = interval.from;
    this.rightEdge = interval.to;

    this.fields = [];
    this.records = [];
    this.tr_conflicts = {};
    this.conflictIntervalsTotal = [];

    /**
    conflictResources:
    key: integer (resourceId)
    value: object
    {
      conflicts: integer,
      intervals: array of objects:
        {
          from: moment object
          to: moment object
          parentId: integer (parent id)
        }
      conflictIntervals: array of arrays of conflicts
        0: from
        1: to
      type: string (vozilo|vozac) // maybe use some encodings for this?
    }
    */
    this.conflictResources = {};
    this.conflictIntervals = [];
    this.conflictIds = new Set();

    this.dc = new dataController(model);
    this.api = new api();

    this.CheckData = this.CheckData.bind(this);
    this.GetData = this.GetData.bind(this);
    this.parseData = this.parseData.bind(this);
    this.parseRecord = this.parseRecord.bind(this);
    this.chopIntervals = this.chopIntervals.bind(this);
    this.chopIntervalSingle = this.chopIntervalSingle.bind(this);
  }

  /**
    @name: CheckData
    @description: check if we are out of cached interval and fetch new data if we are
    @params:
      startDate: moment object (leftmost date shown in calendar)
      resolutionHours: integer (defined how much days are shown in the calendar)
      force: bool (if true fetch new data no matter the cached data)
    @returns:
      Promise:
        resolve(false) if we dont have to invalidate the cache
        resolve(new data) otherwise
        reject(error) if there was an error fetching data
    */
  CheckData(startDate, resolutionHours, force = false) {
    if (this.debug) {
      console.log("Check data: ", startDate.format(formats.datetime), resolutionHours, force);
    }

    const currentDateDiff = this.rightEdge.diff(this.leftEdge, "days");
    if (
      force || // force refresh data
      startDate.diff(this.leftEdge, "days") < currentDateDiff / 4 || // if we went to a date before leftmost for which we have gotten the data
      this.rightEdge.diff(startDate, "days") < currentDateDiff / 4   // if we went to a date after rightmost for which we have gotten the data
    ) {
      return this.GetData(startDate, resolutionHours)
        .then((resp) => {
          return new Promise((resolve, reject) => {
            return resp.success ?
              resolve([this.parseData(resp.data || []), this.conflictResources])
              : reject(resp);
          })
        })
        .catch((error) => Promise.reject(error));
    } else {
      return Promise.resolve(false);
    }
  }

  /**
    @name: GetData
    @description: communication point with mw api rest service, fetches the data
    @params:
      startDate: moment object
      resolutionHours: integer
    @returns:
      Promise:
        resolve: success: bool, [new data]: object
        reject: error: object?
    */
  GetData(startDate, resolutionHours) {
    if (this.debug) {
      console.log("GetData", startDate, resolutionHours);
    }

    const interval = schedulerDataController.calcInterval(startDate, resolutionHours);

    const intervalFrom = interval.from.format(formats.date_iso);
    const days = interval.to.diff( interval.from, 'days' );
    const blockHours = resolutionHours;

    const url = `kalendar/start/${intervalFrom}/${days}/${blockHours}`;

    return this.api.Call(url, "get")
      .then((resp) => {
        if (resp.success) {
          // hopefully we get interval from and to from db so we can better cache it
          this.leftEdge = interval.from;
          this.rightEdge = interval.to;
          return Promise.resolve({ success: true, data: resp.data });
        } else {
          return Promise.resolve({ success: false });
        }
      })
      .catch((error) => Promise.reject(error));
  }

  /**
    @name: parseData
    @description: initialize class variables and map fetched data through parseRecord
    @params:
      records: array of raw record objects
    @returns:
      array of record objects
    */
  parseData(records) {
    // initialize class data
    this.records = [];
    this.conflictResources = {};
    this.allRecordIntervals = [];
    return records.map((x,i) => this.parseRecord(x,i,records));
  }


  /**
    @name: parseRecord
    @description: parse record and fill class data for conflict calculation
    @params:
      r: raw record object
      pos: integer (position of current record in records array)
    @returns:
      record object
    */
  parseRecord(r, pos, records) {
    // str -> moment object
    r.ts1 = moment(r.ts1);
    r.ts2 = moment(r.ts2);
    r.grouped = true;
    if (r.maintype === 3) {
      r.dontShow = true;
      // r.id = r.linkid;
      r.parentId = r.trjob;
    }
    // TODO:
    // this object is legacy and must be refactored!!!!
    const content = {
      vozaci: [],
      vozilo: null,
      kooperant: null,
      opis: r.opis || ""
    };
    ;["vozac1", "vozac2", "vozac3", "vozilo", "kooperant"].forEach((fieldName) => {
      // we dont need to create data for the fieldName (intervals and conflicts) if it doesnt exist
      if (!r[fieldName]) { return; }
      // this record interval object
      if (fieldName === 'vozilo') {
        content.vozilo = {
          id: r[fieldName],
          label: r[fieldName + "_tag"]
        };
      } else if (fieldName === 'kooperant') {
        content.kooperant = {
          id: r[fieldName],
          label: r[fieldName + "_tag"]
        };
      } else {
        content.vozaci.push({
          id: r[fieldName],
          label: r[fieldName + "_tag"]
        });
      }
      const tempInterval = {
        from: r.ts1,
        to: r.ts2,
        parentId: r.trjob,
        id: r.id,
        lbl: r[fieldName + "_tag"],
        inConflict: false
      };
      // current fieldName
      const rFieldName = r[fieldName];
      // if we already have this resource check for conflicts
      if (this.conflictResources[r[fieldName]]) {
        // if we're ever gonna need this?
        this.allRecordIntervals.push(Object.assign({id: r.id}, tempInterval));

        const recordConflicts = [];
        let conflictCnt = 0;

        this.conflictResources[rFieldName].intervals.forEach(interval => {
          if (schedulerDataController.checkIntervalOverlap(tempInterval, interval)) {
            if (r.id === interval.id || (r.parentId && r.parentId === interval.id)) {
              return;
            }
            // console.log('conflict found', tempInterval, interval, r.parentId, interval.id);
            ++conflictCnt;
            tempInterval.inConflict = true;
            interval.inConflict = true;
            this.conflictResources[rFieldName].conflictIntervals.push(this.timeSetDifference(interval, tempInterval));
          }
        });

        this.conflictResources[rFieldName].conflicts += conflictCnt;
        this.conflictResources[rFieldName].intervals.push(tempInterval);
      } else if (fieldName !== 'kooperant') {
      // otherwise initialize the object
        this.conflictResources[r[fieldName]] = {
          conflicts: 0,
          intervals: [tempInterval],
          conflictIntervals: [],
          type: fieldName === "vozilo" ? "vozilo" : "vozac"
        };
      }
    });

    const sub_jobs = Array.isArray(records) ? records.filter(x => x.trjob === r.id && x.maintype === 3) : null;
    this.records.push(Object.assign(r, {
      content: content,
      subjobs: sub_jobs,
      hasSubJobs: r.maintype === 1 && sub_jobs.length > 0
    }));
    if (Array.isArray(sub_jobs) && r.maintype === 1) {
      sub_jobs.map(this.parseRecord);
    }
    return r;
  }

  /**
    @name: chopIntervals
    @description: chops all intervals in day by day smaller intervals
    @params: void
    @returns: conflictResources array
    */
  chopIntervals() {
    const cIds = new Set();
    const it = this.conflictIds.values();
    let v = it.next().value;
    while (v) {
      const [resourceId, other] = v.split("#");
      cIds.add(parseInt(resourceId, 10));
      v = it.next().value
    }
    let result = [];
    const id = cIds.values();
    v = id.next().value;

    while (v) {
      this.conflictResources[v].conflictIntervals.forEach(int => {
        // console.log(int, this.chopIntervalSingle(int))
        result = result.concat(this.chopIntervalSingle(int));
      })
      this.conflictResources[v].conflictIntervals = [...result];
      result = [];
      v = id.next().value;
    }
    return this.conflictResources;
  }

  /**
    @name: chopIntervalSingle
    @description: creates a day by day chopped intervals from single longer interval
    @params:
      int: conflict object
    @returns:
      conflict object
    */
  chopIntervalSingle(int) {
    const result = [];
    let first = int.from.clone();
    let currDay = moment(int.from.clone().format('YYYY-MM-DD')).add(1, 'days').subtract(1, 'minute');
    const lastDay = int.to.clone();
    while (currDay.isBefore(lastDay, 'days')) {
      result.push(Object.assign({}, int, {
        from: first.clone(),
        to: currDay.clone()
      }));
      first = currDay.clone().add(1, 'minute');
      currDay = currDay.add(1, 'days');
    }
    currDay.subtract(1, 'days');
    if (moment.duration(currDay.diff(lastDay)).asMinutes() > 5) {
      result.push(Object.assign({}, int, {
        from: currDay.clone(),
        to: lastDay.clone()
      }));
    }
    return result;
  }


  /**
    @name: timeSetDifference
    @description: creates a difference of time intervals which treats time as sets
    @params:
      int1: conflict object
      int2: conflict object
    @returns:
      conflict object
    */
  timeSetDifference(int1, int2) {
    if (int1.from.isSameOrAfter(int2.from) && int1.to.isSameOrBefore(int2.to)) {
      // [  {  }  ]
      return {...int1};
    } else if (int2.from.isSameOrAfter(int1.from) && int2.to.isSameOrBefore(int1.to)) {
      // {  [  ]  }
      return {...int2};
    } else if (int1.from.isBefore(int2.from)) {
      // {  [  }  ]
      return Object.assign({}, int1, {
        from: int2.from.clone(),
        to: int1.to.clone()
      })
    } else if (int2.from.isBefore(int1.from)) {
      // [  {  ]  }
      return Object.assign({}, int1, {
        from: int1.from.clone(),
        to: int2.to.clone()
      })
    } else {
      throw new Error("This scenario is not possible", int1, int2);
    }
  }

  // @Piero
  // move this somewhere more appropriate
  GetRows(start_date, end_date, res, cell_width, r_edge, userFilters, userShow) {
    return [[], []];
    let rows = [];
    let total_n = this.records.length;

    let isGroupVisibile = true;

    for( let i=0; i < total_n; i++ ){
      let r = this.records[ i ];

      let userShowTrue = true;
      let recordId = null;
      if (r.tip === 0 || r.tip === null) {
        userShowTrue = userShow.ostalo;
        recordId = r.sort3;
      } else if (r.tip >= 20 && r.tip <= 29) {
        userShowTrue = userShow.voznje;
        recordId = r.trjob;
      } else if (r.tip >= 30 && r.tip <= 39) {
        userShowTrue = userShow.vozila;
        recordId = r.sort3;
      } else if (r.tip >= 40 && r.tip <= 49) {
        userShowTrue = userShow.vozaci;
        recordId = r.sort3;
      } else if (r.tip >= 220 && r.tip <= 229) {
        userShowTrue = userShow.voznje;
        recordId = r.linkid;
      }

      if( !userShowTrue ){
        continue;
      }


      let filterKeys = Object.keys(userFilters);
      if (filterKeys.length > 0) {
        let includeRecord = false;
        let includeSummary = {}

        filterKeys.forEach(source => {

          const filterByValues = userFilters[source];
          const okValues = filterByValues.map(x => x.value);
          includeSummary[source ] = false; //all filters must be met

          switch (source) {
            case 'vozilo':
              const indv = okValues.indexOf(r['vozilo']);
              if (indv >= 0) {
                includeSummary[source] = true;
              }
              break;
            case 'vozac1':
              const ind = okValues.indexOf(r['vozac']);
              const ind1 = okValues.indexOf(r['vozac1']);
              const ind2 = okValues.indexOf(r['vozac2']);
              const ind3 = okValues.indexOf(r['vozac3']);
              if (ind >= 0 || ind1 >= 0 || ind2 >= 0 || ind3 >= 0 ) {
                includeSummary[source] = true;
              }
              break;
            case 'search':
              const recordValue = r['opis'] ? r['opis'].toLowerCase() : '';
              const searchValue = okValues[0].toLowerCase();
              const inds = recordValue.indexOf(searchValue);
              if (inds >= 0) {
                includeSummary[source] = true;
              }
              break;
          }
        })

        //all includeSummary keys must be true to render
        const notOk = Object.keys(includeSummary).find(key => includeSummary[key] === false);
        if (notOk) {
          continue;
        }
      }

      let isGroupRow = (r.tip >= 220 && r.tip <=229);

      let d = moment.duration( r.ts1.utc().diff( start_date.utc() ) );
      let offset = Math.floor( ( d.asMinutes() ) * (cell_width / res) ) / 60;

      let w = moment.duration( r.ts2.utc().diff( r.ts1.utc() ) );
      let width = w.asHours() * (cell_width / res);

      // check for vozaci / vozila
      let action = null;
      if( r.linkid ){
        action = 'check';
      }

      let conflictRecords = [];

      //['vozac1', 'vozac2', 'vozac3', 'vozilo'].forEach(( item ) => {
      //  const recordInterval = {'from': r.ts1, 'to': r.ts2}
      //  if( r[item] && Object.keys( this.tr_conflicts ).indexOf( r[ item ].toString() ) > -1  && this.tr_conflicts[ r[ item ].toString() ].conflicts > 0
      //      && this.checkInterval(recordInterval, this.tr_conflicts[ r[ item ].toString() ] , r.linkid ? null : r.trjob ) )
      //  {
      //    recordInterval.type = item === 'vozilo' ? 'vozilo' : 'vozac';
      //    recordInterval.conflictIntervals = this.tr_conflicts[ r[ item ].toString() ].conflictInterval;
      //    conflictRecords.push( recordInterval );
      //  }
      //})
      // How to getRecordObject for vozac and vozilo?? Here are numbers only
      let robj = {
        id: i,
        tip: r.tip,
        icon: action,
        label: r.lbl,
        content: {
          registration: {label: r.vozilo_tag, id: r.vozilo},
          driver: [
            {label: r.vozac1_tag, id: r.vozac1},
            {label: r.vozac2_tag, id: r.vozac2},
            {label: r.vozac3_tag, id: r.vozac3}
          ],
          description: {label: r.opis, id: r.linkid ? r.linkid : r.trjob},
          cooperant: {label: r.kooperant_tag, id: r.kooperant}
        },
        class: r.st,
        offset: offset,
        width: width,
        timeSpan: {from: r.ts1, to: r.ts2},
        color: r.hexcolor,
        tooltip: schedulerDataController.getTooltipLines( r ),
        conflict: conflictRecords,
        subjobs: r.sub_jobs,
        recordId: recordId,
        trjobId: r.trjob
      }
      rows.push( robj );
    }
    return [ rows, this.tr_conflicts ];
  }


  // @Piero
  // this should return a function by which we should sort the records for the scheduler
  // TODO:
  // check ig we need this, should we sort the rocords in the db?
  getSortFunction(sortType) {
    switch (sortType) {
      case 1:
        return (a, b) => {
          if (a.day1 < b.day1) {
            return -1;
          } else if (a.day1 > b.day1) {
            return 1;
          } else {
            if (a.djelatnik_id < b.djelatnik_id) {
              return -1;
            } else if (a.djelatnik_id > b.djelatnik_id) {
              return 1;
            } else {
              if (a.oprema_id < b.oprema_id) {
                return -1;
              } else if (a.oprema_id > b.oprema_id) {
                return 1;
              } else {
                return a.id < b.id ? -1 : 1;
              }
            }
          }
        };
      case 2:
        return (a, b) => {
          if (a.link_id < b.link_id) {
            return -1;
          } else if (a.link_id > b.link_id) {
            return 1;
          } else {
            if (a.day1 < b.day1) {
              return -1;
            } else if (a.day1 > b.day1) {
              return 1;
            } else {
              if (a.oprema_id < b.oprema_id) {
                return -1;
              } else if (a.oprema_id > b.oprema_id) {
                return 1;
              } else {
                return a.id < b.id ? -1 : 1;
              }
            }
          }
        };
      case 3:
        return (a, b) => {
          if (a.djelatnik_id < b.djelatnik_id) {
            return -1;
          } else if (a.djelatnik_id > b.djelatnik_id) {
            return 1;
          } else {
            if (a.day1 < b.day1) {
              return -1;
            } else if (a.day1 > b.day1) {
              return 1;
            } else {
              if (a.oprema_id < b.oprema_id) {
                return -1;
              } else if (a.oprema_id > b.oprema_id) {
                return 1;
              } else {
                return a.id < b.id ? -1 : 1;
              }
            }
          }
        };
      case 4:
        return (a, b) => {
          if (a.oprema_id < b.oprema_id) {
            return -1;
          } else if (a.oprema_id > b.oprema_id) {
            return 1;
          } else {
            if (a.day1 < b.day1) {
              return -1;
            } else if (a.day1 > b.day1) {
              return 1;
            } else {
              if (a.djelatnik_id < b.djelatnik_id) {
                return -1;
              } else if (a.djelatnik_id > b.djelatnik_id) {
                return 1;
              } else {
                return a.id < b.id ? -1 : 1;
              }
            }
          }
        };
      default:
        return (a, b) => {
          return a.id < b.id ? -1 : 1;
        };
    }
  }

/***************
  Static methods
****************/

  /**
    @name: remapIntervals
    @description: merges all overlaping intervals in the interval list
    @params:
      interval: moment object
      intervalList: moment object[]
    @returns:
      moment object[]
    */
  static remapIntervals(interval, intervalList = []) {
    const result = [];
    let i = 0, intPtr = 0;
    while (i < intervalList.length) {
      if (schedulerDataController.checkIntervalOverlap(intervalList[i], interval)) {
        interval = schedulerDataController.mergeIntervals(intervalList[i], interval);
        if (intPtr === result.length) {
          result.push({...interval});
        } else {
          result[intPtr] = {...interval};
        }
      } else {
        if (intPtr === result.length) {
          ++intPtr;
        }
        result.push({...intervalList[i]});
      }
      ++i;
    }
    return result;
  }

  /**
    @name: mergeIntervals
    @description: merges two intervals into one such that earlier from is result from and later to is result to
    @params:
      int1: moment object
      int2: moment object
    @returns:
      moment object
    */
  static mergeIntervals(int1, int2) {
    const ts1 = int1.from.isBefore(int2.from) ? int1.from : int2.from;
    const ts2 = int1.to.isAfter(int2.to) ? int1.to : int2.to;
    return Object.assign({}, int1, { from: ts1, to: ts2 });
  }

  /**
    @name: checkInterval
    @description: check if some interval intersects (conflict) any other in the intervalList
    @params:
      interval: moment object
      intervalList: array of objects:
        from: moment object
        to: moment object
        parentId: integer (parentId of the interval)
      id: integer (id of current interval)
    @returns:
      bool (true if passed interval has conflict with any other, false othewise)
    */
  static checkIntervalList(interval, intervalList, id) {
    // good thing with .some is that it will short circuit when one of the iterations returns true
    // copy this to browser console and check yourself
    // const asd = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    // console.log(asd.some((n) => {console.log(n); return n % 3 === 0;}))
    // it will log only first three items and then true
    return intervalList.conflictInterval.some((item) => {
      // check if current interval is the one checked against
      if (id && id !== item.parentId) {
        return false;
      }
      return schedulerDataController.checkIntervalOverlap(interval, item);
    });
  }

  /**
    @name: checkIntervalOverlap
    @description: compares two intervals and returns true if they overlap
    @params:
      int1: interval object
      int2: interval object:
      {
        from: moment object.
        to: moment object
      }
    @returns:
      bool
    */
  static checkIntervalOverlap(int1, int2) {
    return (
      // passed interval left edge is between currently checked interval edges
      (int1.from <= int2.from && int2.from < int1.to) ||
      // passed interval right edge is between currently checked interval edges
      (int1.from < int2.to && int2.to <= int1.to) ||
      // currently checked interval is completely between passed interval
      (int1.from >= int2.from && int1.to <= int2.to)
    );
  }

  /**
    @name: calcInterval (statis)
    @description: calculate left and right edge of display from start date and resolution in hourse
    @params:
      startDate: moment object
      resolutionHours: integer
    @returns:
      object:
        from: moment object
        to: moment object
    */
  static calcInterval(startDate, resolutionHours) {
    const nDays = schedulerDataController.getNDays(resolutionHours);

    const half = Math.ceil(nDays * 0.5);
    const bufferLeft = half * -1;
    const bufferRight = nDays + half;

    const from = startDate.clone().add(bufferLeft, "days");
    const to = startDate.clone().add(bufferRight, "days");

    return { from: from, to: to };
  }

  /**
    @name: getNDays (static)
    @description: get number of days visible depending on the resolution of hours
    @params:
      resolutionHours: integer
    @returns:
      integer
    */
  static getNDays(resolutionHours) {
    switch (resolutionHours) {
      case 1:
      case 2:
        return 7;
      case 3:
        return 14;
      case 4:
        return 21;
      case 6:
        return 28;
      case 8:
        return 35;
      case 12:
        return 56;
      case 24:
        return 98;
      default:
        return 7;
    }
  }

  /**
    @name: getTooltipLines
    @description: make a string to show in record tooltip
    @params:
      r: record object
    @returns:
      array:
        0: tooltip string
        1: record opis
    */
  static getTooltipLines(r) {
    return [
      `${r.kupac_tag ? r.kupac_tag + ',' : ''} ${r.kontakt ? r.kontakt + ',' : ''} od ${r.ts1.format(formats.datetime)} do ${r.ts2.format(formats.datetime)}`,
      // r.lbl,
      r.opis
    ];
  }
}

export default schedulerDataController;
