import React, { useContext, useCallback } from 'react'
import moment from "moment";

//Material-UI Icons
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";

//Custom Components
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import AlarmDialog from "Views/AlarmDialog";
import model from "Models/alarms";
import dataController from "Lib/dataController";

import { alarmsService } from "Services/alarmsService";

function useAlarm(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);

  const { record, alarmType, onFieldChange } = props;

  const recordId = record ? record.id : undefined;
  const alarm_ts = record ? record.alarm_ts : undefined;
  const alarm_active = record && record.alarm_active !== undefined && record.alarm_active !== null ? record.alarm_active : true;
  const alarm_remark = record ? record.alarm_remark : undefined;

  const dc = new dataController(model);

  const handleOpenAlarmDialog = useCallback(
    (evt) => {
      const alarmRecord = {
        alarm_ts: alarm_ts,
        alarm_active: alarm_active,
        alarm_remark: alarm_remark
      };
      const icon = getAlarmIcon(alarm_active, alarm_ts);

      dialogContext.showDialog(AlarmDialog, {
        dc: dc,
        mode: "insert",
        form: "default",
        onClose: handleCloseAlarmDialog,
        onSave: handleSaveAlarm,
        icon: icon,
        defaultValues: alarmRecord
      });
    },
    [alarm_ts, alarm_active, alarm_remark]
  );

  const handleCloseAlarmDialog = () => {
    dialogContext.hideDialog();
  }

  const handleSaveAlarm = useCallback(
    (newAlarmRecord) => {
    alarmsService.setAlarm(alarmType, recordId, newAlarmRecord)
      .then((resp) => {
        if (resp.success) {
          snackbarContext.showNotification("update", "success");
          if (onFieldChange) {
            onFieldChange(newAlarmRecord.alarm_ts, "alarm_ts");
            onFieldChange(newAlarmRecord.alarm_active, "alarm_active");
            onFieldChange(newAlarmRecord.alarm_remark, "alarm_remark");
          }
          dialogContext.hideDialog();
        } else {
          snackbarContext.showNotification(resp.error.message, "error");
        }
      });
  },
  [alarmType, recordId]
  );

  const getAlarmIcon = (isActive, ts) => {
    if (isActive === true && ts && moment.utc(ts) > moment.utc()) {
      return <NotificationsActiveIcon />;
    } else if (isActive === false) {
      return <NotificationsOffIcon />;
    } else {
      return <NotificationsIcon />;
    }
  }

  return {
    handleOpenAlarmDialog,
    handleSaveAlarm,
    getAlarmIcon
  };

}

export default useAlarm;