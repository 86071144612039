import React, { Fragment, useContext } from 'react';
import { useTranslation } from "react-i18next";

// Material-UI Components
import {
	ButtonGroup
,	Divider
, Grow
, Typography
} from "@material-ui/core";
 
// Custom Components
import { sifrarnici } from "Lib/sifrarnici";
import KomunikatorContext from "Components/KomunikatorContext/KomunikatorContext";
import UserContext from "Components/UserContext/UserContext";
import ChatMessageConfirmButton from './ChatMessageConfirmButton';

function ChatMessageConfirm(props) {
	const { t } = useTranslation();
	const { message, didAnswer, confirm, channelId, isReadOnly } = props;
	const { accepted, declined } = sifrarnici.msg_delivery_types;
	const KC = useContext(KomunikatorContext);
	const UC = useContext(UserContext);

	const onClick = (yes) => {
		KC.setDeliveryMessage(channelId, {
			...message,
			message_delivery_type_id: yes ? accepted : declined,
		});
	}

	const msgConfirmedOrDeclined = 
		message.message_delivery_type_id === accepted ||
		message.message_delivery_type_id === declined;
	const msgConfirmed = message.message_delivery_type_id === accepted;

	return(
		message.message_type_id === sifrarnici.msg_types.confirmation && !isReadOnly ?
			<Fragment>
				<Divider />
				{
					msgConfirmedOrDeclined ?
						<Grow in={true}>
							<Typography style={{color: msgConfirmed ? "#009944" : "#cf000f"}} variant="subtitle1" align="center">
								{t(msgConfirmed ? "common.accepted" : "common.denied")}!
							</Typography>
						</Grow>
					:
					message.confirmation_user_id === UC.userId ?
						<ButtonGroup variant="text" size="small" fullWidth>
							<ChatMessageConfirmButton yes={true} onClick={() => onClick(true)}/>					
							<ChatMessageConfirmButton yes={false} onClick={() => onClick(false)}/>					
						</ButtonGroup>
					: null
				}
			</Fragment>
			: null
	);
}

export default ChatMessageConfirm;