import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Icons
import SaveAltIcon from '@material-ui/icons/SaveAlt';

//Custom components
import CardToolbarMenuItem from "UI/Card/CardToolbarMenuItem";

const CardToolbarMenuItemSave = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { label } = props;

  return <CardToolbarMenuItem label={t(label)} icon={<SaveAltIcon />} onClick={props.onClick} ref={ref} />;
});

export default CardToolbarMenuItemSave;
