import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Box from "@material-ui/core/Box";

//Custom Components
import AgencijePage from "UI/AppPage/AgencijePage";
import AgencijeHome from "./AgencijeHome";

const AgencijeHomePage = React.memo((props) => {
  const { t } = useTranslation();

  return (
    <AgencijePage>
      <Box m={2}>
        <AgencijeHome />
      </Box>
    </AgencijePage>
  );
})

export default AgencijeHomePage;
