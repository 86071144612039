import React from "react";

//Material-UI Core Components
import Box from "@material-ui/core/Box";

//Custom Components
import AgencijePage from "UI/AppPage/AgencijePage";
import ProfileForm from "Views/ProfilePage/ProfileForm";

const AgencijeProfilePage = React.memo((props) => {
  return (
    <AgencijePage>
      <Box m={2}>
        <ProfileForm />
      </Box>
    </AgencijePage>
  );
})

export default AgencijeProfilePage;
