import React from "react";
import { useTranslation } from "react-i18next";

// Material UI Core
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import LocalOffer from "@material-ui/icons/LocalOffer";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import SearchIcon from "@material-ui/icons/Search";
import { Typography } from "@material-ui/core";

// Custom Components
import Background from "Images/test.jpg";
import withRedirect from "Components/withRedirect";
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import GoogleMap from "Components/Google/GoogleMap";
import BrzaTrazilica from "./BrzaTrazilica";
import dataController from "Lib/dataController";
import model from "Models/upiti";

const homeStyles = makeStyles((theme) => ({
  showContainer: {
    [theme.breakpoints.up("md")]: {
      backgroundImage: `url(${Background})`,
      objectFit: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
      height: "300px",
      borderRadius: "5px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginBottom: "10px"
    }
  },
  linkContainer: {
    width: "100%"
  },
  links: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  infoCard: {
    width: "100%",
    cursor: "pointer"
  },
  infoCardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center"
  }
}));

function AgencijeHome(props) {
  const {t} = useTranslation();

  const {redirect} = props; //HOC withRedirect

  const styles = homeStyles();

  const dc = new dataController(model);

  return (
      <GridContainer>
        <GridItem className={styles.showContainer} style={{padding: 0}} md={12} sm={12} xs={12}>
          <BrzaTrazilica
            dc={dc}
            mode={"insert"}
            form={"brzaTrazilica"}
          />
        </GridItem>
        <GridItem className={styles.linkContainer}>
          <GridContainer direction="row" wrap="wrap" justify="space-evenly" align="center">
            <GridItem className={styles.links} md={4} sm={4} xs={12}>
              <Card className={styles.infoCard}>
                <CardActionArea
                    className={styles.infoCardContent}
                    onClick={() => {
                      redirect("/a/upiti");
                    }}
                >
                  <Box m={2}>
                    <Typography variant="h6" component="h2" align="center" style={{width: "100%"}}>
                      {t("titles.upiti")}
                    </Typography>
                    <ShowChartIcon fontSize="large"/>
                    <Typography variant="body2" align="center" style={{width: "100%"}}>
                      {t("dashboard.upiti_caption")}
                    </Typography>
                  </Box>
                </CardActionArea>
              </Card>
            </GridItem>
            <GridItem className={styles.links} md={4} sm={4} xs={12}>
              <Card className={styles.infoCard}>
                <CardActionArea
                    className={styles.infoCardContent}
                    onClick={() => {
                      redirect("/a/trazilica");
                    }}
                >
                  <Box m={2}>
                    <Typography variant="h6" component="h2" align="center" style={{width: "100%"}}>
                      {t("titles.trazilica")}
                    </Typography>
                    <SearchIcon fontSize="large"/>
                    <Typography variant="body2" align="center" style={{width: "100%"}}>
                      {t("dashboard.trazilica_caption")}
                    </Typography>
                  </Box>
                </CardActionArea>
              </Card>
            </GridItem>
            <GridItem className={styles.links} md={4} sm={4} xs={12}>
              <Card className={styles.infoCard}>
                <CardActionArea
                    className={styles.infoCardContent}
                    onClick={() => {
                      redirect("/a/posebne-ponude");
                    }}
                >
                  <Box m={2}>
                    <Typography variant="h6" component="h2" align="center" style={{width: "100%"}}>
                      {t("titles.posebne_ponude")}
                    </Typography>
                    <LocalOffer fontSize="large"/>
                    <Typography variant="body2" align="center" style={{width: "100%"}}>
                      {t("dashboard.posebne_ponude_caption")}
                    </Typography>
                  </Box>
                </CardActionArea>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12}>
          <GoogleMap />
        </GridItem>
      </GridContainer>
  );
}

export default withRedirect(AgencijeHome);
