import React, { Fragment, useState, useContext } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';

// Material-UI Core Components
import {
  Avatar
  ,	Typography
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";


// Custom Components
import { helpers as H } from 'Lib/helpers';
import KomunikatorContext from "Components/KomunikatorContext/KomunikatorContext";
import KomunikatorFavButton from "UI/Komunikator/KomunikatorFavButton";
import { sifrarnici } from "Lib/sifrarnici";

function KomunikatorChannelDisplay(props) {
	const { t } = useTranslation();
	const { channel, channelSelected, onChannelClick, hasUnseen } = props;
	const KC = useContext(KomunikatorContext);
	const classes = useStyles(hasUnseen);

	const ts = moment(channel.message.created_on);
	const today = moment(new Date);

	// const user = KC.users.find(u => u.value === channel.message.created_by);
	const user = channel.message.message_type_id !== sifrarnici.msg_types.notice ? channel.message.created_by_full_name : null;
	const msgDisplay = `${user ? user + ": " : ""}${t(channel.message.body)}`;
	return (
		<div
			style={ channelSelected ? {backgroundColor: "#aaa", color: "#fff"} : {}}
			onClick={onChannelClick}
			className={classes.divWrapper}
		>
			<Avatar
				alt={channel.title}
				style={{backgroundColor: channel.color, width: "30px", height: "30px", alignSelf: "center"}}
			>
			{
				channel.img ?
					<img src={channel.img} />
					: channel.title[0]
			}
			</Avatar>
			<div style={{ padding: "0 8px", maxWidth: "280px" }}>
				<Typography
					className={classes.typography}
					noWrap={true}
					variant="body2"
				>
					{t(channel.title)}
				</Typography>
				<Typography
					variant="caption"
					noWrap={true}
					style={{display: "block", fontWeight: hasUnseen ? "bold" : "normal"}}
				>
					{msgDisplay.length > 35 ? `${msgDisplay.slice(0, 32)}...` : msgDisplay}
				</Typography>
			</div>
			<div style={{width: "100%", display: "flex", flexDirection: "row-reverse"}} >
        <KomunikatorFavButton channelId={channel.id} />
				<Typography
					className={classes.typography}
					align="right"
					variant="caption"
					style={{ margin: "auto 0" }}
				>
					{
						H.formatDate(ts, today)
					}
				</Typography>
				</div>
		</div>
	);
}

const useStyles = hasUnseen => makeStyles(theme => ({
	typography: {
		width: "auto",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    alignSelf: "flex-end",
    margin: "auto",
    fontWeight: hasUnseen ? "bold" : "normal"
	},
	divWrapper: {
		width: "100%",
		padding: "4px",
		display: "flex",
		cursor: "pointer",
		'&:hover': {
			backgroundColor: "#eee",
			color: "#000"
		}
	}
}))();

export default KomunikatorChannelDisplay;
