import { coreFields } from "./helpers/coreFields";
import { buttonFields } from "./helpers/buttonFields";

export default {
  title: "Financijske Obveze",
  exportFileName: "Financijske_Obveze",
  apiPath: "poslovi/",
  forms: {
    default: {
      fields: ["vl_duguje_hrk", "vl_potrazuje_hrk", "vl_saldo_hrk"]
    },
    popis_financ_obveze: {
      fields: [
        "datum_obveze",
        "komitent_id",
        "owner_duguje_hrk",
        "owner_potrazuje_hrk",
        "opis"
      ]
    },
    admin_popis_financ_obveze: {
      fields: [
        "datum_obveze",
        {
          source: "komitent_id",
          subModel: {
            apiPath: "admin/voznje/{posao_id}/komitenti",
            format: "{label}"
          }
        },
        "owner_duguje_hrk",
        "owner_potrazuje_hrk",
        "opis"
      ]
    }
  },
  listViews: {
    default: {
      fields: ["id", "datum_obveze", "komitent_id", "owner_duguje_hrk", "owner_potrazuje_hrk", "opis", "edit"]
    },
    readonly: {
      fields: ["id", "datum_obveze", "komitent_id", "owner_duguje_hrk", "owner_potrazuje_hrk", "opis", "view"]
    },
    admin_readonly: {
      fields: [
        "id",
        "datum_obveze",
        {
          source: "komitent_id",
          subModel: {
            apiPath: "admin/voznje/{posao_id}/komitenti",
            format: "{label}"
          }
        },
        "owner_duguje_hrk",
        "owner_potrazuje_hrk",
        "opis",
        "view"
      ]
    },
    kalkulacija: {
      fields: ["komitent_id", "vl_duguje_hrk", "vl_potrazuje_hrk", "vl_saldo_hrk"]
    },
    admin_kalkulacija: {
      fields: [
        {
          source: "komitent_id",
          subModel: {
            apiPath: "admin/voznje/{posao_id}/komitenti",
            format: "{label}"
          }
        },
        "vl_duguje_hrk",
        "vl_potrazuje_hrk",
        "vl_saldo_hrk"
      ]
    },
    obveze_komitent: {
      fields: ["id", "datum_obveze", "owner_duguje_hrk", "owner_potrazuje_hrk", "opis", "created_on", "posao_id", "view"],
      hidden: ["created_on"]
    },
    ukupno_komitent: {
      fields: ["vl_duguje_hrk", "vl_potrazuje_hrk", "vl_saldo_hrk"]
    },
    admin: {
      fields: [
        "id",
        "datum_obveze",
        "owner_duguje_hrk",
        "owner_potrazuje_hrk",
        "opis",
        "posao_id",
        "view"
      ]
    }
  },
  fields: [
    coreFields.id,
    coreFields.created_on,
    coreFields.created_by,
    {
      title: "Posao",
      source: "posao_id",
      type: "wholenum",
      ttoken: "common.posao",
      readonly: true
    },
    {
      title: "Owner duguje",
      source: "owner_duguje_hrk",
      type: "currency",
      ttoken: "financijskeObveze.owner_duguje",
      currency: "€",
      validation: {
        required: true,
        maxValue: 99999999
      }
    },
    {
      title: "owner_potrazuje_hrk",
      source: "owner_potrazuje_hrk",
      type: "currency",
      ttoken: "financijskeObveze.owner_potrazuje",
      currency: "€",
      validation: {
        required: true,
        maxValue: 99999999
      }
    },
    {
      title: "vl_saldo_hrk",
      source: "vl_saldo_hrk",
      type: "currency",
      ttoken: "financijskeObveze.vl_saldo",
      currency: "€",
      readonly: true,
      calculated: true,
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "datum_obveze",
      source: "datum_obveze",
      type: "date",
      ttoken: "financijskeObveze.datum",
      validation: {
        required: true
      }
    },
    {
      title: "komitent_id",
      source: "komitent_id",
      ttoken: "financijskeObveze.komitent",
      type: "picker",
      subModel: {
        apiPath: "prijevoznici/{prijevoznik_id}/komitenti?need_for=picker",
        format: "{label}"
      },
      validation: {
        required: true
      }
    },
    {
      title: "opis",
      source: "opis",
      type: "text",
      ttoken: "financijskeObveze.opis",
      validation: {
        maxLength: 250,
        required: true
      }
    },
    {
      title: "Owner duguje",
      source: "vl_duguje_hrk",
      type: "currency",
      ttoken: "financijskeObveze.owner_duguje",
      currency: "€",
      readonly: true,
      calculated: true,
      validation: {
        required: true,
        maxValue: 99999999
      }
    },
    {
      title: "Owner potrazuje",
      source: "vl_potrazuje_hrk",
      type: "currency",
      ttoken: "financijskeObveze.owner_potrazuje",
      currency: "€",
      readonly: true,
      calculated: true,
      validation: {
        required: true,
        maxValue: 99999999
      }
    },
    buttonFields.edit,
    buttonFields.view
  ]
};
