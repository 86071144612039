import { green, grey, lightBlue, lightGreen, orange, purple, red, yellow } from "@material-ui/core/colors";

export {
  upitStatusesOK,
  upitStatusesLost,
  ponudaStatusesOK,
  ponudaStatusesLost,
  posebnaPonudaStatuses,
  posloviStatuses,
  // retrieveStatusState,
  retrievePosloviStatusLabel,
  retrieveIconColor
};

const posloviStatuses = [
  {id: 20, label: "poslovi.statuses.priprema_ponude"},
  {id: 21, label: "poslovi.statuses.ponuda_izdana"},
  {id: 22, label: "poslovi.statuses.ponuda_prihvacana"},
  {id: 23, label: "poslovi.statuses.ugovoreno"},
  {id: 24, label: "poslovi.statuses.usluga_potvrdjeno"},
  {id: 25, label: "poslovi.statuses.voznja_u_tijeku"},
  {id: 26, label: "poslovi.statuses.voznja_zavrsena"},
  {id: 29, label: "poslovi.statuses.voznja_zatvorena"}
]

const posebnaPonudaStatuses = [
  {id: 10, label: "posebna_ponuda.statuses.10"},
  {id: 11, label: "posebna_ponuda.statuses.11"},
  {id: 12, label: "posebna_ponuda.statuses.12"},
  {id: 13, label: "posebna_ponuda.statuses.13"}
]

const upitStatusesOK = [
  {id: 10, label: "Skica upita"},
  {id: 11, label: "Objavljen upit"},
  {id: 13, label: "Ugovoreno"},
  {id: 14, label: "Potvrđeno"},
  {id: 15, label: "Usluga u tijeku"},
  {id: 16, label: "Usluga završena"},
  {id: 19, label: "Zatvoreno"}
];

const upitStatusesLost = [
  {id: 10, label: "Skica upita"},
  {id: 11, label: "Objavljen upit"},
  {id: 19, label: "Zatvoreno"}
];


const retrieveIconColor = (icon) => {
  switch(icon){
    case "drafts":
      return yellow["700"];
    case "send":
      return lightBlue["500"];
    case "hourglass_empty":
      return grey["500"];
    case "thumb_up":
      return lightGreen["500"];
    case "thumb_down":
      return red["500"];
    case "help":
      return orange["500"];
    case "assignment_turned_in":
      return lightGreen["500"];
    case "commute":
      return purple["500"];
    case "home":
      return lightGreen["500"];
    case "archive":
      return orange["500"];
    case "warning":
      return red["500"];
    case "remove_circle":
      return red["500"];
  }
}

const retrievePosloviStatusLabel = (id) => {
  return posloviStatuses.find((status) => status.id === id).label;
}

// const retrieveStatusState = (statusID) => {
//   const pack = function (percentage, color) {
//     return {percentage: percentage, color: color};
//   };

//   switch (statusID) {
//     case 10:
//       return pack(10, "#ffd600");
//     case 11:
//       return pack(20, "#ffd600");
//     case 12:
//       return pack(30, "#ffd600");
//     case 13:
//       return pack(50, "#00c853");
//     case 14:
//       return pack(60, "#00c853");
//     case 15:
//       return pack(70, "#00c853");
//     case 16:
//       return pack(80, "#00c853");
//     case 18:
//       return pack(99, "#d50000");
//     case 19:
//       return pack(100, "#00c853");
//     case 20:
//       return pack(10, "#ffd600");
//     case 21:
//       return pack(20, "#ffd600");
//     case 22:
//       return pack(30, "#ffd600");
//     case 23:
//       return pack(50, "#00c853");
//     case 24:
//       return pack(60, "#00c853");
//     case 25:
//       return pack(70, "#00c853");
//     case 26:
//       return pack(80, "#00c853");
//     case 27:
//       return pack(99, "#00c853");
//     case 28:
//       return pack(99, "#d50000");
//     case 29:
//       return pack(100, "#00c853");
//   }
// }

const ponudaStatusesOK = [
  {id: 10, label: "Skica ponude"},
  {id: 11, label: "Poslana ponuda"},
  //{id: 12, label: "Prihvaćena ponuda"},
  {id: 13, label: "Ugovoreno"},
  {id: 19, label: "Zatvoreno"}
];

const ponudaStatusesLost = [
  {id: 10, label: "Skica ponude"},
  {id: 11, label: "Poslana ponuda"},
  {id: 18, label: "Izgubljeno"}
];
