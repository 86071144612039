import api from "Lib/api";

class dataControllerSubModel {
  constructor(model, field, defaultValues) {
    const dv = defaultValues ? defaultValues : {};
    this.apiPath = this.parseApiPath(dv, field.subModel.apiPath);
    this.fields = model.fields;
    this.fieldId = this.fields.find(attr => {
      return attr.idattr === true;
    });

    this.records = [];

    this.defaultItem = field && field.items && field.items.default ? field.items.default : null;

    const fieldIdName = field.subModel.idattr ? field.subModel.idattr : null;
    if (fieldIdName) {
      this.fieldId = this.fields.find(attr => attr.source === fieldIdName);
    } else {
      this.fieldId = this.fields.find(attr => attr.idattr === true);
    }

    this.api = new api();
  }

  GetData() {
    if (this.records.length === 0) {
      return this.api.Call(this.apiPath, "get").then(resp => {
        if (resp.success) {
          if(resp.data) {
            this.records = resp.data.map(d => Object.assign({}, d));
          } else {
            this.records = [];
          }
        }
        return resp;
      });
    } else {
      return Promise.resolve({data: this.records})
    }
  }

  getRecordObject(recordId) {
    if (this.fieldId && (recordId !== null || recordId !== undefined)) {
      let res = this.records.find(r => {
        return r[this.fieldId.source] == recordId;
      });
      if (res) {
        return Object.assign({}, res); //clone object
      } else {
        return null;
      }
    }
    return null;
  }

  parseApiPath(defaultValues, currApiPath) {
    let keys = Object.keys(defaultValues);
    if (currApiPath.indexOf('{') >= 0 && currApiPath.indexOf('}') >= 0) {
      let label = currApiPath;
      if (keys.length > 0) {
        keys.forEach((k) => {
          let re = new RegExp('{' + k + '}');
          label = label.replace(re, defaultValues[k] != null ? defaultValues[k] : '');
        })
        return label;
      } else {
        return null;
      }

    } else {
      return currApiPath;
    }
  }

}

export default dataControllerSubModel;
