import React, { useContext } from 'react'

//Custom components
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import DialogContext from "UI/DialogContext/DialogContext";

import { adminService } from "Services/adminService";
import AssignMediatorDialog from "./AssignMediatorDialog";

function useMediation(props) {

  const snackbarContext = useContext(SnackbarContext);
  const dialogContext = useContext(DialogContext);

  const { dc, record, doRefresh } = props;

  const handleMediatorSubmit = (mediatorRecord) => {
    const data = {
      mediator_id: mediatorRecord.mediator_id.value
    };

    adminService
      .assignMediation(dc.apiPath, record.id, data)
      .then((resp) => {
        if (resp.success) {
          snackbarContext.showNotification("action", "success");
          if (doRefresh) {
            doRefresh();
          }
          dialogContext.hideDialog();
        }
      })
      .catch((resp) => {
        snackbarContext.showNotification(resp.error.message, "error");
      });
  }

  const handleMediatorCancel = () => {
    dialogContext.hideDialog();
  }

  const handleClaimMediation = () => {
    adminService
      .claimMediation(dc.apiPath, record.id)
      .then((resp) => {
        if (resp.success) {
          snackbarContext.showNotification("action", "success");
          if (doRefresh) {
            doRefresh();
          }
        }
      })
      .catch((resp) => {
        snackbarContext.showNotification(resp.error.message, "error");
      });
  };

  const handleAssignMediation = () => {
    const defaultValues = record.mediator_id ? { mediator_id: record.mediator_id} : undefined;

    dialogContext.showDialog(AssignMediatorDialog, {
      dc: dc,
      mode: "insert",
      form: "assign_mediator",
      onClose: handleMediatorCancel,
      onSubmit: handleMediatorSubmit,
      defaultValues: defaultValues
    });
  };

  return {
    handleClaimMediation,
    handleAssignMediation
  }
}

export default useMediation;