import React, { Fragment } from 'react'
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faCalculator } from '@fortawesome/free-solid-svg-icons'

//Material-UI Core Components
import TextField from '@material-ui/core/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";

//Material-UI Icons
import LockIcon from "@material-ui/icons/Lock";

class TextControl extends React.Component {

  constructor(props) {
    super(props);

    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.getLabel = this.getLabel.bind(this);
    this.getValue = this.getValue.bind(this);
    this.getHelperText = this.getHelperText.bind(this);
    this.checkIfRequired = this.checkIfRequired.bind(this);

    this.state = {
      isFocused: false
    }
  }

  handleChange(evt){
    const { field } = this.props;

    // There is regex in replace because without global parameter g replace replaces only the first occurence
    const value = (field && field.mask) ? evt.currentTarget.value.replace(/ /g, '') : evt.currentTarget.value;
    const source = field ? field.source : null;

    if (this.props.onChange) {
      this.props.onChange(value, source);
    }
  }

  handleFocus(evt) {
    const { onFocus } = this.props;
    this.setState({ isFocused: true });
    if (onFocus) {
      onFocus();
    }
  }

  handleBlur(evt) {
    const { onBlur } = this.props;
    this.setState({ isFocused: false });
    if (onBlur) {
      onBlur();
    }

  }

  getLabel() {
    const { field, label, t } = this.props;

    if (field) {
      return field.ttoken ? t(field.ttoken) : field.title;
    } else {
      return label;
    }
  }

  getValue(value){
    const { field, t } = this.props;

    if (field && field.items && field.items.values && field.items.labels){
      let pos = value ? field.items.values.indexOf(value) : field.items.values.indexOf( field.items.default );
      return t(field.items.labels[ pos ])
    } else {
      return (value === null || value === undefined) ? '' : value;
    }
  }

  getHelperText() {
    const { field, helperText, showHelper, t } = this.props;

    return field && field.tooltip ? t(field.tooltip) : (helperText ? helperText : (showHelper ? " " : null));
  }

  checkIfRequired() {
    const { field } = this.props;

    if (field) {
      return field.validation && field.validation.required;
    } else {
      return false;
    }
  }

  render() {
    const { field, formMode, controlMode, value, validation, multiline, rows, rowsMax, type, inputProps, additionalInputProps, inputLabelProps, t } = this.props;
    const { isFocused } = this.state;

    const isRequired = this.checkIfRequired();
    const hasValue = value !== undefined && value !== null && value !== '' ? true : false;
    const hasError = validation && validation.valid === false;
    const isReadOnly = controlMode === "view" || (field && field.readonly) || (field && field.type === "categorization");
    const isAutoFocused = field && field.focus === true;
    const doShowHelper = field === undefined || field.showHelper === undefined || field.showHelper === null ? true : field.showHelper;

    const label = this.getLabel();
    const helperText = this.getHelperText();
    const hasPlaceholder = field && field.placeholder ? true : false;

    const displayValue = this.getValue(value);

    const isValueCalculated = field && field.calculated ? true : false;

    const endAdornmentIcons = [];
    if (isValueCalculated) {
      endAdornmentIcons.push( <FontAwesomeIcon icon={faCalculator} color="grey" /> );
    }
    if (isReadOnly) {
      endAdornmentIcons.push( <LockIcon style={{ fontSize: 20 }} color="disabled" />  );
    }

    const endAdornment = [
      additionalInputProps && additionalInputProps.endAdornment
        ? additionalInputProps.endAdornment
        : null,
      isValueCalculated
        ? <InputAdornment>
            <div style={{padding: "8px"}}><FontAwesomeIcon icon={faCalculator} color="grey" /></div>
          </InputAdornment>
        : isReadOnly
        ? <InputAdornment><LockIcon style={{ fontSize: 20 }} color="disabled" /></InputAdornment>
        : null
    ]

    const InputProps = Object.assign({
        readOnly: isReadOnly,
        // endAdornment: isValueCalculated ? <FontAwesomeIcon icon={faCalculator} color="grey" /> :
        //               isReadOnly ? <LockIcon style={{ fontSize: 20 }} color="disabled" /> :
        //               null
        endAdornment: endAdornment,
      }, inputProps);

    const InputLabelProps = Object.assign({
      shrink: hasValue || isFocused || isAutoFocused || displayValue !== ''
    }, inputLabelProps);

    return(
      <TextField
        margin="none"
        fullWidth
        required={isRequired}
        error={hasError}
        label={ formMode === "form" ? t(label) : null}
        value={displayValue}
        placeholder={hasPlaceholder ? field.placeholder : ''}
        onChange={this.handleChange}
        onKeyDown={(evt) => {if (evt.keyCode == 13){this.props.onChange(evt, field ? field.source : null)}}}
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}
        InputProps={InputProps}
        inputProps={{
          autoComplete: "off"
        }}
        InputLabelProps={InputLabelProps}
        variant = {"outlined"}
        helperText={doShowHelper ? (hasError ? t(validation.msg) : t(helperText)) : null}
        autoFocus={isAutoFocused}
        type={type}
        color="secondary"
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
      />
    )
  }
}

TextControl.defaultProps = {
  formMode: "form",
  controlMode: "edit",
  multiline: false,
  rowsMax: 5,
  isValueCalculated: false,
  showHelper: true
}

TextControl.propTypes = {
  formMode: PropTypes.oneOf(["form", "table"]),
  controlMode: PropTypes.oneOf(["edit", "view"]),
  field: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isValueCalculated: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  validation: PropTypes.object,
  onChange: PropTypes.func,
  multiline: PropTypes.bool,
  rowsMax: PropTypes.number,
  showHelper: PropTypes.bool,
}


export default withTranslation()(TextControl);
