import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Swal from 'sweetalert2';

import confirmDialog from 'Lib/confirmDialog';
import withSnackbar from "Components/withSnackbar";

import appValidator from 'Lib/appValidator';
import dataControllerSubModel from "Lib/dataControllerSubModel";

import radio from "Models/submodels/radio";
import checkbox from "Models/submodels/checkbox";
import picker from "Models/submodels/picker";

import Loader from "UI/Loader/Loader";

function withFormController(WrappedComponent) {

  return class extends React.Component {
    constructor(props) {
      super(props);
      const { t } = props;

      this.handleFieldChange = this.handleFieldChange.bind(this);
      this.handleClose = this.handleClose.bind(this);

      this.handleInsert = this.handleInsert.bind(this);
      this.handleUpdate = this.handleUpdate.bind(this);
      this.handleDelete = this.handleDelete.bind(this);
      this.handleClear = this.handleClear.bind(this);
      this.handleSearch = this.handleSearch.bind(this);
      this.handleRefresh = this.handleRefresh.bind(this);
      this.handleValidate = this.handleValidate.bind(this);

      this.confirmUpdate = this.confirmUpdate.bind(this);
      this.confirmUpdateField = this.confirmUpdateField.bind(this);
      this.confirmDelete = this.confirmDelete.bind(this);
      this.confirmUpdateClose = this.confirmUpdateClose.bind(this);
      this.confirmInsertClose = this.confirmInsertClose.bind(this);

      this.serverErrorPromise = this.serverErrorPromise.bind(this);
      this.getFields = this.getFields.bind(this);
      this.getDefaultValues = this.getDefaultValues.bind(this);
      this.getFieldsWithModel = this.getFieldsWithModel.bind(this);
      this.checkIfChanged = this.checkIfChanged.bind(this);
      this.validate = this.validate.bind(this);

      this.validator = new appValidator(t);

      let record = {};

      if (props.recordId) {
        // do nothing -> we will get data on mount
      } else if (props.record) {
        record = props.record;
      } else {
        if (props.mode === "insert") {
          record = this.getDefaultValues();
        }
      }

      const recordCopy = Object.assign({}, record);
      const originalRecord = Object.assign({}, record);
      const fields = this.getFields();
      this.state = {
        originalRecord: originalRecord,
        record: recordCopy,
        fields: fields,
        validation: null,
        validated: false,
        dataChanged: false,
        subModels: null,
        loader: false,
        refreshCounter: 0
      }
    }

    setDefaultValues(record, defaultValues, force = false) {
      if (defaultValues) {
        if (force) {
          Object.keys(defaultValues).forEach(key => {
            record[key] = defaultValues[key];
          })
        } else {
          Object.keys(defaultValues).forEach(key => {
            if (!record.hasOwnProperty(key) || record[key] === undefined || record[key] === null) {
              record[key] = defaultValues[key];
            }
          })
        }
      }
    }

    componentDidMount() {
      const { recordId, defaultValues, dc, mode, secondary = null } = this.props;
      const { subModelDefaultValues } = this.props;
      this.mounted = true;

      if (recordId) {
        this.initialize(recordId)
      }

      const fieldsWithModel = this.getFieldsWithModel();

      Promise.all(fieldsWithModel).then(fModels => {
        const subModels = {};
        fModels.forEach(f => {
          const modelType = f.type === 'picker' ? picker
            : f.type === 'radio' ? radio
            : f.type === 'checkbox' ? checkbox
            : null;
          if (modelType !== null) {
            Object.assign(subModels, {[f.source]: new dataControllerSubModel(modelType, f, subModelDefaultValues)})
          }
        })
        this.setState({
          subModels: subModels
        })
      })
      .catch(err => {
        console.error("Error catching submodels", err);
      })
    }

    componentDidUpdate(prevProps) {
      const { record } = this.state;

      const prevDefaultValuesHash = prevProps.defaultValues ? Object.keys(prevProps.defaultValues).map(key => prevProps.defaultValues[key]).join('#') : '';
      const defaultValuesHash = this.props.defaultValues ? Object.keys(this.props.defaultValues).map(key => this.props.defaultValues[key]).join('#') : '';

      if (prevDefaultValuesHash !== defaultValuesHash) {
        console.log('refresh default values');
        this.setDefaultValues(record, this.props.defaultValues, true);

        this.setState({
          record: record
        });
      }

      if (prevProps.recordId !== this.props.recordId) {
        this.initialize(this.props.recordId);
      }
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    initialize(recordId) {
      const { defaultValues, dc, mode, secondary = null } = this.props;
      const secondaryPath = secondary ? secondary.replace("__split__", recordId) : "";

      if (secondary) {
        dc.GetData(secondaryPath)
        .then(resp => {
          if (resp.success) {
            const record = resp.data;

            if (defaultValues && mode === "insert") {
              this.setDefaultValues(record, defaultValues)
              // Object.keys(defaultValues).forEach(key => {
              //   if (!record.hasOwnProperty(key) || record[key] === undefined || record[key] === null) {
              //     record[key] = defaultValues[key];
              //   }
              // })
            }

            const recordCopy = Object.assign({}, record);
            const originalRecord = Object.assign({}, record);

            this.setState({
              originalRecord: originalRecord,
              record: recordCopy
            });
          }
        });
      } else {
        dc.GetDataSingle(recordId)
        .then(resp => {
          if (resp.success) {
            const record = resp.data;
            if (defaultValues && mode === "insert") {
              this.setDefaultValues(record, defaultValues);
              // Object.keys(defaultValues).forEach(key => {
              //   if (!record.hasOwnProperty(key) || record[key] === undefined || record[key] === null) {
              //     record[key] = defaultValues[key];
              //   }
              // })
            }

            const recordCopy = Object.assign({}, record);
            const originalRecord = Object.assign({}, record);

            this.setState({
              originalRecord: originalRecord,
              record: recordCopy
            });
          }
        });
      }
    }

    getDefaultValues(){
      const { dc, t, defaultValues } = this.props;
      const record = {};

      //set default values from fields
      dc.fields.forEach(attr => {
        if( attr.items && attr.items.hasOwnProperty('default') ){
          if ( attr.type === 'boolean' ){
            record[ attr.source ] = attr.items.default;
          } else if ( attr.type === 'radio'){
            const pos = attr.items.values.indexOf( attr.items.default );
            record[ attr.source ] = {
              label: t( attr.items.labels[ pos ] ),
              value: attr.items.default
            }
          } else if (attr.type === 'checkbox') {
            const valueArray = Array.isArray(attr.items.default) ? attr.items.default : [attr.items.default];
            record[ attr.source ] = valueArray;
          }
        }
      })

      //set default values from props
      if (defaultValues !== undefined && defaultValues !== null) {
        Object.keys(defaultValues).forEach(key => {
          record[key] = defaultValues[key];
        })
      }

      return record;
    }

    getFieldsWithModel() {
      const fields = this.getFields();
      const fieldModels = fields.filter(f => f.subModel);
      return fieldModels;
    }

    handleRefresh(){
      const { record, recordId, dc } = this.props;

      const rid = recordId || record.id;

      if (rid) {

        dc.GetDataSingle(rid)
        .then(resp => {
          if (resp.success) {
            const r = resp.data

            const recordCopy = Object.assign({}, r);
            const originalRecord = Object.assign({}, r);

            this.setState({
              originalRecord: originalRecord,
              record: recordCopy
            });
          }
        });
      }
    }

    handleFieldChange(value, source, callback) {
      const { validated } = this.state;

      this.setState(prevState => {
        let record = prevState.record
        if (!record) {
          record = {};
        }
        record[source] = value;
        if (callback && typeof callback === 'function') {
          // be careful not to mutate the record this way!
          callback(record);
        }
        return {
          record: record,
          dataChanged: this.checkIfChanged(record),
          refreshCounter: prevState.refreshCounter + 1
        }
      }, () => {
        if (validated) { this.validateField(source); }
      });
    }

    // TODO:
    // translations

    handleInsert() {
      const { record } = this.state;
      const { dc, t, showNotification, path } = this.props;

      const isValid = this.validate();

      if (isValid) {
        this.setState({loader: true})
        return dc.InsertRecord(record, path)
          .then((response) => {
            if (response && response.success) {
              const newId = response.id;
              if (this.props.onDataUpdate) {
                this.props.onDataUpdate();
              }
              return Promise.resolve({ success: true, id: newId });
            } else {
              return Promise.reject();
            }
          })
          .catch((response) => {
            switch (response.error.errorCode) {
              case 401:
                return this.serverErrorPromise({
                  email: {
                    valid: false,
                    msg:
                      "Korisnik s navedenom e-mail adresom je već registriran u sustavu."
                  }
                });
              case 9:
                return this.serverErrorPromise({
                  username: {
                    valid: false,
                    msg: "validation.existing_username"
                  }
                });
              }
              if(response.error.detail) {
                if(response.error.detail.errcode === 9) {
                  this.setState(prevState => {
                    const validation_ = prevState.validation;
                    validation_["username"] = this.validator.existingUsername();
                    return {
                      validation: validation_
                    }
                  })
                  return Promise.reject({
                    success:false,
                    validationPass: false,
                    error: { message: response.error.detail }
                  })
                }
              }
              return Promise.reject({
                success: false,
                validationPass: true,
                error: "Greška prilikom spremanja novih podataka! " + ( response && response.error && response.error.message ? response.error.message : '')
              })
          })
          .finally(() => {
            if (this.mounted) {
              this.setState({loader: false})
            }
          })
      } else {
        showNotification("messages.required_fields", "warning");
        return Promise.reject({ success: false, validationPass: false })
      }
    }

    handleUpdate() {
      const { record, validation } = this.state;
      const { dc, recordId, t, showNotification, path } = this.props;

      const isValid = this.validate();

      if (isValid) {
        this.setState({loader: true});
        return this.confirmUpdate()
          .then(result => {
            if (result.canceled) {
              return Promise.resolve({ success: false, canceled: true });
            }
            else if (result.confirmed) {
              return dc.UpdateRecord(record.id, record, path)
                .then((response) => {
                  if (response && response.success) {
                    this.setState({
                      dataChanged: false,
                      validated: false
                    })

                    if (this.props.onDataUpdate) {
                      this.props.onDataUpdate();
                    }

                    return Promise.resolve({ success: true, updated: true });
                  } else {
                    return Promise.resolve({ success: false });
                  }
                })
                .catch((response) => {
                  const { error } = response;
                  if (error && error.detail) {
                    return Promise.reject({ success: false, validationPass: true, error: error.detail });
                  } else {
                    return Promise.reject({ success: false, validationPass: true, error: "Greška prilikom ažuriranja podataka!" })
                  }
                })
                .finally(() => {
                  if (this.mounted) {
                    this.setState({loader: false})
                  }
                })
            } else {
              return Promise.resolve({ success: false });
            }
          })

      } else {
        showNotification("messages.required_fields", "warning");
        return Promise.resolve({ success: false, validationPass: false, validation: validation });
      }
    }

    handleDelete(apiPath = null) {
      const { dc, recordId } = this.props;

      return this.confirmDelete()
        .then(result => {
          if (result.canceled) {
            return Promise.resolve({ success: false, canceled: true });
          }
          if (result.confirmed) {
            return dc.DeleteRecord(recordId, apiPath)
              .then(response => {
                if (response && response.success) {
                  if (this.props.onDataUpdate) {
                    this.props.onDataUpdate();
                  }
                  return Promise.resolve({ success: true });
                } else {
                  if (response.error) {
                    return Promise.resolve({ success: false, error: response.error.errorCode.toString() })
                  } else {
                    return Promise.resolve({ success: false })
                  }
                }
              })
              .catch(response => {
                const { error } = response;
                if (error && error.detail) {
                  return Promise.reject({ success: false, error: error.detail });
                }
                return Promise.reject({ success: false, error: "Greška prilikom brisanja podataka" });
              })

          }
          else {
            return Promise.resolve({ success: false });
          }
        })
    }

    handleClear() {
      this.setState({
        record: {}
      })
      return Promise.resolve({ success: true });
    }

    handleSearch() {
      const isValid = this.validate();
      const { record, t, showNotification } = this.state;

      if (isValid) {
        return Promise.resolve({ success: true, filters: record })
      }
      else {
        showNotification("messages.required_fields", "warning");
        return Promise.resolve({ success: false, validationPass: false });
      }
    }

    handleClose(externalDataChanged = false) {
      const { dataChanged } = this.state;
      const { mode } = this.props;

      if (dataChanged || externalDataChanged) {
        if (mode === "insert") {
          return this.confirmInsertClose()
            .then(result => {
              if (result.canceled || result.confirmed) {
                return Promise.resolve({ success: false, canceled: true });
              } else {
                return Promise.resolve({ success: true });
              }
            });
        } else if (mode === "update") {
          return this.confirmUpdateClose()
            .then(result => {
              if (result.canceled) {
                return Promise.resolve({ success: false, canceled: true });
              }
              else if (result.confirmed) {
                return Promise.resolve({ success: false, shouldsave: true });
                //return this.handleUpdate();
              } else {
                return Promise.resolve({ success: true });
              }
            });
        } else if (mode === "view") {
          return Promise.resolve({ success: true })
        }
      } else {
        return Promise.resolve({ success: true })
      }
    }

    handleValidate() {
      return this.validate();
    }

    serverErrorPromise(serverValidation) {
      this.setState({
        validation: serverValidation
      });
      return Promise.resolve({
        success: false,
        validationPass: false,
        validation: serverValidation
      });
    }

    confirmUpdate() {
      const { originalRecord, record } = this.state;

      const fields = this.getFields();
      const checkFields = fields.filter(f => f.validation && f.validation.confirmchange);
      const changedFields = checkFields.filter(f => this.validator.checkIfFieldChanged(record[f.source], originalRecord[f.source]));

      if (changedFields && changedFields.length > 0) {
        return changedFields.reduce((previousPromise, field) => {
          return previousPromise.then(() => {
            return this.confirmUpdateField(field);
          });
        }, Promise.resolve());

      } else {
        return Promise.resolve({ confirmed: true });
      }
    }

    confirmUpdateField(field) {
      const { originalRecord, record } = this.state;

      const oldValue = originalRecord[field.source];
      const newValue = record[field.source];

      const title = "Želite li doista izmijeniti vrijednost polja '" + field.title + "'?";
      const text = "Stara vrijednost: " + oldValue + ", nova vrijednost: " + newValue;
      const type = "warning";
      const confirmButtonText = "Da, izmijeni";
      const cancelButtonText = "Ne, odustani";

      return confirmDialog(title, text, type, confirmButtonText, cancelButtonText);
    }

    confirmDelete() {
      const { t } = this.props;

      const title = t("cdialogs.are_you_sure");
      const text = t("cdialogs.once_deleted")
      const type = "warning";
      const confirmButtonText = t("cdialogs.yes_delete")
      const cancelButtonText = t("cdialogs.no_cancel")

      return confirmDialog(title, text, type, confirmButtonText, cancelButtonText);
    }

    confirmUpdateClose() {
      const { t } = this.props;

      const title = t("cdialogs.changes");
      const text = t("cdialogs.want_to_save");
      const type = "warning";
      const confirmButtonText = t("cdialogs.yes_save_changes");
      const cancelButtonText = t("cdialogs.no_just_close");

      return confirmDialog(title, text, type, confirmButtonText, cancelButtonText);
    }

    confirmInsertClose() {
      const { t } = this.props;

      const title = t("cdialogs.changes");
      const text = t("cdialogs.close_text");
      const type = "warning";
      const confirmButtonText = t("cdialogs.continue");
      const cancelButtonText = t("cdialogs.close");

      return confirmDialog(title, text, type, confirmButtonText, cancelButtonText);
    }

    checkIfChanged(record) {
      const { originalRecord } = this.state;
      const changed = this.validator.checkIfRecordChanged(record, originalRecord);
      return changed;
    }

    getValidator(record, t) {
      const { dc, form, mode } = this.props;

      const formId = form ? form : mode;
      const validateFunc = dc.getValidator(formId);
      if (this.validator[validateFunc] !== undefined && typeof this.validator[validateFunc] === 'function') {
        return this.validator[validateFunc](record, t);
      } else {
        return () => { return {} };
      }
    }

    getFields() {
      const { fieldNames, dc, form, controllerForm, mode } = this.props;

      let formId = form ? form : mode;

      let fields = [];

      //get all fields for controller
      if (controllerForm) {
        fields = dc.getFormFields(controllerForm);
      }

      //override with custom definitions
      let formFields = dc.getFormFields(formId);
      if (formFields) {
        return formFields
      }
      formFields.forEach(f => {
        const ind = fields.findIndex(x => x.source == f.source);
        if (ind >= 0) {
          fields[ind] = f;
        } else {
          fields.push(f);
        }
      })

      //filter by fieldnames if necessary
      if (fieldNames) {
        return fields.map(x => fieldNames.indexOf(x.source >= 0));
      } else {
        return fields;
      }
    }

    validate() {
      const { record } = this.state;
      const { t } = this.props;

      const fields = this.getFields();

      //model validation
      let validation = this.validator.validateModel(record, fields);

      //custom validation
      const customValidation = this.getValidator(record, t);

      const finalValidation = this.validator.mergeValidation(validation, customValidation);
      const isValid = this.validator.checkIfValid(finalValidation);

      this.setState({
        validation: finalValidation,
        validated: true
      });

      return isValid;
    }

    validateField(source) {
      const { record } = this.state;
      const fields = this.getFields();
      const field = fields.find(f => f.source === source);

      const fieldValidation = this.validator.validateField(record, field);

      this.setState(prevState => {
        const { validation } = prevState;
        validation[source] = fieldValidation;
        return { validation: validation };
      });
    }

    render() {
      const { record, refreshCounter, fields, validation, validated, subModels, dataChanged, loader } = this.state;

      // const fields = this.getFields();

      return (
        <Fragment>
          <WrappedComponent
            {...this.props}
            validation={validation}
            validated={validated}
            subModels={subModels}
            dataChanged={dataChanged}
            fields={fields}
            record={record}
            refreshCounter={refreshCounter}

            onFieldChange={this.handleFieldChange}
            doClose={this.handleClose}

            doInsert={this.handleInsert}
            doUpdate={this.handleUpdate}
            doDelete={this.handleDelete}
            doClear={this.handleClear}
            doPrepareSearch={this.handleSearch}
            doRefresh={this.handleRefresh}
            doValidate={this.handleValidate}
          />
          <Loader open={loader} />
        </Fragment>
      )
    }
  }
}

export default (Component) => withSnackbar(withFormController(withTranslation()(Component)));
