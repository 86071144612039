import React from 'react';

import FormAvatar from "UI/Form/FormAvatar";
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import VoziloIcon from "Icons/VoziloIcon";

function VoziloAvatar(props) {
  return <FormAvatar ariaLabel="vozilo" icon={<DirectionsBusIcon />} />;
}

export default VoziloAvatar;
