import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

// Material-UI Icons
import CloseIcon from "@material-ui/icons/Close";
import SendIcon from "@material-ui/icons/Send";

//Material UI
import Box from "@material-ui/core/Box";
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

//Custom components
import DynamicDialog from "UI/Dialog/DynamicDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButton from "UI/Dialog/DialogActionButton";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";

import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

import FormContent from "Components/FormContent";
import withFormController from "Components/withFormController";
import { komunikatorService as KS } from "Services/komunikatorService";

function KomunikatorDialogPanic(props) {
  const { title, channelId } = props;
  const { dc, record, validation, onFieldChange, fields, mode, form } = props;
  const { doClose, onClose, doValidate } = props;
  const { t } = useTranslation();

  const DC = useContext(DialogContext);
  const SC = useContext(SnackbarContext);

  const handleAdd = (evt) => {
    if (doValidate) {
      const isValid = doValidate();
      if (isValid) {
        const { record } = props;
        KS.setPanicMode(channelId, record).then((resp) => {
        	if (resp.success) {
        		close({ dataChanged: true });
        	} else {
        		SC.showNotification("insert", "error");
        	}
        })
      }
    }
  }

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
          // do nothing
        }
      });
    }
  };

  const close = (result) => {
    onClose({ dataChanged: result.dataChanged });
    DC.hideDialog();
  };

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    mode: mode
  };

  return (
      <DynamicDialog maxWidth={"sm"} onClose={handleClose}>
        <DialogHeader>
          <Toolbar variant="dense" disableGutters={true}>
            <DialogAvatar ariaLabel="" icon_word="group"/>
            <DialogToolbarHeading>
              {t(title)}
            </DialogToolbarHeading>
            <DialogToolbarFillContent/>
            <DialogToolbarButtonClose onClick={handleClose}/>
          </Toolbar>
        </DialogHeader>
        <DialogBody>
          <Box m={2}>
          	<Typography
          		align="center"
          		color="error"
          		paragraph
          		variant="body1"
          	>
          		{t("comm.panic_explanation")}
          	</Typography>
            <GridContainer spacing={0} direction="column" justify="flex-start">
              <FormContent
                {...commonProps}
                fieldNames={dc.getFormFieldsNames(form)}
                columns={1}
              />
            </GridContainer>
          </Box>
        </DialogBody>
        <DialogActions>
          <DialogActionButton variant="outlined" startIcon={<SendIcon />} onClick={handleAdd}>
          	{t("buttons.send")}
          </DialogActionButton>
          <DialogActionButtonClose variant="contained" onClick={handleClose} />
        </DialogActions>
      </DynamicDialog>
  );
}

export default withFormController(KomunikatorDialogPanic);
