import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";

//Custom components
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import AddButton from "UI/Buttons/AddButton";
import GooglePlacesAutocomplete from "./GooglePlacesAutocomplete";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@material-ui/core";
import { helpers } from "Lib/helpers";

function GooglePlacesViaControl(props) {
  const { t } = useTranslation();
  const [viaControls, setViaControls] = useState([]);

  const { field, onChange, value, controlMode } = props;

  const valueHash = Array.isArray(value) ? value.map((x) => x.id).join("#") : null;
  const viaControlsCount = Array.isArray(viaControls) ? viaControls.length : 0;

  const handleRemoveViaRoute = (id) => {
    setViaControls(viaControls.filter((c) => c.id !== id));
    onChange(
      value.filter((c) => c.id !== id),
      field.source
    );
  };

  const handleAddViaRoute = () => {
    const control = {
      id: helpers.uuidGenerator()
    };
    setViaControls(viaControls.concat(control));
  };

  const handleViaChange = useCallback(
    (val, id, currentState) => {
      if (onChange) {
        const newValue = viaControls.map((c) => {
          const currentStateVal = currentState ? currentState.find((x) => x.id === c.id) : null;
          const current_val = currentStateVal ? currentStateVal.value : null;
          return {
            id: c.id,
            value: c.id === id ? val : current_val
          };
        });

        onChange(newValue, field.source);
      }
    },
    [valueHash, viaControlsCount]
  );

  useEffect(() => {
    if (value) {
      const _viaControls = value.map((c) => {
        return {
          id: c.id,
          value: c.value
        };
      });
      setViaControls(_viaControls);
    }
  }, [valueHash]);

  const disabled = controlMode === "view" ? true : false;
  const hasControls = viaControls.length > 0;

  return (
    <GridContainer>
      {hasControls ? (
        <GridItem xs={12} md={12}>
          {viaControls.map((control, i) => {
            const _control = viaControls[i];
            const hasValue = Array.isArray(value) ? value.find((x) => x.id === _control.id) : null;

            return (
              <GridContainer
                direction="row"
                spacing={1}
                justify="center"
                alignItems="flex-start"
                key={"location-picker-" + i}
              >
                <GridItem sm={10} md={10}>
                  <GooglePlacesAutocomplete
                    controlMode={controlMode}
                    value={hasValue ? hasValue.value : null}
                    onChange={(v) => handleViaChange(v, control.id, value)}
                    field={{
                      source: `stop-${i}`
                    }}
                  />
                </GridItem>
                <GridItem sm={1} md={1}>
                  <Tooltip title={t("buttons.remove")}>
                    <IconButton
                      color="secondary"
                      size="medium"
                      variant="contained"
                      onClick={() => handleRemoveViaRoute(control.id)}
                      key={"remove-marker-button-" + i}
                      disabled={disabled}
                    >
                      <DeleteSweepIcon />
                    </IconButton>
                  </Tooltip>
                </GridItem>
              </GridContainer>
            );
          })}
        </GridItem>
      ) : null}
      <GridItem xs={12}>
        <AddButton onClick={handleAddViaRoute} label={t("trazilica.medjustajalista")} disabled={disabled} />
      </GridItem>
    </GridContainer>
  );
}

export default GooglePlacesViaControl;
