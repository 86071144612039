import React from "react";
import { useTranslation } from "react-i18next";

//Materual-UI Icons
import CancelIcon from "@material-ui/icons/Cancel";

//Custom Components
import DialogActionButton from "UI/Dialog/DialogActionButton";

function DialogActionButtonCancel(props) {
  const { t } = useTranslation();

  return (
    <DialogActionButton startIcon={<CancelIcon />} {...props} aria-label="cancel" aria-controls="cancel">
      {t("buttons.cancel")}
    </DialogActionButton>
  );
}

export default DialogActionButtonCancel;
