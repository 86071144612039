import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

// Custom Components
import ModelTable from "UI/Table/ModelTable";
import TableCard from "UI/Table/TableCard";
import TableCardContent from "UI/Table/TableCardContent";
import DialogContext from "UI/DialogContext/DialogContext";

import model from "Models/vozaci";
import dataController from "Lib/dataController";
import VozacDialog from "./VozacDialog";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import UserContext from "Components/UserContext/UserContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

// TODO:
// subModels cleanup: they are added to the project because Table.jsx can not function without them

function VozaciTable(props) {
  const dialogContext = useContext(DialogContext);
  const loaderContext = useContext(LoaderContext);
  const userContext = useContext(UserContext);
  const snackbarContext = useContext(SnackbarContext);

  const { t } = useTranslation();
  const [records, setRecords] = useState([]);

  const path = useMemo(() => `prijevoznici/${userContext.subjectId ? userContext.subjectId : -1}/vozaci`, [
    userContext.subjectId
  ]);
  const dc = new dataController(model, path);

  useEffect(() => {
    if (userContext.subjectId) {
      refreshRecords();
    }
  }, [userContext.subjectId]);

  const handleAdd = (evt) => {
    dialogContext.showDialog(VozacDialog, {
      dc: dc,
      mode: "insert",
      onClose: handleDialogClose,
      refreshRecords: refreshRecords
    });
  };

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords();
    }
  };

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData(path)
      .then((resp) => {
        if (resp.success) {
          setRecords(resp.data || []);
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const handleRowAction = function(action, id) {
    if (action === "edit") {
      dialogContext.showDialog(VozacDialog, {
        dc: dc,
        form: "update",
        mode: "update",
        onClose: handleDialogClose,
        recordId: id
      });
    }
  }

  const allowRowAction = function(action, id) {
    return true;
  }

  return (
    <TableCard>
      <TableCardContent>
        <ModelTable
          title={t("titles.vozaci")}
          records={records}
          dc={dc}
          allowExport={true}
          allowFilter={true}
          allowAdd={true}
          addLabel={t("buttons.new_m")}
          allowSelection={"none"}
          handleAdd={handleAdd}
          handleRefresh={refreshRecords}
          onRowAction={handleRowAction}
          allowRowAction={allowRowAction}
          onDoubleClick={(id) => handleRowAction("edit", id)}
        />
      </TableCardContent>
    </TableCard>
  );
}

export default VozaciTable;
