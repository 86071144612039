import React, { Fragment} from 'react'
import PropTypes from "prop-types";

//Material-UI Core Components
import makeStyles from '@material-ui/core/styles/makeStyles';
import withTheme from '@material-ui/core/styles/withTheme';
import Menu from '@material-ui/core/Menu';

//Material-UI Icons
import MenuIcon from '@material-ui/icons/Menu';

//Custom Components
import CardToolbarButton from 'UI/Card/CardToolbarButton';
import UserConsumer from "Components/UserContext/UserConsumer";


const customStyles =  makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.primary.main,
  },
  list: {
    color: theme.palette.common.black
  }
}));


function CardToolbarMenuButton(props) {
  const { needPermission } = props;

  const [ anchorEl, setAnchorEl] = React.useState(null);
  const classes = customStyles();

  const handleClick = evt => {
    setAnchorEl(evt.currentTarget);
  }

  const handleClose = (evt, reason) => {
    setAnchorEl(null);
  }

  const hookAction = action => {
    setAnchorEl(null);
    if (action) {
      action();
    }
  }

  const childrenWithProps = React.Children.map(props.children, child => {
    // checking isValidElement is the safe way and avoids a typescript error too
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { onClick: (evt) => hookAction(child.props.onClick) });
    }
    return child;
  });

  return (
    <UserConsumer>
      {userContext =>
        needPermission && !userContext.hasAnyPermission(needPermission) ? null : (
          <Fragment>
            <CardToolbarButton onClick={handleClick}>
              <MenuIcon />
            </CardToolbarButton>
            <Menu
              id="card-toolbar-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              classes={classes}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
             {childrenWithProps}
            </Menu>
          </Fragment>
        )
      }
    </UserConsumer>
  );
}

CardToolbarMenuButton.propTypes = {
  needPermission: PropTypes.arrayOf(PropTypes.object)
};

export default withTheme(CardToolbarMenuButton);