import React, { useContext } from "react";
import PropTypes from "prop-types";

//Material-UI Core Components
import IconButton from "@material-ui/core/IconButton";

//Custom Components
import UserContext from "Components/UserContext/UserContext";

function DialogToolbarButton(props) {
  const userContext = useContext(UserContext);

  const { needPermission, ...rest } = props;

  return needPermission && !userContext.hasAnyPermission(needPermission) ? null : (
    <IconButton className="nodrag" color="inherit" {...rest}>
      {props.children}
    </IconButton>
  );
}

DialogToolbarButton.propTypes = {
  needPermission: PropTypes.arrayOf(PropTypes.object)
};

export default DialogToolbarButton;
