import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Icons
import DeleteIcon from "@material-ui/icons/Delete";

//Custom Components
import TableButton from "UI/Table/TableButton";

function TableButtonDelete(props) {
  const { t } = useTranslation();

  return (
    <TableButton variant="outlined" allow="many" startIcon={<DeleteIcon />} {...props}>
      {t("buttons.delete")}
    </TableButton>
  );
}

export default TableButtonDelete;
