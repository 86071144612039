import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

//Material UI
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import DialogActions from "@material-ui/core/DialogActions";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

//Custom Components
import withFormController from "Components/withFormController";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogActionButtonAdd from "UI/Dialog/ActionButtons/DialogActionButtonAdd";
import DialogActionButtonSave from "UI/Dialog/ActionButtons/DialogActionButtonSave";
import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItemDelete from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemDelete";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import GridContainer from "UI/Grid/GridContainer";
import FormContent from "Components/FormContent";
import DynamicDialog from "UI/Dialog/DynamicDialog";
import DialogContext from "UI/DialogContext/DialogContext";
import EuroConversionComment from "Components/EuroConversionComment";

function FinObvezaDialog(props) {
  const snackbarContext = useContext(SnackbarContext);
  const dialogContext = useContext(DialogContext);
  const { t } = useTranslation();

  const { dc, mode, record, validation, fields, subModels } = props; //HOC withFormController
  const { onFieldChange, onClose } = props; //HOC withFormController
  const { doInsert, doUpdate, doClose, doDelete } = props; //HOC withFormController

  const handleDelete = () => {
    if (doDelete) {
      doDelete()
        .then((result) => {
          if (result.success) {
            close({ dataChanged: true, action: "delete" });
            snackbarContext.showNotification("delete", "success");
          }
        })
        .catch((result) => {
          snackbarContext.showNotification(result.error, "error");
        });
    }
  };

  const handleUpdate = () => {
    if (doUpdate) {
      doUpdate()
        .then((result) => {
          if (result.success) {
            close({ dataChanged: true, action: "update" });
            snackbarContext.showNotification("update", "success");
          }
        })
        .catch((result) => {
          if (result.success === false && result.validationPass === false) {
            //do nothing
          } else {
            snackbarContext.showNotification(result.error, "error");
          }
        });
    }
  };

  const handleInsert = () => {
    if (doInsert) {
      doInsert()
        .then((result) => {
          if (result.success) {
            close({ dataChanged: true, action: "insert", id: result.id });
            snackbarContext.showNotification("insert", "success");
          }
        })
        .catch((result) => {
          if (result.success === false && result.validationPass === false) {
            //do nothing
          } else {
            snackbarContext.showNotification(result.error, "error");
          }
        });
    }
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const close = (result) => {
    if (result.dataChanged) {
      onClose(result);
    } else {
      onClose({ dataChanged: false });
    }

    dialogContext.hideDialog();
  };

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels
  };

  return (
    <DynamicDialog maxWidth={"md"}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogAvatar ariaLabel="" icon={<MonetizationOnIcon />} />
          <DialogToolbarHeading>
            {mode == "insert" ? t("titles.financijskiObaveza_new") : t("titles.financijskiObaveza")}{" "}
            {record.id ? " (ID=" + record.id + ")" : ""}
          </DialogToolbarHeading>
          <DialogToolbarFillContent />
          {mode === "update" ? (
            <DialogToolbarMenuButton>
              <DialogToolbarMenuItemDelete onClick={handleDelete} />
            </DialogToolbarMenuButton>
          ) : null}
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer>
            <FormContent fieldNames={["datum_obveze", "komitent_id"]} {...commonProps} columns={2} />
            <FormContent fieldNames={["owner_duguje_hrk", "owner_potrazuje_hrk"]} {...commonProps} columns={2} />
            <FormContent fieldNames={["opis"]} {...commonProps} columns={1} />
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <EuroConversionComment />
        <DialogToolbarFillContent />
        <DialogActionButtonClose variant="outlined" onClick={onClose} />
        {mode === "insert" ? <DialogActionButtonAdd variant="contained" onClick={handleInsert} /> : null}
        {mode === "update" ? <DialogActionButtonSave variant="contained" onClick={handleUpdate} /> : null}
      </DialogActions>
    </DynamicDialog>
  );
}

export default withFormController(FinObvezaDialog);
