import createReport from "docx-templates";
import moment from "moment";
import numbro from "numbro";
import { formats } from "Lib/formats";

import api from "Lib/api";
import { loadWordTemplate, saveDataToFile } from "Lib/downloadTemplate";

const handlePrint = (posao, t) => {
  const apiInstance = new api();
  const posaoId = posao.id;
  const path = `poslovi/${posaoId}/potvrda-rezervacije`;
  apiInstance.Call(path, "get").then((resp) => {
    const data = resp.data;
    const wordTemplatePath = getWordTemplatePath();
    const fillFunction = getFillFunction(t);

    loadWordTemplate(wordTemplatePath, fillFunction(data));
  });
};

const handleAdminPrint = (record, t) => {

  const wordTemplatePath = getWordTemplatePath();
  const fillFunction = getFillFunction(t);

  loadWordTemplate(wordTemplatePath, fillFunction(record));
}

const handleAdminPrint2 = (posao, t) => {
  const apiInstance = new api();
  const posaoId = posao.id;
  const path = `admin/voznje/${posaoId}/potvrda-rezervacije`;
  apiInstance.Call(path, "get").then((resp) => {
    const data = resp.data;
    const wordTemplatePath = getWordTemplatePath();
    const fillFunction = getFillFunction(t);

    loadWordTemplate(wordTemplatePath, fillFunction(data));
  });
}

const getWordTemplatePath = () => {
  return "/templates/potvrda_rezervacije_template.docx";
};

const getFillFunction = (t) => {
  return (record) =>
    function (error, content) {
      if (error) {
        console.warn(error);
        throw error;
      }

      fillReport(t, content, record);
    }.bind(this);
};

const fillReport = (t, content, record) => {
  const report = createReport({
    template: content,
    data: {
      rid: record.posao_id
      , cbc_avans_iznos: record.cbc_avans_iznos
      , cbc_iban: record.cbc_iban
      , cbc_model: record.cbc_model
      , cbc_poziv_na_broj: record.cbc_poziv_na_broj
      , datum_potvrde: record.datum_potvrde ? moment(record.datum_potvrde).local().format(formats.datetime) : ""
      , from_loc_name: record.from_loc_name
      , from_datum: record.from_ts ? moment(record.from_ts).local().format(formats.datetime) : ""
      , to_datum: record.to_ts ? moment(record.to_ts).local().format(formats.datetime) : ""
      , id: record.id
      , narucitelj_id: record.narucitelj_id
      , narucitelj_naziv: record.narucitelj_naziv
      , ponuda_avans_dane: record.ponuda_avans_iznos ? "Da/Yes" : "Ne/No"
      , ponuda_avans_iznos: record.ponuda_avans_iznos ? numbro(record.ponuda_avans_iznos).format({ thousandSeparated: true, mantissa: 2 }) + " €" : "_____"
      , ponuda_avans_rok_uplate: record.ponuda_avans_rok_uplate ? moment(record.ponuda_avans_rok_uplate).local().format(formats.date) : "_____"
      , ponuda_iznos: record.ponuda_iznos ? numbro(record.ponuda_iznos).format({ thousandSeparated: true, mantissa: 2 }) + " €" : ""
      , ponuda_napomena: record.ponuda_napomena
      , ponuda_vozilo_id: record.ponuda_vozilo_id
      , ponuda_vozilo_name: record.ponuda_vozilo_name
      , ponuda_vozilo_opis: record.ponuda_vozilo_opis
      , posao_id: record.posao_id
      , prijevoznik_id: record.prijevoznik_id
      , prijevoznik_name: record.prijevoznik_name
      , rezervacija_id: record.rezervacija_id
      , stajalista_names: record.stajalista_names
      , to_loc_name: record.to_loc_name
      , upit_broj_putnika: record.upit_broj_putnika
      , upit_cijena_eur: record.upit_cijena_eur
      , upit_cijena_hrk: record.upit_cijena_hrk
      , upit_opis: record.upit_opis
      , upit_tip_vozila_name: record.upit_tip_vozila_name
    }
  });
  report.then((data) => {
    let filename = `potvrda_rezervacije_${record.id}.docx`;

    saveDataToFile(data, filename, "application/vnd.openxmlformats-officedocument.wordprocessingml.document");
  });
};

export const potvrdaRezervacijePrint = {
  handlePrint,
  handleAdminPrint
};
