import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import './styles/layout.css';
import './styles/map.css';
import './styles/react-datetime.css';
import './styles/index.css';
import './styles/scheduler.css';
import "./styles/a11y.css";

import "./i18n";
import "./numbro";

ReactDOM.render(<App/>, document.getElementById("root"));
