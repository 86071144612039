import React from "react";
import PropTypes from "prop-types";

//Custom Controls
import TextControl from "Controls/TextControl";

function FloatNumberControl(props) {
  const { onChange } = props;

  const handleChange = (value, source) => {
    let valid = false;
    if (value === "") {
      valid = true;
    }

    const valueCorrectDot = value.length > 0 ? value.replace(".", ",") : null;

    if (value && value.length > 0 && valueCorrectDot.match(/^[-|0-9]{1}[0-9]{0,}[,]{0,1}[0-9]{0,}$/)) {
      valid = true;
    }

    if (onChange && valid) {
      onChange(valueCorrectDot, source);
    }
  };

  return (
    <TextControl
      {...props}
      onChange={handleChange}
    />
  );
}

FloatNumberControl.defaultProps = {
  formMode: "form",
  controlMode: "edit"
};

FloatNumberControl.propTypes = {
  formMode: PropTypes.oneOf(["form", "table"]),
  controlMode: PropTypes.oneOf(["edit", "view"]),
  field: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  validation: PropTypes.object,
  onChange: PropTypes.func
};

export default FloatNumberControl;
