import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

//Material-UI Core Components
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Toolbar from "@material-ui/core/Toolbar";

//Material-UI Icons
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import MoneyIcon from "@material-ui/icons/Money";
import AssignmentIcon from "@material-ui/icons/Assignment";

//Custom Components
import withFormController from "Components/withFormController";
import { CardActionButtonClose, CardActionButtonShowFinCalc, CardActionButtonShowVoznje, CardHeading, CardToolbarButtonAlarm, CardToolbarButtonBack } from "UI/Card";
import { FormFillContent, FormHeading, FormTab, FormTabPanel, FormTabs } from "UI/Form";

import UserContext from "Components/UserContext/UserContext";
import PonudaAvatar from "UI/Form/Avatars/PonudaAvatar";
import GridContainer from "UI/Grid/GridContainer";
import FormContent from "Components/FormContent";
import TabPanelUpit from "Views/Ponude/Tabs/TabPanelUpit";
import TabPanelPojedinacneVoznje from "Views/Ponude/Tabs/TabPanelPojedinacneVoznje";
import TabPanelInternaKalkulacija from "Views/Ponude/Tabs/TabPanelInternaKalkulacija";
import EuroConversionComment from "Components/EuroConversionComment";

function PonudaForm11(props) {
  const userContext = useContext(UserContext);

  const history = useHistory();
  const { t } = useTranslation();

  const { upitId, dcUpiti, dc, statuses } = props;

  const { record, validation, fields, subModels, mode, recordId } = props; //HOC withFormController
  const { onFieldChange, doValidate } = props; //HOC withFormController
  const identifier = dc.getSource();
  const [currentTab, setCurrentTab] = useState(1);

  const [showTabVoznje, setShowTabVoznje] = useState(userContext.getRecent("toggleTabVoznje", identifier, false));
  const [showTabFinCalc, setShowTabFinCalc] = useState(userContext.getRecent("toggleTabFinCalc", identifier, false));

  const handleClickBack = () => {
    history.goBack();
  };

  const handleTabChange = (evt, value) => {
    if(value > tabIndexPonuda) {
      setCurrentTab(1)
    } else {
      setCurrentTab(value);
    }
  };

  const handleToggleTabVoznje = (evt) => {
    userContext.setRecent("toggleTabVoznje", identifier, !showTabVoznje);
    setShowTabVoznje(!showTabVoznje);
    if(currentTab > 0) {
      if(!showTabVoznje) {
        setCurrentTab(currentTab + 1);
      } else {
        setCurrentTab(currentTab - 1);
      }
    }
  };

  const handleToggleTabFinCalc = (evt) => {
    userContext.setRecent("toggleTabFinCalc", identifier, !showTabFinCalc);
    setShowTabFinCalc(!showTabFinCalc);
    if(currentTab > 0 && (currentTab !== 1 && showTabVoznje)) {
      if(!showTabFinCalc) {
        setCurrentTab(currentTab + 1);
      } else {
        setCurrentTab(currentTab - 1);
      }
    }
  };

  const avansAllowed = userContext.subjectAllowAvans;

  const commonProps = {
    record: record,
    validation: validation,
    fields: fields,
    mode: "view",
    subModels: subModels,
    recordId: recordId,
    columns: 2
  };
  const tabIndexFinCalc = 1 + (showTabVoznje ? 1 : 0);
  const tabIndexPonuda = tabIndexFinCalc + (showTabFinCalc ? 1 : 0);

  const header = t("titles.ponuda");
  const subheader = t("ponuda.statuses.11");

  const recordStatus = record ? record.ponuda_state_id : 11;

  const basedOnPosebnaPonuda = record && record.posebna_ponuda_id;

  return (
    <Card>
      <CardHeading recordStatus={recordStatus} statuses={statuses} color={basedOnPosebnaPonuda ? "#D4AF37" : null}>
        <Toolbar variant="dense" disableGutters={true}>
          <PonudaAvatar />
          <FormHeading header={header} subheader={subheader} />
          <FormFillContent />
          <CardToolbarButtonAlarm record={record} type="ponuda" onFieldChange={onFieldChange} />
          <CardToolbarButtonBack onClick={handleClickBack} />
        </Toolbar>
      </CardHeading>
      <CardContent style={{ padding: 0 }}>
        <FormTabs
          value={currentTab}
          onChange={handleTabChange}
          identifier={identifier}
        >
          <FormTab id="upit" label={t("ponuda.tabs.upit")} icon={<LiveHelpIcon />} aria-label="upit" />
          {showTabVoznje ? (
            <FormTab id="pojedinacne-voznje" label={t("ponuda.tabs.voznje")} icon={<AirportShuttleIcon />} aria-label="pojedinacne_voznje" />
          ) : null}
          {showTabFinCalc ? (
            <FormTab id="interna-kalkulacija" label={t("ponuda.tabs.kalkulacija")} icon={<MoneyIcon />} aria-label="interna_kalkulacija" />
          ) : null}
          <FormTab id="ponuda" label={t("ponuda.tabs.ponuda")} icon={<AssignmentIcon />} aria-label="ponuda" />
        </FormTabs>
        <TabPanelUpit
            currentTab={currentTab}
            tabIndex={0}
            dc={dcUpiti}
            mode="view"
            recordId={upitId}
            form="ponuda_tab"
        />
        {showTabVoznje ? (
          <TabPanelPojedinacneVoznje
            currentTab={currentTab}
            tabIndex={1}
            record={record}
            recordId={recordId ? recordId : record.id}
            validation={validation}
            mode="view"
            fields={fields}
            //onFieldChange={onFieldChange}
          />
        ) : null}
        {showTabFinCalc ? (
          <TabPanelInternaKalkulacija
            currentTab={currentTab}
            tabIndex={tabIndexFinCalc}
            record={record}
            recordId={recordId ? recordId : record.id}
            subModels={subModels}
            validation={validation}
            mode="view"
            fields={fields}
            //onFieldChange={onFieldChange}
          />
        ) : null}
        <FormTabPanel value={currentTab} index={tabIndexPonuda}>
          <GridContainer>
            <FormContent {...commonProps} fieldNames={["submitted_on", "submitted_by"]} />
            <FormContent
              {...commonProps}
              title={t("ponuda.sections.osnovno")}
              fieldNames={["from_ts", "to_ts", "relacija_opis_skraceni"]}
            />
            <FormContent {...commonProps} fieldNames={["broj_ponude", "datum_ponude", "rok_potvrda"]} columns={3} />
            <FormContent {...commonProps} fieldNames={["vozilo_id", "vozilo_opis", "napomena"]} />
            <FormContent
              {...commonProps}
              title={t("ponuda.sections.financije")}
              fieldNames={["iznos_hrk", "cbc_provizija_perc", "cbc_provizija_iznos", "iznos_s_cbc_provizijom"]}
              columns={4}
            />
            {avansAllowed ? <FormContent {...commonProps} fieldNames={["avans_iznos", "rok_avans_uplate"]} /> : null}
          </GridContainer>
        </FormTabPanel>
      </CardContent>
      <CardActions>
        <EuroConversionComment />
        <FormFillContent />
        <CardActionButtonClose variant="outlined" onClick={handleClickBack} />
        <CardActionButtonShowVoznje showTab={showTabVoznje} onToggle={handleToggleTabVoznje} />
        <CardActionButtonShowFinCalc showTab={showTabFinCalc} onToggle={handleToggleTabFinCalc} />
      </CardActions>
    </Card>
  );
}

export default withFormController(PonudaForm11);
