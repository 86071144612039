import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Custom components
import { Permissions } from "Lib/permissions";
import ModelTable from "UI/Table/ModelTable";
import TableCard from "UI/Table/TableCard";
import TableCardContent from "UI/Table/TableCardContent";
import dataController from "Lib/dataController";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import UserContext from "Components/UserContext/UserContext";
import model from "Models/poslovi";
import DialogContext from "UI/DialogContext/DialogContext";
import withRedirect from "Components/withRedirect";
import VoznjaDialog from "./VoznjaDialog";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

function VoznjeTable(props) {
  const { t } = useTranslation();
  const { state } = useLocation();

  const dialogContext = useContext(DialogContext);
  const loaderContext = useContext(LoaderContext);
  const userContext = useContext(UserContext);
  const snackbarContext = useContext(SnackbarContext);

  const { redirect } = props;

  const [records, setRecords] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const viewName = "voznje";

  const dc = new dataController(model);

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords();
    }

    dialogContext.hideDialog();
  };

  useEffect(() => {
    if (state) {
      const { active, tip_voznje_id, posao_state_id } = state;
      const identifier = dc.getSource() + viewName;
      userContext.setRecentFilters(identifier, "active", active);
      userContext.setRecentFilters(identifier, "tip_voznje_id", tip_voznje_id);
      userContext.setRecentFilters(identifier, "posao_state_id", posao_state_id);
      userContext.setRecent("toggle_filter", identifier, true);
    }
    refreshRecords();
  }, []);

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData()
      .then((resp) => {
        if (resp.success) {
          setRecords(Array.isArray(resp.data) ? resp.data : []);
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
        setLoaded(true)
      });
  };

  const handleAdd = () => {
    dialogContext.showDialog(VoznjaDialog, {
      dc: dc,
      form: "internalPosao",
      mode: "insert",
      onClose: handleDialogClose,
      subModelDefaultValues: {
        prijevoznik_id: userContext.subjectId
      }
    });
  };

  const handleRowAction = function (action, id) {
    const rec = records.find((x) => x.id === id);

    if (action === "view") {
      redirect(`/p/voznje/${id}`);
    } else if (action === "view_pp") {
      if (rec && rec.posebna_ponuda_id) {
        redirect(`/p/posebne-ponude/${rec.posebna_ponuda_id}`);
      }
    } else if (action === "view_ag") {
      if (userContext.hasAnyPermission([Permissions.AdminModule])) {
        redirect(`/admin/voznje/${rec.agentura_pposao_id}`);
      } else {
        redirect(`/p/voznje/${id}`);
      }
    }

    // else if (action === "summary") {
    //   if (rec) {
    //     const status = rec.posao_state_id;

    //     dialogContext.showDialog(VoznjaSazetakDialog, {
    //       dc: dc,
    //       form: "status" + status,
    //       mode: "view",
    //       recordId: id,
    //       onClose: handleDialogClose,
    //       external: true,
    //       subModelDefaultValues: {
    //         prijevoznik_id: userContext.subjectId
    //       }
    //     });
    //   }
    // }
  };

  const allowRowAction = function (action, id) {
    if (action === "view_pp") {
      const rec = records.find((x) => x.id === id);
      if (rec && rec.posebna_ponuda_id) {
        return true;
      } else {
        return false;
      }
    } else if (action === "view_ag") {
      const rec = records.find((x) => x.id === id);
      return rec && rec.agentura_pposao_id ? true : false;
    } else {
      return true;
    }
  };

  return (
    <TableCard>
      <TableCardContent>
        {loaded && (
          <ModelTable
            dc={dc}
            viewName={viewName}
            title={t("menu.voznje")}
            records={records}
            allowSelection="none"
            allowFilter={true}
            allowAdd={true}
            addLabel={t("buttons.new_f")}
            handleAdd={handleAdd}
            allowExport={true}
            subModelDefaultValues={{
              prijevoznik_id: userContext.subjectId
            }}
            onRowAction={handleRowAction}
            allowRowAction={allowRowAction}
            onDoubleClick={(id) => handleRowAction("view", id)}
            handleRefresh={refreshRecords}
          />
        )}
      </TableCardContent>
    </TableCard>
  );
}

export default withRedirect(VoznjeTable);
