import api from "Lib/api";

export const searchService = {
  searchVozila,
  getPrijevoznikPublicData,
  getVoziloPublicData,
  sendQuote
}

const vozilaUrl = "vozila/";
const bsUrl = "business-subjects/";
const prijevozniciUrl = "prijevoznici/";

function getPrijevoznikPublicData(prijevoznikId){
  const apiInstance = new api();
  const path = bsUrl + prijevoznikId + "/public";
  return apiInstance.Call(path, "get");
}

function getVoziloPublicData(voziloId){
  const apiInstance = new api();
  const path = vozilaUrl + voziloId + "/public";
  return apiInstance.Call(path, "get");
}

function searchVozila(record){
  let queryString = "";

  const query_obj = {
    from_loc: "start_loc=" + record["from_loc"].wkt,
    to_loc: "&end_loc=" + record["to_loc"].wkt,
    from_ts: "&start_date=" + encodeURIComponent(record["from_ts"]),
    to_ts: "&end_date=" + encodeURIComponent(record["to_ts"]),
    neto_udaljenost_voznje: "&net_distance=" + (record["neto_udaljenost_voznje"] + record["udaljenost_povratka_vozila"]).toString(),
    trajanje_voznje: "&time_taken=" + record["trajanje_voznje"],
    vozilo_tip_id: "&tip=" + record["vozilo_tip_id"],
    vozilo_broj_putnika: "&br_sjedala=" + record["vozilo_broj_putnika"],
    vozilo_klasa_id: "&klasa=" + record["vozilo_klasa_id"],
    klasa_motora_id: "&klasa_motora=" + record["klasa_motora_id"],
    vozilo_oprema_ids: "&oprema=" + record["vozilo_oprema_ids"],
    djeca_grupa: "&djecja_grupa=" + record["djeca_grupa"],
    povratna_ruta: "&povratna=" + record["povratna_ruta"]
  };

  Object.keys(query_obj).forEach((key) => {
    if (record[key] !== null && record[key] !== undefined) {
      queryString = queryString + query_obj[key]
    }
  })

  const apiInstance = new api();
  const path = vozilaUrl + "search?" + queryString;
  return apiInstance.Call(path, "get");
}

function sendQuote(data) {
  const apiInstance = new api();
  const path = "upiti/"
  return apiInstance.Call(path, "post", data)
}
