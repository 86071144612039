import React from 'react';
import { useTranslation } from "react-i18next";

//Material-UI
import CloseIcon from "@material-ui/icons/Close";

//Custom Components
import FormButton from "../Form/FormButton";

function CloseButton(props) {
  const {t} = useTranslation();
  const { needPermission, onClick} = props;

  return (
      <FormButton
          variant="outlined"
          startIcon={<CloseIcon/>}
          onClick={onClick}
          needPermission={needPermission}
      >
        {t("buttons.close")}
      </FormButton>
  );
}

export default CloseButton;
