import React from "react";
import { useTranslation } from "react-i18next";

//Materual-UI Icons
import CloseIcon from "@material-ui/icons/Close";

//Custom Components
import CardActionButton from "UI/Card/CardActionButton";

function CardActionButtonClose(props) {
  const { t } = useTranslation();

  return (
    <CardActionButton startIcon={<CloseIcon />} {...props} aria-label="close" aria-controls="close">
      {t("buttons.close")}
    </CardActionButton>
  );
}

export default CardActionButtonClose;
