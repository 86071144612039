import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import Chart from "react-apexcharts";

// Material-UI Core Components
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import withChart from "./withChart";
import ThemeContext from "Components/ThemeContext/ThemeContext";

function InfoChart(props) {
  const { t } = useTranslation();
  const { title, value, handleClick, icon } = props;

  const themeContext = useContext(ThemeContext);

  const [options, setOptions] = useState({});
  const [ctx, setCtx] = useState(null);

  const barData = {
    series: [
      {name: "series-1", data: [12, 16, 13, 8, 12, 3, 0, 12, 1, 8].reverse() }
    ],
    categories: (new Array(10).fill(0))
  }

  useEffect(() => {
    const initialOpts = {
      stroke: { show: true, width: 3 },
      chart: { 
        events: {
          beforeMount: (c) => setCtx(c)
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 1000,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        },
        toolbar: { show: false },
      },
      grid: { show: false },
      legend: { show: false },
      tooltip: { enabled: false },
      labels: [],
      xaxis: {
        categories: barData.categories,
        labels: { show: false },
        axisBorder: { show: false },
        axisTicks: { show: false },
        crosshairs: { show: false },
        tooltip: { enabled: false }
      },
      yaxis: {
        show: false,
        showAlways: false,
        showForNullSeries: false,
        labels: {
          formatter: (v) => t(v).split(' '),
          style: { fontSize: "14px" }
        }
      }
    }
    setOptions(initialOpts);
  }, [])

  useEffect(() => {
    if (ctx) {
      ctx.updateOptions({
        theme: {
          palette: "palette7",
          mode: themeContext.theme
        }
      }, true, true);
    }
  }, [themeContext.theme, ctx])


  return (
    <Box style={{ display: "flex", cursor: "pointer" }} p={2} onClick={handleClick}>
      { icon ? icon : null }
      <div style={{ width: "100%", display: "inline-block", textAlign: "center" }}>
        <Typography variant="body2" paragraph>
          {t(title)}
        </Typography>
        <Typography variant="h5">
          {value}
        </Typography>
      </div>
      <div style={{ maxWidth: "40%", display: "inline-block" }} >
        <Chart
          type="line"
          options={options}
          series={barData.series}
          height="80px"
        />
      </div>
    </Box>
  );
}


export default InfoChart;