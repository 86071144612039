import React, { Fragment } from "react";

//Material UI Core Components
import Dialog from '@material-ui/core/Dialog';
import Hidden from "@material-ui/core/Hidden";

//Custom Components
import DraggableDialog from "UI/Dialog/DraggableDialog";

function DynamicDialog(props) {

  const { fixedHeight, otherProps, children, maxWidth} = props;

  return (
    <Fragment>
      <Hidden mdDown>
        <DraggableDialog open={true} fullScreen={false} fixedHeight={fixedHeight} maxWidth={ maxWidth || "lg"} {...otherProps}>
          {children}
        </DraggableDialog>
      </Hidden>
      <Hidden lgUp>
        <Dialog open={true} fullScreen={true} {...otherProps}>
          {children}
        </Dialog>
      </Hidden>
    </Fragment>
  );

}

export default DynamicDialog;