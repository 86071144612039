import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";

// Material-UI Icons
import RefreshIcon from "@material-ui/icons/Refresh";
import AddIcon from "@material-ui/icons/Add";
import StarsIcon from "@material-ui/icons/Stars";

// Custom Components
import ToolbarButton from "UI/Toolbar/ToolbarButton";
import DialogContext from "UI/DialogContext/DialogContext";
import dataController from "Lib/dataController";
import modelVoznja from "Models/poslovi";
import modelPP from "Models/posebnePonude";
import VoznjaDialog from "Views/Prijevoznici/Voznje/VoznjaDialog";
import UserContext from "Components/UserContext/UserContext";
import confirm from "Lib/confirmDialog";
import PosebnaPonudaTransferDialog from "Views/Prijevoznici/PosebnePonude/PosebnaPonudaTransferDialog";
import PosebnaPonudaKruznaDialog from   "Views/Prijevoznici/PosebnePonude/PosebnaPonudaKruznaDialog";

function SchedulerToolbarActionsData(props) {
  const { t } = useTranslation();
  const dialogContext = useContext(DialogContext);
  const userContext = useContext(UserContext);

  const { openDateTime, handleRefresh, displayIcon, displayDate } = props;

  const handleAdd = () => {
    const dc = new dataController(modelVoznja);

    dialogContext.showDialog(VoznjaDialog, {
      //TODO: add ukupno_vodica to poslovi_ins
      dc: dc,
      form: "internalPosao",
      mode: "insert",
      onClose: handleDialogClose,
      subModelDefaultValues: {
        prijevoznik_id: userContext.subjectId
      }
    });
  };

  const confirmPosebnaPonudaType = () => {
    const title = t("cdialogs.posebna_ponuda.title");
    const text = t("cdialogs.posebna_ponuda.text");
    const type = "question";
    const confirmButtonText = t("cdialogs.posebna_ponuda.transfer");
    const cancelButtonText = t("cdialogs.posebna_ponuda.kruzna");

    return confirm(title, text, type, confirmButtonText, cancelButtonText);
  };

  const handleAddPosebnaPonuda = () => {
    confirmPosebnaPonudaType().then((result) => {
      if (!result.canceled) {
        const isKruzno = !result.confirmed;
        const DialogComponent = isKruzno ? PosebnaPonudaKruznaDialog :
          PosebnaPonudaTransferDialog;

        const dc = new dataController(modelPP);

        dialogContext.showDialog(DialogComponent, {
          dc: dc,
          mode: "insert",
          form: isKruzno ? "prijevoznikKruzni" : "prijevoznikTransfer",
          onClose: handleDialogClose,
          subModelDefaultValues: {
            prijevoznik_id: userContext.subjectId
          },
          defaultValues: {
            cbc_provizija_perc: userContext.subjectCBCProvision
          }
        });
      }
    });
  }

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      handleRefresh();
    }

    dialogContext.hideDialog();
  };

  return (
    <Fragment>
      <ToolbarButton
        onClick={openDateTime}
        style={{ width: 170, minWidth: 150 }}
        startIcon={displayIcon}
        color="secondary"
      >
        {displayDate}
      </ToolbarButton>
      <ToolbarButton onClick={handleAdd} color="secondary" startIcon={<AddIcon />}>
        {t("buttons.add_voznja")}
      </ToolbarButton>
      <ToolbarButton color="secondary" onClick={handleAddPosebnaPonuda}>
        <StarsIcon />
      </ToolbarButton>
      <ToolbarButton color="secondary" onClick={handleRefresh}>
        <RefreshIcon />
      </ToolbarButton>
    </Fragment>
  );
}

export default SchedulerToolbarActionsData;
