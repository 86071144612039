// These are some common address fields that are present in tables where address data is stored
// They can be added to UI models...

const adr_ulica = {
  title: "Ulica (i broj)",
  source: "adr_ulica",
  ttoken: "common.adr_ulica",
  type: "text",
  validation: {
    required: true,
    maxLength: 100
  }
};

const adr_grad = {
  title: "Grad",
  source: "adr_grad",
  ttoken: "common.adr_grad",
  type: "text",
  validation: {
    required: true,
    maxLength: 100
  }
};

const adr_pbr = {
  title: "Poštanski broj",
  source: "adr_pbr",
  ttoken: "common.adr_pbr",
  type: "text",
  validation: {
    required: true,
    maxLength: 10
  }
};

const adr_drzava = {
  title: "Država",
  source: "adr_drzava",
  ttoken: "common.adr_drzava",
  type: "text",
  validation: {
    required: true,
    maxLength: 100
  }
};

export const addressFields = {
  adr_ulica: adr_ulica,
  adr_pbr: adr_pbr,
  adr_grad: adr_grad,
  adr_drzava: adr_drzava
};
