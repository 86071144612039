import { coreFields } from "./helpers/coreFields";
import { buttonFields } from "./helpers/buttonFields";

export default {
  title: "Financijske kalkulacije",
  exportFileName: "Financijske_kalkulacije",
  apiPath: "generic/{generic_id}/financijske-kalkulacije",
  forms: {
    default: {
      fields: ["id", "opis", "iznos_neto", "pdv_stopa", "pdv_iznos", "iznos_bruto"]
    },
    popis_finCalc: {
      fields: ["id", "opis", "iznos_neto", "pdv_stopa", "pdv_iznos", "iznos_bruto"]
    }
  },
  listViews: {
    default: {
      fields: ["id", "opis", "iznos_neto", "pdv_stopa", "pdv_iznos", "iznos_bruto", "edit"],
      hidden: ["id"]
    },
    readonly: {
      fields: ["id", "opis", "iznos_neto", "pdv_stopa", "pdv_iznos", "iznos_bruto", "view"],
      hidden: ["id"]
    },
    readonly_noview: {
      fields: ["id", "opis", "iznos_neto", "pdv_stopa", "pdv_iznos", "iznos_bruto"],
      hidden: ["id"]
    },
    locked: {
      fields: ["id", "opis", "iznos_neto", "pdv_stopa", "pdv_iznos", "iznos_bruto"],
      hidden: ["id"]
    },
    posebnePonudaInsert: {
      fields: ["id", "opis", "iznos_neto", "pdv_stopa", "pdv_iznos", "iznos_bruto", "remove"],
      hidden: ["id"]
    },
    posebnePonudaUpdate: {
      fields: ["id", "opis", "iznos_neto", "pdv_stopa", "pdv_iznos", "iznos_bruto", "edit"],
      hidden: ["id"]
    },
    posebnePonudaView: {
      fields: ["id", "opis", "iznos_neto", "pdv_stopa", "pdv_iznos", "iznos_bruto"],
      hidden: ["id"]
    },
    izlazne: {
      fields: ["id", "opis", "iznos_neto", "pdv_stopa", "pdv_iznos", "iznos_bruto", "edit"],
      hidden: ["id"]
    },
    ulazne: {
      fields: ["id", "opis", "iznos_neto", "pdv_stopa", "pdv_iznos", "iznos_bruto", "edit"],
      hidden: ["id"]
    }
  },
  fields: [
    coreFields.id,
    {
      title: "Iznos Neto",
      source: "iznos_neto",
      type: "currency",
      ttoken: "financijskeKalkulacija.iznos_neto",
      validation: {
        required: true,
        maxValue: 99999999
      }
    },
    {
      title: "PDV Stopa",
      source: "pdv_stopa",
      ttoken: "financijskeKalkulacija.pdv_stopa",
      type: "picker",
      items: {
        labels: ["25.0%", "0%"],
        values: [25, 0]
      },
      validation: {
        required: true
      }
    },
    {
      title: "PDV Iznos",
      source: "pdv_iznos",
      type: "currency",
      ttoken: "financijskeKalkulacija.pdv_iznos",
      validation: {
        required: true,
        maxValue: 99999999
      },
      calculated: true,
      readonly: true
    },
    {
      title: "Iznos Bruto",
      source: "iznos_bruto",
      type: "currency",
      ttoken: "financijskeKalkulacija.iznos_bruto",
      validation: {
        required: true,
        maxValue: 99999999
      },
      calculated: true,
      readonly: true
    },
    {
      title: "Opis",
      source: "opis",
      ttoken: "financijskeKalkulacija.opis",
      type: "multiline",
      maxrows: 5,
      width: "full",
      bind: true,
      validation: {
        required: true,
        maxLength: 250
      }
    },
    buttonFields.edit,
    buttonFields.view,
    buttonFields.remove
  ]
};
