import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Custom Components
import ModelTable from "UI/Table/ModelTable";
import TableCard from "UI/Table/TableCard";
import TableCardContent from "UI/Table/TableCardContent";
import DialogContext from "UI/DialogContext/DialogContext";
import model from "Models/upiti";
import dataController from "Lib/dataController";
import UpitDialog from "Views/Upiti/UpitDialog";
import withRedirect from "Components/withRedirect";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

function UpitiTable(props) {
  const dialogContext = useContext(DialogContext);
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();

  const [records, setRecords] = useState([]);

  const { redirect } = props;

  const dc = new dataController(model);

  useEffect(() => {
    refreshRecords();
  }, []);

  const handleAdd = (evt) => {
    dialogContext.showDialog(UpitDialog, {
      dc: dc,
      mode: "insert",
      form: "insert",
      onClose: handleDialogClose,
      refreshRecords: refreshRecords
    });
  };

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords();
    }
  };

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData()
      .then((resp) => {
        if (resp.success) {
          setRecords(resp.data);
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const handleRowAction = function (action, id) {
    if (action === "edit") {
      redirect(`/a/upiti/${id}`);
    }
  };

  const allowRowAction = function (action, id) {
    if (action === "view_ag") {
      const rec = records.find(x => x.id === id);
      return rec && rec.agentura ? true : false;
    } else {
      return true;
    }
  };

  return (
    <TableCard>
      <TableCardContent>
        <ModelTable
          allowSelection="one"
          allowFilter={true}
          allowExport={true}
          allowAdd={true}
          title={t("titles.upiti_moji")}
          records={records}
          dc={dc}
          viewName={"moji_upiti"}
          allowExport={true}
          allowFilter={true}
          allowAdd={true}
          handleAdd={handleAdd}
          addLabel={t("buttons.new_m")}
          allowSelection={"none"}
          onRowAction={handleRowAction}
          allowRowAction={allowRowAction}
          onDoubleClick={(id) => handleRowAction("edit", id)}
          handleRefresh={refreshRecords}
        ></ModelTable>
      </TableCardContent>
    </TableCard>
  );
}

export default withRedirect(UpitiTable);
