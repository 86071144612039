import React from "react";
import { useTranslation } from "react-i18next";

import ReceiptIcon from '@material-ui/icons/Receipt';

import CardToolbarMenuItem from "UI/Card/CardToolbarMenuItem";

const CardToolbarMenuItemKorekcija = React.forwardRef((props, ref) => {
    const {t} = useTranslation();
    return(
        <CardToolbarMenuItem label={t("ponuda.korekcija_provizije")} icon={<ReceiptIcon/>} {...props} />
    )
});

export default CardToolbarMenuItemKorekcija;