import { coreFields } from "./helpers/coreFields";

export default {
  title: "images",
  exportFileName: "vozila_images",
  source: "images",
  apiPath: "",
  forms: {
    default: {
      fields: []
    }
  },
  listViews: {
    default: {
      fields: []
    }
  },
  fields: [
    coreFields.id,
    {
      title: "Title",
      source: "title",
      type: "text"
    },
    {
      title: "image",
      source: "image",
      ttoken: "vozilaImages.image",
      type: "images",
      maxFiles: 1,
      width: "full"
    }
  ]
};
