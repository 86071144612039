import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

//Custom Components
import { GridContainer, GridItem } from "UI/Grid";
import FormTabPanel from "UI/Form/FormTabPanel";
import FormContent from "Components/FormContent";
import { googleService } from "Services/googleService";
import GoogleMapRoute from "Components/Google/GoogleMapRoute";

function TabPanelUpitJavno(props) {
  const { currentTab, tabIndex } = props;
  const { recordId, record, validation, fields, onFieldChange, mode, subModels, internal, showNarucitelj } = props;

  const { t } = useTranslation();

  const fFromLoc = "from_loc";
  const fToLoc = "to_loc";
  const fVia = "stajalista";
  const fRoute = "ruta";

  const fTrajanjeVoznje = "trajanje_voznje";
  const fNetoUdaljenostVoznje = "neto_udaljenost_voznje";
  const fUdaljenostPovratka = "udaljenost_povratka_vozila";

  const handleFieldChange = (value, source) => {
    if (source === fFromLoc) {
      if (record[fToLoc]) {
        calcRoute(value, record[fVia], record[fToLoc]);
      }
    } else if (source === fVia) {
      if (record[fFromLoc] && record[fToLoc]) {
        calcRoute(record[fFromLoc], value, record[fToLoc]);
      }
    } else if (source === fToLoc) {
      if (record[fFromLoc]) {
        calcRoute(record[fFromLoc], record[fVia], value);
      }
    }

    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  useEffect(() => {
    if (record[fFromLoc] && record[fToLoc] && mode !== "view") {
      calcRoute(record[fFromLoc], record[fVia], record[fToLoc]);
    }
  }, [record[fFromLoc], record[fToLoc]]);

  const calcRoute = (from_loc, via, to_loc) => {
    const originCoords = from_loc ? from_loc.coords : null;
    const destCoords = to_loc ? to_loc.coords : null;

    const viaPoints = Array.isArray(via) ? via.filter((x) => x.value) : null;

    if (originCoords && destCoords) {
      googleService.route(from_loc, to_loc, viaPoints).then((resp) => {
        if (resp.success) {
          const duration = resp.data.duration;
          const length = resp.data.length;
          let returnLength = { value: 0, label: "0 km" };

          googleService.route(to_loc, from_loc, [], false).then((respReturn) => {
            if (respReturn.success) {
              returnLength = respReturn.data.length;

              onFieldChange(resp.data.routes, fRoute);
              onFieldChange(duration, fTrajanjeVoznje);
              onFieldChange(length, fNetoUdaljenostVoznje);

              onFieldChange(returnLength, fUdaljenostPovratka);
            }
          });
        }
      });
    } else {
      if (onFieldChange) {
        onFieldChange(null, fRoute);
      }
    }
  };

  const commonProps = {
    recordId: recordId,
    record: record,
    validation: validation,
    onFieldChange: handleFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels
  };

  const hasExactVozilo = record && record["vozilo_id"] ? true : false;

  const fieldNames = ["from_ts", "to_ts", "from_loc", "stajalista", "to_loc"];

  return (
    <FormTabPanel value={currentTab} index={tabIndex}>
      <GridContainer>
        <GridItem xs={12} sm={6}>
          <GridContainer>
            {showNarucitelj ? (
              <FormContent
                recordId={recordId}
                record={record}
                fields={fields}
                mode={"view"}
                subModels={subModels}
                title={t("common.narucitelj")}
                fieldNames={["narucitelj_naziv", "narucitelj_ocjena"]}
                columns={2}
              />
            ) : null}
            <FormContent {...commonProps} title={t("upit.sections.ruta")} fieldNames={fieldNames} columns={1} />
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <GoogleMapRoute
            height={600}
            origin={record ? record.from_loc : null}
            destination={record ? record.to_loc : null}
            stops={
              record && Array.isArray(record.stajalista)
                ? record.stajalista.filter((x) => x.value).map((x) => x.value)
                : []
            }
            route={record && record.ruta ? record.ruta[0] : null}
          />
        </GridItem>
        {internal ? null : (
          <FormContent
            {...commonProps}
            title={t("upit.sections.vozilo")}
            fieldNames={
              hasExactVozilo
                ? [
                    "vozilo_id",
                    "vozilo_tip_id",
                    "vozilo_klasa_id",
                    "klasa_motora_id",
                    "vozilo_broj_putnika",
                    "opis",
                    "vozilo_oprema_ids"
                  ]
                : [
                    "vozilo_tip_id",
                    "vozilo_klasa_id",
                    "klasa_motora_id",
                    "vozilo_broj_putnika",
                    "opis",
                    "vozilo_oprema_ids"
                  ]
            }
            columns={hasExactVozilo ? 4 : 3}
          />
        )}
        <FormContent
          {...commonProps}
          title={t("upit.sections.podaci_o_putovanju")}
          fieldNames={["trajanje_voznje", "neto_udaljenost_voznje", "udaljenost_povratka_vozila"]}
          columns={3}
        />
        {internal ? null : (
          <FormContent
            {...commonProps}
            title={t("upit.sections.ponuda")}
            fieldNames={["cijena_hrk", "rok_za_ponude"]}
            columns={3}
          />
        )}
      </GridContainer>
    </FormTabPanel>
  );
}

TabPanelUpitJavno.defaultProps = {
  mode: "update"
};

TabPanelUpitJavno.propTypes = {
  currentTab: PropTypes.number,
  tabIndex: PropTypes.number,
  recordId: PropTypes.number,
  record: PropTypes.object,
  validation: PropTypes.object,
  onFieldChange: PropTypes.func,
  fields: PropTypes.array,
  mode: PropTypes.oneOf(["view", "update"])
};

export default TabPanelUpitJavno;
