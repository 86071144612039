import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Box from "@material-ui/core/Box";

//Custom Components
import { GridContainer, GridItem } from "UI/Grid";
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import UserContext from "Components/UserContext/UserContext";
import ModelTable from "UI/Table/ModelTable";
import dataController from "Lib/dataController";
import modelFakture from "Models/fakture";
import finCalcmodel from "Models/fincalc";
import AdminFakturaDialog from "Views/Administration//AdminVoznje/Dialogs/AdminFakturaDialog";
import { fakturaPrint } from "Lib/print/fakturaPrint";

function AdminVoznjaTabFakture(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const loaderContext = useContext(LoaderContext);
  const userContext = useContext(UserContext);
  const { t } = useTranslation();

  const [recordsFakture, setRecordsFakture] = useState([]);
  const [recordsStavke, setRecordsStavke] = useState([]);

  const { record, recordId, mode, onConfirm, isPanic } = props;

  const dcFakture = new dataController(modelFakture, `admin/voznje/${recordId}/fakture`);
  const dcFakturaStavke = new dataController(finCalcmodel);

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dcFakture
      .GetData()
      .then((resp) => {
        if (resp.success) {
          if (resp.data) {
            setRecordsFakture(resp.data);
          }
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      })
  };

  const refreshStavke = (id) => {
    loaderContext.toggleLoading(true);
    const dcFakturaStavkeSpecific = new dataController(finCalcmodel, `admin/voznje/${recordId}/fakture/${id}/stavke`);
    dcFakturaStavkeSpecific
      .GetData()
      .then((resp) => {
        if (resp && resp.data && resp.success) {
          setRecordsStavke(resp.data);
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const handleSelectFaktura = (id) => {
    if (id) {
      refreshStavke(id)
    } else {
      setRecordsStavke([]);
    }
  }

  useEffect(() => {
    refreshRecords();
  }, [recordId]);

  const handleAddFaktura = () => {


    dialogContext.showDialog(AdminFakturaDialog, {
      dc: dcFakture,
      mode: "insert",
      form: "admin_pp",
      onClose: handleDialogClose,
      allowSkica: false,
      defaultValues: {
        posao_id: record.id,
        izdavatelj_bs_id: userContext.platformBSId,
        kupac_bs_id: record.narucitelj_id
      },
      subModelDefaultValues: {
        posao_id: record.id
      }
    });
  }


  const handleRowActionFaktura = function (action, id) {
    if (action === "view") {
      dialogContext.showDialog(AdminFakturaDialog, {
        dc: dcFakture,
        mode: "view",
        form: "pp",
        onClose: handleDialogClose,
        allowSkica: false,
        recordId: id,
        stavkeApiPath: `admin/voznje/${recordId}/fakture/${id}/stavke`,
        subModelDefaultValues: {
          posao_id: record.id
        }
      });
    } else if (action === "print") {
      const rec = {
        id: id,
        posao_id: recordId
      };
      fakturaPrint.handleAdminPrint(rec, t);
    }
  };

  const handleRowActionStavka = function (action, id) {
    // if (action === "edit" || action === "view") {
    //   dialogContext.showDialog(FakturaStavkaDialog, {
    //     dc: dcFakturaStavke,
    //     mode: record.posao_state_id === 29 || isPanic ? "view" : "update",
    //     form: "pp",
    //     recordId: id,
    //     onClose: handleDialogClose
    //   });
    // }
  };

  const allowRowActionFaktura = function (action, id) {
    return true;
  };

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords();
      if (result.id) {
        refreshStavke(result.id);
      }
    }
    dialogContext.hideDialog();
  };

  return (
      <GridContainer>
        <GridItem xs={12}>
          <ModelTable
            title={t("titles.fakture")}
            dc={dcFakture}
            viewName={"admin"}
            allowExport={true}
            allowFilter={true}
            allowAdd={isPanic ? false: true}
            addLabel={t("buttons.new_f")}
            handleAdd={handleAddFaktura}
            allowSelection="one"
            onRowClick={handleSelectFaktura}
            records={Array.isArray(recordsFakture) ? recordsFakture : []}
            onRowAction={handleRowActionFaktura}
            allowRowAction={allowRowActionFaktura}
            subModelDefaultValues={{
              prijevoznik_id: userContext.subjectId
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <ModelTable
            title={t("titles.faktura_stavke")}
            dc={dcFakturaStavke}
            viewName={"readonly_noview"}
            allowExport={true}
            allowFilter={true}
            allowAdd={false}
            allowSelection="none"
            records={Array.isArray(recordsStavke) ? recordsStavke : []}
            onRowAction={handleRowActionStavka}
          />
        </GridItem>
      </GridContainer>
  );
}

AdminVoznjaTabFakture.defaultProps = {
  mode: "update"
};

AdminVoznjaTabFakture.propTypes = {
  dc: PropTypes.object,
  recordId: PropTypes.number,
  record: PropTypes.object,
  validation: PropTypes.object,
  onFieldChange: PropTypes.func,
  fields: PropTypes.array,
  mode: PropTypes.oneOf(["view", "update"]),
  onConfirm: PropTypes.func
};

export default AdminVoznjaTabFakture;
