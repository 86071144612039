import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Custom Components
import ModelTable from "UI/Table/ModelTable";
import TableCard from "UI/Table/TableCard";
import TableCardContent from "UI/Table/TableCardContent";
import DialogContext from "UI/DialogContext/DialogContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import model from "Models/a_prijevoznici";
import upitModel from "Models/upiti";
import dataController from "Lib/dataController";
import PrijevoznikPublicDialog from "Views/Agencije/Trazilica/PrijevoznikPublicDialog";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import UpitDialog from "Views/Upiti/UpitDialog";
import withRedirect from "Components/withRedirect";

function AgencijePrijevozniciTable(props) {
  const dialogContext = useContext(DialogContext);
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();

  const { redirect } = props;

  const [records, setRecords] = useState([]);

  const dc = new dataController(model);
  const upitDC = new dataController(upitModel);

  useEffect(() => {
    refreshRecords();
  }, []);

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData()
      .then((resp) => {
        if (resp.success) {
          setRecords(resp.data);
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const handleRowAction = function(action, id) {
    const rec = records.find(x => x.id === id);
    if (action === "view") {
      dialogContext.showDialog(PrijevoznikPublicDialog, {
        recordId: id,
        direktIdRecord: rec,
        sendUpit: true
      });
    } else if (action === "direktni_upit") {
      dialogContext.showDialog(UpitDialog, {
        dc: upitDC,
        mode: "insert",
        form: "insert",
        onClose: handleDialogClose,
        direktIdRecord: rec
      });
    }
  };

  const handleDialogClose = (result) => {
    if (result.id) {
      redirect(`/a/upiti/${result.id}`);
    }
    dialogContext.hideDialog();
  };

  const allowRowAction = function(action, id) {
    return true;
  };

  return (
    <TableCard>
      <TableCardContent>
        <ModelTable
          title={t("titles.prijevoznici")}
          records={records}
          dc={dc}
          allowSelection="none"
          allowFilter={true}
          allowExport={true}
          allowAdd={false}
          //addLabel={t("buttons.new_m")}
          //handleAdd={handleAdd}
          onRowAction={handleRowAction}
          allowRowAction={allowRowAction}
          onDoubleClick={(id) => handleRowAction("view", id)}
        />
      </TableCardContent>
    </TableCard>
  );
}

export default withRedirect(AgencijePrijevozniciTable);
