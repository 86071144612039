//USED IN PONUDE

import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Icons
import AddCircleIcon from "@material-ui/icons/AddCircle";

//Custom components
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import { GridContainer, GridItem } from "UI/Grid";
import { FormTabPanel, FormButton } from "UI/Form";
import FormContent from "Components/FormContent";
import ModelTable from "UI/Table/ModelTable";
import FinancijskaKalkulacijaDialog from "Views/Prijevoznici/Voznje/VoznjaFormTabDialogs/FinancijskaKalkulacijaDialog";
import dataController from "Lib/dataController";
import model from "Models/fincalc";
import { ponudeService } from "Services/ponudeService";

function TabPanelInternaKalkulacija(props) {
  const dialogContext = useContext(DialogContext);
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);

  const { t } = useTranslation();
  const [records, setRecords] = useState([]);

  const { currentTab, tabIndex } = props;
  const { record, recordId, validation, fields, onFieldChange, mode, subModels } = props;
  const { dialog, onFinCalcRecordsChange } = props;

  const fEksterniTrosakHelper = "eksterni_trosak_helper";

  const dcFinCalc = new dataController(model, `ponude/${recordId}/financijske-kalkulacije`);

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  useEffect(() => {
    refreshRecords();
  }, []);

  useEffect(() => {
    if (dialog) {
      refreshRecords();
    }
  }, [records]);

  const refreshRecords = () => {
    if (!dialog) {
      dcFinCalc.GetData().then((resp) => {
        setRecords(Array.isArray(resp.data) ? resp.data : []);
        const iznos_bruto = calcIznosBruto(resp.data);
        if (onFieldChange) {
          onFieldChange(iznos_bruto, "iznos_fincalc");
        }
      });
    } else {
      const iznos_bruto = calcIznosBruto(records);
      if (onFieldChange) {
        onFieldChange(iznos_bruto, "iznos_fincalc");
      }
    }
  };

  const calcIznosBruto = (arr) => {
    return Array.isArray(arr)
      ? arr
          .filter((x) => x["iznos_bruto"])
          .map((x) => x["iznos_bruto"])
          .reduce((a, b) => a + b, 0)
      : 0;
  };

  const getMaxId = (arr) => {
    return Array.isArray(arr) && arr.length > 0 ? Math.max(...arr.map((x) => x.id)) : 1000000;
  };

  const handleAdd = (evt) => {
    dialogContext.showDialog(
      FinancijskaKalkulacijaDialog,
      {
        dc: dcFinCalc,
        mode: "insert",
        parentMode: mode,
        form: "default",
        onClose: handleDialogClose
      },
      dialog ? 2 : 1
    );
  };

  const handleView = (action, id) => {
    const rec = records.find((r) => r.id === id);

    dialogContext.showDialog(
      FinancijskaKalkulacijaDialog,
      {
        dc: dcFinCalc,
        mode: "update",
        parentMode: mode,
        form: "default",
        recordId: dialog ? null : id,
        record: dialog ? rec : null,
        onClose: handleDialogClose
      },
      dialog ? 2 : 1
    );
  };

  const addEksterniTrosak = () => {
    const eksterniTroskovi = subModels.eksterni_trosak_helper.records;
    const selectedPickerElements = record.eksterni_trosak_helper;

    if (selectedPickerElements.length > 0) {
      const chosenRecords = selectedPickerElements
        .map((selected) => {
          const tempItem = eksterniTroskovi.find((current) => current.value === selected.value);
          const inRecords = records.some((record) => record.value === selected.value);
          if (!inRecords) {
            return tempItem;
          }
        })
        .filter((record) => record !== undefined);
      mode === "insert" ? insertExterniTroskoviTemp(chosenRecords) : insertExterniTroskovi(chosenRecords);
    }
  };

  const insertExterniTroskoviTemp = (chosenRecords) => {
    const maxId = getMaxId(records);
    const troskovi = chosenRecords.map((record, i) => {
      return {
        id: maxId + 1 + i,
        opis: record.label,
        iznos_neto: record.iznos_neto,
        pdv_stopa: record.pdv_stopa,
        pdv_iznos: record.pdv_iznos,
        iznos_bruto: record.iznos_bruto
      };
    });
    const newRecords = records.concat(troskovi);
    if (onFieldChange) {
      onFieldChange(null, fEksterniTrosakHelper);
    }
    setRecords(newRecords);
    onFinCalcRecordsChange(newRecords);
  };

  const insertExterniTroskovi = (chosenRecords) => {
    const maxId = getMaxId(records);
    const troskovi = chosenRecords.map((record, i) => {
      return {
        id: maxId + 1 + i,
        opis: record.label,
        iznos_neto: record.iznos_neto,
        pdv_stopa: record.pdv_stopa,
        pdv_iznos: record.pdv_iznos,
        iznos_bruto: record.iznos_bruto
      };
    });
    loaderContext.toggleLoading(true);
    ponudeService
      .addFromEksterniTroskovi(recordId, troskovi)
      .then((resp) => {
        if (resp.success) {
          snackbarContext.showNotification("insert", "success");
          refreshRecords();
          if (onFieldChange) {
            onFieldChange(null, fEksterniTrosakHelper);
          }
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      if (mode === "insert") {
        const rec = result.record;
        switch (result.action) {
          case "insert":
            const temp_add_records = records;
            const maxId = getMaxId(temp_add_records);
            rec.id = maxId + 1;
            temp_add_records.push(rec);
            setRecords(temp_add_records);
            if (onFinCalcRecordsChange) {
              onFinCalcRecordsChange(temp_add_records);
            }
            refreshRecords();
            break;
          case "update":
            const _id = rec.id;
            const temp_upd_records = records.map((r) => {
              return r.id === _id ? rec : r;
            });
            setRecords(temp_upd_records);
            if (onFinCalcRecordsChange) {
              onFinCalcRecordsChange(temp_upd_records);
            }
            refreshRecords();
            break;
          case "delete":
            const id = result.id;
            const temp_del_records = records.filter((x) => x.id !== id);
            setRecords(temp_del_records);
            if (onFinCalcRecordsChange) {
              onFinCalcRecordsChange(temp_del_records);
            }
            break;
        }
      } else {
        refreshRecords();
      }
    }
    dialogContext.hideDialog(dialog ? 2 : 1);
  };

  const allowRowAction = function (action, id) {
    return true;
  };

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: handleFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels
  };

  const pickerRecords = record.eksterni_trosak_helper ? record.eksterni_trosak_helper : [];

  return (
    <FormTabPanel value={currentTab} index={tabIndex}>
      <GridContainer>
        {mode === "insert" || mode === "update" ? (
          <GridItem xs={12} >
            <GridContainer>
              <FormContent
                title={t("trjob.sections.eksterni_troskovi")}
                {...commonProps}
                fieldNames={["eksterni_trosak_helper"]}
                columns={2}
              />
              <GridItem xs={2} sm={4} md={4}>
                <FormButton
                  variant="outlined"
                  startIcon={<AddCircleIcon />}
                  onClick={addEksterniTrosak}
                  disabled={pickerRecords.length === 0 ? true : false}
                >
                  {pickerRecords.length === 1 || pickerRecords.length === 0
                    ? t("trjob.add_trosak")
                    : t("trjob.add_troskovi")}
                </FormButton>
              </GridItem>
            </GridContainer>
          </GridItem>
        ) : null}
        <GridItem xs={12}>
          <ModelTable
            title={t("titles.interna_kalkulacija")}
            records={records}
            dc={dcFinCalc}
            viewName={mode === "view" ? "locked" : "default"}
            search={false}
            allowExport={false}
            allowFilter={false}
            allowAdd={mode === "view" ? false : true}
            addLabel={t("buttons.new_f")}
            allowSelection={"none"}
            handleAdd={handleAdd}
            allowRowAction={allowRowAction}
            onRowAction={handleView}
          />
        </GridItem>
        <FormContent fieldNames={["iznos_fincalc", "iznos_fincalc_override"]} {...commonProps} columns={2} />
      </GridContainer>
    </FormTabPanel>
  );
}
export default TabPanelInternaKalkulacija;
