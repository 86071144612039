import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

//Custom Components
import dataController from "Lib/dataController";
import model from "Models/ponuda";
import modelUpiti from "Models/upiti";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import UserContext from "Components/UserContext/UserContext";
import PonudaForm10 from "Views/Prijevoznici/Ponude/PonudaForm10";
import PonudaForm11 from "Views/Prijevoznici/Ponude/PonudaForm11";
import PonudaForm13 from "Views/Prijevoznici/Ponude/PonudaForm13";
import PonudaForm18 from "Views/Prijevoznici/Ponude/PonudaForm18";
import { ponudaStatusesLost, ponudaStatusesOK } from "Lib/statuses";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

function PonudaForm(props) {
  const loaderContext = useContext(LoaderContext);
  const userContext = useContext(UserContext);
  const snackbarContext = useContext(SnackbarContext);
  const [formInfo, setFormInfo] = useState({
    component: undefined, form: undefined, upitID: undefined, record: undefined, isPanic: undefined, statuses: undefined
  });
  const [dcUpiti, setDCUpiti] = useState(null);
  const [targetUpitId, setTargetUpitId] = useState(null);

  const { recordId } = props;

  const dc = new dataController(model);

  const getFormInfo = (recordStatus) => {
    switch (recordStatus) {
      case 10:
        return { component: PonudaForm10, form: "status10", statuses: ponudaStatusesOK };
        break;
      case 11:
        return { component: PonudaForm11, form: "status11", statuses: ponudaStatusesOK };
        break;
      case 13:
      case 14:
      case 15:
      case 16:
        return { component: PonudaForm13, form: "status13", statuses: ponudaStatusesOK };
        break;
      case 18:
        return { component: PonudaForm18, form: "status18", statuses: ponudaStatusesLost };
        break;
      // case 19:
      //   setForm({component: PonudaForm19, form: "status19", upitId: upitId, statuses: ponudaStatusesOK});
      //   break;
      default:
        return { component: null, form: null, upitId: null, record: null, isPanic: null };
        break;
    }
  };

  const retrieveRecord = () => {
    loaderContext.toggleLoading(true);
    dc.GetDataSingle(recordId)
      .then((resp) => {
        if (resp.success) {
          const record = resp.data;
          const isDirektanUpit = record["upiti_direktni_id"] !== null;
          const direktanUpitId = isDirektanUpit ? record.upiti_direktni_id : null;
          const status = record.ponuda_state_id;
          const isPanic = record.panic;
          const upitId = record.upit_id;
          setDCUpiti(new dataController(modelUpiti, isDirektanUpit ? "direktni-upiti" : "burza/upiti"));
          setTargetUpitId(isDirektanUpit ? direktanUpitId : upitId)
          const formInfo = getFormInfo(status);
          setFormInfo({ ...formInfo, upitId: isDirektanUpit ? direktanUpitId : upitId, isPanic: isPanic });
        }
      }).catch((result) => {
      snackbarContext.showNotification(result.error, "error");
    })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  useEffect(() => {
    retrieveRecord();
  }, []);

  return formInfo.component
    ? React.createElement(formInfo.component, {
      dc: dc,
      dcUpiti: dcUpiti,
      mode: "update",
      form: formInfo.form,
      recordId: recordId,
      upitId: formInfo.upitId,
      isPanic: formInfo.isPanic,
      statuses: formInfo.statuses,
      subModelDefaultValues: {
        prijevoznik_id: userContext.subjectId
      },
      handleSendOffer: retrieveRecord
    })
    : null;
}

PonudaForm.propTypes = {
  recordId: PropTypes.number
};

export default PonudaForm;
