import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//Custom components
import withRedirect from "Components/withRedirect";
import ModelTable from "UI/Table/ModelTable";
import model from "Models/user";
import dataController from "Lib/dataController";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import TableButtonEdit from "UI/Table/ActionButtons/TableButtonEdit";
import DialogContext from "UI/DialogContext/DialogContext";
import UserDialog from "Views/Administration/UsersPage/UserDialog";

function Korisnici(props) {
  const { t } = useTranslation();
  const snackbarContext = useContext(SnackbarContext);
  const loaderContext = useContext(LoaderContext);
  const dialogContext = useContext(DialogContext);

  const [records, setRecords] = useState([]);

  const { currentTab, recordId } = props;
  const { redirect } = props; //HOC withRedirect

  const path = "business-subjects/" + recordId + "/users";
  const dc = new dataController(model);

  const handleAdd = (evt) => {
    dialogContext.showDialog(UserDialog, {
      dc: dc,
      mode: "insert",
      form: "insert_bs",
      onClose: handleDialogClose,
      defaultValues: {
        business_subject_id: recordId
      }
    });
  };

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords();
    }
  };

  const handleEdit = (evt, sel) => {
    const selId = parseInt(sel[0], 10);
    const record = records[selId];

    redirect("/admin/users/" + record.id);
  };

  const handleRowAction = function(action, id) {
    if (action === "edit") {
      redirect("/admin/users/" + id);
    }
  }

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData(path)
      .then((resp) => {
        if (resp && resp.data && resp.success) {
          setRecords(resp.data);
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  useEffect(() => {
    refreshRecords();
  }, []);

  return (
    <ModelTable
      records={records}
      dc={dc}
      viewName="bs"
      allowExport={false}
      allowFilter={false}
      allowAdd={true}
      addLabel={t("buttons.new_m")}
      handleAdd={handleAdd}
      allowSelection="none"
      onRowAction={handleRowAction}
      onDoubleClick={(id) => handleRowAction("edit", id)}
    >
      {/* <TableButtonEdit onClick={handleEdit} /> */}
    </ModelTable>
  );
}

export default withRedirect(Korisnici);
