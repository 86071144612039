import React from 'react';
import { useTranslation } from "react-i18next";

import { Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';

function ChatMessageConfirmButton(props) {
	const { t } = useTranslation()
	const { onClick, yes } = props;
	return (
		<Button 
			style={{
				color: yes ? "#009944" : "#cf000f", 
				whiteSpace: "nowrap",
				width: "50%"
			}} variant="text" 
			onClick={() => onClick(yes)} 
			startIcon={yes ? <CheckIcon /> : <CancelIcon />}
		>
			{t(`common.${yes ? "da" : "ne"}`)}
		</Button>
	);
}

export default ChatMessageConfirmButton;
