import React from "react";
import clsx from "clsx";

//Material-UI Core Components
import { makeStyles } from '@material-ui/core/styles';

function SchedulerGridRow(props) {
  const { dayOffset, dayCells, cellWidth } = props;
  const { onCellEnter, onCellLeave } = props;

  const classes = myStyles();

  return (
    <span
      //className="scheduler-grid"
      className={classes.grid}
      style={{ transform: "translateX(" + dayOffset + "px)" }}
      onMouseLeave={onCellLeave}
    >
      {dayCells.map((d, di) => (
        <div
          //className="scheduler-day"
          className={classes.day}
          style={{ width: d.width + "px" }} key={di}>
          {d.cells.map((c, ci) => (
            <div
              className={clsx(classes.cell, "noselect")}
              style={{ width: cellWidth + "px" }}
              onMouseEnter={(evt) => {
                onCellEnter(evt, d.day, c);
              }}
              key={ci}
            >&nbsp;</div>
          ))}
        </div>
      ))}
    </span>
  );
}

const myStyles = makeStyles((theme) => ({
  grid: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    borderLeft: "1px solid #000",
    borderRight: "1px solid #000",
    borderBottom: "1px dotted #ccc",
    backgroundColor: theme.palette.background.paper
  },
  day: {
    display: "flex",
    flexDirection: "row",
    borderRight: `1px solid ${theme.palette.text.disabled}`,
    backgroundColor: theme.palette.background.paper
  },
  cell: {
    textAlign: "center",
    borderRight: `1px dashed ${theme.palette.text.disabled}`,
    backgroundColor: theme.palette.background.paper
  }
}));

export default SchedulerGridRow;
