import React from "react";
import { grey, purple, blue } from "@material-ui/core/colors";

//Material UI Core
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

import AppPage from "UI/AppPage/AppPage";
import menuAgencije from "Routes/menuAgencije";

import DialogProvider from "UI/DialogContext/DialogProvider";
import DialogContainer from "UI/DialogContext/DialogContainer";

const moduleTheme = createMuiTheme({
  palette: {
    primary: {
      main: grey[800],
      dark: grey[900],
      light: grey[400]
    },
    secondary: {
      main: purple[600],
      dark: purple[700],
      light: purple[500]
    },
    trjobstatus: {
      20: "#DDDBDF",
      21: "#C7C5C9",
      22: "#FCF2A3",
      23: "#F1D852",
      24: "#91D0C0",
      25: "#5AB786",
      26: "#67A2CB",
      29: "#A1CDE0"
    },
  }
});

function AgencijePage(props) {
  return (
    <MuiThemeProvider theme={outerTheme => ({
       ...outerTheme,
       palette: {
         ...outerTheme.palette
         , primary: moduleTheme.palette.primary
         , secondary: moduleTheme.palette.secondary
       }
      })}>
      <DialogProvider>
        <AppPage menu={menuAgencije} modul="agencije">
          {props.children}
        </AppPage>
        <DialogContainer />
      </DialogProvider>
    </MuiThemeProvider>
  );
}

export default AgencijePage;
