import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";

//Material-UI Core Components
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import DialogActions from "@material-ui/core/DialogActions";

//Custom Components
import DynamicDialog from "UI/Dialog/DynamicDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogActionButtonSave from "UI/Dialog/ActionButtons/DialogActionButtonSave";
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import FormContent from "Components/FormContent";
import FormAvatar from "UI/Form/FormAvatar";
import withFormController from "Components/withFormController";
import useAlarm from "Components/useAlarm";

function AlarmDialog(props) {
  const { getAlarmIcon } = useAlarm({});
  const { record, validation, fields, mode } = props; //HOC withFormController
  const { doClose, onFieldChange, doValidate } = props; //HOC withFormController
  const { onClose, onSave } = props;

  const { t } = useTranslation();

  const alarm_ts = record ? record.alarm_ts : undefined;
  const alarm_active = record ? record.alarm_active : undefined;

  const icon = getAlarmIcon(alarm_active, alarm_ts);

  const handleSave = (evt) => {
    const isValid = doValidate();
    if (isValid) {
      if (onSave) {
        onSave(record);
      } else {
        console.warn("onSave function not provided!");
      }
    }
  }

  const handleClose = (evt) => {
    close({ dataChanged: false });
  };

  const close = (result) => {
    if (result.dataChanged) {
      doClose({ dataChanged: true, action: result.action });
    }

    if (onClose) {
      onClose();
    }
  };

  return (
    <DynamicDialog onClose={handleClose} maxWidth={"sm"}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <FormAvatar icon={icon} />
          <DialogToolbarHeading>{t("titles.alarm")}</DialogToolbarHeading>
          <DialogToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer>
            <FormContent
              record={record}
              validation={validation}
              fields={fields}
              mode={mode}
              onFieldChange={onFieldChange}
              columns={2}
              fieldNames={["alarm_ts", "alarm_active", "alarm_remark"]}
            />
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
        <DialogActionButtonSave variant="contained" onClick={handleSave} />
      </DialogActions>
    </DynamicDialog>
  );
}

AlarmDialog.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func
}

export default withFormController(AlarmDialog);
