import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Custom Components
import ModelTable from "UI/Table/ModelTable";
import TableCard from "UI/Table/TableCard";
import TableCardContent from "UI/Table/TableCardContent";
import DialogContext from "UI/DialogContext/DialogContext";

import model from "Models/posebnePonude";
import dataController from "Lib/dataController";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import UserContext from "Components/UserContext/UserContext";
import confirm from "Lib/confirmDialog";
import PosebnaPonudaTransferDialog from "./PosebnaPonudaTransferDialog";
import PosebnaPonudaKruznaDialog from "./PosebnaPonudaKruznaDialog";
import withRedirect from "Components/withRedirect";

function PrijevozniciPosebnePonudeTable(props) {
  const dialogContext = useContext(DialogContext);
  const loaderContext = useContext(LoaderContext);
  const userContext = useContext(UserContext);

  const { t } = useTranslation();
  const [records, setRecords] = useState([]);

  const { redirect } = props;

  const dc = new dataController(model);

  useEffect(() => {
    if (userContext.subjectId) {
      refreshRecords();
    }
  }, [userContext.subjectId]);

  const confirmPosebnaPonudaType = () => {
    const title = t("cdialogs.posebna_ponuda.title");
    const text = t("cdialogs.posebna_ponuda.text");
    const type = "question";
    const confirmButtonText = t("cdialogs.posebna_ponuda.transfer");
    const cancelButtonText = t("cdialogs.posebna_ponuda.kruzna");

    return confirm(title, text, type, confirmButtonText, cancelButtonText);
  };

  const handleAdd = (evt) => {
    confirmPosebnaPonudaType().then((result) => {
      if (!result.canceled) {
        const isKruzno = !result.confirmed;
        const DialogComponent = isKruzno ? PosebnaPonudaKruznaDialog :
          PosebnaPonudaTransferDialog;

        dialogContext.showDialog(DialogComponent, {
          dc: dc,
          mode: "insert",
          form: isKruzno ? "prijevoznikKruzni" : "prijevoznikTransfer",
          onClose: handleDialogClose,
          subModelDefaultValues: {
            prijevoznik_id: userContext.subjectId
          },
          defaultValues: {
            cbc_provizija_perc: userContext.subjectCBCProvision
          }
        });
      }
    });
  };

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords();
    }
  };

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData('posebne-ponude/?select=my')
      .then((resp) => {
        if (resp.success) {
          setRecords(resp.data || []);
        }
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const handleRowAction = function(action, id) {
    if (action === "view") {
      const rec = records.find(x => x.id === id);
      if (rec && rec.posebna_ponuda_state_id === 13) {
        redirect(`/p/voznje/${rec.posao_id}`);
      } else {
        if (rec.status === 0) {
          redirect(`/p/posebne-ponude/${id}#detalji`);
        } else if (rec.status === 1) {
          redirect(`/p/posebne-ponude/${id}#upiti`);
        } else {
          redirect(`/p/posebne-ponude/${id}`);
        }
      }
    }
  };

  const allowRowAction = function(action, id) {
    return true;
  };

  return (
    <TableCard>
      <TableCardContent>
        <ModelTable
          title={t("titles.posebne_ponude")}
          viewName={"prijevoznik"}
          records={records}
          dc={dc}
          allowExport={true}
          allowFilter={true}
          allowAdd={true}
          addLabel={t("buttons.new_f")}
          allowSelection={"none"}
          handleAdd={handleAdd}
          handleRefresh={refreshRecords}
          onRowAction={handleRowAction}
          allowRowAction={allowRowAction}
          onDoubleClick={(id) => handleRowAction("view", id)}
          subModelDefaultValues={{
            prijevoznik_id: userContext.subjectId
          }}
         />
      </TableCardContent>
    </TableCard>
  );
}

export default withRedirect(PrijevozniciPosebnePonudeTable);
