import React from 'react';
import { useTranslation } from "react-i18next";

// Material-UI Core Components
import { makeStyles } from "@material-ui/core/styles";
import {
	Divider
} from "@material-ui/core";

// Custom Components
import { helpers as H } from "Lib/helpers";

function ChatDate(props) {
	const { t } = useTranslation();
	const { date } = props;
	const classes = useStyle();

	const [DD, MM, YYYY] = H.formatDateSplit(date);

	return (
		<div className={classes.wrapper}>
			<div className={classes.date}>
				{ DD }.&nbsp;
				{ t(`common.months_genitiv.${MM}`) }&nbsp;
				{ YYYY }.
			</div>
			<Divider />
		</div>
	) 
}

const useStyle = makeStyles(theme => ({
	wrapper: {
		width: "100%",
		margin: "16px 0",
		height: "12px"
	},
	date: {
		fontSize: "10px",
		textAlign: "center"
	}
}))

export default ChatDate;