import React, { Fragment, useContext, useState } from "react";
import PropTypes from "prop-types";

//Material-UI Core Components
import makeStyles from "@material-ui/core/styles/makeStyles";
import Popover from "@material-ui/core/Popover";
import { Typography } from "@material-ui/core";

//Custom Components
import PickerControl from "Controls/PickerControl";
import dataController from "Lib/dataController";
import modelPosao from "Models/poslovi";
import modelPojedinacnaVoznja from "Models/pojedinacneVoznje";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

const myStyles = makeStyles((theme) => ({
  paper: {
    paddingLeft: "5px",
    paddingRight: "5px",
    width: 400,
    minWidth: 400
  },

  conflict: {
    //color: "#FF0000",
    fontWeight: "bold"
  },

  basic: {
    //color: theme.palette.text.primary,
    fontWeight: "normal"
  }
}));

function SchedulerPicker(props) {
  const snackbarContext = useContext(SnackbarContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const { label, dcRecord, dcPicker, fieldName, fieldValue, autoFocus, conflict, onChange } = props;
  const { posaoId, voznjaId, rezervacijaId, ponudaId, readOnly } = props;

  const classes = myStyles();

  const handleShowPicker = (evt) => {
    setAnchorEl(evt.target);
  };

  const getApiFieldName = (source) => {
    switch (source) {
      case "vozilo_id":
        return "vozilo";
      case "vozac_id":
        return "vozac";
      case "vozac_1_id":
        return "vozac-1";
      case "vozac_2_id":
        return "vozac-2";
      case "vozac_3_id":
        return "vozac-3";
      default:
        return null;
    }
  };

  const handleChangeField = (value, source) => {
    //TODO: implement

    const model = voznjaId ? modelPojedinacnaVoznja : modelPosao;

    const apiFieldName = getApiFieldName(source);

    const customApiPath = rezervacijaId ?
      `rezervacije/${rezervacijaId}/${apiFieldName}`
    : ( voznjaId
      ? `poslovi/${posaoId}/voznje/${voznjaId}/${apiFieldName}`
      : `poslovi/${posaoId}/${apiFieldName}`
    );

    const dc = new dataController(model);

    dc.CustomPatchAction(customApiPath, {
      [source]: value
    }).then((resp) => {
      if (resp.success) {
        setAnchorEl(null);
        if (onChange) {
          onChange();
        }
      }
    }).catch((result) => {
      snackbarContext.showNotification(result.error, "error");
    });

    // let record = dcRecord.getRecordObject(recordId);

    // //    record[modelRecord.id_attribute.source] = recordId;
    // record[source] = value;

    // // TODO: brisanje vozaca i vozila kod odabiranja kooperanta

    // dcRecord.UpdateRecord(recordId, record).then((result) => {
    //   if (result.success) {
    //      setAnchorEl(null);
    //   }
    // });

    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const field = dcRecord ? dcRecord.getField(fieldName) : null;

  const allowPicker = ponudaId || readOnly ? false : true; //all except ponuda

  const textColor = conflict
    ? "error"
    : (allowPicker ? "textPrimary" : "textSecondary");

  return (
    <Fragment>
      <div onClick={dcPicker && allowPicker ? handleShowPicker : null} className={conflict ? classes.conflict : classes.basic}>
        <Typography variant="body2" color={textColor}>
          &nbsp;{label}&nbsp;
        </Typography>
      </div>
      {dcPicker ? (
        <Popover
          id={"singlepicker"}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          classes={{ paper: classes.paper }}
        >
          <PickerControl
            field={field}
            formMode="form"
            controlMode="edit"
            model={dcPicker}
            allowClear={true}
            allowNewRecord={false}
            showHelper={false}
            onChange={handleChangeField}
            value={fieldValue}
            autoFocus={autoFocus}
          />
        </Popover>
      ) : null}
    </Fragment>
  );
}

SchedulerPicker.propTypes = {
  label: PropTypes.string,
  dcPicker: PropTypes.object,
  posaoId: PropTypes.number,
  voznjaId: PropTypes.number,
  rezervacijaId: PropTypes.number,
  fieldName: PropTypes.string,
  fieldValue: PropTypes.number,
  autoFocus: PropTypes.bool,
  conflict: PropTypes.bool
};

export default SchedulerPicker;
