import React, { useContext, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

import UserContext from "Components/UserContext/UserContext";
import KomunikatorContext from "Components/KomunikatorContext/KomunikatorContext";

import { authService } from "Services/authService";
import { Role } from 'Lib/role';

function PrivateRoute(props) {
  const { component, ...rest } = props;
  const userContext = useContext(UserContext);
  const komunikatorContext = useContext(KomunikatorContext);

  const accessToken = localStorage.getItem("auth_token");

  useEffect(() => {
    if (accessToken) {
      if (!userContext || !userContext.username) {
        authService.whoAmI().then(resp => {
          if (resp.success) {
            userContext.setUserData(resp.data);
            komunikatorContext.connect(resp.data.user_id, resp.data.role_id, resp.data.subject_id);
          } else {
            localStorage.removeItem("auth_token");
            window.location.reload(true);
          }
        })
      } else {
        komunikatorContext.connect(userContext.userId, userContext.roleId, userContext.subjectId);
      }
    }
  }, [accessToken]);

  return (
    accessToken ? 
    <Route
      {...rest}
      render={props =>
        React.createElement(component, props)
        // accessToken ? (
        // ) : (
        // )

      }
    />
    :
    <Redirect
      to={{ pathname: "/login", state: { from: props.location } }}
    />
  )
}

export default PrivateRoute;
