import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Custom Components
import DialogContext from "UI/DialogContext/DialogContext";
import ModelTable from "UI/Table/ModelTable";
import TableCard from "UI/Table/TableCard";
import TableCardContent from "UI/Table/TableCardContent";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import UserContext from "Components/UserContext/UserContext";

import model from "Models/a_korisnici";
import dataController from "Lib/dataController";

import AgencijeKorisnikDialog from "Views/Agencije/Korisnici/AgencijeKorisnikDialog";

// TODO:
// subModels cleanup: they are added to the project because Table.jsx can not function without them

function AgencijeKorisniciTable(props) {
  const dialogContext = useContext(DialogContext);
  const loaderContext = useContext(LoaderContext);
  const userContext = useContext(UserContext);
  const { t } = useTranslation();

  const [records, setRecords] = useState([]);

  const dc = new dataController(model);

  useEffect(() => {
    refreshRecords();
  }, [userContext.subjectId]);

  const handleAdd = (evt) => {
    dialogContext.showDialog(AgencijeKorisnikDialog, {
      dc: dc,
      mode: "insert",
      form: "insert",
      onClose: handleDialogClose,
      defaultValues: { permission: 0, user_role: 100 },
      referrer: "list"
    });
  };

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords();
    }
  };

  const refreshRecords = () => {
    if (userContext.subjectId) {
      loaderContext.toggleLoading(true);
      const path = "agencije/" + userContext.subjectId + "/korisnici"
      dc.GetData(path)
        .then((resp) => {
          if (resp.success) {
            setRecords(resp.data)
          };
        })
        .finally(() => {
          loaderContext.toggleLoading(false);
        });
    }
  };

  return (
    <TableCard>
      <TableCardContent>
        <ModelTable
          title={t("titles.agencijski_korisnici")}
          records={records}
          dc={dc}
          allowSelection="none"
          allowFilter={true}
          allowExport={true}
          allowAdd={false} //TODO: in v1.1 user will be able to add its own users
          addLabel={t("buttons.new_m")}
          handleAdd={handleAdd}
          handleRefresh={refreshRecords}
        >
          {/* <TableButton variant="outlined" allow="one" onClick={handleEdit} startIcon={<EditIcon />}>
            {t("buttons.edit")}
          </TableButton> */}
        </ModelTable>
      </TableCardContent>
    </TableCard>
  );
}

export default AgencijeKorisniciTable;
