import React, { useContext } from "react";
import PropTypes from "prop-types";

//Material-UI Core Components
import makeStyles from "@material-ui/core/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";

import UserContext from "Components/UserContext/UserContext";

const iconStyles = (theme) => ({
  root: {
    color: theme.palette.common.black
  }
});

const customStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.primary
    }
  }
}));

const HeaderMenuItem = React.forwardRef((props, ref) => {
  const userContext = useContext(UserContext);
  const { onClick, label, icon, needPermission, ...otherProps } = props;
  const classes = customStyles();

  if (needPermission && !userContext.hasAnyPermission(needPermission)) {
    return null;
  } else {
    return (
      <MenuItem onClick={onClick} classes={classes} ref={ref} {...otherProps}>
        {icon ? (
          <ListItemIcon style={{ minWidth: 32, color: "#000" }}>
            <Icon>{icon}</Icon>
          </ListItemIcon>
        ) : null}
        {label ? <Typography variant="button">{label}</Typography> : null}
      </MenuItem>
    );
  }
});

HeaderMenuItem.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  needPermission: PropTypes.arrayOf(PropTypes.object)
};

export default HeaderMenuItem;
