import api from "Lib/api";

export const posloviService = {
  createInterniPosao,
  updateInterniPosao,
  updateInterniPosaoSazetak,
  interniPosaoStanje20_21,
  interniPosaoStanje21_22,
  interniPosaoStanje22_23,
  interniPosaoStanje23_24,
  interniPosaoStanje24_25,
  interniPosaoStanje25_26,
  interniPosaoStanje26_29,
  stornirajInterniPosao,
  stornirajPlatformskiPosao,

  getAdminPoslovi,
  getAdminPosao,

  getPojedinacneVoznje,
  getPojedinacneVoznjeById,
  createPojedinacneVoznje,
  updatePojedinacneVoznje,
  deletePojedinacneVoznje,

  addFromEksterniTroskovi
}

const posloviApiUrl = 'poslovi/';
const interniPosloviApiUrl ='interni-poslovi/';

function createInterniPosao(data){
  const apiInstance = new api();
  const path = interniPosloviApiUrl;
  return apiInstance.Call(path, "post", data);
}

function updateInterniPosao(id, data) {
  const apiInstance = new api();
  const path = interniPosloviApiUrl + id;
  return apiInstance.Call(path, "put", data);
}

function updateInterniPosaoSazetak(id, data) {
  const apiInstance = new api();
  const path = interniPosloviApiUrl + id + "/sazetak";
  return apiInstance.Call(path, "patch", data);
}

function interniPosaoStanje20_21(id) {
  const apiInstance = new api();
  const path = interniPosloviApiUrl + id + "/stanje-20-21";
  return apiInstance.Call(path, "put", id);
}

function interniPosaoStanje21_22(id) {
  const apiInstance = new api();
  const path = interniPosloviApiUrl + id + "/stanje-21-22";
  return apiInstance.Call(path, "put", id);
}

function interniPosaoStanje22_23(id, data) {
  const apiInstance = new api();
  const path = interniPosloviApiUrl + id + "/stanje-22-23";
  return apiInstance.Call(path, "put", data);
}

function interniPosaoStanje23_24(id, data) {
  const apiInstance = new api();
  const path = interniPosloviApiUrl + id + "/stanje-23-24";
  return apiInstance.Call(path, "put", data);
}

function interniPosaoStanje24_25(id) {
  const apiInstance = new api();
  const path = interniPosloviApiUrl + id + "/stanje-24-25";
  return apiInstance.Call(path, "put", id);
}

function interniPosaoStanje25_26(id) {
  const apiInstance = new api();
  const path = interniPosloviApiUrl + id + "/stanje-25-26";
  return apiInstance.Call(path, "put", id);
}

function interniPosaoStanje26_29(id) {
  const apiInstance = new api();
  const path = interniPosloviApiUrl + id + "/stanje-26-29";
  return apiInstance.Call(path, "put", id);
}

function stornirajInterniPosao(id){
  const apiInstance = new api();
  const path = interniPosloviApiUrl + id + "/storno";
  return apiInstance.Call(path, "patch");
}

function stornirajPlatformskiPosao(id){
  const apiInstance = new api();
  const path = posloviApiUrl + id + "/storno";
  return apiInstance.Call(path, "patch");
}

function getAdminPoslovi(){
  const apiInstance = new api();
  const path = posloviApiUrl + "admin";
  return apiInstance.Call(path, "get");
}

function getAdminPosao(posaoId){
  const apiInstance = new api();
  const path = posloviApiUrl + "admin/" + posaoId;
  return apiInstance.Call(path, "get");
}

function getPojedinacneVoznje(id){
  const apiInstance = new api();
  const path = posloviApiUrl + id + "/voznje";
  return apiInstance.Call(path, "get");
}

function getPojedinacneVoznjeById(posaoId, poj_voznje_id){
  const apiInstance = new api();
  const path = posloviApiUrl + posaoId + "/voznje/" + poj_voznje_id;
  return apiInstance.Call(path, "get");
}

function createPojedinacneVoznje(id, data){
  const apiInstance = new api();
  const path = posloviApiUrl + id + "/voznje";
  return apiInstance.Call(path, "post", data);
}

function updatePojedinacneVoznje(posaoId, poj_voznje_id, data){
  const apiInstance = new api();
  const path = posloviApiUrl + posaoId + "/voznje/" + poj_voznje_id;
  return apiInstance.Call(path, "put", data);
}

function deletePojedinacneVoznje(posaoId, poj_voznje_id){
  const apiInstance = new api();
  const path = posloviApiUrl + posaoId + "/voznje/" + poj_voznje_id;
  return apiInstance.Call(path, "delete");
}

function addFromEksterniTroskovi(posaoId, data){
  const apiInstance = new api();
  const path = posloviApiUrl + posaoId + "/financijske-kalkulacije" + "/add-external";
  return apiInstance.Call(path, "post", data);
}
