import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import moment from "moment";

//Custom components
import Calender from "rc-year-calendar";
import 'rc-year-calendar/locales/rc-year-calendar.hr';
import { Tooltip } from "@material-ui/core";


function CalendarControl(props) {
  const {i18n} = useTranslation();
  const [eventTitle, setEventTitle] = useState(null);

  const {
    records, allowOverlap, enableRangeSelection,
    onRangeSelected, onDayClick, roundRangeLimits
  } = props;

  const currentYear = new Date().getFullYear();

  const lang = i18n.language;

  const onDayEnter = (evt) => {
    // console.log(evt);
    // evt.events.length > 0 ? setEventTitle(evt.events[0].name) :
    //     setEventTitle(null);
  }

  return (
      <Fragment>
        <Calender
            year={currentYear}
            language={lang}
            style="background"
            roundRangeLimits={roundRangeLimits}
            allowOverlap={allowOverlap}
            dataSource={records}
            onDayEnter={onDayEnter}
            enableRangeSelection={enableRangeSelection}
            onRangeSelected={enableRangeSelection ? (evt) => onRangeSelected(evt) : () => {
            }}
            onDayClick={onDayClick ? (evt) => onDayClick(evt) : () => {
            }}
        />
      </Fragment>
  );
}

export default CalendarControl;
