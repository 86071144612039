import React, { useContext } from "react";
import PropTypes from "prop-types";

//Material-UI Core Components
import makeStyles from "@material-ui/core/styles/makeStyles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

import UserContext from "Components/UserContext/UserContext";

const iconStyles = (theme) => ({
  root: {
    color: theme.palette.common.black
  }
});

const customStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.type === "dark" ? theme.palette.primary.light : theme.palette.text.primary
    }
  }
}));

const HeaderMenuItemExt = React.forwardRef((props, ref) => {
  const userContext = useContext(UserContext);
  const { onClick, label, caption, icon, needPermission } = props;
  const classes = customStyles();

  if (needPermission && !userContext.hasAnyPermission(needPermission)) {
    return null;
  } else {
    return (
      <ListItem button dense={true} onClick={onClick} classes={classes} ref={ref}>
        <ListItemAvatar>
          <Avatar>
            <Icon color="primary">{icon}</Icon>
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={label} secondary={caption} />
      </ListItem>
    );
  }
});

HeaderMenuItemExt.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  needPermission: PropTypes.arrayOf(PropTypes.object)
};

export default HeaderMenuItemExt;
