import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

//Material UI
import Toolbar from "@material-ui/core/Toolbar";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Box from "@material-ui/core/Box";
import DialogActions from "@material-ui/core/DialogActions";

//Custom Components
import withFormController from "Components/withFormController";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogActionButtonAdd from "UI/Dialog/ActionButtons/DialogActionButtonAdd";
import DialogActionButtonSave from "UI/Dialog/ActionButtons/DialogActionButtonSave";
import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItemDelete from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemDelete";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import GridContainer from "UI/Grid/GridContainer";
import FormContent from "Components/FormContent";
import DynamicDialog from "UI/Dialog/DynamicDialog";
import { posloviService } from "Services/posloviService";

import DialogContext from "UI/DialogContext/DialogContext";
import EuroConversionComment from "Components/EuroConversionComment";

function TroskoviDialog(props) {
  const snackbarContext = useContext(SnackbarContext);
  const loaderContext = useContext(LoaderContext);
  const dialogContext = useContext(DialogContext);
  const { t } = useTranslation();

  const { mode, record, validation, fields, subModels } = props; //HOC withFormController
  const { onFieldChange, onClose, doDelete, doUpdate, doInsert, doClose } = props; //HOC withFormController

  const fIznosNeto = "iznos_neto";
  const fPdvStopa = "pdv_stopa";
  const fPdvIznos = "pdv_iznos";
  const fIznosBruto = "iznos_bruto";

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      switch (source) {
        case "iznos_neto":
          if (record[fPdvStopa] !== undefined) {
            const val = (value && value.length > 0) ? parseFloat(value.replace(',', '.')) : NaN;
            const pdv_stopa = (typeof record[fPdvStopa] === "object" && record[fPdvStopa].value !== undefined) ? record[fPdvStopa].value : record[fPdvStopa];
            if (isNaN(val)) {
              onFieldChange(null, fPdvIznos);
              onFieldChange(null, fIznosBruto);
            } else {
              const pdv_iznos = val * (pdv_stopa / 100)
              const bruto_iznos = val + pdv_iznos;
              onFieldChange(pdv_iznos, fPdvIznos);
              onFieldChange(bruto_iznos, fIznosBruto);
            }
          }
          onFieldChange(value, source);
          break;
        case "pdv_stopa":
          if (record[fIznosNeto] !== undefined) {
            const iznos_neto = typeof record[fIznosNeto] === "string" ? parseFloat(record[fIznosNeto].replace(',','.')) : record[fIznosNeto];
            const pdv_iznos = (value.value / 100 ) * iznos_neto;
            const bruto_iznos = iznos_neto + pdv_iznos;

            onFieldChange(pdv_iznos, fPdvIznos);
            onFieldChange(bruto_iznos, fIznosBruto);
          }
          onFieldChange(value, source);
          break;
        default:
          onFieldChange(value, source);
          break;
      }
    }
  };

  const handleDelete = (evt) => {
    if (doDelete) {
      doDelete().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "insert"});
          snackbarContext.showNotification("delete", "success");
        }
      }).catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      });;
    }
  };

  const handleUpdate = (evt) => {
    if (doUpdate) {
      doUpdate().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "insert"});
          snackbarContext.showNotification("update", "success");
        }
      }).catch((result) => {
        if (result.success === false && result.validationPass === false) {
          //do nothing
        } else {
          snackbarContext.showNotification(result.error, "error");
        }
      });
    }
  };

  const handleInsert = (evt) => {
    if (doInsert) {
      doInsert().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "insert"});
          snackbarContext.showNotification("insert", "success");
        }
      }).catch((result) => {
        if (result.success === false && result.validationPass === false) {
          //do nothing
        } else {
          snackbarContext.showNotification(result.error, "error");
        }
      });
    }
  };

  const close = (result) => {
    if (result.dataChanged) {
      onClose({ dataChanged: true, action: result.action });
    }

    dialogContext.hideDialog();
  };

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: handleFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels,
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  return (
      <DynamicDialog onClose={handleClose} maxWidth={"md"}>
        <DialogHeader>
          <Toolbar variant="dense" disableGutters={true}>
            <DialogAvatar ariaLabel="" icon={<MonetizationOnIcon />} />
            <DialogToolbarHeading>
              {mode == "insert"
                  ? t("titles.new_eksterni_trosak")
                  : t("titles.eksterni_trosak")}{" "}
              {record.id ? " (ID=" + record.id + ")" : ""}
            </DialogToolbarHeading>
            <DialogToolbarFillContent />
            {mode === "update" || mode === "view" ? (
                <DialogToolbarMenuButton>
                  <DialogToolbarMenuItemDelete onClick={handleDelete} />
                </DialogToolbarMenuButton>
            ) : null}
            <DialogToolbarButtonClose onClick={handleClose} />
          </Toolbar>
        </DialogHeader>
        <DialogBody>
          <Box m={2}>
            <GridContainer>
              <FormContent
                  fieldNames={[
                    "opis",
                    "iznos_neto",
                    "pdv_stopa",
                    "pdv_iznos",
                    "iznos_bruto",
                  ]}
                  {...commonProps}
                  columns={2}
              />
            </GridContainer>
          </Box>
        </DialogBody>
        <DialogActions>
          <EuroConversionComment />
          <DialogToolbarFillContent />
          <DialogActionButtonClose variant="outlined" onClick={handleClose} />
          {mode === "insert" ? (
              <DialogActionButtonAdd variant="contained" onClick={handleInsert} />
          ) : null}
          {mode === "update" ? (
              <DialogActionButtonSave variant="contained" onClick={handleUpdate} />
          ) : null}
        </DialogActions>
      </DynamicDialog>
  );
}

export default withFormController(TroskoviDialog);
