import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI
import SaveIcon from "@material-ui/icons/Save";

//Custom Components
import DialogActionButton from "UI/Dialog/DialogActionButton";

function DialogActionButtonSave(props) {
  const { t } = useTranslation();

  return (
    <DialogActionButton startIcon={<SaveIcon />} {...props} aria-label="Save" aria-controls="Save">
      {t("buttons.save")}
    </DialogActionButton>
  );
}

export default DialogActionButtonSave;
