import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import numbro from "numbro";

//Material-UI Core Components
import Toolbar from "@material-ui/core/Toolbar";
import DialogActions from "@material-ui/core/DialogActions";

//Material-UI Icons
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import MoneyIcon from "@material-ui/icons/Money";
import AssignmentIcon from "@material-ui/icons/Assignment";

//Custom Components
import withFormController from "Components/withFormController";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import UserContext from "Components/UserContext/UserContext";
import DialogContext from "UI/DialogContext/DialogContext";
import DynamicDialog from "UI/Dialog/DynamicDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import { FormTab, FormTabPanel, FormTabs } from "UI/Form";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import PonudaAvatar from "UI/Form/Avatars/PonudaAvatar";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItemDraft from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemDraft";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogActionButtonShowFinCalc from "UI/Dialog/ActionButtons/DialogActionButtonShowFinCalc";
import DialogActionButtonSend from "UI/Dialog/ActionButtons/DialogActionButtonSend";
import GridContainer from "UI/Grid/GridContainer";
import FormContent from "Components/FormContent";

import { ponudeService } from "Services/ponudeService";
import { upitService } from "Services/upitService";
import TabPanelUpit from "Views/Ponude/Tabs/TabPanelUpit";
import TabPanelPojedinacneVoznje from "Views/Ponude/Tabs/TabPanelPojedinacneVoznje";
import TabPanelInternaKalkulacija from "Views/Ponude/Tabs/TabPanelInternaKalkulacija";

import dataController from "Lib/dataController";
import modelUpit from "Models/upiti";
import modelFinCalc from "Models/fincalc";
import EuroConversionComment from "Components/EuroConversionComment";

function PonudaDialog(props) {
  const dialogContext = useContext(DialogContext);
  const userContext = useContext(UserContext);
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();

  const { upitID, isDirektan, upitRecord, dc } = props;

  const { dcUpitiPath, onClose } = props;
  const { record, validation, fields, subModels, mode, recordId } = props; //HOC withFormController
  const { onFieldChange, doValidate } = props; //HOC withFormController

  const [currentTab, setCurrentTab] = useState(1);

  const [recordsFinCalc, setRecordsFinCalc] = useState([]);

  const [showTabVoznje, setShowTabVoznje] = useState(false);
  const [showTabFinCalc, setShowTabFinCalc] = useState(false);

  const dcUpiti = new dataController(modelUpit, dcUpitiPath);
  const dcFinCalc = new dataController(modelFinCalc);

  const handleClose = (result) => {
    if (onClose) {
      onClose(result);
    }
  };

  const handleRecordsFinCalc = (records) => {
    setRecordsFinCalc(records);
  };

  const handleTabChange = (evt, value) => {
    setCurrentTab(value);
  };

  const handleToggleTabVoznje = (evt) => {
    setShowTabVoznje(!showTabVoznje);
  };

  const handleToggleTabFinCalc = (evt) => {
    setShowTabFinCalc(!showTabFinCalc);
  };

  const createDraft = () => {
    const preparedRecord = dc.prepareSendingData(record);
    preparedRecord["fin_calc"] = recordsFinCalc.map((x) => dcFinCalc.prepareSendingData(x));
    if (isDirektan) {
      ponudeService.createDraftForDirektanUpit(upitID, preparedRecord).then((resp) => {
        if (resp.success) {
          if (onClose) {
            onClose({success: true, dataChanged: true})
          }
          snackbarContext.showNotification("notifications.create_draft", "success");
        }
      }).catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      });
    } else {
      ponudeService.createDraftForUpit(upitID, preparedRecord).then((resp) => {
        if (resp.success) {
          if (onClose) {
            onClose({success: true, dataChanged: true})
          }
          snackbarContext.showNotification("notifications.create_draft", "success");
        }
      }).catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      });
    }
  };

  const sendQuote = () => {
    const isValid = doValidate();
    if (isValid) {
      const preparedRecord = dc.prepareSendingData(record);
      preparedRecord["fin_calc"] = recordsFinCalc.map((x) => dcFinCalc.prepareSendingData(x));
      const fn = isDirektan ? upitService.quoteDirektanUpit : upitService.quoteUpit;

      fn(upitID, preparedRecord)
        .then((resp) => {
          if (resp.success) {
            snackbarContext.showNotification("notifications.create_quote", "success");
            handleClose({ success: true, dataChanged: true});
          }
        }).catch((result) => {
          snackbarContext.showNotification(result.error, "error");
        });
    }
  };

  const avansAllowed = userContext.subjectAllowAvans;

  const handleFieldChange = (value, source) => {
    const fCijenaBruto = "iznos_hrk";
    const fCBCProvizijaIznos = "cbc_provizija_iznos";
    const fCijenaSCBCProvizijom = "iznos_s_cbc_provizijom";

    if (source === fCijenaBruto) {
      const decValue = numbro(value);

      const provizija_iznos = decValue * (record && record.cbc_provizija_perc ? record.cbc_provizija_perc * 0.01 : 0);
      const cijena_s_provizijom = decValue + provizija_iznos;

      onFieldChange(provizija_iznos, fCBCProvizijaIznos);
      onFieldChange(cijena_s_provizijom, fCijenaSCBCProvizijom);
    }
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: handleFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels,
    recordId: recordId,
    columns: 2
  };

  const tabIndexFinCalc = 1 + (showTabVoznje ? 1 : 0);
  const tabIndexPonuda = tabIndexFinCalc + (showTabFinCalc ? 1 : 0);

  return (
    <DynamicDialog onClose={handleClose} maxWidth={"lg"} fixedHeight={true}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <PonudaAvatar />
          <DialogToolbarHeading>{t("titles.ponuda")}</DialogToolbarHeading>
          <DialogToolbarFillContent />
          <DialogToolbarMenuButton>
            <DialogToolbarMenuItemDraft onClick={createDraft} />
          </DialogToolbarMenuButton>
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <FormTabs value={currentTab} onChange={handleTabChange} scrollButtons="off" variant="fullWidth">
          <FormTab label={t("ponuda.tabs.upit")} icon={<LiveHelpIcon />} aria-label="upit" />
          {showTabVoznje ? (
            <FormTab label={t("ponuda.tabs.voznje")} icon={<AirportShuttleIcon />} aria-label="pojedinacne_voznje" />
          ) : null}
          {showTabFinCalc ? (
            <FormTab label={t("ponuda.tabs.kalkulacija")} icon={<MoneyIcon />} aria-label="interna_kalkulacija" />
          ) : null}
          <FormTab
            label={t("ponuda.tabs.ponuda")}
            icon={<AssignmentIcon />}
            aria-label="ponuda"
            validation={validation}
            fields={[
              "from_ts",
              "to_ts",
              "relacija_opis_skraceni",
              "broj_ponude",
              "datum_ponude",
              "rok_potvrda",
              "vozilo_id",
              "vozilo_opis",
              "napomena",
              "iznos_hrk",
              "cbc_provizija_perc",
              "cbc_provizija_iznos",
              "iznos_s_cbc_provizijom"
            ]}
          />
        </FormTabs>
        <TabPanelUpit
          currentTab={currentTab}
          tabIndex={0}
          dc={dcUpiti}
          mode="view"
          recordId={upitID}
          form="ponuda_tab"
          subModelDefaultValues={{
            prijevoznik_id: userContext.subjectId
          }}
        />
        {showTabVoznje ? (
          <TabPanelPojedinacneVoznje
            currentTab={currentTab}
            tabIndex={1}
            record={record}
            validation={validation}
            mode={mode}
            fields={fields}
            onFieldChange={onFieldChange}
          />
        ) : null}
        {showTabFinCalc ? (
          <TabPanelInternaKalkulacija
            currentTab={currentTab}
            tabIndex={tabIndexFinCalc}
            subModels={subModels}
            recordId={record.id}
            record={record}
            validation={validation}
            mode={mode}
            fields={fields}
            onFieldChange={onFieldChange}
            dialog={true}
            onFinCalcRecordsChange={handleRecordsFinCalc}
          />
        ) : null}
        <FormTabPanel value={currentTab} index={tabIndexPonuda}>
          <GridContainer>
            <FormContent
              {...commonProps}
              title={t("ponuda.sections.osnovno")}
              fieldNames={["from_ts", "to_ts", "relacija_opis_skraceni"]}
            />
            <FormContent {...commonProps} fieldNames={["broj_ponude", "datum_ponude", "rok_potvrda"]} columns={3} />
            <FormContent {...commonProps} fieldNames={["vozilo_id", "vozilo_opis", "napomena"]} />
            <FormContent
              {...commonProps}
              title={t("ponuda.sections.financije")}
              fieldNames={["iznos_hrk", "cbc_provizija_perc", "cbc_provizija_iznos", "iznos_s_cbc_provizijom"]}
              columns={4}
            />
            {avansAllowed ? <FormContent {...commonProps} fieldNames={["avans_iznos", "rok_avans_uplate"]} /> : null}
          </GridContainer>
        </FormTabPanel>
      </DialogBody>
      <DialogActions>
        <EuroConversionComment />
        <DialogToolbarFillContent />
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
        {/* <DialogActionButtonShowVoznje showTab={showTabVoznje} onToggle={handleToggleTabVoznje} /> */}
        <DialogActionButtonShowFinCalc showTab={showTabFinCalc} onToggle={handleToggleTabFinCalc} />
        <DialogActionButtonSend variant="contained" onClick={sendQuote} />
      </DialogActions>
    </DynamicDialog>
  );
}

export default withFormController(PonudaDialog);
