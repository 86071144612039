import React, { Fragment, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

// Material UI Core
import Box from "@material-ui/core/Box";

// Custom Components
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import AgencijePage from "UI/AppPage/AgencijePage";
import TrazilicaForm from "./TrazilicaForm";
import dataController from "Lib/dataController";
import model from "Models/upiti";
import GoogleMapRoute from "Components/Google/GoogleMapRoute";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import { searchService } from "Services/searchService";
import withRedirect from "Components/withRedirect";

const TrazilicaPage = React.memo((props) => {
  const { t } = useTranslation();
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);

  const [results, setResults] = useState([]);
  const [counter, setCounter] = useState(0);
  const [searchRecord, setSearchRecord] = useState(null);

  const { redirect } = props;

  const dc = new dataController(model);

  const defaultData = props.location.state ? props.location.state.data : null;

  const handleChangeSearchRecord = (rec) => {
    setSearchRecord(rec);
    setCounter(prevState => prevState + 1);
  };

  const handleSelectResult = (value, onFieldChange) => {
    onFieldChange(value, "vozilo_ids");
  };

  const handleClearSearch = (onFieldChange) => {
    setResults([]);
    onFieldChange([], "vozilo_ids");
  };

  const search = (record) => {
    loaderContext.toggleLoading(true);
    searchService
      .searchVozila(record)
      .then((resp) => {
        if (resp.success && resp.data.data) {
          snackbarContext.showNotification(resp.data.message, "success");
          setResults(resp.data.data);
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const handleSearch = (record) => {
    const preparedRecord = dc.prepareSendingData(record);
    search(preparedRecord);
  };

  const hasResults = results.length > 0;

  return (
    <AgencijePage>
      <Box m={1}>
        <Fragment>
          <GridContainer direction="row" spacing={1}>
            {hasResults ? null : (
              <GridItem xs={12} sm={12} md={6}>
                <GoogleMapRoute
                  counter={counter}
                  height={1200}
                  origin={searchRecord ? searchRecord.from_loc : null}
                  destination={searchRecord ? searchRecord.to_loc : null}
                  stops={
                    searchRecord && Array.isArray(searchRecord.stajalista)
                      ? searchRecord.stajalista.filter((x) => x.value).map((x) => x.value)
                      : []
                  }
                  route={searchRecord && searchRecord.ruta ? searchRecord.ruta[0] : null}
                />
              </GridItem>)}
            <GridItem xs={12} sm={12} md={hasResults ? 12 : 6}>
              <TrazilicaForm
                dc={dc}
                mode={"insert"}
                form={"trazilica"}
                results={results}
                defaultValues={defaultData}
                onSearch={(record) => handleSearch(record)}
                onChangeSearchRecord={handleChangeSearchRecord}
                onClearSearch={handleClearSearch}
                onSelect={handleSelectResult}
              />
            </GridItem>
          </GridContainer>
        </Fragment>
      </Box>
    </AgencijePage>
  );
})

export default withRedirect(TrazilicaPage);
