import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Material-UI Icons
import CreateIcon from '@material-ui/icons/Create';

// Material-UI Core Components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from '@material-ui/core/Zoom';

// Custom Components
import KomunikatorContext from "Components/KomunikatorContext/KomunikatorContext";

function ChatSidebar(props) {
	/**
		Constructor
		*/
	const { t } = useTranslation();
	const classes = useStyles();
	const KC = useContext(KomunikatorContext);

 	const createNewChat = (conversation) => {
 		KC.addNewChat(conversation);
 		KC.addFocused(conversation.id);
 	}

	return (
		<aside id="komunikator-drawer" style={{width:"56px"}}>
			<Drawer
				className={classes.drawer}
				variant="permanent"
				anchor="right"
			>
				<div style={{width: "56px"}}></div>
				<List 
					className={classes.komunikatorList}
					disablePadding		
				>
					{/*<ListItem
						button dense disableGutters
						// onClick={() => console.log("new convo")}
					>
						<Tooltip title={t("buttons.add_convo")}>
							<ListItemIcon className={classes.komunikatorListItemIcon}>
								<Badge
									anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
									className={classes.badge}
									color="secondary" 
									badgeContent={0}
									overlap="circle"
								>
									<Avatar>
										<CreateIcon />
									</Avatar>
								</Badge>
							</ListItemIcon>
						</Tooltip>
					</ListItem>*/}
					{
						KC.sidebarWindows.map((convo, i) => (
							<Zoom key={i + 1} in={convo.sidebar} timeout={500} style={ !convo.sidebar ? { display: "none" } : {} }>
								<ListItem
									button dense disableGutters
									onClick={() => createNewChat(convo)}
								>
									<Tooltip title={t(convo.title)}>
										<ListItemIcon className={classes.komunikatorListItemIcon}>
											<Badge 
												anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
												className={classes.badge}
												color="secondary" 
												badgeContent={convo.unread}
												overlap="circle"
											>
												{
													convo.img ? 
														<img src={convo.img} />
														:
														<Avatar className={classes.avatar} style={{backgroundColor: convo.color}}>{t(convo.title)[0]}</Avatar>
												}
											</Badge>
										</ListItemIcon>
									</Tooltip>
								</ListItem>
							</Zoom>
						))
					}						
				</List>
			</Drawer>
		</aside>
	);
}

export default ChatSidebar;

const drawerWidth = 80;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    minWidth: drawerWidth,
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  komunikatorList: {
    height: "100%",
    display: "flex",
    flexDirection: "column-reverse"
  },
  komunikatorListItemIcon: {
  	height: "56px",
  	width: "56px",
		justifyContent: "center"
  },
	badge: {
  	width : "40px",
		height : "40px",
		marginTop : "8px"
	},
	avatar: {
		width : "40px",
		height : "40px"
	}
}));
