import React from "react";
import { useTranslation } from "react-i18next";

//Material UI
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

//Custom components
import withFormController from "Components/withFormController";
import FormContent from "Components/FormContent";
import { GridContainer } from "UI/Grid";

function TrazilicaSazetak(props) {
  const { t } = useTranslation();

  const { record, validation, fields, subModels, mode, onFieldChange } = props; //HOC withFormController

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels,
  };

  return (
  <Card>
    <CardHeader title={t("trazilica.sazetak")} style={{ textAlign: "center", paddingBottom: 0 }} />
    <CardContent>
      <GridContainer>
        <FormContent {...commonProps} fieldNames={["from_ts", "to_ts"]} columns={2} />
        <FormContent
          {...commonProps}
          fieldNames={["from_loc"]}
          columns={1}
        />
        {record["stajalista"] ? (<FormContent {...commonProps} fieldNames={["stajalista"]} columns={1} />) : null}
        <FormContent
          {...commonProps}
          fieldNames={["to_loc"]}
          columns={1}
        />
        <FormContent
          {...commonProps}
          fieldNames={[
            "vozilo_tip_id",
            "vozilo_klasa_id",
            "klasa_motora_id",
            "vozilo_broj_putnika"
          ]}
          columns={2}
        />
        <FormContent {...commonProps} fieldNames={["opis"]} columns={1} />
        {record["vozilo_oprema_ids"] && record["vozilo_oprema_ids"].length > 0 ? <FormContent {...commonProps} fieldNames={["vozilo_oprema_ids"]} columns={1} /> : null}
        <FormContent {...commonProps} fieldNames={["povratna_ruta", "djeca_grupa", "izbegni_cestarine", "jezik_posade"]} columns={2} />
        <FormContent
          {...commonProps}
          fieldNames={["trajanje_voznje"]}
          columns={1}
        />
        <FormContent
          {...commonProps}
          fieldNames={["neto_udaljenost_voznje"]}
          columns={1}
        />
        <FormContent
          {...commonProps}
          fieldNames={["udaljenost_povratka_vozila"]}
          columns={1}
        />
        <FormContent {...commonProps} fieldNames={["rok_za_ponude"]} columns={1}/>
      </GridContainer>
    </CardContent>
  </Card>
  );
}

export default withFormController(TrazilicaSazetak);
