import React from "react";
import { useParams } from "react-router";

//Material UI
import Box from "@material-ui/core/Box";

//Custom components
import PrijevozniciPage from "UI/AppPage/PrijevozniciPage";
import PonudaForm from "Views/Prijevoznici/Ponude/PonudaForm";

const PonudaPage = React.memo((props) => {
  const { id } = useParams();
  const recordId = parseInt(id, 10);

  return (
    <PrijevozniciPage>
      <Box m={2}>
        <PonudaForm recordId={recordId} />
      </Box>
    </PrijevozniciPage>
  );
})

export default PonudaPage;
