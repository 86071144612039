import api from "Lib/api";

export const notificatorService = {
  getAll,
  getNew,
  markRead,
  markDelete
};

const notificatorApi = "notificator";

function getAll() {
  const apiInstance = new api();
  const path = notificatorApi + "/messages"
  return apiInstance.Call(path, "get")
}

function getNew() {
  const apiInstance = new api();
  const path = notificatorApi + "/messages?select=new";
  return apiInstance.Call(path, "get");
}

function markRead(ids) {
  const apiInstance = new api();
  const path = notificatorApi + "/messages/mark-read";
  return apiInstance.Call(path, "patch", { ids: ids });
}

function markDelete(ids) {
  const apiInstance = new api();
  const path = notificatorApi + "/messages/mark-delete";
  return apiInstance.Call(path, "patch", { ids: ids });
}
