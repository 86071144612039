import api from "Lib/api";

export const businessSubjectMemoService = {
  createImage,
  getFullImage,
  deleteImage
}

const bsApiUrl = 'business-subjects/';

function createImage(bsId, data) {
  const apiInstance = new api();
  const path = bsApiUrl + bsId + "/memo-headers"
  return apiInstance.Call(path, "post", data);
}

function getFullImage(bsId, uuid){
  const apiInstance = new api();
  const path = bsApiUrl + bsId + "/memo-headers/" + uuid;
  return apiInstance.Call(path, "get");
}

function deleteImage(bsId, uuid) {
  const apiInstance = new api();
  const path = bsApiUrl + bsId + "/memo-headers/" + uuid;
  return apiInstance.Call(path, "delete");
}
