import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

// Custom Components
import ModelTable from "UI/Table/ModelTable";
import TableCard from "UI/Table/TableCard";
import TableCardContent from "UI/Table/TableCardContent";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import dataController from "Lib/dataController";
import model from "Models/upiti";
import TableButtonView from "UI/Table/ActionButtons/TableButtonView";
import withRedirect from "Components/withRedirect";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

function AdminUpitiTable(props) {
  const { t } = useTranslation();
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);
  const { redirect } = props;

  const [records, setRecords] = useState([]);

  const dc = new dataController(model, "admin/upiti");

  const handleView = (evt, sel) => {
    const selId = parseInt(sel[0], 10);
    const record = records[selId];
    const id = record.id;

    //history.push(`/p/voznje/${id}`, id);
    redirect(`/admin/upiti/${id}`);
  };

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData().then((resp) => {
      if(resp.success){
        setRecords(resp.data);
      }
    }).catch((result) => {
      snackbarContext.showNotification(result.error, "error");
    }).finally(() => {
      loaderContext.toggleLoading(false);
    })
  };

  useEffect(() => {
    refreshRecords();
  }, [])

  const handleRowAction = function(action, id) {
    if (action === "view") {
      redirect(`/admin/upiti/${id}`);
    }
  }

  const allowRowAction = function(action, id) {
    return true;
  }

  return (
      <TableCard>
        <TableCardContent>
          <ModelTable
            title={t("titles.upiti")}
            viewName="admin"
            dc={dc}
            records={records}
            allowSelection="none"
            allowFilter={true}
            allowAdd={false}
            allowExport={true}
            onRowAction={handleRowAction}
            allowRowAction={allowRowAction}
            onDoubleClick={(id) => handleRowAction("view", id)}
            handleRefresh={refreshRecords}
          >
            {/* <TableButtonView onClick={handleView} /> */}
          </ModelTable>
        </TableCardContent>
      </TableCard>
  );
}

export default withRedirect(AdminUpitiTable);
