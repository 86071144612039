import React from 'react'
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';
import { sifrarnici } from "Lib/sifrarnici";

//Material-UI Core Components
import { makeStyles, withTheme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';

const customCheckboxStyles = makeStyles(theme => ({
  root: {
    paddingLeft: 15,
    paddingRight: 15,
    // color: theme.palette.common.white,
    '&$checked': {
      // color: theme.palette.common.white,
    }
  },
  checked: {}
}));

class CheckboxControl extends React.PureComponent {
  constructor(props){
    super(props);

    this.state = {
      items: []
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleToggleAll = this.handleToggleAll.bind(this);
    this.getItems = this.getItems.bind(this);
    this.getItem = this.getItem.bind(this);
    this.getValues = this.getValues.bind(this);
  }

  componentDidMount(){
    this.getItems();
  }

  componentDidUpdate(prevProps, prevState){
    const { value, onChange, field } = this.props;
    const tempValue = []

    if (prevProps.model !== this.props.model){
      this.getItems();
    } else if (prevProps.value !== this.props.value) {
      this.getItems();
    }

    // TODO:
    // What in the gods name is this??
    if(prevState.items !== this.state.items){

      if (Array.isArray(value)){
        value.forEach(v => {
          tempValue.push(this.getItem(v));
        })
      } else if(Number.isInteger(value)){
        const tempValueArray = this.getValues();
        tempValueArray.forEach(v => {
          tempValue.push(this.getItem(v));
        })
      }
    }

    return null;
  }

  handleChange(evt) {
    const { field, onChange, value } = this.props;
    const itemValue = evt.target.value;
    const checked = evt.target.checked;
    const item = this.getItem(itemValue);

    const tempValue = this.getValues().map(v => this.getItem(v));

    if (checked) {
      tempValue.push(item)
    } else {
      tempValue.forEach((v, i) => {
        if (v.value === itemValue  || v.value === parseInt(itemValue,10)){
          tempValue.splice(i, 1);
        }
      })
    }

    const source = field ? field.source : null;

    if (onChange) {
      onChange(tempValue, source);
    }
  };

  handleToggleAll(evt){
    const { field, onChange, value } = this.props;
    const { items } = this.state;
    const checked = evt.target.checked;

    const source = field ? field.source : null;

    if (checked) {
      if (onChange) {
        onChange(items, source);
      }

    } else {
      if (onChange) {
        onChange([], source);
      }

    }
  }

  getItems() {
    const { field, t, model } = this.props;

    if (field && field.subModel && model ){
      const idAttribute = model.fieldId.source;
      model.GetData()
        .then(resp => {
          if (model.filter) {
            const items = this.filter(resp.data);
            this.setState({
              items: items
            })
          } else {
            this.setState({
              items: resp.data ? resp.data : []
            })
          }
        });
    } else if (field && field.items) {
      const items = [];
      field.items.labels.forEach((label, i) => {
        items.push({label: t(label), value: field.items.values[i]})
      });
      this.setState({
        items: items
      });
    } else {
      this.setState({ items: [] });
    }
  }

  filter(items){
    const { model } = this.props;
    if (model.filter) {
      return items.filter(x => model.filter.find(f => f === x.value))
    } else {
      return items;
    }
  }

  getItem(value) {
    const { items } = this.state;
    return items.find(x => x.value === parseInt(value, 10));
  }

  getValues(){
    const { value } = this.props;
    let valueArray = [];
    let valueCopy = value;

    if(Array.isArray(valueCopy) && valueCopy.length >= 0){
      valueCopy.forEach(el => {
        if(typeof el === 'object' && el.value){
          valueArray.push(el.value);
        } else {
          valueArray.push(el);
        }
      })

    } else if(Number.isInteger(valueCopy) && valueCopy >= 0){
      // get array of powers of two from valueCopy
      let pow = 1
      while (valueCopy > 0) {
        if (valueCopy % 2) {
          valueArray.push(pow);
        }
        pow <<= 1;
        valueCopy = Math.floor(valueCopy / 2);
      }
    }

    return valueArray;
  }

  render(){
    const { field, t, value, validation, formMode, controlMode, onChange } = this.props;
    const { items } = this.state;

    const hasValue = value !== undefined && value !== null;
    const isRequired = (field && field.validation && field.validation.required) ? true : false;
    const hasError = validation && validation.valid === false;
    const isReadOnly = controlMode === "view" || (field && field.readonly);
    const title = field.ttoken ? t(field.ttoken) : t(field.title);

    const valueArray = this.getValues();

    const showToggleAll = (field && field.showToggleAll) || this.props.showToggleAll;
    const allChecked = valueArray.length === items.length;
    const someChecked = valueArray.length > 0 && !allChecked;

    return (
      <FormControl error={hasError}>
        <FormLabel required={isRequired}>{title}</FormLabel>
        <FormGroup
          row={field.row}
          value={hasValue ? value.value : ''}
          aria-label={t(this.props.title)}
        >
          {(showToggleAll) ? (
            <FormControlLabel control={<Checkbox
              checked={allChecked}
              indeterminate={someChecked}
              color="secondary"
              onChange={this.handleToggleAll}
            />} value={-1} label={"Toggle All"} disabled={isReadOnly} />
          ) : null}
          {items.map((item, i) =>
            <FormControlLabel
              control={
                <Checkbox
                  checked={valueArray.indexOf(item.value) >= 0}
                  color="secondary"
                  onChange={this.handleChange}
                  key={i}
                />
              }
              value={item.value}
              label={t(item.label)}
              key={i}
              disabled={isReadOnly || item.disabled}
            />
          )}
        </FormGroup>
        <FormHelperText id={field.source + "-helper"}>
            {hasError ? t(validation.msg) : (field.tooltip ? field.tooltip : " ")}
          </FormHelperText>
      </FormControl>
    )
  }
}

CheckboxControl.propTypes = {
  formMode: PropTypes.oneOf(["form", "table"]),
  controlMode: PropTypes.oneOf(["edit", "view"]),
  field: PropTypes.object.isRequired,
  value: PropTypes.any,
  validation: PropTypes.object,
  onChange: PropTypes.func
}

export default withTranslation()(withTheme(CheckboxControl));
