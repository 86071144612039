import React, { Fragment, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import numbro from "numbro";

//Material-UI Core components
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import DialogActions from "@material-ui/core/DialogActions";

//Material-UI Icons
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

//Custom Components
import withFormController from "Components/withFormController";
import DynamicDialog from "UI/Dialog/DynamicDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarCreationDetails from "UI/Dialog/DialogToolbarCreationDetails";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogActionButtonAdd from "UI/Dialog/ActionButtons/DialogActionButtonAdd";
import DialogActionButtonSave from "UI/Dialog/ActionButtons/DialogActionButtonSave";
import DialogActionButtonDelete from "UI/Dialog/ActionButtons/DialogActionButtonDelete";

import FormContent from "Components/FormContent";

import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import GridContainer from "UI/Grid/GridContainer";
import dataController from "Lib/dataController";
import model from "Models/vozilaCjenici";
import EuroConversionComment from "Components/EuroConversionComment";

function PredlozakCjenikaDialog(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();

  const { record, mode, validation, fields, subModels, recordId, path } = props; //HOC withFormController
  const { doInsert, doUpdate, doDelete, doClose } = props; //HOC withFormController
  const { onFieldChange, onClose } = props; //HOC withFormController

  const fPricePerKm = "price_per_distance";
  const fFullDayDistance = "distance_for_day";
  const fFullDayPrice = "price_for_day";

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      let price = null;

      if (source === fPricePerKm) {
        if (value && record[fFullDayDistance]) {
            price = numbro(value).value() * numbro(record[fFullDayDistance]).value()
            onFieldChange(price, fFullDayPrice)
        } else {
          onFieldChange(null, fFullDayPrice)
        }
      }

      if (source === fFullDayDistance) {
        if (value && record[fPricePerKm]) {
          price = numbro(value).value() * numbro(record[fPricePerKm]).value()
          onFieldChange(price, fFullDayPrice)
        } else {
          onFieldChange(null, fFullDayPrice)
        }
      }

      onFieldChange(value, source);
    }
  };

  const handleInsert = (evt) => {
    if (doInsert) {
      doInsert().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "insert" });
          snackbarContext.showNotification("insert", "success");
        }
      }).catch((result) => {
        if (result.success === false && result.validationPass === false) {
          //do nothing
        } else {
          snackbarContext.showNotification(result.error, "error");
        }
      });
    }
  };

  const handleUpdate = (evt) => {
    if (doUpdate) {
      doUpdate().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "update" });
          snackbarContext.showNotification("update", "success");
        }
      }).catch((result) => {
        if (result.success === false && result.validationPass === false) {
          //do nothing
        } else {
          snackbarContext.showNotification(result.error, "error");
        }
      });
    }
  };

  const handleDelete = () => {
    if (doDelete) {
      doDelete().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "delete" });
          snackbarContext.showNotification("delete", "success");
        }
      }).catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      });
    }
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const close = (result) => {
    if (result.dataChanged) {
      onClose({ dataChanged: true, action: result.action });
    }

    dialogContext.hideDialog();
  };

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: handleFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels
  };

  return (
    <DynamicDialog onClose={handleClose} >
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogAvatar ariaLabel="predlozak_cjenika" icon={<MonetizationOnIcon />} />
          <DialogToolbarHeading>{t("titles.predlozak_cjenika")}</DialogToolbarHeading>
          <DialogToolbarFillContent />
          <DialogToolbarCreationDetails record={record} />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer>
            <FormContent
              {...commonProps}
              title={t("cjenici.sections.predlozak")}
              fieldNames={["naziv", "kategorija_cjenika_id"]}
              columns={3}
            />
            <FormContent
              {...commonProps}
              title={t("cjenici.sections.osnovno")}
              fieldNames={["price_per_distance"]}
              columns={3}
            />
            <FormContent
              {...commonProps}
              title={t("cjenici.sections.kratkotrajni_najam")}
              fieldNames={["duration_for_transfer", "distance_for_transfer", "price_for_transfer"]}
              columns={3}
            />
            <FormContent
              {...commonProps}
              title={t("cjenici.sections.poludnevni_najam")}
              fieldNames={["duration_for_half_day", "distance_for_half_day", "price_for_half_day"]}
              columns={3}
            />
            <FormContent
              {...commonProps}
              title={t("cjenici.sections.cijelodnevni_najam")}
              fieldNames={["duration_for_day", "distance_for_day", "price_for_day"]}
              columns={3}
            />
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <EuroConversionComment />
        <DialogToolbarFillContent />
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
        {mode === "insert" ? <DialogActionButtonAdd variant="contained" onClick={handleInsert} /> : null}
        {mode === "update" ? (
          <Fragment>
            <DialogActionButtonDelete variant="outlined" onClick={handleDelete} />
            <DialogActionButtonSave variant="contained" onClick={handleUpdate} />
          </Fragment>
        ) : null}
      </DialogActions>
    </DynamicDialog>
  );
}

export default withFormController(PredlozakCjenikaDialog);
