import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Elements
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

//Custom Components
import withRedirect from "Components/withRedirect";
import KomunikatorContext from "Components/KomunikatorContext/KomunikatorContext";

function NotificationModal(props) {
  const KC = useContext(KomunikatorContext);
  const { t } = useTranslation();
  const { redirect } = props; //HOC withRedirect;
  const { notificationCount, notifications } = props;

  const handleClose = (event, reason) => {
    if (reason === "clickaway" || reason === "backdropClick") {
      return;
    }
    if (notificationCount === 1) {
      const link = KC.getNotificationRedirectLink(notifications[0].link_type, notifications[0].link_id);
      if (link) {
        KC.markReadNotification(notifications[0].id || notifications[0].notif_id).then((resp) => {
          if (resp.success) {
            if (link === window.location.pathname) {
              window.location.reload(true);
            } else {
              redirect(link);
            }
          }
        });
      }
    } else {
      KC.markReadNotifications(notifications.map((noti) => noti.id)).then((resp) => {
        if (resp.success) {
          redirect("/notifications", { record: notifications }, "panic");
        }
      });
    }
  };

  const remark =
    notificationCount === 1
      ? (notifications[0].tip_ttoken ? t(notifications[0].tip_ttoken) : notifications[0].tip) 
        + (notifications[0].remark ? ": " + notifications[0].remark : "")
      : notificationCount < 5
      ? t("common.panic_count_notice_2", { count: notificationCount })
      : t("common.panic_count_notice_5", { count: notificationCount });

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t("common.important_action")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{remark}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          {t("buttons.open")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withRedirect(NotificationModal);
