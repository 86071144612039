import React from "react";
import { useParams } from "react-router-dom";

//Custom components
import PrijevozniciPage from "UI/AppPage/PrijevozniciPage";
import dataController from "Lib/dataController";
import model from "Models/vozila";
import VoziloForm from "./VoziloForm";
import Box from "@material-ui/core/Box";

const VoziloView = React.memo((props) => {
  const { id } = useParams();
  const dc = new dataController(model);

  const recordId = parseInt(id, 10);

  return (
    <PrijevozniciPage>
      <Box m={2}>
        <VoziloForm dc={dc} recordId={recordId} form="default" mode="view" />
      </Box>
    </PrijevozniciPage>
  );
})

export default VoziloView;
