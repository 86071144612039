import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import moment from "moment";
//import patchedevent from 'Libs/patchedevent';

//Material-UI Core Components
import withTheme from "@material-ui/core/styles/withTheme";
import Toolbar from "@material-ui/core/Toolbar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

//Material-UI Icons
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import MouseIcon from "@material-ui/icons/Mouse";

//Custom Components
import withSnackbar from "Components/withSnackbar";
import withRedirect from "Components/withRedirect";
import { GridContainer, GridItem } from "UI/Grid";
import Loader from "UI/Loader/Loader";

import schedulerDataController from "Components/Scheduler/schedulerDataController";

import SchedulerDatetimePicker from "Components/Scheduler/SchedulerDatetimePicker";
import SchedulerDrawer from "Components/Scheduler/SchedulerDrawer";
import SchedulerDrawerHeading from "Components/Scheduler/SchedulerDrawerHeading";
import SchedulerDrawerSwitch from "Components/Scheduler/SchedulerDrawerSwitch";
import SchedulerDrawerSwitches from "Components/Scheduler/SchedulerDrawerSwitches";
import SchedulerGrid from "Components/Scheduler/SchedulerGrid";
import SchedulerFilterForm from "Components/Scheduler/SchedulerFilterForm";
import SchedulerToolbarNavigation from "Components/Scheduler/SchedulerToolbarNavigation";
import SchedulerToolbarActionsData from "Components/Scheduler/SchedulerToolbarActionsData";
import SchedulerToolbarActionsView from "Components/Scheduler/SchedulerToolbarActionsView";
import SchedulerLegendButton from "Components/Scheduler/SchedulerLegendButton";

import { posloviService } from "Services/posloviService";
import VoznjaSazetakDialog from "Views/Prijevoznici/Voznje/VoznjaSazetakDialog";
import PojedinacnaVoznjaDialog from "Views/Prijevoznici/Voznje/VoznjaFormTabDialogs/PojedinacnaVoznjaDialog";
import RezervacijaDialog from "Views/Prijevoznici/Rezervacije/RezervacijaDialog";
import modelPoslovi from "Models/poslovi";
import modelPojedinacneVoznje from "Models/pojedinacneVoznje";
import modelRezervacije from "Models/rezervacije";

import DialogConsumer from "UI/DialogContext/DialogConsumer";
import UserConsumer from "Components/UserContext/UserConsumer";

import model from "Models/kalendar";
import modelSearch from "Models/search";
import dataController from "Lib/dataController";
import { formats } from "Lib/formats";

// @Piero
// Should we change this to functional component? if there is time certainly
// i dont think so, at least not for now
class SchedulerView extends React.Component {
  constructor(props) {
    super(props);
    const { location, history } = props;
    // console.log("scheduler is alive", props); // well you can go kill yourself

    this.resolutions = [1, 2, 3, 4, 6, 8/*, 12, 24*/];

    this.thisWeekStart = moment().isoWeekday(1).startOf("day");

    let urlStartDate = null;
    let urlResolution = null;
    const hParams = location.hash.length > 0 ? location.hash.substring(1).split('|') : null;
    if (hParams !== null) {
      hParams.forEach(p => {
        const dt = moment(p, formats.isodate, true);
        if (dt.isValid()) {
          urlStartDate = moment(p);
        } else if (!isNaN(parseInt(p, 10))) {
          urlResolution = parseInt(p, 10);
        }
      })
    }

    // const urlStartDate = location.hash.length > 0 ? moment(location.hash.substring(1)) : null;


    this.dcVoznja = new dataController(modelPoslovi, "poslovi", "?fields=summary");
    this.dcRezervacije = new dataController(modelRezervacije);
    this.dcPojedinacneVoznje = new dataController(modelPojedinacneVoznje);

    this.state = {
      startDate: urlStartDate ? urlStartDate : this.thisWeekStart,
      resolutionHours: urlResolution ? urlResolution : 2,
      dateTimeAnchorEl: null,
      dateUnderMouse: null,
      iconUnderMouse: null,
      userShow: {
        vozaci: true,
        vozila: true,
        voznje: true,
        ostalo: true
      },
      userDetails: {
        registracija: true,
        vozac: true,
        opis: true
      },
      userFilters: {},
      isLoading: false,
      records: [],
      conflicts: {},
      cellWidth: 15,
    };

    this.dcSearch = new dataController(modelSearch);

    this.sdc = new schedulerDataController(this.thisWeekStart);
    this.dc = new dataController(model);

    //this.handleToolbarFilterChange = this.handleToolbarFilterChange.bind(this);
    this.handleOpenDateTime = this.handleOpenDateTime.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);

    this.handleSetUserFilter = this.handleSetUserFilter.bind(this);

    this.refreshData = this.refreshData.bind(this);
    this.forceRefreshData = this.forceRefreshData.bind(this);
    this.showDetails = this.showDetails.bind(this);
    this.showPrikaz = this.showPrikaz.bind(this);

    this.changeStartDate = this.changeStartDate.bind(this);
    this.manualStartChange = this.manualStartChange.bind(this);
    this.changeResolution = this.changeResolution.bind(this);

    this.cell_enter = this.cell_enter.bind(this);
    this.cell_leave = this.cell_leave.bind(this);
    this.toggleItemGroupState = this.toggleItemGroupState.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.filterRecords = this.filterRecords.bind(this);
    this.positionRecords = this.positionRecords.bind(this);

  }

  componentDidMount() {
    this.refreshData(true);
  }

  forceRefreshData() {
    this.refreshData(true);
  }

  /**
    @name: refreshData
    @description: refresh data if necessary (CheckData is taking care of that)
    @params:
      force: bool (if you really, really want to refresh the data)
    @returns:
      undefined
    */
  refreshData(force = false) {
    const { startDate, resolutionHours } = this.state;
    this.setState({ isLoading: true });
    this.sdc.CheckData(startDate, resolutionHours, force)
      .then(resp => {
        if (resp) {
          const [records, conflicts] = resp;
          // console.log('DATA', records, conflicts);
          const posRecords = this.positionRecords(records);
          this.setState({
            records: posRecords || [],
            conflicts: conflicts || {},
          })
        } else {
          // Data didn't change only update positions
          const posRecords = this.positionRecords( this.state.records );
          this.setState({
            records: posRecords || []
          });
        }
      })
      .catch((error) => console.error("There wa a problem with fetching data", error))
      .finally(() => this.setState({ isLoading: false }));
  }

  /**
   * @name: positionRecords
   * @description: Add information about records' positions on screen
   * @param {*} records
   */
  positionRecords(records) {
    const { startDate, resolutionHours, cellWidth } = this.state;

    return Array.isArray(records) ? records.map(r => {
      const dOffset = moment.duration( r.ts1.clone().utc().diff( startDate.clone().utc() ) );
      const offset = Math.floor( ( dOffset.asMinutes() ) * (cellWidth / resolutionHours) ) / 60;

      const dWidth = moment.duration( r.ts2.clone().utc().diff( r.ts1.clone().utc() ) )
      const width = Math.floor( ( dWidth.asMinutes() ) * (cellWidth / resolutionHours) ) / 60;

      const subJobs = this.positionRecords(r.subJobs);

      return Object.assign({}, r, { offset, width, subJobs });
    }) : [];
  }

  /**
    @name: changeResolution
    @description: change the hour resolution of the scheduler and refresh data
    @params:
      newpos: integer (position of the new resolution in resolutions array)
    @returns:
      void
    */
  changeResolution(newpos) {
    const { startDate, resolutionHours } = this.state;
    const { location, history } = this.props;

    // check if new resolution position is in bounds
    const calcPos = Math.max(0, Math.min(this.resolutions.length - 1, newpos));
    // if new resolution is same as old do nothing
    if (calcPos === this.resolutions.indexOf(resolutionHours)) return;

    const newResH = this.resolutions[calcPos];
    this.setState({ resolutionHours: newResH }, () => this.refreshData());

    let hash = '';
    if (location.hash.length > 0) {
      hash = location.hash.substring(1);
      let hParams = hash.split('|');
      let replaced = false;
      hParams.forEach((p,i) => {
        const dt = moment(p, formats.isodate, true);
        if (dt.isValid()) {
          //skip
        } else if (!isNaN(parseInt(p, 10))) {
          hParams[i] = newResH;
          replaced = true;
        }
      })
      if (!replaced) {
        hParams.push(newResH);
      }
      hash = "#" + hParams.join('|');
    } else {
      hash = '#' + newResH;
    }

    history.replace(location.pathname + hash)
  }

  /**
    @name: changeStartDate
    @description: change the leftmost date of the scheduler view and refresh data
    @params:
      newdate: moment object
    @returns:
      void
    */
  changeStartDate(newDate) {
    const { resolutionHours }  = this.state;
    const { location, history } = this.props;

    if (!newDate.isValid()) {
      return;
    }
    this.setState({ startDate: newDate }, () => this.refreshData());

    let hash = '';
    if (location.hash.length > 0) {
      hash = location.hash.substring(1);
      let hParams = hash.split('|');
      let replaced = false;
      hParams.forEach((p,i) => {
        const dt = moment(p, formats.isodate, true);
        if (dt.isValid()) {
          hParams[i] = newDate.format(formats.isodate);
          replaced = true;
        }
      })
      if (!replaced) {
        hParams.push(newDate.format(formats.isodate));
      }
      hash = "#" + hParams.join('|');
    } else {
      hash = '#' + newDate.format(formats.isodate);
    }

    history.replace(location.pathname + hash)
  }

  /**
    @name: manualStartChange
    @description: manually change the leftmost date of scheduler view by some value
    @params:
      dir: integer (1|-1) go left or right
      stepWeight: integer (value by how much we should change the start date)
    @returns:
      void
    */
  manualStartChange(dir, stepWeight) {
    const { startDate, resolutionHours } = this.state;

    let interval = 2;
    if (resolutionHours == 1) {
      interval = 2;
    } else if (resolutionHours > 1 && resolutionHours <= 3) {
      interval = 7;
    } else if (resolutionHours > 3 && resolutionHours <= 6) {
      interval = 14;
    } else if (resolutionHours > 6) {
      interval = 28;
    }

    let step = dir + dir * stepWeight * (interval - 1);
    this.changeStartDate(startDate.clone().add(step, "days"));
  }

  /**
    @name: handleOpenDateTime
    @description: open the popper with datetime picker
    @params:
      evt: event object
    @returns:
      void
    */
  handleOpenDateTime(evt) {
    this.setState({
      dateTimeAnchorEl: evt.currentTarget
    });
  }

  cell_enter(evt, day, div) {
    return; // because this doesnt add anything to the calendar
    const { hideTime } = this.props;
    const { startDate, dateUnderMouse } = this.state;

    let offset = day * 24 + div * this.state.resolutionHours;
    let target = startDate.clone().add(offset, "hours");
    let end = Math.min(target.hours() + this.state.resolutionHours, 24);
    let value = hideTime ? target.format("DD.MM.YYYY") : target.format("DD.MM.YYYY H") + "-" + end;
    if (value !== dateUnderMouse) {
      this.setState({ dateUnderMouse: value, iconUnderMouse: <MouseIcon /> });
    }
  }

  cell_leave(evt) {
    return; // because this doesnt add anything to the calendar
    this.setState({ dateUnderMouse: null, iconUnderMouse: null });
  }

  // @Piero
  // TODO:
  handleClick(record, subjectId, showDialog) {
    const { showNotification } = this.props;
    const { redirect } = this.props; //HOC withRedirect

    if (record.maintype === 1) {
      redirect("/p/voznje/" + record.id + "#osnovni-podaci");
      // showDialog(VoznjaSazetakDialog, {
      //   dc: this.dcVoznja,
      //   secondary: "poslovi/__split__?fields=summary",
      //   form: "sazetak",
      //   mode: "update",
      //   recordId: record.id,
      //   onClose: this.handleDialogClose,
      //   external: true,
      //   subModelDefaultValues: {
      //     prijevoznik_id: subjectId
      //   }
      // });
    } else if (record.maintype === 2) {
      showDialog(RezervacijaDialog, {
        dc: this.dcRezervacije,
        mode: "update",
        form: "update",
        recordId: record.id,
        onClose: this.handleDialogClose,
        subModelDefaultValues: {
          prijevoznik_id: subjectId
        }
      });
    } else if (record.maintype === 3) {
      redirect("/p/voznje/" + record.linkid + "#pojedinacne-voznje");
      // posloviService
      //   .getPojedinacneVoznjeById(record.trjob, record.id)
      //   .then((resp) => {
      //     if (resp && resp.success) {
      //       showDialog(PojedinacnaVoznjaDialog, {
      //         dc: this.dcPojedinacneVoznje,
      //         record: resp.data,
      //         mode: "update",
      //         form: "default",
      //         posaoId: record.trjob,
      //         onClose: this.handleDialogClose,
      //         subModelDefaultValues: {
      //           prijevoznik_id: subjectId
      //         }
      //       });
      //     } else {
      //       showNotification(result.error.message, "error");
      //     }
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //   });
    } else if (record.maintype === 4) {
      redirect("/p/ponude/" + record.id);
    }
  }

  /**
    @name: toggleItemGroupState
    @description: change if voznja is grouped (sub voznje not showing)
    @params:
      recordId: integer (id of the voznja which should be grouped/ungrouped)
    @returns:
      void
    */
  toggleItemGroupState(recordId) {
    this.setState(prevState => ({
      records: prevState.records.map(r =>
        r.id === recordId ?
          Object.assign({}, r, {grouped: !r.grouped})
        : r.maintype === 3 && r.trjob === recordId ?
          Object.assign({}, r, {dontShow: !r.dontShow})
        : r
      )
    }));
  }

  /**
    @name: handleSetUserFilter
    @description: set the filters for records (vozila, vozaci or free text)
    @params:
      filterRecord: record object
    @returns:
      void
    */
  handleSetUserFilter(filterRecord) {
    this.setState({ userFilters: filterRecord });
  }

  /**
    @name: handleDialogClose
    @description: handle what happens when dialog is closed
    @params:
      result: result object
    @returns:
      void
    */
  handleDialogClose(result) {
    if (result.dataChanged) {
      this.refreshData(true);
    }
  }

  /**
    @name: showDetails
    @description: set what record details we should see
    @params:
      item: string (name of the item whose value we want to toggle)
    @returns:
      void
    */
  showDetails(item){
    this.setState(prevState => {
      const userDetails = Object.assign({}, prevState.userDetails);
      userDetails[item] = !prevState.userDetails[item];
      return {
        userDetails: userDetails
      }
    })
  }

  /**
    @name: showPrikaz
    @description: set what types of records we want to see (voznje, vozila, vozaci, ostalo)
    @params:
      item: string (name of the type of records whose value we want to toggle)
    @returns:
      void
    */
  showPrikaz(item) {
    this.setState((prevState) => {
      const userShow = Object.assign({}, prevState.userShow);
      userShow[item] = !prevState.userShow[item];
      // console.log(userShow);
      return {
        userShow: userShow
      };
    });
  }

  /**
    @name: nonEmptyObject
    @description: return true if the object is not empty else false
    @params:
      object
    @returns:
      bool
    */
  nonEmptyObject(o) {
    return Object.keys(o).some(k => this.nonEmpty(o[k]));
  }

  /**
    @name: nonEmpty
    @description: return true if value is not empty else false
    @params:
      value
    @returns:
      bool
    */
  nonEmpty(v) {
    return v !== undefined && v !== null && v !== "" && v !== [];
  }

  /**
    @name: filterRecords
    @description: filter records according to the filters set by user
    @params:
      void
    @returns:
      void
    */
  filterRecords() {
    const { startDate, records, userShow: US, userFilters: UF } = this.state;
    return records && Array.isArray(records) ?
      records
        .filter(r => !r.ts2.isBefore(startDate, 'minutes') /*&& !t.ts1.isAfter()*/) // add isAfter logic
        .filter(r =>
          US.voznje && r.maintype === 1 || // poslovi
          US.voznje && r.maintype === 3 || // voznje na poslovima
          US.voznje && r.maintype === 4 || // predane ponude
          US.vozila && r.maintype === 2 && Math.floor(r.subtype / 10) === 3 ||
          US.vozaci && r.maintype === 2 && Math.floor(r.subtype / 10) === 4 ||
          US.ostalo && r.maintype === 2 && Math.floor(r.subtype / 10) === 0
        )
        .filter(r =>
          // console.log(r.content.vozaci, UF.vozac_id) ||
          !this.nonEmptyObject(UF) ||
          (this.nonEmpty(UF.search)    && this.nonEmpty(r.opis) && r.opis.toLowerCase().indexOf(UF.search.toLowerCase()) >= 0) ||
          (this.nonEmpty(UF.vozilo_id) && UF.vozilo_id.find(v => r.content.vozilo && v.value === r.content.vozilo.id)) ||
          (this.nonEmpty(UF.vozac_id)  && UF.vozac_id.find(v => r.content.vozaci.find(vozac => vozac.id === v.value) !== undefined))
        )
      : [];
  }

  render() {
    const {
      dateTimeAnchorEl,
      resolutionHours,
      startDate,
      userDetails,
      userFilters,
      userShow,
      isLoading,
      records,
      conflicts
    } = this.state;

    const { t } = this.props;
    const currentResolution = this.resolutions.indexOf(resolutionHours);

    // TODO: icon under mouse update!!!
    const iconUnderMouse = this.state.iconUnderMouse ? this.state.iconUnderMouse : <CalendarTodayIcon />;
    const dateUnderMouse = this.state.dateUnderMouse
      ? this.state.dateUnderMouse
      : startDate.format("DD.MM.YYYY");

    const filteredRecords = this.filterRecords();

    return (
      <Fragment>
      <DialogConsumer>
        {({ showDialog }) => (
          <UserConsumer>
            {({ subjectId }) => (
              <Card id="scheduler-component-xx" style={{ marginBottom: "0px" }}>
                <CardContent>
                  <Toolbar variant="dense" disableGutters={true}>
                    {/* Add responsivity to these toolbar actions */}
                    <SchedulerToolbarActionsData
                      openDateTime={this.handleOpenDateTime}
                      handleRefresh={this.forceRefreshData}
                      displayIcon={iconUnderMouse}
                      displayDate={dateUnderMouse}
                    />
                    <SchedulerToolbarNavigation
                      manualStartChange={this.manualStartChange}
                      setStartToday={() => this.changeStartDate(this.thisWeekStart)}
                    />
                    <SchedulerToolbarActionsView
                      changeResolution={this.changeResolution}
                      resolutions={this.resolutions}
                      currentResolution={currentResolution}
                    />
                    <SchedulerDatetimePicker
                      dateTimeAnchorEl={dateTimeAnchorEl}
                      onClose={() => this.setState({ dateTimeAnchorEl: null })}
                      startDate={startDate}
                      changeStartDate={this.changeStartDate}
                    />
                    <SchedulerLegendButton />
                  </Toolbar>
                  <GridContainer>
                    <GridItem xs={2}>
                      <SchedulerDrawer>
                        <SchedulerDrawerSwitches
                          title={t("scheduler.show")}
                          state={userShow}
                          setState={this.showPrikaz}
                          fieldIds={['voznje', 'vozila', 'vozaci', 'ostalo']}
                        />
                        {
                          subjectId ?
                          <SchedulerFilterForm
                            dc={this.dcSearch}
                            form="scheduler"
                            onFilterChange={this.handleSetUserFilter}
                            subModelDefaultValues={{ prijevoznik_id: subjectId }}
                          />
                          : null
                        }
                        <SchedulerDrawerSwitches
                          title={t("scheduler.details")}
                          state={userDetails}
                          setState={this.showDetails}
                          fieldIds={['registracija', 'vozac', 'opis']}
                        />
                      </SchedulerDrawer>
                    </GridItem>
                    <GridItem xs={10}>
                      <SchedulerGrid
                        startDate={startDate}
                        resolutionHours={this.state.resolutionHours}
                        records={filteredRecords}
                        conflicts={conflicts}
                        onCellEnter={this.cell_enter}
                        onCellLeave={this.cell_leave}
                        onToggleGrouped={this.toggleItemGroupState}
                        onItemClick={(r) => this.handleClick(r, subjectId, showDialog)}
                        onUpdateCellWidth={(cw) => this.setState({ cellWidth: cw })}
                        userDetails={userDetails}
                        userContextSubjectId={subjectId}
                        onNeedRefresh={this.forceRefreshData}
                      />
                    </GridItem>
                  </GridContainer>
                </CardContent>
              </Card>
            )}
          </UserConsumer>
        )}
      </DialogConsumer>
      <Loader open={isLoading} />
      </Fragment>
    );
  }
}

export default withTranslation()(withTheme(withSnackbar(withRedirect(withRouter(SchedulerView)))));
