import React from 'react'

//Custom Components
import PickerControl from 'Controls/PickerControl';
import TextControl from 'Controls/TextControl';
import PasswordControl from "Controls/PasswordControl";
import WholeNumberControl from 'Controls/WholeNumberControl';
import FloatNumberControl from 'Controls/FloatNumberControl';
import DateTimeControl from 'Controls/DateTimeControl';
import BoolControl from 'Controls/BoolControl';
import CurrencyControl from 'Controls/CurrencyControl';
import RichTextControl from 'Controls/RichTextControl';
import CheckboxControl from 'Controls/CheckboxControl';
import RadioControl from 'Controls/RadioControl';
import FileControl from 'Controls/FileControl';
import RatingControl from 'Controls/RatingControl';
import LinkControl from "Controls/LinkControl";
import HERELocationPickerControl from "Controls/HERELocationPickerControl";
import HERELocationViaControl from "Controls/HERELocationViaControl";
import GooglePlacesAutocomplete from "Controls/GooglePlacesAutocomplete";
import ViewControl from "Controls/ViewControl";
import GooglePlacesViaControl from "Controls/GooglePlacesViaControl";

let formGenerator = function () {

}

formGenerator.prototype.generateFormControls = function(fields, formMode, controlMode, record, validation, onChangeHandler, subModels) {
  let rec = {}
  if (fields) {
    fields.forEach(field => {
      if (field) {
        const defaultValue = record && field && field.source && record.hasOwnProperty(field.source) ? record[field.source] : null;
        const fieldValidation = validation && validation.hasOwnProperty(field.source) ? validation[field.source] : null;
        const control = this.generateFieldControl(field, formMode, controlMode, record, defaultValue, fieldValidation, onChangeHandler, subModels);

        rec[field.source] = control;
      }
    });
  }
  return rec;
}

formGenerator.prototype.generateFieldControl = function(field, formMode, controlMode, record, value, validation, onChangeHandler, subModels) {

  let model = null;
  if (field && field.subModel && subModels) {
    model = subModels[field.source];
  }

  const commonProps = {
    field: field,
    formMode: formMode,
    controlMode: controlMode,
    value: value,
    validation: validation,
    onChange: onChangeHandler
  }

  if (controlMode === "simpleView") {
    return <ViewControl {...commonProps} model={model} record={record}/>
  }

  switch (field.type) {
    case ('numeric'):
      return <FloatNumberControl {...commonProps} />
    case ('wholenum'):
      return <WholeNumberControl {...commonProps} />
    case ('date'):
      return <DateTimeControl {...commonProps} showTime={false} showTimeLeft={this.should_show_timeleft(field)} />
    case ('datetime'):
      return <DateTimeControl {...commonProps} showTimeLeft={this.should_show_timeleft(field)} />
    case ('pickergroup'):
      return <PickerControl {...commonProps} isLoading={subModels ? false : true} model={model} />
    case ('picker'):
      return <PickerControl {...commonProps} isLoading={subModels ? false : true} model={model} />
    case ('multiline'):
      return <TextControl {...commonProps} multiline={true} rows={field.rows || 1} rowsMax={field.rowsMax} />
    case 'currency':
        return <CurrencyControl {...commonProps} />
    case ('boolean'):
      return <BoolControl {...commonProps} />
    case ('password'):
      return <PasswordControl {...commonProps}/>
    case ('richtext'):
      return <RichTextControl {...commonProps} />;
    case ('checkbox'):
      return <CheckboxControl {...commonProps} model={model}/>;
    case ('radio'):
      return <RadioControl {...commonProps} model={model}/>
    case ('images'):
    //case ('cmsimages'):
      return <FileControl accept={'image/jpeg,image/png'} {...commonProps} />
    case ('dokumenti'):
      return <FileControl accept={'*'} {...commonProps} />
    case "mail":
      return <LinkControl {...commonProps} linkType={"mail"} />
    case "link":
      return <LinkControl {...commonProps} linkType={field.link.recordType} linkId={record[field.link.recordIdField]} />
    case ('rating'):
      return <RatingControl {...commonProps} />
    case ('HEREautocomplete'):
      return <HERELocationPickerControl {...commonProps}/>
    case "HEREautocompleteViaStops":
      return <HERELocationViaControl {...commonProps} />
    case "GooglePlaces":
      return <GooglePlacesAutocomplete {...commonProps} />
    case "GooglePlacesViaControl":
      return <GooglePlacesViaControl {...commonProps} />
    default:
      return <TextControl {...commonProps} />
  }
}

formGenerator.prototype.should_show_timeleft = function(attr) {
  return attr.flags ? attr.flags.indexOf('showTimeLeft') > -1 : false;
}

export default formGenerator;
