import React, { useState } from "react";
import PropTypes from "prop-types";
import numbro from "numbro";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import InputAdornment from "@material-ui/core/InputAdornment";

//Custom Controls
import TextControl from "Controls/TextControl";

function CurrencyControl(props) {
  const [isFocused, setIsFocused] = useState(false);
  const { field, value, onChange } = props;
  const { t } = useTranslation();

  const handleFocus = () => {
    setIsFocused(true);
  }

  const handleBlur = () => {
    setIsFocused(false);
  }

  const handleChange = (newValue, source) => {
    if(!(newValue instanceof Object)) {
      const decValue = newValue.length > 0 ? newValue.replace(".", ",") : null;
  
      const valueIsValid = newValue.length === 0 || numbro(decValue).value() !== undefined;
  
      const numbers = newValue.length > 0 ? newValue.split(",") : null;
      if (numbers && numbers.length === 2 && numbers[1].length > 2) {
        return;
      }
      if (onChange && valueIsValid) {
        onChange(decValue, field.source);
      }
    } else {
      handleBlur()
    }
  };

  const formatEditingValue = (value) => {
    if (typeof value === "string") {
      return value;
    } else if (typeof value === "number") {
      const isInt = Number.isInteger(value);
      return numbro(value).format({ thousandSeparated: false, mantissa: isInt ? 0 : 2 });
    } else if (typeof value === "object") {
      const isInt = Number.isInteger(value.value());
      return value.format({ thousandSeparated: false, mantissa: isInt ? 0 : 2 });
    } else {
      return value;
    }
  };

  const formatDisplayValue = (value) => {
    const numValue = numbro(value);
    if (numValue.value() !== undefined && numValue.value() !== null) {
      return numValue.format({ thousandSeparated: true, mantissa: 2 });
    } else {
      return "";
    }
  };

  const hasValue = value !== undefined && value !== null;
  const currency = field && field.currency ? field.currency : "€";
  const negativeRed = field && field.negativeRed ? true : false;

  return (
    <TextControl
      {...props}

      value={hasValue ? (isFocused ? formatEditingValue(value) : formatDisplayValue(value)) : ""}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}

      inputProps={{
        startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
        style: negativeRed ? { color: "#F00" } : null
      }}
      inputLabelProps={{
        shrink: true
      }}
    />
  );
}

CurrencyControl.defaultProps = {
  formMode: "form",
  controlMode: "edit"
};

CurrencyControl.propTypes = {
  formMode: PropTypes.oneOf(["form", "table"]),
  controlMode: PropTypes.oneOf(["edit", "view"]),
  field: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  label: PropTypes.string,
  helperText: PropTypes.string,
  validation: PropTypes.object,
  onChange: PropTypes.func
};

export default CurrencyControl;
