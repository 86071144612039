import React, { Fragment } from 'react';

// Material-UI Icons
import MenuIcon from "@material-ui/icons/Menu";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";

// Custom Components
import ToolbarButton from "UI/Toolbar/ToolbarButton";
import ToolbarMenuButton from "UI/Toolbar/ToolbarMenuButton";

function SchedulerToolbarActionsView(props) {
	const { changeResolution, resolutions, currentResolution } = props;

	return (
		<Fragment>
      <ToolbarButton
        onClick={() => changeResolution(currentResolution + 1)}
        color="secondary"
        disabled={currentResolution === resolutions.length - 1}
      >
        <ZoomOutIcon />
      </ToolbarButton>
      <ToolbarButton
        onClick={() => changeResolution(currentResolution - 1)}
        color="secondary"
        disabled={currentResolution < 1 ? true : false}
      >
        <ZoomInIcon />
      </ToolbarButton>
      <ToolbarMenuButton
        onSelect={(value) => changeResolution(value)}
        color="secondary"
        items={resolutions}
        selected={currentResolution}
      >
        <MenuIcon />
      </ToolbarMenuButton>
		</Fragment>
	)
}

export default SchedulerToolbarActionsView;