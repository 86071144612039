import React from "react";
import moment from "moment";
import PropTypes from "prop-types";

// Material-UI Core Components
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// Material-UI Icons
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";
import PersonIcon from "@material-ui/icons/Person";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import HelpIcon from "@material-ui/icons/Help";

// Custom components
import UngroupIcons from "Components/Scheduler/UngroupIcons";
import SchedulerPicker from "Components/Scheduler/SchedulerPicker";
import schedulerDataController from "Components/Scheduler/schedulerDataController";

import SchedulerGridBarBox from "Components/Scheduler/SchedulerGridBarBox";
import SchedulerGridBarIconGroup from "Components/Scheduler/SchedulerGridBarIconGroup";

const SDC = schedulerDataController;

const tooltipStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(255, 0, 0, 1)",
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}));

const errorColor = makeStyles((theme) => ({
  root: {
    color: theme.palette.error.dark,
    fontWeight: "bold"
  }
}));

const goodColor = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary
  }
}));

// @Piero
// please refactor this boy
function SchedulerGridBarIcons(props) {
  const { id } = props;
  const { vozilo, vozac, opis, cooperant } = props.showDetails;
  const { grouped, handleToggleGrouped, hasSubJobs, conflicts, timeSpan, onNeedRefresh } = props;
  const { posaoId, voznjaId, rezervacijaId, ponudaId } = props;

  const vozaci = [];
  const classes = goodColor();
  const classesConflict = errorColor();
  const tooltipClasses = tooltipStyles();

  let voziloConf = false;

  let voziloTTip;
  let vozacTTip;

  if (vozilo.content && conflicts[vozilo.content.id]) {
    conflicts[vozilo.content.id].conflictIntervals.forEach((ci) => {
      if (voziloConf) return;
      if (SDC.checkIntervalOverlap(ci, timeSpan)) {
        voziloConf = true;
        voziloTTip = `${ci.from.format("DD.MM HH:mm")} - ${ci.to.format("DD.MM HH:mm")}`;
      }
    });
  }

  if (vozac && Array.isArray(vozac.content)) {
    vozac.content.forEach((item, i) => {
      let vozaciConf = false;
      if (conflicts[item.id]) {
        conflicts[item.id].conflictIntervals.forEach((ci) => {
          if (vozaciConf) return;
          if (SDC.checkIntervalOverlap(ci, timeSpan)) {
            vozaciConf = true;
            vozacTTip = `${ci.from.format("DD.MM HH:mm")} - ${ci.to.format("DD.MM HH:mm")}`;
          }
        });
      }
      if (item.id) {
        vozaci.push(
          <div key={`div${i}`} style={{ display: "inline-block" }}>
            {item.label === "?" ? (
              <HelpIcon color="disabled" fontSize="small" key={"vozac-" + i} />
            ) : (
              <div>
                <PersonIcon
                  key={`icon${i}`}
                  fontSize="small"
                  classes={vozaciConf ? classesConflict : classes}
                  onClick={
                    () => {}
                    // tempVozaciConf-- > 0 ?
                    //   () => props.onIconClick({trjobId: props.trjobId, id: item.id, timeSpan: props.timeSpan})
                    //   : null
                  }
                />
                {vozac.state ? (
                  <div style={{ float: "right" }} key={`text${i}`}>
                    <SchedulerPicker
                      conflict={vozaciConf}
                      label={item.label}
                      dcRecord={props.dcRecord}
                      dcPicker={props.dcVozac}
                      posaoId={posaoId}
                      voznjaId={voznjaId}
                      rezervacijaId={rezervacijaId}
                      fieldName={rezervacijaId ? "vozac_id" : voznjaId ? "vozac_" + (i + 1) + "_id" : "vozac_id"}
                      fieldValue={item.id}
                      id={id}
                      autoFocus={true}
                      onChange={onNeedRefresh}
                      readOnly={props.isPanic}
                    />
                  </div>
                ) : null}
              </div>
            )}
          </div>
        );
      }
    });
  }

  return (
    <Toolbar variant="dense" disableGutters={true}>
      {hasSubJobs ? <SchedulerGridBarIconGroup id={id} grouped={grouped} onToggle={handleToggleGrouped} /> : null}

      {grouped ? (
        <UngroupIcons
          subjobs={props.subjobs}
          trjobId={props.trjobId}
          driverState={vozac.state}
          registrationState={vozilo.state}
          allConflicts={props.allConflicts}
          classes={classes}
          classesConflict={classesConflict}
          conflictsActive={props.conflictsActive}
          onIconClick={props.onIconClick}
          dcVozilo={props.dcVozilo}
          dcVozac={props.dcVozac}
          dcRecord={props.dcRecord}
          dcPojedinacnaVoznja={props.dcPojedinacnaVoznja}
          posaoId={posaoId}
          onChange={onNeedRefresh}
        />
      ) : null}

      {cooperant &&
      cooperant.content &&
      cooperant.content.id &&
      props.subjobs !== null &&
      props.subjobs.length === 0 ? (
        <SchedulerGridBarBox>
          <AirportShuttleIcon fontSize="small" classes={classes} />
          {cooperant.state ? <span style={{ float: "right" }}>
            <Typography variant="body2" color="textPrimary">
              &nbsp;{cooperant.content.label}&nbsp;
            </Typography>
            </span> : null}
        </SchedulerGridBarBox>
      ) : null}

      {vozilo &&
      vozilo.content &&
      vozilo.content.id &&
      (!cooperant || !cooperant.content || !cooperant.content.id) &&
      props.subjobs !== null &&
      props.subjobs.length === 0 ? (
        <SchedulerGridBarBox>
          <DirectionsBusIcon
            fontSize="small"
            classes={voziloConf ? classesConflict : classes}
            onClick={
              () => {}
              // voziloConf ?
              // () => props.onIconClick({trjobId: props.trjobId, id: vozilo.content.id, timeSpan: props.timeSpan})
              // : null
            }
          />
          {vozilo.state ? (
            <span style={{ float: "right" }}>
              <SchedulerPicker
                conflict={voziloConf}
                label={vozilo.content.label}
                dcRecord={props.dcRecord}
                dcPicker={props.dcVozilo}
                posaoId={posaoId}
                voznjaId={voznjaId}
                ponudaId={ponudaId}
                rezervacijaId={rezervacijaId}
                fieldName={"vozilo_id"}
                fieldValue={vozilo.content.id}
                id={id}
                autoFocus={true}
                onChange={onNeedRefresh}
                readOnly={props.isPanic}
              />
            </span>
          ) : null}
        </SchedulerGridBarBox>
      ) : null}

      {vozaci.length !== 0 && !cooperant.content && props.subjobs.length === 0 ? (
        <SchedulerGridBarBox>{vozaci.map((person) => person)}</SchedulerGridBarBox>
      ) : null}

      {opis.state && opis.content.length ? (
        <SchedulerGridBarBox>
          <Typography variant="body2" color="textPrimary">
            &nbsp;{opis.content}&nbsp;
          </Typography>
        </SchedulerGridBarBox>
      ) : null}
      <div className="scheduler-span-icons-hover"></div>
    </Toolbar>
  );
}

SchedulerGridBarIcons.propTypes = {
  dcVozilo: PropTypes.object,
  dcVozac: PropTypes.object,
  dcRecord: PropTypes.object,
  id: PropTypes.number,
  trjobId: PropTypes.number,
  posaoId: PropTypes.number,
  voznjaId: PropTypes.number,
  rezervacijaId: PropTypes.number
};

export default SchedulerGridBarIcons;
