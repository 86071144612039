import React, { Fragment } from "react";
import PropTypes from "prop-types";

//Material-UI Core Components
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const style = {
  toolbarHeading: {
    marginLeft: 10,
    marginRight: 20
  }
};

class DialogToolbarHeading extends React.Component {
  render() {
    const { children, classes, header, subheader } = this.props;

    return header ? (
      <div>
        <Typography
          align="left"
          variant="subtitle1"
          className={classes.toolbarHeading}
          style={this.props.lowercase ? { textTransform: "none" } : null}
        >
          {header}
        </Typography>
        {subheader ? (
          <Typography
            align="left"
            variant="subtitle2"
            className={classes.toolbarHeading}
            style={this.props.lowercase ? { textTransform: "none" } : null}
          >
            {subheader}
          </Typography>
        ) : null}
      </div>
    ) : (
      <Typography
        align="left"
        variant="subtitle1"
        className={classes.toolbarHeading}
        style={this.props.lowercase ? { textTransform: "none" } : null}
      >
        {children}
      </Typography>
    );
  }
}

DialogToolbarHeading.defaultProps = {
  lowercase: false
};

DialogToolbarHeading.propTypes = {
  lowercase: PropTypes.bool
};

export default withStyles(style)(DialogToolbarHeading);
