import React from 'react';
import Datetime from "react-datetime";

// Material-UI Core Components
import Popover from "@material-ui/core/Popover";

function SchedulerDatetimePicker(props) {
	const { dateTimeAnchorEl, onClose, startDate, changeStartDate } = props;

	return (
		<Popover
      id={"manual-navi-controls"}
      open={Boolean(dateTimeAnchorEl)}
      onClose={onClose}
      anchorEl={dateTimeAnchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Datetime
        locale="hr" utc={false} timeFormat={false} input={false} open={true}
        value={startDate}
        onChange={(...params) => {onClose(); changeStartDate(...params);}}
      />
    </Popover>
	)
}

export default SchedulerDatetimePicker