import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

//Material-UI Icons
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

//Custom components
import { formats } from "Lib/formats";

function FormHeadingMediator(props) {
  const { t } = useTranslation();

  const { record } = props;

  const id = record.id;

  const mediatorText = record.mediator_id ? record.mediator_fullname : t("messages.mediator_not_assigned");
  const mediationAssignedText = record.mediator_id
    ? `${t("common.mediator")}, ${t("common.postavio")}${record.mediator_assigned_by_fullname}${t("common.u_vrijeme")}${moment(
        record.mediator_assigned_on
      )
        .local()
        .format(formats.timedate)}`
    : t("messages.mediator_not_assigned");

  return (
    <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", marginLeft: "10px" }}>
      <Tooltip title={t("common.id")}>
        <div style={{ display: "flex", alignItems: "center", marginRight: 16 }}>
          <FingerprintIcon style={{ marginRight: 8 }} />
          <Typography align="left" variant="subtitle2">
            {id}
          </Typography>
        </div>
      </Tooltip>
      <Tooltip title={mediationAssignedText}>
        <div
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <AccountCircleIcon style={{ marginRight: 8 }} />
          <Typography align="left" variant="subtitle2">
            {mediatorText}
          </Typography>
        </div>
      </Tooltip>
    </div>
  );
}

export default FormHeadingMediator;
