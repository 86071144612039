import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from "react-i18next";

// Material-UI Icons
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

// Material-UI Core Components
import { makeStyles } from "@material-ui/core/styles";
import {
	Divider
, Typography
} from "@material-ui/core";

// Custom Components
import { GridContainer, GridItem } from "UI/Grid";
import { helpers as H } from "Lib/helpers";

function ChatMessagePanic(props) {
	const { message, users } = props;
	const classes = useStyle();
	const { t } = useTranslation();
	const [text, setText] = useState("");

	useEffect(() => {
		if (Array.isArray(users) && message && message.created_by) {
			const sender = users.find(u => u.id === message.created_by);
			const tmp = `${sender && sender.name || "Unknown"}·
				${H.formatDate(moment(message.created_on), moment(new Date))} -\
				${message.body || ""}`
			setText(tmp)
		}
	}, [users, message])

	return (
		<div className={classes.wrapper}>
			<div className={classes.msg}>
				<GridContainer spacing={0}>
					<GridItem xs={1}>
						<ErrorOutlineIcon color="error"/>
					</GridItem>
					<GridItem xs={11}>
						<Typography color="error" variant="body1" style={{ lineBreak: "anywhere" }}>
							{ text }
						</Typography>
					</GridItem>
				</GridContainer>
			</div>
			<Divider />
		</div>
	)
}

const useStyle = makeStyles(theme => ({
	wrapper: {
		width: "100%",
		margin: "16px 0 24px 0",
	},
	msg: {
		textAlign: "center",
	}
}))

export default ChatMessagePanic;