import api from "Lib/api";

export const upitService = {
  transferUpitOwnerShip,
  publishUpit,
  quoteUpit,
  quoteDirektanUpit,
  rejectUpit,
  getQuotesForUpit,
  getQuoteForUpit,
  createUpitDraft,
  updateUpitDraft,
  createUpitPonudeDraft,
  acceptPonuda,
  deleteSkica
}

const upitiUrl = 'upiti/';
const direktniUpitiUrl = "direktni-upiti/";

function transferUpitOwnerShip(upitId, owner_id){
  const apiInstance = new api();
  const path = upitiUrl + upitId + "/transfer-ownership";
  return apiInstance.Call(path, "patch", {owner_id: owner_id});
}

function publishUpit(upitId, record){
  const apiInstance = new api();
  const path = upitiUrl + upitId + "/publish";
  return apiInstance.Call(path, "put", record);
}

function quoteUpit(upitId, quote){
  const apiInstance = new api();
  const path = upitiUrl + upitId + "/ponude";
  return apiInstance.Call(path, "post", quote);
}

function quoteDirektanUpit(direktanUpitId, quote) {
  const apiInstance = new api();
  const path = direktniUpitiUrl + direktanUpitId + "/ponude";
  return apiInstance.Call(path, "post", quote);
}

function rejectUpit(upitId){
  const apiInstance = new api();
  const path = direktniUpitiUrl + upitId + "/decline";
  return apiInstance.Call(path, "patch");
}

function getQuotesForUpit(upitId){
  const apiInstance = new api();
  const path = upitiUrl + upitId + "/ponude";
  return apiInstance.Call(path, "get");
}

function getQuoteForUpit(upitId, quoteId){
  const apiInstance = new api();
  const path = upitiUrl + upitId + "/ponude/" + quoteId;
  return apiInstance.Call(path, "get");
}

function createUpitDraft(upit){
  const apiInstance = new api();
  const path = upitiUrl + "skice";
  return apiInstance.Call(path, "post", upit);
}

function updateUpitDraft(upitId, upit){
  const apiInstance = new api();
  const path = upitiUrl + "skice/" + upitId;
  return apiInstance.Call(path, "put", upit);
}

function createUpitPonudeDraft(upitId, ponuda){
  const apiInstance = new api();
  const path = upitiUrl + upitId + "/ponude/skice";
  return apiInstance.Call(path, "post", ponuda);
}

function acceptPonuda(upitId, ponudaId){
  const apiInstance = new api();
  const path = upitiUrl + upitId + "/ponuda/" + ponudaId;
  return apiInstance.Call(path, "post");
}

function deleteSkica(upitId){
  const apiInstance = new api();
  const path = upitiUrl + "skice/" + upitId;
  return apiInstance.Call(path, "delete");
}
