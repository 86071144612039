import { coreFields } from "./helpers/coreFields";

export default {
  title: "Search",
  exportFileName: "Search",
  apiPath: "search/",
  forms: {
    default: {
      fields: ["search", "vozilo_id", "vozac_id"]
    },
    scheduler: {
      fields: ["search", "vozilo_id", "vozac_id"]
    }
  },
  listViews: {
    default: {
      fields: ["id", "link_id", "id2", "opis"]
    }
  },

  fields: [
    coreFields.id,
    {
      title: "Pretraga",
      source: "search",
      ttoken: "common.search",
      type: "text",
      showHelper: false
    },
    {
      title: "Vozilo",
      source: "vozilo_id",
      ttoken: "common.vozilo",
      type: "picker",
      multi: true,
      subModel: {
        apiPath: "prijevoznici/{prijevoznik_id}/vozila?need_for=picker",
        format: "{label}, {registracija}"
      },
      showHelper: false
    },
    {
      title: "Vozac",
      source: "vozac_id",
      ttoken: "common.vozac",
      type: "picker",
      multi: true,
      subModel: {
        apiPath: "prijevoznici/{prijevoznik_id}/vozaci?need_for=picker",
        format: "{label}"
      },
      showHelper: false
    }
  ]
};
