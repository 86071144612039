const view = {
  title: null,
  source: "view",
  tooltip: "buttons.view",
  ttoken: null,
  type: "button",
  icon: "open_in_new",
  action: "view",
  padding: "none",
  sort: false
};

const edit = {
  title: null,
  source: "edit",
  tooltip: "buttons.edit",
  ttoken: null,
  type: "button",
  icon: "edit",
  action: "edit",
  padding: "none",
  sort: false
};

const remove = {
  title: null,
  source: "remove",
  tooltip: "buttons.remove",
  ttoken: null,
  type: "button",
  icon: "delete_sweep",
  action: "remove",
  padding: "none",
  sort: false
}

const view_pp = {
  title: null,
  source: "view_pp",
  ttoken: "buttons.pp",
  tooltip: "buttons.view_posebnaponuda",
  type: "button",
  icon: "stars",
  action: "view_pp",
  padding: "none",
  sort: false
}

const view_ag = {
  title: null,
  source: "view_ag",
  ttoken: "buttons.ag",
  tooltip: "buttons.agentura_open_job",
  type: "button",
  icon: "security",
  action: "view_ag",
  padding: "none",
  sort: false
}

const print = {
  title: null,
  source: "print",
  tooltip: "buttons.print",
  ttoken: null,
  type: "button",
  icon: "print",
  action: "print",
  padding: "none",
  sort: false
}

const send_upit = {
  title: null,
  source: "send_upit",
  tooltip: "buttons.upit_create",
  ttoken: null,
  type: "button",
  icon: "control_camera",
  action: "direktni_upit",
  padding: "none",
  sort: false
}

export const buttonFields = {
  view: view,
  edit: edit,
  remove: remove,
  view_pp: view_pp,
  view_ag: view_ag,
  print: print,
  send_upit: send_upit
};
