import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

//Custom components
import GridContainer from "UI/Grid/GridContainer";
import FormContent from "Components/FormContent";
import GridItem from "UI/Grid/GridItem";
import ModelTable from "UI/Table/ModelTable";
import DialogContext from "UI/DialogContext/DialogContext";

import PojedinacnaVoznjaDialog from "../VoznjaFormTabDialogs/PojedinacnaVoznjaDialog";
import { posloviService } from "Services/posloviService";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import UserContext from "Components/UserContext/UserContext";
import dataController from "Lib/dataController";
import model from "Models/pojedinacneVoznje";

function TabPojedinacneVoznje(props) {
  const { t } = useTranslation();
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);
  const userContext = useContext(UserContext);
  const dialogContext = useContext(DialogContext);

  const [records, setRecords] = useState([]);

  const { recordId, record, validation, fields, onFieldChange, mode, subModels, currentTab, tabValue, isAdmin, isPanic, allowAction = true } = props;

  const dc = new dataController(model);
  const fUkNetoKm = "km_calc_voznje";
  const fUkNetoMin = "min_calc_voznje";
  const fNetoKm = "neto_km";
  const fNetoMin = "neto_min";

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    posloviService
      .getPojedinacneVoznje(recordId)
      .then((resp) => {
        if (resp && resp.success) {
          setRecords(resp.data ? resp.data : []);
          const total_km = resp.data
            ? resp.data
                .filter((x) => x[fNetoKm])
                .map((x) => x[fNetoKm])
                .reduce((a, b) => a + b, 0)
            : null;
          const total_min = resp.data
            ? resp.data
                .filter((x) => x[fNetoMin])
                .map((x) => x[fNetoMin])
                .reduce((a, b) => a + b, 0)
            : null;
          onFieldChange(total_km, fUkNetoKm);
          onFieldChange(total_min, fUkNetoMin);
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  useEffect(() => {
    if (currentTab === tabValue) {
      refreshRecords();
    }
  }, [currentTab]);

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels,
    subModelDefaultValues: {
      prijevoznik_id: userContext.subjectId
    }
  };

  const handleAdd = () => {
    dialogContext.showDialog(PojedinacnaVoznjaDialog, {
      dc: dc,
      mode: "insert",
      form: "default",
      posaoId: recordId,
      onClose: handleDialogClose,
      external: false,
      subModels: subModels,
      subModelDefaultValues: {
        prijevoznik_id: userContext.subjectId
      }
    });
  };

  const handleView = (id) => {
    openPojedinacnaVoznjaDialog(id);
  };

  const openPojedinacnaVoznjaDialog = (id) => {
    posloviService
      .getPojedinacneVoznjeById(recordId, id)
      .then((resp) => {
        if (resp && resp.success) {
          dialogContext.showDialog(PojedinacnaVoznjaDialog, {
            dc: dc,
            record: resp.data,
            mode: record.posao_state_id === 29 || isPanic || !allowAction ? "view" : "update",
            form: "default",
            posaoId: recordId,
            onClose: handleDialogClose,
            external: false,
            subModelDefaultValues: {
              prijevoznik_id: userContext.subjectId
            }
          });
        }
      }).catch((result) => {
      snackbarContext.showNotification(result.error, "error");
    });
  };

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords();
    }
  };

  const handleRowAction = function (action, id) {
    if (action === "edit" || action === "view") {
      openPojedinacnaVoznjaDialog(id);
    }
  };

  const allowAdd = mode === "update" && record["posao_state_id"] < 29 && !isAdmin && !isPanic && allowAction;

  return (
    <GridContainer>
      <GridItem md={12} sm={12}>
        <ModelTable
          records={records}
          dc={dc}
          viewName={record.posao_state_id === 29 || isPanic || !allowAction ? "readonly" : "default"}
          allowExport={false}
          allowFilter={true}
          allowAdd={allowAdd}
          addLabel={t("buttons.new_f")}
          allowSelection="none"
          handleAdd={handleAdd}
          subModelDefaultValues={{
            prijevoznik_id: userContext.subjectId
          }}
          onRowAction={handleRowAction}
          onDoubleClick={(id) => handleView(id)}
        >
          {/* <TableButtonView onClick={handleView} /> */}
        </ModelTable>
      </GridItem>
      <FormContent fieldNames={["km_calc_voznje", "min_calc_voznje"]} {...commonProps} columns={2} />
      <FormContent fieldNames={["km_manual", "min_manual"]} {...commonProps} columns={2} />
    </GridContainer>
  );
}

TabPojedinacneVoznje.propTypes = {
  recordId: PropTypes.number,
  record: PropTypes.object,
  validation: PropTypes.object,
  onFieldChange: PropTypes.func,
  fields: PropTypes.array,
  mode: PropTypes.oneOf(["view", "update", "insert"])
};

export default TabPojedinacneVoznje;
