export const sifrarnici = {
	rezervacija_picker: {
		empty_picker: 0,
		voznja_id: 2,
		vozilo_id: 3,
		vozac_id: 4,
		none: 5
	},
	channel_types: {
		main: 10,
		standalone: 98,
		other: 99
	},
	channel_states: {
		active: 10,
		paused: 20,
		inactive: 30,
		blocked: 98,
		deleted: 99,
	},
	msg_types: {
		notice: 10,
		standard: 20,
		confirmation: 30,
		mediation: 40,
		file: 50,
		panic: 99,
	},
	msg_delivery_types: {
		queued: 10,
		sent: 20,
		delivered: 30,
		seen: 40,
		accepted: 50,
		declined: 60,
		deleted: 99,
	},
	zauzetost: {
		1: "zauzetost.slobodan",
		2: "zauzetost.rezerviran",
		3: "zauzetost.zauzet",
	}
}
