import { coreFields } from "./helpers/coreFields";
import { buttonFields } from "./helpers/buttonFields";

const priceFields = [
  "price_per_distance",
  "price_for_transfer",
  "distance_for_transfer",
  "duration_for_transfer",
  "price_for_half_day",
  "distance_for_half_day",
  "duration_for_half_day",
  "price_for_day",
  "distance_for_day",
  "duration_for_day",
  "cbc_provizija_day",
  "cbc_provizija_half_day",
  "cbc_provizija_transfer"
];

export default {
  title: "Posebne ponude",
  exportFileName: "Posebne_ponudu",
  apiPath: "posebne-ponude/",
  forms: {
    default: {
      fields: ["vozilo_id",
        "from_ts", "to_ts",
        "from_loc", "from_loc_radius",
        "to_loc", "to_loc_radius",
        "trajanje_voznje", "neto_udaljenost_voznje", "udaljenost_povratka_vozila",
        ...priceFields,
        "pdv_stopa", "interna_napomena", "opis", "rok_potvrda",
        "akcijska_cijena_uk", "cijena_s_pdv_iznos", "cijena_s_fincalc_iznos",
        "pdv_stopa_manual", "cbc_provizija_perc", "cbc_provizija_iznos",
        "eksterni_trosak_helper", "uk_bruto_hrk", "uk_bruto_hrk_manual",
        "iznos_fincalc", "iznos_fincalc_manual", "pdv_iznos", "fin_calc_direct", "panic"],
      validator: "pdvStopaManualRequired"
    },
    agencijeTransfer: {
      fields: [
        "vozilo_id", "vozilo_naziv",
        "from_ts", "from_loc", "from_loc_radius",
        "to_ts", "to_loc", "to_loc_radius",
        "trajanje_voznje", "neto_udaljenost_voznje", "udaljenost_povratka_vozila",
        "price_per_distance",
        "pdv_stopa", "opis", "rok_potvrda",
        "akcijska_cijena_uk", "cijena_s_pdv_iznos", "cijena_s_fincalc_iznos",
        "pdv_stopa_manual", "cbc_provizija_perc", "cbc_provizija_iznos",
        "eksterni_trosak_helper", "uk_bruto_hrk", "uk_bruto_hrk_manual",
        "iznos_fincalc", "iznos_fincalc_manual", "pdv_iznos"]
    },
    prijevoznikTransfer: {
      fields: ["vozilo_id",
        "from_ts", "from_loc", "from_loc_radius",
        "to_ts", "to_loc", "to_loc_radius",
        "trajanje_voznje", "neto_udaljenost_voznje", "udaljenost_povratka_vozila",
        "price_per_distance",
        "pdv_stopa", "interna_napomena", "opis", "rok_potvrda",
        "akcijska_cijena_uk", "cijena_s_pdv_iznos", "cijena_s_fincalc_iznos",
        "pdv_stopa_manual", "cbc_provizija_perc", "cbc_provizija_iznos",
        "eksterni_trosak_helper", "uk_bruto_hrk", "uk_bruto_hrk_manual",
        "iznos_fincalc", "iznos_fincalc_manual", "pdv_iznos", "panic"]
    },
    agencijeKruzni: {
      fields: [
        "vozilo_id", "vozilo_naziv",
        "from_ts", "to_ts",
        "from_loc", "from_loc_radius",
        "price_per_distance",
        ...priceFields,
        "opis", "rok_potvrda",
        "cbc_provizija_perc",
        "eksterni_trosak_helper", "uk_bruto_hrk", "uk_bruto_hrk_manual",
        "iznos_fincalc", "iznos_fincalc_manual"]
    },
    prijevoznikKruzni: {
      fields: [
        "vozilo_id",
        "from_ts", "to_ts",
        "from_loc", "from_loc_radius",
        "price_per_distance",
        ...priceFields,
        "interna_napomena", "opis", "rok_potvrda",
        "cbc_provizija_perc",
        "eksterni_trosak_helper", "uk_bruto_hrk", "uk_bruto_hrk_manual",
        "iznos_fincalc", "iznos_fincalc_manual", "panic"]
    }
  },
  listViews: {
    default: {
      fields: []
    },
    prijevoznik: {
      fields: ["id", "created_on", "tip", "posebna_ponuda_state_id", "vozilo_id", "from_ts", "to_ts", "from_loc_name", "to_loc_name", "price_per_distance", "rok_potvrda", "active", "view", "panic"],
      hidden: ["id", "active", "panic"]
    },
    agencija: {
      fields: [
        "id",
        "created_on",
        "narucitelj_naziv",
        "vozilo_naziv",
        "from_ts", "to_ts", "from_loc_name", "to_loc_name",
        { source: "uk_bruto_hrk",
          ttoken: "common.price"
        },
         "rok_potvrda", "active", "view", "panic"],
      hidden: ["id", "active", "panic"]
    }
  },
  fields: [
    coreFields.id,
    {
      title: "Vozilo ID",
      source: "vozilo_id",
      ttoken: "common.vozilo",
      type: "picker",
      subModel: {
        apiPath: "prijevoznici/{prijevoznik_id}/vozila?need_for=picker",
        format: "{label}, {registracija}"
      },
      validation: {
        required: true
      },
      multi: false
    },
    {
      title: "Vozilo Naziv",
      source: "vozilo_naziv",
      ttoken: "common.vozilo",
      type: "link",
      link: {
        recordIdField: "vozilo_id",
        recordType: "vozilo"
      }
    },
    {
      title: "Posebna Ponuda state",
      source: "posebna_ponuda_state_id",
      ttoken: "ponuda.ponuda_state_id",
      type: "iconStatus",
      items: {
        labels: [
          "posebna_ponuda.statuses.10",
          "posebna_ponuda.statuses.11",
          "posebna_ponuda.statuses.12",
          "posebna_ponuda.statuses.13"
        ],
        values: [10, 11, 12, 13],
        icons: ["hourglass_empty", "help", "send", "thumb_up"]
      },
      customSort: "elementProperty",
      translate: true
    },
    {
      title: "From Timestamp",
      source: "from_ts",
      ttoken: "posebna_ponuda.from_ts",
      type: "datetime",
      validation: {
        required: true
      }
    },
    {
      title: "From Location",
      source: "from_loc",
      ttoken: "posebna_ponuda.from_loc",
      type: "GooglePlaces",
      validation: {
        required: true
      }
    },
    {
      title: "From Location Name",
      source: "from_loc_name",
      ttoken: "posebna_ponuda.from_loc",
      type: "text"
    },
    {
      title: "From Location Radius",
      source: "from_loc_radius",
      ttoken: "posebna_ponuda.from_loc_radius",
      type: "wholenum",
      suffix: "km",
      validation: {
        required: true
      }
    },
    {
      title: "To Timestamp",
      source: "to_ts",
      ttoken: "posebna_ponuda.to_ts",
      type: "datetime",
      validation: {
        required: true,
        isAfter: "from_ts",
        isAfterToken: "posebna_ponuda.from_ts"
      }
    },
    {
      title: "To Location",
      source: "to_loc",
      ttoken: "posebna_ponuda.to_loc",
      type: "GooglePlaces",
      validation: {
        required: true
      }
    },
    {
      title: "To Location Name",
      source: "to_loc_name",
      ttoken: "posebna_ponuda.to_loc",
      type: "text"
    },
    {
      title: "To Location Radius",
      source: "to_loc_radius",
      ttoken: "posebna_ponuda.to_loc_radius",
      type: "wholenum",
      suffix: "km",
      validation: {
        required: true
      }
    },
    {
      title: "Distance for Transfer",
      source: "distance_for_transfer",
      ttoken: "cjenici.distance",
      type: "wholenum",
      suffix: "km",
      validation: {
        maxValue: 2147483647
      }
    },
    {
      title: "Distance for Half Day",
      source: "distance_for_half_day",
      ttoken: "cjenici.distance",
      type: "wholenum",
      suffix: "km",
      validation: {
        maxValue: 2147483647
      }
    },
    {
      title: "Distance for Day",
      source: "distance_for_day",
      ttoken: "cjenici.distance",
      type: "wholenum",
      suffix: "km",
      validation: {
        required: true,
        maxValue: 2147483647
      }
    },
    {
      title: "Price for Transfer",
      source: "price_for_transfer",
      ttoken: "cjenici.price",
      type: "currency",
      currency: "€",
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Price for Half Day",
      source: "price_for_half_day",
      ttoken: "cjenici.price",
      type: "currency",
      currency: "€",
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Price for Day",
      source: "price_for_day",
      ttoken: "cjenici.price",
      type: "currency",
      currency: "€",
      readonly: true,
      calculated: true,
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Price per Distance",
      source: "price_per_distance",
      ttoken: "posebna_ponuda.akcija_cijena_km",
      type: "currency",
      currency: "€",
      validation: {
        required: true,
        maxValue: 99999999
      }
    },
    {
      title: "Duration for Transfer",
      source: "duration_for_transfer",
      ttoken: "cjenici.duration",
      type: "wholenum",
      suffix: "h",
      validation: {
        maxValue: 2147483647
      }
    },
    {
      title: "Duration for Half Day",
      source: "duration_for_half_day",
      ttoken: "cjenici.duration",
      type: "wholenum",
      suffix: "h",
      validation: {
        maxValue: 2147483647
      }
    },
    {
      title: "Duration for Day",
      source: "duration_for_day",
      ttoken: "cjenici.duration",
      type: "wholenum",
      suffix: "h",
      validation: {
        required: true,
        maxValue: 2147483647
      }
    },
    {
      title: "CBC provizija transfer",
      source: "cbc_provizija_transfer",
      ttoken: "cjenici.cbc_provizija",
      type: "currency",
      currency: "€",
      readonly: true,
      calculated: true,
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "CBC provizija half day",
      source: "cbc_provizija_half_day",
      ttoken: "cjenici.cbc_provizija",
      type: "currency",
      currency: "€",
      readonly: true,
      calculated: true,
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "CBC provizija day",
      source: "cbc_provizija_day",
      ttoken: "cjenici.cbc_provizija",
      type: "currency",
      currency: "€",
      readonly: true,
      calculated: true,
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Akcijska cijena ukupna",
      source: "akcijska_cijena_uk",
      ttoken: "",
      type: "currency",
      currency: "€",
      validation: {
        maxValue: 99999999
      },
      readonly: true,
      calculated: true
    },
    {
      title: "Cijena s PDV Iznos",
      source: "cijena_s_pdv_iznos",
      ttoken: "posebna_ponuda.cijena_s_pdv_iznos",
      type: "currency",
      currency: "€",
      validation: {
        maxValue: 99999999
      },
      readonly: true,
      calculated: true
    },
    {
      title: "Cijena s Fin Calc Iznos",
      source: "cijena_s_fincalc_iznos",
      ttoken: "posebna_ponuda.cijena_s_fin_calc_iznos",
      type: "currency",
      currency: "€",
      validation: {
        maxValue: 99999999
      },
      readonly: true,
      calculated: true
    },
    {
      title: "PDV Stopa",
      source: "pdv_stopa",
      ttoken: "posebna_ponuda.pdv_stopa",
      type: "picker",
      items: {
        labels: ["25.0%", "0%"],
        values: [25, 0],
        default: 0
      }
    },
    {
      title: "PDV Iznos",
      source: "pdv_iznos",
      ttoken: "posebna_ponuda.pdv_iznos",
      type: "currency",
      currency: "€",
      calculated: true,
      readonly: true,
      validation: {
        required: true,
        maxValue: 99999999
      }
    },
    {
      title: "PDV Stopa Manual",
      source: "pdv_stopa_manual",
      ttoken: "posebna_ponuda.pdv_stopa_manual",
      type: "currency",
      currency: "€",
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Postotak CBC provizije",
      source: "cbc_provizija_perc",
      ttoken: "ponuda.cbc_provizija_perc",
      type: "numeric",
      readonly: true,
      validation: {
        required: true
      }
    },
    {
      title: "Iznos CBC provizije",
      source: "cbc_provizija_iznos",
      ttoken: "ponuda.cbc_provizija_iznos",
      type: "currency",
      currency: "€",
      calculated: true,
      readonly: true,
      validation: {
        required: true,
        maxValue: 99999999
      }
    },
    {
      title: "",
      source: "eksterni_trosak_helper",
      ttoken: "posebna_ponuda.externi_troskovi",
      type: "picker",
      multi: true,
      subModel: {
        apiPath: "eksterni-troskovi/?need_for=picker",
        format: "'{label}' {iznos_bruto} €"
      }
    },
    {
      title: "Cijena izračunata fincalc",
      source: "iznos_fincalc",
      ttoken: "posebna_ponuda.vanjski_troskovi",
      tooltip: "ponuda.helpers.iznos_fincalc",
      type: "currency",
      currency: "€",
      readonly: true,
      calculated: true,
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Cijena izračunata fincalc manual",
      source: "iznos_fincalc_manual",
      ttoken: "posebna_ponuda.iznos_fincalc_override",
      tooltip: "ponuda.helpers.iznos_fincalc_override",
      type: "currency",
      currency: "€",
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Financial calculations",
      source: "fin_calc_direct",
      ttoken: "",
      type: "picker"
    },
    {
      title: "Ukupno Bruto",
      source: "uk_bruto_hrk",
      tooltip: "ponuda.helpers.uk_bruto",
      ttoken: "posebna_ponuda.uk_bruto",
      type: "currency",
      currency: "€",
      calculated: true,
      readonly: true,
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Ukupno Bruto Manual",
      source: "uk_bruto_hrk_manual",
      tooltip: "ponuda.helpers.uk_bruto_manual",
      ttoken: "posebna_ponuda.uk_bruto_manual",
      type: "currency",
      currency: "€",
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Interna Napomena",
      source: "interna_napomena",
      ttoken: "posebna_ponuda.interna_napomena",
      type: "text"
    },
    {
      title: "Napomena",
      source: "opis",
      ttoken: "posebna_ponuda.opis",
      type: "multiline",
      validation: {
        maxLength: 250
      },
      rows: "5",
      width: "full",
      tooltip: "tooltips.posebna_ponuda.ponuda_info"
    },
    {
      title: "Rok Potvrda",
      source: "rok_potvrda",
      ttoken: "common.valid_until",
      type: "datetime",
      validation: {
        required: true
      }
    },
    {
      title: "Rok Potvrda",
      source: "round_trip",
      ttoken: "posebna_ponuda.round_trip",
      type: "boolean",
      items: {
        labels: ["common.ne", "common.da"],
        values: [false, true],
        default: false
      }
    },
    {
      title: "Tip posebne ponude",
      source: "tip",
      ttoken: "posebna_ponuda.tip",
      type: "picker",
      items: {
        labels: ["posebna_ponuda.tipovi.10", "posebna_ponuda.tipovi.20"],
        values: [10, 20]
      },
      translate: true
    },
    {
      title: "Trajanje Voznje",
      source: "trajanje_voznje",
      ttoken: "trazilica.trajanje_voznje",
      type: "picker",
      readonly: true,
      calculated: true,
      formatting: "dhm",
      validation: {
        required: true
      }
    },
    {
      title: "Neto Udaljenost Voznje",
      source: "neto_udaljenost_voznje",
      ttoken: "trazilica.neto_udaljenost_voznje",
      type: "picker",
      readonly: true,
      calculated: true,
      formatting: "km",
      validation: {
        required: true
      }
    },
    {
      title: "Udaljenost Povratka Vozila",
      source: "udaljenost_povratka_vozila",
      ttoken: "trazilica.udaljenost_povratka_vozila",
      type: "picker",
      readonly: true,
      calculated: true,
      formatting: "km",
      validation: {
        required: true
      }
    },
    {
      title: "Narucitelj naziv",
      source: "narucitelj_naziv",
      ttoken: "common.prijevoznik",
      type: "link",
      link : {
        recordIdField: "bs_owner_id",
        recordType: "prijevoznik"
      }
    },
    {
      title: "Created On / Published On",
      source: "created_on",
      ttoken: "common.published_on",
      type: "datetime",
      readonly: true,
      width: 20
    },
    {
      title: "Panic",
      source: "panic",
      ttoken: "common.panic",
      type: "panic",
      items: {
        labels: ["common.ne", "common.da"],
        values: [false, true]
      },
      readonly: true,
      translate: true
    },
    coreFields.active,
    buttonFields.view
  ]

};
