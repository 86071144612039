export const Role = {
	Prijevoznik: { id: 'prijevoznik', code : 100, display : "role.prijevoznik", shortDisplay : "PVN" },
	Vozac: { id: 'prijevoznikKorisnik', code : 110, display : "role.prijevoznikKorisnik", shortDisplay : "VOZ" },
	AgencijskiUpravitelj: { id: 'agencijskiKorisnikManager', code : 200, display : "role.agencijskiKorisnikManager", shortDisplay : "AKU" },
	AgencijskiKorisnik: { id: 'agencijskiKorisnik', code : 210, display : "role.agencijskiKorisnik", shortDisplay : "AKK" },
	Vodic: { id: 'vodic', code : 220, display : "role.vodic", shortDisplay : "VDC" },
	CBCAdministrator: { id: 'cbcAdministrator', code : 900, display : "role.cbcAdministrator", shortDisplay : "CBA" },
	CBCMedijator: { id: 'cbcMedijator', code : 910, display : "role.cbcMedijator", shortDisplay : "CBM" },
	Custom: { id: 'custom', code : 990, display : "role.custom", shortDisplay : "CUS" },

	DEFAULT : { id : 'default', code : -1, display:"DEFAULT_VALUE"},
	getRoleByCode: (code) => {
		switch(code){
			case 100:
				return Role.Prijevoznik;
			case 110:
				return Role.Vozac;
			case 200:
				return Role.AgencijskiUpravitelj;
			case 210:
				return Role.AgencijskiKorisnik;
			case 220:
				return Role.Vodic;
			case 900:
				return Role.CBCAdministrator;
			case 910:
				return Role.CBCMedijator;
			case 990:
				return Role.Custom;
			default:
				return Role.default;
		}
	},
	// cant use lambda because it binds this to the caller
	// refer to this: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/Arrow_functions
	isAdmin: function(code) { return code === this.CBCMedijator.code || code === this.CBCAdministrator.code;},
	isPrijevoznik: function(code) { return code === this.Prijevoznik.code || code === this.Vozac.code;},
	isAgencija: function(code) { return code === this.AgencijskiKorisnik.code || code === this.AgencijskiUpravitelj.code || code === this.Vodic.code;},
};
