import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Icons
import DraftsIcon from "@material-ui/icons/Drafts";

//Custom components
import DialogToolbarMenuItem from "UI/Dialog/DialogToolbarMenuItem";

const DialogToolbarMenuItemDraft = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return <DialogToolbarMenuItem label={t("buttons.save_draft")} icon={<DraftsIcon />} {...props} ref={ref} />;
});

export default DialogToolbarMenuItemDraft;
