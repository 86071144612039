import createReport from "docx-templates";
import moment from "moment";
import { formats } from "Lib/formats";

import api from "Lib/api";
import { loadWordTemplate, saveDataToFile } from "Lib/downloadTemplate";

const handlePrint = (record, t) => {
  const apiInstance = new api();
  const posaoId = record.posao_id;
  const recordId = record.id;
  const path = `poslovi/${posaoId}/putni-nalozi/${recordId}`;
  apiInstance.Call(path, "get").then((resp) => {
    const data = resp.data;
    const wordTemplatePath = getWordTemplatePath();
    const fillFunction = getFillFunction(t);

    loadWordTemplate(wordTemplatePath, fillFunction(data));
  });
};

const getWordTemplatePath = () => {
  return "/templates/putni_nalog_template.docx";
};

const getFillFunction = (t) => {
  return (record) =>
    function (error, content) {
      if (error) {
        console.warn(error);
        throw error;
      }

      fillReport(t, content, record);
    }.bind(this);
};

const fillReport = (t, content, record) => {
  const report = createReport({
    template: content,
    data: {
      pr_naz: record.pr_naziv
      , pr_adr_full: record.pr_adr_full
      , pr_adr_grad: record.pr_adr_grad
      , pr_oib: record.pr_oib
      , created_on: record.created_on ? moment(record.created_on).local().format(formats.date) : ""
      , vozac_ime_prezime: record.vozac_ime_prezime
      , from_datum: record.ts_from ? moment(record.ts_from).local().format(formats.date) : ""
      , to_datum: record.ts_to ? moment(record.ts_to).local().format(formats.date) : ""
      , vozilo_marka_tip: record.vozilo_marka_tip
      , vozilo_reg_oznaka: record.vozilo_reg_oznaka
      , km_from: record.km_from
      , km_to: record.km_to
      , opis: record.opis
      , oznaka: record.oznaka
    }
  });
  report.then((data) => {
    let filename = `putni_nalog_${record.id}.docx`;

    saveDataToFile(data, filename, "application/vnd.openxmlformats-officedocument.wordprocessingml.document");
  });
};

export const putniNalogPrint = {
  handlePrint
};
