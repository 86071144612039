import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

//Material UI Core Components
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import Typography from "@material-ui/core/Typography";

//Material-UI Icons
import ViewColumnIcon from "@material-ui/icons/ViewColumn";

//Custom Components
import TableHeaderButton from "UI/Table/TableHeaderButton";

function TableHeaderButtonColumnPicker(props) {
  const { t } = useTranslation();
  const { columns, hiddenColumnNames, onChange, ...otherProps } = props;

  const [menuState, setMenuState] = useState({ open: false, anchorEl: null });

  const handleOpen = (evt) => {
    setMenuState({ open: true, anchorEl: evt.currentTarget });
  };

  const handleToggle = (evt, source) => {
    const isVisible = !hiddenColumnNames.includes(source);
    const newState = !isVisible;

    if (onChange) {
      onChange(source, newState);
    }
  };

  const handleClose = (evt, reason) => {
    setMenuState({ open: false, anchorEl: null });
  };

  return (
    <>
      <TableHeaderButton onClick={handleOpen} startIcon={<ViewColumnIcon />} variant={"outlined"} {...otherProps}>
        {t("buttons.toggle_columns")}
      </TableHeaderButton>
      <Menu
        open={menuState.open}
        onClose={handleClose}
        anchorEl={menuState.anchorEl}
        transformOrigin={{
          vertical: "center",
          horizontal: "right"
        }}
      >
        {columns.map((c, i) => {
          return c.accessor && c.Header ? (
            <MenuItem value={i} onClick={(evt) => handleToggle(evt, c.accessor)} key={i}>
              <Switch color="secondary" checked={!hiddenColumnNames.includes(c.accessor)} value={i} />
              <Typography variant="caption" style={{textTransform: "uppercase", fontWeight: 600}}>{c.Header}</Typography>
            </MenuItem>
          ) : null;
        })}
      </Menu>
    </>
  );
}

TableHeaderButtonColumnPicker.propTypes = {
  columns: PropTypes.array,
  hiddenColumnNames: PropTypes.array,
  onChange: PropTypes.func
};

export default TableHeaderButtonColumnPicker;
