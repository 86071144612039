import React from "react";

import HomePage from "Views/HomePage/HomePage";
import ProfilePage from "Views/ProfilePage/ProfilePage";
import AdminHomePage from "Views/Administration/AdminHomePage";
import NotificationsPage from "Views/Notifications/NotificationsPage";

// Prijevnoznici
import BurzaUpitPage from "Views/Prijevoznici/Burza/BurzaUpitPage";
import BurzaPage from "Views/Prijevoznici/Burza/BurzaPage";
import PrijevozniciHomePage from "Views/Prijevoznici/PrijevozniciHomePage";
import PrijevozniciKorisniciPage from "Views/Prijevoznici/Korisnici/PrijevozniciKorisniciPage";
import PrijevozniciKalendarPage from "Views/Prijevoznici/PrijevozniciKalendarPage";
import PrijevozniciKomitentiPage from "Views/Prijevoznici/PrijevozniciKomitentiPage";
import PrijevozniciPredlosciCjenikaPage from "Views/Prijevoznici/PrijevozniciPredlosciCjenikaPage";
import RezervacijePage from "Views/Prijevoznici/Rezervacije/RezervacijePage";
import RezervacijaPage from "Views/Prijevoznici/Rezervacije/RezervacijaPage";
import PrijevozniciVozaciPage from "Views/Prijevoznici/PrijevozniciVozaciPage";
import PrijevozniciVozilaPage from "Views/Prijevoznici/PrijevozniciVozilaPage";
import PrijevozniciVoznjePage from "Views/Prijevoznici/PrijevozniciVoznjePage";
import PrijevozniciPosebnePonudePage from "Views/Prijevoznici/PosebnePonude/PrijevozniciPosebnePonudePage";
import PrijevozniciPosebnaPonudaPage from "Views/Prijevoznici/PosebnePonude/PrijevozniciPosebnaPonudaPage";
import DirektniUpitiPage from "Views/Prijevoznici/DirektniUpiti/DirektniUpitiPage";
import DirektanUpitPage from "Views/Prijevoznici/DirektniUpiti/DirektanUpitPage";
import VoznjaView from "Views/Prijevoznici/Voznje/VoznjaView";
import VoziloView from "Views/Prijevoznici/Vozila/VoziloView";
import KomitentiView from "Views/Prijevoznici/Komitenti/KomitentiView";
import PonudePage from "Views/Prijevoznici/Ponude/PonudePage";
import PonudaPage from "Views/Prijevoznici/Ponude/PonudaPage";
import PrijevozniciNotificationsPage from "Views/Prijevoznici/PrijevozniciNotificationsPage";
import PrijevozniciProfilePage from "Views/ProfilePage/PrijevozniciProfilePage";

// Agencije
import AgencijeHomePage from "Views/Agencije/AgencijeHomePage";
import UpitiPage from "Views/Agencije/Burza/UpitiPage";
import UpitPage from "Views/Agencije/Burza/UpitPage";
import TrazilicaPage from "Views/Agencije/Trazilica/TrazilicaPage";
import AgencijePosebnePonudePage from "Views/Agencije/PosebnePonude/AgencijePosebnePonudePage";
import AgencijePosebnaPonudaPage from "Views/Agencije/PosebnePonude/AgencijePosebnaPonudaPage";
import AgencijePrijevozniciPage from "Views/Agencije/Prijevoznici/AgencijePrijevozniciPage";
import AgencijeKorisniciPage from "Views/Agencije/Korisnici/AgencijeKorisniciPage";
import AgencijeNotificationsPage from "Views/Agencije/AgencijeNotificationsPage";
import AgencijeProfilePage from "Views/ProfilePage/AgencijeProfilePage";

// Administracija
import PoslovniSubjektiPage from "Views/Administration/PoslovniSubjekti/PoslovniSubjektiPage";
import UsersPage from "Views/Administration/UsersPage/UsersPage";
import UserView from "Views/Administration/UsersPage/UserView";
import AdminVozilaPage from "Views/Administration/Vozila/AdminVozilaPage";
import AdminVoziloView from "Views/Administration/Vozila/AdminVoziloView";
import PoslovniSubjektView from "Views/Administration/PoslovniSubjekti/PoslovniSubjektView";
import AdminUpitiPage from "Views/Administration/AdminUpiti/AdminUpitiPage";
import AdminUpitView from "Views/Administration/AdminUpiti/AdminUpitView";
import AdminVoznjePage from "Views/Administration/AdminVoznje/AdminVoznjePage";
import AdminVoznjaView from "Views/Administration/AdminVoznje/AdminVoznjaView";
import TroskoviPage from "Views/Administration/Troskovi/TroskoviPage";
import AdminNotificationsPage from "Views/Administration/AdminNotificationsPage";
import AdminProfilePage from "Views/ProfilePage/AdminProfilePage";
import FinancijePage from "Views/Administration/Financije/FinancijePage";
import FinancijeView from "Views/Administration/Financije/FinancijeView";

//Komunikator
import PrijevozniciKomunikatorPage from "Views/Komunikator/PrijevozniciKomunikatorPage";
import PrijevozniciKomunikatorSinglePage from "Views/Komunikator/PrijevozniciKomunikatorSinglePage";
import AgencijeKomunikatorPage from "Views/Komunikator/AgencijeKomunikatorPage";
import AgencijeKomunikatorSinglePage from "Views/Komunikator/AgencijeKomunikatorSinglePage";
import AdminKomunikatorPage from "Views/Komunikator/AdminKomunikatorPage";
import AdminKomunikatorSinglePage from "Views/Komunikator/AdminKomunikatorSinglePage";
import KomunikatorPageUnseen from "Views/Komunikator/KomunikatorPageUnseen";


const privateRoutes = [
  { path: "/profile", name: "Profile", component: ProfilePage},
  { path: "/notifications", name: "Notifications", component: NotificationsPage},
  { path: "/komunikator-unseen", name: "Komunikator", component: KomunikatorPageUnseen},

  { path: "/p/korisnici", name: "Korisnici", component: PrijevozniciKorisniciPage },
  { path: "/p/direktni-upiti/:id", name: "Direktan Upit", component: DirektanUpitPage },
  { path: "/p/direktni-upiti", name: "Direktni Upiti", component: DirektniUpitiPage },
  { path: "/p/burza/:id", name: "BurzaUpit", component: BurzaUpitPage },
  { path: "/p/burza", name: "Burza", component: BurzaPage },
  { path: "/p/komunikator/:id", name: "Komunikator", component: PrijevozniciKomunikatorSinglePage },
  { path: "/p/komunikator", name: "Komunikator", component: PrijevozniciKomunikatorPage },
  { path: "/p/komunikator-unseen", name: "Komunikator", component: () => <PrijevozniciKomunikatorSinglePage isOnUnseen={true}/> },
  { path: "/p/notifications", name: "Notifikator", component: PrijevozniciNotificationsPage },
  { path: "/p/kalendar", name: "Prijevoznici Kalendar", component: PrijevozniciKalendarPage },
  { path: "/p/ponude/:id", name: "Ponuda", component: PonudaPage},
  { path: "/p/ponude", name: "Ponude", component: PonudePage },
  { path: "/p/voznje/:id", name: "Voznja View", component: VoznjaView },
  { path: "/p/voznje", name: "Prijevoznici Kalendar", component: PrijevozniciVoznjePage },
  { path: "/p/posebne-ponude/:id", name: "Posebne Ponude View", component: PrijevozniciPosebnaPonudaPage },
  { path: "/p/posebne-ponude", name: "Posebne Ponude", component: PrijevozniciPosebnePonudePage },
  { path: "/p/komitenti/:id", name: "Prijevoznici Komitenti Single", component: KomitentiView },
  { path: "/p/komitenti", name: "Prijevoznici Komitenti", component: PrijevozniciKomitentiPage },
  { path: "/p/predlosci-cjenika", name: "Predlošci cjenika", component: PrijevozniciPredlosciCjenikaPage },
  { path: "/p/rezervacije/:id", name: "Rezervacija", component: RezervacijaPage },
  { path: "/p/rezervacije", name: "Rezervacije", component: RezervacijePage },
  { path: "/p/vozila/:id", name: "Vozilo View", component: VoziloView },
  { path: "/p/vozila", name: "Prijevoznici vozila", component: PrijevozniciVozilaPage },
  { path: "/p/vozaci", name: "Prijevoznici Kalendar", component: PrijevozniciVozaciPage },
  { path: "/p/profile", name: "Profile", component: PrijevozniciProfilePage},
  { path: "/p", name: "Prijevoznici Home Page", component: PrijevozniciHomePage },

  { path: "/admin/troskovi", name:"Troškovi", component: TroskoviPage},
  { path: "/admin/poslovni-subjekti/:id", name: "poslovni subjekt", component: PoslovniSubjektView },
  { path: "/admin/poslovni-subjekti", name: "Map", component: PoslovniSubjektiPage },
  { path: "/admin/vozila/:id", name: "Vozilo View", component: AdminVoziloView },
  { path: "/admin/vozila", name: "Admin vozila", component: AdminVozilaPage },
  { path: "/admin/upiti/:id", name: "Admin upit", component: AdminUpitView },
  { path: "/admin/upiti", name: "Admin upiti", component: AdminUpitiPage },
  { path: "/admin/voznje/:id", name: "Ugovoreni Voznja view", component: AdminVoznjaView },
  { path: "/admin/voznje", name: "Ugovoreni voznje", component: AdminVoznjePage },
  { path: "/admin/users/:id", name: "Map", component: UserView },
  { path: "/admin/users", name: "Map", component: UsersPage },
  { path: "/admin/komunikator/:id", name: "Komunikator", component: AdminKomunikatorSinglePage },
  { path: "/admin/komunikator", name: "Komunikator", component: AdminKomunikatorPage },
  { path: "/admin/komunikator-unseen", name: "Komunikator", component: () => <AdminKomunikatorSinglePage isOnUnseen={true}/> },
  { path: "/admin/notifications", name: "Notifikator", component: AdminNotificationsPage },
  { path: "/admin/profile", name: "Profile", component: AdminProfilePage},
  { path: "/admin/financije/:id", name: "Financijska slika", component: FinancijeView},
  { path: "/admin/financije", name: "Financije", component: FinancijePage},
  { path: "/admin", name: "Admin Home Page", component: AdminHomePage},

  { path: "/a/trazilica", name:"Tražilica", component: TrazilicaPage},
  { path: "/a/upiti/:id", name: "Upit", component: UpitPage },
  { path: "/a/upiti", name: "Upiti", component: UpitiPage },
  { path: "/a/posebne-ponude/:id", name: "Posebne Ponude View", component: AgencijePosebnaPonudaPage },
  { path: "/a/posebne-ponude", name: "Posebne Ponude", component: AgencijePosebnePonudePage },
  { path: "/a/korisnici", name: "Korisnici", component: AgencijeKorisniciPage },
  { path: "/a/prijevoznici", name: "Prijevoznici", component: AgencijePrijevozniciPage },
  { path: "/a/komunikator/:id", name: "Komunikator", component: AgencijeKomunikatorSinglePage },
  { path: "/a/komunikator", name: "Komunikator", component: AgencijeKomunikatorPage },
  { path: "/a/komunikator-unseen", name: "Komunikator", component: () => <AgencijeKomunikatorSinglePage isOnUnseen={true}/> },
  { path: "/a/notifications", name: "Notifikator", component: AgencijeNotificationsPage },
  { path: "/a/profile", name: "Profile", component: AgencijeProfilePage},
  { path: "/a", name: "Agencije Home Page", component: AgencijeHomePage },

	{ path: "/", name: "Home", component: HomePage }
];

export default privateRoutes
