import React from 'react';
import { useTranslation } from "react-i18next";

//Material-UI Icons
import AddBoxIcon from '@material-ui/icons/AddBox';

//Custom components
import DialogToolbarMenuItem from "UI/Dialog/DialogToolbarMenuItem";

const DialogToolbarMenuItemCopy = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return <DialogToolbarMenuItem label={t("buttons.copy")} icon={<AddBoxIcon />} {...props} ref={ref} />;
});

export default DialogToolbarMenuItemCopy;
