import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import moment from "moment";

//Material UI
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Toolbar from "@material-ui/core/Toolbar";
import { CardActions } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";

//Material-UI Icons
import InfoIcon from "@material-ui/icons/Info";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import AssignmentIcon from "@material-ui/icons/Assignment";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import MoneyIcon from "@material-ui/icons/Money";

//CBC Icons
import UgovorIcon from "Icons/UgovorIcon";

//Custom components
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import withFormController from "Components/withFormController";
import TabOsnovniPodaci from "../VoznjaFormTabs/TabOsnovniPodaci";
import TabPojedinacneVoznje from "../VoznjaFormTabs/TabPojedinacneVoznje";
import TabPonuda from "../VoznjaFormTabs/TabPonuda";
import GridItem from "UI/Grid/GridItem";
import GridContainer from "UI/Grid/GridContainer";
import TabFinancijskeObaveze from "../VoznjaFormTabs/TabFinancijskeObaveze";
import TabUgovor from "../VoznjaFormTabs/TabUgovor";
import CardHeading from "UI/Card/CardHeading";
import VoznjaAvatar from "UI/Form/Avatars/VoznjaAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import { 
  CardColored, 
  CardToolbarButtonAlarm, 
  CardToolbarButtonBack, 
  CardSOSHeading 
} from "UI/Card";
import TabInternaKalkulacija from "../VoznjaFormTabs/TabInternaKalkulacija";
import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItemCopy from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemCopy";
import DialogToolbarMenuItemInvalidate from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemInvalidate";
import DialogToolbarMenuItemInvalidateCopy from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemInvalidateCopy";
import PrintButton from "UI/Buttons/PrintButton";
import ConfirmButton from "UI/Buttons/ConfirmButton";
import SaveButton from "UI/Buttons/SaveButton";
import { FormTab, FormTabPanel, FormTabs } from "UI/Form";
import { posloviStatuses, retrievePosloviStatusLabel } from "Lib/statuses";
import { posloviService } from "Services/posloviService";
import confirmDialog from "Lib/confirmDialog";
import FormFillContent from "UI/Form/FormFillContent";
import EuroConversionComment from "Components/EuroConversionComment";

function VoznjaForm22(props) {
  const snackbarContext = useContext(SnackbarContext);

  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();

  const [currentTab, setCurrentTab] = useState(0);

  const { dc, record, mode, validation, fields, subModels } = props; //HOC withFormController
  const { onFieldChange, doValidate } = props; //HOC withFormController
  const { handleCopy, handleStorniraj, handleStornirajCopy, handleRefreshForm } = props;
  const { isPanic } = props;
  const { ownerId } = props;

  const handleTabChange = (evt, value) => {
    setCurrentTab(value);
  };

  const confirmNextStep = () => {
    const title = t("confirm_voznje_status.voznja22.title");
    const text = t("confirm_voznje_status.voznja22.text");
    const type = "warning";
    const confirmButtonText = t("cdialogs.confirm");
    const cancelButtonText = t("cdialogs.cancel");

    return confirmDialog(title, text, type, confirmButtonText, cancelButtonText);
  };

  const handleUgovor = () => {
    confirmNextStep().then((result) => {
      if (result.confirmed) {
        if (doValidate) {
          const validated = doValidate();
          if (validated) {
            const preparedRecord = dc.prepareSendingData(record);
            posloviService.interniPosaoStanje22_23(record.id, preparedRecord).then((resp) => {
              if (resp.success) {
                snackbarContext.showNotification("update", "success");
                if (handleRefreshForm) {
                  handleRefreshForm();
                }
              } else {
                snackbarContext.showNotification(resp.error.message, "error");
              }
            }).finally(() => {
              //history.goBack();
            });
          }
        }
      }
    });
  };

  const handleClickBack = () => {
    history.goBack();
  };

  const handleSave = () => {
    const preparedRecord = dc.prepareSendingData(record);
    posloviService.updateInterniPosao(preparedRecord.id, preparedRecord).then((resp) => {
      if (resp.success) {
        snackbarContext.showNotification("update", "success");
        // window.location.reload();
      } else {
        snackbarContext.showNotification(resp.error.message, "error");
      }
    });
  };

  const commonTabProps = {
    currentTab: currentTab,
    dc: dc,
    subModels: subModels,
    record: record,
    mode: mode,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    recordId: record.id
  };

  const title = `${t(retrievePosloviStatusLabel(record.posao_state_id))}  (ST.${record.posao_state_id})(ID:${record.id})`;
  const subtitle = `Rok Važenja Ponude: ${moment(record.st_expire).format("DD.MM.YYYY")}`;

  const identifier = dc.getSource() + record.posao_state_id;
  const isInterni = record["tip_voznje_id"] === 50;
  const isStorno = record["storno"] === true;
  const isAgentura = record.agentura === true;

  const customColor = isStorno ? theme.palette.custom.storno :
    isPanic ? theme.palette.custom.panic :
    isAgentura ? theme.palette.custom.agentura :
    undefined;

  const customText = isStorno ? t("messages.storno") :
    isPanic ? t("messages.panic") :
    isAgentura ? t("messages.agentura") :
    undefined;
    
  return (
    <CardColored color={customColor}>
      <CardHeading recordStatus={record.posao_state_id} statuses={posloviStatuses} color={customColor}>
        <Toolbar variant="dense" disableGutters={true}>
          <VoznjaAvatar />
          <DialogToolbarHeading header={title} subheader={subtitle} />
          {/*<CardSOSHeading isPanic={props.isPanic}/>*/}
          <DialogToolbarFillContent />
          {customText ?
            <Typography variant="button">{customText}</Typography>
            : null
          }
          <DialogToolbarFillContent />
          {
            !props.isPanic ?
              <Fragment>
              <CardToolbarButtonAlarm record={record} type="interna" onFieldChange={onFieldChange} />
                <DialogToolbarMenuButton>
                  {
                    isInterni && !isStorno ? (
                      <DialogToolbarMenuItemInvalidate onClick={() => handleStorniraj(record["id"])} />
                    ) : null
                  }
                  {
                    isInterni && !isStorno ? (
                      <DialogToolbarMenuItemInvalidateCopy onClick={() => handleStornirajCopy(record["id"], record)} />
                    ) : null
                  }
                  <DialogToolbarMenuItemCopy onClick={() => handleCopy(record)} />
                </DialogToolbarMenuButton>
              </Fragment>
              : null
          }
          <CardToolbarButtonBack onClick={handleClickBack} />
        </Toolbar>
      </CardHeading>
      <CardContent style={{ paddingTop: 0 }}>
        <FormTabs value={currentTab} onChange={handleTabChange} identifier={identifier}>
          <FormTab id="osnovni-podaci" label={t("poslovi.tabs.osnovni_podaci")} icon={<InfoIcon />} aria-label="osnovni_podaci" />
          <FormTab id="pojedinacne-voznje" label={t("poslovi.tabs.pojedinacne_voznje")} icon={<AirportShuttleIcon />} aria-label="pojedinacne_voznje" />
          <FormTab id="interna-kalkulacija" label={t("poslovi.tabs.interna_kalkulacija")} icon={<MoneyIcon />} aria-label="interna_kalkulacija" />
          <FormTab id="ponuda" label={t("poslovi.tabs.ponuda")} icon={<AssignmentIcon />} aria-label="ponuda" />
          <FormTab id="financijske-obveze" label={t("poslovi.tabs.financijske_obveze")} icon={<MonetizationOnIcon />} aria-label="financijske_obveze" />
          <FormTab id="ugovor" label={t("poslovi.tabs.ugovor")} icon={<UgovorIcon />} aria-label="ugovor" />
        </FormTabs>
        <FormTabPanel value={currentTab} index={0}>
          <TabOsnovniPodaci {...commonTabProps} internal={true} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={1}>
          <TabPojedinacneVoznje {...commonTabProps} tabValue={1} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={2}>
          <TabInternaKalkulacija {...commonTabProps} tabValue={2} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={3}>
          <TabPonuda {...commonTabProps} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={4}>
          <TabFinancijskeObaveze {...commonTabProps} tabValue={4} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={5}>
          <TabUgovor {...commonTabProps} />
        </FormTabPanel>
      </CardContent>
      <CardActions>
        <EuroConversionComment />
        <FormFillContent />
        <PrintButton
          dc={dc}
          target={currentTab === 3 ? "ponuda" : currentTab === 5 ? "ugovor" : null}
          record={record}
          text={currentTab === 3 ? t("buttons.ponuda") : currentTab === 5 ? t("buttons.ugovor") : t("buttons.print")}
          disabled={!(currentTab === 3 || currentTab === 5)}
        />
        {
          !isPanic ?
            <ConfirmButton
              onClick={handleUgovor}
              text={t("buttons.ugovor_potpisan")}
              disabled={mode === "view" || currentTab !== 5}
            />
            : null
        }
        {
          !isPanic ?
            <SaveButton
              onClick={handleSave}
              text={t("buttons.save")}
              disabled={mode === "view"}
            />
            : null
        }
      </CardActions>
    </CardColored>
  );
}

export default withFormController(VoznjaForm22);
