// These are some common fields that are present in multiple tables in DB
// They can be added to UI models...

const mark_new = {
  title: "New",
  source: "mark_new",
  ttoken: "common.new",
  type: "mark_new",
  items: {
    labels: ["common.ne", "common.da"],
    values: [false, true]
  },
  readonly: true,
  translate: true
}

const oib = {
  title: "OIB",
  source: "oib",
  ttoken: "common.oib",
  type: "text",
  validation: {
    required: true,
    minLength: 11,
    maxLength: 11,
    regex: "oib"
  },
  width: 20
};

const vat = {
  title: "VAT",
  source: "vat",
  ttoken: "common.vat",
  type: "text",
  validation: {
    required: true,
    maxLength: 50
  },
  width: 20
};

const email = {
  title: "E-mail",
  source: "email",
  ttoken: "common.email",
  type: "mail",
  validation: {
    required: true,
    maxLength: 250,
    regex: "email"
  },
  width: 30
};

const tel = {
  title: "Telephone",
  source: "tel",
  ttoken: "common.tel",
  type: "text",
  //placeholder: "+385xxxxxxxxx",
  mask: false,
  validation: {
    // required: true,
    //regex: "tel"
    maxLength: 20
  },
  width: 25
};

const mobile = {
  title: "Mobile phone",
  source: "mobile",
  ttoken: "common.mobile",
  type: "text",
  validation: {
    maxLength: 20
  },
  width: 25
};

const from_loc_name = {
  title: "From location name",
  source: "from_loc_name",
  ttoken: "common.from_loc",
  type: "text",
  readonly: true
};

const to_loc_name = {
  title: "To location name",
  source: "to_loc_name",
  ttoken: "common.to_loc",
  type: "text",
  readonly: true
};

export const commonFields = {
  mark_new: mark_new,
  oib: oib,
  vat: vat,
  email: email,
  tel: tel,
  mobile: mobile,
  from_loc_name: from_loc_name,
  to_loc_name: to_loc_name
};
