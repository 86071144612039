import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import moment from "moment";

//Material UI
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";

//Material-UI Icons
import InfoIcon from "@material-ui/icons/Info";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import AssignmentIcon from "@material-ui/icons/Assignment";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import MoneyIcon from "@material-ui/icons/Money";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ReceiptIcon from "@material-ui/icons/Receipt"

//CBC Icons
import UgovorIcon from "Icons/UgovorIcon";

//Custom components
import withFormController from "Components/withFormController";
import TabOsnovniPodaci from "../VoznjaFormTabs/TabOsnovniPodaci";
import TabPojedinacneVoznje from "../VoznjaFormTabs/TabPojedinacneVoznje";
import TabPonuda from "../VoznjaFormTabs/TabPonuda";
import TabFinancijskeObaveze from "../VoznjaFormTabs/TabFinancijskeObaveze";
import TabUgovor from "../VoznjaFormTabs/TabUgovor";
import CardHeading from "UI/Card/CardHeading";
import VoznjaAvatar from "UI/Form/Avatars/VoznjaAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import {
  CardColored,
  CardToolbarButtonAlarm,
  CardToolbarButtonBack,
  CardSOSHeading
} from "UI/Card";
import TabInternaKalkulacija from "../VoznjaFormTabs/TabInternaKalkulacija";
import TabPutniNalozi from "../VoznjaFormTabs/TabPutniNalozi";
import TabFaktura from "../VoznjaFormTabs/TabFaktura";
import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItemCopy from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemCopy";
import DialogToolbarMenuItemInvalidate from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemInvalidate";
import DialogToolbarMenuItemInvalidateCopy from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemInvalidateCopy";
import PrintButton from "UI/Buttons/PrintButton";
import { FormTab, FormTabPanel, FormTabs } from "UI/Form";
import { posloviStatuses, retrievePosloviStatusLabel } from "Lib/statuses";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import SaveButton from "UI/Buttons/SaveButton";
import ConfirmButton from "UI/Buttons/ConfirmButton";
import { posloviService } from "Services/posloviService";
import confirmDialog from "Lib/confirmDialog";
import DialogContext from "UI/DialogContext/DialogContext";
import DialogToolbarMenuItemPosebnaPonudaCreate from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemPosebnaPonuda";
import UserContext from "Components/UserContext/UserContext";
import withRedirect from "Components/withRedirect";
import TabPotvrda from "../VoznjaFormTabs/TabPotvrda";
import FormFillContent from "UI/Form/FormFillContent";
import EuroConversionComment from "Components/EuroConversionComment";

function VoznjaForm24(props) {
  const snackbarContext = useContext(SnackbarContext);
  const dialogContext = useContext(DialogContext);
  const userContext = useContext(UserContext);

  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();

  const [currentTab, setCurrentTab] = useState(0);

  const { dc, record, mode, validation, fields, subModels } = props; //HOC withFormController
  const { onFieldChange, doUpdate, doValidate } = props; //HOC withFormController
  const { handleCopy, handleStorniraj, handleStornirajCopy, handleRefreshForm } = props;
  const { isAdmin } = props;
  const { redirect } = props;

  const handleTabChange = (evt, value) => {
    setCurrentTab(value);
  };

  const handleClickBack = () => {
    history.goBack();
  };

  const handleUpdate = (evt) => {
    if (doUpdate) {
      doUpdate().then((result) => {
        if (result.success) {
          snackbarContext.showNotification("update", "success");
        }
      }).catch((result) => {
        if (result.success === false && result.validationPass === false) {
          //do nothing
        } else {
          snackbarContext.showNotification(result.error, "error");
        }
      });
    }
  };

  const confirmNextStep = () => {
    const title = t("confirm_voznje_status.voznja24.title");
    const text = t("confirm_voznje_status.voznja24.text");
    const type = "warning";
    const confirmButtonText = t("cdialogs.confirm");
    const cancelButtonText = t("cdialogs.cancel");

    return confirmDialog(title, text, type, confirmButtonText, cancelButtonText);
  };

  const handleConfirm = () => {
    confirmNextStep().then((result) => {
      if (result.confirmed) {
        if (doValidate) {
          const validation = doValidate();
          if (validation) {
            posloviService
              .interniPosaoStanje24_25(record.id)
              .then((resp) => {
                if (resp.success) {
                  snackbarContext.showNotification("update", "success");
                  if (handleRefreshForm) {
                    handleRefreshForm();
                  }
                } else {
                  snackbarContext.showNotification(resp.error.message, "error");
                }
              })
              .finally(() => {
                //history.goBack();
              });
          }
        }
      }
    });
  };

  const commonTabProps = {
    currentTab: currentTab,
    dc: dc,
    subModels: subModels,
    record: record,
    mode: mode,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    isAdmin: isAdmin,
    recordId: record.id
  };

  const title = `${t(retrievePosloviStatusLabel(record.posao_state_id))}  (ST.${
    record.posao_state_id
  })(ID:${record.id})`;
  const subtitle = `Rok Važenja Ponude: ${moment(record.st_expire).format(
    "DD.MM.YYYY"
  )}`;

  const identifier = dc.getSource() + record.posao_state_id;
  const isInterni = record["tip_voznje_id"] === 50;
  const isStorno = record["storno"] === true;
  const isAgentura = record.agentura === true;
  const { isPanic } = props;

  const customColor = isStorno ? theme.palette.custom.storno :
    isPanic ? theme.palette.custom.panic :
    isAgentura ? theme.palette.custom.agentura :
    undefined;

  const customText = isStorno ? t("messages.storno") :
    isPanic ? t("messages.panic") :
    isAgentura ? t("messages.agentura") :
    undefined;

  return (
    <CardColored color={customColor}>
      <CardHeading
        recordStatus={record.posao_state_id}
        statuses={posloviStatuses}
        color={customColor}
      >
        <Toolbar variant="dense" disableGutters={true}>
          <VoznjaAvatar />
          <DialogToolbarHeading header={title} subheader={subtitle} />
          {/*<CardSOSHeading isPanic={props.isPanic}/>*/}
          <DialogToolbarFillContent />
          {customText ?
            <Typography variant="button">{customText}</Typography>
            : null
          }
          <DialogToolbarFillContent />
          {
            !props.isPanic ?
              <Fragment>
              <CardToolbarButtonAlarm record={record} type="interna" onFieldChange={onFieldChange} />
                <DialogToolbarMenuButton>
                  {isInterni && !isStorno ? (
                    <DialogToolbarMenuItemInvalidate
                      onClick={() => handleStorniraj(record["id"])}
                    />
                  ) : null}
                  {isInterni && !isStorno ? (
                    <DialogToolbarMenuItemInvalidateCopy
                      onClick={() => handleStornirajCopy(record["id"], record)}
                    />
                  ) : null}
                  <DialogToolbarMenuItemCopy onClick={() => handleCopy(record)} />
                  <DialogToolbarMenuItemPosebnaPonudaCreate record={record} />
                </DialogToolbarMenuButton>
              </Fragment>
              : null
          }
          <CardToolbarButtonBack onClick={handleClickBack} />
        </Toolbar>
      </CardHeading>
      <CardContent style={{ paddingTop: 0 }}>
        <FormTabs
          value={currentTab}
          onChange={handleTabChange}
          identifier={identifier}
        >
          <FormTab id="osnovni-podaci" label={t("poslovi.tabs.osnovni_podaci")} icon={<InfoIcon />} aria-label="osnovni_podaci" />
          <FormTab id="pojedinacne-voznje" label={t("poslovi.tabs.pojedinacne_voznje")} icon={<AirportShuttleIcon />} aria-label="pojedinacne_voznje" />
          <FormTab id="interna-kalkulacija" label={t("poslovi.tabs.interna_kalkulacija")} icon={<MoneyIcon />} aria-label="interna_kalkulacija" />
          <FormTab id="ponuda" label={t("poslovi.tabs.ponuda")} icon={<AssignmentIcon />} aria-label="ponuda" />
          <FormTab id="financijske-obveze" label={t("poslovi.tabs.financijske_obveze")} icon={<MonetizationOnIcon />} aria-label="financijske_obveze" />
          <FormTab id="ugovor" label={t("poslovi.tabs.ugovor")} icon={<UgovorIcon />} aria-label="ugovor" />
          <FormTab id="usluge" label={t("poslovi.tabs.potvrda_usluge")} icon={<DoneAllIcon />} aria-label="potvrda_usluge" />
          <FormTab id="putni-nalozi" label={t("poslovi.tabs.putni_nalozi")} icon={<AssignmentIndIcon />} aria-label="putni_nalozi" />
          { isStorno ? <FormTab id="faktura" label={t("poslovi.tabs.faktura")} icon={<ReceiptIcon />} aria-label="faktura" /> : null }
        </FormTabs>
        <FormTabPanel value={currentTab} index={0}>
          <TabOsnovniPodaci {...commonTabProps} internal={true} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={1}>
          <TabPojedinacneVoznje {...commonTabProps} tabValue={1} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={2}>
          <TabInternaKalkulacija {...commonTabProps} tabValue={2} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={3}>
          <TabPonuda {...commonTabProps} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={4}>
          <TabFinancijskeObaveze {...commonTabProps} tabValue={4} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={5}>
          <TabUgovor {...commonTabProps} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={6}>
          <TabPotvrda {...commonTabProps} mode="view" />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={7}>
          <TabPutniNalozi {...commonTabProps} posao_state_id={record.posao_state_id} tabValue={7} />
        </FormTabPanel>
        { isStorno ?
          <FormTabPanel value={currentTab} index={8}>
            <TabFaktura {...commonTabProps} mode="update" doValidate={doValidate}/>
          </FormTabPanel> : null
        }
      </CardContent>
      <CardActions>
        <EuroConversionComment />
        <FormFillContent />
        <PrintButton
          dc={dc}
          target={currentTab === 3 ? "ponuda" :
            currentTab === 5 ? "ugovor" :
              currentTab === 6 ? "potvrda_usluge" : null
          }
          record={record}
          text={
            currentTab === 3
              ? t("buttons.ponuda")
              : currentTab === 5
              ? t("buttons.ugovor")
              : currentTab === 6
                ? t("buttons.potvrda_usluge")
                : t("buttons.print")
          }
          disabled={
            !(currentTab === 3 || currentTab === 5 || currentTab === 6)
          }
        />
        {
          !props.isPanic ?
            <ConfirmButton
              onClick={handleConfirm}
              text={t("buttons.start_voznje")}
              disabled={mode === "view" || currentTab !== 7}
            />
            : null
        }
        {
          !props.isPanic ?
            <SaveButton
              onClick={handleUpdate}
              text={t("buttons.save")}
              disabled={mode === "view"}
            />
            : null
        }
      </CardActions>
    </CardColored>
  );
}

export default withRedirect(withFormController(VoznjaForm24));
