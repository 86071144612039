import React, { useContext, useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

//Custom Components
import LoaderContext from "Components/LoaderContext/LoaderContext";
import model from "Models/vozilaCjenici";
import dataController from "Lib/dataController";
import CalendarControl from "UI/Calendar/CalendarControl";
import { vozilaService } from "Services/vozilaService";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import DialogContext from "UI/DialogContext/DialogContext";
import AlokacijaCjenikaDialog from "../AlokacijaCjenikaDialog";

const currentYear = new Date().getFullYear();

export default function TabPanelRasporedCjenika(props) {
  const {recordId, currentTab, tabIndex} = props;

  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);
  const dialogContext = useContext(DialogContext);

  const {t} = useTranslation();
  const [records, setRecords] = useState([]);

  const dc = new dataController(model);

  const contextMenuItems = [
    {
      text: t('buttons.edit'), click: evt => {}
    },
    {
      text: t('buttons.delete'), click: evt => {}
    }
  ];

  useEffect(() => {
    if(currentTab === tabIndex){
      refreshRecords();
    }
  }, [currentTab]);

  const parseCalendarData = (records) => {
    let parsedRecords = [];

    records.forEach((record) => {
      let event = {};
      event.name = record.name;
      event.id = record.id;
      event.startDate = moment(record.start_date).toDate();
      event.endDate = moment(record.end_date).toDate();
      event.color = record.rgba
      parsedRecords.push(event);
    })
    return parsedRecords;
  }

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    vozilaService.getAlokacijeCijenka(recordId).then((resp) => {
      if (resp.success) {
        setRecords(resp.data ? parseCalendarData(resp.data) : []);
      }
    }).catch((result) => {
      snackbarContext.showNotification(result.error, "error");
    }).finally(() => {
      loaderContext.toggleLoading(false);
    })
  }

  const handleDialogClose = (result) => {
    if (result && result.dataChanged) {
      refreshRecords();
    }
  };

  const onDayClick = (evt) => {
    onRangeSelected(evt)
  }

  const onRangeSelected = (evt) => {
    const id = evt.events.length > 0  ? evt.events[0].id : null;
    if (id) {
      vozilaService.getAlokacijeCjenik(recordId, id).then((resp) => {
        if (resp.success) {
          dialogContext.showDialog(AlokacijaCjenikaDialog, {
            dc: dc,
            form: "alokacija",
            mode: "update",
            record: resp.data,
            voziloId: recordId,
            onClose: handleDialogClose,
            subModelDefaultValues: {
              vozilo_id: recordId
            }
          });
        }
      }).catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      });
    } else {
      dialogContext.showDialog(AlokacijaCjenikaDialog, {
        dc: dc,
        form: "alokacija",
        mode: "insert",
        onClose: handleDialogClose,
        start_date: evt.startDate,
        end_date: evt.endDate,
        voziloId: recordId,
        subModelDefaultValues: {
          vozilo_id: recordId
        }
      });
    }
  }

  return (
      <Fragment>
        <CalendarControl
            allowOverlap={false}
            roundRangeLimits={true}
            enableRangeSelection={true}
            records={records}
            onRangeSelected={(evt) => onRangeSelected(evt)}
            onDayClick={(evt) => onDayClick(evt)}
            refreshRecords={refreshRecords}
        />
      </Fragment>
  );
}
