import React from "react";
import PropTypes from "prop-types";

//Material-UI Core Components
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";

//Custom Components
import UserConsumer from "Components/UserContext/UserConsumer";

const customButtonStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.black,
    marginLeft: 5,
    marginRight: 5
  }
}));

function FormButton(props) {
  const { needPermission, ...rest } = props;
  const classes = customButtonStyles();

  return (
    <UserConsumer>
      {userContext =>
        needPermission && !userContext.hasAnyPermission(needPermission) ? null : (
          <Button classes={classes} color="secondary" size="medium" {...rest}>
            {props.children}
          </Button>
        )
      }
    </UserConsumer>
  );
}

FormButton.propTypes = {
  needPermission: PropTypes.arrayOf(PropTypes.object)
};

export default FormButton;
