import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

//Material UI
import Box from "@material-ui/core/Box";

//Custom components
import withRedirect from "Components/withRedirect";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import UserContext from "Components/UserContext/UserContext";
import confirmDialog from "Lib/confirmDialog";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import AdminPage from "UI/AppPage/AdminPage";
import dataController from "Lib/dataController";
import model from "Models/poslovi";
import modelUpiti from "Models/upiti";

import AdminUpitForm11 from "Views/Administration/AdminVoznje/AdminVoznjaForms/AdminUpitForm11";
import AdminVoznjaForm24 from "Views/Administration/AdminVoznje/AdminVoznjaForms/AdminVoznjaForm24";
import AdminVoznjaForm25 from "Views/Administration/AdminVoznje/AdminVoznjaForms/AdminVoznjaForm25";
import AdminVoznjaForm26 from "Views/Administration/AdminVoznje/AdminVoznjaForms/AdminVoznjaForm26";
import AdminVoznjaForm29 from "Views/Administration/AdminVoznje/AdminVoznjaForms/AdminVoznjaForm29";

import { adminService } from "Services/adminService";

const AdminVoznjaView = React.memo((props) => {
  const { t } = useTranslation();
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);
  const userContext = useContext(UserContext);

  const [formInfo, setFormInfo] = useState({ component: undefined, form: undefined, dc: undefined });

  const dc = new dataController(model, "admin/voznje");
  const dcPoslovi = new dataController(model, "admin/voznje");
  const dcUpiti = new dataController(modelUpiti, "admin/upiti");

  const { id } = useParams();
  const recordId = parseInt(id, 10);
  const { redirect } = props;

  const getFormInfo = (status) => {
    switch (status) {
      case 11:
        return { component: AdminUpitForm11, form: "status11", dc: dcUpiti };
      case 24:
        return { component: AdminVoznjaForm24, form: "AdminPPosaoStatus24", dc: dcPoslovi };
      case 25:
        return { component: AdminVoznjaForm25, form: "AdminPPosaoStatus25", dc: dcPoslovi };
      case 26:
        return { component: AdminVoznjaForm26, form: "AdminPPosaoStatus26", dc: dcPoslovi };
      case 29:
        return { component: AdminVoznjaForm29, form: "AdminPPosaoStatus29", dc: dcPoslovi };
    }
  };

  const refreshRecord = () => {
    if (userContext.subjectId) {
      loaderContext.toggleLoading(true);
      dc.GetDataSingle(recordId)
        .then((resp) => {
          if (resp.success) {
            const record = resp.data;
            const status = record.posao_state_id;
            const markNew = record.mark_new;
            if (markNew === true) {
              markRead();
            }
            const fi = getFormInfo(status);
            setFormInfo({...fi, record: record});
          }
        })
        .catch((result) => {
          if (result && result.error) {
            snackbarContext.showNotification(result.error.message, "error");
          }
        })
        .finally(() => {
          loaderContext.toggleLoading(false);
        });
    }
  };

  const markRead = () => {
    dc.MarkReadRecord(recordId)
    .then((resp) => {
      //do nothing
    })
    .catch((result) => {
      //also do nothing
    })
  }

  const handleStorniraj = (id) => {
    const title = t("confirm_voznje_status.storniraj.title");
    const text = t("confirm_voznje_status.storniraj.text");
    return confirmDialog(title, text, "warning", t("cdialogs.confirm"), t("buttons.cancel")).then((result) => {
      if(result.confirmed) {
        adminService.stornirajPosao(id).then((resp) => {
          if (resp.success) {
            snackbarContext.showNotification("notifications.storniraj", "success");
            redirect("/admin/voznje");
          }
        }).catch((result) => {
          if (result && result.error) {
            snackbarContext.showNotification(result.error.message, "error");
          }
        });
      }
    })
  };

  useEffect(() => {
    refreshRecord();
  }, [userContext.subjectId, id, recordId]);

  return (
    <AdminPage>
      <Box m={2}>
        {formInfo.component
          ? React.createElement(formInfo.component, {
              dc: formInfo.dc,
              mode: "view",
              form: formInfo.form,
              record: formInfo.record,
              subModelDefaultValues: {
                posaoId: recordId
                , prijevoznik_id: formInfo.record.prijevoznik_id
              },
              handleStorniraj: (id) => handleStorniraj(id)
            })
          : null}
      </Box>
    </AdminPage>
  );
})

export default withRedirect(AdminVoznjaView);
