import api from "Lib/api";

export const businessSubjectService = {
  activateBusinessSubject,
  deactivateBusinessSubject,

  createImage,
  getFullImage,
  deleteImage
}

const bsApiUrl = 'business-subjects/';

function activateBusinessSubject(id){
  const apiInstance = new api();
  const path = bsApiUrl + id + "/activate";
  return apiInstance.Call(path, "patch");
}

function deactivateBusinessSubject(id){
  const apiInstance = new api();
  const path = bsApiUrl + id + "/deactivate";
  return apiInstance.Call(path, "patch");
}

function createImage(bsId, data) {
  const apiInstance = new api();
  const path = bsApiUrl + bsId + "/images"
  return apiInstance.Call(path, "post", data);
}

function getFullImage(bsId, uuid){
  const apiInstance = new api();
  const path = bsApiUrl + bsId + "/images/" + uuid;
  return apiInstance.Call(path, "get");
}

function deleteImage(bsId, uuid) {
  const apiInstance = new api();
  const path = bsApiUrl + bsId + "/images/" + uuid;
  return apiInstance.Call(path, "delete");
}
