import React from "react";

//Custom Components
import PrijevozniciPage from "UI/AppPage/PrijevozniciPage";
import ResponsiveBox from "UI/Box/ResponsiveBox";
import RezervacijeTable from "Views/Prijevoznici/Rezervacije/RezervacijeTable";

const RezervacijePage = React.memo((props) => {
  return (
    <PrijevozniciPage>
      <ResponsiveBox fillHeight>
        <RezervacijeTable />
      </ResponsiveBox>
    </PrijevozniciPage>
  );
});

export default RezervacijePage;
