import React, { useState } from "react";
import PropTypes from "prop-types";

//Material-UI Core Components
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

//Material-UI Icons
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

//Custom Controls
import TextControl from "Controls/TextControl";

function PasswordControl(props) {
  const { formMode, controlMode, field, value, label, helperText, validation, onChange, ...other } = props;

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => {
      return !prevState;
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextControl
      {...props}
      type={showPassword ? "text" : "password"}
      inputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              tabIndex={-1}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
}

PasswordControl.defaultProps = {
  formMode: "form",
  controlMode: "edit"
};

PasswordControl.propTypes = {
  formMode: PropTypes.oneOf(["form", "table"]),
  controlMode: PropTypes.oneOf(["edit", "view"]),
  field: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  helperText: PropTypes.string,
  validation: PropTypes.object,
  onChange: PropTypes.func
};

export default PasswordControl;
