import React from 'react';

//Custom components
import PrijevozniciPage from "UI/AppPage/PrijevozniciPage";
import KomunikatorPage from "./KomunikatorPage";

const PrijevozniciKomunikatorPage = React.memo((props) => {

  return (
    <PrijevozniciPage>
      <KomunikatorPage prefix={"p"}/>
    </PrijevozniciPage>
  );
})

export default PrijevozniciKomunikatorPage;
