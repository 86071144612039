import React from 'react'
import PropTypes from "prop-types";

//Material-UI Core Components
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const style = {
  toolbarHeading: {
    marginLeft: 10,
    marginRight: 20
  }
}

class TableHeading extends React.Component {
  render() {
    const { children, classes } = this.props;

    return (
      <Typography align="left" variant="h5" component="h3" className={classes.toolbarHeading} style={this.props.lowercase ? {textTransform: 'none'} : null}>
        {children}
      </Typography>
    );
  }
}

TableHeading.defaultProps = {
  lowercase: false,
}

TableHeading.propTypes = {
  lowercase: PropTypes.bool,
}

export default withStyles(style)(TableHeading);