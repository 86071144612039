import React from 'react';

//Custom components
import AgencijePage from "UI/AppPage/AgencijePage";
import KomunikatorSinglePage from "./KomunikatorSinglePage";

const AgencijeKomunikatorSinglePage = React.memo((props) => {

  return (
      <AgencijePage>
        <KomunikatorSinglePage isOnUnseen={props.isOnUnseen}/>
      </AgencijePage>
  );
})

export default AgencijeKomunikatorSinglePage;
