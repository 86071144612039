import moment from "moment";
import numbro from "numbro";

import api from "Lib/api";
import { conversions } from "Lib/conversions";
import { helpers } from "Lib/helpers";

class dataController {
  constructor(model, customPath = null) {
    this.apiPath = customPath ? customPath : model.apiPath;
    this.filename = model.exportFileName;
    this.listViews = model.listViews;
    this.forms = model.forms;
    this.fields = model.fields;

    this.fieldId = this.fields.find(attr => {
      return attr.idattr === true;
    });

    this.api = new api();
  }

  path_combine(path, recordId) {
    const pathEndsWithSlash = path.charAt(path.length - 1) === "/";
    return path + (pathEndsWithSlash ? "" : "/") + recordId;
  }

  CustomGetAction(url) {
    return this.api.Call(url, "get");
  }

  CustomPutAction(url, data) {
    const putData = this.prepareSendingData(data);
    return this.api.Call(url, "put", putData);
  }

  CustomPostAction(url, data, multi = false) {
    let postData = null;

    if (multi) {
      postData = data.map(d => this.prepareSendingData(d));
    } else {
      postData = this.prepareSendingData(data);
    }

    return this.api.Call(url, "post", postData);
  }

  CustomPatchAction(url, data) {
    const patchData = this.prepareSendingData(data);
    return this.api.Call(url, "patch", patchData);
  }

  GetData(customPath = null) {
    const path = customPath !== null ? customPath : this.apiPath;
    return this.api.Call(path, "get")
      .then(resp => {
        if (Array.isArray(resp.data)) {
          const data = resp.data.map(record => this.prepareReturnData(record));
          const success = resp.success;
          return { success: success, data: data };
        } else {
          return { success: resp.success, data: this.prepareReturnData(resp.data) };
        }
      });
  }

  GetDataSingle(id, customPath = null) {
    const path = customPath !== null ? customPath : this.path_combine(this.apiPath, id);

    return this.api.Call(path, "get")
      .then(resp => {
        if (resp.success) {
          const returnData = this.prepareReturnData(resp.data);
          return { success: true, data: returnData };
        } else {
          return { sucess: false, error: resp.error };
        }
      });
  }

  InsertRecord(record, apiPath = null) {
    let insertData = this.prepareSendingData(record);
    const path = apiPath !== null ? apiPath : this.apiPath;
    return this.api
      .Call(path, "post", insertData)
      .then(response => {
        if (response && response.success) {
          let newId = response.data.id;
          if (newId) {
            return Promise.resolve({ success: true, data: response.data, id: newId });
          } else {
            return Promise.resolve({ success: true, data: response.data });
          }
        } else {
          return Promise.reject({ success: false, error: response.error });
        }
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  UpdateRecord(recordId, record, apiPath = null) {
    if (!this.fieldId) {
      this.debugLog(
        "Attempt to update record without id attribute in the model!"
      );
      return Promise.reject("Unknown ID field!");
    }

    if (!recordId) {
      this.debugLog("Attempt to update record withhout recordId!");
      return Promise.reject("Unknown record Id!");
    }

    let updateData = this.prepareSendingData(record);
    const currPath = apiPath !== null ? apiPath : this.apiPath;
    const path = this.path_combine(currPath, recordId);
    return this.api
      .Call(path, "put", updateData)
      .then(response => {
        if (response.success) {
          return Promise.resolve({ success: true, data: response.data });
        } else {
          return Promise.reject({ success: false, error: response.error });
        }
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  DeleteRecord(recordId, apiPath = null) {
    if (!this.fieldId) {
      this.debugLog(
        "Attempt to delete record without id attribute in the model!"
      );
      return Promise.reject("Unknown ID field!");
    }

    if (!recordId) {
      this.debugLog("Attempt to delete record withhout recordId!");
      return Promise.reject("Unknown record Id!");
    }

    let deleteData = {};
    deleteData[this.fieldId.source] = recordId;
    const currPath = apiPath !== null ? apiPath : this.apiPath;
    const path = this.path_combine(currPath, recordId);
    return this.api
      .Call(path, "delete", deleteData)
      .then(response => {
        if (response.success) {
          return Promise.resolve({ success: true, data: response.data });
        } else {
          return Promise.resolve({ success: false, error: response.error });
        }
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  MarkReadRecord(recordId, read = true) {
    const action = read ? "/mark-read" : "/mark-unread"
    const path = this.path_combine(this.apiPath, recordId) + action;
    return this.api
      .Call(path, "patch", {})
      .then(response => {
        if (response && response.success) {
          let newId = response.data.id;
          if (newId) {
            return Promise.resolve({ success: true, data: response.data, id: newId });
          } else {
            return Promise.resolve({ success: true, data: response.data });
          }
        } else {
          return Promise.reject({ success: false, error: response.error });
        }
      })
      .catch(err => {
        return Promise.reject(err);
      });

  }

  debugLog(msg) {
    console.warn(msg);
  }

  prepareReturnData(record) {
    let data = Object.assign({}, record);

    //we pass everything (filter keys also!)
    Object.keys(data).forEach(key => {
      const field = this.getField(key);
      if (field) {
        switch (field.type) {
          case "date":
          case "datetime":
            if (data[key]) {
              data[key] = moment.utc(data[key]);
            }
            break;
          case "dokumenti":
            if (!Array.isArray(data[key])) {
              data[key] = [data[key]];
            }
          // case "table":
          //   let arr = data[key];
          //   let parseArr = [];
          //   arr.forEach(rec => {
          //     parseArr.push(this.prepareReturnData(rec));
          //   })
          //   data[key] = parseArr;
          //   break;
          case "HEREautocomplete":
          case "GooglePlaces":
            if (data[key] !== null) {
              const obj = data[key];
              if (obj.id && obj.name && obj.wkt) {
                data[key] = {
                  value: obj.id,
                  label: obj.name,
                  coords: conversions.wkt_to_latLng(obj.wkt)
                };
              }
            }
            break;
          case "HEREautocompleteViaStops":
          case "GooglePlacesViaControl":
            if (data[key] !== null) {
              if (Array.isArray(data[key])) {
                data[key] = data[key].map(x => {
                  return {
                    id: helpers.uuidv4(),
                    value: {
                      value: x.id,
                      label: x.name,
                      coords: conversions.wkt_to_latLng(x.wkt)
                    }
                  };
                });
              }
            }
            break;
          case "HEREroute":
            if (data[key] !== null) {
              const obj = data[key];
              data[key] = [{
                id: helpers.uuidv4(),
                points: conversions.wkt_to_line(obj)
              }];
            }
            break;
          case "picker":
            if (data[key] !== null) {
              if (field.formatting) {
                switch (field.formatting) {
                  case "dhm":
                    const secondsInDay = 24 * 60 * 60;
                    const time = moment().startOf("day").seconds(data[key] * 60 * 60);

                    const timeFormatted = data[key] < secondsInDay
                      ? time.format("H") + " h " + time.format("m") + " min"
                      : Math.floor(data[key] / secondsInDay) + " d " + time.format("H") + " h " + time.format("m") + " min";

                    data[key] = { value: data[key], label: timeFormatted };
                    break;
                  case "km":
                    const kmFormatted = { value: data[key], label: Math.round(data[key]).toString() + " km" };
                    data[key] = kmFormatted;
                    break;
                }
              }
            }
            break;
          default:
        }

      }
    });

    return data;
  }

  prepareSendingData(record) {
    let data = Object.assign({}, record);

    const subModelKey = "value";

    //we pass everything (filter keys also!)
    Object.keys(data).forEach(key => {
      const field = this.getField(key);
      if (field) {
        switch (field.type) {
          case "date":
            data[key] = data[key] ? moment(data[key]).format("YYYY-MM-DD") : null;
            break;
          case "datetime":
            data[key] = data[key] ? moment.utc(data[key]).toISOString(true) : null;
            break;
          case "richtext":
            const reg = /\&nbsp\;/gi;
            const regAmp = /\&amp\;/gi;
            if (data[key]) {
              data[key] = data[key].replace(reg, " ").replace(regAmp, "&");
            }
            break;
          case "picker":
          case "pickergroup":
          case "radio":
            if (typeof data[key] === "object" && data[key] !== null && data[key].hasOwnProperty(subModelKey)) {
              data[key] = data[key][subModelKey];

            } else if (field.multi) {
              if (Array.isArray(data[key])) {
                data[key] = data[key].map(d => d[subModelKey] ? d[subModelKey] : d);
              }
            }
            break;
          case "checkbox":
            if (Array.isArray(data[key]) && data[key].length > 0) {
              const dataArray = [];
              let dataInt = 0;
              if (field.flags) {
                data[key].forEach(d => {
                  dataInt += d[subModelKey];
                });
                data[key] = dataInt;
              } else {
                data[key].forEach(d => {
                  if (d.hasOwnProperty(subModelKey)) {
                    dataArray.push(d[subModelKey]);
                  } else {
                    dataArray.push(d);
                  }
                });
                data[key] = dataArray;
              }
            }
            break;
          case "numeric":
            if (data[key] !== null && typeof data[key] === "string") {
              data[key] = parseFloat((data[key]).replace(",", "."));
            }
            break;
          case "currency":
            if (data[key] !== null) {
              const parsedValue = numbro(data[key]);
              numbro.setLanguage("en-US");
              data[key] = numbro.unformat(parsedValue.format());
              numbro.setLanguage("hr-HR");
            }
            break;
          case "images":
          case "dokumenti":
            if (field.object) {
              data[key] = data[key][0];
            }
            break;
          case "HEREautocomplete":
          case "GooglePlaces":
            if (data[key] !== null) {
              if (data[key].coords && data[key].label && data[key].value) {
                data[key] = {
                  id: data[key].value,
                  name: data[key].label,
                  wkt: conversions.lat_lng_toWkt(data[key].coords)
                };
              }
            }
            break;
          case "HEREautocompleteViaStops":
          case "GooglePlacesViaControl":
            if (data[key] !== null) {
              if (Array.isArray(data[key])) {
                data[key] = data[key].map(x => x.value).map(y => {
                  return {
                    id: y.value,
                    name: y.label,
                    wkt: conversions.lat_lng_toWkt(y.coords)
                  };
                });
              }
            }
            break;
          case "HEREroute":
            if (data[key] !== null) {
              if (Array.isArray(data[key]) && data[key].length > 0) {
                const coords = data[key][0].points;
                data[key] = conversions.line_toWkt(coords);
              }
            }
          case "special_faktura_stavke":
            if (data[key] !== null) {
              if (Array.isArray(data[key]) && data[key].length > 0) {
                data[key] = data[key].map(x => ({
                  id: x.id,
                  opis: x.opis,
                  iznos_neto: numbro(x.iznos_neto).value(),
                  pdv_stopa: x.pdv_stopa.hasOwnProperty("value") ? x.pdv_stopa.value : x.pdv_stopa,
                  pdv_iznos: x.pdv_iznos,
                  ukupno: x.iznos_bruto
                }));
              }
            }
          // case "table":
          //   let arr = data[key];
          //   let parseArr = [];
          //   arr.forEach(rec => {
          //     parseArr.push(this.prepareSendingData(rec));
          //   })
          //   data[key] = parseArr;
          //   break;
        }
      }
    });

    // ensure we send id
    // if (this.fieldId) {
    //   data[this.fieldId.source] = record[this.fieldId.source]; //ensure we have recordId
    // }

    return data;
  }

  getField(name) {
    const field = this.fields.find(f => f.source === name);
    return field ? JSON.parse(JSON.stringify(field)) : null; //clone
  }

  getFields(fieldNames) {
    const fields = fieldNames
      ? fieldNames.map(name => this.fields.find(f => f.source === name))
      : [];
    return JSON.parse(JSON.stringify(fields)); //clone
  }

  //Helpers

  overrideFieldConfigurations(baseFields, finalFields) {
    finalFields.forEach(f => {
      if (typeof f === "object") {
        Object.keys(f).forEach(key => {
          if (key !== "source") {
            let baseField = baseFields.find(x => x.source == f.source);
            if (baseField.hasOwnProperty(key)) {
              if (f[key] !== null) {
                baseField[key] = f[key];
              } else {
                delete baseField[key];
              }
            } else {
              if (f[key] !== null) {
                baseField[key] = f[key];
              }
            }
          }
        });
      }
    });
    return baseFields;
  }

  getSource() {
    return this.apiPath !== null && this.apiPath !== undefined && this.apiPath.length ?
      this.apiPath
      : "undefined_source";
  }

  //View

  getView(viewId) {
    if (viewId !== undefined && this.listViews.hasOwnProperty(viewId)) {
      return this.listViews[viewId];
    } else if (this.listViews.hasOwnProperty("default")) {
      return this.listViews["default"];
    } else {
      return this.errorObj(this.listViews, "default");
    }
  }

  getViewFieldsNames(viewId) {
    const view = this.getView(viewId);
    return view && view.fields && Array.isArray(view.fields) ? view.fields.map(x => (typeof x === "object" ? x.source : x)) : this.errorObj(view, "fields", "array");
  }

  getViewFields(viewId) {
    const view = this.getView(viewId);
    if (view === null) {
      return this.errorObj(view, "", "array");
    }

    const fieldNames = this.getViewFieldsNames(viewId);
    let baseFields = this.getFields(fieldNames);

    baseFields = this.overrideFieldConfigurations(baseFields, view.fields);

    return baseFields;
  }

  getHiddenViewFieldsNames(viewId) {
    const view = this.getView(viewId);
    if (view !== null && view.hasOwnProperty("hidden")) {
      return ["id"].concat(view.hidden);
    } else {
      return ["id"];
    }
  }

  //Form

  getForm(formId) {
    if (this.forms === undefined) {
      return null;
    } else if (formId !== undefined && this.forms[formId]) {
      return this.forms[formId];
    } else if (this.forms.default) {
      return this.forms.default;
    } else {
      return this.errorObj(this.forms, "default");
    }
  }

  getFormFieldsNames(formId) {
    const form = this.getForm(formId);
    return form && form.fields && Array.isArray(form.fields) ? form.fields.map(x => (typeof x === "object" ? x.source : x)) : this.errorObj(form, "fields", "array");
  }

  getFormFields(formId) {
    const form = this.getForm(formId);
    if (form === null) {
      return this.errorObj(form, "", "array");
    }
    const fieldNames = this.getFormFieldsNames(formId);
    let baseFields = this.getFields(fieldNames);

    baseFields = this.overrideFieldConfigurations(baseFields, form.fields);

    return baseFields;
  }

  //Validator

  getValidator(formId) {
    const form = this.getForm(formId);
    return form && form.validator ? form.validator : this.errorObj(form, "validator");
  }

  //Error handling

  /**
   * @description Error logger for objects
   * @name errorObj
   * @params
   *   obj: object
   *   key: string (optional)
   *   type: string (optional)
   * @returnVal
   *   if type == "array" return value will be an empty array else the function returns null
   */
  errorObj(obj, key = "", type = null) {
    if (process.env.REACT_APP_DEVELOPMENT) {
      return type === "array" ? [] : null;
    }

    if (obj === null || obj === undefined) {
      console.error("Object", obj, "is null or undefined!");
    } else if (!obj.hasOwnProperty(key)) {
      console.error("Object", obj, "doesn't contain the key:", key);
    } else if (obj[key] === null || obj[key] === undefined) {
      console.error("Object", obj, "value under key:", key, "is", obj[key]);
    } else if (type !== null) {
      if (type === "array" && !Array.isArray(obj[key])) {
        console.error("Object", obj, "value under key:", key, "is not an Array!");
      } else if (typeof obj[key] !== type) {
        console.error("Object", obj, "value under key:", key, "type:", typeof obj[key], " is not expected type:", type, "!");
      }
    } else {
      console.error("Unknown error!", obj, key, type);
    }

    return type === "array" ? [] : null;
  }
}

export default dataController;
