import React from "react";
import PropTypes from "prop-types";

//Custom Components
import GoogleMap from "Components/Google/GoogleMap";
import { mapIcons } from "Lib/mapIcons";

function GoogleMapRoute(props) {
  const { origin, destination, stops, route, ...other } = props;

  const markers = [];
  const routes = [];

  if (origin) {
    const icon = mapIcons.characterPointIcon("►");
    const fromMarker = {
      id: origin.value,
      label: origin.label,
      coords: origin.coords,
      icon: icon
    };
    markers.push(fromMarker);
  }

  if (Array.isArray(stops) && stops.length > 0) {
    stops.forEach((stop,i) => {
      const stopMarker = {
        id: stop.value,
        label: stop.label,
        coords: stop.coords,
        icon: mapIcons.characterPointIcon((i + 1).toString())
      };
      markers.push(stopMarker);
    })
  }

  if (destination) {
    const icon = mapIcons.characterPointIcon("◼");
    const toMarker = {
      id: destination.value,
      label: destination.label,
      coords: destination.coords,
      icon: icon
    };
    markers.push(toMarker);
  }

  if (route) {
    routes.push(route);
  }

  return <GoogleMap markers={markers} routes={routes} allowInteraction={true} {...other} />;
}

GoogleMapRoute.propTypes = {
  origin: PropTypes.object,
  destination: PropTypes.object,
  stops: PropTypes.array,
  route: PropTypes.object
}

export default GoogleMapRoute;
