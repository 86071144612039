import React from "react";

//Custom Components
import PrijevozniciPage from "UI/AppPage/PrijevozniciPage";
import ResponsiveBox from "UI/Box/ResponsiveBox";
import DirektniUpitiTable from "Views/Prijevoznici/DirektniUpiti/DirektniUpitiTable";

const DirektniUpitiPage = React.memo((props) => {
  return (
    <PrijevozniciPage>
      <ResponsiveBox fillHeight>
        <DirektniUpitiTable />
      </ResponsiveBox>
    </PrijevozniciPage>
  );
});

export default DirektniUpitiPage;
