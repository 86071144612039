import React from 'react';
import { useTranslation } from "react-i18next";

//Material-UI Icons
import AddBoxIcon from '@material-ui/icons/AddBox';

//Custom components
import CardToolbarMenuItem from "UI/Card/CardToolbarMenuItem";

const CardToolbarMenuItemCopy = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return <CardToolbarMenuItem label={t("buttons.copy")} icon={<AddBoxIcon />} {...props} ref={ref} />;
});

export default CardToolbarMenuItemCopy;
