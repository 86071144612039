import { coreFields } from "./helpers/coreFields";
import { commonFields } from "./helpers/commonFields";
import { alarmFields } from "./helpers/alarmFields";
import { buttonFields } from "./helpers/buttonFields";

const tabOsnovnoFields = [
  "id",
  "from_ts",
  "to_ts",
  "from_loc",
  "to_loc",
  "relacija_opis_skraceni",
  "kupac_id",
  "kupac_kontakt",
  "narucitelj_id",
  "narucitelj_kontakt",
  "vozilo_id",
  "vozac_id",
  "kooperant_id",
  "kooperant_napomena",
  "vozilo_broj_putnika",
  "vozilo_broj_vodica",
  "napomena_interna"
];

const adminTabOsnovnoFields = [
  "id",
  "from_ts",
  "to_ts",
  "from_loc",
  "to_loc",
  "relacija_opis_skraceni",
  "kupac_id",
  "kupac_kontakt",
  "narucitelj_naziv",
  "narucitelj_kontakt",
  "vozilo_naziv",
  "vozac_ime_i_prezime",
  "kooperant_naziv",
  "kooperant_napomena",
  "vozilo_broj_putnika",
  "vozilo_broj_vodica",
  "napomena_interna"
];

const tabVoziloFields = ["vozilo_tip_id",
  "vozilo_klasa_id",
  "klasa_motora_id",
  "vozilo_oprema_ids"];

const tabVoznjeFields = ["km_calc_voznje", "min_calc_voznje", "km_manual", "min_manual"];
const tabFinCalcFields = ["iznos_fincalc", "iznos_hrk", "eksterni_trosak_helper"];
const tabPonudaFields = ["broj_ponude", "iznos_hrk", "datum_ponude", "rok_potvrda", "ponuda_napomena"];
const tabUgovorFields = ["broj_sjedala", "datum_ugovora", "ugovor_iznos_hrk"];
const tabPotvrdaFields = ["kupac_id", "vodic_ime_prezime", "potvrda_napomena"];

export default {
  title: "Poslovi",
  exportFileName: "poslovi",
  apiPath: "poslovi/",
  forms: {
    status11: {
      fields: [...tabOsnovnoFields, ...tabVoznjeFields, ...tabFinCalcFields, ...tabPonudaFields]
    },
    status20: {
      fields: [...tabOsnovnoFields, ...tabVoznjeFields, ...tabFinCalcFields, ...tabPonudaFields]
    },
    status21: {
      fields: [...tabOsnovnoFields, ...tabVoznjeFields, ...tabFinCalcFields, ...tabPonudaFields]
    },
    status22: {
      fields: [...tabOsnovnoFields, ...tabVoznjeFields, ...tabFinCalcFields, ...tabPonudaFields, ...tabUgovorFields]
    },
    status23: {
      fields: [
        ...tabOsnovnoFields,
        ...tabVoznjeFields,
        ...tabFinCalcFields,
        ...tabPonudaFields,
        ...tabUgovorFields,
        ...tabPotvrdaFields
      ]
    },
    status24: {
      fields: [
        ...tabOsnovnoFields,
        ...tabVoznjeFields,
        ...tabFinCalcFields,
        ...tabPonudaFields,
        ...tabUgovorFields,
        ...tabPotvrdaFields
      ]
    },
    status25: {
      fields: [
        ...tabOsnovnoFields,
        ...tabVoznjeFields,
        ...tabFinCalcFields,
        ...tabPonudaFields,
        ...tabUgovorFields,
        ...tabPotvrdaFields
      ]
    },
    status26: {
      fields: [
        ...tabOsnovnoFields,
        ...tabVoznjeFields,
        ...tabFinCalcFields,
        ...tabPonudaFields,
        ...tabUgovorFields,
        ...tabPotvrdaFields
      ]
    },
    status29: {
      fields: [
        ...tabOsnovnoFields,
        ...tabVoznjeFields,
        ...tabFinCalcFields,
        ...tabPonudaFields,
        ...tabUgovorFields,
        ...tabPotvrdaFields
      ]
    },
    PPosaoStatus24: {
      fields: [
        ...tabOsnovnoFields,
        ...tabVoznjeFields,
        ...tabFinCalcFields,
        ...tabPonudaFields,
        ...tabPotvrdaFields,
        ...tabVoziloFields
      ]
    },
    PPosaoStatus25: {
      fields: [
        ...tabOsnovnoFields,
        ...tabVoznjeFields,
        ...tabFinCalcFields,
        ...tabPonudaFields,
        ...tabPotvrdaFields,
        ...tabVoziloFields
      ]
    },
    PPosaoStatus26: {
      fields: [
        ...tabOsnovnoFields,
        ...tabVoznjeFields,
        ...tabFinCalcFields,
        ...tabPonudaFields,
        ...tabPotvrdaFields,
        ...tabVoziloFields
      ]
    },
    PPosaoStatus29: {
      fields: [
        ...tabOsnovnoFields,
        ...tabVoznjeFields,
        ...tabFinCalcFields,
        ...tabPonudaFields,
        ...tabPotvrdaFields,
        ...tabVoziloFields
      ]
    },
    AdminPPosaoStatus24: {
      fields: [
        ...adminTabOsnovnoFields,
        ...tabVoznjeFields,
        ...tabPonudaFields,
        ...tabPotvrdaFields,
        ...tabVoziloFields
      ]
    },
    AdminPPosaoStatus25: {
      fields: [
        ...adminTabOsnovnoFields,
        ...tabVoznjeFields,
        ...tabPonudaFields,
        ...tabPotvrdaFields,
        ...tabVoziloFields
      ]
    },
    AdminPPosaoStatus26: {
      fields: [
        ...adminTabOsnovnoFields,
        ...tabVoznjeFields,
        ...tabPonudaFields,
        ...tabPotvrdaFields,
        ...tabVoziloFields
      ]
    },
    AdminPPosaoStatus29: {
      fields: [
        ...adminTabOsnovnoFields,
        ...tabVoznjeFields,
        ...tabPonudaFields,
        ...tabPotvrdaFields,
        ...tabVoziloFields
      ]
    },
    sazetak: {
      fields: ["from_ts", "to_ts", "relacija_opis_skraceni", "kupac_id", "kooperant_id", "vozilo_id", "vozac_id"]
    },
    internalPosao: {
      fields: [
        "from_ts",
        "to_ts",
        "from_loc",
        "to_loc",
        "ruta",
        "relacija_opis_skraceni",
        "kupac_id",
        "kupac_kontakt",
        "narucitelj_id",
        "narucitelj_kontakt",
        "vozilo_id",
        "vozac_id",
        "kooperant_id",
        "kooperant_napomena",
        "vozilo_broj_putnika",
        "vozilo_broj_vodica",
        "napomena_interna",
        "stajalista",
        "trajanje_voznje",
        "neto_udaljenost_voznje",
        "udaljenost_povratka_vozila"
      ]
    },
    assign_mediator: {
      fields: [ "mediator_id" ]
    },
    agentura: {
      fields: [ "agentura_napomena"]
    }
  },
  listViews: {
    default: {
      fields: ["id", "posao_state_id", "vozilo_broj_putnika", "kupac_id", "relacija_opis_skraceni"],
      hidden: ["id"]
    },
    admin: {
      fields: [
        "id",
        "upit_id",
        "view_ag",
        "active",
        "panic",
        "tip_voznje_id",
        "status",
        "posao_state_id",
        "narucitelj_naziv",
        "prijevoznik_naziv",
        "published_on",
        "rok_za_ponude",
        "iznos_hrk",
        "from_loc_name",
        "to_loc_name",
        "relacija_opis_skraceni",
        "from_ts",
        "to_ts",
        "alarm_ts",
        "alarm_active",
        "alarm_remark",
        "mark_new",
        "mediator_fullname",
        "view_pp",
        "view"
      ],
      hidden: ["id", "upit_id", "active", "panic", "alarm_ts", "alarm_active", "alarm_remark", "mark_new"]
    },
    voznje: {
      fields: [
        "id",
        "view_ag",
        "active",
        "panic",
        "agentura",
        "status",
        "tip_voznje_id",
        "view_pp",
        "vozilo_id",
        "posao_state_id",
        "narucitelj_naziv",
        "published_on",
        "rok_potvrda",
        "from_loc_name",
        "to_loc_name",
        "relacija_opis_skraceni",
        "from_ts",
        "to_ts",
        "alarm_ts",
        "alarm_active",
        "alarm_remark",
        "vozac_id",
        "iznos_hrk",
        "view"
      ],
      hidden: ["id", "active", "panic", "agentura", "alarm_ts", "alarm_active", "alarm_remark"]
    },
    ponude: {
      fields: ["id", "owner_bs_id", "from_ts", "cijena_ponudjena", "rok_ponude"]
    },
    pojedinacne_voznje: {
      fields: ["id", "from_ts", "to_ts", "vozilo_id", "vozac_id", "relacija_opis_skraceni"],
      hidden: ["id"]
    },
    interna_kalkulacija: {
      fields: ["id", "rbr", "relacija_opis_skraceni"],
      hidden: ["id"]
    },
    financijske_obaveze: {
      fields: ["id", "rbr", "relacija_opis_skraceni"],
      hidden: ["id"]
    }
  },
  fields: [
    coreFields.id,
    coreFields.active,
    alarmFields.alarm_ts,
    alarmFields.alarm_active,
    alarmFields.alarm_remark,
    commonFields.mark_new,
    {
      title: "Trjob Id",
      source: "trjobid",
      ttoken: "poslovi.trjobid",
      type: "numeric"
    },
    {
      title: "Panic",
      source: "panic",
      ttoken: "common.panic",
      type: "panic",
      items: {
        labels: ["common.ne", "common.da"],
        values: [false, true]
      },
      readonly: true,
      translate: true
    },
    {
      title: "Posao state",
      source: "posao_state_id",
      ttoken: "poslovi.posao_state_id",
      type: "iconStatus",
      values: [],
      sort: true,
      filter: true,
      items: {
        labels: [
          "ponuda.statuses.11",
          "trjob.status.priprema_upita",
          "trjob.status.objavljen_upit",
          "trjob.status.prihvacena_ponuda",
          "trjob.status.ugovoreno",
          "trjob.status.potvrdjeno",
          "trjob.status.usluga_u_tijeku",
          "trjob.status.usluga_zavrsena",
          "trjob.status.zatvoreno"
        ],
        values: [11, 20, 21, 22, 23, 24, 25, 26, 29],
        icons: ["hourglass_empty", "drafts", "send", "hourglass_empty", "thumb_up", "assignment_turned_in", "commute", "home", "archive"]
      },
      customSort: "elementProperty",
      customFilter: "elementProperty"
    },
    {
      title: "Status",
      source: "status",
      ttoken: "poslovi.stanjeTitle",
      type: "picker",
      items: {
        labels: [
          "poslovi.stanje.aktivno", "poslovi.stanje.arhivirano", "poslovi.stanje.storno"
        ],
        values: [1, 0, -1]
      },
      translate: true
    },
    {
      title: "Kupac",
      source: "kupac_id",
      ttoken: "poslovi.kupac_id",
      type: "picker",
      subModel: {
        apiPath: "prijevoznici/{prijevoznik_id}/komitenti?need_for=picker&select=kupac",
        format: "{label}"
      }
    },
    {
      title: "Narucitelj",
      source: "narucitelj_id",
      ttoken: "common.narucitelj",
      type: "picker",
      subModel: {
        apiPath: "business-subjects/?need_for=picker",
        format: "{label}"
      }
    },
    {
      title: "Kupac kontakt",
      source: "kupac_kontakt",
      ttoken: "poslovi.kupac_kontakt",
      type: "text"
    },
    {
      title: "Vozilo Id",
      source: "vozilo_id",
      ttoken: "poslovi.vozilo_id",
      type: "picker",
      subModel: {
        apiPath: "prijevoznici/{prijevoznik_id}/vozila?need_for=picker",
        format: "{label}, {registracija}"
      }
    },
    {
      title: "Vozilo",
      source: "vozilo_naziv",
      ttoken: "poslovi.vozilo_id",
      type: "text",
      link: {
        recordIdField: "vozilo_id",
        recordType: "vozilo"
      }
    },
    {
      title: "Oprema",
      source: "vozilo_oprema_ids",
      ttoken: "vozila.dodatna_oprema",
      type: "checkbox",
      icon: "build",
      row: true,
      subModel: {
        apiPath: "sifrarnici/oprema-vozila",
        format: "{label}"
      },
      translate: true,
      filter: true,
      multi: true,
      customSort: "multiSort"
    },
    {
      title: "Tip vozila",
      source: "vozilo_tip_id",
      ttoken: "vozila.tip",
      icon: "commute",
      type: "picker",
      subModel: {
        apiPath: "sifrarnici/tipovi-vozila",
        format: "{label}"
      },
      translate: true
    },
    {
      title: "Klasa vozila",
      source: "vozilo_klasa_id",
      ttoken: "vozila.klasa",
      icon: "directions_bus",
      type: "picker",
      subModel: {
        apiPath: "sifrarnici/klase-vozila",
        format: "{label}"
      },
      translate: true
    },
    {
      title: "Klasa motora",
      source: "klasa_motora_id",
      ttoken: "vozila.klasa_motora",
      icon: "class",
      type: "picker",
      subModel: {
        apiPath: "sifrarnici/klase-motora",
        format: "{label}"
      },
      translate: true
    },
    {
      title: "Vozac Id",
      source: "vozac_id",
      ttoken: "poslovi.vozac_id",
      type: "picker",
      subModel: {
        apiPath: "prijevoznici/{prijevoznik_id}/vozaci?need_for=picker",
        format: "{label}"
      }
    },
    {
      title: "Vozac Id",
      source: "vozac_ime_i_prezime",
      ttoken: "poslovi.vozac_id",
      type: "text",
      link: {
        recordIdField: "vozac_id",
        recordType: "vozac"
      }
    },
    {
      title: "Kooperant",
      source: "kooperant_id",
      ttoken: "poslovi.kooperant_id",
      type: "picker",
      subModel: {
        apiPath: "prijevoznici/{prijevoznik_id}/komitenti?need_for=picker&select=dobavljac",
        format: "{label}"
      }
    },
    {
      title: "Komitent",
      source: "kooperant_naziv",
      ttoken: "poslovi.kooperant_id",
      type: "text",
      link: {
        recordIdField: "kooperant_id",
        recordType: "komitent"
      }
    },
    {
      title: "Kooperant napomena",
      source: "kooperant_napomena",
      ttoken: "poslovi.kupac_kontakt",
      type: "text",
      bind: true,
      validation: {
        maxLength: 50
      }
    },
    {
      title: "Ugovorna relacija",
      source: "relacija_opis_skraceni",
      ttoken: "poslovi.opis",
      type: "multiline",
      maxrows: 5,
      width: "full",
      bind: true,
      filter: true,
      validation: {
        required: true,
        maxLength: 500
      }
    },
    {
      title: "Početak datum i vrijeme",
      source: "from_ts",
      ttoken: "poslovi.from_ts",
      type: "datetime",
      validation: {
        required: true
      }
    },
    {
      title: "Kraj datum i vrijeme",
      source: "to_ts",
      ttoken: "poslovi.to_ts",
      type: "datetime",
      validation: {
        required: true,
        isAfter: "from_ts",
        isAfterToken: "poslovi.from_ts"
      }
    },
    {
      title: "From location",
      source: "from_loc",
      ttoken: "common.from_loc",
      type: "GooglePlaces",
      external: true,
      validation: {
        required: true
      }
    },
    commonFields.from_loc_name,
    {
      title: "To location",
      source: "to_loc",
      ttoken: "common.to_loc",
      type: "GooglePlaces",
      external: true,
      validation: {
        required: true
      }
    },
    commonFields.to_loc_name,
    {
      title: "Ruta",
      source: "ruta",
      ttoken: "trazilica.ruta",
      type: "HEREroute"
    },
    {
      title: "Stajalista",
      source: "stajalista",
      ttoken: "trazilica.medjustajalista",
      type: "GooglePlacesViaControl"
    },
    {
      title: "Trajanje Voznje",
      source: "trajanje_voznje",
      ttoken: "trazilica.trajanje_voznje",
      type: "picker",
      formatting: "dhm",
      readonly: true,
      calculated: true,
      originalHeight: true
    },
    {
      title: "Neto Udaljenost Voznje",
      source: "neto_udaljenost_voznje",
      ttoken: "trazilica.neto_udaljenost_voznje",
      type: "picker",
      formatting: "km",
      readonly: true,
      calculated: true,
      originalHeight: true
    },
    {
      title: "Udaljenost Povratka Vozila",
      source: "udaljenost_povratka_vozila",
      ttoken: "trazilica.udaljenost_povratka_vozila",
      type: "picker",
      formatting: "km",
      readonly: true,
      calculated: true,
      originalHeight: true
    },
    {
      title: "Interna napomena_interna",
      source: "napomena_interna",
      ttoken: "poslovi.napomena_interna",
      type: "multiline",
      maxrows: 25,
      width: "full",
      nrows: 5,
      bind: true
    },
    {
      title: "Ponuđena cijena",
      source: "cijena_ponudjena",
      ttoken: "trjob.cijena_ponudjena",
      type: "currency"
    },
    {
      title: "Rok za zaprimanje ponuda",
      source: "rok_ponude",
      ttoken: "trjob.rok_ponude",
      type: "datetime"
    },
    {
      title: "Putnika",
      source: "vozilo_broj_putnika",
      ttoken: "poslovi.vozilo_broj_putnika",
      type: "numeric"
    },
    {
      title: "Vodica",
      source: "vozilo_broj_vodica",
      ttoken: "poslovi.vozilo_broj_vodica",
      type: "numeric"
    },
    {
      title: "Ukupno Neto KM",
      source: "km_calc_voznje",
      ttoken: "poslovi.uk_neto_km",
      type: "text",
      calculated: true,
      readonly: true,
      tooltip: "poslovi.uk_neto_km_tooltip"
    },
    {
      title: "Ukupno Neto Min",
      source: "min_calc_voznje",
      ttoken: "poslovi.uk_neto_min",
      type: "text",
      calculated: true,
      readonly: true,
      tooltip: "poslovi.uk_neto_min_tooltip"
    },
    {
      title: "Iznos Final Calculation",
      source: "iznos_fincalc",
      ttoken: "poslovi.uk_bruto",
      type: "currency",
      calculated: true,
      readonly: true,
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Kupac Tag",
      source: "kupac_tag",
      ttoken: "poslovi.kupac_tag",
      type: "text"
    },
    {
      title: "Kupac Kontakt",
      source: "kupac_kontakt",
      ttoken: "poslovi.kupac_kontakt",
      type: "text",
      validation: {
        maxLength: 50
      }
    },
    {
      title: "Narucitelj Kontakt",
      source: "narucitelj_kontakt",
      ttoken: "poslovi.kupac_kontakt",
      type: "text",
      validation: {
        maxLength: 50
      }
    },
    {
      title: "Ponuda Tag",
      source: "ponuda_tag",
      ttoken: "poslovi.ponuda_tag",
      type: "text",
      validation: {
        maxLength: 15
      }
    },
    {
      title: "Ponuda Expire",
      source: "ponuda_expire",
      ttoken: "poslovi.ponuda_expire",
      type: "text"
    },
    {
      title: "Ponuda Napomena",
      source: "ponuda_napomena",
      ttoken: "poslovi.ponuda_napomena",
      type: "multiline",
      validation: {
        required: true
      },
      rows: 5,
      width: "full"
    },
    {
      title: "Ponuda Datum",
      source: "ponuda_datum",
      ttoken: "poslovi.ponuda_datum",
      type: "text"
    },
    {
      title: "Ugovoreno Broj Sjedala",
      source: "ugovor_brsjedala",
      ttoken: "poslovi.ugovor_brsjedala",
      type: "text"
    },
    {
      title: "Ugovor Datum",
      source: "ugovor_datum",
      ttoken: "poslovi.ugovor_datum",
      type: "text"
    },
    {
      title: "Ugovor Datum",
      source: "ugovor_datum",
      ttoken: "poslovi.ugovor_datum",
      type: "text"
    },
    {
      title: "Ugovor Bruto",
      source: "ugovor_bruto_hrk",
      ttoken: "poslovi.ugovor_bruto",
      type: "text"
    },
    {
      title: "Faktura Tag",
      source: "oznaka_fakture",
      ttoken: "common.tag",
      type: "text",
      validation: {
        maxLength: 15,
        required: true
      }
    },
    {
      title: "Faktura Isporuka",
      source: "datum_isporuke",
      ttoken: "poslovi.faktura_isporuka",
      type: "date",
      validation: {
        required: true
      }
    },
    {
      title: "Faktura Izdavanje",
      source: "ts_izdavanja",
      ttoken: "poslovi.faktura_izdavanje",
      type: "datetime",
      validation: {
        required: true
      }
    },
    {
      title: "Faktura Neoporezivo",
      source: "calc_neoporezivo",
      ttoken: "poslovi.faktura_neoporezivo",
      type: "currency",
      calculated: true,
      readonly: true,
      tooltip: "poslovi.faktura_tooltip",
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Faktura Oporezivo",
      source: "calc_oporezivo",
      ttoken: "poslovi.faktura_oporezivo",
      type: "currency",
      calculated: true,
      readonly: true,
      tooltip: "poslovi.faktura_tooltip",
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Faktura Porez",
      source: "calc_porez",
      ttoken: "poslovi.faktura_porez",
      type: "currency",
      calculated: true,
      readonly: true,
      tooltip: "poslovi.faktura_tooltip",
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Faktura Bruto",
      source: "calc_ukupno",
      ttoken: "poslovi.faktura_bruto",
      type: "currency",
      calculated: true,
      readonly: true,
      tooltip: "poslovi.faktura_tooltip",
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Faktura Pozivna Broj",
      source: "poziv_na_broj",
      ttoken: "poslovi.faktura_pozivnabroj",
      type: "text",
      validation: {
        maxLength: 25,
        required: true
      }
    },
    {
      title: "Faktura Napomena",
      source: "faktura_napomena",
      ttoken: "poslovi.faktura_napomena",
      type: "text",
      width: "full",
      validation: {
        maxLength: 500
      }
    },
    {
      title: "Potvrda Napomena",
      source: "potvrda_napomena",
      ttoken: "poslovi.potvrda_napomena",
      type: "text"
    },
    {
      title: "Potvrda Start Lok",
      source: "vodic_ime_prezime",
      ttoken: "poslovi.vodic_ime_prezime",
      type: "text",
      validation: {
        maxLength: 99
      }
    },
    {
      title: "Iznos ukupno (ručno unesen)",
      source: "iznos_hrk",
      ttoken: "poslovi.uk_bruto_helper",
      type: "currency",
      filter: true,
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "rbr",
      source: "rbr",
      ttoken: "poslovi.rbr",
      type: "text"
    },
    {
      title: "owner_bs_id",
      source: "owner_bs_id",
      type: "text"
    },
    {
      title: "Naručitelj",
      source: "narucitelj_naziv",
      ttoken: "common.narucitelj",
      type: "text",
      readonly: true
    },
    {
      title: "Prijevoznik",
      source: "prijevoznik_naziv",
      ttoken: "common.prijevoznik",
      type: "text",
      readonly: true
    },
    {
      title: "Broj ponude",
      source: "broj_ponude",
      ttoken: "ponuda.broj_ponude",
      type: "text",
      validation: {
        required: true,
        maxLength: 20
      }
    },
    {
      title: "Datum ponude",
      source: "datum_ponude",
      ttoken: "ponuda.datum_ponude",
      type: "date",
      validation: {
        required: true
      }
    },
    {
      title: "Published on",
      source: "published_on",
      ttoken: "common.published_on",
      type: "datetime",
      readonly: true
    },
    {
      title: "Rok za Potvrdu",
      source: "rok_potvrda",
      ttoken: "ponuda.rok_za_potvrdu",
      type: "datetime"
    },
    {
      title: "Ukupno km",
      source: "km_calc_voznje",
      ttoken: "ponuda.km_calc_voznje",
      tooltip: "ponuda.helpers.km_calc_voznje",
      type: "wholenum",
      readonly: true,
      calculated: true,
      validation: {
        maxValue: 2147483647
      }
    },
    {
      title: "Ukupno min",
      source: "min_calc_voznje",
      ttoken: "ponuda.min_calc_voznje",
      tooltip: "ponuda.helpers.min_calc_voznje",
      type: "wholenum",
      readonly: true,
      calculated: true,
      validation: {
        maxValue: 2147483647
      }
    },
    {
      title: "Manual km",
      source: "km_manual",
      ttoken: "ponuda.km_manual",
      tooltip: "ponuda.helpers.km_manual",
      type: "wholenum",
      validation: {
        maxValue: 2147483647
      }
    },
    {
      title: "Manual min",
      source: "min_manual",
      ttoken: "ponuda.min_manual",
      tooltip: "ponuda.helpers.min_manual",
      type: "wholenum",
      validation: {
        maxValue: 2147483647
      }
    },
    {
      title: "Cijena izračunata fincalc",
      source: "iznos_fincalc",
      ttoken: "ponuda.iznos_fincalc",
      tooltip: "ponuda.helpers.iznos_fincalc",
      type: "currency",
      currency: "€",
      readonly: true,
      calculated: true,
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Cijena izračunata fincalc override",
      source: "iznos_fincalc_override",
      ttoken: "ponuda.iznos_fincalc_override",
      tooltip: "ponuda.helpers.iznos_fincalc_override",
      type: "currency",
      currency: "€",
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "",
      source: "eksterni_trosak_helper",
      ttoken: "",
      type: "picker",
      multi: true,
      subModel: {
        apiPath: "eksterni-troskovi/?need_for=picker",
        format: "'{label}' {iznos_bruto} €"
      }
    },
    {
      title: "Cijena bruto",
      source: "iznos_hrk",
      ttoken: "ponuda.cijena_bruto",
      type: "currency",
      currency: "€",
      readonly: true,
      validation: {
        maxValue: 99999999
      }
    },

    {
      title: "Tip voznje",
      source: "tip_voznje_id",
      ttoken: "common.tip_voznje_id",
      type: "picker",
      subModel: {
        apiPath: "sifrarnici/tipovi-voznje",
        format: "{label}"
      },
      translate: true
    },

    buttonFields.edit,
    buttonFields.view,
    buttonFields.view_pp,
    buttonFields.view_ag,
    {
      title: null,
      source: "summary",
      tooltip: "buttons.summary",
      ttoken: null,
      type: "button",
      icon: "search",
      action: "summary",
      padding: "none",
      sort: false
    },
    {
      title: "Ugovor ID",
      source: "ugovor_id",
      type: "numeric",
      idattr: true
    },
    {
      title: "Broj Sjedala",
      source: "broj_sjedala",
      ttoken: "ugovori.broj_sjedala",
      type: "numeric",
      validation: {
        required: true
      }
    },
    {
      title: "Datum ugovora",
      source: "datum_ugovora",
      ttoken: "ugovori.datum_ugovora",
      type: "date",
      validation: {
        required: true
      }
    },
    {
      title: "Iznos",
      source: "ugovor_iznos_hrk",
      ttoken: "ugovori.iznos",
      type: "currency",
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Rok za Ponude",
      source: "rok_za_ponude",
      ttoken: "upit.rok_ponude",
      type: "datetime",
      validation: {
        required: true
      }
    },
    {
      title: "Ukupno Bruto",
      source: "uk_bruto_hrk",
      ttoken: "faktura.uk_bruto",
      type: "currency",
      validation: {
        maxValue: 99999999
      }
    },
      {
      title: "Mediator",
      source: "mediator_id",
      ttoken: "common.mediator",
      type: "picker",
      subModel: {
        apiPath: "users/?select=mediators",
        format: "{label}"
      }
    },
    {
      title: "Mediator",
      source: "mediator_fullname",
      ttoken: "common.mediator",
      type: "text",
      readonly: true
    },
    {
      title: "Agentura napomena",
      source: "agentura_napomena",
      ttoken: "common.napomena",
      type: "multiline",
      validation: {
        required: true
      }
    },
    {
      title: "Agentura",
      source: "agentura",
      ttoken: "common.agentura",
      type: "agentura",
      items: {
        labels: ["common.ne", "common.da"],
        values: [false, true]
      },
      readonly: true,
      translate: true
    },
    {
      title: "Upit ID",
      source: "upit_id",
      ttoken: "common.upit_id",
      type: "text",
      readonly: true
    }
  ]
};
