import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import DialogActions from "@material-ui/core/DialogActions";

//CBC Icons
import PoslovniSubjektIcon from "Icons/PoslovniSubjektIcon";

//Custom Components
import withFormController from "Components/withFormController";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogActionButtonAdd from "UI/Dialog/ActionButtons/DialogActionButtonAdd";
import DialogActionButtonSave from "UI/Dialog/ActionButtons/DialogActionButtonSave";
import DialogActionButtonActivate from "UI/Dialog/ActionButtons/DialogActionButtonActivate";
import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItemDelete from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemDelete";
import DialogToolbarMenuItemActivate from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemActivate";
import DialogToolbarMenuItemDeactivate from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemDeactivate";
import DialogContext from "UI/DialogContext/DialogContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import GridContainer from "UI/Grid/GridContainer";
import FormContent from "Components/FormContent";
import GridItem from "UI/Grid/GridItem";
import { businessSubjectService } from "Services/businessSubjectService";
import DynamicDialog from "UI/Dialog/DynamicDialog";

function PoslovniSubjektDialog(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const loaderContext = useContext(LoaderContext);
  const { t } = useTranslation();

  const [dataChanged, setDataChanged] = useState(false);

  const { mode, form, record, validation, fields, subModels, recordId } = props; //HOC withFormController
  const { doInsert, doUpdate, doClose, doDelete } = props; //HOC withFormController
  const { onFieldChange, onClose } = props; //HOC withFormController

  const handleInsert = (evt) => {
    if (doInsert) {
      doInsert().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "insert", id: result.id });
          snackbarContext.showNotification("insert", "success");
        }
      }).catch((result) => {
        if (result.success === false && result.validationPass === false) {
          //do nothing
        } else {
          snackbarContext.showNotification(result.error, "error");
        }
      });
    }
  };

  const handleUpdate = (evt) => {
    if (doUpdate) {
      doUpdate().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "update" });
          snackbarContext.showNotification("update", "success");
        }
      }).catch((result) => {
        if (result.success === false && result.validationPass === false) {
          //do nothing
        } else {
          snackbarContext.showNotification(result.error, "error");
        }
      });
    }
  };

  const handleDelete = (evt) => {
    if (doDelete) {
      doDelete().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "delete" });
          snackbarContext.showNotification("delete", "success");
        }
      }).catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      });;
    }
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const handleActivate = (evt) => {
    businessSubjectService
      .activateBusinessSubject(recordId)
      .then((res) => {
        if (res.success) {
          close({ dataChanged: true, action: "activate" });
          snackbarContext.showNotification("notifications.activate", "success");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDeactivate = (evt) => {
    businessSubjectService
      .deactivateBusinessSubject(recordId)
      .then((res) => {
        if (res.success) {
          close({ dataChanged: true, action: "deactivate" });
          snackbarContext.showNotification("notifications.deactivate", "success");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleCloseDialog = () => {
    dialogContext.hideDialog(2);
  };

  const close = (result) => {
    if (onClose) {
      onClose(result);
    }

    dialogContext.hideDialog();
  };

  const commonProps = {
    recordId: recordId,
    record: record,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels
  };

  return (
    <DynamicDialog onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogAvatar ariaLabel="poslovni_subjekt" icon={<PoslovniSubjektIcon />} />
          <DialogToolbarHeading>{record.naziv ? record.naziv : t("titles.businessSubject")}</DialogToolbarHeading>
          <DialogToolbarFillContent />
          {mode === "update" || mode === "view" ? (
            <DialogToolbarMenuButton>
              {record.active ? (
                <DialogToolbarMenuItemDeactivate onClick={handleDeactivate} />
              ) : (
                <DialogToolbarMenuItemActivate onClick={handleActivate} />
              )}
              <DialogToolbarMenuItemDelete onClick={handleDelete} />
            </DialogToolbarMenuButton>
          ) : null}
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer direction="row" spacing={3}>
            <GridItem spacing={2} xs={12} sm={6}>
              <GridContainer direction="row">
                <FormContent
                  title={t("bs.sections.basic")}
                  fieldNames={["naziv", "type_id", "oib", "napomena"]}
                  {...commonProps}
                  columns={2}
                />
                <FormContent
                  title={t("bs.sections.adresa")}
                  fieldNames={["adr_drzava", "adr_grad", "adr_ulica", "adr_pbr"]}
                  {...commonProps}
                  columns={2}
                />
                <FormContent
                  title={t("bs.sections.contact")}
                  fieldNames={["contact_email", "contact_tel", "contact_mobile", "contact_person"]}
                  {...commonProps}
                  columns={2}
                />
              </GridContainer>
            </GridItem>
            <GridItem spacing={2} xs={12} sm={6}>
              <GridContainer direction="row">
              <FormContent
                  title={t("bs.sections.billing")}
                  fieldNames={["vat", "swift", "banka", "iban", "cbc_provizija", "dozvoli_avans", "faktura_rok_uplate_dani", "faktura_napomene"]}
                  {...commonProps}
                  columns={2}
                />
              </GridContainer>
            </GridItem>
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
        {mode === "insert" ? <DialogActionButtonAdd variant="contained" onClick={handleInsert} /> : null}
        {mode === "update" && record.active ? (
          <DialogActionButtonSave variant="contained" onClick={handleUpdate} />
        ) : null}
        {mode === "view" && !record.active ? (
          <DialogActionButtonActivate variant="contained" onClick={handleActivate} />
        ) : null}
      </DialogActions>
    </DynamicDialog>
  );
}

export default withFormController(PoslovniSubjektDialog);
