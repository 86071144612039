import React, { Fragment } from 'react';
import { useTranslation } from "react-i18next";

// Material-UI Custom Components
import Divider from "@material-ui/core/Divider";

// Custom Components
import SchedulerDrawerHeading from "Components/Scheduler/SchedulerDrawerHeading";
import SchedulerDrawerSwitch from "Components/Scheduler/SchedulerDrawerSwitch";


function SchedulerDrawerSwitches(props) {
	const { t } = useTranslation();
	const { title, state, setState, fieldIds } = props;

	return (
		<Fragment>
	    <SchedulerDrawerHeading>{title}</SchedulerDrawerHeading>
	    <Divider variant="fullWidth" />
	    {
	    	fieldIds.map((fId, i) => (
	    		<SchedulerDrawerSwitch 
	    			key={i}
	    			id={fId}
	    			checked={state[fId]}
	    			onChange={() => setState(fId)}
	    			label={t(`scheduler.${fId}`)}
	    		/>
	    	))
	    }
		</Fragment>
	);
} 

export default SchedulerDrawerSwitches;