import React, { Component } from 'react';
import LoaderContext from "./LoaderContext";

class MyComponent extends Component {
  constructor(props) {
    super(props);

    this.toggleLoading = this.toggleLoading.bind(this);

    this.state = {
      loading: 0,
      toggleLoading : this.toggleLoading
    }
  }

  toggleLoading(value) {
    // changed the logic from boolean to integer +/- because if we turn on the loader multiple times it doesn't consider turning it off the same number of times
    // it will turn off when the first one sets the value to false, although not everything was properly loaded
    this.setState(prevState => ({
      loading: prevState.loading + (value ? 1 : -1)
    }));
  }

  render() {
    return <LoaderContext.Provider value={this.state}>{this.props.children}</LoaderContext.Provider>
  }
}


export default MyComponent;
