import React, { useContext, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";

// Material-UI Icons
import ImageIcon from '@material-ui/icons/Image';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

// Material-UI Core Components
import { makeStyles } from "@material-ui/core/styles";
import { blue, grey, orange, purple } from "@material-ui/core/colors";
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Tooltip from "@material-ui/core/Tooltip";

// CUstom Components
import ChatMessageConfirm from "./ChatMessageConfirm";
import ChatMessageLastRow from "./ChatMessageLastRow";
import KomunikatorContext from "Components/KomunikatorContext/KomunikatorContext";
import { komunikatorService as KS } from "Services/komunikatorService";
import { sifrarnici as S } from "Lib/sifrarnici";

function ChatMessage(props) {
  const { message, noData, tab, channelId, isReadOnly } = props;
  const { t } = useTranslation();
  const classes = tab ? tabStyles() : contextStyles();
  const KC = useContext(KomunikatorContext);

  const msgBody = message.body && message.body.length ?
    message.body.split('\n')
    : [];

  // const user = KC.users.find(u => u.value === message.created_by);
  // console.log(message)
  const userLabel = message.created_by_full_name;
  const userInitials = message.created_by_initials;
  const color = getBackgroundColor(message.created_by_bs_type);

  const file = typeof message.file  === "string" ? JSON.parse(message.file) : message.file;
  const img = typeof message.img === "string" ? JSON.parse(message.img) : message.img;


  function getBackgroundColor (business_subject_type) {
    switch (business_subject_type) {
      case 10:
        return blue[800];
      case 20:
        return purple[600];
      case 90:
        return orange[800];
      default:
        return grey[400];
    }
  };

  function downloadBase64File(base64Data, meta) {
    // console.log(meta, base64Data)
    const { name, mime_type } = meta;
    const linkSource = `data:${mime_type};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = name;
    downloadLink.click();
  }

  const handleFileClick = (file) => {
    const f = typeof file === "string" ? JSON.parse(file) : file;
    KS.getFile(f.uuid).then(resp => {
      if (resp && resp.data) {
        downloadBase64File(resp.data.file, f)
      }
    })

  }
  const handleImgClick = (file) => {
    const f = typeof file === "string" ? JSON.parse(file) : file;
    KS.getFile(f.uuid).then(resp => {
      if (resp && resp.success && resp.data) {
        downloadBase64File(resp.data.file, f);
      }
    })
  }

  return (
    <div className={`${classes.parent} ${message.owner ? classes.pOwner : classes.pNonOwner}`}>
      {
        !message.owner && userLabel ?
          <Tooltip title={userLabel}>
            <Avatar className={classes.avatar} color="primary" style={{backgroundColor: color}}>{userInitials}</Avatar>
          </Tooltip>
          : null
      }
      <div className={`${classes.base} ${message.owner ? classes.owner : classes.nonOwner}`}>
        {
          msgBody.map((m, i) => (
            i === msgBody.length - 1 ?
            <ChatMessageLastRow
              key={i}
              body={m}
              time={message.created_on}
              deliveryType={message.message_delivery_type_id}
              owner={Boolean(message.owner)}
            />
            : <div key={i}>{m}&nbsp;</div>
          ))
        }
        {
          message.message_type_id === S.msg_types.confirmation ?
            <ChatMessageConfirm message={message} channelId={channelId} isReadOnly={isReadOnly} />
            : null
        }
        {
          message.message_type_id === S.msg_types.file ? [
            file && file.name &&
              <Chip
                clickable
                onClick={() => handleFileClick(message.file)}
                key="file"
                color={message.owner ? "secondary" : "primary"}
                size="small"
                component="span"
                label={file.name.slice(0, 20)}
                icon={<InsertDriveFileIcon fontSize="small"/>}
              />,
            img && img.name &&
              <Chip
                clickable
                onClick={() => handleImgClick(img)}
                key="img"
                color={message.owner ? "secondary" : "primary"}
                size="small"
                component="span"
                icon={<ImageIcon fontSize="small"/>}
                label={img.name.slice(0, 20)}
              />
            ]
           : null
        }
      </div>

    </div>
  );
}

const h = 20;
const contextStyles = makeStyles((theme) => ({
  parent: {
    maxWidth: "80%",
    // minWidth: "20%",
  },
  pOwner: {
    alignSelf: "flex-end",
    color: "#eee"
  },
  pNonOwner: {
    alignSelf: "flex-start",
  },
  base: {
    display: "inline-block",
    width: "fit-content",
    padding: theme.spacing(0.75) + "px",
    margin: "2px 0",
    borderRadius: theme.spacing(1.5) + "px",
    // color: "#000",
    overflowWrap: "anywhere"
  },
  owner: {
    backgroundColor: theme.palette.secondary.main,
    color: "#eee"
  },
  nonOwner: {
    backgroundColor: theme.palette.grey[500]
  },
  cardHeader: {
    borderBottom: "1px solid " + theme.palette.secondary.main,
    padding: theme.spacing(1)
  },
  cardContent: {
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(1) + "px !important"
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    display: "inline-block",
    textAlign: "center",
    fontSize: "16px",
    width : "16px",
    height : "16px"
  }
}))

const tabStyles = makeStyles((theme) => ({
  parent: {
    display: "flex",
    flexDirection: "row",
    maxWidth: "80%",
    // minWidth: "10%",
  },
  pOwner: {
    alignSelf: "flex-end",
    color: "#eee"
  },
  pNonOwner: {
    alignSelf: "flex-start",
  },
  base: {
    display: "inline-block",
    width: "fit-content",
    padding: theme.spacing(0.75) + "px",
    margin: "2px 0",
    borderRadius: theme.spacing(1.5) + "px",
    // color: "#000",
    overflowWrap: "anywhere"
  },
  owner: {
    // maxWidth: "90%",
    backgroundColor: theme.palette.secondary.main,
    color: "#eee"
  },
  nonOwner: {
    backgroundColor: theme.palette.grey[500]
  },
  cardHeader: {
    borderBottom: "1px solid " + theme.palette.secondary.main,
    padding: theme.spacing(1)
  },
  cardContent: {
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(1) + "px !important"
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  avatar: {
    //backgroundColor: theme.palette.secondary.main,
    // display: "inline-block",
    // textAlign: "center",
    fontSize: "14px",
    width : "24px",
    height : "24px"
  }
}));

export default ChatMessage;
