import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";

//CBC Icons
import KorisnikAgencijskiIcon from "Icons/KorisnikAgencijskiIcon";

//Custom Components
import withFormController from "Components/withFormController";
import DynamicDialog from "UI/Dialog/DynamicDialog";
import DraggableDialog from "UI/Dialog/DraggableDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButton from "UI/Dialog/DialogActionButton";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogActionButtonAdd from "UI/Dialog/ActionButtons/DialogActionButtonAdd";
import DialogActionButtonSave from "UI/Dialog/ActionButtons/DialogActionButtonSave";
import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItem from "UI/Dialog/DialogToolbarMenuItem";
import DialogContext from "UI/DialogContext/DialogContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import UserContext from "Components/UserContext/UserContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import GridContainer from "UI/Grid/GridContainer";
import FormContent from "Components/FormContent";
import GridItem from "UI/Grid/GridItem";
import { Permissions } from "Lib/permissions";

import model from "Models/a_korisnici";
import dataController from "Lib/dataController";

function AgencijeKorisnikDialog(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const loaderContext = useContext(LoaderContext);
  const userContext = useContext(UserContext);
  const { t } = useTranslation();

  const USERROLE_CUSTOM = 990;
  const fUserRole = "role_id";
  const fPermissions = "permissions";

  const [dataChanged, setDataChanged] = useState(false);

  const { mode, form, record, validation, fields, subModels, recordId } = props; //HOC withFormController
  const { doInsert, doUpdate, doClose, doValidate } = props; //HOC withFormController
  const { onFieldChange, onClose } = props; //HOC withFormController

  const dc = new dataController(model);

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      // if (source === fUserRole && value !== null) {
      //   const subModelRecords = subModels.role_id.records;
      //   const record = subModelRecords.find(record => record.id === value.value);
      //   onFieldChange(record.app_permissions, fPermissions)
      //   onFieldChange(value, fUserRole)
      //   return;
      // }
      // if (source === fPermissions && value !== undefined && value !== null) {
      //   const { record } = props;
      //   const prevPermissionsLen = record && record.hasOwnProperty(fPermissions) && record[fPermissions] ? record[fPermissions].length : 0;
      //   const currPermissionsLen = Array.isArray(value) ? value.length : 1;
      //   const checked = currPermissionsLen > prevPermissionsLen;
      //   const intValue = Array.isArray(value) ? value.reduce((acc, curr) => ((curr.value || curr) + acc), 0) : value;
      // }
    }
    onFieldChange(value, source);
  };

  const handleInsert = (evt) => {
    if (doValidate) {
      const isValid = doValidate();
      if (isValid) {
        const path = "agencije/" + userContext.subjectId + "/korisnici";
        dc.CustomPostAction(path, record).then((result) => {
          if (result.success) {
            close({ dataChanged: true, action: "insert" });
            snackbarContext.showNotification("insert", "success");
          }
        }).catch((result) => {
          snackbarContext.showNotification(result.error, "error");
        });
      } else {
      }
    }
  };

  const handleUpdate = (evt) => {
    if (doValidate) {
      const isValid = doValidate();
      if (isValid) {
        const path = "agencije/" + userContext.subjectId + "/korisnici/" + record.id;
        dc.CustomPutAction(path, record).then((result) => {
          if (result.success) {
            close({ dataChanged: true, action: "update" });
            snackbarContext.showNotification("update", "success");
          }
        }).catch((result) => {
          snackbarContext.showNotification(result.error, "error");
        });
      }
    }
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const close = (result) => {
    if (result.dataChanged || dataChanged) {
      onClose({ dataChanged: true, action: result.action });
    }

    dialogContext.hideDialog();
  };

  const editFields = ["username", "first_name", "last_name", "email", "user_role"];
  const insertFields = ["username", "password", "password_confirm", "first_name", "last_name", "email", "user_role"];

  const commonProps = {
    recordId: recordId,
    record: record,
    validation: validation,
    onFieldChange: handleFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels,
    columns: 1
  };

  return (
    <DynamicDialog maxWidth={"sm"} onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogAvatar ariaLabel="user" icon={<KorisnikAgencijskiIcon />} />
          <DialogToolbarHeading>
            {mode == "insert" ? t("titles.user_new") : t("titles.agencijski_korisnik")}
          </DialogToolbarHeading>
          <DialogToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer>
            <FormContent fieldNames={mode === "insert" ? insertFields : editFields} {...commonProps} />
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
        {mode === "insert" ? <DialogActionButtonAdd variant="contained" onClick={handleInsert} /> : null}
        {mode === "update" ? <DialogActionButtonSave variant="contained" onClick={handleUpdate} /> : null}
      </DialogActions>
    </DynamicDialog>
  );
}

export default withFormController(AgencijeKorisnikDialog);
