import React from 'react';
import { useTranslation } from "react-i18next";

import { Typography } from "@material-ui/core";

//Custom components
import GridContainer from "UI/Grid/GridContainer";
import FormContent from "Components/FormContent";
import GridItem from "UI/Grid/GridItem";


function TabPonuda(props) {
  const { record, validation, fields, onFieldChange, mode, subModels } = props;

  const {t} = useTranslation();

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    mode: record["posao_state_id"] > 20 ? "view" : mode,
    subModels: subModels
  };

  return (
      <GridContainer>
        <FormContent
          fieldNames={["broj_ponude", "iznos_hrk"]}
          {...commonProps}
          columns={2}
        />
        <FormContent {...commonProps} fieldNames={["datum_ponude", "rok_potvrda"]} columns={2} />
        <FormContent
            fieldNames={["ponuda_napomena"]}
            {...commonProps}
            columns={1}
        />
      </GridContainer>
  );
}

export default TabPonuda;

