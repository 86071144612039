import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//Material-UI Icons
import AppsIcon from "@material-ui/icons/Apps";

//Custom Components
import withRedirect from "Components/withRedirect";
import HeaderMenuButton from "UI/Header/HeaderMenuButton";
import HeaderMenuItem from "UI/Header/HeaderMenuItem";
import { Permissions } from "Lib/permissions";

function ModuleMenu(props) {
  const { t } = useTranslation();
  const { redirect } = props; //HOC withRedirect

  const theme = useTheme();

  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  const handleSelectAdministration = () => {
    redirect("/admin");
  };

  const handleSelectPrijevoznici = () => {
    redirect("/p");
  };

  const handleSelectAgencije = () => {
    redirect("/a")
  }

  return (
    <HeaderMenuButton icon={<AppsIcon />} showExpandIcon={mdUp ? false : false} desc="modules">
      <HeaderMenuItem label={t("menu.administration")} onClick={handleSelectAdministration} needPermission={[Permissions.AdminModule]} />
      <HeaderMenuItem label={t("menu.prijevoznici")} onClick={handleSelectPrijevoznici} needPermission={[Permissions.PrijevozniciSve, Permissions.PrijevozniciOsnovno]} />
      <HeaderMenuItem label={t("menu.agencije")} onClick={handleSelectAgencije} />
    </HeaderMenuButton>
  );
}

export default withRedirect(ModuleMenu);
