import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Box from "@material-ui/core/Box";

//Custom Components
import { GridContainer, GridItem } from "UI/Grid";
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import UserContext from "Components/UserContext/UserContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import FormTabPanel from "UI/Form/FormTabPanel";
import ModelTable from "UI/Table/ModelTable";
import dataController from "Lib/dataController";
import modelFakture from "Models/fakture";
import finCalcmodel from "Models/fincalc";
import UpitFakturaDialog from "Views/Upiti/Dialogs/UpitFakturaDialog";
import { fakturaPrint } from "Lib/print/fakturaPrint";

function TabPanelFakture(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const loaderContext = useContext(LoaderContext);
  const userContext = useContext(UserContext);
  const { t } = useTranslation();

  const [recordsFakture, setRecordsFakture] = useState([]);
  const [recordsStavke, setRecordsStavke] = useState([]);

  const { currentTab, tabIndex, record, recordId, mode } = props;

  const dcFakture = new dataController(modelFakture, `upiti/${recordId}/fakture`);
  const dcFakturaStavke = new dataController(finCalcmodel);

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dcFakture
      .GetData()
      .then((resp) => {
        if (resp.success) {
          if (resp.data) {
            setRecordsFakture(resp.data);
          }
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const refreshStavke = (id) => {
    loaderContext.toggleLoading(true);
    const dcFakturaStavkeSpecific = new dataController(finCalcmodel, `upiti/${recordId}/fakture/${id}/stavke`);
    dcFakturaStavkeSpecific
      .GetData()
      .then((resp) => {
        if (resp && resp.data && resp.success) {
          setRecordsStavke(resp.data);
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const handleSelectFaktura = (id) => {
    if (id) {
      refreshStavke(id);
    } else {
      setRecordsStavke([]);
    }
  };

  useEffect(() => {
    if (recordId) {
      refreshRecords();
    }
  }, [recordId]);

  const handleRowActionFaktura = function (action, id) {
    if (action === "view") {
      dialogContext.showDialog(UpitFakturaDialog, {
        dc: dcFakture,
        mode: "view",
        form: "pp",
        onClose: handleDialogClose,
        allowSkica: false,
        recordId: id,
        upitId: recordId,
        stavkeApiPath: `upiti/${recordId}/fakture/${id}/stavke`,
        subModelDefaultValues: {
          posao_id: record.id
        }
      });
    } else if (action === "print") {
      const rec = {
        id: id,
        upit_id: recordId
      }
      fakturaPrint.handleAgencijaPrint(rec, t);
    }
  };

  const handleRowActionStavka = function (action, id) {};

  const allowRowActionFaktura = function (action, id) {
    return true;
  };

  const handleDialogClose = (result) => {
    dialogContext.hideDialog();
  };

  return (
    <FormTabPanel value={currentTab} index={tabIndex}>
      <Box m={2}>
        <GridContainer>
          <GridItem xs={12}>
            <ModelTable
              title={t("titles.fakture")}
              dc={dcFakture}
              viewName={"admin"}
              allowExport={true}
              allowFilter={true}
              allowAdd={false}
              allowSelection="one"
              onRowClick={handleSelectFaktura}
              records={Array.isArray(recordsFakture) ? recordsFakture : []}
              onRowAction={handleRowActionFaktura}
              allowRowAction={allowRowActionFaktura}
              subModelDefaultValues={{
                prijevoznik_id: userContext.subjectId
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <ModelTable
              title={t("titles.faktura_stavke")}
              dc={dcFakturaStavke}
              viewName={"readonly_noview"}
              allowExport={true}
              allowFilter={true}
              allowAdd={false}
              allowSelection="none"
              records={Array.isArray(recordsStavke) ? recordsStavke : []}
              onRowAction={handleRowActionStavka}
            />
          </GridItem>
        </GridContainer>
      </Box>
    </FormTabPanel>
  );
}

TabPanelFakture.defaultProps = {
  mode: "update"
};

TabPanelFakture.propTypes = {
  currentTab: PropTypes.number,
  tabIndex: PropTypes.number,
  dc: PropTypes.object,
  recordId: PropTypes.number,
  record: PropTypes.object,
  validation: PropTypes.object,
  onFieldChange: PropTypes.func,
  fields: PropTypes.array,
  mode: PropTypes.oneOf(["view", "update"]),
  onConfirm: PropTypes.func
};

export default TabPanelFakture;
