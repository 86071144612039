import React from "react";

//Material-UI Core components
import makeStyles from "@material-ui/styles/makeStyles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("xl")]: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    }
  }
}));

function ResponsiveBox(props) {
  const { fillHeight, ...otherProps } = props;
  const classes = useStyles();

  return <Box {...otherProps} className={classes.root} height={fillHeight ? "calc(100vh - 68px)" : undefined} />;
}

export default ResponsiveBox;
