import React, { useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";

//Material UI
//Custom components
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import { GridContainer, GridItem } from "UI/Grid";
import FormContent from "Components/FormContent";
import SaveButton from "UI/Buttons/SaveButton";
import withFormController from "Components/withFormController";

const a_evaluacijaSteps = [
  {
    label: "evaluacija.grupe",
    fieldNames: ["grupa_cistoca_i_urednost", "grupa_tocnost", "grupa_nagrada"]
  },
  {
    label: "evaluacija.a_osoblje",
    fieldNames: ["osoblje_informacije", "osoblje_organizacija", "osoblje_dostupnost"]
  },
  {
    label: "evaluacija.preliminarno",
    fieldNames: ["prateci_priprema", "prateci_komunikacija", "prateci_agilnost"]
  }
];

const p_evaluacijaSteps = [
  {
    label: "evaluacija.vozilo",
    fieldNames: ["vozilo_ispravnost", "vozilo_udobnost", "vozilo_cistoca", "vozilo_oprema"]
  },
  {
    label: "evaluacija.p_osoblje",
    fieldNames: ["osoblje_komunikacija", "osoblje_urednost", "osoblje_priprema"]
  },
  {
    label: "evaluacija.prijevoznik",
    fieldNames: ["dogovor_komunikacija", "brzina_reakcije"]
  },
  {
    label: "evaluacija.value_for_money",
    fieldNames: ["vrijednost_za_novac"]
  }
];

function TabEvaluacije(props) {
  const snackbarContext = useContext(SnackbarContext);
  const loaderContext = useContext(LoaderContext);

  const { t } = useTranslation();

  const { currentTab, tabValue } = props;
  const { isPosao, hasEvaluacija } = props;
  const { dc, record, validation, fields, onFieldChange, mode, refreshParent, isPanic } = props;

  const steps = isPosao ? a_evaluacijaSteps : p_evaluacijaSteps;

  const handleTotalEvaluation = (value, source) => {
    if (onFieldChange) {
      onFieldChange(value, source);
      for (let x = 0; x < steps.length; x++) {
        for (let y = 0; y < steps[x].fieldNames.length; y++) {
          onFieldChange(value, steps[x].fieldNames[y]);
        }
      }
    }
  };

  const handleSubTotalEvaluation = (value, source) => {
    if (onFieldChange && !hasEvaluacija) {
      onFieldChange(value ? value : 5, source);
      let sum = 0;
      let stepLength = 0;

      steps.forEach((step) => {
        stepLength += step.fieldNames.length;
      });

      for (let x = 0; x < steps.length; x++) {
        for (let y = 0; y < steps[x].fieldNames.length; y++) {
          sum += record[steps[x].fieldNames[y]] ? record[steps[x].fieldNames[y]] : 5;
        }
      }
      const latestValue = sum - record[source] + (value ? value : 5);
      const totalEval = Math.floor((latestValue / stepLength) / 0.5) * 0.5;
      onFieldChange(totalEval, "ocjena_ukupno");
    }
  };

  const handleInsertEvaluation = () => {
    loaderContext.toggleLoading(true);
    dc.InsertRecord(record).then((resp) => {
      if (resp.success) {
        snackbarContext.showNotification("notifications.evaluation_insert", "success");
        if(refreshParent){
          refreshParent();
        }
      } else {
        snackbarContext.showNotification(resp.error.detail.message, "error");
      }
    }).catch((err) => {
      console.error(err);
    }).finally(() => {
      loaderContext.toggleLoading(false);
    });
  };

  return (
    <GridContainer direction="row" spacing={1} justify="flex-start">
      <FormContent
        record={record}
        validation={validation}
        onFieldChange={handleTotalEvaluation}
        fields={fields}
        fieldNames={["ocjena_ukupno"]}
        mode={isPanic ? "view" : mode}
        columns={1}
      />
      {
        steps.map((eStep, i) => (
          <Fragment key={"eval_step" + i}>
            <FormContent
              title={t(eStep.label)}
              record={record}
              validation={validation}
              onFieldChange={handleSubTotalEvaluation}
              fields={fields}
              fieldNames={eStep.fieldNames}
              mode={isPanic ? "view" : mode}
              columns={3}
            />
          </Fragment>
        ))
      }
      {hasEvaluacija || isPanic ? null : (
        <GridItem sm={12} md={12}>
          <SaveButton
            text={t("buttons.save_eval")}
            onClick={() => handleInsertEvaluation()}
          />
        </GridItem>
      )}
    </GridContainer>
  );
}

export default withFormController(TabEvaluacije);
