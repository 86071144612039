import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

// Material UI Core
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Icon from "@material-ui/core/Icon";
import SvgIcon from "@material-ui/core/SvgIcon";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

// Material UI Icons
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

//CBC Icons
import KorisnikIcon from "Icons/KorisnikIcon";
import KorisnikAgencijskiIcon from "Icons/KorisnikAgencijskiIcon";
import KorisnikPrijevoznickiIcon from "Icons/KorisnikPrijevoznickiIcon";
import PoslovniSubjektIcon from "Icons/PoslovniSubjektIcon";
import PoslovniSubjektAgencijaIcon from "Icons/PoslovniSubjektAgencijaIcon";
import PoslovniSubjektPrijevoznikIcon from "Icons/PoslovniSubjektPrijevoznikIcon";
import VoziloIcon from "Icons/VoziloIcon";
import VoznjaIcon from "Icons/VoznjaIcon";
import VozacIcon from "Icons/VozacIcon";

// Custom
import UserContext from "Components/UserContext/UserContext";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(6) + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  },
  activeLink: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.light,
    "&:hover": {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.primary.main
    }
  }
}));

const useListStyle = makeStyles((theme) => ({
  root: {
    paddingTop: "4px",
    paddingBottom: "4px"
  },
  gutters: {
    paddingLeft: "14px",
    paddingRight: "12px"
  }
}));

export default function Sidebar(props) {
  const classes = useStyles();
  const muiListItem = useListStyle();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { menu, sidebarOpen, toggleSidebar } = props;
  const userContext = useContext(UserContext);
  const { t } = useTranslation();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const genSvgIcon = (svgicon) => {
    const iconProps = {
      fontSize: "small",
      color: "secondary"
    };
    switch (svgicon) {
      case "korisnik":
        return <KorisnikIcon {...iconProps} />;
      case "korisnik-prijevoznicki":
        return <KorisnikPrijevoznickiIcon {...iconProps} />;
      case "korisnik-agencijski":
        return <KorisnikAgencijskiIcon {...iconProps} />;
      case "poslovni-subjekt":
        return <PoslovniSubjektIcon {...iconProps} />;
      case "agencija":
        return <PoslovniSubjektAgencijaIcon {...iconProps} />;
      case "prijevoznik":
        return <PoslovniSubjektPrijevoznikIcon {...iconProps} />;
      case "vozilo":
        return <VoziloIcon {...iconProps} />;
      case "vozac":
        return <VozacIcon {...iconProps} />;
      case "voznja":
        return <VoznjaIcon {...iconProps} />;
      default:
        return null;
    }
  };

  return (
    <aside id="nav-drawer">
      <Drawer
        variant="permanent"
        className={`${sidebarOpen ? classes.drawerOpen : classes.drawerClose} ${classes.drawer}`}
        classes={{
          paper: sidebarOpen ? classes.drawerOpen : classes.drawerClose
        }}
      >
        <div className={classes.toolbar} style={{ minHeight: "54px" }}>
          <IconButton onClick={toggleSidebar}>{!sidebarOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
        </div>
        {
          //sidebarOpen ?
          //<Divider />
          //: null
        }
        <List disablePadding>
          {menu
            .filter((x) => !x.hasOwnProperty("needPermission") || userContext.hasAnyPermission(x.needPermission))
            .map((m, i) => {
              if (m.title) {
                return sidebarOpen ? (
                    <Typography key={i} align="center" style={{ marginTop: "5px", fontWeight: "bold" }}>{t(m.title)}</Typography>
                ) : null;
              } else if (m.divider)
              {
                return <Divider key={i} />;
              } else {
                return (
                  <ListItem
                    key={i}
                    button
                    classes={{
                      root: muiListItem.root,
                      gutters: muiListItem.gutters
                    }}
                    className={classes.link}
                    component={NavLink}
                    to={m.path}
                    exact
                    isActive={(match, location) => {
                      if (!match) return false;
                      //console.log(match.url === location.pathname, match.url, location.pathname);
                      return match.url === location.pathname;
                    }}
                    activeClassName={classes.activeLink}
                  >
                    <ListItemIcon>
                      {m.icon ? (
                        <Icon fontSize="small" color="secondary">
                          {m.icon}
                        </Icon>
                      ) : null}
                      {m.svgicon ? genSvgIcon(m.svgicon) : null}
                    </ListItemIcon>
                    <ListItemText primary={t(m.ttoken)} />
                  </ListItem>
                );
              }
            })}
            </List>
              <Divider />
            </Drawer>
            </aside>
            );
          }
