import { coreFields } from "./helpers/coreFields";
import { commonFields } from "./helpers/commonFields";
import { addressFields } from "./helpers/addressFields";
import { buttonFields } from "./helpers/buttonFields";
import { buttonFieldsFav } from "./helpers/buttonFieldsFav";

export default {
  title: "Users",
  exportFileName: "Poslovni_subjekti",
  apiPath: "business-subjects/",
  forms: {
    insert: {
      fields: [
        "naziv",
        "type_id",
        "oib",
        "napomena",
        "vat",
        "swift",
        "banka",
        "iban",
        "cbc_provizija",
        "dozvoli_avans",
        "faktura_rok_uplate_dani",
        "faktura_napomene",
        "adr_ulica",
        "adr_grad",
        "adr_pbr",
        "adr_drzava",
        "contact_email",
        "contact_tel",
        "contact_mobile",
        "contact_person"
      ]
    },
    update: {
      fields: [
        "naziv",
        "type_id",
        "oib",
        "napomena",
        "vat",
        "swift",
        "banka",
        "iban",
        "cbc_provizija",
        "dozvoli_avans",
        "faktura_rok_uplate_dani",
        "faktura_napomene",
        "adr_ulica",
        "adr_grad",
        "adr_pbr",
        "adr_drzava",
        "contact_email",
        "contact_tel",
        "contact_mobile",
        "contact_person",
        "public_naziv",
        "public_adr_full",
        "public_email",
        "public_tel",
        "public_mobile",
        "public_web",
        "public_desc_hr",
        "public_desc_en"
      ]
    },
    view: {
      fields: [
        { source: "naziv", readonly: true },
        { source: "oib", readonly: true },
        { source: "vat", readonly: true },
        { source: "adr_ulica", readonly: true },
        { source: "adr_grad", readonly: true },
        { source: "adr_pbr", readonly: true },
        { source: "adr_drzava", readonly: true },
        { source: "napomena", readonly: true },
        { source: "type_id", readonly: true },
        "dozvoli_avans"
      ]
    },
    public_bs: {
      fields: [
        "public_adr_full",
        "public_email",
        "public_tel",
        "public_mobile",
        "public_web",
        "public_desc_hr",
        "public_desc_en"
      ]
    }
  },
  listViews: {
    default: {
      fields: [
        "id",
        "naziv",
        "type_id",
        "contact_person",
        "contact_tel",
        "contact_mobile",
        "contact_email",
        "oib",
        "vat",
        "adr_ulica",
        "adr_grad",
        "adr_pbr",
        "adr_drzava",
        "users_cnt",
        "vozila_cnt",
        {
          source: "active",
          ttoken: "users.status_aktivan",
          items: {
            labels: ["common.ne", "common.da"],
            values: [false, true]
          },
          translate: true
        },
        "favorite_a",
        "edit"
      ],
      hidden: ["contact_tel", "contact_email", "vat", "adr_ulica", "adr_pbr"]
    },
  financije: {
    fields: ["id", "naziv", "oib", "adr_grad", "bs_potrazuje", "bs_duguje", "saldo", "view"]
  },
    exportExcel: {
      fields: [
        { source: "naziv", width: 25 },
        { source: "oib", width: 25 },
        { source: "vat", width: 25 },
        { source: "adr_ulica", width: 25 },
        { source: "adr_grad", width: 25 },
        { source: "adr_pbr", width: 25 },
        { source: "adr_drzava", width: 25 }
      ]
    }
  },
  fields: [
    coreFields.id,
    coreFields.active,
    {
      title: "Tip Poslovni Subjekt",
      source: "type_id",
      ttoken: "bs.type_id",
      type: "picker",
      subModel: {
        apiPath: "business-subject-types/",
        format: "{label}"
      },
      validation: {
        required: true
      },
      translate: true,
      filter: true
    },
    {
      title: "Naziv",
      source: "naziv",
      ttoken: "bs.naziv",
      type: "text",
      filter: true,
      validation: {
        required: true,
        maxLength: 100
      }
    },
    commonFields.oib,
    commonFields.vat,
    addressFields.adr_ulica,
    addressFields.adr_pbr,
    addressFields.adr_grad,
    addressFields.adr_drzava,
    {
      title: "Napomena",
      source: "napomena",
      ttoken: "bs.napomena",
      type: "multiline",
      rows: 3,
      validation: {
        required: false,
        maxLength: 2000
      },
      width: "full"
    },
    {
      title: "Dozvoli avans",
      source: "dozvoli_avans",
      ttoken: "bs.dozvoli_avans",
      type: "boolean",
      items: {
        labels: ["common.ne", "common.da"],
        values: [false, true],
        default: false
      },
      translate: true
    },

    {
      title: "Kontakt Email",
      source: "contact_email",
      ttoken: "bs.contact_email",
      type: "text",
      filter: true,
      validation: {
        maxLength: 100
      }
    },
    {
      title: "Kontakt Telefon",
      source: "contact_tel",
      ttoken: "bs.contact_tel",
      type: "text",
      filter: true,
      validation: {
        maxLength: 20
      }
    },
    {
      title: "Kontakt Mobile",
      source: "contact_mobile",
      ttoken: "bs.contact_mobile",
      type: "text",
      filter: true,
      validation: {
        maxLength: 20
      }
    },
    {
      title: "Kontakt Osoba",
      source: "contact_person",
      ttoken: "bs.contact_person",
      type: "text",
      filter: true,
      validation: {
        maxLength: 100
      }
    },
    {
      title: "SWIFT",
      source: "swift",
      ttoken: "bs.swift",
      type: "text",
      filter: true,
      validation: {
        required: true,
        maxLength: 20
      }
    },
    {
      title: "Banka",
      source: "banka",
      ttoken: "bs.banka",
      type: "text",
      filter: true,
      validation: {
        required: true,
        maxLength: 100
      }
    },
    {
      title: "IBAN",
      source: "iban",
      ttoken: "bs.iban",
      type: "text",
      filter: true,
      validation: {
        required: true,
        maxLength: 34
      }
    },
    {
      title: "CBC provizija",
      source: "cbc_provizija",
      ttoken: "bs.cbc_provizija",
      type: "numeric",
      validation: {
        required: true,
        min: 0,
        max: 99.99
      }
    },
    {
      title: "Faktura rok uplate (dani)",
      source: "faktura_rok_uplate_dani",
      ttoken: "bs.faktura_rok_uplate_dani",
      type: "wholenum",
      validation: {
        required: true,
        min: 0,
        max: 365
      }
    },
    {
      title: "Faktura Napomene",
      source: "faktura_napomene",
      ttoken: "bs.faktura_napomene",
      type: "multiline",
      rows: 5,
      validation: {
        required: true,
        maxLength: 500
      },
      width: "full"
    },
    {
      title: "Public naziv",
      source: "public_naziv",
      ttoken: "bs.public_naziv",
      type: "text",
      validation: {
        maxLength: 100
      },
      width: "full"
    },
    {
      title: "Public adresa",
      source: "public_adr_full",
      ttoken: "bs.public_adr_full",
      type: "text",
      validation: {
        maxLength: 100
      }
    },
    {
      title: "Public email",
      source: "public_email",
      ttoken: "bs.public_email",
      type: "text",
      validation: {
        maxLength: 100
      }
    },
    {
      title: "Public telefon",
      source: "public_tel",
      ttoken: "bs.public_tel",
      type: "text",
      validation: {
        maxLength: 20
      }
    },
    {
      title: "Public broj mobitela",
      source: "public_mobile",
      ttoken: "bs.public_mobile",
      type: "text",
      validation: {
        maxLength: 20
      }
    },
    {
      title: "Public web stranica",
      source: "public_web",
      ttoken: "bs.public_web",
      type: "text",
      width: "full",
      validation: {
        maxLength: 100
      }
    },
    {
      title: "Public opis HR",
      source: "public_desc_hr",
      ttoken: "bs.public_desc_hr",
      type: "multiline",
      rows: 5,
      validation: {
        maxLength: 1000
      }
    },
    {
      title: "Public opis EN",
      source: "public_desc_en",
      ttoken: "bs.public_desc_en",
      type: "multiline",
      rows: 5,
      validation: {
        maxLength: 1000
      }
    },
    {
      title: "Logo",
      source: "logo",
      ttoken: "bs.logo",
      type: "image"
    },
    {
      title: "Users count",
      source: "users_cnt",
      ttoken: "bs.users_cnt",
      type: "wholenumber",
      readonly: true
    },
    {
      title: "Vozila count",
      source: "vozila_cnt",
      ttoken: "bs.vozila_cnt",
      type: "wholenumber",
      readonly: true
    },
    {
      title: "BS potrazuje",
      source: "bs_potrazuje",
      ttoken: "bs.bs_potrazuje",
      type: "currency",
      currency: "€",
      readonly: true
    },
    {
      title: "BS duguje",
      source: "bs_duguje",
      ttoken: "bs.bs_duguje",
      type: "currency",
      currency: "€",
      readonly: true
    },
    {
      title: "Saldo",
      source: "saldo",
      ttoken: "bs.saldo",
      type: "currency",
      currency: "€",
      readonly: true
    },
    buttonFields.edit,
    buttonFields.view,
    buttonFieldsFav.favorite_a
  ]
};
