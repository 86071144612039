import React, { useContext } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import { makeStyles, ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";

//Material-UI Icons
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";

//Custom Components
import ThemeContext from "Components/ThemeContext/ThemeContext";
import ToolbarFillContent from "UI/Toolbar/ToolbarFillContent";
import HeaderDropdown from "UI/Header/HeaderDropdown";
import HeaderButton from "UI/Header/HeaderButton";
import HeaderMenuButton from "UI/Header/HeaderMenuButton";
import HeaderMenuItem from "UI/Header/HeaderMenuItem";
import ThemeSwitch from "UI/Header/ThemeSwitch";
import LanguageSelector from "UI/Header/LanguageSelector";
import ProfileMenu from "UI/Header/ProfileMenu";
import ModuleMenu from "UI/Header/ModuleMenu";
import AccessibilityMenu from "UI/Header/AccessibilityMenu";
import NotificationButton from "UI/Header/NotificationButton";
import CommunicatorButton from "UI/Header/CommunicatorButton";
import KomunikatorContext from "Components/KomunikatorContext/KomunikatorContext";
import NotificationAlert from "UI/Header/NotificationAlert";
import NotificationModal from "UI/Header/NotificationModal";
import HelpButton from "UI/Header/HelpButton"

import Logo from "Images/eurohop-50h.png";

const drawerWidth = 240;

const makeAppBarStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    backgroundColor: theme.palette.background.paper
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1,
    textAlign: "center",
    justifyContent: "center"
  },
  link: {
    textDecoration: "none",
    paddingLeft: 10,
    paddingRight: 10
  },
  titleLink: {
    color: "#fff",
    textDecoration: "none"
  },
  activeLink: {
    color: "#FFD33A",
    textDecoration: "underline"
  }
}));

const makeToolbarStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      [theme.breakpoints.down("sm")]: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0)
      },
      [theme.breakpoints.up("md")]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
      }
    },
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    // borderBottom: "2px",
    borderStyle: "solid",
    borderColor: theme.palette.secondary.main
  }
}));

const AppHeader = React.memo((props) => {
  const KC = useContext(KomunikatorContext);
  const themeContext = useContext(ThemeContext);

  const appBarClasses = makeAppBarStyles();
  const toolbarClasses = makeToolbarStyles();
  const { t } = useTranslation();
  const { menu, modul, sidebarOpen, toggleSidebar } = props;

  const importantMessages = KC.notifications.filter((x) => x.prioritet === "Medium");
  const criticalMessages = KC.notifications.filter((x) => x.prioritet === "High");

  const isLightTheme = themeContext.theme === "light" ? true : false;

  return (
    <MuiThemeProvider
      theme={outerTheme => ({
        ...outerTheme,
        palette: {
          ...outerTheme.palette,
          primary: isLightTheme ? outerTheme.palette.secondary : {
            main: "#FFF"
          },
          text: {
            ...outerTheme.palette.text,
            primary: isLightTheme ? "#FFF" : outerTheme.palette.secondary.main,
            secondary: isLightTheme ? "#888" : "#888"
          }
        }
      })}>
      <AppBar
        position="fixed"
        color="primary"
        className={`${appBarClasses.appBar} ${sidebarOpen ? appBarClasses.appBarShift : ""}`}
      >
        <Toolbar className={toolbarClasses.root} variant="dense" disableGutters={true}>
          {!sidebarOpen ? <HeaderMenuButton toggleSidebar={toggleSidebar} /> : null}
          <NavLink to="/" className={appBarClasses.titleLink}>
            <img src={Logo} height={47} />
          </NavLink>
          <div className={appBarClasses.grow} />


          <Hidden smDown>

            {modul ?

              <Typography
                variant="h6"
                component="h2"
                className={appBarClasses.grow}
                color={"textSecondary"}
                style={{ fontSize: "1.15rem" }}
              >{t("modul." + modul)}</Typography>

              : null}
          </Hidden>

          {/* <Hidden xsDown mdUp>
          <NavLink to="/" className={appBarClasses.titleLink}>
            <img src={Logo} height={47} />
          </NavLink>
        </Hidden> */}


          <div className={appBarClasses.grow} />
          {/* <ToolbarFillContent>
          <Typography variant="h6" component="h2" className={appBarClasses.grow} style={{ color: "#fff", fontSize: "1.15rem" }}>
            <NavLink to="/" className={appBarClasses.titleLink}>
              {t("app_title")}
            </NavLink>
          </Typography>
        </ToolbarFillContent> */}
          <LanguageSelector />
          <HelpButton currentModul={modul} />
          <AccessibilityMenu />
          <ThemeSwitch />
          <CommunicatorButton />
          <NotificationButton />
          <ProfileMenu />
          <ModuleMenu />
        </Toolbar>
        {
          Array.isArray(importantMessages) && importantMessages.length > 0 ? (
            <NotificationAlert notification={importantMessages[0]} />
          ) : null
        }
        {
          Array.isArray(criticalMessages) && criticalMessages.length > 0 ? (
            <NotificationModal notifications={criticalMessages} notificationCount={criticalMessages.length} />
          ) : null
        }
      </AppBar >
    </MuiThemeProvider>
  );
})
// <div id="header" className={classes.root}>
// </div>

AppHeader.propTypes = {
  menu: PropTypes.array
};

export default AppHeader;
