import React, { useContext } from "react";

//Material-UI Core Components
import IconButton from "@material-ui/core/IconButton";

// Material-UI Icons
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";

//Custom Components
import UserContext from "Components/UserContext/UserContext";
import { Permissions } from "Lib/permissions";

function KomunikatorFavButton(props) {
  const userContext = useContext(UserContext);

  const { channelId } = props;

  const isFavChannel = userContext.isFavoriteChannel(channelId);

  const needPermissions = [Permissions.AdminModule];

  return !userContext.hasAnyPermission(needPermissions) ? null : (
    <IconButton
      size="small"
      value={isFavChannel}
      onClick={(evt) => {
        evt.stopPropagation();
        userContext.toggleFavoriteChannel(channelId);
      }}
    >
      {isFavChannel ? (
        <FavoriteIcon color="secondary" fontSize="small" />
      ) : (
        <FavoriteBorderIcon color="secondary" fontSize="small" />
      )}
    </IconButton>
  );
}

export default KomunikatorFavButton;
