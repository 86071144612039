import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//Material UI
import AddCircleIcon from "@material-ui/icons/AddCircle";

//Custom components
import GridContainer from "UI/Grid/GridContainer";
import FormContent from "Components/FormContent";
import GridItem from "UI/Grid/GridItem";
import ModelTable from "UI/Table/ModelTable";
import DialogContext from "UI/DialogContext/DialogContext";
import FinancijskaKalkulacijaDialog from "Views/Prijevoznici/Voznje//VoznjaFormTabDialogs/FinancijskaKalkulacijaDialog";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import dataController from "Lib/dataController";
import model from "Models/fincalc";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import { FormButton, FormTabPanel } from "UI/Form";

function TabPanelFinancijskaKalkulacija(props) {
  const { t } = useTranslation();
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const loaderContext = useContext(LoaderContext);
  const [records, setRecords] = useState([]);

  const { recordId } = props;
  const { record, validation, fields, onFieldChange, mode, subModels, currentTab, tabIndex, dialog } = props;

  const dc = new dataController(model, `posebne-ponude/${recordId}/financijske-kalkulacije`);

  const fFinCalcDirect = "fin_calc_direct";
  const fIznosFinCalc = "iznos_fincalc";
  const fIznosBruto = "iznos_bruto";
  const fEksterniTrosakHelper = "eksterni_trosak_helper";

  const handleAdd = (evt) => {
    dialogContext.showDialog(FinancijskaKalkulacijaDialog, {
      dc: dc,
      mode: "insert",
      form: "popis_finCalc",
      onClose: handleDialogClose
    });
  };

  const openFinCalcDialog = (id) => {
    dialogContext.showDialog(FinancijskaKalkulacijaDialog, {
      dc: dc,
      mode: "update",
      form: "popis_finCalc",
      recordId: id,
      onClose: handleDialogClose
    });
  };

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords();
    }
    dialogContext.hideDialog(dialog ? 2 : 1);
  };

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData().then((resp) => {
      if (resp.success) {
        setRecords(resp.data ? resp.data : []);
        const iznos = resp.data
          ? resp.data
            .filter((x) => x[fIznosBruto])
            .map((x) => x[fIznosBruto])
            .reduce((a, b) => a + b, 0)
          : null;
        onFieldChange(iznos, fIznosFinCalc);
      } else {
        snackbarContext.showNotification("notifications.action_fail", "error");
      }
    }).catch((err) => {
      console.error(err);
    }).finally(() => {
      loaderContext.toggleLoading(false);
    });
  };

  const addExterniTrosak = () => {
    const currentRecords = subModels.eksterni_trosak_helper.records;
    const selectedRecords = record.eksterni_trosak_helper;

    if (selectedRecords.length > 0) {
      const chosenRecords = selectedRecords
        .map((selected) => {
          const tempItem = currentRecords.find((current) => current.value === selected.value);
          const inRecords = records.some((record) => record.value === selected.value);
          if (!inRecords) {
            return tempItem;
          }
        })
        .filter((record) => record !== undefined);
      insertExterniTroskovi(chosenRecords);
    }
  };

  const insertExterniTroskovi = (chosenRecords) => {
    const troskovi = chosenRecords.map((record, i) => {
      const length = records.length;
      return {
        id: length + 1 + i,
        opis: record.label,
        iznos_neto: record.iznos_neto,
        pdv_stopa: record.pdv_stopa,
        pdv_iznos: record.pdv_iznos,
        iznos_bruto: record.iznos_bruto
      };
    });
    const newRecords = records.concat(troskovi);
    onFieldChange(null, fEksterniTrosakHelper);
    handleSetRecords(newRecords);
  };


  const handleSetRecords = (records) => {
    setRecords(records);
    const preparedArray = records.map((record) => {
      return {
        opis: record.opis,
        iznos_neto: record.iznos_neto,
        pdv_stopa: record.pdv_stopa,
        pdv_iznos: record.pdv_iznos,
        iznos_bruto: record.iznos_bruto
      };
    });
    onFieldChange(preparedArray, fFinCalcDirect);
  };

  const handleRowAction = function(action, id) {
    if (action === "edit") {
      openFinCalcDialog(id);
    }
    if (action === "remove") {
      const newRecords = records.filter((r) => r.id !== id);
      handleSetRecords(newRecords);
    }
  };

  const allowRowAction = function(action, id) {
    return true;
  };

  useEffect(() => {
    if (currentTab === tabIndex && mode === "view") {
      refreshRecords();
    }
  }, [currentTab]);

  useEffect(() => {
    if (mode === "insert") {
      const iznos = records
        ? records
          .filter((x) => x["iznos_bruto"])
          .map((x) => x["iznos_bruto"])
          .reduce((a, b) => a + b, 0)
        : null;
      onFieldChange(iznos, "iznos_fincalc");
    }
  }, [records.length]);

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: handleFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels
  };

  const pickerRecords = record.eksterni_trosak_helper ? record.eksterni_trosak_helper : [];
  const viewName = mode === "insert" ? "posebnePonudaInsert" : mode === "view" ? "posebnePonudaView" : "posebnePonudaUpdate";

  return (
    <FormTabPanel value={currentTab} index={tabIndex}>
      <GridContainer>
        {mode === "insert" || mode === "update" ? (
          <GridItem xs={12} sm={12}>
            <GridContainer>
              <FormContent
                title={t("trjob.sections.eksterni_troskovi")}
                {...commonProps}
                fieldNames={["eksterni_trosak_helper"]}
                columns={2}
              />
              <GridItem xs={2} sm={4} md={4}>
                <FormButton
                  variant="outlined"
                  startIcon={<AddCircleIcon />}
                  onClick={addExterniTrosak}
                  disabled={mode === "view" || pickerRecords.length === 0 ? true : false}
                >
                  {pickerRecords.length === 1 || pickerRecords.length === 0
                    ? t("trjob.add_trosak")
                    : t("trjob.add_troskovi")}
                </FormButton>
              </GridItem>
            </GridContainer>
          </GridItem>
        ) : null}
        <GridItem md={12} sm={12}>
          <ModelTable
            records={records}
            dc={dc}
            viewName={viewName}
            allowExport={false}
            allowFilter={false}
            allowAdd={mode === "insert"}
            handleAdd={handleAdd}
            addLabel={t("buttons.new_m")}
            allowSelection="none"
            onRowAction={handleRowAction}
            allowRowAction={allowRowAction}
          />
        </GridItem>
        <FormContent fieldNames={["iznos_fincalc", "iznos_fincalc_manual"]} {...commonProps} columns={2} />
      </GridContainer>
    </FormTabPanel>
  );
}

export default TabPanelFinancijskaKalkulacija;
