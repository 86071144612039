import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

// Custom Components
import ModelTable from "UI/Table/ModelTable";
import TableCard from "UI/Table/TableCard";
import TableCardContent from "UI/Table/TableCardContent";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import UserContext from "Components/UserContext/UserContext";

// import model from "Models/upiti";
import model from "Models/upiti";
import dataController from "Lib/dataController";
import withRedirect from "Components/withRedirect";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

function BurzaTable(props) {
  const userContext = useContext(UserContext);
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);

  const { t } = useTranslation();
  const { state } = useLocation();

  const [records, setRecords] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const { redirect } = props;

  const { viewName } = props;

  const dc = new dataController(model, "burza/upiti");

  useEffect(() => {
    if (state) {
      const { moj_odgovor } = state;
      const identifier = dc.getSource() + viewName;
      userContext.setRecentFilters(identifier, "moj_odgovor", moj_odgovor);
      userContext.setRecent("toggle_filter", identifier, true);
    }
    refreshRecords();
  }, []);

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData()
      .then((resp) => {
        if (resp.success) {
          setRecords(resp.data);
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
        setLoaded(true);
      });
  };

  const handleRowAction = function (action, id) {
    const rec = records.find((x) => x.id === id);
    const hasPonuda = rec && rec.ponuda_id ? true : false;
    if (action === "view") {
      if (hasPonuda) {
        redirect(`/p/ponude/${rec.ponuda_id}#ponuda`);
      } else {
        redirect(`/p/burza/${id}`);
      }
    }
  };

  const allowRowAction = function (action, id) {
    return true;
  };

  return (
    <TableCard>
      <TableCardContent>
        {loaded && (
          <ModelTable
            title={t("titles.upiti_burza")}
            records={records}
            dc={dc}
            viewName={viewName}
            allowExport={true}
            allowFilter={true}
            allowAdd={false}
            allowSelection={"none"}
            onRowAction={handleRowAction}
            allowRowAction={allowRowAction}
            onDoubleClick={(id) => handleRowAction("view", id)}
            handleRefresh={refreshRecords}
          />
        )}
      </TableCardContent>
    </TableCard>
  );
}

BurzaTable.propTypes = {
  viewName: PropTypes.string
};

export default withRedirect(BurzaTable);
