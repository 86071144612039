import React from "react";
import { grey, orange, purple } from "@material-ui/core/colors";

//Material UI Core
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

import AppPage from "UI/AppPage/AppPage";
import menuAdministration from "Routes/menuAdministration";

import DialogProvider from "UI/DialogContext/DialogProvider";
import DialogContainer from "UI/DialogContext/DialogContainer";

import { Permissions } from "Lib/permissions";

const moduleTheme = createMuiTheme({
  palette: {
    primary: {
      main: grey[800],
      dark: grey[900],
      light: grey[400]
    },
    secondary: {
      main: orange[800],
      dark: orange[900],
      light: orange[700]
    }
  }
});

function AdminPage(props) {

  const { needPermission } = props;

  const definedPermissions = needPermission !== undefined ? needPermission : [Permissions.AdminModule]

  return (
    <MuiThemeProvider theme={outerTheme => ({
       ...outerTheme,
       palette: {
         ...outerTheme.palette
         , primary: moduleTheme.palette.primary
         , secondary: moduleTheme.palette.secondary
       }
      })}>
      <DialogProvider>
        <AppPage menu={menuAdministration} modul="admin" needPermission={definedPermissions} >
          {props.children}
        </AppPage>
        <DialogContainer />
      </DialogProvider>
    </MuiThemeProvider>
  );
}

export default AdminPage;
