import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

//Material-UI Icons
import VpnKeyIcon from "@material-ui/icons/VpnKey"

//Custom Components
import dataController from "Lib/dataController";
import model from "Models/user";
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import TextControl from "Controls/TextControl";
import FormButton from "UI/Form/FormButton";
import PasswordChangeDialog from "Views/ProfilePage/PasswordChangeDialog";

import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import DialogContext from "UI/DialogContext/DialogContext";
import UserContext from "Components/UserContext/UserContext";
import { Role } from "Lib/role";

function ProfileForm(props) {
  const userContext = useContext(UserContext);
  const dialogContext = useContext(DialogContext);
  const { t } = useTranslation();

  const dc = new dataController(model);

  const handlePasswordChange = (evt) => {
    dialogContext.showDialog(PasswordChangeDialog, {
      dc: dc,
      form: "password_change"
    });
  };

  const userRole = Role.getRoleByCode(userContext.roleId) || {};

  return (
    <Container maxWidth="sm">
      <Card>
        <CardContent>
          <GridContainer>
            <GridItem xs={12}>
              <Typography variant="h5" component="h3">
                {t("titles.user_profile")}
              </Typography>
            </GridItem>
            <GridItem xs={12}>
              <TextControl forMode="table" controlMode="view" value={userContext.email} label={t("users.email")} />
            </GridItem>
            <GridItem xs={12}>
              <TextControl forMode="table" controlMode="view" value={userContext.user} label={t("users.full_name")} />
            </GridItem>
            <GridItem xs={12}>
              <TextControl forMode="table" controlMode="view" value={userRole.id} label={t("users.role")} />
            </GridItem>
            <GridItem xs={12}>
              <TextControl
                forMode="table"
                controlMode="view"
                value={userContext.subject}
                label={t("users.business_subject")}
              />
            </GridItem>
          </GridContainer>
        </CardContent>
        <CardActions>
          <FormButton variant="contained" onClick={handlePasswordChange} startIcon={<VpnKeyIcon />}>
            {t("buttons.password_change")}
          </FormButton>
        </CardActions>
      </Card>
    </Container>
  );
}

export default ProfileForm;
