import React from "react";

//Custom Components
import AdminPage from "UI/AppPage/AdminPage";
import ResponsiveBox from "UI/Box/ResponsiveBox";
import UsersTable from "./UsersTable";

const UsersPage = React.memo((props) => {
  return (
    <AdminPage>
      <ResponsiveBox fillHeight>
        <UsersTable />
      </ResponsiveBox>
    </AdminPage>
  );
});

export default UsersPage;
