import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//Custom components
import withRedirect from "Components/withRedirect";
import ModelTable from "UI/Table/ModelTable";
import model from "Models/financijskeObveze";
import dataController from "Lib/dataController";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";

function AdminFinObveze(props) {
  const { t } = useTranslation();
  const snackbarContext = useContext(SnackbarContext);
  const loaderContext = useContext(LoaderContext);


  const [records, setRecords] = useState([]);

  const { currentTab, recordId } = props;
  const { redirect } = props; //HOC withRedirect

  const path = "admin/financije/" + recordId + "/obveze";
  const dc = new dataController(model, path);

  const handleRowAction = function(action, id) {
    if (action === "view") {
      const record = records.find(x => x.id === id);
      if (record) {
        const posaoId = record.posao_id;
        redirect("/admin/voznje/" + posaoId + "#financijske-obveze");
      }
    }
  }

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData(path)
      .then((resp) => {
        if (resp && resp.data && resp.success) {
          setRecords(resp.data);
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  useEffect(() => {
    refreshRecords();
  }, []);

  return (
    <ModelTable
      records={records}
      dc={dc}
      viewName="admin"
      allowExport={false}
      allowFilter={false}
      allowAdd={false}
      allowSelection="none"
      onRowAction={handleRowAction}
      subModelDefaultValues={{
        posao_id: recordId
      }}
    >
      {/* <TableButtonEdit onClick={handleEdit} /> */}
    </ModelTable>
  );
}

export default withRedirect(AdminFinObveze);
