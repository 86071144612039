import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

// Material-UI Icons
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import MinimizeIcon from '@material-ui/icons/Minimize';
import SettingsIcon from '@material-ui/icons/Settings';

// Material-UI Core Components
import { makeStyles } from '@material-ui/core/styles';
import {
	Avatar
, Hidden
, IconButton
, Popover
,	Toolbar
, Typography
} from "@material-ui/core";

// Custom Components
import { helpers as H } from "Lib/helpers";
import { sifrarnici } from "Lib/sifrarnici";
import { komunikatorService as KS } from "Services/komunikatorService";
import KomunikatorAddMembers from "./KomunikatorAddMembers";
import KomunikatorSettings from "./KomunikatorSettings";
import KomunikatorContext from "Components/KomunikatorContext/KomunikatorContext";

const A = {
	MEMBERS: 1,
	SETTINGS: 2
};

function KomunikatorChatHeader(props) {
	const [members, setMembers] = useState([]);
	const [addMembersOpen, setAddMembersOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState(0);
	const classes = useStyles();
	const { channel, voznjaId, bsId, onClose } = props;
	const { t } = useTranslation();
	const KC = useContext(KomunikatorContext);

	const getMembers = () => {
		if (voznjaId === 0) {
			KS.getInternalMembers(bsId).then(resp => 
				setMembers(resp.success && resp.data.map(m => Object.assign({
					color: H.getRandomColor()
				}, m)) || [])
			);
		} else {
			KS.getJobMembers(voznjaId).then(resp => 
				setMembers(resp.success && resp.data.map(m => Object.assign({
					color: H.getRandomColor()
				}, m)) || [])
			);
		}
	}

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleOpenMembers = (evt) => {
		setAnchorEl(evt.currentTarget);
		setOpen(A.MEMBERS)
		getMembers();
	}

	const handleOpenSettings = (evt) => {
		setAnchorEl(evt.currentTarget);
		setOpen(A.SETTINGS)
	}

	useEffect(() => {
		
	}, []);

	return (
		<Toolbar className={classes.toolbar} color="secondary" variant="dense">
			<Avatar
				alt={t(channel.title)}
				style={{backgroundColor: channel.color}}
				className={classes.avatar}
			>
				{ t(channel.title)[0] }
			</Avatar>
			<Typography
				noWrap
				variant="h6"
				display="inline"
				align="left"
				className={classes.typographyTitle}
			>
				{ t(channel.title) }
			</Typography>
      <div align="center" className={classes.toolbarFillContent} />
      <div className={classes.buttonWrapper}>
      	{
      		voznjaId !== 0 ?
		      <IconButton
		      	className={classes.button}
		      	onClick={handleOpenSettings}
		      	disabled={Boolean(channel.panic && channel.channel_type_id === sifrarnici.channel_types.main)}
		      >
		      	<SettingsIcon />
		      </IconButton>
		      : null
      	}
	      <IconButton
	      	className={classes.button}
	      	onClick={handleOpenMembers}
	      	disabled={Boolean(channel.panic && channel.channel_type_id === sifrarnici.channel_types.main)}
	      >
	      	<GroupAddIcon />
	      </IconButton>
	      <IconButton
	      	className={classes.button}
	      	onClick={() => KC.addNewChat(channel, true)}
	      >
	      	<MinimizeIcon />
	      </IconButton>
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
      	{
      		open === A.MEMBERS ?
		        <KomunikatorAddMembers 
		        	members={members}
		        	channelMembers={channel.users}
		        	channelId={channel.id}
		        />
		      :
		      open === A.SETTINGS ? // there is no settings for now but I will leave this here if we ever need it
		      	<KomunikatorSettings
		      		channel={channel}
		      		onClose={(data) => handleClose() || onClose(data)}
		      	/>
		     	: null
      	}
      </Popover>
		</Toolbar>
	);
}

const useStyles = makeStyles(theme => ({
	toolbar: {
		borderBottom: `1px solid ${theme.palette.secondary.main}`,
		// backgroundColor: "#fff"
	},
  avatar: {
  	alignSelf: "center"
  },
  typographyTitle: {
  	//maxWidth: "500px",
  	marginLeft: theme.spacing(1)
  },
  toolbarFillContent: {
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  buttonWrapper: {
  	marginRight: theme.spacing(1)
  },
  button: {
  	padding: theme.spacing(1)
  }
}));


export default KomunikatorChatHeader;