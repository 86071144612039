import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//Material UI
import Box from "@material-ui/core/Box";

//Custom components
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import KomunikatorCard from "UI/Komunikator/KomunikatorCard";
import KomunikatorCardInternal from "UI/Komunikator/KomunikatorCardInternal";
import KomunikatorSearch from "UI/Komunikator/KomunikatorSearch";

import modelKomunikator from "Models/komunikator";
import modelPoslovi from "Models/poslovi";
import dataController from "Lib/dataController";
import LoaderContext from "Components/LoaderContext/LoaderContext";

import { komunikatorService as KS } from "Services/komunikatorService";
import UserContext from "Components/UserContext/UserContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

function KomunikatorPage(props) {
  const { prefix } = props;
  const { t } = useTranslation();
  const dcPoslovi = new dataController(modelPoslovi);
  const dcKomunikator = new dataController(modelKomunikator);
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);
  const userContext = useContext(UserContext);
  const [records, setRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [favOnly, setFavOnly] = useState(false);


  const cmp = (obj, key) => (
    obj && obj[key] && obj[key]
      .toString().toLowerCase()
      .indexOf(filterValue.toLowerCase()) !== -1
  )

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    KS.getJobs()
      .then(resp => {
        if (resp.success) {
          setRecords(resp.data || []);
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const filterRecords = (records) => {
    if (filterValue.length === 0) {
      return records;
    }

    return records.map(r => {
      let match = 0;
      // console.log(r.partner, filterValue, cmp(r, "partner"))
      if (cmp(r, "partner")) {
        match = 1;
      } else if (cmp(r, "from_loc_name") || cmp(r, "to_loc_name")) {
        match = 0.8;
      } else if (cmp(r, "from_ts") || cmp(r, "to_ts")) {
        match = 0.6;
      } else {
        if (r.channels && r.channels.some(c => cmp(c, "title"))) {
          match = 0.4;
        }
      }
      return [match, r];
    })
    .filter(r => r[0])
    .sort((a, b) => b[0] - a[0])
    .map(r => r[1]);
  }

  useEffect(() => {
    // TODO:
    // add fetching of archived channels, maybe as an optional button
    refreshRecords();
  }, []);

  useEffect(() => {
    setFilteredRecords(records);
  }, [records]);

  useEffect(() => {
    setFilteredRecords(filterRecords(records));
  }, [filterValue]);

  return (
    <Box m={2}>
      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <KomunikatorSearch setFilterValue={setFilterValue} filterValue={filterValue} favOnly={favOnly} setFavOnly={setFavOnly} />
        </GridItem>
        <GridItem xs={false} sm={6} md={8}/>
        <GridItem xs={12} sm={12} md={8} lg={6}>
          <KomunikatorCardInternal prefix={prefix} />
        </GridItem>
        {
          (favOnly ? 
            filteredRecords.filter(r => r.channels.some(c => userContext.isFavoriteChannel(c.id))) 
          : filteredRecords
          ).map((job, i) => (
            <GridItem xs={12} sm={6} md={4} lg={3} key={i}>
              <KomunikatorCard prefix={prefix} job={job} />
            </GridItem>
        ))}
      </GridContainer>
    </Box>
  );
}

export default KomunikatorPage;
