import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";

//Material-UI Icons
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import PublicIcon from "@material-ui/icons/Public";
import SendIcon from "@material-ui/icons/Send";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

//Custom Components
import withFormController from "Components/withFormController";
import { GridContainer } from "UI/Grid";
import DynamicDialog from "UI/Dialog/DynamicDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import UpitAvatar from "UI/Form/Avatars/UpitAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarCreationDetails from "UI/Dialog/DialogToolbarCreationDetails";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import { FormTabs, FormTab, FormTabPanel } from "UI/Form";
import DialogActionButton from "UI/Dialog/DialogActionButton";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import DialogToolbarMenuItemDraft from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemDraft";
import FormContent from "Components/FormContent";

import TabPanelUpitPrivatno from "Views/Upiti/Tabs/TabPanelUpitPrivatno";
import TabPanelUpitJavno from "Views/Upiti/Tabs/TabPanelUpitJavno";
import TabPanelUpitPrijevoznici from "Views/Upiti/Tabs/TabPanelUpitPrijevoznici";

import dataController from "Lib/dataController";
import model from "Models/upiti";
import prijevoznikModel from "Models/a_prijevoznici";
import { upitService } from "Services/upitService";
import EuroConversionComment from "Components/EuroConversionComment";

function UpitDialog(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState(1);

  const { mode, form, record, recordId, validation, fields, subModels } = props; //HOC withFormController
  const { doInsert, doClose, doValidate } = props; //HOC withFormController
  const { onFieldChange, onClose } = props; //HOC withFormController
  const { external } = props;

  const { direktIdRecord } = props;

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleTabChange = (evt, value) => {
    setCurrentTab(value);
  };

  const dc = new dataController(model);
  const prijevozniciDc = new dataController(prijevoznikModel);

  const handleInsert = (evt) => {
    if (doInsert) {
      doInsert().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "insert", id: result.id });
          snackbarContext.showNotification("insert", "success");
        }
      }).catch((result) => {
        if (result.success === false && result.validationPass === false) {
          //do nothing
        } else {
          snackbarContext.showNotification(result.error, "error");
        }
      });
    }
  };

  const handleUpdate = (evt) => {
    upitService.updateUpitDraft(record["id"], record).then((resp) => {
      if (resp.success) {
        snackbarContext.showNotification("notifications.updated_draft", "success");
        close({ dataChanged: false });
      } else {
        snackbarContext.showNotification(resp.error.message, "error");
      }
    });
  };

  const handleContinue = (evt) => {
    setCurrentTab(prevState => prevState + 1);
  }

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const close = (result) => {
    if (result.dataChanged) {
      onClose({ dataChanged: true, action: result.action, id: result.id });
    }

    dialogContext.hideDialog(external ? 2 : 1);
  };

  const createDraft = () => {
    const preparedRecord = dc.prepareSendingData(record);
    upitService.createUpitDraft(preparedRecord).then((resp) => {
      if (resp.success) {
        snackbarContext.showNotification("notifications.create_draft", "success");
        close({ dataChanged: true });
      }
    }).catch((result) => {
      snackbarContext.showNotification(result.error.message, "success");
    });
  };

  const isLocked = false;
  const panelMode = isLocked ? "view" : "update";

  const commonTabProps = {
    currentTab: currentTab,
    recordId: recordId,
    subModels: subModels,
    record: record,
    validation: validation,
    onFieldChange: handleFieldChange,
    fields: fields,
    mode: panelMode
  };

  const commonProps = {
    recordId: recordId,
    subModels: subModels,
    record: record,
    validation: validation,
    onFieldChange: handleFieldChange,
    fields: fields,
    mode: panelMode
  };

  return (
    <DynamicDialog onClose={handleClose} fixedHeight={true}>
      <DialogHeader recordStatus={record.upit_state_id}>
        <Toolbar variant="dense" disableGutters={true}>
          <UpitAvatar />
          <DialogToolbarHeading header={t("titles.upit_novi")} />
          <DialogToolbarFillContent />
          {record ? <DialogToolbarCreationDetails record={record} /> : null}
          <DialogToolbarMenuButton>
            <DialogToolbarMenuItemDraft onClick={createDraft} />
          </DialogToolbarMenuButton>
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <FormTabs value={currentTab} onChange={handleTabChange}>
          <FormTab
            value={0}
            label={t("upit.tabs.interni_podaci")}
            icon={<BusinessCenterIcon />}
            validation={validation}
            fields={["narucitelj_interni", "napomena_interna"]}
          />
          <FormTab
            value={1}
            label={t("upit.tabs.javni_podaci")}
            icon={<PublicIcon />}
            validation={validation}
            fields={[
              "from_ts",
              "to_ts",
              "vozilo_tip_id",
              "vozilo_klasa_id",
              "vozilo_broj_putnika",
              "opis",
              "vozilo_oprema_ids",
              "trajanje_voznje", "neto_udaljenost_voznje", "udaljenost_povratka_vozila",
              "cijena_hrk",
              "rok_za_ponude"
            ]}
          />
          <FormTab
            value={2}
            label={t("upit.tabs.odabir_prijevoznika")}
            icon={<AirportShuttleIcon />}
            validation={validation}
            fields={["burza_visible"]}
          />
        </FormTabs>
        <FormTabPanel value={currentTab} index={0}>
          <GridContainer>
            <FormContent
              {...commonProps}
              title={t("upit.sections.internal")}
              fieldNames={["id", "owner_user_fullname", "narucitelj_interni", "napomena_interna"]}
            />
          </GridContainer>
        </FormTabPanel>
        <TabPanelUpitJavno tabIndex={1} {...commonTabProps}/>
        <TabPanelUpitPrijevoznici tabIndex={2} {...commonTabProps} dc={prijevozniciDc} direktIdRecord={direktIdRecord}/>
      </DialogBody>
      <DialogActions>
        <EuroConversionComment />
        <DialogToolbarFillContent />
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
        { currentTab < 2 ?
        <DialogActionButton variant="contained" onClick={handleContinue} startIcon={<ArrowForwardIcon />}>
          {t("buttons.continue")}
        </DialogActionButton> :
          <DialogActionButton variant="contained" onClick={handleInsert} startIcon={<SendIcon />}>
            {t("buttons.publish")}
          </DialogActionButton>
        }
      </DialogActions>
    </DynamicDialog>
  );
}

export default withFormController(UpitDialog);
