import React from "react";

//Material UI Core Components
import Toolbar from "@material-ui/core/Toolbar";
import CardMedia from "@material-ui/core/CardMedia";
import Box from "@material-ui/core/Box";
import ImageIcon from "@material-ui/icons/Image";
import { makeStyles } from "@material-ui/core/styles";

// Custom Components
import DraggableDialog from "UI/Dialog/DraggableDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActions from "@material-ui/core/DialogActions";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItemDelete from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemDelete";

import { Permissions } from "Lib/permissions";

const styles = makeStyles(theme => ({
  root: {
    zIndex: 2001
  }
}));

function ImageDialog(props) {

  const classes = styles();

  const {image, onClose, onDelete, uuid} = props;

  const handleDelete = () => {
    if (onDelete && uuid) {
      onDelete(uuid).then((resp) => {
        if (resp.success) {
          onClose({dataChanged: true})
        };
      })
    }
  }

  const handleDialogClose = () => {
    onClose({dataChanged: false});
  }

  return (
      <DraggableDialog classes={{root: classes.root}} open={true} maxWidth="lg" fullWidth={false} onClose={handleDialogClose}>
        <DialogHeader>
          <Toolbar variant="regular" disableGutters>
            <DialogAvatar ariaLabel="image" icon={<ImageIcon/>}/>
            <DialogToolbarFillContent/>
            <DialogToolbarMenuButton needPermission={[Permissions.AdminModule]}>
              <DialogToolbarMenuItemDelete onClick={handleDelete} />
            </DialogToolbarMenuButton>
            <DialogToolbarButtonClose onClick={handleDialogClose}/>
          </Toolbar>
        </DialogHeader>
        <DialogBody>
          <Box m={2}>
            <CardMedia component="img" src={"data:image/jpeg;base64, " + image} style={{ borderRadius: "8px"}}/>
          </Box>
        </DialogBody>
        <DialogActions>
          <DialogActionButtonClose variant="outlined" onClick={onClose}/>
        </DialogActions>
      </DraggableDialog>
  );
}

export default ImageDialog;
