import React, { Fragment } from "react";
import Swal from "sweetalert2";
import { withTranslation } from "react-i18next";

//Custom components
import appValidator from "Lib/appValidator";
import { authService } from "Services/authService";
import Loader from "UI/Loader/Loader";

function withFormAuthController(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      const { t } = props;

      this.handleFieldChange = this.handleFieldChange.bind(this);
      this.handleClose = this.handleClose.bind(this);

      this.handleLogin = this.handleLogin.bind(this);
      this.handleRegister = this.handleRegister.bind(this);
      this.handleActivate = this.handleActivate.bind(this);
      this.handleChangePassword = this.handleChangePassword.bind(this);
      this.handleSendPasswordResetRequest = this.handleSendPasswordResetRequest.bind(
        this
      );
      this.handleResetPassword = this.handleResetPassword.bind(this);

      this.serverErrorPromise = this.serverErrorPromise.bind(this);
      this.confirmClose = this.confirmClose.bind(this);
      this.confirmRegister = this.confirmRegister.bind(this);

      this.getFields = this.getFields.bind(this);
      this.checkIfChanged = this.checkIfChanged.bind(this);
      this.validate = this.validate.bind(this);

      this.validator = new appValidator(t);

      let record = props.record ? props.record : null;
      if (record === null && props.defaultValues) {
        record = {};
        Object.keys(props.defaultValues).forEach(key => {
          record[key] = props.defaultValues[key];
        });
      }

      const recordCopy = Object.assign({}, record);
      const originalRecord = Object.assign({}, record);

      this.state = {
        originalRecord: originalRecord,
        record: recordCopy,
        validation: null,
        validated: false,
        dataChanged: false,
        subModels: null,
        loader: false
      };
    }

    componentDidMount() {
      this.mounted = true;
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    handleFieldChange(value, source) {
      const { validated } = this.state;

      this.setState(
        prevState => {
          let record = prevState.record;
          if (!record) {
            record = {};
          }
          record[source] = value;
          return {
            record: record,
            dataChanged: this.checkIfChanged(record)
          };
        },
        () => {
          if (validated) {
            this.validate();
          }
        }
      );
    }

    handleClose() {
      const { dataChanged } = this.state;

      if (dataChanged) {
        return this.confirmClose().then(result => {
          if (result.canceled) {
            return Promise.resolve({ success: false, canceled: true });
          } else if (result.confirmed) {
            return Promise.resolve({ success: false, shouldsave: true });
            //return this.handleUpdate();
          } else {
            return Promise.resolve({ success: true });
          }
        });
      } else {
        return Promise.resolve({ success: true });
      }
    }

    serverErrorPromise(serverValidation) {
      this.setState({
        validation: serverValidation
      });
      return Promise.resolve({
        success: false,
        validationPass: false,
        validation: serverValidation
      });
    }


    // TODO:
    // translations
    handleLogin() {
      const { record, validation } = this.state;

      const isValid = this.validate();
      if (!isValid) {
        return Promise.resolve({
          success: false,
          validationPass: false,
          validation: validation
        });
      }

      this.setState({loader: true})
      return authService
        .login(record.username, record.password)
        .then(data => {
          if (data.access_token) {
            return Promise.resolve({ success: true, data: data });
          } else {
            return this.serverErrorPromise({
              username: {
                valid: false,
                msg: "Neispravna e-mail adresa ili lozinka"
              },
              password: {
                valid: false,
                msg: "Neispravna e-mail adresa ili lozinka"
              }
            });
          }
        })
        .catch(data => {
          switch (data.errorCode) {
            case 407:
              return this.serverErrorPromise({
                email: {
                  valid: false,
                  msg: "Neispravna e-mail adresa ili lozinka."
                },
                password: {
                  valid: false,
                  msg: "Neispravna e-mail adresa ili lozinka"
                }
              });
          }
        })
        .finally(() => {
          if (this.mounted) {
            this.setState({loader: false})
          }
        });
    }

    handleRegister() {
      const { record, validation } = this.state;

      const isValid = this.validate();
      if (!isValid) {
        return Promise.resolve({
          success: false,
          validationPass: false,
          validation: validation
        });
      }

      return this.confirmRegister().then(result => {
        if (result.canceled || result.confirmed == false) {
          return Promise.resolve({ success: false, canceled: true });
        } else if (result.confirmed) {
          this.setState({loader: true})
          return authService
            .register(record)
            .then(data => {
              return Promise.resolve({ success: true });
            })
            .catch(data => {
              switch (data.errorCode) {
                case 401:
                  return this.serverErrorPromise({
                    email: {
                      valid: false,
                      msg:
                        "Korisnik s navedenom e-mail adresom je već registriran u sustavu."
                    }
                  });
                case 402:
                  return this.serverErrorPromise({
                    oib: {
                      valid: false,
                      msg:
                        "Korisnik s navedenim OIB-om je već registriran u sustavu."
                    }
                  });
              }
              return Promise.resolve({
                success: false,
                error: data.error,
                errorCode: data.errorCode
              });
            })
            .finally(() => {
              if (this.mounted) {
                this.setState({loader: false})
              }
            });
        } else if (result.confirmed == false) {
          return Promise.resolve({ success: false, canceld: true });
        } else {
          return Promise.resolve({ success: false });
        }
      });
    }

    handleActivate() {
      const { record, validation } = this.state;

      const isValid = this.validate();
      if (!isValid) {
        return Promise.resolve({
          success: false,
          validationPass: false,
          validation: validation
        });
      }

      this.setState({loader: true})
      return authService
        .activate(record)
        .then(data => {
          if (data.access_token) {
            console.log(data);
            return Promise.resolve({ success: true, data: data });
          } else {
          }
        })
        .catch(resp => {
          switch (resp.error.errorCode) {
            case 409:
              return this.serverErrorPromise({
                password: { valid: false, msg: "Lozinka nije ispravna." }
              });
          }
          return Promise.resolve({
            success: false,
            error: resp.error.message,
            errorCode: resp.error.errorCode
          })
        })
        .finally(() => {
          if (this.mounted) {
            this.setState({loader: false})
          }
        });
    }

    handleSendPasswordResetRequest() {
      const { record, validation } = this.state;

      const isValid = this.validate();
      if (!isValid) {
        return Promise.resolve({
          success: false,
          validationPass: false,
          validation: validation
        });
      }

      this.setState({loader: true})
      return authService
        .resetPasswordRequest(record)
        .then(() => {
          return Promise.resolve({ success: true });
        })
        .catch(() => {
          return Promise.resolve({ success: false });
        })
        .finally(() => {
          if (this.mounted) {
            this.setState({loader: false})
          }
        });
    }

    handleResetPassword() {
      const { record, validation } = this.state;

      const isValid = this.validate();
      if (!isValid) {
        return Promise.resolve({
          success: false,
          validationPass: false,
          validation: validation
        });
      }

      this.setState({loader: true})
      return authService
        .resetPassword(record)
        .then((data) => {
          return Promise.resolve({ success: true, data: data });
        })
        .catch(() => {
          return Promise.resolve({ success: false });
        })
        .finally(() => {
          if (this.mounted) {
            this.setState({loader: false})
          }
        });
    }

    handleChangePassword() {
      const { record, validation } = this.state;

      const isValid = this.validate();
      if (!isValid) {
        return Promise.resolve({
          success: false,
          validationPass: false,
          validation: validation
        });
      }

      this.setState({loader: true})
      return authService
        .changePassword(record)
        .then(() => {
          return Promise.resolve({ success: true });
        })
        .catch((resp) => {
          switch (resp.error.errorCode) {
            case 409:
              return this.serverErrorPromise({
                password: { valid: false, msg: "Lozinka nije ispravna." }
              });
          }
          return Promise.resolve({
            success: false,
            error: resp.error.message,
            errorCode: resp.error.errorCode
          });
        })
        .finally(() => {
          if (this.mounted) {
            this.setState({loader: false})
          }
        });
    }

    confirmClose() {
      const { t } = this.props;

      const title = t("cdialogs.changes");
      const text = t("cdialogs.want_to_save");
      const type = "warning";
      const confirmButtonText = t("cdialogs.yes_save_changes");
      const cancelButtonText = t("cdialogs.no_just_close");

      return this.confirm(
        title,
        text,
        type,
        confirmButtonText,
        cancelButtonText
      );
    }

    confirmRegister() {
      const { t } = this.props;

      return Promise.resolve({ confirmed: true });

      // const title = t("cdialogs.register");
      // const text = t("cdialogs.please_confirm");
      // const type = "warning";
      // const confirmButtonText = t("cdialogs.register");
      // const cancelButtonText = t("cdialogs.cancel");

      // return this.confirm(
      //   title,
      //   text,
      //   type,
      //   confirmButtonText,
      //   cancelButtonText
      // );
    }

    confirm(title, text, type, confirmButtonText, cancelButtonText) {
      return Swal.fire({
        title: title,
        text: text,
        icon: type,
        showCancelButton: true,
        cancelButtonText: cancelButtonText,
        confirmButtonText: confirmButtonText,
        allowOutsideClick: false,
        allowEscapeKey: true,
        keydownListenerCapture: true,
        showCloseButton: true,
        onClose: Swal.close()
      }).then(result => {
        Swal.close();
        if (result.value) {
          return Promise.resolve({ confirmed: true });
        } else if (result.dismiss === "cancel") {
          return Promise.resolve({ confirmed: false });
        } else {
          return Promise.resolve({ canceled: true });
        }
      });
    }

    checkIfChanged(record) {
      const { originalRecord } = this.state;
      const changed = this.validator.checkIfRecordChanged(
        record,
        originalRecord
      );
      return changed;
    }

    getValidator() {
      const { dc, form, mode } = this.props;

      const formId = form ? form : mode;
      const validateFunc = dc.getValidator(formId);
      if (
        this.validator[validateFunc] !== undefined &&
        typeof this.validator[validateFunc] === "function"
      ) {
        return this.validator[validateFunc];
      } else {
        return () => {
          return {};
        };
      }
    }

    getFields() {
      const { fieldNames, dc, form, controllerForm, mode } = this.props;

      let formId = form ? form : mode;

      let fields = [];

      //get all fields for controller
      if (controllerForm) {
        fields = dc.getFormFields(controllerForm);
      }

      //override with custom definitions
      let formFields = dc.getFormFields(formId);
      if (formFields) {
        return formFields
      }
      formFields.forEach(f => {
        const ind = fields.findIndex(x => x.source == f.source);
        if (ind >= 0) {
          fields[ind] = f;
        } else {
          fields.push(f);
        }
      })

      //filter by fieldnames if necessary
      if (fieldNames) {
        return fields.map(x => fieldNames.indexOf(x.source >= 0));
      } else {
        return fields;
      }
    }

    validate() {
      const { record } = this.state;
      const { t } = this.props;


      const fields = this.getFields();

      //model validation
      let validation = this.validator.validateModel(record, fields);

      //custom validation
      const customValidation = this.getValidator()(record, t);

      const finalValidation = this.validator.mergeValidation(
        validation,
        customValidation
      );
      const isValid = this.validator.checkIfValid(finalValidation);

      this.setState({
        validation: finalValidation,
        validated: true
      });

      return isValid;
    }

    render() {
      const {
        record,
        validation,
        validated,
        subModels,
        dataChanged,
        loader
      } = this.state;

      const fields = this.getFields();

      return (
        <Fragment>
          <WrappedComponent
            {...this.props}
            validation={validation}
            validated={validated}
            subModels={subModels}
            dataChanged={dataChanged}
            fields={fields}
            record={record}
            onFieldChange={this.handleFieldChange}
            doClose={this.handleClose}
            doLogin={this.handleLogin}
            doRegister={this.handleRegister}
            doActivate={this.handleActivate}
            doChangePassword={this.handleChangePassword}
            doResetPassword={this.handleResetPassword}
            doSendPasswordResetRequest={this.handleSendPasswordResetRequest}
          />
          <Loader open={loader} />
        </Fragment>
      );
    }
  };
}

export default (Component) => withTranslation()(withFormAuthController((Component)));
