import React, { useState, useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

//Material-UI Core Components
import Toolbar from "@material-ui/core/Toolbar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";

//Material-UI Icons
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import PublicIcon from "@material-ui/icons/Public";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import AssignmentIcon from "@material-ui/icons/Assignment";
import GradeIcon from "@material-ui/icons/Grade";
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import ReceiptIcon from "@material-ui/icons/Receipt";

//CBC Icons
import PotvrdaRezervacijeIcon from "Icons/PotvrdaRezervacijeIcon";

//Custom Components
import withFormController from "Components/withFormController";
import DialogToolbarMenuItemInvalidate from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemInvalidate";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import { CardHeading, CardToolbarButtonAlarm, CardToolbarButtonBack } from "UI/Card";
import { CardToolbarMenuButton, CardToolbarMenuItemTransferOwnership, CardToolbarMenuItemCopy, CardToolbarMenuItemMarkUnread } from "UI/Card";
import CardToolbarMenuItemInvalidate from "UI/Card/ToolbarMenuItems/CardToolbarMenuItemInvalidate"
import { CardColored, CardActionButtonClose, CardActionButtonSave, CardSOSHeading } from "UI/Card";
import { FormHeading, FormHeadingIDAndOwner, FormFillContent } from "UI/Form";
import { FormTabs, FormTab } from "UI/Form";
import UpitAvatar from "UI/Form/Avatars/UpitAvatar";
import confirmDialog from "Lib/confirmDialog";

import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

import TabPanelUpitPrivatno from "Views/Upiti/Tabs/TabPanelUpitPrivatno";
import TabPanelUpitJavno from "Views/Upiti/Tabs/TabPanelUpitJavno";
import TabPanelUpitPrijevoznici from "Views/Upiti/Tabs/TabPanelUpitPrijevoznici";
import TabPanelPonude from "Views/Upiti/Tabs/TabPanelPonude";
import TabPotvrdaRezervacije from "Views/Prijevoznici/Voznje/VoznjaFormTabs/TabPotvrdaRezervacije";
import TabPanelFaktura from "Views/Upiti/Tabs/TabPanelFaktura";

import UpitTransferOwnershipDialog from "Views/Upiti/UpitTransferOwnershipDialog";
import UserContext from "Components/UserContext/UserContext";
import dataController from "Lib/dataController";
import model from "Models/upiti";
import prijevoznikModel from "Models/a_prijevoznici";
import eval_model from "Models/p_evaluacija";
import UpitDialog from "./UpitDialog";
import withRedirect from "Components/withRedirect";
import KomunikatorSinglePage from "../Komunikator/KomunikatorSinglePage";
import { FormTabPanel } from "UI/Form";
import PrintButton from "UI/Buttons/PrintButton";
import TabEvaluacije from "Views/Prijevoznici/Voznje/VoznjaFormTabs/TabEvaluacije";
import useMarkRead from "Components/useMarkRead";
import { posloviService } from "Services/posloviService"
import { common } from "@material-ui/core/colors";
import EuroConversionComment from "Components/EuroConversionComment";


function UpitForm19(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const userContext = useContext(UserContext);

  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const { handleMarkUnread } = useMarkRead({dc: props.dc, recordId: props.record.id});

  const [currentTab, setCurrentTab] = useState(0);

  const { record, validation, fields, subModels, statuses } = props; //HOC withFormController
  const { onFieldChange, doUpdate, doRefresh } = props; //HOC withFormController
  const { refreshRecord, isPanic } = props;
  const { redirect } = props;

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleTabChange = (evt, value) => {
    setCurrentTab(value);
  };

  const dc = new dataController(model);
  const prijevozniciDc = new dataController(prijevoznikModel);
  const eval_dc = new dataController(eval_model, `poslovi/${record.posao_id}/evaluacija-prijevoznika`);

  const openTransferOwnershipDialog = (evt) => {
    dialogContext.showDialog(UpitTransferOwnershipDialog, {
      dc: dc,
      mode: "update",
      form: "transfer-ownership",
      upitId: record["id"],
      subModelDefaultValues: {
        owner_id: userContext.subjectId
      }
    });
  };

  const handleUpdate = (evt) => {
    if (doUpdate) {
      doUpdate().then((result) => {
        if (result.success) {
          snackbarContext.showNotification("update", "success");
          history.goBack();
        }
      }).catch((result) => {
        if (result.success === false && result.validationPass === false) {
          //do nothing
        } else {
          snackbarContext.showNotification(result.error, "error");
        }
      });
    }
  };

  const handleCopy = (evt) => {
    dialogContext.showDialog(UpitDialog, {
      dc: dc,
      record: record,
      mode: "insert",
      form: "insert",
      onClose: handleDialogClose
    });
  };

  const handleDialogClose = (result) => {
    if (result.id) {
      const id = result.id;
      redirect(`/a/upiti/${id}`);
    }
  };

  const handleClickBack = () => {
    history.goBack();
  };

  const isLocked = true;
  const isStorno = record.storno;
  const isAgentura = record.agentura_interni_posao_id ? true : false;
  const panelMode = isLocked ? "view" : "update";
  const interniPanelMode = isLocked ? "view" : "update";
  const javniPanelMode = isLocked ? "view" : "update";

  const commonTabProps = {
    currentTab: currentTab,
    recordId: record.id,
    subModels: subModels,
    record: record,
    validation: validation,
    onFieldChange: handleFieldChange,
    fields: fields,
    mode: panelMode
  };

  const privatniTabProps = {
    ...commonTabProps,
    mode: interniPanelMode
  };

  const header = `${t("titles.upit")} (${t("upit.statuses.19")})`;

  const recordStatus = record ? record.upit_state_id : 19;

  const printButtonTarget = currentTab === 4 ? "potvrda_rezervacije" : null;
  const printButtonText = currentTab === 4 ? t("buttons.potvrda_rezervacije") : t("buttons.print");
  const printButtonDisabled = !(currentTab === 4);

  const hasEvaluacija = record && record.evaluacija_id;

  const customColor = isStorno ? theme.palette.custom.storno :
    isPanic ? theme.palette.custom.panic :
    isAgentura ? theme.palette.custom.agentura :
    undefined;

  const customText = isStorno ? t("messages.storno") :
    isPanic ? t("messages.panic") :
    isAgentura ? t("messages.agentura") :
    undefined;

  return (
    <CardColored color={customColor}>
      <CardHeading recordStatus={recordStatus} statuses={statuses} color={customColor}>
        <Toolbar variant="dense" disableGutters={true}>
          <UpitAvatar />
          <FormHeading header={header} subheaderContent={<FormHeadingIDAndOwner record={record} />} />
          <FormFillContent/>
          {/*<CardSOSHeading isPanic={props.isPanic}/>*/}
          <DialogToolbarFillContent />
          {customText ?
            <Typography variant="button">{customText}</Typography>
            : null
          }
          <DialogToolbarFillContent />
          {
            !props.isPanic ? (
              <Fragment>
                <CardToolbarButtonAlarm record={record} type="upit" onFieldChange={onFieldChange} />
                <CardToolbarMenuButton>
                  <CardToolbarMenuItemTransferOwnership onClick={openTransferOwnershipDialog} />
                  <CardToolbarMenuItemCopy onClick={handleCopy} />
                  <CardToolbarMenuItemMarkUnread onClick={handleMarkUnread} />
                </CardToolbarMenuButton>
              </Fragment>
            ) : null
          }
          <CardToolbarButtonBack onClick={handleClickBack} />
        </Toolbar>
      </CardHeading>

      <CardContent style={{ padding: 0 }}>
        <FormTabs value={currentTab} onChange={handleTabChange} identifier={dc.getSource()}>
          <FormTab
            value={0}
            id="interni-podaci"
            label={t("upit.tabs.interni_podaci")}
            icon={<BusinessCenterIcon />}
            validation={validation}
            fields={["narucitelj_interni", "napomena_interna"]}
          />
          <FormTab
            value={1}
            id="javni-podaci"
            label={t("upit.tabs.javni_podaci")}
            icon={<PublicIcon />}
            validation={validation}
            fields={[
              "from_ts",
              "to_ts",
              "vozilo_tip_id",
              "vozilo_klasa_id",
              "vozilo_broj_putnika",
              "opis",
              "vozilo_oprema_ids",
              "trajanje_voznje",
              "neto_udaljenost_voznje",
              "udaljenost_povratka_vozila",
              "cijena_hrk",
              "rok_za_ponude"
            ]}
          />
          <FormTab
            value={2}
            id="odabir-prijevoznika"
            label={t("upit.tabs.odabir_prijevoznika")}
            icon={<AirportShuttleIcon />}
            validation={validation}
            fields={["burza_visible"]}
          />
          <FormTab
            value={3}
            id="ponude"
            label={t("upit.tabs.ponude")}
            icon={<LocalOfferIcon />}
            validation={validation}
            fields={[]}
          />
          <FormTab
            id="potvrda-rezervacije"
            value={4}
            label={t("poslovi.tabs.potvrda_rezervacije")}
            icon={<PotvrdaRezervacijeIcon />}
            aria-label="potvrda_rezervacije"
          />
          <FormTab
            id="komunikator"
            value={5}
            label={t("ponuda.tabs.komunikator")}
            icon={<AssignmentIcon />}
            aria-label="komunikator"
          />
          <FormTab
            id="faktura"
            value={6}
            label={t("upit.tabs.faktura")}
            icon={<ReceiptIcon />}
            aria-label="faktura"
          />
          <FormTab
            id="evaluacija"
            value={7}
            label={t("upit.tabs.evaluacija")}
            icon={<GradeIcon />}
            aria-label="evaluacija"
          />
        </FormTabs>
        <TabPanelUpitPrivatno tabIndex={0} {...commonTabProps} mode="update"/>
        <TabPanelUpitJavno tabIndex={1} {...commonTabProps} mode="view" />
        <TabPanelUpitPrijevoznici
          tabIndex={2}
          {...commonTabProps}
          dc={prijevozniciDc}
          disableRemove={true}
          mode="view"
        />
        <TabPanelPonude tabIndex={3} {...privatniTabProps} recordId={record.id} mode="view" />
        <FormTabPanel value={currentTab} index={4}>
          <TabPotvrdaRezervacije {...commonTabProps} index={4} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={5}>
          <KomunikatorSinglePage recordId={record.posao_id} refreshForm={refreshRecord} />
        </FormTabPanel>
        <TabPanelFaktura tabIndex={6} {...commonTabProps} mode="update"/>
        <FormTabPanel value={currentTab} index={7}>
          <TabEvaluacije
            dc={eval_dc}
            mode={!isStorno && !hasEvaluacija && record.upit_state_id !== 19 ? "insert" : "view"}
            form="default"
            refreshParent={doRefresh}
            recordId={hasEvaluacija ? record.evaluacija_id : null}
            hasEvaluacija={record.upit_state_id === 19 ? true : hasEvaluacija}
            isPosao={false}
            isPanic={isPanic}
            tabValue={6}
            defaultValues={{
              ocjena_ukupno: 5,
              vozilo_cistoca: 5,
              osoblje_urednost: 5,
              vozilo_ispravnost: 5,
              vozilo_udobnost: 5,
              dogovor_komunikacija: 5,
              vrijednost_za_novac: 5,
              osoblje_priprema: 5,
              osoblje_komunikacija: 5,
              brzina_reakcije: 5,
              vozilo_oprema: 5
            }}
          />
        </FormTabPanel>
      </CardContent>
      <CardActions>
        <EuroConversionComment />
        <FormFillContent />
        <PrintButton
          dc={dc}
          target={printButtonTarget}
          record={{ id: record.posao_id }}
          text={printButtonText}
          disabled={printButtonDisabled}
        />
        <CardActionButtonClose variant="outlined" onClick={handleClickBack} />
        {
          !props.isPanic ?
            <CardActionButtonSave variant="contained" onClick={handleUpdate} />
            : null
        }
      </CardActions>
    </CardColored>
  );
}

export default withRedirect(withFormController(UpitForm19));
