import { standardHeadersWithAuth } from "Services/helpers/standardHeadersWithAuth";
import { downloadHeadersWithAuth } from "Services/helpers/downloadHeadersWithAuth";
import { handleResponse } from "Services/helpers/handleResponse";

let api = function() {};

const apiUrl = process.env.REACT_APP_APIPATH;

api.prototype._get = function(path) {
  return fetch(path, {
    method: "GET",
    headers: standardHeadersWithAuth()
  })
    .then(handleResponse)
    .then(jsondata => {
      return Promise.resolve({ success: true, data: jsondata });
    })
    .catch(error => {
      return Promise.reject({ success: false, error: error });
    })
    .finally(() => {
    });
};

api.prototype._post = function(path, data = {}) {

  return fetch(path, {
    method: "POST",
    mode: "cors",
    headers: standardHeadersWithAuth(),
    body: JSON.stringify(data)
  })
    .then(handleResponse)
    .then(jsondata => {
      return Promise.resolve({ success: true, data: jsondata });
    })
    .catch(error => {
      return Promise.reject({ success: false, error: error });
    })
    .finally(() => {
    });
};

api.prototype._put = function(path, data = {}) {

  return fetch(path, {
    method: "PUT",
    mode: "cors",
    headers: standardHeadersWithAuth(),
    body: JSON.stringify(data)
  })
    .then(handleResponse)
    .then(jsondata => {
      return Promise.resolve({ success: true, data: jsondata });
    })
    .catch(error => {
      return Promise.reject({ success: false, error: error });
    })
    .finally(() => {
    });
};

api.prototype._patch = function(path, data = {}) {

  return fetch(path, {
    method: "PATCH",
    mode: "cors",
    headers: standardHeadersWithAuth(),
    body: JSON.stringify(data)
  })
    .then(handleResponse)
    .then(jsondata => {
      return Promise.resolve({ success: true, data: jsondata });
    })
    .catch(error => {
      return Promise.reject({ success: false, error: error });
    })
    .finally(() => {
    });
};

api.prototype._delete = function(path, data = {}) {

  return fetch(path, {
    method: "DELETE",
    mode: "cors",
    headers: standardHeadersWithAuth(),
    body: JSON.stringify(data)
  })
    .then(handleResponse)
    .then(jsondata => {
      return Promise.resolve({ success: true, data: jsondata });
    })
    .catch(error => {
      return Promise.reject({ success: false, error: error });
    })
    .finally(() => {
    });
};

api.prototype.Call = function(path, action, data) {
  const url = `${apiUrl}${path}`;

  switch (action) {
    case "get":
      return this._get(url);
    case "post":
      return this._post(url, data);
    case "put":
      return this._put(url, data);
    case "patch":
      return this._patch(url, data);
    case "delete":
      return this._delete(url, data);
    default:
      console.error("Wrong action was provided, expected one of: ", '["get", "post", "put", "patch", "delete"] ', '@api.js @api.prototype.Call');
      return new Promise(() => {});
  }
};

api.prototype.Download = function(type, key) {
  const url = `${apiUrl}${type}/${key}`;
  return fetch(url, {
    method: "GET",
    mode: "cors",
    headers: downloadHeadersWithAuth()
  })
    .then(data => {
      return data;
    })
    .catch(error => {
      return Promise.reject({ success: false, error: error });
    });
};

export default api;
