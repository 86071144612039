import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import DialogActions from "@material-ui/core/DialogActions";

//Icons
import AgenturaIcon from "Icons/AgenturaIcon";

//Custom Components
import DynamicDialog from "UI/Dialog/DynamicDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButtonCancel from "UI/Dialog/ActionButtons/DialogActionButtonCancel";
import DialogActionButtonConfirm from "UI/Dialog/ActionButtons/DialogActionButtonConfirm";
import { GridContainer, GridItem } from "UI/Grid";
import FormContent from "Components/FormContent";
import FormAvatar from "UI/Form/FormAvatar";
import withFormController from "Components/withFormController";

function AgenturaDialog(props) {
  const { record, validation, fields, mode, subModels } = props; //HOC withFormController
  const { doClose, onFieldChange, doValidate } = props; //HOC withFormController
  const { onClose, onSubmit } = props;

  const { t } = useTranslation();

  const handleSubmit = (evt) => {
    const isValid = doValidate();
    if (isValid) {
      if (onSubmit) {
        onSubmit(record);
      } else {
        console.warn("onSubmit function not provided!");
      }
    }
  }

  const handleClose = (evt) => {
    close({ dataChanged: false });
  };

  const close = (result) => {
    if (result.dataChanged) {
      doClose({ dataChanged: true, action: result.action });
    }

    if (onClose) {
      onClose();
    }
  };

  return (
    <DynamicDialog onClose={handleClose} maxWidth={"sm"}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <FormAvatar icon={<AgenturaIcon/>} />
          <DialogToolbarHeading>{t("titles.take_agentura")}</DialogToolbarHeading>
          <DialogToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer>
            <FormContent
              record={record}
              validation={validation}
              fields={fields}
              mode={mode}
              onFieldChange={onFieldChange}
              columns={1}
              fieldNames={["agentura_napomena"]}
              subModels={subModels}
            />
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonCancel variant="outlined" onClick={handleClose} />
        <DialogActionButtonConfirm variant="contained" onClick={handleSubmit} />
      </DialogActions>
    </DynamicDialog>
  );
}

AgenturaDialog.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
}

export default withFormController(AgenturaDialog);
