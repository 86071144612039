import React, { useContext } from 'react';
import PropTypes from "prop-types";

//Custom Components
import UserContext from "Components/UserContext/UserContext";

//Material-UI Core Components
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function FormTabPanel(props) {
  const { children, value, index, needPermission, padding, noBox, ...other } = props;

  const userContext = useContext(UserContext);

  return (
    needPermission && !userContext.hasAnyPermission(needPermission) ? null :
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
    {
      value === index ?
        <Box p={noBox ? 0 : 3} style={{padding: padding}}>{children}</Box>
        : null
    }
    </Typography>
  );
}

FormTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  needPermission: PropTypes.arrayOf(PropTypes.object)
};

export default FormTabPanel;
