import React from "react";

//Custom Components
import PrijevozniciPage from "UI/AppPage/PrijevozniciPage";
import ResponsiveBox from "UI/Box/ResponsiveBox";
import SchedulerView from "Components/Scheduler/SchedulerView";

const PrijevozniciKalendarPage = React.memo((props) => {
  return (
    <PrijevozniciPage>
      <ResponsiveBox>
        <SchedulerView hideTime={true}></SchedulerView>
      </ResponsiveBox>
    </PrijevozniciPage>
  );
});

export default PrijevozniciKalendarPage;
