import React, { Fragment, useState, useEffect, useContext } from 'react';

import { makeStyles } from "@material-ui/core/styles";

// Custom Components
import { komunikatorService } from "Services/komunikatorService";
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import KomunikatorContext from "Components/KomunikatorContext/KomunikatorContext";
import KomunikatorChatInput from "UI/Komunikator/KomunikatorChatInput";
import KomunikatorChatMessages from "UI/Komunikator/KomunikatorChatMessages";
import { sifrarnici } from "Lib/sifrarnici";

const useStyle = height => makeStyles((theme) => ({
	msgContainer:{
		//height: "calc(100% - 48px - 100px)",
		height: `calc(100% - 48px - ${height}px)`,
		overflow: "hidden"
	}
}))();

const inputRowHeight = 19;
const baseInputHeight = 90 - inputRowHeight; // height of the whole element when empty (19 is height of one row in textarea)

function KomunikatorChatContent(props) {
	const { channel } = props;
	const [value, setValue] = useState('');
	const [height, setHeight] = useState(baseInputHeight + inputRowHeight);
	const KC = useContext(KomunikatorContext);
	const [img, setImg] = useState(null);
	const [file, setFile] = useState(null);

	const handleChange = (evt) => {
		const value = evt.currentTarget.value;
		setValue(value && value.length === 1 ? value.trim() : value);
		
		// save element to variable because the event is released before we get to setImmediate delayed fnc call
		const T = evt.currentTarget;
		// because when event is fired the height isn't updated yet so we need a small delay
		setImmediate(() => setHeight(baseInputHeight + T.offsetHeight));
	}


	const handleSetFile = (newFile) => {
		setFile(newFile);
	}

	const handleSetImg = (newImg) => {
		setImg(newImg);
	}

	const sendMessage = () => {
		if (file || img) {
			KC.sendMessage({
				message_body: value || value.trim(),
				channel_id: channel.id,
				message_type_id: sifrarnici.msg_types.file,
				message_delivery_type_id: sifrarnici.msg_delivery_types.queued,
				file: file ? JSON.stringify(file) : null,
				img: img ? JSON.stringify(img) : null
			})
		} else if (value && value.trim().length) {
			KC.sendMessage({
				message_body: value.trim(),
				channel_id: channel.id,
				message_type_id: sifrarnici.msg_types.standard,
				message_delivery_type_id: sifrarnici.msg_delivery_types.queued
			});

		}
		setValue("");
		setImg(null);
		setFile(null);
		setHeight(baseInputHeight + inputRowHeight);
	}

	const commonProps = {
		style: { padding: 0 },
		xs: false,
		sm: 1,
		md: 2,
		lg: 3
	};

	const contentSizes = {
		xs: 12,
		sm: 10,
		md: 8,
		lg: 6,
	};

	const classes = useStyle(height);
	const isReadOnly = Boolean(channel && (channel.panic && channel.channel_type_id === sifrarnici.channel_types.main
													|| channel.channel_state_id === sifrarnici.channel_states.blocked));
	return (
		<Fragment>
			<GridContainer className={classes.msgContainer} spacing={0}>
				<GridItem {...commonProps}>
				</GridItem>
				<GridItem {...contentSizes} style={{ height: "100%" }}>
					<KomunikatorChatMessages channel={channel} />	
				</GridItem>
				<GridItem {...commonProps}>
				</GridItem>
			</GridContainer>
			<GridContainer spacing={0}>
				<GridItem {...commonProps}>
				</GridItem>
				<GridItem {...contentSizes}>
					<KomunikatorChatInput 
						msgInput={value}
						handleChange={handleChange}
						handleSetFile={handleSetFile}
						handleSetImg={handleSetImg}
						sendMessage={sendMessage}
						channelId={channel.id}
						isReadOnly={isReadOnly}
						jobId={Number(channel.posao_id)}
					/>
				</GridItem>
				<GridItem {...commonProps}>
				</GridItem>
			</GridContainer>
		</Fragment>
	);
}

export default KomunikatorChatContent;