import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Custom Components
import LoaderContext from "Components/LoaderContext/LoaderContext";
import model from "Models/ponuda";
import dataController from "Lib/dataController";
import ModelTable from "UI/Table/ModelTable";
import TableCard from "UI/Table/TableCard";
import TableCardContent from "UI/Table/TableCardContent";
import withRedirect from "Components/withRedirect";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import UserContext from "Components/UserContext/UserContext";

function PonudeTable(props) {
  const userContext = useContext(UserContext);
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);

  const { state } = useLocation();
  const { t } = useTranslation();

  const { redirect } = props;

  const [records, setRecords] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const dc = new dataController(model);

  const viewName = "moje_ponude";

  useEffect(() => {
    if (state) {
      const { active, ponuda_state_id } = state;
      const identifier = dc.getSource() + viewName;
      userContext.setRecentFilters(identifier, "active", active);
      userContext.setRecentFilters(identifier, "ponuda_state_id", ponuda_state_id);
      userContext.setRecent("toggle_filter", identifier, true);
    }
    refreshRecords();
  }, []);

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData()
      .then((resp) => {
        if (resp.success) {
          setRecords(resp.data);
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
        setLoaded(true);
      });
  };

  const handleRowAction = function (action, id) {
    if (action === "view") {
      const rec = records.find((x) => x.id === id);

      if (rec && [10, 11, 18].indexOf(rec.ponuda_state_id) >= 0) {
        redirect(`/p/ponude/${id}`);
      } else {
        redirect(`/p/voznje/${rec.posao_id}`);
      }
    } else if (action === "view_pp") {
      const rec = records.find((x) => x.id === id);
      if (rec && rec.posebna_ponuda_id) {
        redirect(`/p/posebne-ponude/${rec.posebna_ponuda_id}`);
      }
    }
  };

  const allowRowAction = function (action, id) {
    if (action === "view_pp") {
      const rec = records.find((x) => x.id === id);
      if (rec && rec.posebna_ponuda_id) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <TableCard>
      <TableCardContent>
        {loaded && (
          <ModelTable
            title={t("titles.my_ponude")}
            records={records}
            viewName={viewName}
            dc={dc}
            allowExport={true}
            allowFilter={true}
            allowAdd={false}
            allowSelection={"none"}
            handleRefresh={refreshRecords}
            onRowAction={handleRowAction}
            allowRowAction={allowRowAction}
            onDoubleClick={(id) => handleRowAction("view", id)}
          />
        )}
      </TableCardContent>
    </TableCard>
  );
}

export default withRedirect(PonudeTable);
