import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core components
import Divider from "@material-ui/core/Divider";

//Material-UI Icons
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import HeaderMenuIconButton from "UI/Header/HeaderMenuIconButton";
import HeaderMenuItem from "UI/Header/HeaderMenuItem";

function HelpButton(props) {
  const { t } = useTranslation();

  const { currentModul } = props;

  const handleClick = (evt, action) => {
    const a = document.createElement('a')
    switch (currentModul) {
      case "prijevoznici":
        a.href = action === "tutorial" ? t("help.pr_external"): t("help.pr_manual");
        if(action === "download") {
          a.download = "Upute za korištenje prijevozničkog sučelja";
        }
        break;
      case "agencije":
        a.href = action === "tutorial" ? t("help.ag_external"): t("help.ag_manual");
        if(action === "download") {
          a.download = "Upute za korištenje modul za agencije";
        }
        break;
      case "admin":
        a.href = action === "tutorial" ? t("help.adm_external"): t("help.adm_manual");
        if(action === "download") {
          a.download = "Upute za korištenje admin";
        }
        break;
    }

    document.body.appendChild(a);
    a.target="_blank"
    a.style = 'display: none';
    a.click();
    a.remove();
  }

  const handleChat = () => {

  }

  return (
      <HeaderMenuIconButton
        desc={"help"}
        icon={<HelpOutlineIcon />}
      >
        <HeaderMenuItem label={t("common.prirucnik")} onClick={(evt) => handleClick(evt, "download")}/>
        <HeaderMenuItem label={t("common.tutorial")} onClick={(evt) => handleClick(evt, "tutorial")} disabled={true}/>
        <Divider/>
        <HeaderMenuItem label={t("common.chat")} onClick={handleChat} disabled={true}/>
      </HeaderMenuIconButton>
  );
}

export default HelpButton;
