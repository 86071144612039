import React from "react";

//Material-UI Core Components
import Box from "@material-ui/core/Box";

//Custom Components
import AgencijePage from "UI/AppPage/AgencijePage";
import NotificationsTable from "Views/Notifications/NotificationsTable";

const AgencijeNotificationsPage = React.memo((props) => {

  return (
    <AgencijePage>
      <Box m={2}>
        <NotificationsTable />
      </Box>
    </AgencijePage>
  );
})

export default AgencijeNotificationsPage;
