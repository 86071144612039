import React from "react";
import PropTypes from "prop-types";

//Material-UI Core Components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark
    }
  }
}));

const CardToolbarMenuItem = React.forwardRef((props, ref) => {
  const { onClick, label, icon } = props;
  const classes = useStyles();

  return (
    <MenuItem onClick={onClick} className={classes.root} ref={ref}>
      {icon ? <ListItemIcon style={{ minWidth: 32, color: "#fff" }}>{icon}</ListItemIcon> : null}
      {label ? (
        <Typography variant="button" style={{ color: "#fff" }}>
          {label}
        </Typography>
      ) : null}
    </MenuItem>
  );
});

CardToolbarMenuItem.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func
};

export default CardToolbarMenuItem;
