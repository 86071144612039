import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import numbro from "numbro";

//Material UI
import Toolbar from "@material-ui/core/Toolbar";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Box from "@material-ui/core/Box";
import DialogActions from "@material-ui/core/DialogActions";

//Material-UI Icons
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import SaveIcon from "@material-ui/icons/Save";

//Custom Components
import withFormController from "Components/withFormController";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButton from "UI/Dialog/DialogActionButton";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogActionButtonCancel from "UI/Dialog/ActionButtons/DialogActionButtonCancel";
import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItemDelete from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemDelete";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import { GridContainer, GridItem } from "UI/Grid";
import FormContent from "Components/FormContent";
import DynamicDialog from "UI/Dialog/DynamicDialog";
import PrintButton from "UI/Buttons/PrintButton";

import DialogContext from "UI/DialogContext/DialogContext";
import ModelTable from "UI/Table/ModelTable";
import dataController from "Lib/dataController";
import modelFakture from "Models/fakture";
import finCalcmodel from "Models/fincalc";
import FakturaStavkaDialog from "Views/Shared/FakturaStavkaDialog";
import EuroConversionComment from "Components/EuroConversionComment";

function FakturaDialog(props) {
  const snackbarContext = useContext(SnackbarContext);
  const dialogContext = useContext(DialogContext);
  const { t } = useTranslation();

  const [recordsStavke, setRecordsStavke] = useState([]);

  const { isInterni } = props;
  const { dc, mode, form, record, recordId, validation, fields, subModels, allowSkica, title, stavkeApiPath } = props; //HOC withFormController
  const { onFieldChange, onClose, doValidate } = props; //HOC withFormController
  const { doInsert, doUpdate, doClose, doDelete } = props; //HOC withFormController

  const dcFaktureSkice = new dataController(modelFakture, `poslovi/${record.posao_id}/fakture-skice`)
  const dcFakturaStavke = new dataController(finCalcmodel, stavkeApiPath);

  const fNeoporezivo = "calc_neoporezivo";
  const fOporezivo = "calc_oporezivo";
  const fPorez = "calc_porez";
  const fUkupno = "calc_ukupno";
  const fStavke = "stavke";

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  useEffect(() => {
    if (mode === "view" || mode === "update") {
      if (recordId) {
        refreshStavke();
      }
    }

  }, [recordId])

  useEffect(() => {
    calculateTotals();
    if (onFieldChange) {
      onFieldChange(recordsStavke, fStavke);
    }
  }, [recordsStavke]);

  const refreshStavke = () => {
    dcFakturaStavke.GetData().then((resp) => {
      if (resp.success) {
        const stavke = Array.isArray(resp.data) ? resp.data : [];
        setRecordsStavke(stavke);
        if (onFieldChange) {
          onFieldChange(stavke, fStavke);
        }
      }
    })

  }

  const calculateTotals = () => {
    if (Array.isArray(recordsStavke) && recordsStavke.length > 0) {
      const calc_neoporezivo = recordsStavke
        .filter((x) => x.pdv_stopa === 0 || x.pdv_stopa.value === 0)
        .map((x) => numbro(x.iznos_neto).value())
        .reduce((a, b) => a + b, 0);
      const calc_oporezivo = recordsStavke
        .filter((x) => x.pdv_stopa === 25 || x.pdv_stopa.value === 25)
        .map((x) => numbro(x.iznos_neto).value())
        .reduce((a, b) => a + b, 0);

      const calc_porez = recordsStavke.map((x) => numbro(x.pdv_iznos).value()).reduce((a, b) => a + b, 0);
      const calc_ukupno = recordsStavke.map((x) => numbro(x.iznos_bruto).value()).reduce((a, b) => a + b, 0);

      if (onFieldChange) {
        onFieldChange(calc_neoporezivo, fNeoporezivo);
        onFieldChange(calc_oporezivo, fOporezivo);
        onFieldChange(calc_porez, fPorez);
        onFieldChange(calc_ukupno, fUkupno);
      }
    } else {
      if (onFieldChange) {
        onFieldChange(null, fNeoporezivo);
        onFieldChange(null, fOporezivo);
        onFieldChange(null, fPorez);
        onFieldChange(null, fUkupno);
      }
    }
  };

  const handleSaveDraft = () => {
    if (record.id) {
      dcFaktureSkice.UpdateRecord(record.id, record)
        .then((result)=> {
          if (result.success) {
            close({ dataChanged: true, action: "update", id: record.id });
            snackbarContext.showNotification("update", "success");
          }
        })
        .catch((result) => {
          snackbarContext.showNotification(result.error.message, "error");
        });

    } else {
      dcFaktureSkice.InsertRecord(record)
        .then((result)=> {
          if (result.success) {
            close({ dataChanged: true, action: "insert" });
            snackbarContext.showNotification("insert", "success");
          }
        })
        .catch((result) => {
          snackbarContext.showNotification(result.error.message, "error");
        });
    }
  };

  const handleDeleteSkica = () => {
    dcFaktureSkice.DeleteRecord(record.id)
      .then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "delete" });
          snackbarContext.showNotification("delete", "success");
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error.message, "error");
      });

  };

  const handleSaveAndSend = () => {
    if (doValidate) {
      const valid = doValidate();
      if (valid) {
        if (record.id) {
          const path = `poslovi/${record.posao_id}/fakture-skice/${record.id}/publish`
          dcFaktureSkice.CustomPutAction(path, record)
          .then((result) => {
            if (result.success) {
              close({ dataChanged: true, action: "insert" });
              snackbarContext.showNotification("update", "success");
            }
          })
          .catch((result) => {
            snackbarContext.showNotification(result.error.message, "error");
          });
        } else {
          doInsert()
            .then((result) => {
              if (result.success) {
                close({ dataChanged: true, action: "insert" });
                snackbarContext.showNotification("update", "success");
              }
            })
            .catch((result) => {
              snackbarContext.showNotification(result.error.message, "error");
            });
        }
      }
    }
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
        }
      });
    }
  };

  const close = (result) => {
    if (result.dataChanged) {
      onClose(result);
    } else {
      onClose({ dataChanged: false });
    }
  };

  const handleAddStavka = () => {
    dialogContext.showDialog(
      FakturaStavkaDialog,
      {
        dc: dcFakturaStavke,
        mode: "insert",
        form: "default",
        onClose: handleDialogStavkaClose
      },
      2
    );
  };

  const handleDialogStavkaClose = (result) => {
    if (result.dataChanged) {
      switch (result.action) {
        case "insert":
          const minId = recordsStavke.length > 0 ? Math.min(...recordsStavke.map((x) => x.id)) : 0;
          const newId = minId - 1;
          setRecordsStavke((prevState) => {
            return prevState.concat([Object.assign({ id: newId }, result.record)]);
          });
          break;
        case "update":
          setRecordsStavke((prevState) => {
            return prevState.map((x) => (x.id === result.id ? result.record : x));
          });
          break;
        case "delete":
          setRecordsStavke((prevState) => {
            return prevState.filter((x) => x.id !== result.id);
          });
          break;
      }
    }
    dialogContext.hideDialog(2);
  };

  const handleRowAction = (action, id) => {
    if (action === "edit") {
      const rec = recordsStavke.find((x) => x.id === id);
      if (rec) {
        dialogContext.showDialog(
          FakturaStavkaDialog,
          {
            dc: dcFakturaStavke,
            mode: "update",
            form: "default",
            record: rec,
            onClose: handleDialogStavkaClose
          },
          2
        );
      }
    }
  };

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: handleFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels
  };

  const isViewOnly = mode === "view" ? true : false;

  return (
    <DynamicDialog onClose={handleClose} maxWidth={"lg"}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogAvatar ariaLabel="" icon={<MonetizationOnIcon />} />
          <DialogToolbarHeading>{title ? title : t("titles.faktura")}</DialogToolbarHeading>
          <DialogToolbarFillContent />
          {mode === "update" ? (
            <DialogToolbarMenuButton>
              <DialogToolbarMenuItemDelete onClick={handleDeleteSkica} />
            </DialogToolbarMenuButton>
          ) : null}
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer>
            <FormContent
              fieldNames={[
                "izdavatelj_bs_id",
                form === "ip" ? "kupac_komitent_id" : "kupac_bs_id",
                "oznaka_fakture",
                "poziv_na_broj",
                "datum_isporuke",
                "ts_izdavanja"
              ]}
              {...commonProps}
              columns={2}
            />
            <GridItem xs={12}>
              <ModelTable
                title={t("titles.faktura_stavke")}
                records={recordsStavke}
                dc={dcFakturaStavke}
                viewName={isViewOnly ? "readonly_noview" : "default"}
                allowExport={false}
                allowFilter={false}
                allowAdd={isViewOnly ? false : true}
                addLabel={t("buttons.new_f")}
                handleAdd={handleAddStavka}
                allowSelection={"none"}
                onRowAction={handleRowAction}
              />
            </GridItem>
            <FormContent
              fieldNames={["calc_neoporezivo", "calc_oporezivo", "calc_porez", "calc_ukupno", "faktura_napomena"]}
              {...commonProps}
              columns={2}
            />
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <EuroConversionComment />
        <DialogToolbarFillContent />
        {mode === "view" || mode === "update" ?
        <PrintButton
          dc={dc}
          target={"faktura"}
          record={record}
          text={t("buttons.print")}
          disabled={false}
        /> : null }
        {isViewOnly ? (
          <DialogActionButtonClose variant="contained" onClick={handleClose} />
        ) : (
          <>
            <DialogActionButtonCancel variant="outlined" onClick={handleClose} />
            {allowSkica ? (
              <DialogActionButton variant="outlined" onClick={handleSaveDraft} startIcon={<DraftsIcon />}>
                {t("buttons.save_draft")}
              </DialogActionButton>
            ) : null}
            {isInterni ?
              <DialogActionButton variant="contained" onClick={handleSaveAndSend} startIcon={<SaveIcon />}>
                {t("buttons.save")}
              </DialogActionButton>
            :
              <DialogActionButton variant="contained" onClick={handleSaveAndSend} startIcon={<SendIcon />}>
                {t("buttons.save_and_send")}
              </DialogActionButton>
            }
          </>
        )}
      </DialogActions>
    </DynamicDialog>
  );
}

export default withFormController(FakturaDialog);
