import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Custom Components
import ModelTable from "UI/Table/ModelTable";
import TableCard from "UI/Table/TableCard";
import TableCardContent from "UI/Table/TableCardContent";
import DialogContext from "UI/DialogContext/DialogContext";
import model from "Models/posebnePonude";
import dataController from "Lib/dataController";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import UserContext from "Components/UserContext/UserContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import withRedirect from "Components/withRedirect";
import PrijevoznikPublicDialog from "Views/Agencije/Trazilica/PrijevoznikPublicDialog";
import VoziloPublicDialog from "Views/Agencije/Trazilica/VoziloPublicDialog";

function AgencijePosebnePonudeTable(props) {
  const dialogContext = useContext(DialogContext);
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);
  const userContext = useContext(UserContext);

  const { t } = useTranslation();
  const [records, setRecords] = useState([]);

  const { redirect } = props;

  const dc = new dataController(model, "posebne-ponude/?select=public");

  useEffect(() => {
    if (userContext.subjectId) {
      refreshRecords();
    }
  }, [userContext.subjectId]);

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData()
      .then((resp) => {
        if (resp.success) {
          setRecords(resp.data || []);
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const showPrijevoznikDialog = (id) => {
    dialogContext.showDialog(PrijevoznikPublicDialog, {
      recordId: id,
      sendUpit: false
    });
  };

  const showVoziloDialog = (id) => {
    dialogContext.showDialog(VoziloPublicDialog, {
      recordId: id
    });
  };

  const handleRowAction = function (action, id) {
    if (action === "view") {
      const rec = records.find((x) => x.id === id);
      if (rec && rec.moj_odgovor === 3) {
        redirect(`/a/upiti/${rec.upit_id}`);
      } else {
        redirect(`/a/posebne-ponude/${id}`);
      }
    } else if (action === "prijevoznik") {
      showPrijevoznikDialog(id);
    } else if (action === "vozilo") {
      showVoziloDialog(id);
    }
  };

  const allowRowAction = function (action, id) {
    return true;
  };

  return (
    <TableCard>
      <TableCardContent>
        <ModelTable
          title={t("titles.posebne_ponude")}
          viewName={"agencija"}
          records={records}
          dc={dc}
          allowExport={true}
          allowFilter={true}
          allowAdd={false}
          allowSelection={"none"}
          onRowAction={handleRowAction}
          allowRowAction={allowRowAction}
          handleRefresh={refreshRecords}
          onDoubleClick={(id) => handleRowAction("view", id)}
          subModelDefaultValues={{
            prijevoznik_id: userContext.subjectId
          }}
        />
      </TableCardContent>
    </TableCard>
  );
}

export default withRedirect(AgencijePosebnePonudeTable);
