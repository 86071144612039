import React, { Fragment } from 'react';
import PropTypes from "prop-types";

//Custom Components
import { GridItem } from "UI/Grid";
import Typography from "@material-ui/core/Typography";

import formGenerator from "Lib/formGenerator";

const customBottomStyle = {
  alignSelf: "flex-end"
}

const customFormHeightStyle = {
  height: "80px"
}

class FormContent extends React.Component {

  constructor(props) {
    super(props);

    this.handleFieldChange = this.handleFieldChange.bind(this);

    this.fg = new formGenerator();
  };

  handleFieldChange(value, source) {
    const { onFieldChange, onSubmit } = this.props;

    if(value && typeof value == 'object' && value.keyCode && value.keyCode === 13){
      if (onSubmit){
        onSubmit()
      } else {
        return
      }
    } else if (onFieldChange) {
      onFieldChange(value, source);
    }
  }

  getWidth(f) {
    const { columns } = this.props;

    if (f && f.width && f.width === 'full') {
      return 12;
    } else {
      switch (columns) {
        case 1:
          return 12;
        case 2:
          return 6;
        case 3:
          return 4;
        case 4:
          return 3;
        default:
          return 6;
      }
    }
  }

  render() {
    const { title, mode, record, fieldNames, validation, subModels, fields } = this.props;
    const renderFields = fieldNames ? fieldNames.map(name => fields.find(f => f.source === name)) : [];

    let controls = {};
    switch (mode) {
      case 'insert':
      case 'update': {
        controls = this.fg.generateFormControls(renderFields, "form", "edit", record, validation, this.handleFieldChange, subModels);
        break;
      }
      case 'view': {
        controls = this.fg.generateFormControls(renderFields, "form", "view", record, null, null, subModels);
        break;
      }
      case 'simpleView':{
        controls = this.fg.generateFormControls(renderFields, "form", "simpleView", record, null, null, subModels);
        break;
      }
    }

    renderFields.map(field => {

      field.control = controls.hasOwnProperty(field.source) ? controls[field.source] : "-"
    })

    return (
      <Fragment>
        { title ?
          <GridItem xs={12}>
            <Typography variant="h6" component="h2" paragraph={true}>{title}</Typography>
          </GridItem>
        : null}
        {renderFields.map((f,i) =>
          <GridItem xs={12} sm={this.getWidth(f)} key={'grid-'+i} style={f.bottom ? customBottomStyle : f.originalHeight ? customFormHeightStyle : null}>
          {f.control}
          </GridItem>
        )}
      </Fragment>
    );
  };
}

FormContent.defaultProps = {
  mode: "update",
  columns: 2,
  onClose: () => { }
}

FormContent.propTypes = {
  title: PropTypes.string
  , subModels: PropTypes.object
  , mode: PropTypes.oneOf(['view', 'insert', 'update', 'simpleView'])
  , onFieldChange: PropTypes.func
  , onClose: PropTypes.func
  , fieldNames: PropTypes.arrayOf(PropTypes.string).isRequired
  // , record: PropTypes.object.isRequired //record is null on first render, will always throw error
  , validation: PropTypes.object
  , fields: PropTypes.arrayOf(PropTypes.object).isRequired
  , columns: PropTypes.oneOf([1, 2, 3, 4])
};

export default FormContent;
