import React, { useContext, useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment";

//Materia-UI Core Elements
import IconButton from "@material-ui/core/IconButton";

//Material-UI Icons
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";

//Custom Components
import DialogContext from "UI/DialogContext/DialogContext";
import AlarmDialog from "Views/AlarmDialog";
import model from "Models/alarms";
import dataController from "Lib/dataController";
import { Typography } from "@material-ui/core";
import { formats } from "Lib/formats";

import useAlarm from "Components/useAlarm/useAlarm";

function CardToolbarButtonAlarm(props) {
  const dialogContext = useContext(DialogContext);

  const { handleOpenAlarmDialog, getAlarmIcon } = useAlarm({record: props.record, alarmType: props.type, onFieldChange: props.onFieldChange });

  const { record, onClose, onSave, ...other } = props;

  const alarm_ts = record ? record.alarm_ts : undefined;
  const alarm_active = record && record.alarm_active !== undefined && record.alarm_active !== null ? record.alarm_active : true;
  const alarm_remark = record ? record.alarm_remark : undefined;

  const icon = props.icon ? props.icon : getAlarmIcon(alarm_active, alarm_ts);

  const dc = new dataController(model);

  const handleAlarmClose = () => {
    dialogContext.hideDialog();
  };

  const handleClick = useCallback(
    (evt) => {
      const alarmRecord = {
        alarm_ts: alarm_ts,
        alarm_active: alarm_active,
        alarm_remark: alarm_remark
      };

      dialogContext.showDialog(AlarmDialog, {
        dc: dc,
        mode: "insert",
        form: "default",
        onClose: handleAlarmClose,
        onSave: onSave,
        icon: icon,
        defaultValues: alarmRecord
      });
    },
    [alarm_ts, alarm_active, alarm_remark]
  );

  return (
    <>
      <div>
        <Typography align="right" variant="caption" component="p">
          {alarm_ts ? moment.utc(alarm_ts).local().format(formats.datetime) : null}
        </Typography>
        <Typography align="right" variant="caption" component="p">
          {alarm_remark}
        </Typography>
      </div>
      <IconButton className="nodrag" color="inherit" {...other} onClick={handleOpenAlarmDialog} >
        {icon}
      </IconButton>
    </>
  );
}

CardToolbarButtonAlarm.propTypes = {
  record: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func
};

export default CardToolbarButtonAlarm;
