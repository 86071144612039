import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";

import makeStyles from "@material-ui/core/styles/makeStyles";

//Custom Components
import AppHeader from "UI/Header/AppHeader";
import Footer from "UI/Footer/Footer";
import ChatSidebar from "UI/Chat/ChatSidebar";
import Sidebar from "UI/Sidebar/Sidebar";

import menuRoutes from "Routes/menu";

import UserContext from "Components/UserContext/UserContext";
import Loader from "UI/Loader/Loader";

import withRedirect from "Components/withRedirect";

const style = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    overflowX: "auto",
    marginTop: "49px !important"
    //padding: theme.spacing(3),
  }
}));

const getSidebarSetting = () => {
  let sidebarSetting = localStorage.getItem('sidebar');
  if (sidebarSetting === undefined || sidebarSetting === null) {
    return false;
  } else if (sidebarSetting === "false") {
    return false;
  } else if (sidebarSetting === "true") {
    return true;
  } else {
    return falase;
  }
}

const AppPage = React.memo((props) => {
  const userContext = useContext(UserContext);

  const [sidebarOpen, setSidebarOpen] = useState(getSidebarSetting());

  const { needPermission, menu, modul } = props;
  const { redirect } = props; //HOC withRedirect

  const classes = style();

  const toggleSidebar = () => {
    localStorage.setItem('sidebar', sidebarOpen ? false : true);
    setSidebarOpen((prevState) => !prevState);
  };

  const forbidden = needPermission && !userContext.hasAnyPermission(needPermission);

  useEffect(() => {
    if (userContext && userContext.loaded && forbidden) {
      console.warn("Tried to access forbidden page, Redirecting...");
      redirect("/");
    }
  }, [userContext.loaded, forbidden]);

  return userContext && userContext.loaded && !forbidden ? (
    <div id="page">
      <AppHeader menu={menu || menuRoutes} modul={modul} sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      <Sidebar menu={menu || menuRoutes} sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      <main id="content-wrapper" className={classes.content}>
        <div id="content">{props.children}</div>
      </main>
      <ChatSidebar modul={modul} />
    </div>
  ) : (
    <Loader open={true} />
  );
});

AppPage.defaultProps = {
  needPermission: []
};

AppPage.propTyps = {
  needPermission: PropTypes.arrayOf(PropTypes.object)
};

export default withRedirect(AppPage);
