import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

//Material-UI Core Components
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

//CBC Icons
import RezervacijaIcon from "Icons/RezervacijaIcon";

//Custom Components
import withFormController from "Components/withFormController";
import { GridContainer, GridItem } from "UI/Grid";
import { CardHeading, CardToolbarMenuButton, CardToolbarButtonBack } from "UI/Card";
import { CardToolbarMenuItemDelete } from "UI/Card";
import { CardActionButtonClose, CardActionButtonSave } from "UI/Card";
import { FormAvatar, FormHeading, FormFillContent } from "UI/Form";

import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import FormContent from "Components/FormContent";

import { sifrarnici } from "Lib/sifrarnici";

const pickerList = ["vozac_id", "voznja_id", "vozilo_id"];
const pickerIntervals = {
  voznja_id: [20, 30],
  vozilo_id: [30, 40],
  vozac_id: [40, 50]
};

function RezervacijaForm(props) {
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();
  const history = useHistory();

  const { dc, record, form, mode, validation, fields, subModels, recordId } = props; //HOC withFormController
  const { doUpdate, doDelete, doClose } = props; //HOC withFormController
  const { onFieldChange, onClose } = props; //HOC withFormController

  const [pickerField, setPickerField] = useState(0);
  const initialPickerField = mode === "update" && record.tip_id ? Math.floor(record.tip_id / 10) : null;

  const handleFieldChange = (value, source) => {
    if (source === "tip_id") {
      const val = value && (value.value || value);
      // since value of this picker is either 0, [20, 30), [30, 40) or [40, 50) its corresponding picker equals `val // 10`
      setPickerField(Math.floor(val / 10));
    }
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleDelete = (evt) => {
    if (doDelete) {
      doDelete().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "" });
        }
      });
    }
  };

  const handleUpdate = (evt) => {
    if (doUpdate) {
      doUpdate().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "update" });
          snackbarContext.showNotification("update", "success");
        } else {
          snackbarContext.showNotification(result.error, "error");
        }
      }).catch((result) => {
        if (result.success === false && result.validationPass === false) {
          //do nothing
        } else {
          snackbarContext.showNotification(result.error, "error");
        }
      });;
    }
  };

  const handleClickBack = () => {
    history.goBack();
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const close = (result) => {
    if (result.dataChanged) {
      onClose({ dataChanged: true, action: result.action });
    }
  };

  const getPickerField = (pFieldVal) => {
    const keys = Object.keys(sifrarnici.rezervacija_picker);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (sifrarnici.rezervacija_picker[key] === pFieldVal) {
        if (key == "none") {
          return 0;
        }
        return key;
      }
    }
    return 0;
  };

  const commonProps = {
    recordId: recordId,
    mode: mode,
    record: record,
    validation: validation,
    fields: fields,
    subModels: subModels,
    onFieldChange: handleFieldChange
  };

  const pField = getPickerField(pickerField || initialPickerField);
  pickerList.forEach((p) => {
    const currPicker = fields.find((f) => f.source === p);
    //return;
    if (currPicker.source === pField) {
      currPicker.validation = { required: true };
    } else {
      currPicker.validation = { required: false };
    }
  });

  return (
    <Card>
      <CardHeading>
        <Toolbar variant="dense" disableGutters={true}>
          <FormAvatar icon={<RezervacijaIcon />} />
          <FormHeading header={t("titles.rezervacija")} subheader={" (ID=" + recordId + ")"} />
          <FormFillContent />
          <CardToolbarMenuButton>
            <CardToolbarMenuItemDelete onClick={handleDelete} />
          </CardToolbarMenuButton>
          <CardToolbarButtonBack onClick={handleClickBack} />
        </Toolbar>
      </CardHeading>
      <CardContent>
        <Box m={2}>
          <GridContainer>
            <FormContent {...commonProps} fieldNames={["alarm_ts", "alarm_active", "tip_id"]} />
            <FormContent {...commonProps} fieldNames={[pField ? pField : "empty_picker"]} />
            <FormContent {...commonProps} fieldNames={["ts_from", "ts_to", "opis"]} />
          </GridContainer>
        </Box>
      </CardContent>
      <CardActions>
        <FormFillContent />
        <CardActionButtonClose variant="outlined" onClick={handleClickBack} />
        <CardActionButtonSave variant="contained" onClick={handleUpdate} />
      </CardActions>
    </Card>
  );
}

export default withFormController(RezervacijaForm);
