import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

//Custom components
import LoaderContext from "Components/LoaderContext/LoaderContext";
import GridContainer from "UI/Grid/GridContainer";
import FormContent from "Components/FormContent";
import dataController from "Lib/dataController";
import model from "Models/potvrdaRezervacije";
import api from "Lib/api";
import IconView from "UI/Form/IconView";

function TabPotvrdaRezervacije(props) {
  const loaderContext = useContext(LoaderContext);
  const { record, validation, fields, onFieldChange, mode, subModels } = props;
  const [potvrdaRecord, setPotvrdaRecord] = useState(null);
  const apiInstance = new api();
  const {t} = useTranslation();

  const dc = new dataController(model);

  useEffect(() => {
    if(record && (record.posao_id || record.id) && potvrdaRecord === null) {
      const path = record.posao_id ? record.posao_id : record.id;
      loaderContext.toggleLoading(true);
      apiInstance.Call(`poslovi/${path}/potvrda-rezervacije`, "get").then((resp) => {
        setPotvrdaRecord(resp.data)
      }).finally(() => {
        loaderContext.toggleLoading(false);
      });
    }
  }, [record])

  const commonProps = {
    record: potvrdaRecord,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: dc.getFields(["cbc_avans_iznos", "cbc_iban", "cbc_model", "cbc_poziv_na_broj", "datum_potvrde", "from_loc_name", "from_ts", "to_loc_name",
    "to_ts", "narucitelj_naziv", "ponuda_avans_iznos", "ponuda_avans_rok_uplata", "ponuda_iznos", "ponuda_napomena", "vozilo_name",
    "vozilo_opis", "prijevoznik_name", "stajalista_names", "upit_broj_putnika", "upit_cijena_hrk", "upit_opis",
    "upit_tip_vozila_name", "ponuda_vozilo_opis"]),
    mode: "simpleView",
    subModels: subModels
  };
  return (
    <Box m={2}>
      { potvrdaRecord ? (
        <Fragment>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <IconView icon="" title={t("common.agencija")} value={potvrdaRecord.narucitelj_naziv}/>
            </AccordionSummary>
            <AccordionDetails>
        <GridContainer>
              <FormContent
                  fieldNames={["from_ts", "to_ts","from_loc_name", "stajalista_names", "to_loc_name" ]}
                  {...commonProps}
                  columns={2}
              />
              <FormContent
                  fieldNames={["upit_broj_putnika", "upit_tip_vozila_name", "upit_opis", "upit_cijena_hrk"]}
                  {...commonProps}
                  columns={2}
              />
        </GridContainer>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panella-content" id="panella-header">
              <IconView icon="" title={t("common.prijevoznik")} value={potvrdaRecord.prijevoznik_name}/>
            </AccordionSummary>
            <AccordionDetails>
              <GridContainer>
                <FormContent
                  fieldNames={["ponuda_napomena"]}
                  {...commonProps}
                  columns={2}
                />
                <FormContent
                  fieldNames={["vozilo_name", "ponuda_iznos",  "datum_potvrde"]}
                  {...commonProps}
                  columns={2}
                />
              </GridContainer>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panella-content" id="panella-header">
              <IconView title={t("common.vise_detalja")}/>
            </AccordionSummary>
            <AccordionDetails>
              <GridContainer>
                <FormContent
                  fieldNames={["cbc_avans_iznos", "ponuda_avans_iznos", "ponuda_avans_rok_uplata"]}
                  {...commonProps}
                  columns={3}
                />
                <FormContent
                  fieldNames={["cbc_iban", "cbc_model", "cbc_poziv_na_broj"]}
                  {...commonProps}
                  columns={3}
                />
              </GridContainer>
            </AccordionDetails>
          </Accordion>
        </Fragment>
      ) : null }
      </Box>
  );
}

export default TabPotvrdaRezervacije;
