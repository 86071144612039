import React from "react";

//Material-UI Core Components
import { useTheme } from "@material-ui/core/styles";

function SchedulerLegend(props) {
  const { show } = props;

  const theme = useTheme();

  const getStatusName = (status) => {
    switch (status) {
      case 20:
        return " Priprema ponude ";
      case 21:
        return " Ponuda izdana, čekamo potvrdu kupca ";
      case 22:
        return " Ponuda prihvaćena, priprema ugovora ";
      case 23:
        return " Ugovoreno, priprema potvrde usluge ";
      case 24:
        return " Usluga potvrđena, priprema putnih naloga ";
      case 25:
        return " Vožnja u tijeku, praćenje aksidenata ";
      case 26:
        return " Vožnja završena, sređivanje papirologije ";
      case 29:
        return " Vožnja zatvorena i arhivirana ";
      default:
        return "";
    }
  };

  const getStatusLegend = () => {
    return [20, 21, 22, 23, 24, 25, 26, 29].map((status, i) => {
      const color1 = theme.palette.trjobstatus[status];
      const background = `${color1}`;

      const style = {
        background: background,
        borderRadius: "15px",
        width: "30px",
        textAlign: "center",
        display: "inline-block"
      };

      return (
        <div key={`group-${i}`} style={{ padding: "2px" }}>
          <div key={`color-${i}`} style={style}>
            {status}
          </div>
          <div key={`text-${i}`} style={{ display: "inline-block", paddingLeft: "5px" }}>
            {getStatusName(status)}
          </div>
        </div>
      );
    });
  };

  const getPanicLegend = () => {
    const style = {
      background: theme.palette.error.main,
      borderRadius: "15px",
      width: "30px",
      textAlign: "center",
      display: "inline-block"
    };
    return (
      <div key="group-panic" style={{ padding: "2px" }}>
        <div key="color-error" style={style}>
          30
        </div>
        <div key="text-error" style={{ display: "inline-block", paddingLeft: "5px" }}>
          S.O.S.
        </div>
      </div>
    );
  }

  const getTimeLeftLegend = () => {
    const alarms = {
      lt_2h: "2 sata",
      lt_1d: "1 dana",
      lt_3d: "3 dana",
      lt_1w: "tjedna",
      lt_2w: "dva tjedna",
      lt_1m: "godinu dana"
    };

    return Object.keys(theme.palette.alarm_za).map((key, i) => {
      const style = {
        background: theme.palette.alarm_za[key],
        width: "30px",
        display: "inline-block"
      };

      return (
        <div key={`group-${i}`} style={{ padding: "2px" }}>
          <div key={`color-${i}`} style={style}>
            &nbsp;
          </div>
          <div
            key={`text-${i}`}
            style={{ display: "inline-block", paddingLeft: "5px" }}
          >{`Manje od ${alarms[key]}`}</div>
        </div>
      );
    });
  };

  const getZadaciLegend = () => {
    // border: 2px solid rgb(244, 143, 177); // vozaci
    // border: 2px solid rgb(0, 85, 160); // Ostalo
    // border: 2px solid rgb(236, 64, 122) // Vozila
    const border = ["1px solid rgb(236, 64, 122)", "1px solid rgb(0, 85, 160)", "1px solid rgb(244, 143, 177)"];
    return ["Vozila", "Vozači", "Ostalo"].map((zadatak, i) => {
      const style = {
        border: border[i],
        borderRadius: "15px",
        width: "30px",
        display: "inline-block"
      };

      return (
        <div key={`group-${i}`} style={{ padding: "2px" }}>
          <div key={`color-${i}`} style={style}>
            &nbsp;
          </div>
          <div key={`text-${i}`} style={{ display: "inline-block", paddingLeft: "5px" }}>
            {zadatak}
          </div>
        </div>
      );
    });
  };

  return (
    <div style={{ padding: "2px 0" }}>
      &nbsp;&nbsp;Status
      {getStatusLegend()}
      {getPanicLegend()}
      <hr style={{ margin: "4px" }} />
      {show === "trjob" ? (
        <div>
          &nbsp;&nbsp;Preostalo vremena:
          {getTimeLeftLegend()}
        </div>
      ) : show === "schrequest" ? (
        <div>
          &nbsp;&nbsp;Zadaci:
          {getZadaciLegend()}
        </div>
      ) : null}
    </div>
  );
}

export default SchedulerLegend;
