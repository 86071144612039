import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

//Material-UI Core Components
import Toolbar from "@material-ui/core/Toolbar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

//Material-UI Icons
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import PublicIcon from "@material-ui/icons/Public";
import SendIcon from "@material-ui/icons/Send";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";

//Custom Components
import withFormController from "Components/withFormController";
import {
  CardActionButton,
  CardActionButtonClose,
  CardHeading,
  CardToolbarButtonAlarm,
  CardToolbarButtonBack,
  CardToolbarMenuButton,
  CardToolbarMenuItemCopy,
  CardToolbarMenuItemDelete,
  CardToolbarMenuItemSave,
  CardToolbarMenuItemTransferOwnership
} from "UI/Card";
import { FormFillContent, FormHeading, FormHeadingIDAndOwner, FormTab, FormTabs } from "UI/Form";
import UpitAvatar from "UI/Form/Avatars/UpitAvatar";

import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

import TabPanelUpitPrivatno from "Views/Upiti/Tabs/TabPanelUpitPrivatno";
import TabPanelUpitJavno from "Views/Upiti/Tabs/TabPanelUpitJavno";
import TabPanelUpitPrijevoznici from "Views/Upiti/Tabs/TabPanelUpitPrijevoznici";

import UpitTransferOwnershipDialog from "Views/Upiti/UpitTransferOwnershipDialog";
import UserContext from "Components/UserContext/UserContext";
import dataController from "Lib/dataController";
import prijevoznikModel from "Models/a_prijevoznici";
import { upitService } from "Services/upitService";
import UpitDialog from "./UpitDialog";
import withRedirect from "Components/withRedirect";
import EuroConversionComment from "Components/EuroConversionComment";

function UpitForm10(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const userContext = useContext(UserContext);

  const history = useHistory();
  const { t } = useTranslation();

  const [dataChanged, setDataChanged] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  const { dc, record, recordId, validation, fields, subModels, statuses } = props; //HOC withFormController
  const { doDelete, doValidate } = props; //HOC withFormController
  const { onFieldChange, doClose } = props; //HOC withFormController

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleTabChange = (evt, value) => {
    setCurrentTab(value);
  };

  const prijevozniciDc = new dataController(prijevoznikModel);

  const openTransferOwnershipDialog = (evt) => {
    dialogContext.showDialog(UpitTransferOwnershipDialog, {
      dc: dc,
      mode: "update",
      form: "transfer-ownership",
      upitId: record["id"],
      subModelDefaultValues: {
        owner_id: userContext.subjectId
      }
    });
  };

  const handleDelete = (evt) => {
    doDelete("upiti/skice/").then((result) => {
      if (result.success) {
        snackbarContext.showNotification("delete", "success");
        history.goBack();
      }
    }).catch((result) => {
      snackbarContext.showNotification(result.error, "error");
    });
  };

  const handleCopy = (evt) => {
    dialogContext.showDialog(UpitDialog, {
      dc: dc,
      record: record,
      mode: "insert",
      form: "insert",
      onClose: handleDialogClose
    });
  };

  const handleDialogClose = (result) => {
    if (result.id) {
      const id = result.id;
      redirect(`/a/upiti/${id}`);
    }
  };

  const publishUpit = () => {
    if (doValidate) {
      const isValid = doValidate();
      if (isValid) {
        const preparedData = dc.prepareSendingData(record);
        upitService.publishUpit(record["id"], preparedData).then((resp) => {
          if (resp.success) {
            snackbarContext.showNotification("notifications.published_upit", "success");
            history.goBack();
          } else {
            snackbarContext.showNotification(resp.error.message, "error");
          }
        });
      }
    }
  };

  const saveDraft = () => {
    const preparedRecord = dc.prepareSendingData(record);
    upitService.updateUpitDraft(record["id"], preparedRecord).then((resp) => {
      if (resp.success) {
        snackbarContext.showNotification("notifications.updated_draft", "success");
        history.goBack();
      } else {
        snackbarContext.showNotification("notifications.updated_draft", "success");
      }
    });
  };

  const handleClickBack = () => {
    history.goBack();
  };


  const isLocked = false;
  const panelMode = isLocked ? "view" : "update";

  const commonTabProps = {
    currentTab: currentTab,
    recordId: recordId,
    subModels: subModels,
    record: record,
    validation: validation,
    onFieldChange: handleFieldChange,
    fields: fields,
    mode: panelMode
  };

  const header = `${t("titles.upit")} (${t("upit.statuses.10")})`;

  const recordStatus = record ? record.upit_state_id : 10;

  return (
    <Card>
      <CardHeading recordStatus={recordStatus} statuses={statuses}>
        <Toolbar variant="dense" disableGutters={true}>
          <UpitAvatar />
          <FormHeading header={header} subheaderContent={<FormHeadingIDAndOwner record={record} />} />
          <FormFillContent />
          <CardToolbarButtonAlarm record={record} type="upitskica" onFieldChange={onFieldChange} />
          <CardToolbarMenuButton>
            <CardToolbarMenuItemSave onClick={saveDraft} label={"buttons.save_skica"} />
            <CardToolbarMenuItemTransferOwnership onClick={openTransferOwnershipDialog} />
            <CardToolbarMenuItemCopy onClick={handleCopy} />
            <CardToolbarMenuItemDelete onClick={handleDelete} />
          </CardToolbarMenuButton>
          <CardToolbarButtonBack onClick={handleClickBack} />
        </Toolbar>
      </CardHeading>

      <CardContent style={{ padding: 0 }}>
        <FormTabs value={currentTab} onChange={handleTabChange} identifier={dc.getSource()}>
          <FormTab
            value={0}
            id="interni-podaci"
            label={t("upit.tabs.interni_podaci")}
            icon={<BusinessCenterIcon />}
            validation={validation}
            fields={["narucitelj_interni", "napomena_interna"]}
          />
          <FormTab
            value={1}
            id="javni-podaci"
            label={t("upit.tabs.javni_podaci")}
            icon={<PublicIcon />}
            validation={validation}
            fields={[
              "from_ts",
              "to_ts",
              "vozilo_tip_id",
              "vozilo_klasa_id",
              "vozilo_broj_putnika",
              "opis",
              "vozilo_oprema_ids",
              "trajanje_voznje", "neto_udaljenost_voznje", "udaljenost_povratka_vozila",
              "cijena_hrk",
              "rok_za_ponude"
            ]}
          />
          <FormTab
            value={2}
            id="odabir-prijevoznika"
            label={t("upit.tabs.odabir_prijevoznika")}
            icon={<AirportShuttleIcon />}
            validation={validation}
            fields={["burza_visible"]}
          />
        </FormTabs>
        <TabPanelUpitPrivatno tabIndex={0} {...commonTabProps} />
        <TabPanelUpitJavno tabIndex={1} {...commonTabProps} />
        <TabPanelUpitPrijevoznici tabIndex={2} {...commonTabProps} dc={prijevozniciDc} />
      </CardContent>

      <CardActions>
        <EuroConversionComment />
        <FormFillContent />
        <CardActionButtonClose variant="outlined" onClick={handleClickBack} />
        <CardActionButton variant="contained" onClick={publishUpit} startIcon={<SendIcon />}>
          {t("buttons.publish")}
        </CardActionButton>
      </CardActions>
    </Card>
  );
}

export default withRedirect(withFormController(UpitForm10));
