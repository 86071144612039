import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { formats } from "Lib/formats";

//Material-UI Core components
import Divider from "@material-ui/core/Divider";

//Material-UI Icons
import NotificationsIcon from "@material-ui/icons/Notifications";
import LaunchIcon from "@material-ui/icons/Launch";

//Custom components
import withRedirect from "Components/withRedirect";
import KomunikatorContext from "Components/KomunikatorContext/KomunikatorContext";
import HeaderMenuIconButton from "UI/Header/HeaderMenuIconButton";
import HeaderMenuItem from "UI/Header/HeaderMenuItem";
import HeaderMenuItemExt from "UI/Header/HeaderMenuItemExt";

function NotificationButton(props) {
  const KC = useContext(KomunikatorContext);
  const { t } = useTranslation();

  const { redirect } = props; //HOC withRedirect;

  const handleOpen = (evt, n) => {
    const link = KC.getNotificationRedirectLink(n.link_type, n.link_id);
    if (link) {
      KC.markReadNotification(n.id || n.notif_id)
      .then(resp => {
        redirect(link);
      });
    }
  };

  const handleOpenAll = (evt) => {
    redirect("/notifications");
  };

  return (
    <HeaderMenuIconButton
      icon={<NotificationsIcon />}
      desc="notifications"
      badge={KC.notifications.length}
      badgeColor="error"
    >
      {KC.notifications.map((n, i) => {
        const ts = moment.utc(n.created_on).local().format(formats.datetime);
        const icon = KC.getNotificationIcon(n.link_type);
        return (
          <HeaderMenuItemExt key={i} label={n.remark ? n.remark : n.tip} caption={ts} icon={icon} onClick={(evt) => handleOpen(evt, n)} />
        )
      })}
      <Divider />
      <HeaderMenuItem label={t("notificator.open_all")} icon={<LaunchIcon />} onClick={handleOpenAll} />
    </HeaderMenuIconButton>
  );
}

export default withRedirect(NotificationButton);
