import { coreFields } from "./helpers/coreFields";
import { commonFields } from "./helpers/commonFields";
import { addressFields } from "./helpers/addressFields";
import { buttonFieldsFav } from "./helpers/buttonFieldsFav";

export default {
  title: "Agencije",
  exportFileName: "CBC_PAgencije",
  apiPath: "agencije/",
  forms: {
    default: {
      fields: ["naziv", "oib", "vat", "adr_ulica", "adr_grad", "adr_pbr", "adr_drzava", "napomena"]
    },
    insert: {
      fields: ["naziv", "oib", "vat", "adr_ulica", "adr_grad", "adr_pbr", "adr_drzava", "napomena"]
    },
    update: {
      fields: ["naziv", "oib", "vat", "adr_ulica", "adr_grad", "adr_pbr", "adr_drzava", "napomena"]
    }
  },
  listViews: {
    default: {
      fields: [
        "id",
        "naziv",
        "oib",
        "vat",
        "adr_ulica",
        "adr_grad",
        "adr_pbr",
        "adr_drzava",
        "created_on",
        "favorite_a"
      ]
    },
    exportExcel: {
      fields: [
        { source: "naziv", width: 25 },
        { source: "oib", width: 25 },
        { source: "vat", width: 25 },
        { source: "adr_ulica", width: 25 },
        { source: "adr_grad", width: 25 },
        { source: "adr_pbr", width: 25 },
        { source: "adr_drzava", width: 25 },
        { source: "created_on", width: 25 }
      ]
    }
  },
  fields: [
    coreFields.id,
    buttonFieldsFav.favorite_a,
    {
      title: "Tip Poslovni Subjekt",
      source: "type_id",
      ttoken: "bs.type_id",
      type: "picker",
      subModel: {
        apiPath: "business-subject-types/",
        format: "{label}"
      }
    },
    {
      title: "Naziv",
      source: "naziv",
      ttoken: "bs.naziv",
      type: "text",
      filter: true,
      validation: {
        required: true,
        maxLength: 100
      }
    },
    commonFields.oib,
    commonFields.vat,
    addressFields.adr_ulica,
    addressFields.adr_grad,
    addressFields.adr_pbr,
    addressFields.adr_drzava,
    {
      title: "Napomena",
      source: "napomena",
      ttoken: "bs.napomena",
      type: "text",
      filter: true,
      validation: {
        required: false,
        maxLength: 2000
      }
    },
    coreFields.created_on
  ]
};
