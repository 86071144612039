import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//Custom components
import withRedirect from "Components/withRedirect";
import ModelTable from "UI/Table/ModelTable";
import model from "Models/vozilaCjenici";
import dataController from "Lib/dataController";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import DialogContext from "UI/DialogContext/DialogContext";
import CjenikDialog from "Views/Prijevoznici/Vozila/CjenikDialog";
import UserContext from "Components/UserContext/UserContext";
import { vozilaService } from "Services/vozilaService";
import { Permissions } from "Lib/permissions";

function TabPanelDodatniCjenici(props) {
  const { t } = useTranslation();
  const snackbarContext = useContext(SnackbarContext);
  const loaderContext = useContext(LoaderContext);
  const dialogContext = useContext(DialogContext);
  const userContext = useContext(UserContext);

  const [records, setRecords] = useState([]);

  const { currentTab, tabIndex, recordId } = props;

  const dc = new dataController(model);

  const handleAdd = (evt) => {
    const isAdmin = userContext.hasAnyPermission(Permissions.AdminModule);
    dialogContext.showDialog(CjenikDialog, {
      dc: dc,
      mode: "insert",
      form: isAdmin ? "admin_cjenik_insert" : "cjenik_insert",
      voziloId: recordId,
      onClose: handleDialogClose,
      subModelDefaultValues:{
        prijevoznik_id: userContext.subjectId,
        vozilo_id: recordId
      }
    });
  };

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords();
    }
  };

  const handleRowAction = function(action, id) {
    if (action === "edit") {
      openVoziloDialog(id);
    }
  }

  const openVoziloDialog = (id) => {
    vozilaService.getCjenik(recordId, id).then((resp) => {
      if(resp.success){
        dialogContext.showDialog(CjenikDialog, {
          dc: dc,
          mode: "update",
          form: "cjenik_update",
          record: resp.data,
          voziloId: recordId,
          onClose: handleDialogClose,
          subModelDefaultValues:{
            prijevoznik_id: userContext.subjectId
          }
        });
      }
    }).catch((result) => {
      snackbarContext.showNotification(result.error, "error");
    });
  }

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    vozilaService.getCjenici(recordId).then((resp) => {
        if (resp.success) {
          setRecords(resp.data || []);
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  useEffect(() => {
    if (currentTab === tabIndex){
      refreshRecords();
    }
  }, [currentTab]);

  return (
    <ModelTable
      records={records}
      dc={dc}
      viewName="cjenici"
      allowExport={false}
      allowFilter={false}
      allowAdd={true}
      addLabel={t("buttons.new_m")}
      handleAdd={handleAdd}
      allowSelection="none"
      onRowAction={handleRowAction}
    />
  );
}

export default withRedirect(TabPanelDodatniCjenici);
