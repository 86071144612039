import { coreFields } from "./helpers/coreFields";
import { alarmFields } from "./helpers/alarmFields";
import { buttonFields } from "./helpers/buttonFields";
import { commonFields } from "./helpers/commonFields";

const tabInterniFields = [
  "narucitelj_interni"
  , "napomena_interna"
]

const fromToFields = [
  "from_ts"
  , "from_loc"
  , "to_ts"
  , "to_loc"
  , "stajalista"
  , "ruta"
  , "trajanje_voznje"
  , "neto_udaljenost_voznje"
  , "udaljenost_povratka_vozila"
]

const objavaFields = [
  "prijevoznici_direkt_ids"
  , "prijevoznici_direkt_ids_helper"
  , "burza_visible"
  , "ask_agentura"
]

const metaFields = [
  "owner_user_id"
  , "owner_user_fullname"
  , "owner_bs_id"
  , "created_on"
  , "created_by"
  , "modified_on"
  , "modified_by"
]

const upitFields = [
  "vozilo_tip_id"
  , "vozilo_klasa_id"
  , "klasa_motora_id"
  , "vozilo_broj_putnika"
  , "vozilo_oprema_ids"
  , "cijena_hrk"
  , "rok_za_ponude"
  , "opis"
]

export default {
  title: "Upiti",
  exportFileName: "Upiti",
  apiPath: "upiti/",
  forms: {
    insert: {
      fields: [
        "id",
        "upit_state_id",
        ...tabInterniFields,
        ...fromToFields,
        ...objavaFields,
        "vozilo_tip_id",
        "vozilo_klasa_id",
        "klasa_motora_id",
        {
          source: "vozilo_broj_putnika",
          originalHeight: true,
          validation: {
            required: true,
            minValue: 1,
            maxValue: 88
          }
        },
        "vozilo_oprema_ids",
        "cijena_hrk",
        "cijena_eur",
        "rok_za_ponude",
        "opis",
        ...metaFields
      ],
      validator: "upitRequired"
    },
    status10: {
      fields: [
        "id",
        "upit_state_id",
        ...tabInterniFields,
        ...fromToFields,
        ...objavaFields,
        ...upitFields,
        ...metaFields
      ],
      validator: "upitRequired"
    },
    status11: {
      fields: [
        "id",
        "upit_state_id",
        ...tabInterniFields,
        "narucitelj_naziv",
        {source: "narucitelj_ocjena", validation: undefined },
        ...fromToFields,
        ...objavaFields,
        ...upitFields,
        ...metaFields
      ]
    },
    status13: {
      fields: [
        "id",
        "upit_state_id",
        ...tabInterniFields,
        ...fromToFields,
        ...objavaFields,
        ...upitFields,
        ...metaFields,
        "panic"
      ]
    },
    "transfer-ownership": {
      fields: ["owner_id"]
    },
    default: {
      fields: [
        "id",
        "upit_state_id",
        ...tabInterniFields,
        ...fromToFields,
        ...upitFields,
        "ponude_broj_zaprimljenih",
        "vozilo_broj_putnika",
        "published_on"
      ]
    },
    direktni_upiti: {
      fields: [
        "id",
        "upit_state_id",
        "from_ts",
        "to_ts",
        "narucitelj_interni",
        ...upitFields,
        "ponude_broj_zaprimljenih",
        "published_on",
        "narucitelj_naziv",
        "narucitelj_ocjena",
        "panic"
      ]
    },
    burza_javno: {
      fields: [
        "id",
        "upit_state_id",
        ...fromToFields,
        ...upitFields,
        "published_on",
        "narucitelj_naziv",
        "narucitelj_ocjena"
      ]
    },
    direktan_upit_javno: {
      fields: [
        "id",
        "upit_state_id",
        ...fromToFields,
        "vozilo_id",
        ...upitFields,
        "published_on",
        "narucitelj_naziv",
        "narucitelj_ocjena",
        "panic"
      ]
    },
    admin: {
      fields: [
        ...fromToFields,
        "vozilo_id",
        ...upitFields,
        "panic"
      ]
    },
    trazilica: {
      fields: [
        {
          source: "from_ts",
          ttoken: "trazilica.from_ts",
          dateFilter: {
            clause: "isBefore",
            scope: "today"
          }
        },
        {
          source: "to_ts",
          ttoken: "trazilica.to_ts",
          dateFilter: {
            clause: "isBefore",
            scope: "today"
          }
        },
        { source: "from_loc", width: "full", originalHeight: true },
        "stajalista",
        "ruta",
        "povratna_ruta",
        { source: "to_loc", width: "full", originalHeight: true },
        {
          source: "vozilo_tip_id",
          originalHeight: true
        },
        {
          source: "vozilo_klasa_id",
          originalHeight: true
        },
        {
          source: "klasa_motora_id",
          originalHeight: true
        },
        {
          source: "vozilo_broj_putnika",
          originalHeight: true,
          validation: {
            required: true,
            minValue: 1,
            maxValue: 88
          }
        },
        "vozilo_oprema_ids",
        "opis",
        "rok_za_ponude",
        "djeca_grupa",
        "jezik_posade",
        "izbegni_cestarine",
        "trajanje_voznje",
        "neto_udaljenost_voznje",
        "udaljenost_povratka_vozila",
        "vozilo_ids",
        "posebna_ponuda_ids"
      ],
      validator: "trazilicaDateConstraint"
    },
    brzaTrazilica: {
      fields: [
        {
          source: "from_ts",
          ttoken: "trazilica.from_ts"
        },
        {
          source: "to_ts",
          ttoken: "trazilica.to_ts"
        },
        {
          source: "from_loc",
          width: "notFull"
        },
        {
          source: "to_loc",
          width: "notFull"
        }
      ]
    },
    trazilicaSazetak: {
      fields: [
        {
          source: "from_ts",
          originalHeight: false
        },
        {
          source: "to_ts",
          originalHeight: false
        },
        { source: "from_loc", width: "full", originalHeight: false },
        "stajalista",
        { source: "to_loc", width: "full", originalHeight: false },
        {
          source: "vozilo_tip_id",
          originalHeight: false
        },
        {
          source: "vozilo_klasa_id",
          originalHeight: false
        },
        {
          source: "klasa_motora_id",
          originalHeight: false
        },
        {
          source: "vozilo_broj_putnika",
          originalHeight: false
        },
        {
          source: "vozilo_oprema_ids",
          originalHeight: false
        },
        "opis",
        {
          source: "rok_za_ponude",
          originalHeight: false
        },
        {
          source: "djeca_grupa",
          originalHeight: false
        },
        {
          source: "jezik_posade",
          originalHeight: false
        },
        {
          source: "izbegni_cestarine",
          originalHeight: false
        },
        {
          source: "trajanje_voznje",
          originalHeight: false
        },
        {
          source: "neto_udaljenost_voznje",
          originalHeight: false
        },
        {
          source: "udaljenost_povratka_vozila",
          originalHeight: false
        },
        {
          source: "povratna_ruta",
          originalHeight: false
        }
      ]
    },
    ponuda_tab: {
      fields: [
        "id",
        "upit_state_id",
        "from_ts",
        "to_ts",
        "from_loc",
        "to_loc",
        "stajalista",
        "ruta",
        "vozilo_id",
        "vozilo_tip_id",
        "vozilo_klasa_id",
        "klasa_motora_id",
        "vozilo_broj_putnika",
        "opis",
        "vozilo_oprema_ids",
        "trajanje_voznje",
        "neto_udaljenost_voznje",
        "udaljenost_povratka_vozila",
        "cijena_hrk",
        "cijena_eur",
        "rok_za_ponude",
        "published_on",
        "narucitelj_naziv",
        "narucitelj_ocjena"
      ]
    },
    posebnaPonuda: {
      fields: [
        {
          source: "prijevoznik_naziv",
          type: "picker",
          subModel: {
            apiPath: "business-subjects/?need_for=picker",
            format: "{label}"
          }
        },
        "prijevoznik_ocjena",
        "from_ts",
        "to_ts",
        "from_loc",
        "stajalista",
        "ruta",
        "to_loc",
        "vozilo_naziv",
        "vozilo_id",
        "vozilo_tip_id",
        "vozilo_klasa_id",
        "klasa_motora_id",
        "vozilo_oprema_ids",
        "vozilo_broj_sjedala",
        "vozilo_broj_putnika",
        "opis",
        "trajanje_voznje",
        "neto_udaljenost_voznje",
        "udaljenost_povratka_vozila",
        "cijena_hrk",
        "cijena_eur",
        "rok_za_ponude",
        "panic"
      ]
    },
    assign_mediator: {
      fields: [ "mediator_id" ]
    }
  },
  listViews: {
    default: {
      fields: [
        "id",
        "panic",
        "narucitelj_naziv",
        "narucitelj_ocjena",
        "from_ts",
        "to_ts",
        "upit_state_id",
        "burza_stats",
        "direktni_upiti_stats",
        "alarm_notifikacije",
        "komcentar_notifikacije",
        "active",
      ],
      hidden: ["active", "panic"]
    },
    moji_upiti: {
      fields: [
        "id",
        {source: "view_ag", tooltip: "buttons.agentura_mark", color: "info"},
        "panic",
        "active",
        "status",
        "published_on",
        "created_on",
        "sve_stats",
        { source: "owner_user_fullname", type: "picker" },
        "narucitelj_interni",
        "from_ts",
        "to_ts",
        "from_loc_name",
        "to_loc_name",
        // "upit_state_id",
        "rok_za_ponude",
        // "burza_stats",
        // "direktni_upiti_stats",
        "alarm_active",
        "alarm_ts",
        "alarm_remark",
        "komcentar_notifikacije",
        "mark_new",
        "edit"
      ],
      hidden: ["active", "created_on", "owner_user_fullname", "alarm_active", "alarm_ts", "alarm_remark", "komcentar_notifikacije", "panic", "mark_new"]
    },
    burza_upiti_javno: {
      fields: [
        "id",
        "published_on",
        "narucitelj_naziv",
        "narucitelj_ocjena",
        "moj_odgovor",
        "from_ts",
        "to_ts",
        "from_loc_name",
        "to_loc_name",
        "vozilo_broj_putnika",
        "rok_za_ponude",
        "alarm_notifikacije",
        "komcentar_notifikacije",
        "active",
        "view"
      ],
      hidden: ["id", "active", "alarm_notifikacije", "komcentar_notifikacije"]
    },
    direktni_upiti: {
      fields: [
        "id",
        { source: "published_on", ttoken: "common.received_on" },
        "panic",

        "narucitelj_naziv",
        "narucitelj_ocjena",
        "moj_odgovor",
        "view_pp",

        "from_ts",
        "to_ts",
        "from_loc_name",
        "to_loc_name",
        "vozilo_id",
        "vozilo_broj_putnika",
        "rok_za_ponude",
        "alarm_notifikacije",
        "komcentar_notifikacije",
        "active",
        "mark_new",
        "view"
      ],
      hidden: ["id", "active", "alarm_notifikacije", "komcentar_notifikacije", "panic"]
    },
    posebne_ponude: {
      fields: [
        "id",
        "published_on",
        "moj_odgovor",
        "narucitelj_naziv",
        "narucitelj_ocjena",
        "from_ts",
        "to_ts",
        "from_loc_name",
        "to_loc_name",
        "vozilo_broj_putnika",
        "rok_za_ponude",
        "view"
      ],
      hidden: ["id"]
    },
    admin: {
      fields: [
        "id",
        "status",
        "sve_stats",
        "narucitelj_naziv",
        "published_on",
        "from_ts",
        "to_ts",
        "from_loc_name",
        "to_loc_name",
        "vozilo_broj_putnika",
        "rok_za_ponude",
        "mediator_fullname",
        "mark_new",
        "view"
      ],
      hidden: ["id", "mark_new"]
    }
  },
  fields: [
    coreFields.id,
    coreFields.active,
    coreFields.created_on,
    coreFields.created_by,
    coreFields.modified_on,
    coreFields.modified_by,

    commonFields.mark_new,

    alarmFields.alarm_active,
    alarmFields.alarm_ts,
    alarmFields.alarm_remark,
    {
      title: "Upit state",
      source: "upit_state_id",
      ttoken: "upit.upit_state_id",
      type: "iconStatus",
      sort: true,
      filter: true,
      items: {
        labels: [
          "upit.statuses.10",
          "upit.statuses.11",
          "upit.statuses.12",
          "upit.statuses.13",
          "upit.statuses.14",
          "upit.statuses.15",
          "upit.statuses.16",
          "upit.statuses.18",
          "upit.statuses.19"
        ],
        values: [10, 11, 12, 13, 14, 15, 16, 18, 19],
        icons: [
          "drafts",
          "send",
          "hourglass_empty",
          "thumb_up",
          "assignment_turned_in",
          "commute",
          "home",
          "warning",
          "archive"
        ]
      },
      customSort: "elementProperty",
      customFilter: "elementProperty"
    },
    {
      title: "Narucitelj (interni)",
      source: "narucitelj_interni",
      ttoken: "upit.narucitelj_interni",
      type: "string",
      width: "full",
      validation: {
        maxLength: 100
      }
    },
    {
      title: "Napomena interna",
      source: "napomena_interna",
      ttoken: "upit.napomena_interna",
      type: "multiline",
      rows: 20,
      rowsMax: 20,
      width: "full"
    },
    {
      title: "From timestamp",
      source: "from_ts",
      ttoken: "common.from_ts",
      type: "datetime",
      validation: {
        required: true
      }
    },
    {
      title: "From location",
      source: "from_loc",
      ttoken: "common.from_loc",
      type: "GooglePlaces",
      external: true,
      validation: {
        required: true
      }
    },
    {
      title: "From location name",
      source: "from_loc_name",
      ttoken: "common.from_loc",
      type: "text",
      readonly: true
    },
    {
      title: "To timestamp",
      source: "to_ts",
      ttoken: "common.to_ts",
      type: "datetime",
      validation: {
        required: true,
        isAfter: "from_ts",
        isAfterToken: "common.from_ts"
      }
    },
    {
      title: "To location",
      source: "to_loc",
      ttoken: "common.to_loc",
      type: "GooglePlaces",
      external: true,
      validation: {
        required: true
      }
    },
    {
      title: "To location name",
      source: "to_loc_name",
      ttoken: "common.to_loc",
      type: "text",
      readonly: true
    },
    {
      title: "Prijveoznicki Direct ID",
      source: "prijevoznici_direkt_ids",
      ttoken: "upit.prijevoznici",
      type: "picker",
      multi: true,
      subModel: {
        apiPath: "prijevoznici/?need_for=picker",
        format: "{label} {evaluation}"
      }
    },
    {
      title: "Prijveoznicki Direct ID Helper",
      source: "prijevoznici_direkt_ids_helper",
      ttoken: "upit.prijevoznici",
      type: "picker",
      multi: true,
      subModel: {
        apiPath: "prijevoznici/?need_for=picker",
        format: "{label} {evaluation}"
      }
    },
    {
      title: "Prijevoznik (javno)",
      source: "burza_visible",
      ttoken: "upit.burza_visible",
      type: "boolean",
      row: true,
      items: {
        labels: ["common.ne", "common.da"],
        values: [false, true],
        default: false
      }
    },
    {
      title: "Vozilo ID",
      source: "vozilo_id",
      ttoken: "upit.vozilo",
      type: "picker",
      subModel: {
        apiPath: "prijevoznici/{prijevoznik_id}/vozila?need_for=picker",
        format: "{label}, {registracija}"
      },
      multi: false
    },
    {
      title: "Vozilo ID",
      source: "vozilo_ids",
      ttoken: "upit.vozilo",
      type: "picker",
      subModel: {
        apiPath: "prijevoznici/{prijevoznik_id}/vozila?need_for=picker",
        format: "{label}, {registracija}"
      },
      multi: true
    },
    {
      title: "Posebna Ponuda IDs",
      source: "posebna_ponuda_ids",
      ttoken: "",
      type: "picker"
    },
    {
      title: "Vozilo naziv",
      source: "vozilo_naziv",
      ttoken: "upit.vozilo",
      type: "text"
    },
    {
      title: "Vozilo Tip",
      source: "vozilo_tip_id",
      ttoken: "upit.vozilo_tip",
      type: "picker",
      subModel: {
        apiPath: "sifrarnici/tipovi-vozila",
        format: "{label}"
      }
    },
    {
      title: "Vozilo Klasa",
      source: "vozilo_klasa_id",
      ttoken: "upit.vozilo_klasa",
      type: "picker",
      subModel: {
        apiPath: "sifrarnici/klase-vozila",
        format: "{label}"
      }
    },
    {
      title: "Klasa motora",
      source: "klasa_motora_id",
      ttoken: "vozila.klasa_motora",
      type: "picker",
      subModel: {
        apiPath: "sifrarnici/klase-motora",
        format: "{label}"
      },
      translate: true
    },
    {
      title: "Vozilo Broj Sjedala",
      source: "vozilo_broj_sjedala",
      ttoken: "upit.vozilo_broj_sjedala",
      type: "wholenum",
      readonly: true,
      validation: {
        maxValue: 2147483647
      }
    },
    {
      title: "Vozilo Broj Putnika",
      source: "vozilo_broj_putnika",
      ttoken: "upit.vozilo_broj_putnika",
      type: "wholenum",
      validation: {
        maxValue: 2147483647
      }
    },
    {
      title: "Oprema",
      source: "vozilo_oprema_ids",
      ttoken: "upit.dodatna_oprema",
      type: "checkbox",
      row: true,
      width: "full",
      subModel: {
        apiPath: "sifrarnici/oprema-vozila",
        format: "{label}"
      }
    },
    {
      title: "Cijena",
      source: "cijena_hrk",
      ttoken: "upit.cijena",
      type: "currency",
      currency: "€",
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Cijena",
      source: "cijena_eur",
      ttoken: "upit.cijena_eur",
      type: "currency",
      currency: "€",
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Rok za Ponude",
      source: "rok_za_ponude",
      ttoken: "upit.rok_ponude",
      type: "datetime",
      validation: {
        required: true
      }
    },
    {
      title: "Opis",
      source: "opis",
      ttoken: "upit.opis",
      type: "multiline",
      rows: 3,
      width: "full",
      validation: {
        maxLength: 4000
      }
    },
    {
      title: "Owner user ID",
      source: "owner_user_id",
      ttoken: "common.owner",
      type: "text"
    },
    {
      title: "Owner Full name",
      source: "owner_user_fullname",
      ttoken: "common.owner",
      type: "text",
      readonly: true,
      filter: true
    },
    {
      title: "Owner Business Subject ID",
      source: "owner_bs_id",
      ttoken: "common.owner_bs",
      type: "text"
    },
    {
      title: "Agency User Owner ID",
      source: "owner_id",
      ttoken: "upit.agency_user",
      type: "picker",
      subModel: {
        apiPath: "agencije/{owner_id}/korisnici?need_for=picker",
        format: "{label}"
      }
    },

    {
      title: "Broj zaprimljenih ponuda",
      source: "ponude_broj_zaprimljenih",
      ttoken: "upit.ponude_broj_zaprimljenih",
      type: "wholenum",
      readonly: true
    },
    {
      title: "Notifikacije alarma",
      source: "alarm_notifikacije",
      ttoken: "upit.alarm_notifikacije",
      type: "special"
    },
    {
      title: "Notifikacije alarma",
      source: "komcentar_notifikacije",
      ttoken: "upit.komcentar_notifikacije",
      type: "special"
    },
    {
      title: "Naručitelj",
      source: "narucitelj_naziv",
      ttoken: "common.narucitelj",
      type: "text",
      readonly: true
    },
    {
      title: "Ocjena naručitelja",
      source: "narucitelj_ocjena",
      ttoken: "common.narucitelj_ocjena",
      type: "rating",
      max: 10,
      precision: 0.5,
      validation: {
        required: true
      }
    },
    {
      title: "Prijevoznik",
      source: "prijevoznik_naziv",
      ttoken: "common.prijevoznik",
      type: "text",
      readonly: true
    },
    {
      title: "Ocjena prijevoznika",
      source: "prijevoznik_ocjena",
      ttoken: "common.ocjena",
      type: "rating",
      readonly: true
    },
    {
      title: "Published on",
      source: "published_on",
      ttoken: "common.published_on",
      type: "datetime",
      readonly: true
    },
    {
      title: "Ponuda",
      source: "ponuda_id",
      type: "wholenum"
    },
    {
      title: "Djecja grupa",
      source: "djeca_grupa",
      ttoken: "trazilica.djeca_grupa",
      type: "boolean",
      items: {
        labels: ["common.ne", "common.da"],
        values: [false, true],
        default: false
      },
      originalHeight: true
    },
    {
      title: "Stajalista",
      source: "stajalista",
      ttoken: "trazilica.medjustajalista",
      type: "GooglePlacesViaControl"
    },
    {
      title: "Ruta",
      source: "ruta",
      ttoken: "trazilica.ruta",
      type: "HEREroute"
    },
    {
      title: "Povratna ruta",
      source: "povratna_ruta",
      ttoken: "trazilica.povratna_ruta",
      type: "boolean",
      items: {
        labels: ["common.ne", "common.da"],
        values: [false, true],
        default: false
      },
      originalHeight: true
    },
    {
      title: "Jezik Posade",
      source: "jezik_posade",
      ttoken: "trazilica.jezik_posade",
      type: "picker",
      subModel: {
        apiPath: "sifrarnici/jezici-posade",
        format: "{label}"
      },
      translate: true,
      originalHeight: true
    },
    {
      title: "Izbjegni cestarine",
      source: "izbegni_cestarine",
      ttoken: "trazilica.izbegni_cestarine",
      type: "boolean",
      items: {
        labels: ["common.ne", "common.da"],
        values: [false, true],
        default: false
      },
      originalHeight: true
    },
    {
      title: "Trajanje Voznje",
      source: "trajanje_voznje",
      ttoken: "trazilica.trajanje_voznje",
      type: "picker",
      readonly: true,
      calculated: true,
      formatting: "dhm",
      originalHeight: true,
      validation: {
        required: true
      }
    },
    {
      title: "Neto Udaljenost Voznje",
      source: "neto_udaljenost_voznje",
      ttoken: "trazilica.neto_udaljenost_voznje",
      type: "picker",
      readonly: true,
      calculated: true,
      formatting: "km",
      originalHeight: true,
      validation: {
        required: true
      }
    },
    {
      title: "Udaljenost Povratka Vozila",
      source: "udaljenost_povratka_vozila",
      ttoken: "trazilica.udaljenost_povratka_vozila",
      type: "picker",
      readonly: true,
      calculated: true,
      formatting: "km",
      originalHeight: true,
      validation: {
        required: true
      }
    },
    {
      title: "Burza Stats",
      source: "burza_stats",
      ttoken: "upit.burza_stats",
      type: "stats"
    },
    {
      title: "Direktni Upiti Stats",
      source: "direktni_upiti_stats",
      ttoken: "upit.direktni_upiti_stats",
      type: "stats"
    },
    {
      title: "Status & Stats",
      source: "sve_stats",
      ttoken: "common.status",
      type: "statusstats",
      sort: true,
      items: {
        labels: [
          "upit.statuses.10",
          "upit.statuses.11",
          "upit.statuses.111",
          "upit.statuses.112",
          "upit.statuses.113",
          "upit.statuses.114",
          "upit.statuses.13",
          "upit.statuses.14",
          "upit.statuses.15",
          "upit.statuses.16",
          "upit.statuses.18",
          "upit.statuses.19"
        ],
        values: [10, 11, 111, 112, 113, 114, 13, 14, 15, 16, 18, 19],
        icons: [
          "drafts",
          "send",
          "send",
          "send",
          "thumb_down",
          "send",
          "thumb_up",
          "assignment_turned_in",
          "commute",
          "home",
          "thumb_down",
          "archive"
        ]
      },
      customSort: "elementProperty"
    },
    {
      title: "Vozila Zauzetost",
      source: "zauzetost",
      ttoken: "upit.zauzetost",
      type: "picker",
      items: {
        labels: ["zauzetost.slobodan", "zauzetost.kroz_ponude", "zauzetost.kroz_posao", "zauzetost.kroz_rezervacije"],
        values: [1, -1, -2, -3]
      },
      translate: true
    },
    {
      title: "Moj odgovor",
      source: "moj_odgovor",
      ttoken: "upit.moj_odgovor",
      type: "iconStatus",
      sort: true,
      filter: true,
      items: {
        labels: [
          "moj_odgovor.skica",
          "moj_odgovor.poslano",
          "moj_odgovor.nema",
          "moj_odgovor.odbijano",
          "moj_odgovor.ugovoreno"
        ],
        values: [1, 2, 0, -1, 3],
        icons: ["drafts", "send", "hourglass_empty", "thumb_down", "thumb_up"]
      },
      customSort: "elementProperty",
      customFilter: "elementProperty"
    },
    {
      title: "Panic",
      source: "panic",
      ttoken: "common.panic",
      type: "panic",
      items: {
        labels: ["common.ne", "common.da"],
        values: [false, true],
        default: true
      },
      readonly: true,
      translate: true
    },
    {
      title: "Mediator",
      source: "mediator_id",
      ttoken: "common.mediator",
      type: "picker",
      subModel: {
        apiPath: "users/?select=mediators",
        format: "{label}"
      },
    },
    {
      title: "Mediator",
      source: "mediator_fullname",
      ttoken: "common.mediator",
      type: "text",
      readonly: true
    },
    {
      title: "Stanje",
      source: "status",
      ttoken: "poslovi.stanjeTitle",
      type: "picker",
      items: {
        labels: [
          "poslovi.stanje.aktivno", "poslovi.stanje.arhivirano", "poslovi.stanje.storno"
        ],
        values: [1, 0, -1]
      },
      translate: true
    },
    {
      title: "Agentura",
      source: "ask_agentura",
      ttoken: "upit.ask_agentura",
      type: "boolean",
      items: {
        labels: ["common.ne", "common.da"],
        values: [false, true],
        default: false
      },
      translate: true
    },
    buttonFields.edit,
    buttonFields.view,
    buttonFields.view_pp,
    buttonFields.view_ag
  ]
};
