import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Hidden from "@material-ui/core/Hidden";

//Material-UI Icons
import PersonIcon from "@material-ui/icons/Person";

//Custom Components
import withRedirect from "Components/withRedirect";
import HeaderMenuButton from "UI/Header/HeaderMenuButton";
import HeaderMenuIconButton from "UI/Header/HeaderMenuIconButton";
import HeaderMenuItem from "UI/Header/HeaderMenuItem";
import UserContext from "Components/UserContext/UserContext";
import KomunikatorContext from "Components/KomunikatorContext/KomunikatorContext";

import { authService } from "Services/authService";

function ProfileMenu(props) {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const KC = useContext(KomunikatorContext);
  const { redirect } = props; //HOC withRedirect

  const handleUserDetails = () => {
    redirect("/profile");
  };

  const handleLogout = () => {
    KC.disconnect(3_001);
    authService.logout();
    redirect("/login");
  };

  const label = userContext && userContext.firstName ? userContext.firstName : "USER";

  return (
    <Fragment>
      <Hidden mdUp>
        <HeaderMenuIconButton icon={<PersonIcon />}>
          <HeaderMenuItem label={t("menu.profile")} icon="person" onClick={handleUserDetails} />
          <HeaderMenuItem label={t("menu.logout")} icon="exit_to_app" onClick={handleLogout} />
        </HeaderMenuIconButton>
      </Hidden>
      <Hidden smDown>
        <HeaderMenuButton text={label} startIcon={<PersonIcon />} showExpandIcon={true}>
          <HeaderMenuItem label={t("menu.profile")} icon="person" onClick={handleUserDetails} />
          <HeaderMenuItem label={t("menu.logout")} icon="exit_to_app" onClick={handleLogout} />
        </HeaderMenuButton>
      </Hidden>
    </Fragment>
  );
}

export default withRedirect(ProfileMenu);
