import { coreFields } from "./helpers/coreFields";

export default {
  title: "komunikator",
  exportFileName: "Komunikator",
  source: "komunikator",
  apiPath: "communicator/",
  forms: {
    default: {
      fields: ["id", "channel_id", "channel_title", "channel_users", "channel_users_internal", "initial_msg", "message_body", "confirmation_expires_on"]
    },
    confirm_msg: {
      fields: ["confirmation_expires_on", "message_body", "channel_users"]
    },
    confirm_msg_internal: {
      fields: ["confirmation_expires_on", "message_body", "channel_users"]
    },
    channel_insert: {
      fields: ["channel_title", "job_users", "initial_msg"]
    },
    channel_insert_internal: {
      fields: ["channel_title", "job_users_internal", "initial_msg"]
    },
    panic_mode: {
      fields: ["panic_reason"]
    }
  },
  listViews: {
    default: {
      fields: []
    }
  },
  fields: [
    coreFields.id,
    {
      title: "channel_id",
      source: "channel_id",
      ttoken: "komunikator.channel_id",
      type: "numeric"
    },
    {
      title: "channel_title",
      source: "channel_title",
      ttoken: "komunikator.channel_title",
      type: "text",
      validation: {
        required: true,
        maxLength: 255
      }
    },
    {
      title: "job_users",
      source: "job_users",
      ttoken: "komunikator.job_users",
      type: "picker",
      multi: true,
      subModel: {
        apiPath: "poslovi/{jobId}/users",
        format: "{label}"
      },
      validation: {
        required: true
      }
    },
    {
      title: "channel_users",
      source: "channel_users",
      ttoken: "komunikator.channel_users",
      type: "picker",
      subModel: {
        apiPath: "communicator/channel/{channelId}/users",
        format: "{label}"
      },
      validation: {
        required: true
      }
    },
    {
      title: "job_users_internal",
      source: "job_users_internal",
      ttoken: "komunikator.job_users",
      type: "picker",
      subModel: {
        apiPath: "business-subjects/{bsId}/my-bs-users",
        format: "{label}"
      },
      validation: {
        required: true
      }
    },
    {
      title: "initial_msg",
      source: "initial_msg",
      ttoken: "komunikator.initial_msg",
      type: "multiline",
      rows: 3,
      rowsMax: 8,
      validation: {
        required: true
      }
    },
    {
      title: "message_body",
      source: "message_body",
      ttoken: "komunikator.msg_body",
      type: "multiline",
      rowsMax: 10,
      validation: {
        required: true
      }
    },
    {
      title: "confirmation_expires_on",
      source: "confirmation_expires_on",
      ttoken: "komunikator.msg_expires",
      type: "datetime",
      validation: {
        required: true
      }
    },
    {
      "title": "Reason",
      "source": "panic_reason",
      "ttoken": "comm.panic_reason",
      "type": "multiline",
      "rows": 5,
      "rowsMax": 5,
      "validation": {
        "required": true,
        "maxLength": 1000
      }
    }
  ]
};
