import React from 'react';

//Custom components
import AdminPage from "UI/AppPage/AdminPage";
import KomunikatorPage from "./KomunikatorPage";

const AgencijeKomunikatorPage = React.memo((props) => {
  return (
    <AdminPage>
      <KomunikatorPage prefix={"admin"} />
    </AdminPage>
  );
})

export default AgencijeKomunikatorPage;
