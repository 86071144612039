import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Icons
import MarkunreadIcon from "@material-ui/icons/Markunread";

//Custom components
import CardToolbarMenuItem from "UI/Card/CardToolbarMenuItem";

const CardToolbarMenuItemMarkUnread = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return <CardToolbarMenuItem label={t("buttons.mark_unread")} icon={<MarkunreadIcon />} {...props} ref={ref} />;
});

export default CardToolbarMenuItemMarkUnread;
