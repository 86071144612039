import React from "react";
import { useParams } from "react-router";

//Material UI
import Box from "@material-ui/core/Box";

//Custom components
import AgencijePage from "UI/AppPage/AgencijePage";
import PosebnaPonudaFormPublic from "Views/Prijevoznici/PosebnePonude/PosebnaPonudaFormPublic";

const AgencijePosebnaPonudaPage = React.memo((props) => {
  const { id } = useParams();

  const recordId = parseInt(id, 10);

  return (
    <AgencijePage>
      <Box m={2}>
        <PosebnaPonudaFormPublic id={recordId}/>
      </Box>
    </AgencijePage>
  );
})

export default AgencijePosebnaPonudaPage;
