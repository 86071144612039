import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";

//Material-UI Icons
import InfoIcon from "@material-ui/icons/Info";
import PublicIcon from "@material-ui/icons/Public";

//CBC Icons
import VozacIcon from "Icons/VozacIcon";

//Custom Components
import withFormController from "Components/withFormController";
import DynamicDialog from "UI/Dialog/DynamicDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButton from "UI/Dialog/DialogToolbarButton";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarCreationDetails from "UI/Dialog/DialogToolbarCreationDetails";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import { FormTabs, FormTab, FormTabPanel } from "UI/Form";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogActionButtonAdd from "UI/Dialog/ActionButtons/DialogActionButtonAdd";
import DialogActionButtonSave from "UI/Dialog/ActionButtons/DialogActionButtonSave";
import DialogActionButtonDelete from "UI/Dialog/ActionButtons/DialogActionButtonDelete";
import FormContent from "Components/FormContent";
import { GridContainer } from "UI/Grid";

import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import dataController from "Lib/dataController";
import model from "Models/ponuda";

const fieldNamesOsnovnoInsert = ["username", "tag", "first_name", "last_name", "password", "password_confirm", "email"];
const fieldNamesOsnovnoUpdate = ["username", "tag", "first_name", "last_name", "email"];
const fieldNamesDodatno = ["oib", "mobitel", "telefon", "adr_pbr", "adr_ulica", "adr_grad", "adr_drzava", "datum_rodjenja", "zdravstveno_do", "nezgoda_kontakt"];

function VozacDialog(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const {t} = useTranslation();

  const poundaDC = new dataController(model);

  const [dataChanged, setDataChanged] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  const {dc, record, form, mode, validation, fields, subModels, recordId} = props; //HOC withFormController
  const {doInsert, doUpdate, doDelete, doClose} = props; //HOC withFormController
  const {onFieldChange, onClose} = props; //HOC withFormController

  const handleTabChange = (evt, value) => {
    setCurrentTab(value);
  };

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };
  const handleInsert = (evt) => {
    if (doInsert) {
      doInsert().then((result) => {
        if (result.success) {
          close({dataChanged: true, action: "insert"});
          snackbarContext.showNotification("insert", "success");
        }
      }).catch((result) => {
        if (result.success === false && result.validationPass === false) {
          //do nothing
        } else {
          snackbarContext.showNotification(result.error, "error");
        }
      });
    }
  };

  const handleDelete = (evt) => {
    if (doDelete) {
      doDelete().then((result) => {
        if (result.success) {
          close({dataChanged: true, action: ""})
        }
      }).catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      });
    }
  };

  const handleUpdate = (evt) => {
    if (doUpdate) {
      doUpdate().then((result) => {
        if (result.success) {
          close({dataChanged: true, action: "update"});
          snackbarContext.showNotification("update", "success");
        }
      }).catch((result) => {
        if (result.success === false && result.validationPass === false) {
          //do nothing
        } else {
          snackbarContext.showNotification(result.error, "error");
        }
      });
    }
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({dataChanged: false});
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const close = (result) => {
    if (result.dataChanged || dataChanged) {
      onClose({dataChanged: true, action: result.action});
    }
    dialogContext.hideDialog();
  };

  const commonProps = {
    mode: mode,
    record: record,
    recordId: recordId,
    validation: validation,
    fields: fields,
    subModels: subModels,
    onFieldChange: handleFieldChange,
  }

  return (
      <DynamicDialog onClose={handleClose} fixedHeight={true}>
        <DialogHeader>
          <Toolbar variant="dense" disableGutters={true}>
            <DialogAvatar ariaLabel="pregled_vozaca" icon={<VozacIcon/>}/>
            <DialogToolbarHeading>{t("titles.vozac")}</DialogToolbarHeading>
            <DialogToolbarFillContent/>
            <DialogToolbarCreationDetails record={record}/>
            <DialogToolbarButtonClose onClick={handleClose}/>
          </Toolbar>
        </DialogHeader>
        <DialogBody>
          <FormTabs value={currentTab} onChange={handleTabChange} identifier={dc.getSource()}>
            <FormTab
                value={0} id="osnovni_podaci" label={t("vozaci.osnovni_podaci")} icon={<InfoIcon/>} validation={validation}
                fields={[mode === 'insert' ? fieldNamesOsnovnoInsert : fieldNamesOsnovnoUpdate]}
            />
            <FormTab
                value={1} id="dodatni_podaci" label={t("vozaci.dodatni_podaci")} icon={<PublicIcon/>} validation={validation}
                fields={[...fieldNamesDodatno, 'napomena']}
            />
          </FormTabs>
          <FormTabPanel index={0} value={currentTab}>
            <GridContainer>
              <FormContent
                  {...commonProps}
                  fieldNames={mode === 'insert' ? fieldNamesOsnovnoInsert : fieldNamesOsnovnoUpdate}
              />
            </GridContainer>
          </FormTabPanel>
          <FormTabPanel index={1} value={currentTab}>
            <GridContainer>
              <FormContent
                  {...commonProps}
                  fieldNames={["oib", "mobitel", "telefon"]} // with additional field `napomena`
                  columns={3}
              />
              <FormContent
                  {...commonProps}
                  fieldNames={["adr_pbr", "adr_ulica", "adr_grad", "adr_drzava", "datum_rodjenja", "zdravstveno_do", "nezgoda_kontakt"]} // with additional field `napomena`
              />
              <FormContent
                  {...commonProps}
                  fieldNames={['napomena']} // with additional field `napomena`
                  columns={1}
              />
            </GridContainer>
          </FormTabPanel>
        </DialogBody>
        <DialogActions>
          <DialogActionButtonClose variant="outlined" onClick={handleClose}/>
          {mode === "update" ? <DialogActionButtonDelete variant="outlined" onClick={handleDelete} disabled/> : null}
          {
            mode === "insert" ?
                <DialogActionButtonAdd variant="contained" onClick={handleInsert}/>
                : <DialogActionButtonSave variant="contained" onClick={handleUpdate}/>
          }
        </DialogActions>
      </DynamicDialog>
  );
}

export default withFormController(VozacDialog);
