import React from "react";

//Material-UI Core Components
import { makeStyles } from "@material-ui/core/styles";

// Material-UI Icons
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

//Custom Components
import SchedulerGridBarBox from "Components/Scheduler/SchedulerGridBarBox";

const myStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary
  }
}));

function SchedulerGridBarIconGroup(props) {
  const { id, grouped, onToggle } = props;

  const classes = myStyles();

  const handleClick = (evt) => {
    if (onToggle) {
      onToggle(id);
    }
  };

  return (
    <SchedulerGridBarBox style={{width: "20px"}}>
      {grouped ? (
        <KeyboardArrowDownIcon fontSize="small" classes={classes} onClick={handleClick} />
      ) : (
        <KeyboardArrowUpIcon fontSize="small" classes={classes} onClick={handleClick} />
      )}
    </SchedulerGridBarBox>
  );
}

export default SchedulerGridBarIconGroup;
