import { coreFields } from "./helpers/coreFields";
import { buttonFields } from "./helpers/buttonFields";

const priceFields = [
  "price_per_distance",
  "price_for_transfer",
  "distance_for_transfer",
  "duration_for_transfer",
  "price_for_half_day",
  "distance_for_half_day",
  "duration_for_half_day",
  "price_for_day",
  "distance_for_day",
  "duration_for_day"
];

export default {
  title: "vozila_cjenici",
  exportFileName: "Vozila_cjenici",
  source: "vozila_cjenici",
  apiPath: "",
  forms: {
    default: {
      fields: ["naziv", "start_date", "end_date", ...priceFields, "in_use", "custom"],
      validator: "minimumRequiredCjenikFields"
    },
    osnovni_cjenik: {
      fields: [...priceFields],
      validator: "minimumRequiredCjenikFields"
    },
    predlozak: {
      fields: ["naziv", "kategorija_cjenika_id", ...priceFields],
      validator: "minimumRequiredCjenikFields"
    },
    cjenik_update: {
      fields: ["naziv", "kategorija_cjenika_id", "vozilo_id", ...priceFields, "lokacija", "change_all_allocations"],
      validator: "minimumRequiredCjenikFields"
    },
    cjenik_insert: {
      fields: ["naziv", "kategorija_cjenika_id", "vozilo_id", ...priceFields, "lokacija"],
      validator: "minimumRequiredCjenikFields"
    },
    admin_cjenik_update: {
      fields: [
        "naziv",
        "kategorija_cjenika_id",
        {
          source: "vozilo_id",
          subModel: {
            apiPath: "vozila/?need_for=picker",
            format: "{label}, {registracija}"
          }
        },
        ...priceFields,
        "lokacija",
        "change_all_allocations"
      ],
      validator: "minimumRequiredCjenikFields"
    },
    admin_cjenik_insert: {
      fields: [
        "naziv",
        "kategorija_cjenika_id",
        {
          source: "vozilo_id",
          subModel: {
            apiPath: "vozila/?need_for=picker",
            format: "{label}, {registracija}"
          }
        },
        ...priceFields,
        "lokacija"
      ],
      validator: "minimumRequiredCjenikFields"
    },
    alokacija: {
      fields: [
        "cjenik_id",
        "start_date",
        "end_date",
        "loc_name",
        {
          source: "price_per_distance",
          validation: {
            required: false
          }
        },
        {
          source: "price_for_transfer",
          validation: {
            required: false
          }
        },
        {
          source: "distance_for_transfer",
          validation: {
            required: false
          }
        },
        {
          source: "duration_for_transfer",
          validation: {
            required: false
          }
        },
        "price_for_half_day",
        "distance_for_half_day",
        "duration_for_half_day",
        {
          source: "price_for_day",
          validation: {
            required: false
          }
        },
        {
          source: "distance_for_day",
          validation: {
            required: false
          }
        },
        {
          source: "duration_for_day",
          validation: {
            required: false
          }
        }
      ]
    }
  },
  listViews: {
    default: {
      fields: ["id", "naziv", "date_from", "date_to", "price_per_distance", "price_for_day", "in_use"]
    },
    predlosci: {
      fields: [
        "id",
        "naziv",
        "kategorija_cjenika_id",
        "price_per_distance",
        {
          source: "price_for_transfer",
          ttoken: "cjenici.price_for_transfer"
        },
        "distance_for_transfer",
        "duration_for_transfer",
        {
          source: "price_for_half_day",
          ttoken: "cjenici.price_for_half_day"
        },
        "distance_for_half_day",
        "duration_for_half_day",
        {
          source: "price_for_day",
          ttoken: "cjenici.price_for_day"
        },
        "distance_for_day",
        "duration_for_day",
        "edit"
      ],
      hidden: [
        "distance_for_transfer",
        "duration_for_transfer",
        "distance_for_half_day",
        "duration_for_half_day",
        "distance_for_day",
        "duration_for_day"
      ]
    },
    cjenici: {
      fields: [
        "id",
        "naziv",
        "kategorija_cjenika_id",
        "price_per_distance",
        {
          source: "price_for_transfer",
          ttoken: "cjenici.price_for_transfer"
        },
        {
          source: "price_for_half_day",
          ttoken: "cjenici.price_for_half_day"
        },
        {
          source: "price_for_day",
          ttoken: "cjenici.price_for_day"
        },
        "edit"
      ]
    },
    exportExcel: {
      fields: ["naziv", "kategorija_cjenika_id", ...priceFields]
    }
  },
  fields: [
    coreFields.id,
    {
      title: "Naziv",
      source: "naziv",
      ttoken: "cjenici.naziv",
      type: "text",
      validation: {
        required: true,
        maxLength: 100
      },
      readonly: false
    },
    {
      title: "Kategorija cjenika",
      source: "kategorija_cjenika_id",
      ttoken: "cjenici.kategorija_cjenika",
      type: "picker",
      subModel: {
        apiPath: "sifrarnici/kategorije-cjenika",
        format: "{label}"
      },
      validation: {
        required: true
      },
      translate: true
    },
    {
      title: "Vozilo",
      source: "vozilo_id",
      ttoken: "common.vozilo",
      type: "picker",
      subModel: {
        apiPath: "prijevoznici/{prijevoznik_id}/vozila?need_for=picker",
        format: "{label}, {registracija}"
      },
      readonly: true,
      validation: {
        required: true
      }
    },
    {
      title: "Price per distance",
      source: "price_per_distance",
      type: "currency",
      ttoken: "cjenici.price_per_distance",
      validation: {
        required: true,
        maxValue: 99999999
      }
    },
    {
      title: "Price for transfer",
      source: "price_for_transfer",
      type: "currency",
      ttoken: "cjenici.price",
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Distance for transfer",
      source: "distance_for_transfer",
      type: "wholenum",
      suffix: "km",
      ttoken: "cjenici.distance"
    },
    {
      title: "Duration for transfer",
      source: "duration_for_transfer",
      type: "wholenum",
      suffix: "h",
      ttoken: "cjenici.duration"
    },

    {
      title: "Price for half day",
      source: "price_for_half_day",
      type: "currency",
      ttoken: "cjenici.price",
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Distance for half day",
      source: "distance_for_half_day",
      type: "wholenum",
      suffix: "km",
      ttoken: "cjenici.distance"
    },
    {
      title: "Duration for half day",
      source: "duration_for_half_day",
      type: "wholenum",
      suffix: "h",
      ttoken: "cjenici.duration"
    },

    {
      title: "Price for day",
      source: "price_for_day",
      type: "currency",
      ttoken: "cjenici.price",
      validation: {
        required: true,
        maxValue: 99999999
      },
      readonly: true,
      calculated: true
    },
    {
      title: "Distance for day",
      source: "distance_for_day",
      type: "wholenum",
      suffix: "km",
      ttoken: "cjenici.distance",
      validation: {
        required: true
      }
    },
    {
      title: "Duration for day",
      source: "duration_for_day",
      suffix: "h",
      type: "wholenum",
      ttoken: "cjenici.duration",
      validation: {
        required: true
      }
    },
    {
      title: "Start date",
      source: "start_date",
      type: "date",
      ttoken: "cjenici.start_date",
      validation: {
        required: false
      },
      readonly: false
    },
    {
      title: "End date",
      source: "end_date",
      type: "date",
      ttoken: "cjenici.end_date",
      validation: {
        required: false
      },
      readonly: false
    },
    {
      title: "In use",
      source: "in_use",
      type: "boolean",
      ttoken: "cjenici.in_use",
      items: {
        labels: ["common.ne", "common.da"],
        values: [false, true]
      }
    },
    {
      title: "Custom cjenik",
      source: "custom",
      ttoken: "cjenici.custom",
      type: "boolean",
      items: {
        labels: ["common.ne", "common.da"],
        values: [false, true],
        default: false
      }
    },
    {
      title: "Cjenik ID",
      source: "cjenik_id",
      ttoken: "cjenici.cjenik_id",
      type: "picker",
      subModel: {
        apiPath: "vozila/{vozilo_id}/cjenici?need_for=picker",
        format: "{label}"
      },
      validation: {
        required: false
      },
      width: "full"
    },
    {
      title: "Change all allocations",
      source: "change_all_allocations",
      ttoken: "cjenici.change_all_allocations",
      type: "boolean",
      items: {
        labels: ["common.ne", "common.da"],
        values: [false, true],
        default: false
      },
      translate: true
    },
    {
      title: "Location",
      source: "lokacija",
      ttoken: "cjenici.lokacija",
      type: "GooglePlaces",
      originalHeight: true,
      external: true,
      validation: {
        required: true
      }
    },
    {
      title: "Location Name",
      source: "loc_name",
      type: "text",
      ttoken: "cjenici.lokacija",
      readonly: false
    },
    buttonFields.edit
  ]
};
