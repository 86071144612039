import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

//Material UI
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import DialogActions from "@material-ui/core/DialogActions";

//Custom components
import withFormController from "Components/withFormController";
import GridContainer from "UI/Grid/GridContainer";
import DynamicDialog from "UI/Dialog/DynamicDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogActionButtonCancel from "UI/Dialog/ActionButtons/DialogActionButtonCancel";
import DialogActionButtonConfirm from "UI/Dialog/ActionButtons/DialogActionButtonConfirm";
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import DialogBody from "UI/Dialog/DialogBody";
import { upitService } from "Services/upitService";
import FormContent from "Components/FormContent";

function UpitTransferOwnershipDialog(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const {t} = useTranslation();

  //const [records,setRecords] = useState([]);
  //const [record,setRecord] = useState({owner_id : null})

  const {upitId} = props;
  const {record, validation, fields, subModels} = props; //HOC withFormController
  const {onFieldChange} = props; //HOC withFormController

  const handleClose = () => {
    dialogContext.hideDialog();
  };

  const handleConfirmation = () => {
    upitService.transferUpitOwnerShip(upitId, record.owner_id.value).then((resp) => {
      if (resp.success) {
        handleClose();
        snackbarContext.showNotification("notifications.transfer_ownership", "success");
      }
    })
  }

  return (
      <DynamicDialog onClose={handleClose} maxWidth={"sm"}>
        <DialogHeader>
          <Toolbar variant="dense" disableGutters={true}>
            <DialogToolbarHeading>{t("titles.transfer_ownership")}</DialogToolbarHeading>
            <DialogToolbarFillContent/>
            <DialogToolbarButtonClose onClick={handleClose}/>
          </Toolbar>
        </DialogHeader>
        <DialogBody>
          <Box m={2}>
            <GridContainer>
              <FormContent
                  record={record}
                  fields={fields}
                  fieldNames={['owner_id']}
                  onFieldChange={onFieldChange}
                  mode={"update"}
                  columns={1}
                  subModels={subModels}
              />
            </GridContainer>
          </Box>
        </DialogBody>
        <DialogActions>
          <DialogActionButtonCancel variant="outlined" onClick={handleClose} />
          <DialogActionButtonConfirm variant="contained" onClick={handleConfirmation} disabled={record.owner_id ? false : true}/>
        </DialogActions>
      </DynamicDialog>
  );
}

export default withFormController(UpitTransferOwnershipDialog);
