import { alarmFields } from "./helpers/alarmFields";

export default {
  title: "Alarm Helper",
  exportFileName: "Alarm_Helper",
  source: "",
  apiPath: "",
  forms: {
    default: {
      fields: ["alarm_ts", "alarm_active", "alarm_remark"],
      validator: "validateAlarm"
    }
  },
  fields: [alarmFields.alarm_ts, alarmFields.alarm_active, alarmFields.alarm_remark]
};
