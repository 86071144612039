import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

// Material-UI Icons
import ErrorIcon from '@material-ui/icons/Error';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';

// Material-UI Core Components
import {
  List
, ListItem
, MenuItem
, ListItemIcon
, ListItemText
, Typography
} from "@material-ui/core";

// Custom Components
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import UserContext from "Components/UserContext/UserContext";
import KomunikatorDialogPanic from "UI/Komunikator/KomunikatorDialogPanic";
import dataController from "Lib/dataController";
import { lockChannel } from "Lib/komunikatorHelpers";
import { sifrarnici } from "Lib/sifrarnici";
import { Permissions } from "Lib/permissions";
import model from "Models/komunikator";

function KomunikatorSettings({ channel, onClose }) {
	const { t } = useTranslation();
	// const KC = useContext(KomunikatorContext);
  const dc = new dataController(model);
  const dialogContext = useContext(DialogContext);
  const SC = useContext(SnackbarContext);
  const UC = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [locked, setLocked] = useState(null);

  const handlePanicMode = (evt) => {
    dialogContext.showDialog(KomunikatorDialogPanic, {
      dc,
      channelId: channel.id,
      form: "panic_mode",
      mode: "insert",
      title: `${t("comm.panic_mode")} ${channel.title}` ,
      onClose: (data) => onClose(data)
    })
  }

  const handleLock = (evt) => {
    lockChannel(channel.id, !locked, t)
      .then(success => {
        console.log(success)
        if (success) {
          SC.showNotification("action", "success");
          onClose({ dataChanged: true });
        } else {
          SC.showNotification("action", "success");
          onClose({ dataChanged: false });
        }
      })
      .catch((err) => {
        console.log(err)
        SC.showNotification(err, "error");
        onClose({ dataChanged: false });
      });
  }

  useEffect(() => {
    if (channel) {
      setLocked(Boolean(channel.channel_state_id === sifrarnici.channel_states.blocked));
    }
  }, [channel])

  const canLock = UC.hasAnyPermission(Permissions.AdminModule) && locked !== null;

	return (
    <List dense={true}>
      {
        channel.channel_type_id !== sifrarnici.channel_types.standalone
        && channel.channel_type_id !== sifrarnici.channel_types.other ?
        <MenuItem onClick={(evt) => handlePanicMode(evt)}>
          <ListItemIcon><ErrorIcon color="error" fontSize="small"/></ListItemIcon>
          <ListItemText>
            <Typography>{t("comm.panic_mode")}</Typography>
          </ListItemText>
        </MenuItem>
        : null
      }
      {
        canLock ?
          <MenuItem onClick={(evt) => handleLock(evt)}>
            <ListItemIcon>
              {
                locked ?
                  <LockOpenOutlinedIcon fontSize="small"/>
                : <LockOutlinedIcon fontSize="small"/>
              }
            </ListItemIcon>
            <ListItemText>
              <Typography>
                {
                  locked ?
                    t("comm.unlock_channel")
                  : t("comm.lock_channel")
                }
              </Typography>
            </ListItemText>
          </MenuItem>
        : null
      }
    </List>
	);
}

export default KomunikatorSettings;
