import api from "Lib/api";

export const userService = {
  activateUser,
  deactivateUser,
  setUserSettings
};

const userApiUrl = 'users/';

function activateUser(userId) {
  const apiInstance = new api();
  const path = userApiUrl + userId + "/activate";
  return apiInstance.Call(path, "patch");
}

function deactivateUser(userId) {
  const apiInstance = new api();
  const path = userApiUrl + userId + "/deactivate";
  return apiInstance.Call(path, "patch");
}

function setUserSettings(data) {
  const apiInstance = new api();
  const path = "my-profile/set-settings";
  return apiInstance.Call(path, "patch", { settings: data});
}
