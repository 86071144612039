import React from "react";

//Material-UI Core Components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const myStyles = makeStyles((theme) => ({
  box: {
    marginRight: "2px !important",
    height: "20px",
    padding: "0px",
    borderRadius: "4px",
    whiteSpace: "nowrap",
    backgroundColor: theme.palette.background.paper,
    boxShadow: "2.5px 2.5px 5px 0px rgba(0,0,0,0.75)"
    //-moz-box-shadow: 2.5px 2.5px 3px 0px rgba(0,0,0,0.75),
    //webkit-box-shadow: 2.5px 2.5px 3px 0px rgba(0,0,0,0.75),
  }
}));

function SchedulerGridBarBox(props) {
  const {children, ...otherProps} = props;
  const classes = myStyles();

  return (
    <Box className={classes.box} {...otherProps}>
      {children}
    </Box>
  );
}

export default SchedulerGridBarBox;
