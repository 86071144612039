import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from 'moment';

// Material-UI Icons
import SendIcon from '@material-ui/icons/Send';

//Material-UI Core Components
import Box from "@material-ui/core/Box";
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from "@material-ui/core/Toolbar";

// Custom Components
import withFormController from "Components/withFormController";
import DynamicDialog from "UI/Dialog/DynamicDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButton from "UI/Dialog/DialogActionButton";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogActionButtonAdd from "UI/Dialog/ActionButtons/DialogActionButtonAdd";
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import KomunikatorContext from "Components/KomunikatorContext/KomunikatorContext";

import GridContainer from "UI/Grid/GridContainer";
import FormContent from "Components/FormContent";
import { sifrarnici } from "Lib/sifrarnici";

function ConfirmMessageDialog(props) {
  const { jobId, channelId } = props;
	const { dc, validation, mode, form, record, fields, onFieldChange, doClose, doValidate, subModels, onClose } = props;
	const { t } = useTranslation();
	const dialogContext = useContext(DialogContext);
  const KC = useContext(KomunikatorContext);
  const [tsValid, setTsValid] = useState({valid: true, msg: ""});

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        }
      });
    }
  };

  const close = (result) => {
    if (result.dataChanged) {
      onClose({ dataChanged: true, action: result.action });
    }

    dialogContext.hideDialog();
  };

  const handleChange = (value, source, callback) => {
    if (source === "confirmation_expires_on") {
      if (record['confirmation_expires_on'] 
        && !moment(record['confirmation_expires_on']).isAfter(moment(new Date), 'second')) {
        setTsValid({ valid: false, msg: "validation.min_date" });
      } else if (record['confirmation_expires_on']) {
        setTsValid({ valid: true, msg: "" });
      }
    }
    onFieldChange(value, source, callback);
  }

	const handleSend = () => {
    if (doValidate) {
      const isValid = doValidate();
      if (isValid && record['confirmation_expires_on'] 
        && moment(record['confirmation_expires_on']).isAfter(moment(new Date), 'second')) {
        KC.sendMessage(Object.assign({
          channel_id: channelId,
          message_type_id: sifrarnici.msg_types.confirmation,
          confirmation_user_id: (record.channel_users_internal || record.channel_users).value,
          message_delivery_type_id: sifrarnici.msg_delivery_types.queued
        }, record));
        close({ dataChanged: true });
        setTsValid({ valid: true, msg: "" });
      } else if (!moment(record['confirmation_expires_on']).isAfter(moment(new Date), 'second')) {
        setTsValid({ valid: false, msg: "validation.min_date" });
      } else {
        setTsValid({ valid: true, msg: "" });
      }
    }
	}

  const fieldNames = dc.getFormFieldsNames(form);

	return (
    <DynamicDialog onClose={handleClose} maxWidth="md">
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogAvatar ariaLabel="work_icon" icon_word="add_alert" />
          <DialogToolbarHeading header={t("titles.komunikator")} subheader={t("komunikator.confirm_msg")} />
          <DialogToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
      	<Box m={2}>
	      	<GridContainer>
	      		<FormContent
	      			onFieldChange={onFieldChange}
	      			fields={fields}
	      			record={record}
	      			validation={
                Object.assign({}, validation, {
                  confirmation_expires_on: tsValid.valid && validation ? 
                    validation['confirmation_expires_on'] 
                    : tsValid
                })
              }
	      			mode={mode}
	      			fieldNames={fieldNames}
	      			columns={1}
              subModels={subModels}
	      		/>
	      	</GridContainer>
      	</Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
        <DialogActionButton variant="contained" onClick={handleSend} startIcon={<SendIcon />}>
        	{t("buttons.send")}
        </DialogActionButton>
      </DialogActions>
    </DynamicDialog>
	)
}

export default withFormController(ConfirmMessageDialog);
