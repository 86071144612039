import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Card, CardContent, Tooltip, Typography } from "@material-ui/core";

//Custom components
import withRedirect from "Components/withRedirect";
import UserContext from "Components/UserContext/UserContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import { komunikatorService as KS } from "Services/komunikatorService";
import { helpers as H } from "Lib/helpers";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

const internalCommunication = {
  id: 1,
  title: "komunikator.internal",
  members: [],
  channels: []
};

function KomunikatorCardInternal(props) {

  const [members, setMembers] = useState([]);
  const userContext = useContext(UserContext);
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();

  const { redirect, prefix } = props;
  const styles = cardStyles();

  const fetchMembers = () => {
    if (userContext.subjectId) {
      loaderContext.toggleLoading(true);
      KS.getInternalMembers(userContext.subjectId)
        .then(resp => {
          if (resp.success && resp.data) {
            setMembers(resp.data.map(u => Object.assign({ bColor: H.getRandomColor() }, u)));
          }
        })
        .catch((result) => {
          snackbarContext.showNotification(result.error, "error");
        })
        .finally(() => {
          loaderContext.toggleLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchMembers();
  }, [userContext.subjectId]);

  // TODO:
  // change redirect route so its not hardcoded
  return (
    <Card className={styles.card} onClick={() => redirect(`${prefix}/komunikator/0`)}>
      <CardContent>
        <Typography variant="h6" component="h4" align="left" stlye={{ marginBottom: "5px" }}>{t("komunikator.internal")}</Typography>
        <GridContainer direction="row" alignItems="center" justify="space-evenly">
          {
            members.map((member, i) => (
              <GridItem key={i}>
                <Tooltip title={member.label}>
                  <Avatar className={styles.avatar} style={{ backgroundColor: member.bColor }}>{member.label[0]}</Avatar>
                </Tooltip>
              </GridItem>
            ))
          }
        </GridContainer>
      </CardContent>
    </Card>
  );
}

const cardStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    height: "100%",
    cursor: "pointer",
    marginRight: "20px",
    marginBottom: "8px",
    WebKitTransform: "perspective(1px) translateZ(0)",
    transform: "perspective(1px) translateZ(0)",
    WebkitTransitionDuration: "0.2s",
    TransitionDuration: "0.2s",
    WebKitTransitionProperty: "transform",
    transitionProperty: "transform",
    "&:hover": {
      WebKitTransform: "scale(0.98)",
      transform: "scale(0.98)"
    },
    "&:focus": {
      WebKitTransform: "scale(0.98)",
      transform: "scale(0.98)"
    },
    "&:active": {
      WebKitTransform: "scale(0.98)",
      transform: "scale(0.98)"
    }
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start"
  },
  paragraph: {
    margin: 0
  },
  arrows: {
    display: "flex",
    justifyContent: "center"
  },
  avatar: {
    width: "28px",
    height: "28px"
  }
}));

export default withRedirect(KomunikatorCardInternal);
