import React from "react";

//Custom Components
import AdminPage from "UI/AppPage/AdminPage";
import ResponsiveBox from "UI/Box/ResponsiveBox";
import AdminVoznjeTable from "Views/Administration/AdminVoznje/AdminVoznjeTable";

const AdminVoznjePage = React.memo((props) => {
  return (
    <AdminPage>
      <ResponsiveBox fillHeight>
        <AdminVoznjeTable />
      </ResponsiveBox>
    </AdminPage>
  );
});

export default AdminVoznjePage;
