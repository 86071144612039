export const mapIcons = {
  pinPointIcon: () => {
    return new H.map.Icon(pinPointSVG);
  },
  characterPointIcon: (char) => {
    return new H.map.Icon(characterPointSVG(char));
  }
};

const pinPointSVG =
  '<svg version="1.1" id="pin" width="50" height="50" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 468.293 468.293" style="enable-background:new 0 0 468.293 468.293;" xml:space="preserve">' +
  '<polygon style="fill:#b0b0b0;" points="215.017,215.206 215.017,439.599 234.146,468.293 234.146,215.206 "/>' +
  '<polygon style="fill:#919191;" points="234.146,215.206 234.146,468.293 253.276,439.599 253.276,215.206 "/>' +
  '<path style="fill:#D15241;" d="M344.141,109.994c0,60.784-49.258,109.994-109.994,109.994c-50.836,0-93.639-34.481-106.216-81.348c-2.487-9.134-3.778-18.699-3.778-28.646C124.152,49.258,173.41,0,234.146,0c34.05,0,64.466,15.447,84.6,39.741C334.624,58.775,344.141,83.309,344.141,109.994z"/>' +
  '<path style="fill:#E56353;" d="M322.524,68.34c0,60.736-49.258,109.994-109.994,109.994c-34.05,0-64.418-15.447-84.6-39.694c-2.487-9.134-3.778-18.699-3.778-28.646C124.152,49.258,173.41,0,234.146,0c34.05,0,64.466,15.447,84.6,39.741C321.233,48.876,322.524,58.441,322.524,68.34z"/>' +
  '<path style="fill:#E1E6E9;" d="M263.079,51.46c0.895-8.058-5.227-15.286-13.18-16.86c-19.33-3.825-66.465-7.214-87.194,43.859c0,0-5.054,13.142,9.477,11.499c7.003-0.792,13.262-4.639,17.67-10.138c7.158-8.931,24.145-23.471,55.723-16.601c7.415,1.613,15.331-2.481,17.163-9.845C262.889,52.762,263.005,52.124,263.079,51.46z"/>' +
  "</svg>";

const characterPointSVG = (char) => {
  return (
    '<svg width="24" height="24" ' +
    'xmlns="http://www.w3.org/2000/svg">' +
    '<rect stroke="white" fill="#1b468d" x="1" y="1" width="22" ' +
    'height="22" /><text x="12" y="18" font-size="12pt" ' +
    'font-family="Arial" font-weight="bold" text-anchor="middle" ' +
    'fill="white">' +
    char +
    "</text></svg>"
  );
};
