import React, { useContext } from "react";

//Material-UI Icons
import LightModeIcon from "@material-ui/icons/Brightness4";
import DarkModeIcon from "@material-ui/icons/BrightnessHigh";

//Custom Components
import ThemeContext from "Components/ThemeContext/ThemeContext";
import HeaderIconButton from "UI/Header/HeaderIconButton";

function ThemeSwitch() {
  const themeContext = useContext(ThemeContext);

  const handleThemeChange = (evt) => {
    let newTheme = themeContext.theme === "light" ? "dark" : "light";

    themeContext.setTheme(newTheme);
  };

  return (
    <HeaderIconButton onClick={handleThemeChange} desc="theme">
      {themeContext.theme === "light" ? <LightModeIcon /> : <DarkModeIcon />}
    </HeaderIconButton>
  );
}

export default ThemeSwitch;
