const MenuAgencije = [
  { path: "/a", name: "Home", ttoken: "menu.home", icon: "home" },
  { divider: true },
  { path: "/a/upiti", name:"", ttoken: "menu.upiti", icon:"live_help"},
  { path: "/a/posebne-ponude", name:"", ttoken: "menu.posebne_ponude", icon:"stars"},
  { path: "/a/trazilica", name:"", ttoken: "menu.trazilica", icon:"search"},
  { path: "/a/komunikator", name:"", ttoken: "menu.komunikator", icon:"chat"},
  { path: "/a/prijevoznici", name:"", ttoken: "menu.prijevoznici", svgicon:"prijevoznik"},
  { path: "/a/korisnici", name:"", ttoken: "menu.korisnici", svgicon:"korisnik-agencijski"},
  // { path: "/a/poruke", name:"", ttoken: "menu.poruke", icon:"chat"}
];

export default MenuAgencije;
