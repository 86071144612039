import React from "react";
import { useTranslation } from "react-i18next";

//Materual-UI Icons
import AddIcon from "@material-ui/icons/Add";

//Custom Components
import DialogActionButton from "UI/Dialog/DialogActionButton";

function DialogActionButtonAdd(props) {
  const { t } = useTranslation();

  return (
    <DialogActionButton startIcon={<AddIcon />} {...props} aria-label="add" aria-controls="add">
      {t("buttons.add")}
    </DialogActionButton>
  );
}

export default DialogActionButtonAdd;
