import React, { Fragment, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";

// Material-UI Icons
import SendIcon from '@material-ui/icons/Send';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

import AddAlertIcon from '@material-ui/icons/AddAlert';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import ImageIcon from '@material-ui/icons/Image';
import MicIcon from '@material-ui/icons/Mic';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

// Material-UI Components
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

// Custom Components
import model from "Models/komunikator";
import dataController from "Lib/dataController";
import arrayBufferToBase64 from "Lib/arrayBufferToBase64";

import UserContext from "Components/UserContext/UserContext";
import ChatFileControl from "Controls/ChatFileControl";
import DialogContext from "UI/DialogContext/DialogContext";
import ConfirmMessageDialog from "UI/Chat/ConfirmMessageDialog";

function KomunikatorChatInput (props) {
	const { t } = useTranslation();
	const { jobId, channelId, sendMessage, msgInput, isReadOnly } = props;
	const { handleChange, handleSetImg, handleSetFile } = props;
	const dialogContext = useContext(DialogContext);
	const userContext = useContext(UserContext);
	const fileInput = useRef(null);
	const imgInput = useRef(null);

	const [menuOpen, setMenuOpen] = useState(false);
	const [msgSent, setMsgSent] = useState(false);
	const [fileName, setFileName] = useState(null);
	const [imgName, setImgName] = useState(null);

	const dc = new dataController(model);

	const handleDialogClose = () => {
		dialogContext.hideDialog();
	}

	const fileElClick = () => {
		fileInput.current.click();
	}

	const removeFile = () => {
		handleSetFile(null);
		setFileName(null)
	}

	const onFileChange = (data, status) => {
		if (status === "done") {
			handleSetFile(data);
			const { name } = data;
			setFileName(name);
		}
	}

	const imgElClick = () => {
		imgInput.current.click();
	}

	const removeImg = () => {
		handleSetImg(null)
		setImgName(null);
	}

	const onImgChange = (data, status) => {
		if (status === "done") {
			handleSetImg(data);
			const { name } = data;
			setImgName(name);
		}
	}

	const addConfirmMsg = () => {
    dialogContext.showDialog(ConfirmMessageDialog, {
      dc: dc,
      mode: "insert",
      form: jobId === 0 ? "confirm_msg_internal" : "confirm_msg",
      onClose: handleDialogClose,
      channelId: channelId,
      jobId: jobId,
      subModelDefaultValues: {
        bsId: userContext.subjectId,
        jobId: jobId,
        channelId: channelId
      }
    });
	}

	const addVoiceMsg = () => {

	}

	const handleSendMessage = () => {
		sendMessage();
		setImgName(null);
		setFileName(null);
		if(!msgSent){
			setMsgSent(true)
		}
	}

	return (
		<div>
				<div style={{width: "100%"}}>
				{
					fileName &&
						<Chip
							key="file"
							color="secondary"
							size="small"
							component="span"
							label={fileName.slice(0, 20)}
							onDelete={removeFile}
							icon={<InsertDriveFileIcon fontSize="small"/>}
						/>
				}
				{
					imgName &&
						<Chip
							key="img"
							color="secondary"
							size="small"
							label={imgName.slice(0, 20)}
							onDelete={removeImg}
							icon={<ImageIcon fontSize="small"/>}
						/>
				}
				</div>
			<div>
				<ChatFileControl ref={fileInput} accept={"*"} onChange={onFileChange}/>
				<ChatFileControl ref={imgInput} accept={"image/png, image/jpeg"} onChange={onImgChange}/>
				<div style={{width: "100%"}}>
				  <TextField
				  	disabled={isReadOnly}
				  	autoFocus
				  	fullWidth
				  	multiline
				  	rows={1}
				  	rowsMax={30}
				  	size="small"
				  	margin="dense"
				  	color="secondary"
				  	InputProps={{
				  		endAdornment:
				  			<IconButton
				  				size="small"
				  				disabled={isReadOnly}
				  				onClick={handleSendMessage}
				  			>
				  				<SendIcon fontSize="small" color={isReadOnly ? "disabled" : "secondary"}/>
				  			</IconButton>,
				  		//startAdornment: <IconButton size="small" onClick={() => {}}><PriorityHighIcon fontSize="small"/></IconButton>,
				  	}}
				  	style={{transition: "width 500ms ease-in-out"}}
				  	value={msgInput}
				  	onChange={handleChange}
				  	onKeyDown={(evt) => {
				  		if (evt.keyCode == 13 && !evt.shiftKey){
				  			evt.stopPropagation();
								handleSendMessage();
				  		}
				  	}}
				  />
			  </div>
				<div>
					<IconButton disabled={isReadOnly} size="small" color="secondary" onClick={addConfirmMsg}>
						<AddAlertIcon fontSize="small" />
					</IconButton>
					<IconButton size="small" color="secondary" onClick={fileElClick} disabled={Boolean(fileName) || isReadOnly}>
						<AttachFileIcon fontSize="small" />
					</IconButton>
					<IconButton size="small" color="secondary" onClick={imgElClick} disabled={Boolean(imgName) || isReadOnly}>
						<ImageIcon fontSize="small" />
					</IconButton>
				</div>
			</div>
		</div>
	);
}

export default KomunikatorChatInput;
