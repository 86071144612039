import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

//Material-UI Core Components
import makeStyles from "@material-ui/core/styles/makeStyles";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

//Custom Components
import ToolbarFillContent from "UI/Toolbar/ToolbarFillContent";

//Images
import LogoEU from "Images/logo_eu_zajedno_100h_4c.png";
import LogoOPKK from "Images/logo_opkk_100h_4c.jpg";
import LogoESF from "Images/logo_esf_100h_4c.jpg";

const myClasses = makeStyles((theme) => ({
  footer: {
    borderTop: "2px solid #000",
    backgroundColor: theme.palette.background.paper,
    minHeight: 100
  },
  logoEU: {
    margin: "auto",
    backgroundColor: "#FFFFFF00",
    backgroundImage: `url(${LogoEU})`,
    backgroundRepeat: "no-repeat",
    width: "143px",
    height: "100px"
  },
  logoOPKK: {
    margin: "auto",
    backgroundColor: "#FFFFFF00",
    backgroundImage: `url(${LogoOPKK})`,
    backgroundRepeat: "no-repeat",
    width: "252px",
    height: "100px"
  },
  logoESF: {
    margin: "auto",
    backgroundColor: "#FFFFFF00",
    backgroundImage: `url(${LogoESF})`,
    backgroundRepeat: "no-repeat",
    width: "225px",
    height: "100px"
  }
}));

function Footer(props) {
  const { t } = useTranslation();

  const c = myClasses();

  const textVariant = "caption";
  const version = process.env.REACT_APP_APPVERSION;
  const currentYear = new Date().getFullYear();

  return (
    <footer id="footer" className={c.footer}>
      <Container maxWidth="xl">
        <Toolbar disableGutters={false}>
          <div className={c.logoEU} />
          <div className={c.logoESF} />
          <div className={c.logoOPKK} />
          <div className={c.logoMin} />
          <ToolbarFillContent />
          <div>
            <Typography variant="caption" color="textPrimary" className={clsx(c.caption, c.align)} component="p">
              {t("footer.sufinanciranje")}
            </Typography>
            <Typography variant="caption" color="textPrimary" className={clsx(c.caption, c.align)} component="p">
            {t("footer.prava")} &copy; {currentYear.toString() + "."}
            </Typography>
            {/* <Typography variant="caption" className={clsx(c.caption, c.align)} component="p">
              {t("footer.projekt")}
            </Typography> */}
            {/* <Typography variant="caption" className={clsx(c.caption, c.align)} component="p">
              {t("footer.odgovornost")}
            </Typography> */}
          </div>
        </Toolbar>
      </Container>
    </footer>
  );
}

export default Footer;
