import { komunikatorService as KS } from "Services/komunikatorService"; 
import confirmDialog from "./confirmDialog";

export const lockChannel = (id, lock, t) => {

	const title = t("comm.channel_lock_title");
	const text = t("comm.channel_lock_text");
	const type = "warning";
	const confirmButtonText = t(`comm.${lock ? "lock" : "unlock"}_channel`);
	const cancelButtonText = t("buttons.cancel");

  return confirmDialog(title, text, type, confirmButtonText, cancelButtonText)
    .then(result => {
      const { confirmed } = result;
      if (confirmed) {
        return KS.lockChannel(id, lock).then(() => true);
      } else {
        return new Promise(resolve => resolve(false));
      }
    })
}