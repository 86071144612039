import { coreFields } from "./helpers/coreFields";
import { buttonFields } from "./helpers/buttonFields";

export default {
  title: "Pojedinacne Voznje",
  exportFileName: "Pojedinacne_Voznje",
  apiPath: "",
  forms: {
    default: {
      fields: [
        "id",
        "ts_from",
        "ts_to",
        "vozac_1_id",
        "vozac_2_id",
        "vozac_3_id",
        "vozilo_id",
        "kooperant_id",
        "opis",
        "napomena",
        "neto_km",
        "neto_min"
      ]
    },
    admin: {
      fields: [
        "id",
        "ts_from",
        "ts_to",
        "vozac_1_ime_i_prezime",
        "vozac_2_ime_i_prezime",
        "vozac_3_ime_i_prezime",
        "vozilo_naziv",
        "kooperant_naziv",
        "opis",
        "napomena",
        "neto_km",
        "neto_min"
      ]
    }
  },
  listViews: {
    default: {
      fields: ["id", "opis", "ts_from", "ts_to", "kooperant_id", "vozilo_id", "vozac_1_id", "vozac_2_id", "vozac_3_id", "neto_km", "neto_min", "napomena", "edit"],
      hidden: ["id", "vozac_2_id", "vozac_3_id", "neto_km", "neto_min", "napomena"]
    },
    readonly:{
      fields: ["id", "opis", "ts_from", "ts_to", "kooperant_id", "vozilo_id", "vozac_1_id", "vozac_2_id", "vozac_3_id", "neto_km", "neto_min", "napomena", "view"],
      hidden: ["id", "vozac_2_id", "vozac_3_id", "neto_km", "neto_min", "napomena"]
    },
    ponuda_voznje: {
      fields: ["id", "opis", "ts_from", "ts_to", "kooperant_id", "vozac_1_id", "vozac_2_id", "vozac_3_id", "neto_km", "neto_min", "napomena"],
      hidden: ["id", "vozac_2_id", "vozac_3_id", "neto_km", "neto_min", "napomena"]
    },
    "admin": {
      fields: ["id", "opis", "ts_from", "ts_to", "kooperant_naziv", "vozilo_naziv", "vozac_1_ime_i_prezime", "vozac_2_ime_i_prezime", "vozac_3_ime_i_prezime", "neto_km", "neto_min", "napomena", "view"],
      hidden: ["id", "vozac_2_ime_i_prezime", "vozac_3_ime_i_prezime", "neto_km", "neto_min", "napomena"]
    }
  },
  fields: [
    coreFields.id,
    {
      title: "Početak",
      source: "ts_from",
      ttoken: "pojedinacneVoznje.ts1",
      type: "datetime",
      validation: {
        required: true
      },
      bind: true,
      style: {
        width: 160
      }
    },
    {
      title: "Kraj",
      source: "ts_to",
      ttoken: "pojedinacneVoznje.ts2",
      type: "datetime",
      validation: {
        required: true,
        isAfter: "ts_from",
        isAfterToken: "pojedinacneVoznje.ts2"

      },
      bind: true,
      style: {
        width: 160
      }
    },
    {
      title: "Komitent",
      source: "kooperant_id",
      ttoken: "pojedinacneVoznje.komitent",
      type: "picker",
      subModel: {
        apiPath: "prijevoznici/{prijevoznik_id}/komitenti?need_for=picker&select=dobavljac",
        format: "{label}"
      }
    },
    {
      title: "Komitent",
      source: "kooperant_naziv",
      ttoken: "pojedinacneVoznje.komitent",
      type: "text",
      link: {
        recordIdField: "kooperant_id",
        recordType: "komitent"
      }
    },
    {
      title: "Vozac 1",
      source: "vozac_1_id",
      ttoken: "pojedinacneVoznje.vozac1",
      type: "picker",
      subModel: {
        apiPath: "prijevoznici/{prijevoznik_id}/vozaci?need_for=picker",
        format: "{label}"
      }
    },
    {
      title: "Vozac 2",
      source: "vozac_2_id",
      ttoken: "pojedinacneVoznje.vozac2",
      type: "picker",
      subModel: {
        apiPath: "prijevoznici/{prijevoznik_id}/vozaci?need_for=picker",
        format: "{label}"
      }
    },
    {
      title: "Vozac 3",
      source: "vozac_3_id",
      ttoken: "pojedinacneVoznje.vozac3",
      type: "picker",
      subModel: {
        apiPath: "prijevoznici/{prijevoznik_id}/vozaci?need_for=picker",
        format: "{label}"
      }
    },
    {
      title: "Vozac 1",
      source: "vozac_1_ime_i_prezime",
      ttoken: "pojedinacneVoznje.vozac1",
      type: "text",
      link: {
        recordIdField: "vozac_1_id",
        recordType: "vozac"
      }
    },
    {
      title: "Vozac 2",
      source: "vozac_2_ime_i_prezime",
      ttoken: "pojedinacneVoznje.vozac2",
      type: "text",
      link: {
        recordIdField: "vozac_2_id",
        recordType: "vozac"
      }
    },
    {
      title: "Vozac 3",
      source: "vozac_3_ime_i_prezime",
      ttoken: "pojedinacneVoznje.vozac3",
      type: "text",
      link: {
        recordIdField: "vozac_3_id",
        recordType: "vozac"
      }
    },
    {
      title: "Vozilo",
      source: "vozilo_id",
      ttoken: "pojedinacneVoznje.vozilo",
      type: "picker",
      subModel: {
        apiPath: "prijevoznici/{prijevoznik_id}/vozila?need_for=picker",
        format: "{label}, {registracija}"
      }
    },
    {
      title: "Vozilo",
      source: "vozilo_naziv",
      ttoken: "pojedinacneVoznje.vozilo",
      type: "text",
      link: {
        recordIdField: "vozilo_id",
        recordType: "vozilo"
      }
    },
    {
      title: "Opis",
      source: "opis",
      ttoken: "pojedinacneVoznje.opis",
      type: "multiline",
      maxrows: 5,
      validation: {
        required: true,
        maxLength: 1000
      },
      width: "full"
    },
    {
      title: "Napomena",
      source: "napomena",
      ttoken: "pojedinacneVoznje.napomena",
      type: "multiline",
      maxrows: 5,
      validation: {
        maxLength: 1000
      },
      width: "full"
    },
    {
      title: "Neto KM",
      source: "neto_km",
      ttoken: "pojedinacneVoznje.neto_km",
      type: "numeric"
    },
    {
      title: "Neto Min",
      source: "neto_min",
      ttoken: "pojedinacneVoznje.neto_min",
      type: "numeric"
    },
    buttonFields.edit,
    buttonFields.view
  ]
};
