import React, { Fragment, useState } from "react";

//Material-UI Core Components
import Popover from "@material-ui/core/Popover";

//Material-UI Icons
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

//Custom Components
import ToolbarButton from "UI/Toolbar/ToolbarButton";
import SchedulerLegend from "Components/Scheduler/SchedulerLegend";

function SchedulerLegendButton(props) {
  const [legendAnchorEl, setLegendAnchorEl] = useState(null);

  return (
    <Fragment>
      <ToolbarButton
        color="secondary"
        onClick={(evt) => {
          setLegendAnchorEl(evt.currentTarget);
        }}
      >
        <HelpOutlineIcon />
      </ToolbarButton>
      <Popover
        id="legend"
        open={Boolean(legendAnchorEl)}
        onClose={() => {
          setLegendAnchorEl(null);
        }}
        anchorEl={legendAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <SchedulerLegend show={"schrequest"} />
      </Popover>
    </Fragment>
  );
}

export default SchedulerLegendButton;
