import { standardHeadersWithAuth } from "Services/helpers/standardHeadersWithAuth";
import { authService as AS } from "Services/authService";

// const ws_url = process.env.REACT_APP_APIPATH + "/ws/";
const ws_url = process.env.REACT_WS_APIPATH;

const retryReasons = {
	SOCKET_CONNECTING: 0,
	SOCKET_CLOSING: 2,
	SOCKET_CLOSED: 3,
	SOCKET_ERROR: 4,
	SOCKET_UNKNOWN: 5,
	DONT_RETRY: 3000,
	DB_EXCEPT: 3001,
	BAD_DATA: 3002,
	WS_EXCEPT: 3003,
	UNKNOWN: 3099,
};

// actions
const A = {
	AUTH: 0,
	CHECK_CONN: 1,
	CHECK_DELIVERIES: 2,

	GET_CHANNEL_DATA: 1_000,
	GET_MSGS_OLD: 1_001,
	GET_MSGS_NEW: 1_002,

	POST_CHANEL_DATA: 2_000,
	POST_MSG: 2_010,

	MSG_DELIVERY: 3_000,
	MSG_DELIVERY_BATCH: 3_001,

  NOTIF: 4_000,
  NOTIFY_DELIVERY: 4_100,
  NOTIFY_MARK_READ: 4_200,
  NOTOFY_MARK_DELETE: 4_300
};

const timeoutTime = 10000; // ping every 5s

class websocket {
	constructor(functions, data) {
		this.emptyQ = () => { functions.emptyQ() };
		this.handleError = (response, callback) => { functions.handleError(response, callback) };
		this.handleNotification = (response, callback) => { functions.handleNotification(response, callback) };
		this.handleGetChannelData = (response, callback) => { functions.handleGetChannelData(response, callback) };
		this.handleRecieveMsg = (response, callback) => { functions.handleRecieveMsg(response, callback) };
		this.handleGetOlderMessages = (response, callback) => { functions.handleGetOlderMessages(response, callback) };
		this.handleGetNewerMessages = (response, callback) => { functions.handleGetNewerMessages(response, callback) };
		this.handleMessageDelivery = (response, callback) => { functions.handleMessageDelivery(response, callback) };
		this.handleCheckConnection = (response, callback) => { functions.handleCheckConnection(response, callback) };
		this.handleCheckDeliveries = (response, callback) => { functions.handleCheckDeliveries(response, callback) };

		this.userId = data.userId;

		this.sendData = this.sendData.bind(this);

		this.sendMessage = this.sendMessage.bind(this);
		this.getChannelData = this.getChannelData.bind(this);

		this.handleAction = this.handleAction.bind(this);
		this.handleHandshake = this.handleHandshake.bind(this);

		this.checkConnection = this.checkConnection.bind(this);
		this.closeConnection = this.closeConnection.bind(this);
		this.retryConnection = this.retryConnection.bind(this);
		this.setConnectionInterval = this.setConnectionInterval.bind(this);

		this.webSocketSetup = this.webSocketSetup.bind(this);

		this.connectionInterval = null;
		this.retryTimeout = null;
		this.socket = null;
		this.lastRequestData = null;
		this.webSocketSetup();
	}

	webSocketSetup(callback) {
		if (this.socket) {
			this.socket.close();
			delete this.socket;
		}

		clearTimeout(this.retryTimeout);
		this.retryTimeout = null;
		clearInterval(this.connectionInterval);
		this.connectionInterval = null;

		this.socket = new WebSocket(ws_url + this.userId.toString());

		this.socket.addEventListener('open', (event) => {
			const saveLastRequestData = this.lastRequestData === null ? null : {...this.lastRequestData};
			this.sendData({
				action: A.AUTH,
				data: standardHeadersWithAuth()
			});
			if (saveLastRequestData !== null) {
				this.sendData(saveLastRequestData);
				this.lastRequestData = null;
			}
			this.emptyQ();
			this.checkConnection();
			this.setConnectionInterval();
			if (callback && typeof callback === 'function') {
				callback();
			}
		})

		this.socket.addEventListener('message', (event)	 => {
			const response = JSON.parse(event.data);
			this.handleAction(response);
		});

		this.socket.addEventListener('error', (event)	 => {
			// console.log("ERROR", event)
			// this.retryConnection(retryReasons.SOCKET_ERROR);
			this.socket.close(retryReasons.SOCKER_ERROR);
		});

		this.socket.addEventListener('close', (event)	 => {
			// console.log("CLOSE", event)
			this.retryConnection(event.code);
		});
	}

	disconnect(reason) {
		if (this.socket) {
			this.socket.close(reason);
			if (reason === retryReasons.DONT_RETRY) {
				this.noReconnect = true;
			}
			clearTimeout(this.retryTimeout);
			this.retryTimeout = null;
			clearInterval(this.connectionInterval)
			this.connectionInterval = null;
		}
	}

	sendData(dataObj) {
		this.lastRequestData = {...dataObj};
		if (this.socket.readyState !== WebSocket.OPEN) {
			return this.retryConnection(this.socket.readyState, () => this.sendData(dataObj));
		}
		this.socket.send(JSON.stringify(Object.assign(
			dataObj, {
			auth_token: this.getToken(),
			user_id: this.userId
		})));
	}

	sendMessage(message) {
		this.sendData({
			action: A.POST_MSG,
			data: message
		});
	}

	deliveryMessage(message) {
		this.sendData({
			action: A.MSG_DELIVERY,
			data: message
		})
	}

	deliveryMessageBatch(messages) {
		this.sendData({
			action: A.MSG_DELIVERY_BATCH,
			data: messages
		})
	}

	getChannelData(channelId) {
		this.sendData({
			action: A.GET_CHANNEL_DATA,
			data: {
				channel_id: channelId
			}
		});
	}

	getOlderMessages(channelId, offset, limit) {
		this.sendData({
			action: A.GET_MSGS_OLD,
			data: {
				channel_id: channelId,
				offset,
				limit
			}
		});
	}

	getNewerMessages(channelId, messageId) {
		this.sendData({
			action: A.GET_MSGS_NEW,
			data: {
				channel_id: channelId,
				message_id: messageId
			}
		});
	}

	handleAction(response) {
		// readyState:
		//		0 = CONNECTING
		// 		1 = OPEN
		//		2 = CLOSING
		//		3 = CLOSED
		if (this.socket.readyState === WebSocket.CLOSING || this.socket.readyState === WebSocket.CLOSED) {
			// return this.retryConnection(this.socket.readyState);
		}
		if (response && response.hasOwnProperty("action") && response.action >= 0) {

			// console.log(response);
			const { action, data } = response;
			switch (action) {
				case A.POST_CHANEL_DATA:
					return this.handleGetChannelData(data);
				case A.POST_MSG:
					return this.handleRecieveMsg(data);
				case A.GET_MSGS_OLD:
					return this.handleGetOlderMessages(data);
				case A.GET_MSGS_NEW:
					return this.handleGetNewerMessages(data);
				case A.MSG_DELIVERY:
					return this.handleMessageDelivery(data);
				case A.CHECK_CONN:
					return this.handleCheckConnection(data);
				case A.CHECK_DELIVERIES:
					return this.handleCheckDeliveries(data);
        case A.NOTIF:
          return this.handleNotification(data);
				// case "error":
				// 	return this.handleError(data, (reason) => this.retryConnection(retryReasons.SOCKET_ERROR));
				// case "handshake":
				// 	return this.handleHandshake(data, () => {});
				// case "notification":
				// 	return this.handleNotification(data, () => {});
				default:
					console.log("DEFAULT",response)
					return;
					// return this.handleError(data, (reason) => this.retryConnection(retryReasons.SOCKET_UNKNOWN));
			}
		}
	}

	handleHandshake(data, callback) {
		console.log("handshake", data);
	}

	checkConnection() {
		this.sendData({
			action: A.CHECK_CONN
		});
	}

	checkDeliveries(channelId, msgId, msgDeliveryId) {
		this.sendData({
			action: A.CHECK_DELIVERIES,
			data: {
				channel_id: channelId,
				msg_id: msgId,
				message_delivery_type_id: msgDeliveryId
			}
		});
	}

	setConnectionInterval() {
		if (this.connectionInterval !== null) {
			clearInterval(this.connectionInterval);
		}
		this.connectionInterval = setInterval(this.checkConnection, timeoutTime);
	}

	closeConnection() {
		if (this.socket.readyState === WebSocket.OPEN) {
			// 1000 means there is no errors when closing WebSocket connection
			this.socket.close(1000, "Deliberate disconnection");
		}
	}

	retryConnection(reason, callback) {
		if (reason === retryReasons.DB_EXCEPT) {
			this.closeConnection();
			AS.whoAmI().then((resp) => {
				if (resp.success) {
					this.retryConnection(retryReasons.WS_EXCEPT, callback);
				} else {
          localStorage.removeItem("auth_token");
          if (window.location.pathname !== "/login") {
	          window.location.reload(true);
          }
				}
			});
			return;
		}
		if (/*reason !== retryReasons.SOCKET_CLOSED &&*/
				reason !== retryReasons.SOCKET_CLOSING &&
				reason !== retryReasons.SOCKET_CONNECTING &&
				!this.retryTimeout) {

			if (this.socket) {
				this.socket.close();
			}

			this.retryTimeout = setTimeout(() => {
				if (!this.noReconnect) {
					this.webSocketSetup(callback)
				}
			}, timeoutTime);
		}
	}

	getToken() {
		return localStorage.getItem("auth_token");
	}
}

export default websocket;