import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//Custom components
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import ModelTable from "UI/Table/ModelTable";
import DialogContext from "UI/DialogContext/DialogContext";
import dataController from "Lib/dataController";
import model from "Models/putniNalozi";
import PutniNalogDialog from "../VoznjaFormTabDialogs/PutniNalogDialog";
import UserContext from "Components/UserContext/UserContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";

import { putniNalogPrint } from "Lib/print/putniNalogPrint";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

function TabPutniNalozi(props) {
  const dialogContext = useContext(DialogContext);
  const userContext = useContext(UserContext);
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);

  const { t } = useTranslation();
  const [records, setRecords] = useState([]);

  const { record, recordId, posao_state_id, currentTab, mode, isPanic, allowAction = true } = props;

  const dc = new dataController(model, `poslovi/${recordId}/putni-nalozi`);

  const handleAdd = (evt) => {
    dialogContext.showDialog(PutniNalogDialog, {
      dc: dc,
      mode: "insert",
      form: "default",
      posaoId: recordId,
      onClose: handleDialogClose,
      defaultValues: {
        ts_from: record.from_ts,
        ts_to: record.to_ts,
        vozilo_id: record.vozilo_id,
        vozac_id: record.vozac_id
      },
      subModelDefaultValues: {
        prijevoznik_id: userContext.subjectId
      }
    });
  };

  const handleView = (action, id) => {};

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords();
    }
    dialogContext.hideDialog();
  };

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData()
      .then((resp) => {
        if (resp && resp.data && resp.success) {
          setRecords(resp.data || []);
        } else {
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  useEffect(() => {
    refreshRecords();
  }, []);

  const handleRowAction = (action, id) => {
    if (action === "edit" || action === "view") {
      dialogContext.showDialog(PutniNalogDialog, {
        dc: dc,
        mode: record.posao_state_id === 29 || isPanic || !allowAction ? "view" : "update",
        form: "default",
        recordId: id,
        posaoId: recordId,
        onClose: handleDialogClose,
        subModelDefaultValues: {
          prijevoznik_id: userContext.subjectId
        }
      });
    } else if (action === "print") {
      const rec = records.find((x) => x.id === id);
      if (rec) {
        putniNalogPrint.handlePrint(rec, t);
      }
    }
  };

  const allowRowAction = (action, id) => {
    return true;
  };

  return (
    <GridContainer>
      <GridItem md={12} sm={12}>
        <ModelTable
          records={records}
          dc={dc}
          viewName={posao_state_id === 29 || isPanic || !allowAction ? "readonly" : "default"}
          allowExport={false}
          allowFilter={false}
          allowAdd={mode === "update" && posao_state_id < 29 && !isPanic && allowAction}
          handleAdd={handleAdd}
          addLabel={t("buttons.new_m")}
          allowSelection={"none"}
          pagination={false}
          search={false}
          allowRowAction={allowRowAction}
          onRowAction={handleRowAction}
          onDoubleClick={(id) => handleRowAction("edit", id)}
          subModelDefaultValues={{
            prijevoznik_id: userContext.subjectId
          }}
        />
      </GridItem>
    </GridContainer>
  );
}

export default TabPutniNalozi;
