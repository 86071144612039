const menuPrijevoznici = [

  { path: "/p", name: "Home", ttoken: "menu.home", icon: "home" },
  { divider: true },
  // { title: "menu.internal" },
  { path: "/p/kalendar", name:"", ttoken: "menu.kalendar", icon: "calendar_today" },
  { path: "/p/voznje", name:"", ttoken: "menu.voznje", svgicon: "voznja" },
  { path: "/p/vozaci", name:"", ttoken: "menu.vozaci", svgicon: "vozac" },
  { path: "/p/vozila", name:"", ttoken: "menu.vozila", icon: "directions_bus" },
  { path: "/p/predlosci-cjenika", name:"", ttoken: "menu.predlosci_cjenika", icon: "monetization_on" },
  { path: "/p/rezervacije", name:"", ttoken: "menu.rezervacije", icon: "assignment" },
  { path: "/p/komitenti", name:"", ttoken: "menu.komitenti", icon: "contact_phone" },
  { divider: true},
  { title: "menu.platforma" },
  { path: "/p/burza", name:"", ttoken: "menu.burza", icon:"trending_up"},
  { path: "/p/posebne-ponude", name:"", ttoken: "menu.posebne_ponude", icon: "stars" },
  { path: "/p/direktni-upiti", name:"", ttoken: "menu.direktniupiti", icon:"control_camera"},
  { path: "/p/ponude", name: "Ponude", ttoken: "menu.ponude", icon: "local_offer" },
  { path: "/p/komunikator", name:"", ttoken: "menu.komunikator", icon: "chat" }
];


export default menuPrijevoznici;
