import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Icons
import CancelIcon from '@material-ui/icons/Cancel';

//Custom components
import CardToolbarMenuItem from "UI/Card/CardToolbarMenuItem";

const CardToolbarMenuItemInvalidate = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return <CardToolbarMenuItem label={t("buttons.invalidate")} icon={<CancelIcon />} {...props} ref={ref} />;
});

export default CardToolbarMenuItemInvalidate;
