import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import DialogActions from "@material-ui/core/DialogActions";

//Material-UI Icons

//Custom Components
import DynamicDialog from "UI/Dialog/DynamicDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import PonudaAvatar from "UI/Form/Avatars/PonudaAvatar";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogActionButtonSave from "UI/Dialog/ActionButtons/DialogActionButtonSave";
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import FormContent from "Components/FormContent";

import dataController from "Lib/dataController";
import modelUpit from "Models/upiti";
import withFormController from "Components/withFormController";

function PonudaKorekcijaProvizijeDialog(props) {

  const { dialogContext } = props;
  const { record, validation, fields, subModels, mode, recordId } = props; //HOC withFormController
  const { doClose, onFieldChange, doValidate } = props; //HOC withFormController
  const { t } = useTranslation();

  const handleClose = () => {
    close({ dataChanged: false });
  }

  const handleUpdate = () => {
    // Nisam siguran da li se ovdje update-a napomena u ponudi ili je svoj posebna polja
    // Pa ćemo to idući tijedan
  }

  const close = (result) => {
    if (result.dataChanged) {
      doClose({ dataChanged: true, action: result.action });
    }

    dialogContext.hideDialog(2);
  };

  const commonProps = {
    record: record,
    validation: validation,
    fields: fields,
    mode: mode,
    subModels: subModels,
    recordId: recordId,
    onFieldChange: onFieldChange,
    columns: 1
  }
  return (
      <DynamicDialog onClose={handleClose} maxWidth={"sm"} >
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <PonudaAvatar />
          <DialogToolbarHeading>{t("titles.ponuda")}</DialogToolbarHeading>
          <DialogToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
          <Box m={2}>
            <FormContent
              {...commonProps}
              fieldNames={["napomena"]}
            />
          </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
        <DialogActionButtonSave variant="contained" onClick={handleUpdate} />
      </DialogActions>
    </DynamicDialog>
  );
}

export default withFormController(PonudaKorekcijaProvizijeDialog);