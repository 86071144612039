import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

//Custom Components
import dataController from "Lib/dataController";
import model from "Models/user";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import PublicPage from "UI/PublicPage/PublicPage";
import PasswordResetForm from "Views/Auth/PasswordResetForm";

import { authService } from "Services/authService";

function PasswordResetPage(props) {
  const loaderContext = useContext(LoaderContext);
  const [token, setToken] = useState({ ok: null, email: null, username: null });
  const { t } = useTranslation();

  const dc = new dataController(model);

  useEffect(() => {
    loaderContext.toggleLoading(true);
    authService
      .check(paramToken)
      .then((response) => {
        if (response && response.success && response.data.success) {
          setToken({
            ok: true,
            email: response.data.email,
            username: response.data.email
          });
        } else {
          setToken({
            ok: false
          });
        }
      })
      .catch((error) => {
        setToken({
          ok: false
        });
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  }, []);

  const getQueryToken = () => {
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const token = params.get("token");
    return token;
  };

  const paramToken = getQueryToken();

  let defaultValues = {};
  if (token.ok) {
    defaultValues = {
      email: username,
      passwordreset_token: paramToken
    };
  }

  return (
    <PublicPage>
      <Box m={2}>
        <Container maxWidth={"sm"}>
          {token.ok === null ? (
            <Card>
              <CardContent>
                <Typography variant="body1">{t("messages.please_wait")}</Typography>
              </CardContent>
            </Card>
          ) : token.ok ? (
            <PasswordResetForm dc={dc} form="password_reset" defaultValues={defaultValues} />
          ) : (
            <Card>
              <CardContent>
                <Typography variant="body1">{t("messages.passwordreset_token_unknown")}</Typography>
              </CardContent>
            </Card>
          )}
        </Container>
      </Box>
    </PublicPage>
  );
}

export default PasswordResetPage;
