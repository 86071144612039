import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core
import Box from "@material-ui/core/Box";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

//Material-UI Icons
import ViewModuleIcon from "@material-ui/icons/ViewModule";

//Custom Components
import withFormController from "Components/withFormController";
import { GridContainer, GridItem } from "UI/Grid";
import { FormTab, FormTabPanel, FormTabs } from "UI/Form";
import FormContent from "Components/FormContent";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import { vozilaService } from "Services/vozilaService";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ImageDialog from "UI/Image/ImageDialog";
import ImageCarouselDialog from "./ImageCarouselDialog";
import { renderArrowNext,renderArrowPrev } from "./CarouselArrows";

function VoziloPublicView(props) {
  const loaderContext = useContext(LoaderContext);
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);

  const { t, i18n } = useTranslation();

  const [currentTab, setCurrentTab] = useState(1);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [photoRecord, setPhotoRecord] = useState([]);

  const { record, validation, fields, subModels, mode } = props; //HOC withFormController
  const { onFieldChange } = props; //HOC withFormController


  const handleTabChange = (evt, value) => {
    setCurrentTab(value);
  };

  const handleLoadInitialPhotos = (ui = 0, record) => {
    const voziloId = record.id;
    const uuid_list = record.images;

    let arr_photos = photoRecord;
    if (ui < 2 || ui === uuid_list.length - 1) {
      loaderContext.toggleLoading(true);
      vozilaService
        .getFullImage(voziloId, uuid_list[ui])
        .then((resp) => {
          if (resp.success) {
            arr_photos.splice(ui, 0, {
              uuid: resp.data.uuid,
              image: resp.data.image
            });
          } else {
            snackbarContext.showNotification(resp.error.message, "error");
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          loaderContext.toggleLoading(false);
          if (ui === uuid_list.length - 1) {
            setPhotoRecord(arr_photos);
          } else {
            handleLoadInitialPhotos(ui + 1, record);
          }
        });
    } else {
      arr_photos.splice(ui, 0, {
        uuid: uuid_list[ui],
        image: null
      });
      handleLoadInitialPhotos(ui + 1, record);
    }
  };

  const handleCarouselChange = (index) => {
    const uuid_list = record.images;
    const length = uuid_list.length - 1;

    if (length > 3) {
      const backward = index !== 0 ? index - 1 : 4;
      const forward = index !== length ? index + 1 : 0;
      if (photoRecord[index].image === null) {
        handleLoadPhoto(index);
      }
      if (photoRecord[backward].image === null) {
        handleLoadPhoto(backward);
      }
      if (photoRecord[forward].image === null) {
        handleLoadPhoto(forward);
      }
    }
    setCarouselIndex(index);
  };

  const handleLoadPhoto = (ui) => {
    const voziloId = record.id;
    const arr_photos = photoRecord;

    vozilaService
      .getFullImage(voziloId, photoRecord[ui].uuid)
      .then((resp) => {
        if (resp.success) {
          arr_photos[ui] = {
            uuid: resp.data.uuid,
            image: resp.data.image
          };
          setPhotoRecord(arr_photos);
        } else {
          snackbarContext.showNotification(resp.error, "error");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const viewFullPhoto = (image) => {
    dialogContext.showDialog(ImageCarouselDialog, {
      // uuid_list: record.images,
      photoRecord: photoRecord,
      onClose: () => dialogContext.hideDialog(2),

    }, 2);
  };



  useEffect(() => {
    if (record.uuid) {
      handleLoadInitialPhotos(0, record);
    }
  }, [record]);

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels
  };

  const language = i18n.language;
  const description = record ? language === "hr" ? record.desc_hr : record.desc_en : "";

  return (
    <Box m={0}>
      <FormTabs value={currentTab} onChange={handleTabChange}>
        <FormTab
          value={1}
          label={t("posebna_ponuda.tabs.detalji")}
          icon={<ViewModuleIcon />}
          validation={validation}
          fields={[]}
        />
        <FormTab value={2} label={t("titles.cjenik")} icon={<MonetizationOnIcon />} aria-label="cjenici" />
      </FormTabs>
      <FormTabPanel value={currentTab} index={1}>
        <GridContainer direciton="column" justify="flex-start" spacing={2} alignItems="center">
          <GridItem md={12} sm={12}>
            <GridContainer direciton="row" justify="center" alignItems="center">
              <GridItem md={12} sm={12}>
                {record && record.images && photoRecord.length !== record.images.length ? null : (
                  <Carousel
                    infiniteLoop
                    className="carousel-root-custom"
                    showThumbs={false}
                    emulateTouch
                    swipeable
                    useKeyboardArrows
                    onChange={handleCarouselChange}
                    renderArrowPrev={renderArrowPrev}
                    renderArrowNext={renderArrowNext}
                  >
                    {photoRecord.map((photo, i) => (
                      <div key={"div-" + i} onClick={() => viewFullPhoto(photo.image)} style={{ cursor: "pointer" }}>
                        <CardMedia
                          key={"CardMedia-" + i}
                          component="img"
                          src={photo.image ? "data:image/png;base64, " + photo.image : null}
                          style={{ borderRadius: "8px", height: "300px" }}
                        />
                      </div>
                    ))}
                  </Carousel>
                )}
              </GridItem>
              <GridItem md={12} sm={12}>
                <GridContainer direction="column" spacing={1} alignItems="center">
                  <GridItem md={12} sm={12}>
                    <Typography variant="h3" align="center">
                      <b>{record ? record.marka_tip : ""}</b>
                    </Typography>
                  </GridItem>
                  <GridItem>
                    <Typography variant="paragraph">{description}</Typography>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem md={12} sm={12}>
            <GridContainer direction="row" justify="center">
              <FormContent
                fieldNames={["godina_proizvodnje", "br_sjedala", "extra_sjedala"]}
                columns={3}
                {...commonProps}
              />
            </GridContainer>
          </GridItem>
          <GridItem md={12} sm={12}>
            <GridContainer direction="row" justify="center">
              <FormContent fieldNames={["tip_id", "klasa_id", "klasa_motora_id"]} columns={3} {...commonProps} />
            </GridContainer>
          </GridItem>
          <GridItem md={12} sm={12}>
            <GridContainer direction="row" justify="center">
              <FormContent fieldNames={["vozilo_oprema_ids", "dozvoli_djecje_grupe"]} columns={1} {...commonProps} />
            </GridContainer>
          </GridItem>
        </GridContainer>
      </FormTabPanel>
      <FormTabPanel value={currentTab} index={2}>
        <GridContainer spacing={1} direciton="row" alignItems="center" justify="center">
          <GridItem md={4} sm={12}>
            <GridContainer>
              <FormContent
                title={t("cjenici.sections.kratkotrajni_najam")}
                fieldNames={["duration_for_transfer", "distance_for_transfer", "price_for_transfer"]}
                columns={1}
                {...commonProps}
              />
            </GridContainer>
          </GridItem>
          <GridItem md={4} sm={12}>
            <GridContainer>
              <FormContent
                title={t("cjenici.sections.poludnevni_najam")}
                fieldNames={["duration_for_half_day", "distance_for_half_day", "price_for_half_day"]}
                columns={1}
                {...commonProps}
              />
            </GridContainer>
          </GridItem>
          <GridItem md={4} sm={12}>
            <GridContainer>
              <FormContent
                title={t("cjenici.sections.cijelodnevni_najam")}
                fieldNames={["duration_for_day", "distance_for_day", "price_for_day"]}
                columns={1}
                {...commonProps}
              />
            </GridContainer>
          </GridItem>
        </GridContainer>
      </FormTabPanel>
    </Box>
  );
}

export default withFormController(VoziloPublicView);
