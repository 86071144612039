import numbro from 'numbro';

numbro.registerLanguage({
  languageTag: "hr-HR",
  delimiters: {
    thousands: ".",
    decimal: ","
  },
  abbreviations: {
    thousand: " tis.",
    million: " mil.",
    billion: " mlrd.",
    trillion: " bil."
  },
  ordinal: () => {
    return "."
  },
  currency: {
    symbol: "€",
    position: "postfix",
    code: "EUR"
  },
  currencyFormat: {
    thousandSeparated: true,
    totalLength: 4,
    spaceSeparated: true,
    average: true
  }
}, true);

numbro.registerLanguage({
  languageTag: "en-US",
  delimiters: {
    thousands: ".",
    decimal: ","
  },
  abbreviations: {
    thousand: " k.",
    million: " m.",
    billion: " b.",
    trillion: " t."
  },
  ordinal: () => {
    return "."
  },
  currency: {
    symbol: "€",
    position: "postfix",
    code: "EUR"
  },
  currencyFormat: {
    thousandSeparated: true,
    totalLength: 4,
    spaceSeparated: true,
    average: true
  }
}, true);

numbro.setDefaults({
  thousandSeparated: true,
  mantissa: 2
});

numbro.setLanguage('hr-HR')
