// These are some user fields that are present in multiple UI views - they all look on same DB table
// They can be added to UI models...

const username = {
  title: "Username",
  source: "username",
  ttoken: "users.username",
  type: "text",
  validation: {
    required: true,
    maxLength: 50
  },
  width: 15
};

const password = {
  title: "Lozinka",
  source: "password",
  ttoken: "users.password",
  type: "password",
  validation: {
    required: true
  }
};

const password_confirm = {
  title: "Potvrda lozinke",
  source: "password_confirm",
  ttoken: "users.password_confirm",
  type: "password",
  validation: {
    required: true
  }
};

const first_name = {
  title: "Ime",
  source: "first_name",
  ttoken: "users.first_name",
  type: "text",
  validation: {
    required: true,
    maxLength: 50
  },
  width: 25
};

const last_name = {
  title: "Prezime",
  source: "last_name",
  ttoken: "users.last_name",
  type: "text",
  validation: {
    required: true,
    maxLength: 50
  },
  width: 25
};

const last_login_on = {
  title: "Datum zadnje prijave",
  source: "last_login_on",
  ttoken: "common.last_login_on",
  type: "datetime",
  readonly: true,
  width: 20
};

const is_anoniman = {
  title: "Anonimni korisnik",
  source: "is_anoniman",
  ttoken: "users.status_anoniman",
  readonly: true,
  type: "text",
  items: {
    labels: ["common.ne", "common.da"],
    values: [false, true],
    default: false
  },
  translate: true
}



export const userFields = {
  username: username,
  first_name: first_name,
  last_name: last_name,
  password: password,
  password_confirm: password_confirm,
  last_login_on: last_login_on
};
