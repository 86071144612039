import hereApi from "Lib/hereApi";
import moment from "moment";

import { decode } from "Lib/flexiblePolyline";

export const hereService = {
  autosuggest: autosuggest,
  discover: discover,
  geocode: geocode,
  autocomplete: autocomplete, //BETA state
  browse: browse,
  lookup: lookup,
  revgeocode: revgeocode,

  route: route
};

function autosuggest(searchString, locationContext) {
  const hereApiInstance = new hereApi();
  const url =
    "https://autosuggest.search.hereapi.com/v1/autosuggest?q=" +
    searchString +
    _parseLocationContext(locationContext) +
    "&lang=hr-HR";

  return hereApiInstance.Call(url).then((response) => {
    if (response.success) {
      return _parseItems(response.data.items);
    }
    return response;
  });
}

function discover(searchStirng) {
  const hereApiInstance = new hereApi();
  const url = "https://discover.search.hereapi.com/v1/discover?q=" + searchStirng;

  return hereApiInstance.Call(url);
}

function geocode(searchString) {
  const hereApiInstance = new hereApi();
  const url = "https://geocode.search.hereapi.com/v1/geocode?q=" + searchString;

  return hereApiInstance.Call(url);
}

//BETA state
function autocomplete(searchString) {
  const hereApiInstance = new hereApi();
  const url = "https://autocomplete.search.hereapi.com/v1/autocomplete?q=" + searchString;

  return hereApiInstance.Call(url);
}

function browse(lat, lon) {
  const hereApiInstance = new hereApi();
  const url = "https://browse.search.hereapi.com/v1/browse?at=" + lat + "," + lon;

  return hereApiInstance.Call(url);
}

function lookup(id) {
  const hereApiInstance = new hereApi();
  const url = "https://lookup.search.hereapi.com/v1/lookup?id=" + id;

  return hereApiInstance.Call(url);
}

function revgeocode(lat, lon) {
  const hereApiInstance = new hereApi();
  const url = "https://revgeocode.search.hereapi.com/v1/revgeocode?at=" + lat + "," + lon;

  return hereApiInstance.Call(url);
}

function route(origin, destination, via, avoidTolls = false) {
  const hereApiInstance = new hereApi();
  let url =
    "https://router.hereapi.com/v8/routes?transportMode=car" +
    "&origin=" +
    origin.lat +
    "," +
    origin.lng +
    "&destination=" +
    destination.lat +
    "," +
    destination.lng +
    "&return=polyline,summary";

  if (Array.isArray(via) && via.length > 0) {
    via.forEach((v) => (url += "&via=" + v.lat + "," + v.lng));
  }
  if (avoidTolls) {
    url += "&avoid[features]=tollRoad";
  }

  return hereApiInstance.Call(url).then((response) => {
    if (response.success) {
      return _parseRoutes(response.data.routes);
    } else {
      return [];
    }
  });
}

function _parseLocationContext(ctx) {
  //TODO: parse at, in,
  return "&at=45,15";
}

function _parseItems(items) {
  if (Array.isArray(items)) {
    const data = items.map((i) => {
      //HACK: HERE Maps postavlja 'Split' na jednosmjerni gat koji vodi u Anconu, tako da sve sto ide iz Splita ili preko Splita mora prvo brodom u Anconu :)
      const hackPosition = i.position && (i.position.lat === 43.50278 && i.position.lng === 16.44273) ? {lat: 43.50356, lng: 16.44266} : i.position;
      return { value: i.id, label: i.title, position: hackPosition };
    });

    return { success: true, data: data };
  } else {
    return [];
  }
}

function _parseRoutes(routes) {
  if (Array.isArray(routes) && routes.length > 0) {
    const route = routes[0];

    let duration = 0;
    let length = 0;
    let routePoints = [];

    if (Array.isArray(route.sections)) {
      route.sections.forEach((s) => {
        duration += s.summary.duration;
        length += s.summary.length;
        routePoints = routePoints.concat(_parseFlexiPolyline(s.polyline));
      });
    }

    const time = moment().startOf("day").seconds(duration);
    const secondsInDay = 24 * 60 * 60;

    const timeFormatted = duration < secondsInDay
      ? time.format("H") + " h " + time.format("m") + " min"
      : Math.floor(duration / secondsInDay) + " d " + time.format("H") + " h " + time.format("m") + " min"

    return {
      success: true,
      data: {
        duration: { value: duration, label: timeFormatted },
        length: { value: length, label: Math.round(length / 1000).toString() + " km" },
        routes: [
          {
            id: route.id,
            points: routePoints
          }
        ]
      }
    };
  } else {
    return null;
  }
}

function _parseFlexiPolyline(flexPolyline) {
  const points = decode(flexPolyline);
  return points.polyline.map((x) => {
    return { lat: x[0], lng: x[1] };
  });
}
