import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Icons
import EditIcon from "@material-ui/icons/Edit";

//Custom Components
import TableButton from "UI/Table/TableButton";

function TableButtonEdit(props) {
  const { t } = useTranslation();

  return (
    <TableButton variant="contained" allow="one" startIcon={<EditIcon />} {...props}>
      {t("buttons.edit")}
    </TableButton>
  );
}

export default TableButtonEdit;
