import React, { Fragment, useState } from 'react'
import { withTranslation } from 'react-i18next';

//Material-UI Core Components
import { makeStyles } from '@material-ui/core/styles';
import { amber, green } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

const customStyles = makeStyles(theme => ({
  root: {
    justifyContent: "center"
  }
}));

const variantsStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  }
}));

function withSnackbar(WrappedComponent) {

  return function(props) {

    const [ status, setStatus] = useState({show: false, message: '', variant:'info'});

    const showSnackbar = function (message, type) {
      const { t }  = props;
      let msg = 'Unknown error';
      switch (message) {
        case "update":
          msg = t("notifications.update");
          break;
        case "insert":
          msg = t("notifications.insert");
          break;
        case "delete":
          msg = t("notifications.delete");
          break;
        case 'copy':
          msg = t("notifications.copy");
          break;
        case 'mistake':
          msg = t('notifications.mistake');
          break
        default:
          if (message && message.length > 0) {
            msg = t(message);
          }
          break;
      }

      let newVariant = status.variant;
      switch (type) {
        case 'success':
        case 'info':
        case 'error':
        case 'warning':
          newVariant = type;
      }

      setStatus({show: true, message: msg, variant: newVariant});
    }

    const hideSnackbar = function() {
      setStatus({show: false, message: '', variant: ''});
    }

    const classes = customStyles();
    const variants = variantsStyles();

    return (
      <Fragment>
        <WrappedComponent
          {...props}
          showNotification={showSnackbar}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          key={"snack"}
          open={status.show}
          onClose={hideSnackbar}
          autoHideDuration={3000}
        >
          <SnackbarContent
            classes={classes}
            className={variants[status.variant]}
            message={status.message}
          />
        </Snackbar>
      </Fragment>
    );
  }
}

export default (Component) => withTranslation()(withSnackbar(Component))  ;