import React from "react";

//Material-UI Core Components
import Box from "@material-ui/core/Box";

//Custom Components
import AdminPage from "UI/AppPage/AdminPage";
import NotificationsTable from "Views/Notifications/NotificationsTable";

const AdminNotificationsPage = React.memo((props) => {

  return (
    <AdminPage>
      <Box m={2}>
        <NotificationsTable />
      </Box>
    </AdminPage>
  );
})

export default AdminNotificationsPage;
