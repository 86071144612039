import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function PoslovniSubjektPrijevoznikIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M 0,0 V 18.029297 H 7.597656 C 7.399802,17.367683 6.909139,16.786996 6.890625,16.033203 c 6e-6,-0.0026 -7e-6,-0.0054 0,-0.0078 H 6.009766 V 14.023438 H 6.875 C 6.8659,13.356173 6.8564,12.687409 6.84961,12.019532 H 6.009767 V 10.015625 H 6.85547 C 6.86417,9.3465026 6.88045,8.6779486 6.91797,8.0117186 H 6.009767 V 6.0097656 H 7.51172 C 8.092167,5.2198779 9.012239,4.7507448 10.013673,4.4804687 L 10.017573,0 Z M 2.0039062,2.0019531 H 4.0058594 V 4.0058594 H 2.0039062 Z m 4.0058598,0 H 8.013672 V 4.0058594 H 6.009766 Z m 7.925781,3.1503906 c -3.350987,0 -6.066406,0.3777537 -6.066406,3.0312499 v 7.5820314 c 0,0.667164 0.295346,1.266616 0.757812,1.683594 v 1.34961 a 0.75814185,0.75814185 0 0 0 0.759766,0.757812 h 0.757812 a 0.75814185,0.75814185 0 0 0 0.757813,-0.757812 v -0.757813 h 6.064453 v 0.757813 a 0.75814185,0.75814185 0 0 0 0.757812,0.757812 h 0.759766 a 0.75814185,0.75814185 0 0 0 0.757813,-0.757812 v -1.34961 C 19.704652,17.032241 20,16.432789 20,15.765625 V 8.1835936 c -2e-6,-2.6534962 -2.713464,-3.0312499 -6.064453,-3.0312499 z M 2.0039062,6.0097656 h 2.0019532 v 2.001953 H 2.0039062 Z m 7.3828128,2.173828 h 9.097656 V 11.97461 H 9.386719 Z M 2.0039062,10.015625 h 2.0019532 v 2.003907 H 2.0039062 Z m 0,4.007813 h 2.0019532 v 2.001953 H 2.0039062 Z M 10.523438,14.25 a 1.1372128,1.1372128 0 0 1 1.136718,1.136719 1.1372128,1.1372128 0 0 1 -1.136718,1.136719 A 1.1372128,1.1372128 0 0 1 9.386719,15.386719 1.1372128,1.1372128 0 0 1 10.523438,14.25 Z m 6.822265,0 a 1.1372128,1.1372128 0 0 1 1.138672,1.136719 1.1372128,1.1372128 0 0 1 -1.138672,1.136719 A 1.1372128,1.1372128 0 0 1 16.208984,15.386719 1.1372128,1.1372128 0 0 1 17.345703,14.25 Z" />
    </SvgIcon>
  );
}

export default PoslovniSubjektPrijevoznikIcon;
