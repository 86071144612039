import React, { useContext, useEffect } from "react";

//Custom Components
import UserContext from "Components/UserContext/UserContext";
import AppPage from "UI/AppPage/AppPage";
import useModuleRedirect from "Components/useModuleRedirect";

function ProfilePage(props) {
  const { moduleRedirect } = useModuleRedirect({ path: "/profile"});
  const userContext = useContext(UserContext);

  useEffect(() => {
    if (userContext.loaded) {
      moduleRedirect();
    }
  }, [userContext.loaded])

  return (
    <AppPage />
  );
}

export default ProfilePage;
