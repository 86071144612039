import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

//Material UI
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";

//Material-UI Icons
import HelpIcon from "@material-ui/icons/Help";
import InfoIcon from "@material-ui/icons/Info";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import MoneyIcon from "@material-ui/icons/Money";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ChatIcon from "@material-ui/icons/Chat";
import ReceiptIcon from "@material-ui/icons/Receipt";

//CBC Icons
import PotvrdaRezervacijeIcon from "Icons/PotvrdaRezervacijeIcon";

//Custom components
import withFormController from "Components/withFormController";
import { FormHeading, FormHeadingID, FormFillContent, FormTab, FormTabPanel, FormTabs } from "UI/Form";
import TabOsnovniPodaci from "../VoznjaFormTabs/TabOsnovniPodaci";
import TabPojedinacneVoznje from "../VoznjaFormTabs/TabPojedinacneVoznje";
import TabPonuda from "../VoznjaFormTabs/TabPonuda";
import TabFinancijskeObaveze from "../VoznjaFormTabs/TabFinancijskeObaveze";
import CardHeading from "UI/Card/CardHeading";
import VoznjaAvatar from "UI/Form/Avatars/VoznjaAvatar";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import { CardColored, CardToolbarButtonAlarm, CardToolbarButtonBack } from "UI/Card";

import TabPlatforma from "Views/Prijevoznici/Voznje/VoznjaFormTabs/TabPlatforma";
import TabInternaKalkulacija from "../VoznjaFormTabs/TabInternaKalkulacija";
import TabPutniNalozi from "../VoznjaFormTabs/TabPutniNalozi";
import TabPotvrdaRezervacije from "../VoznjaFormTabs/TabPotvrdaRezervacije";
import TabFaktura from "Views/Prijevoznici/Voznje/VoznjaFormTabs/TabFaktura";

import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItemCopy from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemCopy";
import DialogToolbarMenuItemInvalidate from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemInvalidate";
import DialogToolbarMenuItemInvalidateCopy from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemInvalidateCopy";
import PrintButton from "UI/Buttons/PrintButton";
import { posloviStatuses, retrievePosloviStatusLabel } from "Lib/statuses";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import SaveButton from "UI/Buttons/SaveButton";
import ConfirmButton from "UI/Buttons/ConfirmButton";

import { posloviService } from "Services/posloviService";
import confirmDialog from "Lib/confirmDialog";
import KomunikatorSinglePage from "Views/Komunikator/KomunikatorSinglePage";
import DialogToolbarMenuItemPosebnaPonudaCreate from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemPosebnaPonuda";
import EuroConversionComment from "Components/EuroConversionComment";

function PlatformskiPosaoForm24(props) {
  const snackbarContext = useContext(SnackbarContext);

  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();

  const [currentTab, setCurrentTab] = useState(0);

  const { dc, record, mode, validation, fields, subModels } = props; //HOC withFormController
  const { onFieldChange, doUpdate, doValidate } = props; //HOC withFormController
  const { handleCopy, handleStorniraj, handleStornirajCopy, handleRefreshForm } = props;
  const { isAdmin, isPanic } = props;
  const { ownerId } = props;

  const handleTabChange = (evt, value) => {
    setCurrentTab(value);
  };

  const handleClickBack = () => {
    history.goBack();
  };

  const handleUpdate = (evt) => {
    if (doUpdate) {
      doUpdate().then((result) => {
        if (result.success) {
          snackbarContext.showNotification("update", "success");
        }
      }).catch((result) => {
        if (result.success === false && result.validationPass === false) {
          //do nothing
        } else {
          snackbarContext.showNotification(result.error, "error");
        }
      });
    }
  };

  const confirmNextStep = () => {
    const title = t("confirm_voznje_status.voznja24.title");
    const text = t("confirm_voznje_status.voznja24.text");
    const type = "warning";
    const confirmButtonText = t("cdialogs.confirm");
    const cancelButtonText = t("cdialogs.cancel");

    return confirmDialog(title, text, type, confirmButtonText, cancelButtonText);
  };

  const handleConfirm = () => {
    confirmNextStep().then((result) => {
      if (result.confirmed) {
        if (doValidate) {
          const validation = doValidate();
          if (validation) {
            posloviService
              .interniPosaoStanje24_25(record.id)
              .then((resp) => {
                if (resp.success) {
                  snackbarContext.showNotification("update", "success");
                  if (handleRefreshForm) {
                    handleRefreshForm();
                  }
                } else {
                  snackbarContext.showNotification(resp.error.message, "error");
                }
              })
              .finally(() => {
                //history.goBack();
              });
          }
        }
      }
    });
  };

  const title = t(retrievePosloviStatusLabel(record.posao_state_id));

  const identifier = dc.getSource() + record.posao_state_id;
  const isInterni = record["tip_voznje_id"] === 50;
  const isStorno = record.storno === true;
  const isStornoNaplativ = record.storno_naplativ === true;
  const isAgentura = record.agentura === true;

  const printButtonTarget = currentTab === 6 ? "potvrda_rezervacije" : null;
  const printButtonText = currentTab === 6 ? t("buttons.potvrda_rezervacije") : t("buttons.print");
  const printButtonDisabled = !(currentTab === 6);

  const customColor = isStorno ? theme.palette.custom.storno :
    isPanic ? theme.palette.custom.panic :
    isAgentura ? theme.palette.custom.agentura :
    undefined;

  const customText = isStorno ? t("messages.storno") :
    isPanic ? t("messages.panic") :
    isAgentura ? t("messages.agentura") :
    undefined;

  const allowAction = !isPanic && !isAgentura && !isStorno;

  const commonTabProps = {
    currentTab: currentTab,
    dc: dc,
    subModels: subModels,
    record: record,
    mode: mode,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    isAdmin: isAdmin,
    isPanic: isPanic,
    recordId: record.id,
    isPanic: isPanic,
    allowAction: allowAction
  };

  return (
    <CardColored color={customColor}>
      <CardHeading recordStatus={record.posao_state_id} statuses={posloviStatuses} color={customColor}>
        <Toolbar variant="dense" disableGutters={true}>
          <VoznjaAvatar />
          <FormHeading header={title} subheaderContent={<FormHeadingID id={record.id} />} />
          <DialogToolbarFillContent>
            {customText ?
              <Typography variant="button">{customText}</Typography>
              : null
            }
            </DialogToolbarFillContent>
          {
            !props.isPanic ?
              <Fragment>
                <CardToolbarButtonAlarm record={record} type="voznja" onFieldChange={onFieldChange} />
                <DialogToolbarMenuButton>
                  {isInterni && !isStorno ? (
                    <DialogToolbarMenuItemInvalidate onClick={() => handleStorniraj(record["id"])} />
                  ) : null}
                  {isInterni && !isStorno ? (
                    <DialogToolbarMenuItemInvalidateCopy onClick={() => handleStornirajCopy(record["id"], record)} />
                  ) : null}
                  <DialogToolbarMenuItemCopy onClick={() => handleCopy(record)} />
                  <DialogToolbarMenuItemPosebnaPonudaCreate record={record} />
                </DialogToolbarMenuButton>
              </Fragment>
              : null
          }
          <CardToolbarButtonBack onClick={handleClickBack} />
        </Toolbar>
      </CardHeading>
      <CardContent style={{ paddingTop: 0 }}>
        <FormTabs value={currentTab} onChange={handleTabChange} identifier={identifier}>
          <FormTab id="platforma" label={t("poslovi.tabs.platforma")} icon={<HelpIcon />} aria-label="platforma" />
          <FormTab
            id="osnovni-podaci"
            label={t("poslovi.tabs.osnovni_podaci")}
            icon={<InfoIcon />}
            aria-label="osnovni_podaci"
          />
          <FormTab
            id="pojedinacne-voznje"
            label={t("poslovi.tabs.pojedinacne_voznje")}
            icon={<AirportShuttleIcon />}
            aria-label="pojedinacne_voznje"
          />
          <FormTab
            id="interna-kalkulacija"
            label={t("poslovi.tabs.interna_kalkulacija")}
            icon={<MoneyIcon />}
            aria-label="interna_kalkulacija"
          />
          <FormTab id="ponuda" label={t("poslovi.tabs.ponuda")} icon={<AssignmentIcon />} aria-label="ponuda" />
          <FormTab
            id="financijske-obveze"
            label={t("poslovi.tabs.financijske_obveze")}
            icon={<MonetizationOnIcon />}
            aria-label="financijske_obveze"
          />
          <FormTab
            id="potvrda-rezervacije"
            label={t("poslovi.tabs.potvrda_rezervacije")}
            icon={<PotvrdaRezervacijeIcon />}
            aria-label="potvrda_rezervacije"
          />
          <FormTab
            id="komunikator"
            label={t("poslovi.tabs.komunikator")}
            icon={<ChatIcon />}
            aria-label="komunikator"
          />
          <FormTab
            id="putni-nalozi"
            label={t("poslovi.tabs.putni_nalozi")}
            icon={<AssignmentIndIcon />}
            aria-label="putni_nalozi"
          />
          { isStornoNaplativ ?
            <FormTab
              id="faktura"
              label={t("poslovi.tabs.faktura")}
              icon={<ReceiptIcon />}
              aria-label="faktura"
            /> : null
          }
        </FormTabs>
        <FormTabPanel value={currentTab} index={0}>
          <TabPlatforma {...commonTabProps} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={1}>
          <TabOsnovniPodaci {...commonTabProps} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={2}>
          <TabPojedinacneVoznje {...commonTabProps} tabValue={2} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={3}>
          <TabInternaKalkulacija {...commonTabProps} tabValue={3} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={4}>
          <TabPonuda {...commonTabProps} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={5}>
          <TabFinancijskeObaveze {...commonTabProps} tabValue={5} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={6}>
          <TabPotvrdaRezervacije {...commonTabProps} index={6} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={7}>
          <KomunikatorSinglePage recordId={record.id} refreshForm={handleRefreshForm} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={8}>
          <TabPutniNalozi {...commonTabProps} posao_state_id={record.posao_state_id} tabValue={8} />
        </FormTabPanel>
        { isStornoNaplativ ?
          <FormTabPanel value={currentTab} index={9}>
            <TabFaktura {...commonTabProps} mode="update" tabValue={9} doValidate={doValidate} />
          </FormTabPanel> : null
        }
      </CardContent>
      <CardActions>
        <EuroConversionComment />
        <FormFillContent />
        <PrintButton
          dc={dc}
          target={printButtonTarget}
          record={record}
          text={printButtonText}
          disabled={printButtonDisabled}
          platformskiPosao={true}
        />
        {
          allowAction ?
            <ConfirmButton
              onClick={handleConfirm}
              text={t("buttons.start_voznje")}
              disabled={currentTab !== 8}
            />
            : null
        }
        {
          allowAction ?
            <SaveButton
              onClick={handleUpdate}
              text={t("buttons.save")}
            />
            : null
        }
      </CardActions>
    </CardColored>
  );
}

export default withFormController(PlatformskiPosaoForm24);
