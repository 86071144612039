import React from 'react'
import KomunikatorContext from 'Components/KomunikatorContext/KomunikatorContext';
import ChatWindow from "UI/Chat/ChatWindow";

import websocket from "Lib/websocket";
import { localStorageAPI as lsAPI } from "Lib/localStorageAPI";
import { helpers as H } from "Lib/helpers";
import { sifrarnici } from "Lib/sifrarnici";
import { Role } from "Lib/role";
import { komunikatorService as KS } from "Services/komunikatorService";
import { notificatorService as NS } from "Services/notificatorService";
import { notificatorService } from '../../services/notificatorService';

//Material-UI Icons
import LaunchIcon from "@material-ui/icons/Launch";

//Custom Icons
import DirektanUpitIcon from "Icons/DirektanUpitIcon";
import VoznjaIcon from "Icons/VoznjaIcon";
import UpitIcon from "Icons/UpitIcon";

const T = sifrarnici.msg_delivery_types;

class KomunikatorProvider extends React.Component {

  constructor(props) {
    super(props);

    this.limit = 20;
    this.userId = null;
    this.roleId = null
    this.currentUserChannels = new Set();
    this.lastHash = "";

    this.addConversations = this.addConversations.bind(this);
    this.addNewChat = this.addNewChat.bind(this);
    this.closeChat = this.closeChat.bind(this);
    this.minimizeChat = this.minimizeChat.bind(this);
    this.setInSidebar = this.setInSidebar.bind(this);

    this.handleError = this.handleError.bind(this);

    this.handleGetChannelData = this.handleGetChannelData.bind(this);

    this.parseMsg = this.parseMsg.bind(this);
    this.setMessageId = this.setMessageId.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.deliveryMessage = this.deliveryMessage.bind(this);
    this.setDeliveryMessage = this.setDeliveryMessage.bind(this);
    this.getMessages = this.getMessages.bind(this);
    this.getOlderMessages = this.getOlderMessages.bind(this);
    this.handleGetOlderMessages = this.handleGetOlderMessages.bind(this);
    this.getNewerMessages = this.getNewerMessages.bind(this);
    this.handleGetNewerMessages = this.handleGetNewerMessages.bind(this);
    this.handleRecieveMsg = this.handleRecieveMsg.bind(this);
    this.handleMessageDelivery = this.handleMessageDelivery.bind(this);

    this.handleCheckConnectionPreprocess = this.handleCheckConnectionPreprocess.bind(this);
    this.handleCheckConnection = this.handleCheckConnection.bind(this);
    this.checkDeliveries = this.checkDeliveries.bind(this);
    this.handleCheckDeliveries = this.handleCheckDeliveries.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.findMessageByCondition = this.findMessageByCondition.bind(this);

    this.refreshNotifications = this.refreshNotifications.bind(this);
    this.markReadNotification = this.markReadNotification.bind(this);
    this.markReadNotifications = this.markReadNotifications.bind(this);
    this.getNotificationRedirectLink = this.getNotificationRedirectLink.bind(this);
    this.getNotificationIcon = this.getNotificationIcon.bind(this);
    this.handleNotification = this.handleNotification.bind(this);
    this.handleNotifications = this.handleNotifications.bind(this);
    this.removeNotifications = this.removeNotifications.bind(this);

    this.wsQ = [];
    this.emptyQ = this.emptyQ.bind(this);
    this.state = {
      sidebarWindows: [],
      chatWindows: [], // complement of conversations visible in sidebar (have sidebar prop set to true)

      connect: this.connect.bind(this),
      connected: false,
      disconnect: this.disconnect.bind(this),

      setMainWindowFocused: this.setMainWindowFocused.bind(this),
      addFocused: this.addFocused.bind(this),
      removeFocused: this.removeFocused.bind(this),
      mainWindowFocused: new Set(), // stores information about chats in Main window
      chatWindowsFocused: new Set(), //stores information about chats in small Chat windows, multiple can be open, can be same as one in Main window
      focused: new Set(),

      firstRender: true,
      bsId: null,
      users: [],

      addConversations: this.addConversations,
      addNewChat: this.addNewChat,
      closeChat: this.closeChat,
      minimizeChat: this.minimizeChat,
      setInSidebar: this.setInSidebar,

      sendMessage: this.sendMessage,
      getMessages: this.getMessages,
      getOlderMessages: this.getOlderMessages,

      markReadNotification: this.markReadNotification,
      markReadNotifications: this.markReadNotifications,
      markDeleteNotifications: this.markDeleteNotifications,
      getNotificationRedirectLink: this.getNotificationRedirectLink,
      getNotificationIcon: this.getNotificationIcon,

      messagesPerConversation: {},
      EOM: {}, // end of messages
      notifications: [],
      msgNotifications: [],

      setDeliveryMessage: this.setDeliveryMessage,
      checkConnection: this.checkConnection.bind(this)
    };

    this.websocket = null
  }

  /**
    @name: setSidebarConvos
    @description: checks if there is any chats in localstorage and sets them to sidebar
    @params: void
    @returns: void
    */
  setSidebarConvos() {
    const sidebarChats = lsAPI.getAllValues('comm');
    if (sidebarChats) {
      const sidebarWindows = [];
      for (const i in sidebarChats) {
        if (typeof sidebarChats[i] === 'object') {
          sidebarWindows.push({...sidebarChats[i], sidebar: true});
        }
      }
      this.setState({ sidebarWindows: sidebarWindows });
    }
  }

  /**
   * @name combineFocused
   * @description
   *  Get new Set of unique values between main window and chat windows
   * @param {int} mainWindowId
   * @param {Set(int)} chatWindowsSet
   * @returns Set
   */
  combineFocused(mainWindowId, chatWindowsSet){

  }

  /**
    @name: setMainWindowFocused
    @description:
      Sets id of currently focused channel and removes it from the notifications if present
      In addition it sets the last message as read
    @params:
      id: int (channelId)
      wasId: int (channelId) - if id is null, wasId was Id berfore 'deletion'
    @returns: void
    */
  setMainWindowFocused(id, wasId) {
    // this.setState({ focused: id });
    if (id) {
      this.setState(prevState => ({
        mainWindowFocused: prevState.mainWindowFocused.add(id),
        focused: prevState.focused.add(id),
        msgNotifications: prevState.msgNotifications.filter(m => m.id !== id),
        sidebarWindows: prevState.sidebarWindows.map(
          sw => (sw.id === id ? {...sw, unread: 0} : sw)
        ),
        chatWindows: prevState.chatWindows.map(
          cw => (cw.id === id ? {...cw, unread: 0} : cw)
        )
      }));
    } else {
      this.setState(prevState => ({
        mainWindowFocused: prevState.mainWindowFocused.delete(wasId) ? prevState.mainWindowFocused : prevState.mainWindowFocused,
        focused: prevState.chatWindowsFocused.has(wasId) ? prevState.focused : (prevState.focused.delete(wasId) ? prevState.focused : prevState.focused)
      }));
    }
  }

  /**
    @name: addFocused
    @description: adds channel to set of focused channels (all open window and komunikator single page)
    @params: id: int (channelId)
    @returns: void
    */
  addFocused(id) {
    if (id) {
      this.setState(prevState => ({
        chatWindowsFocused: prevState.chatWindowsFocused.add(id),
        focused: prevState.focused.add(id),
        msgNotifications: prevState.msgNotifications.filter(m => m.id !== id),
        sidebarWindows: prevState.sidebarWindows.map(
          sw => (sw.id === id ? {...sw, unread: 0} : sw)
        ),
        chatWindows: prevState.chatWindows.map(
          cw => (cw.id === id ? {...cw, unread: 0} : cw)
        )
      }));
      const msg = this.findMessageByCondition(id, null, (m) => (m.created_by !== this.userId))
      if (msg !== null) {
        this.deliveryMessage(this.setDeliveryType(msg, T.seen));
      }
    }
  }

  /**
    @name: removeFocused
    @description: removes channel from the set of focused channels (onMinimize, onClose or when changed in single page)
    @params: id: int (channelId)
    @returns: void
    */
  removeFocused(id) {
    if (id) {
      this.setState(prevState => (
        {
          chatWindowsFocused: prevState.chatWindowsFocused.delete(id) ? prevState.chatWindowsFocused : prevState.chatWindowsFocused,
          focused: prevState.mainWindowFocused.has(id) ? prevState.focused : (prevState.focused.delete(id) ? prevState.focused: prevState.focused)
        }
      ));
    }
  }

  /**
    @name: emptyQ
    @description: empty the queued websocket methods
    @params: void
    @returns: viud
    */
  emptyQ() {
    if (this.websocket && this.websocket.socket && this.websocket.socket.readyState === WebSocket.OPEN) {
      const currentQ = [...this.wsQ];
      this.wsQ = [];
      currentQ.forEach(f => f());
    }
  }

  /**
    @name: connect
    @description: make a connection with websocket
    @params:
      userId: int (id of the user logged in)
    @returns:
      void
    */
  connect(userId, roleId, bsId) {
    const { connected } = this.state;
    if (connected) return;
    this.websocket = new websocket({
      emptyQ: this.emptyQ,
      handleError: this.handleError,
      handleNotification: this.handleNotification,
      handleGetChannelData: this.handleGetChannelData,
      handleRecieveMsg: this.handleRecieveMsg,
      handleGetOlderMessages: this.handleGetOlderMessages,
      handleGetNewerMessages: this.handleGetNewerMessages,
      handleMessageDelivery: this.handleMessageDelivery,
      handleCheckConnection: this.handleCheckConnectionPreprocess,
      handleCheckDeliveries: this.handleCheckDeliveries,
    }, {
      userId: userId
    });
    this.setState({ connected: true });
    this.userId = userId;
    this.roleId = roleId;
    this.getUsers(bsId);
    this.refreshNotifications();
    if (this.checkUser(userId)) {
      this.setSidebarConvos(); // adds chats from localStorage to sidebar
    } else {
      this.clearLS();
      lsAPI.setSecret('comm', userId);
    }
  }

  /**
    @name: disconnect
    @description: disconnects user from the websocket endpoint
    @params: reason: int
    @returns: void
    */
  disconnect(reason) {
    const { connected } = this.state;
    if (!connected) return;

    if (this.websocket && this.websocket.socket && this.websocket.socket.readyState === WebSocket.OPEN) {
      this.websocket.disconnect(reason);
    }

    this.setState({
      connected: false,
      chatWindows: [],
      sidebarWindows: [],
      focused: new Set()
    });
    this.userId = null;
    this.currentUserChannels.forEach(channelId => {
      this.setState({
        [channelId]: null
      });
    });
    this.currentUserChannels.clear();
  }

  /**
    @name: checkUser
    @description: checks if current user is the same as the one logged out
    @params: userId: int
    @returns: void
    */
  checkUser(userId) {
    const tmp = lsAPI.getSecret('comm');
    return tmp === userId;
  }

  /**
    @name: clearLS
    @description: clears everything under key comm in localstorage
    @params: void
    @returns: void
    */
  clearLS() {
    lsAPI.clearStorage('comm');
  }

  /**
    @name: getUsers
    @description: fetch users in your bussiness subject
    @params:
      bsId: int
    @returns: void
    */
  getUsers(bsId) {
    KS.getInternalMembers(bsId).then(resp => {
      if (resp.success) {
        this.setState({
          bsId: bsId,
          users: resp.data || []
        })
      }
    })
  }

  /**
    @name: parseMsg
    @description: adds optional properties to Message object
    @params:
      msg: Message object
    @returns:
      Message object [owner]
    */
  parseMsg(msg) {
    return Object.assign({},
      msg,
      {
        body: msg.message_body ? msg.message_body : msg.body,
        owner: msg.created_by === this.userId
      }
    )
  }

  /**
    @name: setDeliveryType
    @description: changes the delivery type of a message
    @params:
      message: Message object
      type: int
    @returns:
      Message object[delivery_type]
    */
  setDeliveryType(message, type) {
    return Object.assign(message, {
      message_delivery_type_id: type
    });
  }

  /**
    @name: sendMessage
    @description:
      sends a chat message to websocket
      in addition sets the delivery type of message as queued
    @params:
      message: Message object
    @returns:
      void
    */
  sendMessage(message) {
    message.guid = H.uuidGenerator();
    if (this.websocket && this.websocket.socket && this.websocket.socket.readyState === WebSocket.OPEN) {
      this.websocket.sendMessage(message);
    } else {
      this.wsQ.push(() => this.websocket.sendMessage(message));
    }
    this.handleRecieveMsg({
      message: this.setDeliveryType(message, T.queued),
      created_by: this.userId
    }, true);
  }

  /**
    @name: deliveryMessage
    @description: changes message delivery status
    @params:
      message: Message object or Message object Array
    @returns:
      Message object
    */
  deliveryMessage(message) {
    if (this.websocket && this.websocket.socket && this.websocket.socket.readyState === WebSocket.OPEN) {
      if (Array.isArray(message)) {
        this.websocket.deliveryMessageBatch(message);
      } else {
        this.websocket.deliveryMessage(message);
      }
    } else {
      this.wsQ.push(() => this.websocket.deliveryMessage(message));
    }
    return message;
  }

  /**
    @name: setDeliveryMessage
    @description: sends to server new delivery status and changes its state
    @params:
    @returns:
    */
  setDeliveryMessage(channelId, message) {
    this.deliveryMessage(message);
    this.setState(prevState => ({
      [channelId]: prevState[channelId].map(m => m.id === message.id ? message : m)
    }), () => this.currentUserChannels.add(channelId));
  }

  /**
    @name: handleRecieveMsg
    @description:
      handler when websocket recieves a message or called manually when a message is sent
      it will either get messages for the channel or extend the current messages
      in addition sets the delivery type of the message as delivered
    @params:
      messageData: Message object [created_by]
    @returns:
      void
    */
  handleRecieveMsg(messageData, manual = false) {
    const { channel_id: channelId } = messageData.message;
    const { [channelId]: channel } = this.state;

    let msg = this.parseMsg({
      ...messageData.message,
      created_by: messageData.created_by
    });
    if (!manual) {
      msg = this.setDeliveryType(msg, T.delivered)
      this.deliveryMessage(msg);
      this.addMsgNotification(channelId, "", msg);
    }
    if (channel && manual) {
      this.setState(prevState => ({
        [channelId]: [msg].concat(prevState[channelId])
      }), () => this.currentUserChannels.add(channelId))
    } else {
      this.getMessages(channelId);
    }

  }

  /**
    @name: handleMessageDelivery
    @description: sets the delivery status of the message
    @params:
      response: object, data from the middleware
      callback: function
    @returns: void
    */
  handleMessageDelivery(response, callback) {
    const { message, delivery_type_id } = response;
    this.setMessageId(this.setDeliveryType(message, delivery_type_id));
  }

  /**
    @name: setMessageId
    @description: sets the id to newly sent message based on existing guid
    @params:
      message: Message object
    @returns: void
    */
  setMessageId(message) {
    const { channel_id, id, guid } = message;
    this.setState(prevState => ({
      [channel_id]: prevState[channel_id] ?
        prevState[channel_id].map(
          m => m.guid === guid ? Object.assign({}, m, this.parseMsg(message)) : m
        ) : [Object.assign({}, this.parseMsg(message))]
    }), () => this.currentUserChannels.add(channel_id))
  }

  /**
    @name: addMsgNotification
    @description:
      if channel is focused sets its delivery type to seen
      else fetches for new message information
    @params:
      channelId: int
      channelTitle: string
      message: Message object
    @returns: void
    */
  addMsgNotification(channelId, channelTitle, message) {
    const { focused, firstRender } = this.state;
    const { body } = message;
    if (focused.has(channelId) /*&& !firstRender*/) {
      const msg = this.setDeliveryType(message, T.seen);
      this.addMessageToChannel(channelId, msg);
      this.deliveryMessage(msg);
      return;
    } else {
      // I think this is redundant
      // if (this.websocket && this.websocket.socket && this.websocket.socket.readyState === WebSocket.OPEN) {
      //   this.websocket.checkConnection();
      // } else {
      //   this.wsQ.push(() => this.websocket.checkConnection());
      // }
    }
  }

  /**
    @name: addMessageToChannel
    @description: appends message to channel by its id
    @params:
      channelId: int
      message: Message object
    @returns: void
    */
  addMessageToChannel(channelId, message) {
    this.setState(prevState => ({
      [channelId]: [this.parseMsg(message)].concat(
        prevState[channelId] ?
          prevState[channelId].filter(m => m.id !== message.id)
        : []
      )
    }), () => this.currentUserChannels.add(channelId))
  }

  /**
    @name: checkConnection
    @description: pings server to get new messages if any
    @params: void
    @returns: void
    */
  checkConnection() {
    if (this.websocket && this.websocket.socket && this.websocket.socket.readyState === WebSocket.OPEN) {
      this.websocket.checkConnection();
    } else {
      this.wsQ.push(() => this.websocket.checkConnection());
    }
  }

  handleCheckConnectionPreprocess(response, callback) {
    const { messages, notifications } = response;
    this.handleCheckConnection(messages, callback);
    this.handleNotifications(notifications, callback);
  }

  /**
    @name: handleCheckConnection
    @description: sets the message notifications by the response from check connection request
    @params:
      response: object
      callback: function
    @returns: void
    */
  handleCheckConnection(response, callback) {
    // const { messagesPerConversation } = this.state;
    if (response === null) {
      this.checkDeliveries();
      return;
    }

    let S = JSON.stringify(response);
    const newHash = H.hash(S);
    if (newHash === this.lastHash) {
      return;
    }
    this.lastHash = newHash;

    const sidebarWindowsUnread = {};
    const newMsgnotifications = [];
    const messagesDelivery = [];
    response.forEach(channel => {
      const { id, message, title, unread } = channel;
      // if (id === this.state.focused) {
      if (this.state.focused.has(id)) {
        // this.addMessageToChannel(id, message);
        if (this.state[id] && this.state[id].length && message.id !== this.state[id][0]) {
          let latestMsg = this.findMessageByCondition(id, null,
            (m) => m.created_by !== this.userId && m.message_type_id !== sifrarnici.msg_types.notice
          );
          if (latestMsg === null) {
            latestMsg = this.state[id][this.state[id].length - 1];
          }
          this.getNewerMessages(id, latestMsg.id);
        }
      } else {
        newMsgnotifications.push({ ...channel });
        if (message.message_delivery_type_id < T.delivered) {
          // this.deliveryMessage(this.setDeliveryType(message, T.delivered));
          messagesDelivery.push(this.setDeliveryType(message.id, T.delivered))
        }
        sidebarWindowsUnread[id] = unread;
      }
      // this.addMsgNotification(id, title, message);
    });
    this.deliveryMessage(messagesDelivery);

    const { sidebarWindows } = this.state;
    for (let i = 0; i < sidebarWindows.length; ++i) {
      const { id } = sidebarWindows[i];
      if (sidebarWindowsUnread[id]) {
        sidebarWindows[i].unread = sidebarWindowsUnread[id];
      }
    }

    this.setState({ msgNotifications: newMsgnotifications, sidebarWindows },
      () => this.checkDeliveries());
  }

  /**
    @name: checkDeliveries
    @description: checks if any of the delivery statuses was changed for messages in all focused channels
    @params: void
    @returns: void
    */
  checkDeliveries() {
    const { focused } = this.state;
    focused.forEach(channelId => {
      const { [channelId]: channel = [] } = this.state;
      // if there is no messages in channel or the last message is already seen
      // there is no need to check whether there is new delivery types
      if (channel === null || channel.length === 0) return;

      // find first message (among mine) whose delivery type is geq than seen
      const msg = this.findMessageByCondition(null, channel, (m) => (
        m.owner &&
        m.message_delivery_type_id >= T.seen
      ));
      // if not found use last message in channel
      const latestMsg = msg !== null ? msg : channel[channel.length - 1];
      const { id: msgId, message_delivery_type_id: delId } = latestMsg;

      if (this.websocket && this.websocket.socket && this.websocket.socket.readyState === WebSocket.OPEN) {
        this.websocket.checkDeliveries(channelId, msgId, delId);
      } else {
        this.wsQ.push(() => this.websocket.checkDeliveries(channelId, msgId, delId));
      }
    });
  }

  /**
    @name: handleCheckDeliveries
    @description: handles merging of the messages once new delivery statuses were recieved
    @params:
      response: object
      callback: function
    @returns: void
    */
  handleCheckDeliveries(response, callback) {
    const { id, messages = [] } = response;
    const { [id]: channel = [] } = this.state;

    if (messages === null) {
      return; // nothing to do
    }

    let didGetNew = false;
    // const newChannelMsgs = [];
    const cl = channel.length, ml = messages.length;
    let ci = 0, mi = 0; // channel iterator and msgs iterator
    while (true) {
      if (ci >= cl || mi >= ml) break; // over

      if (channel[ci].id !== messages[mi].id) {
        // not my message so I don't care about its delivery status
        ++ci;
      } else {
        if (channel[ci].owner &&
            channel[ci].message_delivery_type_id < messages[mi].message_delivery_type_id)
        {
          didGetNew = true;
          channel[ci] = Object.assign(channel[ci], messages[mi]);
        }
        ++ci; ++mi;
      }
    }
    // set new state if there was change in delivery statuses
    if (didGetNew) {
      this.setState({
        [id]: channel
      }, () => this.currentUserChannels.add(id));
    }
  }

  /**
    @name: getNewerMessages
    @description: sends the request to see if this is really the last message recieved
    @params:
      channelId: int
      messageId: int
    @returns: void
    */
  getNewerMessages(channelId, messageId) {
    if (this.websocket && this.websocket.socket && this.websocket.socket.readyState === WebSocket.OPEN) {
      this.websocket.getNewerMessages(channelId, messageId);
    } else {
      this.wsQ.push(() => this.websocket.getNewerMessages(channelId, messageId));
    }
  }

  /**
    @name: handleGetNewerMessages
    @description: merge new messages with existing ones
    @params:
      response: object
      callback: function
    @returns: void
    */
  handleGetNewerMessages(response, callback) {
    const { id, messages = [] } = response;
    const { [id]: channel = [] } = this.state;
    if (messages === null) {
      return; // nothing to do
    }

    let didGetNew = false;
    // const newChannelMsgs = [];
    const cl = channel.length, ml = messages.length
    let ci = 0, mi = 0; // channel iterator and msgs iterator
    while (true) {
      if (ci >= cl || mi >= ml) {
        break;
      }
      if (channel[ci].id > messages[mi].id) {
        ++ci;
      } else {
        const sameId = channel[ci].id === messages[mi].id;
        channel.splice(ci, sameId ? 1 : 0, this.parseMsg(messages[mi]));
        ++mi; ++ci;
        didGetNew = true;
      }
    }
    if (didGetNew) {
      this.deliveryMessage(this.setDeliveryType(messages[0], T.seen))
      this.setState(prevState => ({
        [id]: channel
        // [id]: (messages || []).concat(prevState[id])
      }), () => this.currentUserChannels.add(id));
    }
  }

  /**
    @name: findMessageByCondition
    @description: find a specific message in a channel according to the passed condition
    @params:
      channelId: int | null
      messages: Array of Message objects
      condition: function<Message object> -> Boolean
    @returns: Message object
    */
  findMessageByCondition(channelId = null, messages = [], condition = () => true) {
    if (channelId !== null) {
      const { [channelId]: channel } = this.state;
      if (channel && channel.length) {
        for (let i = 0; i < channel.length; ++i) {
          if (condition(channel[i])) {
            return channel[i];
          }
        }
      }
    } else {
      if (messages.length) {
        for (let i = 0; i < messages.length; ++i) {
          if (condition(messages[i])) {
            return messages[i];
          }
        }
      }
    }
    return null;
  }

  /**
    @name: handleGetChannelData
    @description: handler when websocket recieves data for some channel
    @params:
      response: object
      callback: function
    @returns:
      void
    */
  handleGetChannelData(response, callback) {
    const { id, messages } = response;
    if (messages === null) {
      this.setState(prevState => ({
        EOM: Object.assign({},
          prevState.EOM,
          {
            [id]: true
          })
      }));
    } else {
      this.setState(prevState => ({
        [id]: messages.map(m => this.parseMsg(m))
      }), () => this.currentUserChannels.add(id));
      const msg = this.findMessageByCondition(null, messages, (m) => (m.created_by !== this.userId))
      if (msg !== null) {
        this.deliveryMessage(this.setDeliveryType(msg, T.seen));
      }
    }
  }

  /**
    @name: getMessages
    @description: returns messages from a specified channel
    @params:
      channelId: int
    @returns:
      Array[Message object] if specified channel was already fetched, otherwise fetches the channel data and returns Array[]
    */
  getMessages(channelId) {
    const { [channelId]: channel } = this.state;
    if (channel) {
      if (channel.length) {
        this.getNewerMessages(channelId, channel[0].id);
      }
      return channel;
    } else {
      if (this.websocket && this.websocket.socket && this.websocket.socket.readyState === WebSocket.OPEN) {
        this.websocket.getChannelData(channelId);
      } else {
        this.wsQ.push(() => this.websocket.getChannelData(channelId));
      }
      return [];
    }
  }

  /**
    @name: getOlderMessages
    @description: fetches older messages for specified channel (offset is calculated from current number of messages in that channel and limit is hardcoded to 10)
    @params:
      channelId: int
    @returns:
      void
    */
  getOlderMessages(channelId) {
    const { [channelId]: channel, EOM } = this.state
    if (!EOM[channelId] && channel) {
      if (this.websocket && this.websocket.socket && this.websocket.socket.readyState === WebSocket.OPEN) {
        this.websocket.getOlderMessages(
          channelId,
          channel.length,
          this.limit
        );
      } else {
        this.wsQ.push(() => this.websocket.getOlderMessages(
          channelId,
          channel.length,
          this.limit
        ));
      }
    } else if (channel) {
      this.getMessages(channelId);
    }
  }

  /**
    @name: handleGetOldMessages
    @description: handler when websocket recieves older message for some channel
    @params:
      response: data object
      callback: function
    @returns:
      void
    */
  handleGetOlderMessages(response, callback) {
    const { id, messages } = response;
    if (messages === null) {
      this.setState(prevState => ({
        EOM: Object.assign({},
          prevState.EOM,
          {
            [id]: true
          })
      }));
    } else {
      this.setState(prevState => ({
        [id]: prevState[id].concat(messages.map(m => this.parseMsg(m)))
      }), () => this.currentUserChannels.add(id));
    }
  }

  handleError() {

  }

  // fill the sidebar with avatars
  addConversations(conversations) {
    this.setState({
      sidebarWindows: Array.isArray(conversations) ?
        conversations.map(c => Object.assign({sidebar: true, color: H.getRandomColor()}, c))
        : []
      });
  }

  // remove from sidebar and open chat window for this conversation
  addNewChat(channel, inSidebar = false) {
    const { id:channelId } = channel;
    this.setInSidebar(channel, inSidebar);
    this.setInSidebarLS(channel, inSidebar);

    if (inSidebar) {
      return;
    }
    if (this.state.chatWindows.find(c => c.channelId === channelId) === undefined) {
      this.setState(prevState => {
        const newChat = (
          <ChatWindow
            key={prevState.chatWindows.length + "" + channelId}
            channelId={channelId}
            onClose={this.closeChat}
            onMinimize={this.minimizeChat}
          />
        )
        return {
          chatWindows: [
            ...prevState.chatWindows,
            {
              channelId: channelId,
              element: newChat
            }
          ]
        }
      });
    }
  }

  // remove chat window from its container
  removeChat(id) {
    this.setState(prevState => ({ chatWindows: prevState.chatWindows.filter(chat => chat.channelId !== id) }));
  }

  // remove conversation from Komunikator
  // you can only access it on Komunikator page!
  // TODO: change user context when chat is closed?
  closeChat(channel) {
    lsAPI.removeValueEnc('comm', channel.id);
    const { id } = channel;
    this.setState(
      prevState => ({
        ...{ sidebarWindows: prevState.sidebarWindows.filter(chat => chat.channelId !== id) },
        ...(prevState.chatWindowsFocused.delete(id) ? { chatWindowsFocused: prevState.chatWindowsFocused } : {}),
        focused: prevState.mainWindowFocused.has(id) ? prevState.focused : (prevState.focused.delete(id) ? prevState.focused : prevState.focused)
      }),
      () => this.removeChat(id)
    );
  }

  // set if current conversation is open in chat window or visible in sidebar
  setInSidebar(chat, inSidebar) {
    const { id:convoId } = chat;
    let tmp;
    this.setState(
      prevState => ({
        sidebarWindows:
          (tmp = prevState.sidebarWindows.find(s => s.id === convoId)) ?
          prevState.sidebarWindows.map(s => s.id === convoId ? Object.assign(s, {sidebar: inSidebar}) : s)
          :
          prevState.sidebarWindows.concat({ sidebar: inSidebar, ...chat })
      })
    );
  }

  // set to localStorge if conversation is open in window or is present in sidebar
  setInSidebarLS(channel, inSidebar) {
    lsAPI.setValueEnc('comm', channel.id, {...channel, sidebar: inSidebar});
  }

  // move conversation from chat window to sidebar avatar
  minimizeChat(channel) {
    const { id } = channel;
    this.setInSidebar(channel, true);
    this.setInSidebarLS(channel, true);
    this.removeChat(id);
    this.removeFocused(id);
  }

  //get new notifications
  refreshNotifications() {
    return notificatorService.getNew()
    .then(resp => {
      if (resp.success) {
        const newNotifications= resp.data || [];
        this.setState({
          notifications: newNotifications
        })
      }
    });
  }

  //set notification as mark-read, both in DB and in context
  markReadNotification(id) {
    return notificatorService.markRead([id])
    .then(resp => {
      if (resp.success) {
        this.removeNotifications([id]);
      }
      return resp;
    });
  }

  markReadNotifications(ids) {
    return notificatorService.markRead(ids)
    .then(resp => {
      if (resp.success) {
        this.removeNotifications(ids);
      }
      return resp;
    });
  }

  markDeleteNotifications(ids) {
    return notificatorService.markDelete(ids)
    .then(resp => {
      if (resp.sucess) {
        this.removeNotifications(ids);
      }
      return resp;
    })
  }

  removeNotifications(ids) {
    this.setState((prevState) => {
      return {
        ...prevState,
        notifications : prevState.notifications.filter(x => ids.indexOf((x.id || x.notif_id)) === -1)
      }
    })
  }


  // get redirect link for notification
  getNotificationRedirectLink(link_type, link_id) {
    if (!link_id) {
      console.warn('LINK_ID missing ', link_id);
      return null;
    }
    else if (!link_type) {
      console.warn("LINK_TYPE missing ", link_type);
      return null;
    }

    const id = link_id.toString();

    switch (link_type) {
      case "upit":
        if (Role.isAdmin(this.roleId)) {
          return "/admin/upiti/" + id;
        } else {
          return "/a/upiti/" + id;
        }
      case "direktan-upit":
        return "/p/direktni-upiti/" + id;
      case "posebna_ponuda":
        return "/p/posebne-ponude/" + id;
      case "rezervacija":
        return "/p/rezervacije/" + id;
      case "ponuda":
        return "/p/ponude/" + id;
      case "posao":
      case "posao_interni":
      case "panic_posao":
        // return "/p/voznje/" + id;
        if (Role.isAdmin(this.roleId)) {
          return "/admin/voznje/" + id;
        } else if (Role.isPrijevoznik(this.roleId)) {
          return "/p/voznje/" + id;
        } else if (Role.isAgencija(this.roleId)) {
          return "/a/upiti/" + id;
        }
        return null;
      default:
        return null;
    }
  }

  //get Notification icon
  getNotificationIcon(link_type) {
    if (!link_type) {
      console.warn("LINK_TYPE missing ", link_type);
      return <LaunchIcon />;
    }

    switch (link_type) {
      case "upit":
        return <UpitIcon />
      case "direktan-upit":
        return <DirektanUpitIcon />;
      case "posebna_ponuda":
        return null;
      case "posao":
      case "posao_interni":
        return <VoznjaIcon />;
      case "rezervacija":
        return null;
      case "ponuda":
        return null;
      default:
        return <LaunchIcon />;
    }
  }

  handleNotifications(data, callback) {
    if (data && Array.isArray(data) && data.length) {
      const { notifications } = this.state;
      const existingNotifs = new Set();
      for (let i = 0; notifications && i < notifications.length; ++i) {
        existingNotifs.add(notifications[i].id);
      }
      const newNotifs = data.filter(n => !existingNotifs.has(n.id));
      if (newNotifs.length > 0) {
        this.setState(prevState => ({
          notifications: newNotifs.concat(prevState.notifications)
        }));
      }
    }
  }

  handleNotification(data, callback) {
    const notif = data.message;
    if (notif) {
      this.setState((prevState) => ({
        notifications: [notif].concat(prevState.notifications)
      }));
    }
  }

  render() {
    // console.log(this.state.mainWindowFocused, this.state.chatWindowsFocused, this.state.focused);
    return (
      <KomunikatorContext.Provider value={this.state}>
        {this.props.children}
      </KomunikatorContext.Provider>

    );
  }
}

export default KomunikatorProvider;
