import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Custom Components
import withRedirect from "Components/withRedirect";
import DialogContext from "UI/DialogContext/DialogContext";
import ModelTable from "UI/Table/ModelTable";
import TableCard from "UI/Table/TableCard";
import TableCardContent from "UI/Table/TableCardContent";
import TableButtonEdit from "UI/Table/ActionButtons/TableButtonEdit";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import UserContext from "Components/UserContext/UserContext";

import model from "Models/rezervacije";
import dataController from "Lib/dataController";
import RezervacijaDialog from "Views/Prijevoznici/Rezervacije/RezervacijaDialog";

function RezervacijeTable(props) {
  const dialogContext = useContext(DialogContext);
  const loaderContext = useContext(LoaderContext);
  const userContext = useContext(UserContext);
  const { t } = useTranslation();

  const [records, setRecords] = useState([]);

  const {redirect} = props;

  const dc = new dataController(model);

  useEffect(() => {
    if (userContext.subjectId) {
      refreshRecords();
    }
  }, [userContext.subjectId]);

  const handleAdd = (evt) => {
    dialogContext.showDialog(RezervacijaDialog, {
      dc: dc,
      mode: "insert",
      form: "insert",
      onClose: handleDialogClose,
      subModelDefaultValues: {
        prijevoznik_id: userContext.subjectId
      }
    });
  };

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords();
    }
  };

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData()
      .then((resp) => {
        if (resp.success) {
          setRecords(resp.data);
        }
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const handleRowAction = function(action, id) {
    if (action === "edit") {
      redirect(`/p/rezervacije/${id}`);
    }
  }

  const allowRowAction = function(action, id) {
    return true;
  }

  return (
    <TableCard>
      <TableCardContent>
        <ModelTable
          title={t("titles.rezervacije")}
          records={records}
          dc={dc}
          allowSelection="none"
          allowFilter={true}
          allowExport={true}
          allowAdd={true}
          addLabel={t("buttons.new_m")}
          handleAdd={handleAdd}
          subModelDefaultValues={{ prijevoznik_id: userContext.subjectId }}
          onRowAction={handleRowAction}
          allowRowAction={allowRowAction}
          onDoubleClick={(id) => handleRowAction("edit", id)}
          handleRefresh={refreshRecords}
        />
      </TableCardContent>
    </TableCard>
  );
}

export default withRedirect(RezervacijeTable);
