import React, {Fragment} from "react";
import PropTypes from "prop-types";

// MUI Icon
import ErrorIcon from "@material-ui/icons/Error";

//Material-UI Core Components
import Typography from "@material-ui/core/Typography"

function CardSOSHeading(props) {
  const { isPanic } = props;
  return isPanic ? [
        <ErrorIcon key={0} color="error"/>,
        <Typography variant="h4" key={1} color="error">
          S.O.S.
        </Typography>
  ] : null
}

export default CardSOSHeading;
