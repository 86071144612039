export const Permissions = {
  BasicAcces: {
    id: "basicAccess",
    code: 1000,
    display: "permissions.basicAccess",
    shortDisplay: "BAC"
  },
  PrijevozniciSve: {
    id: "prijevozniciSve",
    code: 3000,
    display: "permissions.prijevoznici_sve",
    shortDisplay: "PR-ALL"
  },
  PrijevozniciOsnovno: {
    id: "prijevozniciOsnovno",
    code: 3100,
    display: "permissions.prijevoznici_osnovno",
    shortDisplay: "PR-BASIC"
  },
  AgencijaSve: {
    id: "agencijaSve",
    code: 4000,
    display: "permissions.agencijaSve",
    shortDisplay: "AG-SVE"
  },
  AgencijaOsnovno: {
    id: "agencijaOsnovno",
    code: 4100,
    display: "permissions.agencijaOsnovno",
    shortDisplay: "AG-OSN"
  },
  CommunicatorAll: {
    id: "communicatorAll",
    code: 5000,
    display: "permissions.communicatorAll",
    shortDisplay: "COMM-ALL"
  },
  CommunicatorBasic: {
    id: "communicatorBasic",
    code: 5100,
    display: "permissions.communicatorBasic",
    shortDisplay: "COMM-BAS"
  },

  AdminModule: {
    id: "admin",
    code: 9000,
    display: "perm.admin",
    shortDisplay: "ADM"
  },
  AdminProfilesRead: {
    id: "admin-profiles-read",
    code: 9100,
    display: "perm.admin-profiles-read",
    shortDisplay: "ADM-PR"
  },
  AdminProfilesManage: {
    id: "admin-profiles-manage",
    code: 9200,
    display: "perm.admin-profiles-manage",
    shortDisplay: "ADM-PM"
  },
  AdminBillingPausal: {
    id: "perm.admin-billing-pausal",
    code: 9300,
    display: "perm.admin-billing-pausal",
    shortDisplay: "ADM-BP"
  },
  AdminMediationOwn: {
    id: "admin-mediation-own",
    code: 9400,
    display: "perm.admin-mediation-own",
    shortDisplay: "ADM-MO"
  },
  AdminMediationGlobal: {
    id: "admin-mediation-global",
    code: 9500,
    display: "perm.admin-mediation-global",
    shortDisplay: "ADM-MG"
  },
  AdminExternalCosts: {
    id: "admin-externalcosts-manage",
    code: 9600,
    display: "perm.admin-externalcosts-manage",
    shortDisplay: "ADM-EC"
  },

  DEFAULT: { id: "default", code: -1, display: "DEFAULT_VALUE" }
};
