import React, { Fragment, useEffect, useRef, useState } from "react";
import moment from 'moment';
import { useTranslation } from "react-i18next";

// Material-UI Icons
import SendIcon from '@material-ui/icons/Send';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

// Material-UI Components
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

// Custom Components
import ChatDate from "UI/Chat/ChatDate";
import ChatMessage from "UI/Chat/ChatMessage";
import ChatMessagePanic from "UI/Chat/ChatMessagePanic";
import ChatMessageSystem from "UI/Chat/ChatMessageSystem";
import { sifrarnici } from "Lib/sifrarnici";

function ChatContent (props) {
	const { t } = useTranslation();
	const { channelId, height, messages, tab, activeConversation, getOlderMessages, isReadOnly, channelUsers } = props;
	const hRef = useRef(null);
	const [prevOffset, setPrevOffset] = useState(0);
	let observer = null;

	const contextStyle = primaryStyles();
	const tabStyle = secondaryStyles(height);

	const handleObserver = (entities, observer) => {
		const y = entities[0].boundingClientRect.y;
		if (y > prevOffset) {
			getOlderMessages();
		}
		setPrevOffset(y);
	}

	useEffect(() => {
		if (hRef && hRef.current && !observer) {
			observer = new IntersectionObserver(
				handleObserver,
				{
					root: null,
		      rootMargin: "0px",
		      threshold: 1.0
				})
			observer.observe(hRef.current);
		}
	}, [hRef && hRef.current, channelId]);

	let prevDate = null;
	let someMsgSeen = false;
	let someMsgDelivered = false;
	return (
		<div className={tab ? tabStyle.chatContentContainer : contextStyle.chatContentContainer}>
			{
				messages ?
					messages.map((m, i) => {
						if (m.message_type_id === sifrarnici.msg_types.notice) {
							return <ChatMessageSystem key={i} message={m} />
						}
						if (m.message_type_id === sifrarnici.msg_types.panic) {
							return <ChatMessagePanic key={i} message={m} users={channelUsers}/>
						}
						if (m.message_delivery_type_id === sifrarnici.msg_delivery_types.delivered) {
							someMsgDelivered = true;
						}
						if (m.message_delivery_type_id === sifrarnici.msg_delivery_types.seen) {
							someMsgSeen = true;
						}
						const currDate = moment(m.created_on);
						if (prevDate === null) {
							prevDate = currDate;
						}
						if (currDate.isBefore(prevDate, 'day')) {
							const tmp = prevDate;
							prevDate = currDate;
							return (
								<Fragment key={i}>
									<ChatDate date={tmp}/>
									<ChatMessage 
										message={Object.assign(
											{}, m, 
											{ 
												message_delivery_type_id: 
													(someMsgSeen && m.message_delivery_type_id < sifrarnici.msg_delivery_types.seen) ? 
														sifrarnici.msg_delivery_types.seen 
													: (someMsgDelivered && m.message_delivery_type_id < sifrarnici.msg_delivery_types.delivered) ?
														sifrarnici.msg_delivery_types.delivered
													: m.message_delivery_type_id 
											})
										} 
										channelId={channelId}
										tab={true}
										isReadOnly={isReadOnly}
									/>
								</Fragment>
							)
						}
						return (
							<ChatMessage 
								key={i} 
								message={Object.assign(
									{}, m, 
									{ 
										message_delivery_type_id: 
											(someMsgSeen && m.message_delivery_type_id < sifrarnici.msg_delivery_types.seen) ? 
												sifrarnici.msg_delivery_types.seen 
												: m.message_delivery_type_id 
									})
								} 
								tab={true}
								channelId={channelId}
								isReadOnly={isReadOnly}
							/>
						);
					})
				: <LinearProgress color="secondary" />
			}
			{
				messages ?
					<div ref={hRef} ></div>
				: null
			}
		</div>
	);
}

const primaryStyles = makeStyles((theme) => ({
	chatContentContainer:{
		height: "400px",
		display: "flex",
		flexDirection: "column-reverse",
		overflow: "auto"
	}
}))

const secondaryStyles = height => makeStyles((theme) => ({
	chatContentContainer:{
		// height: `calc(100% - 48px - ${height}px)`,
		height: "100%",
		display: "flex",
		flexDirection: "column-reverse",
		overflow: "auto",
		padding: "4px"
	}
}))();

export default ChatContent;
