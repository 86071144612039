import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Draggable from "react-draggable";

//Material-UI Core Components
import withTheme from "@material-ui/core/styles/withTheme";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";

//Material-UI Icons
import ErrorIcon from "@material-ui/icons/Error";

//Custom Components
import SchedulerGridHeader from "Components/Scheduler/SchedulerGridHeader";
import SchedulerGridRow from "Components/Scheduler/SchedulerGridRow";
import SchedulerGridBar from "Components/Scheduler/SchedulerGridBar";
import { schedulerHelpers } from "Lib/schedulerHelpers";
import schedulerDataController from "Components/Scheduler/schedulerDataController";

import dataController from "Lib/dataController";
import dataControllerSubModel from "Lib/dataControllerSubModel";
import picker from "Models/submodels/picker";
import modelPosao from "Models/poslovi";
import modelPojedinacnaVoznja from "Models/pojedinacneVoznje";
import modelRezervacije from "Models/rezervacije";

const SDC = schedulerDataController;
let drag_animation = null;
const rowHeight = 50;

// I guess this was an attempt to change the dragging functionality and then it was forgotten or just discarded as viable
function DragComponent(props) {
  return (
    <Draggable handle={"#scheduler"}>
      <Paper {...props} />
    </Draggable>
  );
}

// @Piero
// changet this to function?
// if you manage to move the dragging functionality to a special component then yes, it will be a breeze
class SchedulerGrid extends React.Component {
  constructor(props) {
    super(props);

    this.drag = this.drag.bind(this);
    this.drag_start = this.drag_start.bind(this);
    this.drag_end = this.drag_end.bind(this);

    this.refreshSize = this.refreshSize.bind(this);

    this.drag_offset = 0;
    this.mouse_down = false;
    this.last_x = 0;
    this.dayClasses = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

    this.fVozilo = {
      title: "Vozilo",
      source: "vozilo_id",
      ttoken: "pojedinacneVoznje.vozilo",
      type: "picker",
      subModel: {
        apiPath: "prijevoznici/{prijevoznik_id}/vozila?need_for=picker",
        format: "{label}, {registracija}"
      }
    },

    this.fVozac = {
      title: "Vozac 2",
      source: "vozac_id",
      ttoken: "pojedinacneVoznje.vozac2",
      type: "picker",
      subModel: {
        apiPath: "prijevoznici/{prijevoznik_id}/vozaci?need_for=picker",
        format: "{label}"
      }
    }

    this.defaultDCValues = {
      "prijevoznik_id": this.props.userContextSubjectId
    }

    this.dcVozilo = new dataControllerSubModel(picker, this.fVozilo, this.defaultDCValues);
    this.dcVozac = new dataControllerSubModel(picker, this.fVozac, this.defaultDCValues);
    this.dcPosao = new dataController(modelPosao);
    this.dcPojedinacnaVoznja = new dataController(modelPojedinacnaVoznja);
    this.dcRezervacija = new dataController(modelRezervacije);

    this.state = {
      gridWidth: 0,
      grid_height: 0,
      cellWidth: 15,
      dayOffset: 0,
    };
  }

/************************************************************************
    DRAGGING-START
************************************************************************/

  componentDidMount() {
    window.addEventListener("resize", this.refreshSize);
    //this.attach_events();

    this.refreshSize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.refreshSize);
    // this.deattach_events();
  }

  // @Piero
  // is it possible to wrap the whole scheduler grid component in a draggable component which will handle all of the dragging and pulling?
  // if it is then all of this functionality can be transferred over yonder
  drag(evt) {
    if (this.mouse_down) {
      this.drag_offset += evt.clientX - this.last_x;
    }
    this.last_x = evt.clientX;
  }

  drag_start(evt) {
    const { cellWidth } = this.props;

    console.log("drag start!");
    if (drag_animation != null) {
      clearTimeout(drag_animation);
    }
    this.last_x = evt.clientX;
    this.drag_offset = 0;
    this.mouse_down = true;
    drag_animation = setInterval(() => {
      let n_cells = 0;
      if (this.drag_offset > 0) {
        n_cells = Math.ceil(this.drag_offset / (cellWidth * 2));
      } else {
        n_cells = Math.floor(this.drag_offset / cellWidth);
      }
      this.drag_offset = 0;
      if (n_cells != 0) this.shift_cells(n_cells);
    }, 150);
  }

  drag_end() {
    const { cellWidth } = this.state;

    console.log("drag_end");

    this.mouse_down = false;
    this.forceUpdate();
    if (drag_animation) {
      clearInterval(drag_animation);
      drag_animation = null;
    }
    let n_cells = Math.round(this.drag_offset / cellWidth);
    this.drag_offset = 0;
    if (n_cells) this.shift_cells(n_cells);
    // console.log( 'drag end!!!', n_cells );
  }

  shift_cells(n_cells) {
    const { cellWidth, dayOffset } = this.state;
    const { startDate, resolutionHours } = this.props;

    let res = Math.floor(24.0 / resolutionHours);
    let len = cellWidth * res;
    let days = Math.ceil(n_cells / res);
    let mask = 0;
    if (n_cells > 0) {
      let offset = n_cells - Math.floor(n_cells / res) * res;
      mask = -len * (offset / res);
    } else if (n_cells < 0) {
      let offset = Math.abs(n_cells) + days * res;
      mask = -len + len * (1 - offset / res);
      mask += dayOffset;
      if (Math.abs(mask) > len) {
        days = -1;
        mask = 0;
      }
    }
    if (days) {
      this.change_startdate(startDate.clone().add(-days, "days"));
    }
    // set offset
    this.setState({ dayOffset: mask });

    // console.log( n_cells, days, mask, n_cells > 0 );
  }

  range_move(evt) {
    const { cellWidth } = this.state;
    const { resolutionHours, onCellEnter } = this.props;

    let cell_offset = Math.floor((evt.clientX - 200) / cellWidth);
    let divisions = Math.floor(24.0 / resolutionHours);
    let days = Math.floor(cell_offset / divisions);
    let div = cell_offset - days * divisions;

    if (onCellEnter) {
      onCellEnter(evt, days, div);
    }
  }

  attach_events() {
    try {
      // this.refs.dateelements.addEventListener( 'wheel', this.scroll.bind( this ) );
      const headerElements = Array.from(this.refs.dateelements.getElementsByClassName("scheduler-header-day"));
      headerElements.forEach((itm) => {
        // TODO: drag evt for android is missing (https://www.kirupa.com/html5/drag.htm)
        itm.addEventListener("mousemove", this.drag);
        itm.addEventListener("mousedown", this.drag_start);
        itm.addEventListener("mouseup", this.drag_end);
      });
      this.refs.dateelements.addEventListener("mouseleave", this.drag_end);
    } catch (e) {
      console.error("Unable to attach scroll event to scheduler control!", e);
    }

    try {
      // this.refs.dateelements.addEventListener( 'wheel', this.scroll.bind( this ) );
      const cellElements = Array.from(this.refs.dragElements.getElementsByClassName("scheduler-cell"));
      cellElements.forEach((itm) => {
        // TODO: drag evt for android is missing (https://www.kirupa.com/html5/drag.htm)
        itm.addEventListener("mousemove", this.drag);
        itm.addEventListener("mousedown", this.drag_start);
        itm.addEventListener("mouseup", this.drag_end);
      });
      this.refs.dragElements.addEventListener("mouseleave", this.drag_end);
    } catch (e) {
      console.error("Unable to attach scroll event to scheduler control!", e);
    }
  }

  deattach_events() {
    try {
      // this.refs.dateelements.removeEventListener( 'wheel', this.scroll.bind( this ) );
      Array.from(this.refs.dateelements.getElementsByClassName("scheduler-header-day")).forEach((itm) => {
        // TODO: drag evt for android is missing
        itm.removeEventListener("mousemove", this.drag);
        itm.removeEventListener("mousedown", this.drag_start);
        itm.removeEventListener("mouseup", this.drag_end);
      });
      this.refs.dateelements.removeEventListener("mouseleave", this.drag_end);
    } catch (e) {
      console.error("Error deattaching scroll event from scheduler control!", e);
    }
  }

  handleDragStart(evt, obj) {}

  handleDrag(evt, obj) {}

  handleDragStop(evt, obj) {
    console.log(obj.deltaX, obj, evt);
  }

/************************************************************************
    DRAGGING-END
************************************************************************/

  // @Piero
  // TODO:
  // this is aesthetics, but still needs to be done
  updateCellWidth(res) {
    const { gridWidth, cellWidth } = this.state;
    const { resolutionHours, startDate } = this.props;

    let min_width = 0;
    if (res == 24) {
      min_width = 17;
    }

    let cw = Math.max(15, min_width); // assumed default

    // how many is missing for fill!?
    let n_cells = schedulerHelpers.schedulerDays(gridWidth, cellWidth, resolutionHours, startDate) * Math.floor(24.0 / resolutionHours);
    let delta = gridWidth - n_cells * cw;

    if (delta) {
      let v = Math.max(0, Math.min(Math.ceil(delta / n_cells), 5)); // again assumption of 15 px cell width so 1/3 increas will be ok
      cw += v;
    }
    this.setState({ cellWidth: cw });
    if (this.props.onUpdateCellWidth) {
      this.props.onUpdateCellWidth(cw);
    }

    let days = Math.floor(gridWidth / (cellWidth * Math.floor(24.0 / res)));
    //this.data_provider.UpdateDateRange(startDate, days);
  }

  // @Piero
  // TODO:
  // this is aesthetics, but still needs to be done
  refreshSize() {
    const { gridWidth, grid_height } = this.state;
    const { resolutionHours } = this.props;

    // if (this.refs.gridcontainer) {
    //   let bbox = this.refs.gridcontainer.getBoundingClientRect();
    //   if (bbox.width != gridWidth) {
    //     this.setState({ gridWidth: bbox.width });

    //     this.updateCellWidth(resolutionHours);
    //     //TODO: check why timeout
    //     setTimeout(() => {
    //       this.updateCellWidth(resolutionHours);
    //     }, 0);
    //     // setTimeout(this.attach_events.bind(this), 0);
    //   }
    // }
    if (this.refs.scheduler) {
      let bbox = this.refs.scheduler.getBoundingClientRect();
      if (bbox.width != gridWidth) {
        this.setState({ gridWidth: bbox.width });

        this.updateCellWidth(resolutionHours);
        //TODO: check why timeout
        setTimeout(() => {
          this.updateCellWidth(resolutionHours);
        }, 0);
        // setTimeout(this.attach_events.bind(this), 0);
      }
      // let height = window.screen.height - bbox.top - this.row_height;
      let height = window.innerHeight - bbox.top - rowHeight - 50;
      if (height != grid_height) {
        this.setState({ grid_height: height });
      }
    }
  }

  render() {
    const { grid_height, gridWidth, cellWidth, dayOffset } = this.state;
    const { theme } = this.props;
    const { startDate, resolutionHours, records, conflicts, userDetails } = this.props;
    const { onMouseMove, onCellEnter, onCellLeave, onItemClick, onToggleGrouped } = this.props;

    // number of cells per day
    const divisions = Math.floor(24.0 / resolutionHours);

    let parentGrouped = false
    const rows = records.map((x) => {
      // logic for grouped sub voznje
      if (x.maintype === 1) {
        parentGrouped = x.grouped
      }
      if (x.maintype === 3 && parentGrouped) {
        return null;
      }
      return x;
    }).filter(x => x !== null);

    // fill empty spaces to the bottom of the screen with empty records
    const nRows = Math.floor(grid_height / rowHeight) + 1;
    for (let i = rows.length; i < nRows; i++) { rows.push({ id: -1, label: " ", icon: null }); }

    // create data for scheduler header
    const nDays = schedulerHelpers.schedulerDays(gridWidth, cellWidth, resolutionHours, startDate);
    const dayCells = new Array(nDays).fill(null).map((_, i) => {
      let date = startDate.clone().add(i, "days");
      let conflict = false;
      let tooltipNum = 0;
      // there is definitely a bug in here
      Object.keys(conflicts).forEach((key, j) => {
          // console.log(key, conflicts[key].conflictIntervals.length)
        conflicts[key].conflictIntervals.forEach((item, k) => {
          // const item = conflicts[key];
          if (!item.inConflict) {
            return;
          }

          if (SDC.checkIntervalOverlap(item, { from: date.clone().add(1, 'minutes'), to: date.clone().add(23 * 50 + 59, 'minutes') })) {
          // if (date.format("MM.DD") >= item.from.format("MM.DD") && date.format("MM.DD") <= item.to.format("MM.DD")) {
            conflict = true;
            ++tooltipNum;
          }
        })
      });

      return {
        day: i,
        dayLabel: date.format(schedulerHelpers.getDateFormat(resolutionHours)),
        name: schedulerHelpers.getDateName(date, resolutionHours),
        width: divisions * cellWidth,
        dayClass: "scheduler-header-day " + this.dayClasses[date.day()],
        cells: new Array(divisions).fill(0).map((_, j) => j),
        conflict: conflict,
        tooltip: tooltipNum
      };
    });

    return (
      <div id="scheduler" ref="scheduler">
        <SchedulerGridHeader
          dayOffset={dayOffset}
          dayCells={dayCells}
          rowHeight={rowHeight}
          resolutionHours={resolutionHours}
        />
        <div
          ref="dragElements"
          className="scheduler-scroll handle"
          style={{ maxHeight: `${grid_height}px`, overflowY: "auto" }}
        >
          {rows.map((r, i) => (
            r.dontShow ?  // please dont ask
            null :
            <Fragment key={i}>
              <div className="scheduler-row scheduler-data" style={{ height: rowHeight }} key={'main-'+i}>
                <div className="scheduler-row-gridwrapper" style={{ height: rowHeight }}>
                  <SchedulerGridRow
                    dayOffset={dayOffset}
                    dayCells={dayCells}
                    cellWidth={cellWidth}
                    onCellEnter={onCellEnter}
                    onCellLeave={onCellLeave}
                    resolutionHours = {resolutionHours}
                  />
                  {r.id > -1 ? (
                    <SchedulerGridBar
                      r={r}
                      dayOffset={dayOffset}
                      gridWidth={gridWidth}
                      onClick={onItemClick}
                      onMouseMove={this.range_move.bind(this)}
                      onCellLeave={onCellLeave}
                      userDetails={userDetails}
                      conflicts={conflicts}
                      handleToggleGrouped={() => onToggleGrouped(r.id)}

                      dcVozilo={this.dcVozilo}
                      dcVozac={this.dcVozac}
                      dcPosao={this.dcPosao}
                      dcPojedinacnaVoznja={this.dcPojedinacnaVoznja}
                      dcRezervacija={this.dcRezervacija}
                      onNeedRefresh={this.props.onNeedRefresh}
                    />
                  ) : null}
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    );
  }
}

SchedulerGrid.propTypes = {
  resolutionHours: PropTypes.number,
  onMouseMove: PropTypes.func,
  onCellEnter: PropTypes.func,
  onCellLeave: PropTypes.func,
  onItemClick: PropTypes.func,
  onToggleGrouped: PropTypes.func,
  records: PropTypes.array,
  conflicts: PropTypes.object
};

export default withTheme(SchedulerGrid);
