import React, {Fragment} from "react";
import PropTypes from "prop-types";

//Material-UI Core Components
import { makeStyles } from "@material-ui/core/styles";
import FormHeaderStatus from "UI/Form/FormHeaderStatus";
import DialogTitle from "@material-ui/core/DialogTitle";
import withTheme from "@material-ui/core/styles/withTheme";

const toolbarStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  }
}))

function CardHeading(props) {

  const {children, recordStatus, statuses, header, color, subheader} = props;

  const classes = toolbarStyles();

  return (
      <Fragment>
        <DialogTitle classes={classes} style={{backgroundColor: color}}>
          {header ? header : children}
        </DialogTitle>
        <FormHeaderStatus statuses={statuses} recordStatus={recordStatus}/>
      </Fragment>
  );
}

CardHeading.propTypes = {
  recordStatus: PropTypes.number,
  statuses: PropTypes.array
}

export default withTheme(CardHeading);
