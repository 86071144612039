import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

function CardToolbarButtonBack(props) {
  return (
      <IconButton className="nodrag" color="inherit" {...props}>
        <ArrowBackIcon/>
      </IconButton>
  );
}

export default CardToolbarButtonBack;
