import React, { Fragment, useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

//Material UI
import Toolbar from "@material-ui/core/Toolbar";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";

//Material-UI Icons
import PublicIcon from "@material-ui/icons/Public";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import StarsIcon from "@material-ui/icons/Stars";

//Custom Icons
import DirektanUpitIcon from "Icons/DirektanUpitIcon";

//Custom Components
import withFormController from "Components/withFormController";
import withRedirect from "Components/withRedirect";
import {
  CardColored,
  CardActionButton,
  CardActionButtonClose,
  CardHeading,
  CardToolbarButtonBack,
  CardToolbarMenuButton,
  CardToolbarMenuItemMarkUnread
} from "UI/Card";
import { FormAvatar, FormFillContent, FormHeading, FormTab, FormTabs } from "UI/Form";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import UserContext from "Components/UserContext/UserContext";
import DialogContext from "UI/DialogContext/DialogContext";
import TabPanelUpitJavno from "Views/Upiti/Tabs/TabPanelUpitJavno";
import TabPanelPosebnaPonuda from "Views/Upiti/Tabs/TabPanelPosebnaPonuda";
import PonudaDialog from "Views/Ponude/PonudaDialog";
import dataController from "Lib/dataController";
import modelPonuda from "Models/ponuda";
import confirmDialog from "Lib/confirmDialog";
import { upitService } from "Services/upitService";
import CardSOSHeading from "UI/Card/CardSOSHeading";
import useMarkRead from "Components/useMarkRead";
import EuroConversionComment from "Components/EuroConversionComment";

function DirektanUpitForm(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();

  const userContext = useContext(UserContext);
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);
  const dialogContext = useContext(DialogContext);

  const [currentTab, setCurrentTab] = useState(0);

  const { handleMarkUnread } = useMarkRead({dc: props.dc, recordId: props.record.id});

  const { dc, record, recordId, validation, fields, subModels, dataChanged, onFieldChange } = props; //HOC withFormController
  const { doUpdate, doDelete, doRefresh, doClose, onClose } = props; //HOC withFormController

  const { redirect } = props; //HOC withRedirect

  const ponudaDC = new dataController(modelPonuda);

  const mode = "view";

  useEffect(() => {
    if (record) {
      const markNew = record.mark_new;
      if (markNew === true) {
        markRead();
      }
    }

  }, [record])

  const markRead = () => {
    dc.MarkReadRecord(recordId)
    .then((resp) => {
      //do nothing
    })
    .catch((result) => {
      //also do nothing
    })
  }

  const handleClickBack = () => {
    history.goBack();
  };

  const handleTabChange = (evt, value) => {
    setCurrentTab(value);
  };

  const handleNewPonuda = (evt) => {
    dialogContext.showDialog(PonudaDialog, {
      dc: ponudaDC,
      dcUpitiPath: "direktni-upiti",
      isDirektan: true,
      mode: "insert",
      form: "insert",
      upitID: record["id"],
      upitRecord: record,
      subModelDefaultValues: {
        prijevoznik_id: userContext.subjectId
      },
      onClose: handleClosePonuda,
      defaultValues: {
        from_ts: record.from_ts,
        to_ts: record.to_ts,
        cbc_provizija_perc: userContext.subjectCBCProvision || 0,
        vozilo_id: record.vozilo_id,
        datum_ponude: Date.now()
      }
    });
  };

  const handleOpenPonuda = (evt) => {
    redirect("/p/ponude/" + record.ponuda_id);
  };

  const handleClosePonuda = (result) => {
    if (result && result.dataChanged) {
      if (doRefresh) {
        doRefresh();
      }
    }
    dialogContext.hideDialog();
  };

  const confirmInquiryRejection = () => {
    const title = t("cdialogs.changes");
    const text = t("cdialogs.want_to_reject");
    const type = "warning";
    const confirmButtonText = t("cdialogs.confirm");
    const cancelButtonText = t("cdialogs.cancel");

    return confirmDialog(title, text, type, confirmButtonText, cancelButtonText);
  };

  const handleRejectInquiry = () => {
    confirmInquiryRejection().then((resp) => {
      if (resp.confirmed) {
        loaderContext.toggleLoading(true);
        upitService
          .rejectUpit(recordId)
          .then((resp) => {
            if (resp.success) {
              snackbarContext.showNotification("notifications.rejected_inquiry", "success");
              history.goBack();
            }
          })
          .catch((result) => {
            snackbarContext.showNotification(result.error, "error");
          })
          .finally(() => {
            loaderContext.toggleLoading(false);
          });
      }
    });
  };

  const commonTabProps = {
    currentTab: currentTab,
    recordId: recordId,
    subModels: subModels,
    record: record,
    fields: fields,
    mode: mode,
    onFieldChange: onFieldChange
  };

  const isStorno = record.storno === true;
  const isAgentura = record.agentura === true;
  const { isPanic } = props;

  const title = record.naziv;
  const subtitle = `(ID:${recordId})`;

  const recordActive = record && record.active ? true : false;

  const basedOnPosebnaPonuda = record && record.posebna_ponuda_id ? true : false;

  const hasPonuda = record.ponuda_id ? true : false;

  const customColor = isStorno ? theme.palette.custom.storno :
    isPanic ? theme.palette.custom.panic :
    isAgentura ? theme.palette.custom.agentura :
    basedOnPosebnaPonuda ? (theme.palette.custom && theme.palette.custom.pp) :
    undefined;

  const customText = isStorno ? t("messages.storno") :
    isPanic ? t("messages.panic") :
    isAgentura ? t("messages.agentura") :
    undefined;

  return (
    <CardColored color={customColor}>
      <CardHeading color={customColor}>
        <Toolbar variant="dense" disableGutters={true}>
          <FormAvatar ariaLabel="poslovni_subjekt" icon={<DirektanUpitIcon />} />
          <FormHeading header={t("titles.direktan_upit")} subheader={subtitle} />
          {/*<CardSOSHeading isPanic={props.isPanic}/>*/}
          <FormFillContent />
          {customText ?
            <Typography variant="button">{customText}</Typography>
            : null
          }
          <FormFillContent />
          <CardToolbarMenuButton>
            <CardToolbarMenuItemMarkUnread onClick={handleMarkUnread} />
          </CardToolbarMenuButton>
          <CardToolbarButtonBack onClick={handleClickBack} />
        </Toolbar>
      </CardHeading>
      <CardContent style={{ paddingTop: 0 }}>
        <FormTabs value={currentTab} onChange={handleTabChange} identifier={dc.getSource()}>
          {basedOnPosebnaPonuda ? (
            <FormTab id="posebna-ponuda" label={t("burza.tabs.pp")} icon={<StarsIcon />} aria-label="posebna ponuda" />
          ) : null}
          <FormTab id="upit" label={t("burza.tabs.upit")} icon={<PublicIcon />} aria-label="javni_podaci" />
        </FormTabs>
        {basedOnPosebnaPonuda ? <TabPanelPosebnaPonuda tabIndex={0} currentTab={currentTab} recordId={record.posebna_ponuda_id} /> : null}
        <TabPanelUpitJavno tabIndex={basedOnPosebnaPonuda ? 1 : 0} {...commonTabProps} showNarucitelj />
        <CardActions>
          <EuroConversionComment />
          <FormFillContent />
          <CardActionButtonClose variant="outlined" onClick={handleClickBack} />
          {
            !props.isPanic ?
              <CardActionButton
                startIcon={<ThumbDownIcon />}
                variant="outlined"
                onClick={handleRejectInquiry}
                disabled={!recordActive || hasPonuda}
              >
                {t("buttons.reject_offer")}
              </CardActionButton>
              : null
          }
          {hasPonuda ? (
            <Fragment>
              {
                !props.isPanic ?
                  <CardActionButton
                    startIcon={<LocalOfferIcon />}
                    variant="contained"
                    onClick={handleOpenPonuda}
                  >
                    {t("buttons.ponuda_open")}
                  </CardActionButton>
                  : null
              }
            </Fragment>
          ) : (
            <Fragment>
              {
                !props.isPanic ?
                  <CardActionButton
                    startIcon={<LocalOfferIcon />}
                    variant="contained"
                    onClick={handleNewPonuda}
                    disabled={!recordActive}
                  >
                    {t("buttons.ponuda_create")}
                  </CardActionButton>
                  : null
              }
            </Fragment>
          )}
        </CardActions>
      </CardContent>
    </CardColored>
  );
}

export default withFormController(withRedirect(DirektanUpitForm));
