import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

//Custom components
import GridContainer from "UI/Grid/GridContainer";
import FormContent from "Components/FormContent";
import GridItem from "UI/Grid/GridItem";
import ModelTable from "UI/Table/ModelTable";
import DialogContext from "UI/DialogContext/DialogContext";

import AdminPojedinacnaVoznjaDialog from "Views/Administration/AdminVoznje/Dialogs/AdminPojedinacnaVoznjaDialog";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import dataController from "Lib/dataController";
import model from "Models/pojedinacneVoznje";

function AdminVoznjaTabPojedinacneVoznje(props) {
  const { t } = useTranslation();
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);
  const dialogContext = useContext(DialogContext);

  const [records, setRecords] = useState([]);

  const { recordId, record, validation, fields, mode, subModels, currentTab, tabValue } = props;

  const dc = new dataController(model, `admin/voznje/${recordId}/pojedinacne-voznje`);

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData()
      .then((resp) => {
        if (resp && resp.success) {
          setRecords(resp.data ? resp.data : []);
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  useEffect(() => {
    if (currentTab === tabValue) {
      refreshRecords();
    }
  }, [currentTab]);

  const commonProps = {
    record: record,
    validation: validation,
    fields: fields,
    mode: mode,
    subModels: subModels
  };


  const handleView = (id) => {
    openPojedinacnaVoznjaDialog(id);
  };

  const openPojedinacnaVoznjaDialog = (id) => {
    dc.GetDataSingle(id)
      .then((resp) => {
        if (resp && resp.success) {
          dialogContext.showDialog(AdminPojedinacnaVoznjaDialog, {
            dc: dc,
            recordId: id,
            mode: "view",
            form: "admin"
          });
        }
      }).catch((result) => {
      snackbarContext.showNotification(result.error, "error");
    });
  };

  const handleRowAction = function (action, id) {
    if (action === "edit" || action === "view") {
      openPojedinacnaVoznjaDialog(id);
    }
  };

  return (
    <GridContainer>
      <GridItem md={12} sm={12}>
        <ModelTable
          records={records}
          dc={dc}
          viewName={"admin"}
          allowExport={false}
          allowFilter={true}
          allowAdd={false}
          allowSelection="none"
          onRowAction={handleRowAction}
          onDoubleClick={(id) => handleView(id)}
        >
          {/* <TableButtonView onClick={handleView} /> */}
        </ModelTable>
      </GridItem>
      <FormContent fieldNames={["km_calc_voznje", "min_calc_voznje"]} {...commonProps} columns={2} />
      <FormContent fieldNames={["km_manual", "min_manual"]} {...commonProps} columns={2} />
    </GridContainer>
  );
}

AdminVoznjaTabPojedinacneVoznje.propTypes = {
  recordId: PropTypes.number,
  record: PropTypes.object,
  validation: PropTypes.object,
  onFieldChange: PropTypes.func,
  fields: PropTypes.array,
  mode: PropTypes.oneOf(["view", "update", "insert"])
};

export default AdminVoznjaTabPojedinacneVoznje;
