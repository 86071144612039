import React, { useRef } from "react";

//Material-UI Core Components
import { useTheme } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";

function SchedulerGridHeader(props) {

  const dateelementsRef = useRef();

  const { dayOffset, dayCells, rowHeight, resolutionHours } = props;

  const theme = useTheme();

  return (
    <div className="scheduler-row scheduler-header" style={{ height: rowHeight }}>
      <div className="scheduler-grid" ref={dateelementsRef} style={{ transform: "translateX(" + dayOffset + "px)" }}>
        {dayCells.map((d, di) => (
          <div className={d.dayClass} style={{ width: d.width + "px" }} key={di}>
            <div align="center" className="scheduler-header-wrapper" 
              style={{ 
                color: theme.palette.type === 'dark' ? "#fff" : "#000", 
                backgroundColor: theme.palette.type === 'dark' ? (
                  d.dayClass.indexOf("saturday") >= 0 || d.dayClass.indexOf("sunday") >= 0 ? theme.palette.primary.main : theme.palette.primary.light
                ) : (
                  d.dayClass.indexOf("saturday") >= 0 || d.dayClass.indexOf("sunday") >= 0 ? theme.palette.primary.light : "#eee"
                )
              }}
            >
              <div className="scheduler-date">{d.dayLabel}</div>
              <div style={{ display: "block", marginRight: "auto", marginLeft: "auto" }}>
                <Badge
                  color="error"
                  badgeContent={d.tooltip}
                  anchorOrigin={{
                    horizontal: "right",
                    vertical: "top"
                  }}
                  component={"div"}
                  showZero={false}
                  variant={resolutionHours >= 8 ? "dot" : "standard"}
                >
                  <div className="scheduler-date-name" style={{ color: d.conflict ? theme.palette.error.dark : null }}>
                    {d.name}
                  </div>
                </Badge>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SchedulerGridHeader;
