import React from 'react'
import PropTypes from 'prop-types';

//Material-UI Core Components
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const customSwitchStyles = makeStyles(theme => ({
  root: {
    marginLeft: 10
  }})
);

function SchedulerDrawerSwitch(props) {
  const classes = customSwitchStyles();

  return (
    <div>
      <FormControlLabel
        control={
          <Switch
            checked={props.checked}
            onChange={props.onChange}
            value={props.id}
            color="secondary"
            classes={classes}
          />
        }
        label={props.label}
      />
    </div>
  );
}

SchedulerDrawerSwitch.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string
}

export default SchedulerDrawerSwitch;