import React, { useState } from 'react';

// Material-UI Icons
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';

// Material-UI Core Components
import {
	Avatar
, CircularProgress
, IconButton
,	List
, ListItem
, ListItemAvatar
, ListItemText
, ListItemSecondaryAction
} from "@material-ui/core";

// Custom Components
import { komunikatorService as KS } from "Services/komunikatorService"; 

function KomunikatorAddMembers(props) {
	const { members, channelMembers, channelId } = props;
	const [loading, setLoading] = useState([]);
	const [added, setAdded] = useState({});

	const addMember = (id) => {
		setLoading(loading.concat(id));
		KS.addMember(id, channelId)
		.then(() => 
			setAdded(Object.assign({ [id]: true }, added))
		)
		.finally(() => 
			setLoading(loading.filter(l => l !== id))
		);
	}

	return (
		members.length === 0 ?
		<CircularProgress />
		:
		<List dense={true}>
      {
      	members.map((m, i) => {
      		const isInChannel = channelMembers.find(cm => cm.id === m.value) !== undefined;
      		return (
		        <ListItem key={i}>
		          <ListItemAvatar>
		            <Avatar style={{ backgroundColor: m.color, width: "30px", height: "30px" }}>
		              {
		              	m.img ?
		              		m.img
		              	: m.label[0]
		              }
		            </Avatar>
		          </ListItemAvatar>
		          <ListItemText
		            primary={m.label}
		          />
		          <ListItemSecondaryAction>
		            <IconButton 
		            	edge="end"
		            	onClick={isInChannel ? () => {} : () => addMember(m.value)}
		            >
		            	{
		            		loading.indexOf(m.value) >= 0 ?
			            		<CircularProgress size={20} />
			            	: isInChannel || added[m.value] ?
			            		<CheckIcon fontSize="small"/>
			            	: <AddIcon fontSize="small"/>
		            	}
		            </IconButton>
		          </ListItemSecondaryAction>
		        </ListItem>
      		);
      	})
      }
    </List>
	);
}

export default KomunikatorAddMembers;