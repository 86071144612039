import React, { Fragment, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import _ from "underscore";

// Material UI Core
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";

// Custom Components
import withFormController from "Components/withFormController";
import FormContent from "Components/FormContent";
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import { helpers } from "Lib/helpers";
import { googleService } from "Services/googleService";
import withRedirect from "Components/withRedirect";
import TrazilicaResults from "./TrazilicaResults";

function TrazilicaForm(props) {
  const { t } = useTranslation();

  const { dc, record, validation, refreshCounter, fields, subModels, onFieldChange, doValidate } = props;
  const { onChangeSearchRecord, onSearch, onClearSearch, onSelect, results } = props;

  const fFromLoc = "from_loc";
  const fToLoc = "to_loc";
  const fVia = "stajalista";
  const fAvoidTolls = "izbegni_cestarine";
  const fReturnTrip = "povratna_ruta";
  const fRoute = "ruta";
  const fTrajanjeVoznje = "trajanje_voznje";
  const fNetoUdaljenostVoznje = "neto_udaljenost_voznje";
  const fUdaljenostPovratka = "udaljenost_povratka_vozila";


  const handleFieldChange = (value, source) => {
    if (source === fFromLoc) {
      calcRoute(value, record[fVia], record[fToLoc], record[fAvoidTolls], record[fReturnTrip]);
    } else if (source === fVia) {
      calcRoute(record[fFromLoc], value, record[fToLoc], record[fAvoidTolls], record[fReturnTrip]);
    } else if (source === fToLoc) {
      calcRoute(record[fFromLoc], record[fVia], value, record[fAvoidTolls], record[fReturnTrip]);
    } else if (source === fAvoidTolls) {
      calcRoute(record[fFromLoc], record[fVia], record[fToLoc], value, record[fReturnTrip]);
    } else if (source === fReturnTrip) {
      let newStajalista;
      const currentStajaliste = record && Array.isArray(record[fVia]) ? record[fVia] : [];
      const destinationField = fields.find((x) => x.source === fToLoc);

      let newFrom = record[fFromLoc];
      let newTo = record[fToLoc];

      if (value) {
        // returnTrip = true
        destinationField.readonly = true;
        if (record[fToLoc]) {
          newStajalista = currentStajaliste.concat(handleAddViaControl(record[fToLoc]));
        } else {
          newStajalista = currentStajaliste.concat(handleAddViaControl());
        }
        if (record[fFromLoc]) {
          onFieldChange(record[fFromLoc], fToLoc);
          newTo = record[fFromLoc];
        }
      } else {
        //returnTrip = false
        const _last = currentStajaliste.length - 1;
        destinationField.readonly = false;
        if (record[fToLoc]) {
          onFieldChange(record[fToLoc], fFromLoc);
          newFrom = record[fToLoc];
        }
        if (currentStajaliste.length > 0 && currentStajaliste[_last].value) {
          onFieldChange(currentStajaliste[_last].value, fToLoc);
          newTo = currentStajaliste[_last].value;
        }
        currentStajaliste.pop();
        newStajalista = currentStajaliste;
      }
      onFieldChange(newStajalista, fVia);

      calcRoute(newFrom, newStajalista, newTo, record[fAvoidTolls], value);
    }

    onFieldChange(value, source);
  };


  const calcRoute = (origin, via, destination, avoidTolls, povratna_ruta = False) => {
    const viaPoints = Array.isArray(via) ? via.filter((x) => x.value) : [];

    if (origin && destination) {
      googleService.route(origin, destination, viaPoints, avoidTolls).then((resp) => {
        if (resp.success) {
          const duration = resp.data.duration;
          const length = resp.data.length;
          let returnLength = { value: 0, label: "0 km" };

          if (!povratna_ruta) {
            googleService.route(destination, origin, [], avoidTolls).then((respReturn) => {
              if (respReturn.success) {
                returnLength = respReturn.data.length;

                onFieldChange(resp.data.routes, fRoute);
                onFieldChange(duration, fTrajanjeVoznje);
                onFieldChange(length, fNetoUdaljenostVoznje);

                onFieldChange(returnLength, fUdaljenostPovratka);
              }
            });
          } else {
            onFieldChange(resp.data.routes, fRoute);
            onFieldChange(duration, fTrajanjeVoznje);
            onFieldChange(length, fNetoUdaljenostVoznje);

            onFieldChange(returnLength, fUdaljenostPovratka);
          }
        }
      });
    }
  };


  const handleAddViaControl = (val) => {
    let control;
    if (val) {
      control = {
        id: helpers.uuidGenerator(),
        value: val,
      };
    } else {
      control = {
        id: helpers.uuidGenerator(),
      };
    }
    return control;
  };

  useEffect(() => {
    debounceOnChangeSearchRecord()
  }, [record.from_loc, record.to_loc, record.stajalista, record.ruta]);


  const debounceOnChangeSearchRecord = useCallback(_.debounce(() => {
    if(onChangeSearchRecord) {
      onChangeSearchRecord(record);
    }
  }, 2500), []);

  const handleSearch = () => {
    if (doValidate) {
      const valid = doValidate();
      if (valid) {
        onSearch(record);
      }
    }
  };

  const commonProps = {
    record: record,
    validation: validation,
    fields: fields,
    onFieldChange: handleFieldChange,
    mode: "insert",
    subModels: subModels
  };

  const hasResults = results.length > 0;

  return (
    <Fragment>
      {hasResults ? (
        <TrazilicaResults
          dc={dc}
          record={record}
          results={results}
          onClearSearch={onClearSearch}
          onSelect={onSelect}
          onFieldChange={onFieldChange}
        />
      ) : null}
      {hasResults ? null : (
        <Card>
          <CardHeader
            title={t("trazilica.ruta_putovanja")}
            style={{ textAlign: "center", paddingBottom: 0 }}
          />
          <CardContent>
            <GridContainer direciton="row" justify="center">
              <FormContent
                {...commonProps}
                fieldNames={["from_ts", "to_ts", "from_loc"]}
                columns={2}
              />
              <FormContent
                {...commonProps}
                fieldNames={["stajalista", "povratna_ruta", "to_loc"]}
                columns={1}
              />
              <FormContent
                {...commonProps}
                fieldNames={[
                  "jezik_posade",
                  "djeca_grupa",
                  "izbegni_cestarine",
                  "vozilo_tip_id"
                ]}
                columns={3}
              />
              <FormContent
                {...commonProps}
                fieldNames={[
                  "vozilo_klasa_id",
                  "klasa_motora_id",
                  "vozilo_broj_putnika",
                  "rok_za_ponude"
                ]}
                columns={3}
              />
              <FormContent
                {...commonProps}
                fieldNames={["opis", "vozilo_oprema_ids"]}
                columns={1}
              />
              <FormContent
                {...commonProps}
                fieldNames={[
                  "trajanje_voznje",
                  "neto_udaljenost_voznje",
                  "udaljenost_povratka_vozila",
                ]}
                columns={3}
              />
              <GridItem xs={12}>
                <Button
                  color="secondary"
                  variant="contained"
                  size="medium"
                  startIcon={<SearchIcon />}
                  onClick={(evt) => handleSearch(evt)}
                >
                  {t("buttons.pronadji_vozila")}
                </Button>
              </GridItem>
            </GridContainer>
          </CardContent>
        </Card>
      )}
    </Fragment>
  );
}

export default withRedirect(withFormController(TrazilicaForm));
