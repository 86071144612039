import React from "react";
import { useTranslation } from "react-i18next";

//Materual-UI Icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

//Custom Components
import DialogActionButton from "UI/Dialog/DialogActionButton";

function DialogActionButtonActivate(props) {
  const { t } = useTranslation();

  return (
    <DialogActionButton startIcon={<CheckCircleIcon />} {...props} aria-label="Activate" aria-controls="Activate">
      {t("buttons.activate")}
    </DialogActionButton>
  );
}

export default DialogActionButtonActivate;
