import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI
import Switch from "@material-ui/core/Switch";

//Material-UI Icons
import MoneyIcon from "@material-ui/icons/Money";

//Custom Components
import DialogActionButton from "UI/Dialog/DialogActionButton";

function DialogActionButtonShowFinCalc(props) {
  const { t } = useTranslation();

  const { showTab, onToggle, ...otherProps } = props;

  const isOn = showTab ? true : false;

  return (
    <DialogActionButton
      onClick={onToggle}
      startIcon={<MoneyIcon />}
      endIcon={<Switch checked={isOn} size="small" color="secondary" />}
      aria-label="toggle"
      aria-controls="Toggle"
      variant={"outlined"}
      {...otherProps}
    >
      {isOn ? t("buttons.hide_fin_calc") : t("buttons.show_fin_calc")}
    </DialogActionButton>
  );
}

export default DialogActionButtonShowFinCalc;
