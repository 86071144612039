import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

//Material UI
import { Card } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import CardContent from "@material-ui/core/CardContent";
import PublicIcon from "@material-ui/icons/Public";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import CardActions from "@material-ui/core/CardActions";

//Custom components
import withFormController from "Components/withFormController";
import { CardHeading, CardToolbarMenuButton, CardToolbarButtonBack } from "UI/Card";
import { CardToolbarMenuItemDelete } from "UI/Card";
import { FormHeading, FormTabs, FormTab, FormTabPanel, FormFillContent } from "UI/Form";
import OsnovniKomitentiForm from "Views/Prijevoznici/Komitenti/OsnovniKomitentiForm";
import FinancijskeObvezeForm from "Views/Prijevoznici/Komitenti/FinancijskeObvezeForm";
import model from "Models/financijskeObveze";
import KomitentAvatar from "UI/Form/Avatars/KomitentAvatar";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import dataController from "Lib/dataController";
import { CardActionButtonSave } from "UI/Card";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import UserContext from "Components/UserContext/UserContext";
import EuroConversionComment from "Components/EuroConversionComment";

function KomitentForm(props) {
  const snackbarContext = useContext(SnackbarContext);
  const loaderContext = useContext(LoaderContext);
  const userContext = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();

  const [currentTab, setCurrentTab] = useState(0);
  const [records_f_obveze, setRecordFObveze] = useState([]);

  const { dc, record, mode, validation, fields, subModels, recordId } = props; //HOC withFormController
  const { doUpdate, doDelete, doClose } = props; //HOC withFormController
  const { onFieldChange } = props; //HOC withFormController

  const path = `prijevoznici/${userContext.subjectId}/komitenti/${recordId}/financijske-obveze`;
  const dc_financ = new dataController(model, path);

  const handleClickBack = () => {
    if (doClose) {
      doClose().then((resp) => {
        if (resp.success) {
          history.goBack();
        } else if (resp.shouldsave) {
          if (doUpdate) {
            doUpdate()
              .then((result) => {
                if (result.success) {
                  snackbarContext.showNotification("update", "success");
                  history.goBack();
                }
              })
              .catch((result) => {
                snackbarContext.showNotification(result.error, "error");
              });
          }
        }
      });
    }
  };

  const handleTabChange = (evt, value) => {
    setCurrentTab(value);
    if (value === 1) {
      dc_financ.GetData().then((resp) => {
        if (resp.success) {
          setRecordFObveze(resp.data);
        }
      });
    }
  };

  const handleUpdate = (evt) => {
    if (doUpdate) {
      doUpdate()
        .then((result) => {
          if (result.success) {
            snackbarContext.showNotification("update", "success");
          }
        })
        .catch((result) => {
          snackbarContext.showNotification(result.error, "error");
        });
    }
  };

  const handleDelete = () => {
    if (doDelete) {
      doDelete()
        .then((result) => {
          if (result.success) {
            snackbarContext.showNotification("delete", "success");
            history.goBack();
          }
        })
        .catch((result) => {
          snackbarContext.showNotification(result.error, "error");
        });
    }
  };

  useEffect(() => {
    if (record.id) {
      loaderContext.toggleLoading(false);
    } else {
      loaderContext.toggleLoading(true);
    }
  }, [record]);

  const title = record.naziv;
  const subtitle = `(ID:${recordId})`;

  const platform = record.platform ? true : false;

  const disableSaveButton = (currentTab !== 0 && currentTab !== 1) || platform === true;
  const disableDeleteButton = platform == true;

  return (
    <Card>
      <CardHeading>
        <Toolbar variant="dense" disableGutters={true}>
          <KomitentAvatar />
          <FormHeading header={title} subheader={subtitle} />
          <FormFillContent />
          {!disableDeleteButton ? (
            <CardToolbarMenuButton>
              <CardToolbarMenuItemDelete onClick={handleDelete} />
            </CardToolbarMenuButton>
          ) : null}
          <CardToolbarButtonBack onClick={handleClickBack} />
        </Toolbar>
      </CardHeading>
      <CardContent style={{ paddingTop: 0 }}>
        <FormTabs value={currentTab} onChange={handleTabChange} identifier={dc.getSource()}>
          <FormTab id="osnovni-podaci" value={0} label={t("komitenti.tab_osnovni_podaci")} icon={<PublicIcon />} />
          <FormTab
            id="financijske-obveze"
            value={1}
            label={t("komitenti.tab_financijske_obveze")}
            icon={<MonetizationOnIcon />}
          />
        </FormTabs>
        <FormTabPanel value={currentTab} index={0}>
          <OsnovniKomitentiForm
            mode={platform ? "view" : mode}
            record={record}
            validation={validation}
            fields={fields}
            subModels={subModels}
            onFieldChange={onFieldChange}
          />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={1}>
          <FinancijskeObvezeForm
            dc={dc_financ === undefined ? [] : dc_financ}
            form="default"
            mode="view"
            records={records_f_obveze ? records_f_obveze : []}
          />
        </FormTabPanel>
      </CardContent>
      <CardActions>
        <EuroConversionComment />
        <FormFillContent />
        <CardActionButtonSave onClick={handleUpdate} disabled={disableSaveButton} />
      </CardActions>
    </Card>
  );
}

export default withFormController(KomitentForm);
