import React from 'react'
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';

//Material-UI Core Components
import { makeStyles, withTheme } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';

const customCheckboxStyles = makeStyles(theme => ({
  root: {
    paddingLeft: 15,
    paddingRight: 15,
    // color: theme.palette.common.white,
    '&$checked': {
      // color: theme.palette.common.white,
    }
  },
  checked: {}
}));

class RadioControl extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.getItems = this.getItems.bind(this);
    this.getItem = this.getItem.bind(this);

    this.state = {
      items: []
    }
  }

  componentDidMount(){
    this.getItems();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.getItems();
    }
  }

  handleChange(evt) {
    const { field, onChange } = this.props;
    const value = evt.target.value;
    const item = this.getItem(value);

    const source = field ? field.source : null;

    if (onChange) {
      onChange(item, source);
    }
  };

  getItems() {
    const { field, t, model } = this.props;

    if (field && field.subModel && model){
      const idAttribute = model.fieldId.source;
      return model.GetData()
        .then(resp => {
          this.setState({
            items: resp.data ? resp.data : []
          })
        });
    } else if (field && field.items) {
      let items = [];
      field.items.labels.forEach((label, i) => {
        items.push({label: t(label), value: field.items.values[i]})
      });
      this.setState({
        items: items
      })
    }
  }

  getItem(value) {
    const { items } = this.state;
    return items.find(x => String(x.value) === String(value));
  }

  getDefaultItem() {
    const { field, model } = this.props;
    const { items } = this.state;
    let defaultValue;

    if (field && field.subModel && field.subModel.name && model ){
      defaultValue = model.defaultItem;
    } else if (field && field.items && field.items.hasOwnProperty('default')) {
      defaultValue = field.items.default;
    }

    const item = items.find(x => String(x.value) === String(defaultValue));

    if (item) {
      return item;
    } else {
      return null;
    }
  }

  render() {

    const { field, t, value, validation, formMode, controlMode } = this.props;
    const { items } = this.state;

    const hasValue = value !== undefined && value !== null;
    const hasProperValue = hasValue && value.hasOwnProperty('value') && value.hasOwnProperty('value');

    const defaultItem = this.getDefaultItem();
    const hasDefaultvalue = defaultItem !== null;
    const isRequired = (field && field.validation && field.validation.required) ? true : false;
    const hasError = validation && validation.valid === false;
    const isReadOnly = controlMode === "view" || (field && field.readonly);
    const title = field.ttoken ? t(field.ttoken) : t(field.title);

    return (
      <FormControl error={hasError}>
        <FormLabel color="secondary" required={isRequired}>{title}</FormLabel>
        <RadioGroup
          row={field.row}
          value={hasProperValue ? value.value : hasValue ? value : ( hasDefaultvalue ? defaultItem.value : '')}
          onChange={this.handleChange}
          aria-label={t(this.props.title)}
          >
          {items.map((item, i) =>
            <FormControlLabel
              control={
                <Radio
                  color="secondary"
                  key={i} />
              }
              value={item.value}
              label={t(item.label)}
              key={i}
              disabled={isReadOnly}
            />
          )}
        </RadioGroup>
        <FormHelperText id={field.source + "-helper"}>
            {hasError ? t(validation.msg) : (field.tooltip ? field.tooltip : " ")}
          </FormHelperText>
      </FormControl>
    )
  }
}

RadioControl.propTypes = {
  formMode: PropTypes.oneOf(["form", "table"]),
  controlMode: PropTypes.oneOf(["edit", "view"]),
  field: PropTypes.object.isRequired,
  value: PropTypes.any,
  validation: PropTypes.object,
  onChange: PropTypes.func
}

export default withTranslation()(withTheme(RadioControl));
