import { coreFields } from "./helpers/coreFields";
import { buttonFields } from "./helpers/buttonFields";

export default {
  title: "Vozila",
  exportFileName: "Vozila",
  apiPath: "vozila/",
  forms: {
    default: {
      fields: [
        "business_subject_id",
        "marka_tip",
        "reg_oznaka",
        "br_sjedala",
        "extra_sjedala",
        "tip_id",
        "klasa_id",
        "klasa_motora_id",
        "godina_proizvodnje",
        "vozilo_oprema_ids",
        "dozvoli_djecje_grupe",
        "public",
        "opis_hr",
        "opis_en",
        "lokacija",
        "price_per_distance",
        "price_for_transfer",
        "price_for_half_day",
        "price_for_day",
        "distance_for_transfer",
        "distance_for_half_day",
        "distance_for_day",
        "duration_for_transfer",
        "duration_for_half_day",
        "duration_for_day"
      ],
      validator: "minimumRequiredCjenikFields"
    },
    update: {
      fields: [
        { source: "business_subject_id", readonly: true },
        "marka_tip",
        "reg_oznaka",
        "br_sjedala",
        "extra_sjedala",
        "tip_id",
        "klasa_id",
        "klasa_motora_id",
        "godina_proizvodnje",
        "vozilo_oprema_ids",
        "dozvoli_djecje_grupe",
        "public",
        "opis_hr",
        "opis_en",
        "lokacija",
        "price_per_distance",
        "price_for_transfer",
        "price_for_half_day",
        "price_for_day",
        "distance_for_transfer",
        "distance_for_half_day",
        "distance_for_day",
        "duration_for_transfer",
        "duration_for_half_day",
        "duration_for_day"
      ],
      validator: "minimumRequiredCjenikFields"
    },
    insert_bs: {
      fields: [
        { source: "business_subject_id", readonly: true },
        "marka_tip",
        "reg_oznaka",
        "br_sjedala",
        "extra_sjedala",
        "tip_id",
        "klasa_id",
        "klasa_motora_id",
        "godina_proizvodnje",
        "vozilo_oprema_ids",
        "dozvoli_djecje_grupe",
        "public",
        "opis_hr",
        "opis_en",
        "lokacija",
        "price_per_distance",
        "price_for_transfer",
        "price_for_half_day",
        "price_for_day",
        "distance_for_transfer",
        "distance_for_half_day",
        "distance_for_day",
        "duration_for_transfer",
        "duration_for_half_day",
        "duration_for_day"
      ],
      validator: "minimumRequiredCjenikFields"
    },
    public : {
      fields: [
        "marka_tip",
        "reg_oznaka",
        "br_sjedala",
        "extra_sjedala",
        "tip_id",
        "klasa_id",
        "klasa_motora_id",
        "godina_proizvodnje",
        "vozilo_oprema_ids",
        "dozvoli_djecje_grupe",
        "opis_hr",
        "opis_en",
        "lokacija",
        "price_per_distance",
        {source: "price_for_transfer", icon: "monetization_on"},
        {source: "price_for_half_day", icon: "monetization_on"},
        {source: "price_for_day", icon: "monetization_on"},
        {source: "distance_for_transfer", icon: "room"},
        {source: "distance_for_half_day", icon: "room"},
        {source: "distance_for_day", icon: "room"},
        {source: "duration_for_transfer", icon: "access_time"},
        {source: "duration_for_half_day", icon: "access_time"},
        {source: "duration_for_day", icon: "access_time"}
      ]
    }
  },
  listViews: {
    default: {
      fields: [
        "id",
        "reg_oznaka",
        "br_sjedala",
        "extra_sjedala",
        "marka_tip",
        "tip_id",
        "klasa_id",
        "klasa_motora_id",
        "godina_proizvodnje",
        "active",
        "view"
      ],
      hidden: ["id", "active"]
    },
    admin: {
      fields: [
        "id",
        "business_subject_id",
        "reg_oznaka",
        "br_sjedala",
        "extra_sjedala",
        "marka_tip",
        "tip_id",
        "klasa_id",
        "klasa_motora_id",
        "godina_proizvodnje",
        "vozilo_oprema_ids",
        "active",
        "edit"
      ],
      hidden: ["id", "active"]
    },
    bs: {
      fields: [
        "id",
        "marka_tip",
        "godina_proizvodnje",
        "reg_oznaka",
        "br_sjedala",
        "extra_sjedala",
        "tip_id",
        "klasa_id",
        "klasa_motora_id",
        "vozilo_oprema_ids",
        "dozvoli_djecje_grupe",
        "loc_name",
        "public",
        "active",
        "created_on",
        "created_by",
        "edit"
      ],
      hidden: ["id", "dozvoli_djecje_grupe", "public", "active", "created_on", "created_by"]
    }
  },
  fields: [
    coreFields.id,
    coreFields.active,
    coreFields.created_on,
    coreFields.created_by,
    {
      title: "Business Subject",
      source: "business_subject_id",
      ttoken: "common.business_subject",
      type: "picker",
      subModel: {
        apiPath: "business-subjects/?need_for=picker",
        format: "{label}"
      },
      validation: {
        required: true
      },
      filter: true
    },
    {
      title: "Business Subject Name",
      source: "business_subject",
      ttoken: "common.business_subject",
      type: "text",
      readonly: true,
      filter: true,
      validation: {
        required: true,
        maxLength: 100
      }
    },
    {
      title: "Registracijska Oznaka",
      source: "reg_oznaka",
      type: "text",
      ttoken: "vozila.reg_oznaka",
      validation: {
        required: true,
        maxLength: 15
      },
      width: 20
    },
    {
      title: "Marka Tip",
      source: "marka_tip",
      type: "text",
      ttoken: "vozila.marka_tip",
      validation: {
        required: true,
        maxLength: 100
      }
    },
    {
      title: "Broj Sjedala",
      source: "br_sjedala",
      type: "wholenum",
      icon: "event_seat",
      ttoken: "vozila.br_sjedala",
      validation: {
        required: true,
        maxValue: 2147483647
      }
    },
    {
      title: "Extra sjedala",
      source: "extra_sjedala",
      type: "wholenum",
      icon: "exposure_plus_1",
      ttoken: "vozila.extra_sjedala",
      validation: {
        required: true,
        maxValue: 2147483647
      }
    },
    {
      title: "Tip vozila",
      source: "tip_id",
      ttoken: "vozila.tip",
      icon: "commute",
      type: "picker",
      subModel: {
        apiPath: "sifrarnici/tipovi-vozila",
        format: "{label}"
      },
      validation: {
        required: true
      },
      translate: true
    },
    {
      title: "Klasa vozila",
      source: "klasa_id",
      ttoken: "vozila.klasa",
      icon: "directions_bus",
      type: "picker",
      subModel: {
        apiPath: "sifrarnici/klase-vozila",
        format: "{label}"
      },
      validation: {
        required: true
      },
      translate: true
    },
    {
      title: "Klasa motora",
      source: "klasa_motora_id",
      ttoken: "vozila.klasa_motora",
      icon: "class",
      type: "picker",
      subModel: {
        apiPath: "sifrarnici/klase-motora",
        format: "{label}"
      },
      validation: {
        required: true
      },
      translate: true
    },
    {
      title: "Godina proizvodnje",
      source: "godina_proizvodnje",
      ttoken: "vozila.godina_proizvodnje",
      type: "wholenum",
      icon: "date_range",
      validation: {
        required: true,
        minValue: 1950,
        maxValue: 2050
      }
    },
    {
      title: "Oprema",
      source: "vozilo_oprema_ids",
      ttoken: "vozila.dodatna_oprema",
      type: "checkbox",
      icon: "build",
      row: true,
      subModel: {
        apiPath: "sifrarnici/oprema-vozila",
        format: "{label}"
      },
      translate: true,
      filter: true,
      multi: true,
      customSort: "multiSort"
    },
    {
      title: "Dozvoli djecje grupe",
      source: "dozvoli_djecje_grupe",
      ttoken: "vozila.dozvoli_djecje_grupe",
      icon: "child_care",
      type: "boolean",
      items: {
        labels: ["common.ne", "common.da"],
        values: [false, true],
        default: true
      },
      translate: true
    },
    {
      title: "Public",
      source: "public",
      ttoken: "vozila.public",
      type: "boolean",
      items: {
        labels: ["common.ne", "common.da"],
        values: [false, true],
        default: true
      },
      translate: true
    },
    {
      title: "Opis HR",
      source: "opis_hr",
      ttoken: "vozila.opis_hr",
      type: "multiline",
      rows: 5,
      validation: {
        required: true,
        maxLength: 2000
      }
    },
    {
      title: "Opis EN",
      source: "opis_en",
      ttoken: "vozila.opis_en",
      type: "multiline",
      rows: 5,
      validation: {
        required: true,
        maxLength: 2000
      }
    },
    {
      title: "Price per distance",
      source: "price_per_distance",
      type: "currency",
      ttoken: "cjenici.cijena",
      validation: {
        required: true,
        maxValue: 99999999
      }
    },
    {
      title: "Price for transfer",
      source: "price_for_transfer",
      type: "currency",
      ttoken: "cjenici.cijena",
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Distance for transfer",
      source: "distance_for_transfer",
      suffix: "Km",
      type: "wholenum",
      ttoken: "cjenici.max_udaljenost",
      validation: {
        maxValue: 2147483647
      }
    },
    {
      title: "Duration for transfer",
      source: "duration_for_transfer",
      type: "wholenum",
      suffix: "Hrs",
      ttoken: "cjenici.max_zauzece",
      validation: {
        maxValue: 2147483647
      }
    },

    {
      title: "Price for half day",
      source: "price_for_half_day",
      type: "currency",
      ttoken: "cjenici.cijena",
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Distance for half day",
      source: "distance_for_half_day",
      suffix: "Km",
      type: "wholenum",
      ttoken: "cjenici.max_udaljenost",
      validation: {
        maxValue: 2147483647
      }
    },
    {
      title: "Duration for half day",
      source: "duration_for_half_day",
      suffix: "Hrs",
      type: "wholenum",
      ttoken: "cjenici.max_zauzece",
      validation: {
        maxValue: 2147483647
      }
    },

    {
      title: "Price for day",
      source: "price_for_day",
      type: "currency",
      ttoken: "cjenici.cijena",
      validation: {
        required: true,
        maxValue: 99999999
      },
      readonly: true,
      calculated: true
    },
    {
      title: "Distance for day",
      source: "distance_for_day",
      type: "wholenum",
      suffix: "Km",
      ttoken: "cjenici.max_udaljenost",
      validation: {
        required: true,
        maxValue: 214748347
      }
    },
    {
      title: "Duration for day",
      source: "duration_for_day",
      type: "wholenum",
      suffix: "Hrs",
      ttoken: "cjenici.max_zauzece",
      validation: {
        required: true,
        maxValue: 2147483647
      }
    },
    {
      title: "Location",
      source: "lokacija",
      ttoken: "cjenici.lokacija",
      type: "GooglePlaces",
      originalHeight: true,
      external: true,
      validation: {
        required: true
      }
    },
    {
      title: "Location",
      source: "loc_name",
      ttoken: "cjenici.lokacija",
      type: "text",
      readonly: true
    },
    buttonFields.edit,
    buttonFields.view
  ]
};
