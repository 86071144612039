import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";

// MUI
import {CardMedia,Box,DialogActions} from "@material-ui/core";

// Custom components
import DraggableDialog from "UI/Dialog/DraggableDialog";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";

// Styles
import "react-responsive-carousel/lib/styles/carousel.css";

import { renderArrowNext,renderArrowPrev } from "./CarouselArrows";

const ImageCarouselDialog = (props) => {
  const [loading, setLoading] = useState(true);
  const [carouselIndex, setCarouselIndex] = useState(0);

  const { onClose, photoRecord } = props;

  // const handleLoadPhoto = (ui) => {
  //   const arr_photos = [...photoRecord];
  //   vozilaService
  //     .getFullImage(voziloId, photoRecord[ui].uuid)
  //     .then((resp) => {
  //       if (resp.success) {
  //         arr_photos[ui] = {
  //           uuid: resp.data.uuid,
  //           image: resp.data.image,
  //         };
  //         setPhotoRecord(arr_photos);
  //       } else {
  //         snackBarContext.showNotification(resp.error, "error");
  //       }
  //     })
  //     .catch((result) => {
  //       snackBarContext.showNotification(result.error, "error");
  //     });
  // };

  // const handleLoadInitialPhotos = async (ui = 0) => {
  //   let arr_photos = [...photoRecord];
  //   if (ui < 2 || ui === uuid_list.length - 1) {
  //     loaderContext.toggleLoading(true);
  //     try {
  //       const resp = await vozilaService.getFullImage(voziloId, uuid_list[ui]);
  //       if (resp.success) {
  //         arr_photos.splice(ui, 0, {
  //           uuid: resp.data.uuid,
  //           image: resp.data.image,
  //         });
  //       } else {
  //         snackBarContext.showNotification(resp.error.message, "error");
  //       }
  //     } catch (error) {
  //       snackBarContext.showNotification(error.error, "error");
  //     } finally {
  //       loaderContext.toggleLoading(false);
  //       if (ui === uuid_list.length - 1) {
  //         setPhotoRecord(arr_photos);
  //       } else {
  //         handleLoadInitialPhotos(ui + 1);
  //       }
  //     }
  //   } else {
  //     arr_photos.splice(ui, 0, {
  //       uuid: uuid_list[ui],
  //       image: null,
  //     });
  //     handleLoadInitialPhotos(ui + 1);
  //   }
  // };

  const handleCarouselChange = (index) => {
    const length = photoRecord.length - 1;
    if (length > 3) {
      const backward = index !== 0 ? index - 1 : 4;
      const forward = index !== length ? index + 1 : 0;
      if (photoRecord[index].image === null) {
        handleLoadPhoto(index);
      }
      if (photoRecord[backward].image === null) {
        handleLoadPhoto(backward);
      }
      if (photoRecord[forward].image === null) {
        handleLoadPhoto(forward);
      }
    }
    setCarouselIndex(index);
  };

  // useEffect(() => {
  //   const loadData = async () => {
  //     await handleLoadInitialPhotos();
  //     setLoading(false);
  //   };
  //   loadData();
  // }, []); // Empty dependency array to mimic componentDidMount()

  return (
    <DraggableDialog open={true} maxWidth={"md"} onClose={onClose}>
      <DialogBody>
        <Box m={2}>
          <Carousel
              infiniteLoop
              style={{ height: "100%" }}
              showThumbs={false}
              onChange={handleCarouselChange}
              renderArrowPrev={renderArrowPrev}
              renderArrowNext={renderArrowNext}
            >
              {photoRecord.map((photo, i) => (
                <div key={"div-" + i}>
                  <CardMedia
                    key={"CardMedia-" + i}
                    component="img"
                    src={photo.image ? "data:image/png;base64, " + photo.image : null}
                    style={{ borderRadius: "8px", height: "500px", objectFit: "contain" }}
                  />
                </div>
              ))}
            </Carousel>
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonClose variant="outlined" onClick={onClose} />
      </DialogActions>
    </DraggableDialog>
  );
};

export default ImageCarouselDialog;
