import { coreFields } from "./helpers/coreFields";
import { buttonFields } from "./helpers/buttonFields";


export default {
  title: "Notifications",
  exportFileName: "Notifications",
  source: "notificator",
  apiPath: "notificator/messages",
  forms: {
    default: {
      fields: ["id", "tip", "prioritet", "active"]
    }
  },
  listViews: {
    default: {
      fields: ["id", "created_on", "tip", "link_type", "remark", "prioritet", "active" ],
      hidden: [ "id"]
    }
  },
  fields: [
    coreFields.id,
    coreFields.active,
    coreFields.created_on,
    buttonFields.view,
    {
      title: "Tip",
      source: "tip",
      ttoken: "notificator.tip",
      type: "text",
      readonly: true
    },
    {
      title: "Prioritet",
      source: "prioritet",
      ttoken: "notificator.prioritet",
      type: "text",
      readonly: true
    },
    {
      title: "Link ID",
      source: "link_id",
      type: "wholenumber",
      readonly: true
    },
    {
      title: "Link Type",
      source: "link_type",
      ttoken: "notificator.link_type",
      type: "link",
      link : {
        recordIdField: "id",
        recordTypeField: "link_type",
        recordType: "dynamic",
        dnymaic: true
      },
      readonly: true
    },
    {
      title: "Remark",
      source: "remark",
      ttoken: "notificator.remark",
      type: "text",
      readonly: true
    },
  ]
}