import React from "react";

//Material-UI Core Components
import { withStyles } from "@material-ui/core/styles";

const style = {
  fill: {
    flexGrow: 1,
    paddingLeft: 5,
    paddingRight: 5
  }
};

class FormFillContent extends React.Component {
  render() {
    const { classes, children } = this.props;
    return (
      <div align="center" className={classes.fill}>
        {children}
      </div>
    );
  }
}

export default withStyles(style)(FormFillContent);