import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";

//Material-UI Icons
import LockIcon from "@material-ui/icons/Lock";
import LinkIcon from "@material-ui/icons/Link";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

//Custom Components
import DialogContext from "UI/DialogContext/DialogContext";
import VoziloPublicDialog from "Views/Agencije/Trazilica/VoziloPublicDialog";
import PrijevoznikPublicDialog from "Views/Agencije/Trazilica/PrijevoznikPublicDialog";

function LinkControl(props) {
  const dialogContext = useContext(DialogContext);
  const [isFocused, setIsFocused] = useState(false);

  const { t } = useTranslation();

  const { field, label, value, onChange, helperText, showHelper } = props;
  const { formMode, controlMode, validation, rows, rowsMax } = props;
  const { linkType, linkId } = props;

  const handleChange = (evt) => {
    // There is regex in replace because without global parameter g replace replaces only the first occurence
    const value = field && field.mask ? evt.currentTarget.value.replace(/ /g, "") : evt.currentTarget.value;
    const source = field ? field.source : null;

    if (onChange) {
      onChange(value, source);
    }
  };

  const handleFocus = (evt) => {
    setIsFocused(true);
  };

  const handleBlur = (evt) => {
    setIsFocused(false);
  };

  const getLabel = () => {
    if (field) {
      return field.ttoken ? t(field.ttoken) : field.title;
    } else {
      return label;
    }
  };

  const getValue = (value) => {
    return value ? value : "";
  };

  const getHelperText = () => {
    return field && field.tooltip ? t(field.tooltip) : helperText ? helperText : showHelper ? " " : null;
  };

  const checkIfRequired = () => {
    if (field) {
      return field.validation && field.validation.required;
    } else {
      return false;
    }
  };

  const handleLinkVozilo = () => {
    const id = linkId !== undefined && linkId.hasOwnProperty("value") ? linkId.value : linkId;
    dialogContext.showDialog(VoziloPublicDialog, {
      recordId: id
    });
  };

  const handleLinkPrijevoznik = () => {
    const id = linkid !== undefined && linkId.hasOwnProperty("value") ? linkId.value : linkId;
    dialogContext.showDialog(PrijevoznikPublicDialog, {
      recordId: id
    });
  };

  const isRequired = checkIfRequired();
  const hasValue = value !== undefined && value !== null && value !== "";
  const hasError = validation && validation.valid === false;
  const isReadOnly = controlMode === "view" || (field && field.readonly);
  const isAutoFocused = field && field.focus === true;

  const renderLabel = getLabel();
  const renderHelperText = getHelperText();
  const hasPlaceholder = field && field.placeholder ? true : false;

  const disabled = value && value.length > 0 ? false : true;
  const displayValue = getValue(value);

  const endAdornmentIcons = [];

  switch (linkType) {
    case "mail":
      const hrefUrl = disabled ? null : linkType === "mail" ? "mailto:" + value : linkType === "link" ? value : null;
      endAdornmentIcons.push(
        <a tabIndex={-1} href={hrefUrl} target={"_blank"} style={{ color: !disabled ? "initial" : "darkgray" }}>
          <MailOutlineIcon color="secondary" />
        </a>
      );
      break;
    case "vozilo":
      endAdornmentIcons.push(
        <IconButton aria-label="open link" tabIndex={-1} size="small" disabled={!hasValue} onClick={handleLinkVozilo}>
          <LinkIcon color={hasValue ? "secondary" : "disabled"} />
        </IconButton>
      );
      break;
    case "prijevoznik":
      endAdornmentIcons.push(
        <IconButton
          aria-label="open link"
          tabIndex={-1}
          size="small"
          disabled={!hasValue}
          onClick={handleLinkPrijevoznik}
        >
          <LinkIcon color={hasValue ? "secondary" : "disabled"} />
        </IconButton>
      );
  }

  //TODO: Key error here with IconButton

  if (isReadOnly) {
    endAdornmentIcons.push(<LockIcon style={{ fontSize: 20 }} color="disabled"/>);
  }

  const InputProps = {
    readOnly: isReadOnly,
    endAdornment: endAdornmentIcons
  };

  return (
    <TextField
      margin="none"
      fullWidth
      required={isRequired}
      error={hasError}
      label={formMode === "form" ? renderLabel : null}
      value={displayValue}
      placeholder={hasPlaceholder ? field.placeholder : ""}
      onChange={handleChange}
      onKeyDown={(evt) => {
        if (evt.keyCode === 13) {
          onChange(evt, field ? field.source : null);
        }
      }}
      rows={rows}
      rowsMax={rowsMax}
      InputProps={InputProps}
      InputLabelProps={{ shrink: hasValue || isFocused || isAutoFocused || displayValue !== "" }}
      variant={formMode === "form" ? "outlined" : "standard"}
      helperText={hasError ? validation.msg : renderHelperText}
      autoFocus={isAutoFocused}
      type="text"
      color="secondary"
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  );
}

LinkControl.defaultProps = {
  formMode: "form",
  controlMode: "edit",
  multiline: false,
  rowsMax: 5,
  showHelper: true
};

LinkControl.propTypes = {
  formMode: PropTypes.oneOf(["form", "table"]),
  controlMode: PropTypes.oneOf(["edit", "view"]),
  field: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  helperText: PropTypes.string,
  validation: PropTypes.object,
  onChange: PropTypes.func,
  multiline: PropTypes.bool,
  rowsMax: PropTypes.number,
  showHelper: PropTypes.bool
};

export default LinkControl;
