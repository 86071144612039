import React from 'react';

// Custom Components
import arrayBufferToBase64 from "Lib/arrayBufferToBase64";
import { helpers as H } from "Lib/helpers";


const MAX_FILE_SIZE = 1024 * 1024 * 5 // 5MB

const ChatFileControl = React.forwardRef((props, ref) => {
	const { accept, onChange, onError, multiple = false } = props;

	const addFile = (evt) => {
		if (!evt.target.files.length) {
			throw new Error("No files");
		}
		const file = evt.target.files[0];

		const { name, type, size, lastModified } = file;
		const fileId = H.uuidv4();
		const metaObj = { uuid: fileId, name, type, size };
		if (file.size > MAX_FILE_SIZE) {
			// onError({ errorText: errors.MAX_FILE_SIZE, errorCode: errorCodes.MAX_FILE_SIZE });
			onChange(metaObj, "error_file_size");
			return;
		}
		const acceptTypes = accept.split(',').map(a => a.trim()).filter(a => a.length);
		if (accept !== "*" && acceptTypes.indexOf(type) === -1) {
			onChange(metaObj, "error_file_type");
			return;
		}

		const tmpPromise = new Promise((resolve, reject) => {

		})
		file.arrayBuffer().then(aBuffer => {
			let fileSizePromise = null;
			const fileB64 = arrayBufferToBase64(aBuffer);
			if (accept.startsWith("image") && type.startsWith("image")) {
				fileSizePromise = H.getImageSize(fileB64, "b64", type);
			}
			if (fileSizePromise !== null) {
				fileSizePromise.then(data => {
					const { width, height } = data;
					onChange({
						...metaObj,
						width,
						height,
						content: fileB64
					}, "done");
				})
			} else {
				onChange({
					...metaObj,
					content: fileB64
				}, "done");
			}
		})
		.finally(() => {
			ref.current.value = null;
		});
	}

	return (
		<input 
			ref={ref} 
			type="file" 
			multiple={multiple}
			style={{display: "none"}} 
			accept={accept}
			onChange={addFile}
		/>
	);
})


export default ChatFileControl;