import { geojsonToWKT, wktToGeoJSON } from "@terraformer/wkt"
import proj4 from "proj4";

proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");
proj4.defs(
    "EPSG:3857",
    "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs"
);

export const conversions = {
  lat_lng_toWkt,
  line_toWkt,
  wkt_to_latLng,
  wkt_to_line
}

function lat_lng_toWkt(coords) {
  const _coords = proj4("EPSG:4326", "EPSG:3857",[coords.lng, coords.lat]);
  const geoJsonPoint = {
    "type": "Point",
    "coordinates": _coords
  }
  return geojsonToWKT(geoJsonPoint);
}

function line_toWkt(coords) {
  const _coords = Array.isArray(coords) ? coords.map(x => proj4("EPSG:4326", "EPSG:3857", [x.lng, x.lat])) : [];
  const geoJsonLine = {
    "type": "LineString",
    "coordinates": _coords
  }
  return geojsonToWKT(geoJsonLine);
}

function wkt_to_latLng(wkt){
  const geoJson = wktToGeoJSON(wkt);
  const _coords = proj4("EPSG:3857", "EPSG:4326",[geoJson.coordinates[0], geoJson.coordinates[1]])
  return {lat: _coords[1], lng: _coords[0]};
}

function wkt_to_line(wkt) {
  const geoJson = wktToGeoJSON(wkt);
  return geoJson.coordinates.map(x => proj4("EPSG:3857", "EPSG:4326", [x[0], x[1]])).map(y => ({lat: y[1], lng: y[0]}));
}
