import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Icons
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";

//Custom components
import CardToolbarMenuItem from "UI/Card/CardToolbarMenuItem";

const CardToolbarMenuItemTransferOwnership = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return <CardToolbarMenuItem label={t("buttons.transfer_ownership")} icon={<SwapHorizIcon />} {...props} ref={ref} />;
});

export default CardToolbarMenuItemTransferOwnership;
