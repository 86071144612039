import createReport from "docx-templates";
import moment from "moment";
import numbro from "numbro";
import { formats } from "Lib/formats";

import api from "Lib/api";
import { loadWordTemplate, saveDataToFile } from "Lib/downloadTemplate";

const transparentPixel1x1 = "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="

const handlePrint = (record, t) => {
  const apiInstance = new api();
  const recordId = record.id;
  const path = `poslovi/${recordId}/ponuda`;
  apiInstance.Call(path, "get").then((resp) => {
    const data = resp.data;
    const wordTemplatePath = getWordTemplatePath();
    const fillFunction = getFillFunction(t);

    loadWordTemplate(wordTemplatePath, fillFunction(data));
  });
};

const getWordTemplatePath = () => {
  return "/templates/ponuda_template.docx";
};

const getFillFunction = (t) => {
  return (record) =>
    function (error, content) {
      if (error) {
        console.warn(error);
        throw error;
      }

      fillReport(t, content, record);
    }.bind(this);
};

const fillReport = (t, content, record) => {
  const report = createReport({
    template: content,
    data: {
      datum_ponude: record.datum_ponude ? moment(record.datum_ponude).format(formats.date) : ""
      , broj_ponude: record.broj_ponude
      , nar_naziv: record.nar_naziv
      , nar_adr_full: record.nar_adr_full
      , nar_oib: record.nar_oib
      , pr_naz: record.pr_naziv
      , pr_adr_grad: record.pr_adr_grad
      , pr_adr_full: record.pr_adr_full
      , pr_oib: record.pr_oib
      , from_date: moment(record.from_ts).local().format(formats.date)
      , to_date: moment(record.to_ts).local().format(formats.date)
      , from_loc: record.from_loc_name
      , to_loc: record.to_loc_name
      , ugovorna_relacija: record.relacija_opis_skraceni
      , broj_putnika: record.vozilo_broj_putnika ? record.vozilo_broj_putnika.toString() : ""
      , broj_vodica: record.vozilo_broj_vodica ? "+" + record.vozilo_broj_vodica.toString() : ""
      , vozilo_name: record.vozilo_name
      , iznos: record.iznos ? numbro(record.iznos).format({ thousandSeparated: true, mantissa: 2 }) + " €" : ""
      , vrijedi_do_ts: record.rok_potvrda ? moment(record.rok_potvrda).local().format(formats.datetime) : ""
      , banka: record.banka
      , iban: record.iban
      , poziv_na_broj: record.poziv_na_broj
      , swift: record.swift
      , napomene: record.napomena
    },
    additionalJsContext: {
      memo_header: function() {
        const data = record.memo_image ? record.memo_image.image : transparentPixel1x1;
        return { width: 16, height: 3, data, extension: '.jpg'}
      }
    }
  });
  report.then((data) => {
    let filename = `ponuda_${record.id}.docx`;

    saveDataToFile(data, filename, "application/vnd.openxmlformats-officedocument.wordprocessingml.document");
  });
};

export const ponudaPrint = {
  handlePrint
};
