import React from "react";

//Custom Components
import AgencijePage from "UI/AppPage/AgencijePage";
import ResponsiveBox from "UI/Box/ResponsiveBox";
import UpitiTable from "Views/Agencije/Burza/UpitiTable";

const UpitiPage = React.memo((props) => {
  return (
    <AgencijePage>
      <ResponsiveBox fillHeight>
        <UpitiTable />
      </ResponsiveBox>
    </AgencijePage>
  );
});

export default UpitiPage;
