import React, { Fragment } from 'react';

// Material-UI Icons
import AddCommentIcon from '@material-ui/icons/AddComment';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DraftsIcon from '@material-ui/icons/Drafts';
import FilterListIcon from '@material-ui/icons/FilterList';

// Material-UI Core Components
import { makeStyles } from '@material-ui/core/styles';
import {
	Avatar
, Hidden
, IconButton
,	Toolbar
, Typography
} from "@material-ui/core";

// Custom Components
import KomunikatorSidebarFilter from "UI/Komunikator/KomunikatorSidebarFilter";

function KomunikatorSidebarHeader(props) {
	const classes = useStyles();
	const { 
		  allowAddChannel
		, addChannel 
		, allowMarkAllAsRead
		, markAllAsRead
		,	setSidebarOpen
		, showFilter
		, setShowFilter
		, filterValue
		, setFilterValue
		, title
	} = props;

	return (
		<Toolbar
			variant="dense"
			className={classes.toolbar}
		>
			{
				showFilter ?
				<KomunikatorSidebarFilter setShowFilter={setShowFilter} setFilterValue={setFilterValue} filterValue={filterValue} />
				:
				<Fragment>
					<Hidden mdUp>
						<div className={classes.buttonWrapper}>
							<IconButton onClick={() => setSidebarOpen(false)}>
								<ChevronLeftIcon />
							</IconButton>
						</div>
					</Hidden>
					<div className={classes.title}>
						{ title ?? "Chat" }
					</div>
					<div align="center" className={classes.toolbarFillContent} />
		      <div className={classes.buttonWrapper}>
			      <IconButton
			      	className={classes.button}
			      	onClick={() => setShowFilter(true)}
			      >
			      	<FilterListIcon />
			      </IconButton>
			      <IconButton
			      	disabled={!allowAddChannel}
			      	className={classes.button}
			      	onClick={() => addChannel()}
			      >
			      	<AddCommentIcon />
			      </IconButton>
			      {
			      	allowMarkAllAsRead ?
				      	<IconButton
				      		onClick={markAllAsRead}
				      		className={classes.button}
				      	>
				      		<DraftsIcon />
				      	</IconButton>
			      	: null
			      }
		      </div>
				</Fragment>
			}
		</Toolbar>
	);
}

const useStyles = makeStyles(theme => ({
	toolbar: {
		borderBottom: `1px solid ${theme.palette.secondary.main}`,
		maxHeight: "48px"
	},
  avatar: {
  	alignSelf: "center",
  	margin: theme.spacing(1),
  	marginLeft: theme.spacing(2)
  },
  typographyTitle: {
  	//maxWidth: "500px",
  	marginLeft: theme.spacing(1)
  },
  toolbarFillContent: {
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  buttonWrapper: {
  	marginRight: theme.spacing(1),
  	display: "contents"
  },
  button: {
  	padding: theme.spacing(1)
  },
  title: {
  	width: "100%",
    overflow: "hidden",
    alignSelf: "flex-end",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    justifyContent: "center",
    margin: "auto 0",
  }
}));

export default KomunikatorSidebarHeader;
