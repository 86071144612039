import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//Custom components
import { GridContainer, GridItem } from "UI/Grid";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import { statisticsService } from "Services/statisticsService";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

import UserContext from "Components/UserContext/UserContext";
import BarChart from "UI/Charts/BarChart";
import DonutChart from "UI/Charts/DonutChart";
import InfoChart from "UI/Charts/InfoChart";
import LineChart from "UI/Charts/LineChart";
import GridItemStatisticsWrapper from "Views/HomePage/GridItemStatisticsWrapper";

import withRedirect from "Components/withRedirect";

function PrijevoznikStatisticsView(props) {
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);

  const { redirect } = props;

  const [burza, setBurza] = useState({});
  const [poslovi, setPoslovi] = useState({});
  const [ponude, setPonude] = useState({});
  const [platformskiPoslovi, setPlatformskiPoslovi] = useState({});

  const { t } = useTranslation();

  useEffect(() => {
    return;
    loaderContext.toggleLoading(true);
    statisticsService.getPrijevzonikStatistics().then((resp) => {
      if (resp.success) {
        const _burza = resp.data.burza;
        const _totalPonude = resp.data.p;
        const _platformskiPoslovi = resp.data.plat;
        const _poslovi = resp.data.pos;

        const pieData0 = {total: _burza.total, labels: genPieLabels(_burza, "graph.burza"), series: genPieSeries(_burza)};
        setBurza(pieData0);

        const pieData1 = {total: _poslovi.total, labels: genPieLabels(_poslovi, "graph.poslovi"), series: genPieSeries(_poslovi)};
        setPoslovi(pieData1);

        const pieData2 = {total: _totalPonude.total, labels: genPieLabels(_totalPonude, "graph.ponude"), series: genPieSeries(_totalPonude)};
        setTotalPonude(pieData2);

        const graphData0 = {labels: genPieLabels(_platformskiPoslovi, "trjob.status"), series: genMetaSeries(_platformskiPoslovi)};
        setPlatformskiPoslovi(graphData0);

      }
    })
  }, [])

  useEffect(() => {
    loaderContext.toggleLoading(true);
    statisticsService.getPrijevzonikStatistics().then(resp => {
      if (resp.success && resp.data) {
        const { burza: _burza, p: _ponude, plat: _platformskiPoslovi, pos: _poslovi } = resp.data

        const series = [], labels = [];
        Object.keys(_burza).forEach(k => {
          series.push(_burza[k]);
          labels.push(`statistics.burza.${k}`);
        });
        setBurza({ labels: [...labels], series: [...series] });

        series.length = 0;
        labels.length = 0;
        Object.keys(_ponude).forEach(k => {
          if (k === "total") return;
          series.push(_ponude[k]);
          labels.push(`statistics.ponude.${k}`);
        });
        setPonude({ labels: [...labels], series: [...series] });

        series.length = 0;
        labels.length = 0;
        Object.keys(_poslovi).forEach(k => {
          series.push(_poslovi[k]);
          labels.push(`statistics.poslovi.${k}`);
        });
        setPoslovi({ labels: [...labels], series: [...series] });

        series.length = 0;
        labels.length = 0;
        Object.keys(_platformskiPoslovi).forEach(k => {
          if (k === "total") return;
          series.push(_platformskiPoslovi[k]);
          labels.push(`statistics.platformski_poslovi.${k}`);
        });
        setPlatformskiPoslovi({
          categories: labels,
          series: [{ name: "statistics.t_platformskiPoslovi", data: series }]
        });
      }
    }).catch((result) => {
      // snackbarContext.showNotification(result.error, "error");
      snackbarContext.showNotification(
        result &&
        result.error &&
        result.error.detai &&
        lresult.error.detail.errmsg ||
        "Nepoznata greška", "error");
    }).finally(() => {
      loaderContext.toggleLoading(false);
    })
  }, []);

  const onBurzaDonutClick = (evt, ctx, opts) => {
    const { dataPointIndex } = opts;
    const val = dataPointIndex === 0 ? 0 : 2;
    redirect('/p/burza' , { moj_odgovor: val});
  }

  const onPosloviDonutClick = (evt, ctx, opts) => {
    const { dataPointIndex } = opts;
    const lbl = dataPointIndex === 0 ? t("voznja_tip.posao") : t("voznja_tip.posao_interni");
    redirect("/p/voznje" , { tip_voznje_id: lbl});
  }

  const onPonudeDonutClick = (evt, ctx, opts) => {
    const { dataPointIndex } = opts;
    const val = dataPointIndex === 0 ? 10
      : dataPointIndex === 1 ? 11
      : dataPointIndex === 2 ? 14
      : 19
    redirect("/p/ponude", { ponuda_state_id: val});
  }

  const onJobBarClick = (evt, ctx, opts) => {
    const { dataPointIndex } = opts;
    const val = dataPointIndex === 0 ? 23
      : dataPointIndex === 1 ? 24
      : dataPointIndex === 2 ? 25
      : dataPointIndex === 3 ? 26
      : 29
    redirect("/p/voznje", { active: val === 29 ? t("common.ne") : t("common.da"), tip_voznje_id: t("voznja_tip.posao"), posao_state_id: val})
  }


  return (
      <GridContainer>
        <GridItemStatisticsWrapper sizes={{xs: 12, sm: 12, md: 6, lg: 4}}>
          <DonutChart
            title="statistics.t_burza"
            handleClick={onBurzaDonutClick}
            series={burza.series}
            labels={burza.labels}
            options={{
              plotOptions: { pie: { donut: { labels: { total: {
                fontSize: "22px"
              }}}}},
              dataLabels: {
                formatter: (_, { seriesIndex, w }) => {
                  return w.config.series[seriesIndex];
                }
              }
            }}
          />
        </GridItemStatisticsWrapper>
        <GridItemStatisticsWrapper sizes={{xs: 12, sm: 12, md: 6, lg: 4}}>
          <DonutChart
            title="statistics.t_poslovi"
            handleClick={onPosloviDonutClick}
            series={poslovi.series}
            labels={poslovi.labels}
            options={{
              plotOptions: { pie: { donut: { labels: { total: {
                fontSize: "22px"
              }}}}},
              dataLabels: {
                formatter: (_, { seriesIndex, w }) => {
                  return w.config.series[seriesIndex];
                }
              }
            }}
          />
        </GridItemStatisticsWrapper>
        <GridItemStatisticsWrapper sizes={{xs: 12, sm: 12, md: 6, lg: 4}}>
          <DonutChart
            title="statistics.t_ponude"
            handleClick={onPonudeDonutClick}
            series={ponude.series}
            labels={ponude.labels}
            options={{
              plotOptions: { pie: { donut: { labels: { total: {
                fontSize: "22px"
              }}}}},
              dataLabels: {
                formatter: (_, { seriesIndex, w }) => {
                  return w.config.series[seriesIndex];
                }
              }
            }}
          />
        </GridItemStatisticsWrapper>
        <GridItemStatisticsWrapper sizes={{xs: 12, md: 6, lg: 12, xl: 12}}>
          <BarChart
            asd="asd"
            title="statistics.t_platformskiPoslovi"
            series={platformskiPoslovi.series}
            categories={platformskiPoslovi.categories}
            handleClick={onJobBarClick}
            options={{
              plotOptions: { bar: {
                horizontal: false
              }},
              legend: { onItemClick: {
                toggleDataSeries: false
              }},
              yaxis: { min: 5, tickAmount: 5, showAlways: true }
            }}
          />
        </GridItemStatisticsWrapper>
      </GridContainer>
  );
}

export default withRedirect(PrijevoznikStatisticsView);
