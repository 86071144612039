import { coreFields } from "./helpers/coreFields";
import { buttonFields } from "./helpers/buttonFields";

export default {
  title: "Fakture",
  exportFileName: "Fakture",
  apiPath: "generic/{generic_id}/fakture",
  forms: {
    default: {
      fields: [
        "id",
        "izdavatelj_bs_id",
        "kupac_bs_id",
        "oznaka_fakture",
        "poziv_na_broj",
        "datum_isporuke",
        "ts_izdavanja",
        "calc_neoporezivo",
        "calc_oporezivo",
        "calc_porez",
        "calc_ukupno",
        "faktura_napomena"
      ]
    },
    pp: {
      fields: [
        "id",
        "izdavatelj_bs_id",
        "kupac_bs_id",
        "oznaka_fakture",
        "poziv_na_broj",
        "datum_isporuke",
        "ts_izdavanja",
        "calc_neoporezivo",
        "calc_oporezivo",
        "calc_porez",
        "calc_ukupno",
        "faktura_napomena",
        "stavke"
      ]
    },
    ip: {
      fields: [
        "id",
        "izdavatelj_bs_id",
        "kupac_komitent_id",
        "oznaka_fakture",
        "poziv_na_broj",
        "datum_isporuke",
        "ts_izdavanja",
        "calc_neoporezivo",
        "calc_oporezivo",
        "calc_porez",
        "calc_ukupno",
        "faktura_napomena",
        "stavke"
      ]
    },
    admin_pp: {
      fields: [
        "id",
        "izdavatelj_bs_id",
        {
          source: "kupac_bs_id",
          readonly: false,
          subModel: {
            apiPath: "admin/voznje/{posao_id}/stakeholders",
            format: "{label}"
          }
        },
        "oznaka_fakture",
        "poziv_na_broj",
        "datum_isporuke",
        "ts_izdavanja",
        "calc_neoporezivo",
        "calc_oporezivo",
        "calc_porez",
        "calc_ukupno",
        "faktura_napomena",
        "stavke"
      ]
    },
    pausal: {
      fields: [
        "id",
        "izdavatelj_bs_id",
        "kupac_bs_id",
        "oznaka_fakture",
        "poziv_na_broj",
        "datum_isporuke",
        "ts_izdavanja",
        "calc_neoporezivo",
        "calc_oporezivo",
        "calc_porez",
        "calc_ukupno",
        "faktura_napomena",
        "stavke"
      ]
    }
  },
  listViews: {
    default: {
      fields: [
        "id",
        "izdavatelj_naziv",
        "kupac_naziv",
        "oznaka_fakture",
        "poziv_na_broj",
        "datum_isporuke",
        "ts_izdavanja",
        "calc_neoporezivo",
        "calc_oporezivo",
        "calc_porez",
        "calc_ukupno",
        "faktura_napomena",
        "print",
        "view",
        "edit"
      ],
      hidden: ["id", "calc_neoporezivo", "calc_oporezivo", "calc_porez", "calc_ukupno", "faktura_napomena"]
    },
    admin: {
      fields: [
        "id",
        "izdavatelj_naziv",
        "kupac_naziv",
        "oznaka_fakture",
        "poziv_na_broj",
        "datum_isporuke",
        "ts_izdavanja",
        "calc_neoporezivo",
        "calc_oporezivo",
        "calc_porez",
        "calc_ukupno",
        "faktura_napomena",
        "print",
        "view"
      ],
      hidden: ["id", "calc_neoporezivo", "calc_oporezivo", "calc_porez", "calc_ukupno", "faktura_napomena"]
    },
    pausal: {
      fields: [
        "id",
        "oznaka_fakture",
        "poziv_na_broj",
        "datum_isporuke",
        "ts_izdavanja",
        "calc_neoporezivo",
        "calc_oporezivo",
        "calc_porez",
        "calc_ukupno",
        "faktura_napomena",
        "print",
        "view"
      ],
      hidden: ["id", "calc_neoporezivo", "calc_oporezivo", "calc_porez"]
    }
  },
  fields: [
    coreFields.id,
    buttonFields.view,
    buttonFields.edit,
    buttonFields.print,
    {
      title: "Izdavatelj",
      source: "izdavatelj_bs_id",
      ttoken: "faktura.izdavatelj",
      type: "picker",
      subModel: {
        apiPath: "business-subjects/?need_for=picker",
        format: "{label}"
      },
      readonly: true
    },
    {
      title: "Izdavatelj Naziv",
      source: "izdavatelj_naziv",
      ttoken: "faktura.izdavatelj",
      type: "text",
      readonly: true
    },
    {
      title: "Izdavatelj adresa",
      source: "izdavatelj_adresa_full",
      ttoken: "common.adresa",
      type: "text",
      readonly: true
    },
    {
      title: "Izdavatelj OIB",
      source: "izdavatelj_oib",
      ttoken: "common.oib",
      type: "text",
      readonly: true
    },
    {
      title: "Kupac",
      source: "kupac_bs_id",
      ttoken: "faktura.kupac",
      type: "picker",
      subModel: {
        apiPath: "business-subjects/?need_for=picker",
        format: "{label}"
      },
      readonly: true
    },
    {
      title: "Kupac",
      source: "kupac_komitent_id",
      ttoken: "faktura.kupac",
      type: "picker",
      subModel: {
        apiPath: "prijevoznici/{prijevoznik_id}/komitenti?need_for=picker&select=kupac",
        format: "{label}"
      },
      readonly: true
    },
    {
      title: "Kupac Naziv",
      source: "kupac_naziv",
      ttoken: "faktura.kupac",
      type: "text",
      readonly: true
    },
    {
      title: "Kupac adresa",
      source: "kupac_adresa_full",
      ttoken: "common.adresa",
      type: "text",
      readonly: true
    },
    {
      title: "Kupac OIB",
      source: "kupac_oib",
      ttoken: "common.oib",
      type: "text",
      readonly: true
    },
    {
      title: "Faktura Tag",
      source: "oznaka_fakture",
      ttoken: "common.tag",
      type: "text",
      validation: {
        maxLength: 15,
        required: true
      }
    },
    {
      title: "Faktura Pozivna Broj",
      source: "poziv_na_broj",
      ttoken: "faktura.poziv_na_broj",
      type: "text",
      validation: {
        maxLength: 25,
        required: true
      }
    },
    {
      title: "Datum isporuke",
      source: "datum_isporuke",
      ttoken: "faktura.datum_isporuke",
      type: "date",
      validation: {
        required: true
      }
    },
    {
      title: "Datum i vrijeme izdavanja",
      source: "ts_izdavanja",
      ttoken: "faktura.ts_izdavanja",
      type: "datetime",
      validation: {
        required: true
      }
    },
    {
      title: "Neoporezivo",
      source: "calc_neoporezivo",
      ttoken: "faktura.neoporezivo",
      type: "currency",
      calculated: true,
      readonly: true,
      tooltip: "faktura.tooltip",
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Oporezivo",
      source: "calc_oporezivo",
      ttoken: "faktura.oporezivo",
      type: "currency",
      calculated: true,
      readonly: true,
      tooltip: "faktura.tooltip",
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Porez",
      source: "calc_porez",
      ttoken: "faktura.porez",
      type: "currency",
      calculated: true,
      readonly: true,
      tooltip: "faktura.tooltip",
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Bruto",
      source: "calc_ukupno",
      ttoken: "faktura.ukupno",
      type: "currency",
      calculated: true,
      readonly: true,
      tooltip: "faktura.tooltip",
      validation: {
        required: true,
        maxValue: 99999999
      }
    },
    {
      title: "Banka",
      source: "banka",
      ttoken: "faktura.banka",
      type: "text",
      readonly: true
    },
    {
      title: "IBAN",
      source: "iban",
      ttoken: "common.iban",
      type: "text",
      readonly: true
    },
    {
      title: "SWIFT",
      source: "swift",
      ttoken: "common.swift",
      type: "text",
      readonly: true
    },
    {
      title: "Faktura Napomena",
      source: "faktura_napomena",
      ttoken: "faktura.napomena",
      type: "text",
      width: "full",
      validation: {
        maxLength: 500
      }
    },
    {
      title: "Rok uplate (dani)",
      source: "rok_uplate_dani",
      ttoken: "fatkura.rok_uplate_dani",
      type: "wholenum",
      readonly: true
    },
    {
      title: "Racun izdao",
      source: "racun_izdao",
      ttoken: "faktura.racun_izdao",
      type: "text",
      readonly: true
    },
    {
      title: "Footer Napomene",
      source: "footer_napomene",
      ttoken: "faktura.footer_napomene",
      type: "multiline",
      readonly: true,
      width: "full"
    },
    {
      title: "Stavke",
      source: "stavke",
      ttoken: "faktura.stavke",
      type: "special_faktura_stavke"
    }
  ]
};
