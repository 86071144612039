import React from "react";

//Custom Components
import AdminPage from "UI/AppPage/AdminPage";
import ResponsiveBox from "UI/Box/ResponsiveBox";
import AdminVozilaTable from "Views/Administration/Vozila/AdminVozilaTable";

const AdminVozilaPage = React.memo((props) => {
  return (
    <AdminPage>
      <ResponsiveBox fillHeight>
        <AdminVozilaTable />
      </ResponsiveBox>
    </AdminPage>
  );
});

export default AdminVozilaPage;
