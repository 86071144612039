import React from 'react';
import { useTranslation } from "react-i18next";

//Material-UI Icons
import CancelIcon from '@material-ui/icons/Cancel';

//Custom components
import DialogToolbarMenuItem from "UI/Dialog/DialogToolbarMenuItem";

const DialogToolbarMenuItemInvalidate = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return <DialogToolbarMenuItem label={t("buttons.invalidate")} icon={<CancelIcon />} {...props} ref={ref} />;
});

export default DialogToolbarMenuItemInvalidate;
