import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function KorisnikPrijevoznickiIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M 9.990234,2.3843699e-6 A 4.999999,4.999999 0 0 0 5,5.0000024 4.999999,4.999999 0 0 0 8.882812,9.8730494 c 0.305629,-2.318552 3.004945,-2.961088 4.986329,-2.945313 0.225109,0.0026 0.497155,0.0034 0.742187,0.002 A 4.999999,4.999999 0 0 0 15,5.0000024 4.999999,4.999999 0 0 0 10,2.3843699e-6 a 4.999999,4.999999 0 0 0 -0.0098,0 z M 14.919922,7.9433614 c -2.803895,0 -5.074219,0.316833 -5.074219,2.5371096 v 6.34375 c 0,0.55824 0.247803,1.059301 0.634766,1.408203 v 1.128906 a 0.63436525,0.63436525 0 0 0 0.632812,0.634766 h 0.634766 a 0.63436525,0.63436525 0 0 0 0.634765,-0.634766 v -0.634766 h 5.074219 v 0.634766 a 0.63436525,0.63436525 0 0 0 0.634766,0.634766 h 0.634765 a 0.63436525,0.63436525 0 0 0 0.634766,-0.634766 v -1.128906 c 0.386963,-0.348902 0.632813,-0.849963 0.632813,-1.408203 v -6.34375 c 0,-2.2202766 -2.270326,-2.5371096 -5.074219,-2.5371096 z m -3.806641,2.5371096 h 7.613281 v 3.171875 h -7.613281 z m -2.28125,2.054687 C 3.8584688,12.824453 0,14.935283 0,17.500002 v 2.5 H 9.609375 C 9.558465,19.877061 9.517685,19.749969 9.496094,19.615236 9.619792,18.814407 9.230727,18.225113 8.933594,17.521486 8.790463,15.871877 8.805121,14.202939 8.832031,12.535158 Z m 3.214844,3.019532 a 0.95154788,0.95154788 0 0 1 0.01758,0 0.95154788,0.95154788 0 0 1 0.953125,0.951171 0.95154788,0.95154788 0 0 1 -0.953125,0.953125 0.95154788,0.95154788 0 0 1 -0.951172,-0.953125 0.95154788,0.95154788 0 0 1 0.933594,-0.951171 z m 5.708984,0 a 0.95154788,0.95154788 0 0 1 0.01953,0 0.95154788,0.95154788 0 0 1 0.951171,0.951171 0.95154788,0.95154788 0 0 1 -0.951171,0.953125 0.95154788,0.95154788 0 0 1 -0.953125,-0.953125 0.95154788,0.95154788 0 0 1 0.933593,-0.951171 z" />
    </SvgIcon>
  );
}

export default KorisnikPrijevoznickiIcon;
