import React, { useState } from "react";

//Custom Components
import PickerControl from "Controls/PickerControl";
import { hereService } from "Services/hereService";

const HERELocationPickerControl = React.memo((props) => {

  const [ options, setOptions] = useState([]);
  const [ refreshCounter, setRefreshCounter] = useState(1);
  const [ inputValue, setInputValue] = useState('');

  const { field, onChange, value } = props;


  const handleInputChange = (value) => {
    if (value && value.length > 0) {
      hereService.autosuggest(value, { in: "circle" }).then((response) => {
        if (response.success) {
          const suggestedOptions = response.data;

          setOptions(suggestedOptions);
          setRefreshCounter(refreshCounter + 1);
        }
      });
    } else {
      setOptions([]);
      setRefreshCounter(refreshCounter + 1);
    }
    setInputValue(value);
  };

  const handleChange = (opt, source) => {
    if (opt && opt.value) {
      if (onChange) {
        onChange({
          value: opt.value,
          label: opt.label,
          coords: opt.position
        }, source)
      }
    } else if(!opt) {
      onChange(undefined, source)
    }
  }

  const optionsToFieldItems = (options) => {
    if (Array.isArray(options)) {
      return {
        labels: options.map(o => o.label),
        values: options.map(o => o.value),
        positions: options.map(o => o.position)
      }
    } else {
      return [];
    }
  }

  const myField = Object.assign({}, field, {items: optionsToFieldItems(options)});

  return (
      <PickerControl
          {...props}
          formMode="form"
          field={myField}
          onInputChange={handleInputChange}
          refresh={refreshCounter}
          onChange={handleChange}
          value={value ? value : inputValue}
      />
  );
})

export default HERELocationPickerControl;
