import React, { Fragment, useState, useEffect } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

function withRedirect(WrappedComponent) {

  return function (props) {

    const [redirectTo, setRedirectTo] = useState(null);
    let mountedFlag;

    const redirect = function (path, state = {}, hash = "") {
      const { history } = props;
      const historyPath = path[0] === '/' ? path : `/${path}`;
      history.push({
        pathname: historyPath,
        state: state,
        hash: hash
      });
      if (mountedFlag) {
        setRedirectTo({
          pathname: path,
          state: state,
          hash: hash
        });
      }
    }

    useEffect(() => {
      mountedFlag = true;
      return () => {
        mountedFlag = false;
      }
    })
    return (
      <Fragment>
        {redirectTo !== null ? <Redirect to={redirectTo} /> : null}
        <WrappedComponent
          {...props}
          redirect={redirect}
        />
      </Fragment>
    )
  }
}

export default (Component) => withRouter(withRedirect(Component));