import React from 'react'
import PropTypes from 'prop-types';
import moment from "moment";
import { withTranslation } from 'react-i18next';

//Material-UI Core Components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import FormHelperText from '@material-ui/core/FormHelperText';

//Material-UI Icons
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import LockIcon from "@material-ui/icons/Lock";
import { OutlinedInput } from "@material-ui/core";
import withTheme from "@material-ui/core/styles/withTheme";

//Custom Components
import { formats } from "Lib/formats";
import Datetime from "react-datetime";

// import TimeLeft from 'UI/TimeLeft';
// TODO:
// switch to material-UI DateTime picker (https://material-ui-pickers.dev/demo/datetime-picker)
// additional functionalities:
//    today button - with jump to today
//    there exists range picker but only for week on their page - it is probably possible to extend the functionality to pick the range on calendar


const labelStyles = (theme) => ({
  label: {
    backgroundColor: theme.palette.background.primary,
    marginLeft: "26px"
  },
  labelShrinkFocused: {
    backgroundColor: theme.palette.background.primary,
    color: theme.palette.secondary.main,
    padding: "0 6px"
  },
  labelShrink: {
    backgroundColor: theme.palette.background.primary,
    padding: "0 6px",
    color: theme.palette.type === "light" ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.72)"
  }
})

class DateTimeControl extends React.PureComponent {

  constructor(props) {
    super(props);

    this.handleOpen = this.handleOpen.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectDate = this.handleSelectDate.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleFocusLost = this.handleFocusLost.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.applyDate = this.applyDate.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.getLabel = this.getLabel.bind(this);

    this.inputRef = React.createRef();

    this.state = {
      isFocused: false,
      open: false,
      achorEl: null,
      inputValue: null
    }
  }

  handleSelectDate(value) {
    const { showTime } = this.props;

    this.setState({ inputValue: undefined });

    if (showTime === false) {
      value = value.set('hour', 12);
    }

    this.applyDate(value.local().toISOString());
  }

  handleInputChange(evt) {
    const value = evt.currentTarget.value;
    this.setState({ inputValue: value })
  }

  handleOpen(evt) {
    this.setState({
      open: true,
      anchorEl: evt.currentTarget
    })
  }

  handleClose(evt, reason) {
    this.setState({
      open: false,
      anchorEl: null
    });
  }

  handleFocus(evt) {
    this.setState({ isFocused: true });
  }

  handleFocusLost(evt) {
    const { inputValue } = this.state;
    const { value, showTime } = this.props;

    if (inputValue === "") {
      this.applyDate(null);
    }
    else if (inputValue !== undefined) {
      let mom = moment(inputValue, formats.supported_date_formats);
      if (mom.isValid()) {
        if (showTime === false) {
          mom = mom.set('hour', 12);
        }
        this.applyDate(mom.utc().toISOString())
      }
    }

    this.setState({ isFocused: false });
  }

  handleKeyDown(evt) {
    if (evt.key === 'Enter') {
      this.applyDate(evt.currentTarget.value);
    }
  }

  getFilterFunction() {
    const { field } = this.props;
    let fnc;

    if(field.dateFilter){
      if(field.dateFilter.scope === 'today'){
        const todayDate = moment().subtract( 1, 'day' );
        fnc = function(current){
          return field.dateFilter.clause === 'isBefore' ? current.isAfter(todayDate) : current.isBefore(todayDate);
        }
      }else{
        const checkDate = moment(field.dateFilter.scope).subtract( 1, 'day' );
        fnc = function(current){
          return field.dateFilter.clause === 'isBefore' ? current.isBefore(checkDate) : current.isAfter(checkDate);
        }
      }
    }
    return fnc;
  }

  applyDate(value) {
    const { field, onChange } = this.props;

    let resultValue = null;

    if (value === undefined || value === null) {
      resultValue = null;
    }
    else if (typeof value === 'string') {
      if (value === '') {
        resultValue = null;
      } else {
        const mom = moment.utc(value, formats.supported_date_formats);
        if (mom.isValid()) {
          resultValue = mom.utc().toISOString();
        } else {
          resultValue = null;
        }
      }
    } else {
      resultValue = value.utc().toISOString();
    }

    this.setState({ inputValue: undefined });

    //console.log('apply', resultValue);
    if (onChange) {
      onChange(resultValue, field.source);
    }
  }

  formatDate(value) {
    const { showTime } = this.props;

    const mom = moment.utc(value);
    //if (value && moment.isMoment(value) && value.isValid()) {
    if (mom.isValid()) {
      //const mom = moment.utc(value, formats.supported_date_formats);
      const format = showTime ? formats.datetime : formats.date;
      mom.local();
      const res = mom.format(format);
      return res;
    } else {
      return '';
    }
  }

  getLabel() {
    const { field, label, t } = this.props;

    if (field) {
      return field.ttoken ? t(field.ttoken) : field.title;
    } else {
      return label;
    }
  }

  render() {
    const { open, anchorEl, inputValue, isFocused } = this.state;
    const { formMode, controlMode, field, value, validation, showTime, showTimeLeft, classes, t, theme } = this.props;

    const isRequired = field.validation && field.validation.required;

    const isFiltered = field.dateFilter && field.dateFilter.clause;
    const filterFunction = isFiltered ? this.getFilterFunction() : null;

    const format = showTime ? formats.datetime : formats.date;
    const label = this.getLabel();

    const inputValueExist = inputValue !== null && inputValue !== undefined;
    //const hasValue = inputValueExist || (value && moment.isMoment(value) && value.isValid());
    const hasValue = inputValueExist || (moment(value).isValid());
    const hasError = validation && validation.valid === false;

    const isReadOnly = controlMode === "view" || field.readonly;

    return (
      <FormControl margin="none" fullWidth required={isRequired} error={hasError} variant="outlined">
        {formMode === "form" ? <InputLabel shrink={hasValue || isFocused} required={isRequired} className={[classes.label, isFocused ? classes.labelShrinkFocused : hasValue ? classes.labelShrink : null].join(' ')} style={ { color: isFocused && hasValue ? theme.palette.secondary.main : null, backgroundColor: theme.palette.type === "light" ? theme.palette.background.primary : "#424242" }}>{t(label)}</InputLabel> : null}
        <OutlinedInput
          value={inputValueExist ? inputValue : this.formatDate(value)}
          placeholder={format}
          color="secondary"
          fullWidth
          readOnly={isReadOnly}
          onChange={this.handleInputChange}
          onFocus={this.handleFocus}
          onBlur={this.handleFocusLost}
          //onKeyDown={this.handleKeyDown}
          startAdornment={
            <InputAdornment position="start">
              <IconButton aria-label="open calendar" onClick={this.handleOpen} size="small" disabled={isReadOnly} >
                <CalendarTodayIcon style={{ fontSize: 20 }} color={isReadOnly ? "disabled" : "secondary"} />
              </IconButton>
            </InputAdornment>
          }
          endAdornment={
            <div>
              {/*showTimeLeft ?
                <InputAdornment position="end">
                  <TimeLeft config={config} deadline={value} format="chip" showText={false} />
                </InputAdornment>
                : null
              */}
              {isReadOnly ? <LockIcon style={{ fontSize: 20 }} color="disabled" /> : null}
            </div>
          }
          ref={this.inputRef}
        />
        <FormHelperText>{hasError ? t(validation.msg) : (field.tooltip ? field.tooltip : ' ')}</FormHelperText>
        <Popover
          id={"calendar"}
          open={open}
          onClose={this.handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
        >
          <Datetime
            utc={false}
            isValidDate={filterFunction}
            locale="hr"
            value={moment.utc(value).local()}
            timeFormat={showTime ? 'HH:mm' : false}
            onChange={this.handleSelectDate}
            className={classes.container}
            input={false}
            open={true}
          />
        </Popover>
      </FormControl>
    )
  }
}

DateTimeControl.defaultProps = {
  formMode: "form",
  controlMode: "edit",
  showTime: true,
  showTimeLeft: false
}

DateTimeControl.propTypes = {
  formMode: PropTypes.oneOf(["form", "table"]),
  controlMode: PropTypes.oneOf(["edit", "view"]),
  field: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  validation: PropTypes.object,
  onChange: PropTypes.func,
  showTime: PropTypes.bool,
  showTimeLeft: PropTypes.bool
}

export default withTranslation()(withStyles(labelStyles)(withTheme(DateTimeControl)));
