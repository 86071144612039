import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

//Material UI
import Toolbar from "@material-ui/core/Toolbar";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";

//Material-UI Icons
import PublicIcon from "@material-ui/icons/Public";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";

//Custom Components
import withFormController from "Components/withFormController";
import withRedirect from "Components/withRedirect";
import {
  CardHeading,
  CardToolbarMenuButton,
  CardToolbarButtonBack,
  CardActionButton,
  CardActionButtonClose
} from "UI/Card";
import { FormAvatar, FormHeading, FormHeadingIDAndBS, FormFillContent } from "UI/Form";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import UserContext from "Components/UserContext/UserContext";
import DialogContext from "UI/DialogContext/DialogContext";
import BurzaIcon from "Icons/BurzaIcon";
import PoslovniSubjektAgencijaIcon from "Icons/PoslovniSubjektAgencijaIcon";
import TabPanelUpitJavno from "Views/Upiti/Tabs/TabPanelUpitJavno";
import PonudaDialog from "Views/Ponude/PonudaDialog";
import dataController from "Lib/dataController";
import modelPonuda from "Models/ponuda";
import EuroConversionComment from "Components/EuroConversionComment";

function BurzaUpitForm(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const userContext = useContext(UserContext);
  const snackbarContext = useContext(SnackbarContext);
  const dialogContext = useContext(DialogContext);

  const { dc, record, recordId, validation, fields, subModels, dataChanged } = props; //HOC withFormController
  const { doRefresh } = props; //HOC withFormController

  const { redirect } = props; //HOC withRedirect

  const ponudaDC = new dataController(modelPonuda);

  const handleClickBack = () => {
    history.goBack();
  };

  const handleNewPonuda = (evt) => {
    dialogContext.showDialog(PonudaDialog, {
      dc: ponudaDC,
      dcUpitiPath: "burza/upiti",
      isDirektan: false,
      mode: "insert",
      form: "insert",
      upitID: record["id"],
      upitRecord: record,
      subModelDefaultValues: {
        prijevoznik_id: userContext.subjectId
      },
      onClose: handleClosePonuda,
      defaultValues: {
        from_ts: record.from_ts,
        to_ts: record.to_ts,
        cbc_provizija_perc: userContext.subjectCBCProvision || 0,
        datum_ponude: Date.now()
      }
    });
  };

  const handleOpenPonuda = (evt) => {
    redirect("/p/ponude/" + record.ponuda_id);
  };

  const handleClosePonuda = (result) => {
    if (result && result.success) {
      snackbarContext.showNotification("insert", "success");
    }
    if (result && result.dataChanged) {
      if (doRefresh) {
        doRefresh();
      }
    }
    dialogContext.hideDialog();
  };

  const recordActive = record && record.active ? true : false;

  const ponudaExists = record && record["ponuda_id"] ? true : false;

  return (
    <Card>
      <CardHeading>
        <Toolbar variant="dense" disableGutters={true}>
          <FormAvatar ariaLabel="poslovni_subjekt" icon={<BurzaIcon />} />
          <FormHeading header={t("titles.upit_burza")} subheaderContent={<FormHeadingIDAndBS record={record} />} dataChanged={dataChanged} />
          <FormFillContent />
          <CardToolbarButtonBack onClick={handleClickBack} />
        </Toolbar>
      </CardHeading>
      <CardContent style={{ paddingTop: 0 }}>
        <TabPanelUpitJavno
          tabIndex={1}
          currentTab={1}
          recordId={recordId}
          subModels={subModels}
          record={record}
          fields={fields}
          mode={"view"}
          showNarucitelj
        />
        <CardActions>
          <EuroConversionComment />
          <FormFillContent />
          <CardActionButtonClose variant="outlined" onClick={handleClickBack} />
          {ponudaExists ? (
            <CardActionButton startIcon={<LocalOfferIcon />} variant="contained" onClick={handleOpenPonuda}>
              {t("buttons.ponuda_open")}
            </CardActionButton>
          ) : (
            <CardActionButton
              startIcon={<LocalOfferIcon />}
              variant="contained"
              onClick={handleNewPonuda}
              disabled={!recordActive}
            >
              {t("buttons.ponuda_create")}
            </CardActionButton>
          )}
        </CardActions>
      </CardContent>
    </Card>
  );
}

export default withFormController(withRedirect(BurzaUpitForm));
