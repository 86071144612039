import React from "react";

import FormAvatar from "UI/Form/FormAvatar";
import VoznjaIcon from "Icons/VoznjaIcon";

function VoznjaAvatar() {
  return <FormAvatar ariaLabel="voznja" icon={<VoznjaIcon />} />;
}

export default VoznjaAvatar;
