import React, { useContext, useState, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";

//Material UI Components
import AddCircleIcon from "@material-ui/icons/AddCircle";
import FavoriteIcon from "@material-ui/icons/Favorite";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

//Custom Components
import { GridContainer, GridItem } from "UI/Grid";
import FormTabPanel from "UI/Form/FormTabPanel";
import FormContent from "Components/FormContent";
import FormButton from "UI/Form/FormButton";
import UserContext from "Components/UserContext/UserContext";
import ModelTable from "UI/Table/ModelTable";
import TableButton from "UI/Table/TableButton";

function TabPanelUpitPrijevoznici(props) {
  const { currentTab, tabIndex, disableRemove, direktIdRecord } = props;
  const { record, validation, fields, onFieldChange, mode, subModels, dc } = props;

  const [records, setRecords] = useState([]);

  const { t } = useTranslation();

  const f_prijevoznici_direkt_ids_helper = "prijevoznici_direkt_ids_helper";
  const f_prijevoznici_direkt_ids = "prijevoznici_direkt_ids";

  const userContext = useContext(UserContext);

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    mode: record.ask_agentura ? "view" : mode,
    subModels: subModels
  };

  const getFavoritePrijevoznici = () => {
    const currentRecords = subModels.prijevoznici_direkt_ids_helper.records;
    const currentFavIds = userContext.userSettings ? userContext.userSettings.fav_prijevoznici : [];

    if (currentFavIds.length > 0) {
      const currentFavs = currentFavIds
        .map((id) => {
          const tempItem = currentRecords.find((record) => record.value === id);
          const inRecords = records.some((record) => record.value === id);
          if (!inRecords) {
            return tempItem;
          }
        })
        .filter((record) => record !== undefined);
      const newRecords = records.concat(currentFavs);
      setRecords(newRecords);
      onFieldChange(null, f_prijevoznici_direkt_ids_helper);
      onFieldChange(
        newRecords.map((record) => record.value),
        f_prijevoznici_direkt_ids
      );
    }
  };

  const addPrijevoznici = () => {
    const currentRecords = subModels.prijevoznici_direkt_ids_helper.records;
    const selectedRecords = record.prijevoznici_direkt_ids_helper;

    if (selectedRecords.length > 0) {
      const chosenRecords = selectedRecords
        .map((selected) => {
          const tempItem = currentRecords.find((current) => current.value === selected.value);
          const inRecords = records.some((record) => record.value === selected.value);
          if (!inRecords) {
            return tempItem;
          }
        })
        .filter((record) => record !== undefined);
      const newRecords = records.concat(chosenRecords);
      setRecords(newRecords);
      onFieldChange(null, f_prijevoznici_direkt_ids_helper);
      onFieldChange(
        newRecords.map((record) => record.value),
        f_prijevoznici_direkt_ids
      );
    }
  };

  const removeFromChosen = (evt, sel) => {
    const toBeRemoved = sel.map((selection) => parseInt(selection[0], 10));
    const indexSet = new Set(toBeRemoved);

    const newRecords = records.filter((value, i) => !indexSet.has(i));
    setRecords(newRecords);
    onFieldChange(
      newRecords.map((record) => record.value),
      f_prijevoznici_direkt_ids
    );
  };

  const handleVIPAgentura = (value, source) => {
    if(value) {
      setRecords([])
      onFieldChange([], f_prijevoznici_direkt_ids)
      onFieldChange(false, "burza_visible")
      onFieldChange(null, f_prijevoznici_direkt_ids_helper)
    }
    onFieldChange(value, source)
  } 


  // TODO: @aleksey
  // refactor these two useEffect abominatios
  useEffect(() => {
    if (direktIdRecord) {
      const chosenRecords = [direktIdRecord];
      setRecords(chosenRecords);
      onFieldChange(
        [direktIdRecord.id],
        f_prijevoznici_direkt_ids
      );
    }
  }, [direktIdRecord]);

  useEffect(() => {
    const chosenOperators = record.prijevoznici_direkt_ids;
    if (chosenOperators && subModels) {
      const helperSubModel = subModels.prijevoznici_direkt_ids_helper;
      helperSubModel.api.Call(subModels.prijevoznici_direkt_ids_helper.apiPath, "get").then((resp) => {
        if (resp.success) {
          const currentOperators = resp.data.map((d) => Object.assign({}, d));
          const chosenRecords = chosenOperators
            .map((selected) => {
              const tempItem = currentOperators.find((current) => current.value === selected);
              return tempItem;
            })
            .filter((record) => record !== undefined);
          setRecords(chosenRecords);
        }
      });
    }
  }, [record, subModels]);

  const pickerRecords = record.prijevoznici_direkt_ids_helper ? record.prijevoznici_direkt_ids_helper : [];

  return (
    <FormTabPanel value={currentTab} index={tabIndex}>
      <GridContainer>
        <GridItem xs={3}>
          <FormContent title={t("upit.sections.burza")} {...commonProps} fieldNames={["burza_visible"]} columns={1} />
        </GridItem>
        <GridItem xs={3}>
          <FormContent
            title={t("upit.sections.agentura")} 
            {...commonProps} 
            mode={mode}
            onFieldChange={handleVIPAgentura}
            fieldNames={["ask_agentura"]} 
            columns={1} 
          />
        </GridItem>
        <GridItem xs={12} sm={12}>
          <GridContainer>
            <FormContent
              title={t("upit.sections.direktni_upit")}
              {...commonProps}
              fieldNames={["prijevoznici_direkt_ids_helper"]}
              columns={2}
            />
            <GridItem xs={2} sm={4} md={4}>
              <FormButton variant="outlined" startIcon={<AddCircleIcon />} onClick={addPrijevoznici} disabled={commonProps.mode === "view" || pickerRecords.length === 0 ? true : false}>
                {pickerRecords.length === 1 || pickerRecords.length === 0
                  ? t("trjob.add_prijevoznika")
                  : t("trjob.add_prijevoznike")}
              </FormButton>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <ModelTable
        title="Odabrani prijevoznici"
        records={records}
        dc={dc}
        viewName="odabir_prijevoznika"
        allowExport={false}
        allowFilter={false}
        allowAdd={false}
        allowSelection={commonProps.mode === "view" ? "none" : "many"}
      >
        <TableButton variant="outlined" allow={disableRemove || commonProps.mode === "view" ? "none" : "many"} onClick={removeFromChosen} startIcon={<RemoveCircleIcon />}>
          {records.length === 1 || records.length === 0
            ? t("buttons.remove_prijevoznik")
            : t("buttons.remove_prijevoznici")}
        </TableButton>
        <TableButton variant="contained" onClick={getFavoritePrijevoznici} startIcon={<FavoriteIcon />} allow={commonProps.mode === "view" ? "none" : "always"}>
          {t("buttons.get_favs")}
        </TableButton>
      </ModelTable>
    </FormTabPanel>
  );
}

export default TabPanelUpitPrijevoznici;
