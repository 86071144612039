import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

//Material UI
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";

//Material-UI Icons
import HelpIcon from "@material-ui/icons/Help";
import InfoIcon from "@material-ui/icons/Info";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ChatIcon from "@material-ui/icons/Chat";
import ReceiptIcon from "@material-ui/icons/Receipt";

//CBC Icons
import PotvrdaRezervacijeIcon from "Icons/PotvrdaRezervacijeIcon";

//Custom components
import withFormController from "Components/withFormController";
import AdminVoznjaTabOsnovniPodaci from "Views/Administration/AdminVoznje/Tabs/AdminVoznjaTabOsnovniPodaci";
import AdminVoznjaTabPojedinacneVoznje from "Views/Administration/AdminVoznje/Tabs/AdminVoznjaTabPojedinacneVoznje";
import { FormHeading, FormHeadingMediator } from "UI/Form";
import { FormFillContent, FormTab, FormTabPanel, FormTabs } from "UI/Form";
import AdminTabFinancijskeObaveze from "Views/Administration/AdminVoznje/Tabs/AdminTabFinancijskeObaveze";
import CardHeading from "UI/Card/CardHeading";
import VoznjaAvatar from "UI/Form/Avatars/VoznjaAvatar";
import { CardColored, CardToolbarButtonAlarm, CardToolbarButtonBack } from "UI/Card";

import AdminVoznjaTabPlatforma from "Views/Administration/AdminVoznje/Tabs/AdminVoznjaTabPlatforma";
import AdminTabPotvrdaRezervacije from "Views/Administration/AdminVoznje/Tabs/AdminTabPotvrdaRezervacije";
import AdminVoznjaTabFakture from "Views/Administration/AdminVoznje/Tabs/AdminVoznjaTabFakture";


import {
  CardToolbarMenuButton,
  CardToolbarMenuItemAssignMediation,
  CardToolbarMenuItemClaimMediation,
  CardToolbarMenuItemInvalidate,
  CardToolbarMenuItemAgentura,
  CardToolbarMenuItemMarkUnread,
  CardToolbarMenuItemResolvePanic
} from "UI/Card";

import AgenturaButton from "UI/Buttons/AgenturaButton";
import { posloviStatuses, retrievePosloviStatusLabel } from "Lib/statuses";

import KomunikatorSinglePage from "Views/Komunikator/KomunikatorSinglePage";

import useMediation from "Components/useMediation";
import useAgentura from "Components/useAgentura";
import useMarkRead from "Components/useMarkRead";
import usePanic from "Components/usePanic";
import EuroConversionComment from "Components/EuroConversionComment";

function AdminVoznjaForm25(props) {

  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();

  const [currentTab, setCurrentTab] = useState(0);

  const { handleClaimMediation, handleAssignMediation} = useMediation({dc: props.dc, record: props.record, doRefresh: props.doRefresh});
  const { handleTakeOverAgentura} = useAgentura({dc: props.dc, record: props.record, doRefresh: props.doRefresh});
  const { handleMarkUnread } = useMarkRead({dc: props.dc, recordId: props.record.id});
  const { handleResolvePanic } = usePanic({record: props.record, doRefresh: props.doRefresh});

  const { dc, record, mode, validation, fields, subModels, onFieldChange } = props; //HOC withFormController
  const { handleStorniraj, handleRefreshForm } = props;

  const handleTabChange = (evt, value) => {
    setCurrentTab(value);
  };

  const handleClickBack = () => {
    history.goBack();
  };

  const title = t(retrievePosloviStatusLabel(record.posao_state_id));

  const identifier = dc.getSource() + record.posao_state_id;
  const isStorno = record.storno ? true : false;
  const isStornoNaplativ = record.storno_naplativ === true;
  const isAgentura = record.agentura_interni_posao_id ? true : false;
  const isPanic = record.panic ? true : false;

  const customColor = isStorno ? theme.palette.custom.storno :
    isPanic ? theme.palette.custom.panic :
    isAgentura ? theme.palette.custom.agentura :
    undefined;

  const customText = isStorno ? t("messages.storno") :
    isPanic ? t("messages.panic") :
    isAgentura ? t("messages.agentura") :
    undefined;

  const commonTabProps = {
      currentTab: currentTab,
      dc: dc,
      subModels: subModels,
      record: record,
      mode: mode,
      validation: validation,
      onFieldChange: onFieldChange,
      fields: fields,
      isPanic: isPanic,
      recordId: record.id
    };

  let i = -1; // because we use ++i and not i++

  return (
    <CardColored color={customColor}>
      <CardHeading recordStatus={record.posao_state_id} statuses={posloviStatuses} color={customColor}>
        <Toolbar variant="dense" disableGutters={true}>
          <VoznjaAvatar />
          <FormHeading header={title} subheaderContent={<FormHeadingMediator record={record} />} />
          {/*<CardSOSHeading isPanic={props.isPanic}/>*/}
          <FormFillContent />
          {customText ?
            <Typography variant="button">{customText}</Typography>
            : null
          }
          <FormFillContent />
          <CardToolbarButtonAlarm record={record} type="voznja" onFieldChange={onFieldChange} />
          <CardToolbarMenuButton>
            <CardToolbarMenuItemClaimMediation onClick={handleClaimMediation}/>
            <CardToolbarMenuItemAssignMediation onClick={handleAssignMediation}/>
            <CardToolbarMenuItemMarkUnread onClick={handleMarkUnread} />
            {!isStorno ? (
              <CardToolbarMenuItemInvalidate onClick={() => handleStorniraj(record["id"])} />
            ) : null}
            {!isAgentura ? (
              <CardToolbarMenuItemAgentura onClick={handleTakeOverAgentura} />
            ) : null }
            {isPanic ? (
              <CardToolbarMenuItemResolvePanic onClick={handleResolvePanic} />
            ) : null }
          </CardToolbarMenuButton>
          <CardToolbarButtonBack onClick={handleClickBack} />
        </Toolbar>
      </CardHeading>
      <CardContent style={{ paddingTop: 0 }}>
        <FormTabs value={currentTab} onChange={handleTabChange} identifier={identifier}>
          <FormTab id="platforma" label={t("poslovi.tabs.platforma")} icon={<HelpIcon />} aria-label="platforma" />
          <FormTab
            id="osnovni-podaci"
            label={t("poslovi.tabs.osnovni_podaci")}
            icon={<InfoIcon />}
            aria-label="osnovni_podaci"
          />
          <FormTab
            id="pojedinacne-voznje"
            label={t("poslovi.tabs.pojedinacne_voznje")}
            icon={<AirportShuttleIcon />}
            aria-label="pojedinacne_voznje"
          />
          <FormTab
            id="financijske-obveze"
            label={t("poslovi.tabs.financijske_obveze")}
            icon={<MonetizationOnIcon />}
            aria-label="financijske_obveze"
          />
          <FormTab
            id="potvrda-rezervacije"
            label={t("poslovi.tabs.potvrda_rezervacije")}
            icon={<PotvrdaRezervacijeIcon />}
            aria-label="potvrda_rezervacije"
          />
          <FormTab
            id="komunikator"
            label={t("poslovi.tabs.komunikator")}
            icon={<ChatIcon />}
            aria-label="komunikator"
          />
          { isStornoNaplativ ?
            <FormTab
              id="faktura"
              label={t("poslovi.tabs.faktura")}
              icon={<ReceiptIcon />}
              aria-label="faktura"
            /> : null
          }
        </FormTabs>
        <FormTabPanel value={currentTab} index={++i}>
          <AdminVoznjaTabPlatforma {...commonTabProps} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={++i}>
          <AdminVoznjaTabOsnovniPodaci {...commonTabProps} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={++i}>
          <AdminVoznjaTabPojedinacneVoznje {...commonTabProps} tabValue={i} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={++i}>
          <AdminTabFinancijskeObaveze {...commonTabProps} mode="update" tabValue={i} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={++i}>
          <AdminTabPotvrdaRezervacije {...commonTabProps} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={++i}>
          <KomunikatorSinglePage recordId={record.id} refreshForm={handleRefreshForm} />
        </FormTabPanel>
        { isStornoNaplativ ?
          <FormTabPanel value={currentTab} index={++i}>
            <AdminVoznjaTabFakture {...commonTabProps} mode="update" tabValue={i}  />
          </FormTabPanel> : null
        }
      </CardContent>
      <CardActions>
        <EuroConversionComment />
        <FormFillContent />
        <AgenturaButton id={record.agentura_interni_posao_id}/>
      </CardActions>
    </CardColored>
  );
}

export default withFormController(AdminVoznjaForm25);
