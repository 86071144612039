import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import numbro from "numbro";

//Material-UI Core Components
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from "@material-ui/core/Toolbar";

//Material-UI Icons
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";

//Custom Components
import withFormController from "Components/withFormController";
import DynamicDialog from "UI/Dialog/DynamicDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarCreationDetails from "UI/Dialog/DialogToolbarCreationDetails";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import { FormTabs, FormTab, FormTabPanel } from "UI/Form";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogActionButtonAdd from "UI/Dialog/ActionButtons/DialogActionButtonAdd";
import FormContent from "Components/FormContent";
import DialogContext from "UI/DialogContext/DialogContext";
import GridContainer from "UI/Grid/GridContainer";
import GoogleMap from "Components/Google/GoogleMap";
import GridItem from "UI/Grid/GridItem";
import {mapIcons} from "Lib/mapIcons";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import EuroConversionComment from "Components/EuroConversionComment";

const mapStyles = {
  height: "280px"
};

function VoziloDialog(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();

  const [dataChanged, setDataChanged] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [pinPoint, setPinPoint] = useState(null);

  const { dc, record, validation, fields, subModels, mode } = props; //HOC withFormController
  const { doInsert, doUpdate, doClose } = props; //HOC withFormController
  const { onFieldChange, onClose } = props; //HOC withFormController

  const fPricePerKm = "price_per_distance";
  const fFullDayDistance = "distance_for_day";
  const fFullDayPrice = "price_for_day";
  const fLocation = "lokacija";

  const handleSetMarker = (data) => {
    const {value, label, coords} = data;

    const icon = mapIcons.pinPointIcon();
    const marker = {id: value, label: label, coords: coords, icon: icon};

    setPinPoint(marker);
  }

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      let price = null;

      if (source === fLocation) {
        handleSetMarker(value)
      }

      if (source === fPricePerKm) {
        if (value && record[fFullDayDistance]) {
            price = numbro(value).value() * numbro(record[fFullDayDistance]).value()
            onFieldChange(price, fFullDayPrice)
        } else {
          onFieldChange(null, fFullDayPrice)
        }
      }

      if (source === fFullDayDistance) {
        if (value && record[fPricePerKm]) {
          price = numbro(value).value() * numbro(record[fPricePerKm]).value()
          onFieldChange(price, fFullDayPrice)
        } else {
          onFieldChange(null, fFullDayPrice)
        }
      }

      onFieldChange(value, source);
    }
  };

  const handleTabChange = (evt, value) => {
    console.log(record);
    setCurrentTab(value);
  };

  const handleInsert = (evt) => {
    if (doInsert) {
      doInsert().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "insert" });
          snackbarContext.showNotification("insert", "success");
        }
      }).catch((result) => {
        if (result.success === false && result.validationPass === false) {
          //do nothing
        } else {
          snackbarContext.showNotification(result.error, "error");
        }
      });
    }
  };

  const handleUpdate = (evt) => {
    if (doUpdate) {
      doUpdate().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "update" });
          snackbarContext.showNotification("update", "success");
        }
      }).catch((result) => {
        if (result.success === false && result.validationPass === false) {
          //do nothing
        } else {
          snackbarContext.showNotification(result.error, "error");
        }
      });
    }
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const close = (result) => {
    if (result.dataChanged || dataChanged) {
      onClose({ dataChanged: true, action: result.action });
    }

    dialogContext.hideDialog();
  };

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: handleFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels
  };

  return (
    <DynamicDialog onClose={handleClose} fixedHeight={true}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogAvatar ariaLabel="pregled_vozila" icon={<DirectionsBusIcon />} />
          <DialogToolbarHeading>
            {t("titles.vozilo")}
          </DialogToolbarHeading>
          <DialogToolbarFillContent />
          <DialogToolbarCreationDetails record={record} />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <FormTabs value={currentTab} onChange={handleTabChange}>
          <FormTab
            value={0}
            label={t("vozila.tabs.osnovni_podaci")}
            icon={<BusinessCenterIcon />}
            aria-label="osnovni_podaci"
            fields={["business_subject_id","public","marka_tip","reg_oznaka","br_sjedala","extra_sjedala","tip_id","klasa_id","klasa_motora_id","godina_proizvodnje","vozilo_oprema_ids","dozvoli_djecje_grupe","opis_hr","opis_en"]}
            validation={validation}
          />
          <FormTab
            value={1}
            label={t("vozila.tabs.osnovni_cjenik")}
            icon={<MonetizationOnIcon />}
            aria-label="osnovni_cjenik"
            fields={["price_per_distance", "price_for_transfer", "distance_for_transfer", "duration_for_transfer", "price_for_half_day", "distance_for_half_day", "duration_for_half_day", "price_for_day", "distance_for_day", "duration_for_day"]}
            validation={validation}
          />
        </FormTabs>
        <FormTabPanel value={currentTab} index={0}>
          <GridContainer>
            <FormContent
              {...commonProps}
              title={t("vozila.sections.vlasnistvo")}
              fieldNames={["business_subject_id", "public"]}
              columns={2}
            />
            <FormContent
              {...commonProps}
              title={t("vozila.sections.podaci")}
              fieldNames={[
                "marka_tip",
                "reg_oznaka",
                "br_sjedala",
                "extra_sjedala",
                "tip_id",
                "klasa_id",
                "klasa_motora_id",
                "godina_proizvodnje",
                "vozilo_oprema_ids",
                "dozvoli_djecje_grupe",
                "opis_hr",
                "opis_en"
              ]}
              columns={2}
            />
          </GridContainer>
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={1}>
          <GridContainer>
            <GridItem md={12} sm={12}>
              <GridContainer direction="row" spacing={2}>
                <GridItem md={3} sm={3}>
                  <GridContainer direction="column" spacing={0}>
                    <FormContent
                        {...commonProps}
                        title={t("cjenici.sections.lokacija")}
                        fieldNames={["lokacija"]}
                        columns={1}
                    />
                    <FormContent
                        {...commonProps}
                        title={t("cjenici.sections.osnovno")}
                        fieldNames={["price_per_distance"]}
                        columns={1}
                    />
                  </GridContainer>
                </GridItem>
                <GridItem xs={9} md={9}>
                  <GoogleMap markers={pinPoint ? [pinPoint] : null} styles={mapStyles} zoomIn={true}/>
                </GridItem>
              </GridContainer>
            </GridItem>
            <FormContent
              {...commonProps}
              title={t("cjenici.sections.kratkotrajni_najam")}
              fieldNames={["duration_for_transfer", "distance_for_transfer", "price_for_transfer"]}
              columns={3}
            />
            <FormContent
              {...commonProps}
              title={t("cjenici.sections.poludnevni_najam")}
              fieldNames={["duration_for_half_day", "distance_for_half_day", "price_for_half_day"]}
              columns={3}
            />
            <FormContent
              {...commonProps}
              title={t("cjenici.sections.cijelodnevni_najam")}
              fieldNames={["duration_for_day", "distance_for_day", "price_for_day"]}
              columns={3}
            />
          </GridContainer>
        </FormTabPanel>
      </DialogBody>
      <DialogActions>
        <EuroConversionComment />
        <DialogToolbarFillContent />
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
        {mode === "insert" ? <DialogActionButtonAdd variant="contained" onClick={handleInsert} /> : null}
      </DialogActions>
    </DynamicDialog>
  );
}

export default withFormController(VoziloDialog);
