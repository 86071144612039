import React from 'react';
import { useTranslation } from "react-i18next";

//Material-UI
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";

//Custom Components
import FormButton from "UI/Form/FormButton";

function SwapButton(props) {
  const {t} = useTranslation();
  const { label, needPermission, onClick, disabled} = props;

  return (
    <FormButton
      variant="contained"
      startIcon={<SwapHorizIcon/>}
      onClick={onClick}
      needPermission={needPermission}
      disabled={disabled}
    >
      { label ? label : t("buttons.swap")}
    </FormButton>
  );
}

export default SwapButton;
