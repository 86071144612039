import React from 'react';

import { useTranslation } from "react-i18next";
import FormButton from "../Form/FormButton";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

function PonudaButton(props) {
  const {t} = useTranslation();
  const { needPermission, onClick, text, disabled} = props;

  return (
      <FormButton
          variant="contained"
          startIcon={<AssignmentTurnedInIcon/>}
          onClick={onClick}
          needPermission={needPermission}
          disabled={disabled}
      >
        {text}
      </FormButton>
  );
}

export default PonudaButton;
