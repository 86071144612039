import React, { useContext } from "react";
import PropTypes from "prop-types";

//Material-UI Core Components
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Tooltip from "@material-ui/core/Tooltip";

//Custom Components
import UserContext from "Components/UserContext/UserContext";

const customButtonStyles = makeStyles((theme) => ({
  root: {
    minHeight: 32,
    color: theme.palette.primary.black,
    marginLeft: 10,
    marginRight: 0
  }
}));

function TableHeaderButton(props) {
  const userContext = useContext(UserContext);
  const { onClick, needPermission, startIcon, ...otherProps } = props;
  const classes = customButtonStyles();

  const handleClick = function (evt) {
    onClick(evt);
  };

  return needPermission && !userContext.hasAnyPermission(needPermission) ? null : (
    <>
      <Hidden mdDown>
        <Button
          classes={classes}
          color="secondary"
          size="small"
          startIcon={startIcon}
          {...otherProps}
          onClick={handleClick}
        >
          {props.children}
        </Button>
      </Hidden>
      <Hidden lgUp>
        <Tooltip title={props.children}>
          <IconButton color="secondary" {...otherProps} onClick={handleClick}>
            {startIcon}
          </IconButton>
        </Tooltip>
      </Hidden>
    </>
  );
}

TableHeaderButton.propTypes = {
  needPermission: PropTypes.arrayOf(PropTypes.object)
};

export default TableHeaderButton;
