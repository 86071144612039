import api from "Lib/api";

export const ponudeService = {
  submit,
  createDraftForUpit,
  createDraftForDirektanUpit,
  updateDraft,
  addFromEksterniTroskovi,

  getPojedinacneVoznje,
  getPojedinacneVoznjeById,
  createPojedinacneVoznje,
  updatePojedinacneVoznje,
  deletePojedinacneVoznje
}

const ponudeApiUrl = 'ponude/';

function submit(id, data) {
  const apiInstance = new api();
  const path = ponudeApiUrl + id + "/submit";
  return apiInstance.Call(path, "put", data);
}

function updateDraft(ponudaId, data){
  const apiInstance = new api();
  const path = ponudeApiUrl + "skice/" + ponudaId;
  return apiInstance.Call(path, "put", data);
}

function getPojedinacneVoznje(id){
  const apiInstance = new api();
  const path = ponudeApiUrl + id + "/voznje";
  return apiInstance.Call(path, "get");
}

function getPojedinacneVoznjeById(ponudaId, poj_voznje_id){
  const apiInstance = new api();
  const path = ponudeApiUrl + ponudaId + "/voznje/" + poj_voznje_id;
  return apiInstance.Call(path, "get");
}

function createPojedinacneVoznje(id, data){
  const apiInstance = new api();
  const path = ponudeApiUrl + id + "/voznje";
  return apiInstance.Call(path, "post", data);
}

function updatePojedinacneVoznje(ponudaId, poj_voznje_id, data){
  const apiInstance = new api();
  const path = ponudeApiUrl + ponudaId + "/voznje/" + poj_voznje_id;
  return apiInstance.Call(path, "put", data);
}

function deletePojedinacneVoznje(ponudaId, poj_voznje_id){
  const apiInstance = new api();
  const path = ponudeApiUrl + ponudaId + "/voznje/" + poj_voznje_id;
  return apiInstance.Call(path, "delete");
}

function addFromEksterniTroskovi(posaoId, data){
  const apiInstance = new api();
  const path = ponudeApiUrl + posaoId + "/financijske-kalkulacije" + "/add-external";
  return apiInstance.Call(path, "post", data);
}

function createDraftForUpit(upitId, ponuda){
  const apiInstance = new api();
  const path = "upiti/" + upitId + "/ponude/skice";
  return apiInstance.Call(path, "post", ponuda);
}

function createDraftForDirektanUpit(direktanUpitId, ponuda){
  const apiInstance = new api();
  const path = "direktni-upiti/" + direktanUpitId + "/ponude/skice";
  return apiInstance.Call(path, "post", ponuda);
}
