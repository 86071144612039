import createReport from "docx-templates";
import moment from "moment";
import numbro from "numbro";
import { formats } from "Lib/formats";

import api from "Lib/api";
import { loadWordTemplate, saveDataToFile } from "Lib/downloadTemplate";

const transparentPixel1x1 = "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="

const handlePrint = (platformskiPosao, record, t) => {
  const apiInstance = new api();
  const path = `poslovi/${record.posao_id}/fakture/${record.id}`;
  const pathStavke = `poslovi/${record.posao_id}/fakture/${record.id}/stavke`;
  apiInstance.Call(path, "get").then((resp) => {
    const data = resp.data;
    apiInstance.Call(pathStavke, "get").then((resp2) => {
      data.stavke = resp2.data;
      const wordTemplatePath = getWordTemplatePath(platformskiPosao);
      const fillFunction = getFillFunction(t);

      loadWordTemplate(wordTemplatePath, fillFunction(data));
    });
  });
};

const handleAdminPrint = (record, t) => {
  const apiInstance = new api();
  const path = `admin/voznje/${record.posao_id}/fakture/${record.id}`;
  const pathStavke = `admin/voznje/${record.posao_id}/fakture/${record.id}/stavke`;
  apiInstance.Call(path, "get").then((resp) => {
    const data = resp.data;
    apiInstance.Call(pathStavke, "get").then((resp2) => {
      data.stavke = resp2.data;
      const wordTemplatePath = "/templates/faktura_platformski_posao_template.docx";
      const fillFunction = getFillFunction(t);

      loadWordTemplate(wordTemplatePath, fillFunction(data));
    });
  });
}

const handleAgencijaPrint = (record, t) => {
  const apiInstance = new api();
  const path = `upiti/${record.upit_id}/fakture/${record.id}`;
  const pathStavke = `upiti/${record.upit_id}/fakture/${record.id}/stavke`;
  apiInstance.Call(path, "get").then((resp) => {
    const data = resp.data;
    apiInstance.Call(pathStavke, "get").then((resp2) => {
      data.stavke = resp2.data;
      const wordTemplatePath = "/templates/faktura_platformski_posao_template.docx";
      const fillFunction = getFillFunction(t);

      loadWordTemplate(wordTemplatePath, fillFunction(data));
    });
  });
}

const handlePausalPrint = (record, t) => {
  const apiInstance = new api();
  const path = `business-subjects/${record.bs_id}/pausal-fakture/${record.id}`;
  const pathStavke = `business-subjects/${record.bs_id}/pausal-fakture/${record.id}/stavke`;
  apiInstance.Call(path, "get").then((resp) => {
    const data = resp.data;
    apiInstance.Call(pathStavke, "get").then((resp2) => {
      data.stavke = resp2.data;
      const wordTemplatePath = "/templates/faktura_platformski_posao_template.docx";
      const fillFunction = getFillFunction(t);

      loadWordTemplate(wordTemplatePath, fillFunction(data));
    });
  });
}

const getWordTemplatePath = (platformskiPosao) => {
  if(platformskiPosao) {
    return "/templates/faktura_platformski_posao_template.docx";
  }
  return "/templates/faktura_interni_posao_template.docx";
};

const getFillFunction = (t) => {
  return (record) =>
    function (error, content) {
      if (error) {
        console.warn(error);
        throw error;
      }

      fillReport(t, content, record);
    }.bind(this);
};

const fillReport = (t, content, record) => {
  const report = createReport({
    template: content,
    data: {
      pr_naz: record.izdavatelj_naziv,
      pr_adr_full: record.izdavatelj_adresa_full,
      pr_oib: record.izdavatelj_oib,
      pr_banka: record.banka,
      pr_iban: record.iban,
      pr_swift: record.swift,
      nar_naz: record.kupac_naziv,
      nar_adr_full: record.kupac_adresa_full,
      nar_oib: record.kupac_oib,
      oznaka: record.oznaka_fakture,
      datum_isporuke: record.datum_isporuke ? moment(record.datum_isporuke).format(formats.date) : "",
      datum_izdavanja: record.ts_izdavanja ? moment(record.ts_izdavanja).format(formats.date) : "",
      vrijeme_izdavanja: record.ts_izdavanja ? moment(record.ts_izdavanja).format(formats.time) : "",
      neoporezivo: record.calc_neoporezivo
        ? numbro(record.calc_neoporezivo).format({ thousandSeparated: true, mantissa: 2 })
        : "",
      oporezivo: record.calc_oporezivo
        ? numbro(record.calc_oporezivo).format({ thousandSeparated: true, mantissa: 2 })
        : "",
      porez: record.calc_porez ? numbro(record.calc_porez).format({ thousandSeparated: true, mantissa: 2 }) : "",
      ukupno: record.calc_ukupno ? numbro(record.calc_ukupno).format({ thousandSeparated: true, mantissa: 2 }) : "",
      poziv_na_broj: record.poziv_na_broj,
      rok_dani: record.faktura_rok_uplate_dani,
      racun_izdao: record.racun_izdao,
      napomena: record.faktura_napomena,
      footer_napomene: record.faktura_napomene,
      stavke: Array.isArray(record.stavke)
        ? record.stavke.map((x, i) => {
            return {
              rbr: `${i+1}.`,
              opis: x.opis,
              iznos_neto: (x.iznos_neto || x.iznos_neto === 0) ? numbro(x.iznos_neto).format({ thousandSeparated: true, mantissa: 2 }) : "",
              pdv_iznos: (x.pdv_iznos || x.pdv_iznos === 0) ? numbro(x.pdv_iznos).format({ thousandSeparated: true, mantissa: 2 }) : "",
              pdv_stopa: (x.pdv_stopa || x.pdv_stopa === 0) ? numbro(x.pdv_stopa).format({ thousandSeparated: true, mantissa: 0 }) + "%" : "",
              iznos_bruto: (x.iznos_bruto || x.iznos_bruto === 0) ? numbro(x.iznos_bruto).format({ thousandSeparated: true, mantissa: 2 }) : ""
            };
          })
        : []
    },
    additionalJsContext: {
      memo_header: function() {
        const data = record.memo_image ? record.memo_image.image : transparentPixel1x1;
        return { width: 16, height: 3, data, extension: '.jpg'}
      }
    }
  });
  report.then((data) => {
    let filename = `faktura_${record.id}.docx`;

    saveDataToFile(data, filename, "application/vnd.openxmlformats-officedocument.wordprocessingml.document");
  });
};

export const fakturaPrint = {
  handlePrint,
  handleAdminPrint,
  handleAgencijaPrint,
  handlePausalPrint
};
