import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Icons
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";

//Custom components
import DialogToolbarMenuItem from "UI/Dialog/DialogToolbarMenuItem";

const DialogToolbarMenuItemInvalidateCopy = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return <DialogToolbarMenuItem label={t("buttons.invalidate_copy")} icon={<AddToPhotosIcon />} {...props} ref={ref} />;
});

export default DialogToolbarMenuItemInvalidateCopy;
