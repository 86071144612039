import React from "react";
import Chart from "react-apexcharts";

import withChart from "./withChart";

function BarChart(props) {
  const { options, series } = props;
  
  return (
    <Chart
      type="bar"
      options={options}
      series={series}
      height="800px"
    />
  );
}


export default withChart(BarChart);