import React, { Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

//Material-UI Core Components
import makeStyles from "@material-ui/core/styles/makeStyles";
import withTheme from "@material-ui/core/styles/withTheme";
import Menu from "@material-ui/core/Menu";
import Icon from "@material-ui/core/Icon";
import Badge from "@material-ui/core/Badge";

//Material-UI Icons
import MenuIcon from "@material-ui/icons/Menu";

//Custom Components
import HeaderIconButton from "UI/Header/HeaderIconButton";
import HeaderMenuItem from "UI/Header/HeaderMenuItem";

const customStyles = makeStyles((theme) => ({
  // paper: {
  //   backgroundColor: theme.palette.background,
  //   color: theme.palette.primary.main
  // },
  // list: {
  //   color: theme.palette.primary.main,
  //   padding: "1px 0"
  // }
}));

function HeaderMenuIconButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = customStyles();
  const { icon, desc, keepOpen, toggleSidebar } = props;
  const { badge, badgeColor } = props;

  const handleClick = (evt) => {
    setAnchorEl(evt.currentTarget);
  };

  const handleClose = (evt, reason) => {
    setAnchorEl(null);
  };

  const hookAction = (action) => {
    if (!keepOpen) {
      setAnchorEl(null);
    }
    action();
  };

  const buildMenuItem = (node, index) => {
    const { onClick, label, icon, ...otherProps } = node.props;
    return <HeaderMenuItem onClick={() => hookAction(onClick)} label={label} icon={icon} key={index} {...otherProps}/>;
  };

  let menuItems = [];
  if (Array.isArray(props.children)) {
    props.children.forEach((child, i) => {
      if (child !== null && child !== undefined) {
        if (child.type === HeaderMenuItem) {
          menuItems.push(buildMenuItem(child, i));
        } else {
          menuItems.push(child);
        }
      }
    });
  } else {
    if (props.children !== null && props.children !== undefined) {
      menuItems.push(buildMenuItem(props.children, 1));
    }
  }

  return (
    <Fragment>
      <HeaderIconButton
        // variant="outlined"
        onClick={toggleSidebar ? toggleSidebar : (menuItems && menuItems.length ? handleClick : () => {})}
        desc={desc}
      >
        <Badge color={badgeColor} badgeContent={badge}>
          {icon ? icon : <MenuIcon />}
        </Badge>
      </HeaderIconButton>
      <Menu
        id="dialogt-toolbar-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={classes}
      >
        {menuItems}
      </Menu>
    </Fragment>
  );
}

HeaderMenuIconButton.propTypes = {
  icon: PropTypes.object
};

export default withTheme(HeaderMenuIconButton);
