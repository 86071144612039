import React from 'react';

import FormButton from "UI/Form/FormButton";
import { useTranslation } from "react-i18next";
import LockIcon from "@material-ui/icons/Lock";

function LockButton(props) {
  const {t} = useTranslation();
  const { needPermission, onClick, text, disabled} = props;

  return (
      <FormButton
          variant="outlined"
          startIcon={<LockIcon/>}
          onClick={onClick}
          needPermission={needPermission}
          disabled={disabled}
      >
        {text}
      </FormButton>
  );
}

export default LockButton;
