import React, { useContext, useEffect } from "react";

//Material-UI Core Components
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

//Custom Components
import UserContext from "Components/UserContext/UserContext";
import AppPage from "UI/AppPage/AppPage";
import useModuleRedirect from "Components/useModuleRedirect";

function HomePage(props) {
  const { moduleRedirect } = useModuleRedirect();
  const userContext = useContext(UserContext);

  useEffect(() => {
    if (userContext.loaded) {
      moduleRedirect();
    }
  }, [userContext.loaded]);

  return (
    <AppPage>
      <Box m={2}>
        <Typography>Should redirect...</Typography>
      </Box>
    </AppPage>
  );
}

export default HomePage;
