import { coreFields } from "./helpers/coreFields";
import { commonFields } from "./helpers/commonFields";
import { userFields } from "./helpers/userFields";
import { makeStyles } from "@material-ui/core";

export default {
  title: "Korisnici",
  exportFileName: "CBC_AKorisnici",
  apiPath: "agencije-korisnici/",
  forms: {
    insert: {
      fields: ["username", "first_name", "last_name", "password", "password_confirm", "email", "user_role"]
    },
    update: {
      fields: ["username", "first_name", "last_name", "email", "user_role"]
    }
  },
  listViews: {
    default: {
      fields: ["id", "username", "email", "first_name", "last_name", "user_role", "created_on", "last_login_on"],
      hidden: ["created_on", "last_login_on"]
    },
    exportExcel: {
      fields: ["id", "username", "email", "first_name", "last_name", "user_role", "created_on", "last_login_on"]
    }
  },
  fields: [
    coreFields.id,
    coreFields.created_on,
    coreFields.created_by,
    coreFields.modified_on,
    coreFields.modified_by,
    userFields.username,
    userFields.password,
    userFields.password_confirm,
    userFields.first_name,
    userFields.last_name,
    commonFields.email,
    commonFields.oib,
    {
      title: "Broj telefona",
      source: "telephone",
      ttoken: "users.telephone",
      type: "text",
      placeholder: "+385xxxxxxxxx",
      mask: false,
      validation: {
        required: true,
        maxLength: 20,
        regex: "tel"
      }
    },
    {
      title: "User role",
      source: "user_role",
      ttoken: "users.role",
      type: "picker",
      subModel: {
        apiPath: "agencije/roles",
        format: "{label}"
      },
      validation: {
        required: true
      },
      filter: true,
      translate: true,
      width: 30
    },
    {
      title: "Napomena",
      source: "napomena",
      ttoken: "users.napomena",
      type: "multiline",
      validation: {
        maxLength: 250
      },
      width: "full"
    },
    userFields.last_login_on
  ]
};
