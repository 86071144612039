import React from "react";
import { useParams } from "react-router";

//Material UI
import Box from "@material-ui/core/Box";

//Custom components
import dataController from "Lib/dataController";
import model from "Models/poslovniSubjekti";
import AdminPage from "UI/AppPage/AdminPage";
import FinancijeForm from "./FinancijeForm";

const FinancijeView = React.memo((props) => {
  const { id } = useParams();
  const dc = new dataController(model);

  const recordId = parseInt(id, 10);

  return (
    <AdminPage>
      <Box m={2}>
        <FinancijeForm dc={dc} recordId={recordId} mode="update" form="update" />
      </Box>
    </AdminPage>
  );
})

export default FinancijeView;
