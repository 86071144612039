import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

//Material-UI
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

//Custom Components
import withFormAuthController from "Components/withFormAuthController";
import withRedirect from "Components/withRedirect";
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import FormContent from "Components/FormContent";
import FormButton from "UI/Form/FormButton";
import UserConsumer from "Components/UserContext/UserConsumer";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "280px"
  },
  textInputContainer: {
    height: "56px"
  }
}));

function LoginForm(props) {
  const { t } = useTranslation();
  const { dc, form } = props;
  const { record, validation, fields } = props; //HOC withFormAuthController

  const classes = useStyles();

  const handleFieldChange = (value, source) => {
    const { onFieldChange } = props; //HOC withFormAuthController

    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleRegistration = () => {
    const { redirect } = props; //HOC withRedirect
    redirect("/register");
  };

  const handlePasswordReset = () => {
    const { redirect } = props; //HOC withRedirect
    redirect("/password-reset-request");
  };

  const handleSubmit = (setUserData) => {
    const { doLogin } = props; //HOC withFormAuthController
    const { redirect } = props; //HOC withRedirect

    if (doLogin) {
      doLogin().then((result) => {
        if (result && result.success) {
          if (!result || !result.data) result = { data: {} };
          setUserData(result.data.data);
          redirect("/");
        } else if (result && result.validationPass === false) {
          //console.log(result.validation);
        }
      });
    }
  };

  return (
    <UserConsumer>
      {(userContext) => (
        <Card>
          <CardContent>
            <form>
              <GridContainer>
                <GridItem xs={12}>
                  <Typography variant="h4" component="h2">
                    {t("titles.login")}
                  </Typography>
                </GridItem>
                <FormContent
                  fieldNames={dc.getFormFieldsNames(form)}
                  record={record}
                  validation={validation}
                  fields={fields}
                  onFieldChange={handleFieldChange}
                  onSubmit={() => handleSubmit(userContext.setUserData)}
                  mode={"insert"}
                  columns={1}
                />
              </GridContainer>
            </form>
          </CardContent>
          <CardActions>
            <FormButton variant="contained" onClick={() => handleSubmit(userContext.setUserData)}>
              {t("buttons.login")}
            </FormButton>
            <FormButton variant="text" onClick={handlePasswordReset}>
              {t("buttons.password_reset")}
            </FormButton>
          </CardActions>
        </Card>
      )}
    </UserConsumer>
  );
}

LoginForm.defaultProps = {
  shouldChangePassword: false
};

LoginForm.propTypes = {
  shouldChangePassword: PropTypes.bool
};

export default withFormAuthController(withRedirect(LoginForm));
