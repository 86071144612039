import React, { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

//Custom components
import GridContainer from "UI/Grid/GridContainer";
import FormContent from "Components/FormContent";

function TabUgovor(props, ref) {
  const { recordId, record, validation, fields, onFieldChange, mode, subModels } = props;

  const { t } = useTranslation();

  const renderMode = mode === "update" ? (record["posao_state_id"] > 22 ? "view" : mode) : "view";

  const commonProps = {
    recordId: recordId,
    record: record,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    mode: renderMode,
    subModels: subModels
  };

  return (
    <GridContainer>
      <FormContent fieldNames={["broj_sjedala", "datum_ugovora"]} {...commonProps} columns={2} />
      <FormContent fieldNames={["ugovor_iznos_hrk"]} {...commonProps} columns={1} />
    </GridContainer>
  );
}

export default TabUgovor;
