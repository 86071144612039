import React, { useContext } from 'react'
import { useHistory } from "react-router-dom";

//Custom Components
import UserContext from "Components/UserContext/UserContext";
import { Permissions } from "Lib/permissions";

function useModuleRedirect(props) {
  const userContext = useContext(UserContext);
  const history = useHistory();

  const path = props !== undefined && props.path !== undefined ? props.path : "";

  const permissionIds = userContext.permissionIds;

  const moduleRedirect = () => {
    if (Array.isArray(permissionIds)) {
      if (permissionIds.indexOf(Permissions.AdminModule.code) >= 0) {
        history.push("/admin" + path, history.location.state);
      } else if (permissionIds.indexOf(Permissions.PrijevozniciSve.code) >= 0) {
        history.push("/p" + path, history.location.state);
      } else if (permissionIds.indexOf(Permissions.AgencijaSve.code) >= 0) {
        history.push("/a" + path, history.location.state);
      }
    }
  }

  return {
    moduleRedirect
  }
}

export default useModuleRedirect;