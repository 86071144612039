import React from "react";

import PersonIcon from "@material-ui/icons/Person";

function KorisnikIcon(props) {
  return (
    <PersonIcon {...props} />
  );
}

export default KorisnikIcon;