import { coreFields } from "./helpers/coreFields";
import { commonFields } from "./helpers/commonFields";
import { userFields } from "./helpers/userFields";

export default {
  title: "Korisnici",
  exportFileName: "CBC_PKorisnici",
  apiPath: "prijevoznici-korisnici/",
  forms: {
    insert: {
      fields: [
        "username",
        "first_name",
        "last_name",
        "password",
        "password_confirm",
        "email",
        "user_role"
      ]
    },
    update: {
      fields: ["username", "first_name", "last_name", "email", "user_role"]
    }
  },
  listViews: {
    default: {
      fields: ["id", "username", "email", "first_name", "last_name", "user_role", "created_on", "last_login_on"]
    },
    exportExcel: {
      fields: [
        { source: "username", width: 25 },
        { source: "email", width: 25 },
        { source: "first_name", width: 25 },
        { source: "last_name", width: 25 },
        { source: "created_on", width: 25 }
      ]
    }
  },
  fields: [
    coreFields.id,
    userFields.username,
    userFields.password,
    userFields.password_confirm,
    userFields.first_name,
    userFields.last_name,
    commonFields.oib,
    commonFields.email,
    {
      title: "Broj telefona",
      source: "telephone",
      ttoken: "users.telephone",
      type: "text",
      placeholder: "+385xxxxxxxxx",
      mask: false,
      validation: {
        required: true,
        maxLength: 20,
        regex: "tel"
      }
    },
    {
      title: "User role",
      source: "user_role",
      ttoken: "users.role",
      type: "picker",
      subModel: {
        apiPath: "prijevoznici/roles",
        format: "{label}"
      },
      validation: {
        required: true
      },
      filter: true
    },
    {
      title: "Napomena",
      source: "napomena",
      ttoken: "users.napomena",
      type: "multiline",
      validation: {
        maxLength: 250
      },
      width: "full"
    },
    coreFields.created_on,
    userFields.last_login_on
  ]
};
