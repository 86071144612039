import React, { useContext, useEffect, useState } from "react";

//Custom Components
import { GridContainer, GridItem } from "UI/Grid";
import UserContext from "Components/UserContext/UserContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import model from "Models/vozilaImages";
import dataController from "Lib/dataController";
import DialogContext from "UI/DialogContext/DialogContext";
import AddButton from "UI/Buttons/AddButton";
import ImageThumbnail from "UI/Image/ImageThumbnail";
import { vozilaService } from "Services/vozilaService";
import ImageUploadDialog from "UI/Dialog/ImageUploadDialog";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import { Permissions } from "Lib/permissions";

export default function TabPanelImages(props) {
  const {
    recordId,
    currentTab,
    tabIndex
  } = props;

  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);
  const dialogContext = useContext(DialogContext);

  const [records, setRecords] = useState([]);

  const path = `vozila/${recordId}/images`;
  const dc = new dataController(model, path);

  useEffect(() => {
    if (currentTab === tabIndex) {
      refreshRecords();
    }
  }, [currentTab]);

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData(path)
      .then(resp => {
        if (resp.success) {
          setRecords(resp.data || []);
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const handleCloseDialog = (result) => {
    if (result && result.dataChanged) {
      refreshRecords();
    }
    dialogContext.hideDialog();
  };

  const viewImage = (uuid) => {
    return vozilaService.getFullImage(recordId, uuid);
  };

  const deleteImage = (uuid) => {
    return vozilaService.deleteImage(recordId, uuid)
      .then(resp => {
        if (resp.success) {
          dialogContext.hideDialog();
          refreshRecords();
        }
      }).catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      });
  };

  const handleUploadButton = () => {
    dialogContext.showDialog(ImageUploadDialog, {
      dc: dc,
      baseRecordId: recordId,
      service: vozilaService,
      onClose: handleCloseDialog
    });
  };

  return (
    <GridContainer direction="row-reverse">
      <GridItem>
        <AddButton onClick={handleUploadButton} needPermission={[Permissions.AdminProfilesManage]} />
      </GridItem>
      <GridItem container direciton="row" alignItems="center" justify="space-evenly" spacing={1}>
        {records.length > 0 ? records.map((image, i) => (
          <GridItem key={"grid-item-" + i}>
            <ImageThumbnail
              thumbnailBase64={image.thumb}
              imageUUID={image.uuid}
              mimetype={image.image_mime_type}
              onView={viewImage}
              onDelete={deleteImage}
              recordId={recordId}
              title={image.file_name}
              onClose={handleCloseDialog}
            />
          </GridItem>
        )) : null}
      </GridItem>
    </GridContainer>
  );
}
