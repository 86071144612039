import React from 'react';
import { useTranslation } from "react-i18next";

//Custom components
import { GridContainer } from "UI/Grid";
import FormContent from "Components/FormContent";

function TabOsnovniPodaci(props) {
  const {record, validation, fields, onFieldChange, mode, subModels} = props;
  const {internal} = props;

  const {t} = useTranslation();

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels
  };

  const firstMode = mode === "view" ? mode : (record["posao_state_id"] > 20 ? "view" : "update");
  const secondMode = mode === "view" ? mode : (record["posao_state_id"] > 25 ? "view" : "update");

  return (
      <GridContainer>
        <FormContent
            fieldNames={["from_ts", "to_ts"]}
            {...commonProps}
            columns={2}
            mode={firstMode}
        />
        {internal ? (
            <FormContent
                fieldNames={["from_loc", "to_loc"]}
                {...commonProps}
                mode={firstMode}
                columns={2}
            />
        ) : null}
        <FormContent
            fieldNames={["relacija_opis_skraceni"]}
            {...commonProps}
            columns={1}
            mode={firstMode}
        />
        <FormContent
            fieldNames={record["is_interni_posao"] || internal ? ["kupac_id"] : ["narucitelj_id"]}
            {...commonProps}
            mode={firstMode}
            columns={2}
        />
        <FormContent
            fieldNames={record["is_interni_posao"] || internal ? ["kupac_kontakt"] : ["narucitelj_kontakt"]}
            {...commonProps}
            mode="update"
            columns={2}
        />
        <FormContent
            fieldNames={["vozilo_id", "vozac_id"]}
            {...commonProps}
            mode={secondMode}
            columns={2}
        />
        <FormContent
            fieldNames={["kooperant_id", "kooperant_napomena"]}
            {...commonProps}
            mode={secondMode}
            columns={2}
        />
        <FormContent
            fieldNames={["vozilo_broj_putnika", "vozilo_broj_vodica"]}
            mode={secondMode}
            {...commonProps}
            columns={2}
        />
        <FormContent
            fieldNames={["napomena_interna"]}
            {...commonProps}
            mode={secondMode}
            columns={1}
        />
      </GridContainer>
  );
}

export default TabOsnovniPodaci;
