import React, { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Paper, Popper } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import MenuList from "@material-ui/core/MenuList";

import { dateOptions } from "Lib/dateFilterOptions";
import CalendarControl from "UI/Calendar/CalendarControl";


export default function DateColumnFilter(props) {
    const { t } = useTranslation();
    const paperMenuRef = useRef();
    const calendarRef = useRef();
    const selectMenuRef = useRef();
    const allOptionRef = useRef();
    const customOptionRef = useRef();
    const {filterValue, onFilterChange} = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuHovered, setMenuHovered] = useState(null);
    const [selectOpen, setSelectOpen] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [customRecord, setCustomRecord] = useState([]);

    // Hovering over an option triggers this method which sets the anchor for an options sub options
    // {evt} = event parameter
    // {menu} = defines which option is being hovered by the mouse
    const handleOption = (evt, menu = null) => {
      if(menu == "custom" || menu == "all") {
        setAnchorEl(null);
      } else if(selectOpen) {
        setAnchorEl(evt.currentTarget);
        setMenuHovered(menu);
      }
    }

    // Function for when the targeted select is clicked
    // if option "all" is selected everything closes and filter changes
    // if option "custom" is selected then open calendarcontrol
    // if else check if click is inside the current ref, if not close select and popper
    const handleSelectOpen = (evt) => {
      if(evt.target.value === "" || evt.target.value === 0 && evt.target === allOptionRef.current) {
        onFilterChange("");
        setSelectOpen(false)
      } else if(evt.target.value === "custom" || evt.target === customOptionRef.current) {
        setPopoverOpen(true);
      } else if(evt.target.value || selectMenuRef.current.contains(evt.target)) {
        setSelectOpen(true)
      } else {
        setSelectOpen(false)
      }
    }

    // Function handles clicks on popper Menuitems
    const handleMenuItemClick = (value) => {
      onFilterChange(value || undefined)
      setCustomRecord([])
      setSelectOpen(false)
    }

    // Returns filterValue to the Select component through renderValue
    const getFilterValue = () => {
      if(filterValue) {
        if(filterValue instanceof Object) {
          return t("datefilter.custom")
        }
        return t("datefilter."+filterValue);
      }
      return t("common.all");
    }

    // Sets the popper anchor to null if select is not open
    useEffect(() => {
      if(!selectOpen) {
        setAnchorEl(null)
      }
    }, [selectOpen])

    // Closes the calendar while setting the filter and custom record 
    const commitCustomFilter = (rec) => {
        setPopoverOpen(false);
        setSelectOpen(false);
        onFilterChange(rec);
        setCustomRecord([{startDate: rec[0], endDate: rec[1]}])
    }

    // Selecting a range on the popover calendar triggers this
    const onRangeSelected = (evt) => {
      commitCustomFilter([evt.startDate, evt.endDate])
    }

    // Force close popover Calendar if clicked target has no class, meaning that the calendar has not been clicked
    const closeCalendar = (evt) => {
      if(!evt.target.attributes.class) {
        setPopoverOpen(false);
      }
    }

    return (
      <Fragment>
        <Select
            open={selectOpen}
            ref={selectMenuRef}
            renderValue={getFilterValue}
            variant="outlined"
            margin="dense"
            defaultValue=""
            displayEmpty
            MenuProps={{
              style: {zIndex: 0},
              getContentAnchorEl: null
            }}
            inputProps= {{
              value: filterValue
            }}
            onClick={handleSelectOpen}
        >
          <MenuItem ref={allOptionRef} onMouseEnter={(evt) => handleOption(evt, "all")} value="">{t("common.all")}</MenuItem>

          {dateOptions.map((menuItem, i) => 
            <MenuItem key={i} value={menuItem.title} onMouseEnter={(evt) => handleOption(evt, menuItem.title)}>
              {t(menuItem.title)}
            </MenuItem>
          )}
          <MenuItem ref={customOptionRef} value="custom" onMouseEnter={(evt) => handleOption(evt, "custom")} onClick={() => handleOption(null, "custom")}>
            {t("datefilter.custom")}
          </MenuItem>
        </Select>
        { dateOptions.map((menuItem, i) => 
          <Popper
            key={"date_col_filter-" + i}
            anchorEl={anchorEl}
            open={Boolean(anchorEl && menuHovered === menuItem.title)}
            placement={"right"}
            transition
          >
            <Paper ref={paperMenuRef} elevation={1} style={{borderBottomLeftRadius: "0px", borderTopLeftRadius: "0px"}}>
              <MenuList>
                {menuItem.options.map((option, o) => 
                  <MenuItem key={o} value={option.value} onClick={() => handleMenuItemClick(option.value)}>
                    {t(option.label)}
                  </MenuItem>
                )}
              </MenuList>
            </Paper>
          </Popper>
        )}
        <Popover
          anchorReference="anchorPosition"
          anchorPosition={{
            left: 250,
            top: 250,
          }}
          open={popoverOpen}
          onClick={closeCalendar}
        >
          <CalendarControl
            roundRangeLimits={true}
            enableRangeSelection={true}
            records={customRecord}
            onRangeSelected={onRangeSelected}
          />
        </Popover>
      </Fragment>
      )
}
