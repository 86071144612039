import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//Custom components
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import ModelTable from "UI/Table/ModelTable";
import FormContent from "Components/FormContent";
import dataController from "Lib/dataController";
import finCalcmodel from "Models/fincalc";
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import UserContext from "Components/UserContext/UserContext";
import FakturaDialog from "Views/Shared/FakturaDialog";
import FakturaStavkaDialog from "Views/Shared/FakturaStavkaDialog";
import modelFakture from "Models/fakture";
import { fakturaPrint } from "Lib/print/fakturaPrint";

function TabFaktura(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const loaderContext = useContext(LoaderContext);
  const userContext = useContext(UserContext);
  const { dc, recordId, record, validation, fields, onFieldChange, mode, subModels, isPanic } = props;
  const { currentTab, tabValue } = props;

  const [recordsFakture, setRecordsFakture] = useState([]);

  const [recordsStavke, setRecordsStavke] = useState([]);

  const { t } = useTranslation();

  const dcFakture = new dataController(modelFakture, `poslovi/${recordId}/fakture`);
  const dcFakturaStavke = new dataController(finCalcmodel);

    const isInterni = record.is_interni_posao;

  const handleAddFaktura = (evt) => {
    if (isInterni) {
      dialogContext.showDialog(FakturaDialog, {
        dc: dcFakture,
        mode: "insert",
        form: "ip",
        isInterni: isInterni,
        onClose: handleDialogClose,
        allowSkica: true,
        defaultValues: {
          posao_id: record.id,
          izdavatelj_bs_id: record.prijevoznik_id,
          kupac_komitent_id: record.kupac_id
        },
        subModelDefaultValues: {
          prijevoznik_id: record.prijevoznik_id
        }
      });
    } else {
      dialogContext.showDialog(FakturaDialog, {
        dc: dcFakture,
        mode: "insert",
        form: "pp",
        isInterni: isInterni,
        onClose: handleDialogClose,
        allowSkica: true,
        defaultValues: {
          posao_id: record.id,
          izdavatelj_bs_id: record.prijevoznik_id,
          kupac_bs_id: userContext.platformBSId
        }
      });
    }
  }

  const handleSelectFaktura = (id) => {
    if (id) {
      refreshStavke(id)
    } else {
      setRecordsStavke([]);
    }
  }

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dcFakture
      .GetData()
      .then((resp) => {
        if (resp.success) {
          if (resp.data) {
            setRecordsFakture(resp.data);
          }
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      })
  }

  const refreshStavke = (id) => {
    loaderContext.toggleLoading(true);
    const dcFakturaStavkeSpecific = new dataController(finCalcmodel, `poslovi/${recordId}/fakture/${id}/stavke`);
    dcFakturaStavkeSpecific
      .GetData()
      .then((resp) => {
        if (resp && resp.data && resp.success) {
          setRecordsStavke(resp.data);
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  useEffect(() => {
    if (currentTab === tabValue) {
      refreshRecords();
    }
  }, [currentTab]);

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords();
      if (result.id) {
        refreshStavke(result.id);
      }
    }
    dialogContext.hideDialog();
  };

  const handleRowActionFaktura = (action, id) => {
    if (action === "view") {
      dialogContext.showDialog(FakturaDialog, {
        dc: dcFakture,
        mode: "view",
        isInterni: isInterni,
        form: isInterni ? "ip" : "pp",
        onClose: handleDialogClose,
        allowSkica: true,
        recordId: id,
        stavkeApiPath: `poslovi/${recordId}/fakture/${id}/stavke`,
        subModelDefaultValues: isInterni ? {
          prijevoznik_id: record.prijevoznik_id
        } : undefined
      });
    } else if (action === "edit") {
      dialogContext.showDialog(FakturaDialog, {
        dc: dcFakture,
        mode: "update",
        isInterni: isInterni,
        form: isInterni ? "ip" : "pp",
        onClose: handleDialogClose,
        allowSkica: true,
        recordId: id,
        stavkeApiPath: `poslovi/${recordId}/fakture/${id}/stavke`,
        subModelDefaultValues: isInterni ? {
          prijevoznik_id: record.prijevoznik_id
        } : undefined
      });
    } else if (action === "print") {
      const isPlatformskiPosao = isInterni ? false : true;
      const rec = {
        id: id,
        posao_id: record.id
      }
      fakturaPrint.handlePrint(isPlatformskiPosao, rec, t);
    }
  }

  const allowRowActionFaktura = (action, id) => {
    if (action === "edit") {
      const rec = recordsFakture.find(x => x.id === id);
      if (rec.faktura_state_id === 10) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  const handleRowAction = function (action, id) {
    if (action === "edit" || action === "view") {
      dialogContext.showDialog(FakturaStavkaDialog, {
        dc: dcFakturaStavke,
        mode: record.posao_state_id === 29 || isPanic ? "view" : "update",
        form: "pp",
        recordId: id,
        onClose: handleDialogClose
      });
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <ModelTable
          title={t("titles.fakture")}
          dc={dcFakture}
          viewName={"default"}
          allowExport={true}
          allowFilter={true}
          allowAdd={(isPanic || mode === "view") ? false: true}
          addLabel={t("buttons.new_f")}
          handleAdd={handleAddFaktura}
          allowSelection="one"
          onRowClick={handleSelectFaktura}
          records={Array.isArray(recordsFakture) ? recordsFakture : []}
          onRowAction={handleRowActionFaktura}
          allowRowAction={allowRowActionFaktura}
          subModelDefaultValues={{
            posao_id: recordId
          }}
        />
      </GridItem>
      <GridItem md={12} sm={12}>
        <ModelTable
          title={t("titles.faktura_stavke")}
          records={Array.isArray(recordsStavke) ? recordsStavke : []}
          dc={dcFakturaStavke}
          viewName={"readonly_noview"}
          allowExport={false}
          allowFilter={false}
          allowAdd={false}
          allowSelection={"none"}
          onRowAction={handleRowAction}
        />
      </GridItem>
    </GridContainer>
  );
}

export default TabFaktura;
