import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Custom Components
import ModelTable from "UI/Table/ModelTable";
import TableCard from "UI/Table/TableCard";
import TableCardContent from "UI/Table/TableCardContent";
import DialogContext from "UI/DialogContext/DialogContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import UserContext from "Components/UserContext/UserContext";
import UserDialog from "Views/Administration/UsersPage/UserDialog";
import withRedirect from "Components/withRedirect";
import model from "Models/user";
import dataController from "Lib/dataController";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

// TODO:
// subModels cleanup: they are added to the project because Table.jsx can not function without them

function UsersTable(props) {
  const { state } = useLocation();
  const { t } = useTranslation();

  const userContext = useContext(UserContext);
  const dialogContext = useContext(DialogContext);
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);
  const viewName = "default";

  const [records, setRecords] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const { redirect } = props;

  const dc = new dataController(model);

  useEffect(() => {
    if (state) {
      const { active } = state;
      const identifier = dc.getSource() + viewName;
      userContext.setRecentFilters(identifier, 'active', active);
      userContext.setRecent("toggle_filter", identifier, true);
    }
    refreshRecords();
  }, []);

  const handleAdd = (evt) => {
    dialogContext.showDialog(UserDialog, {
      dc: dc,
      mode: "insert",
      form: "insert",
      onClose: handleDialogClose
    });
  };

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords();
    }
  };

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData()
      .then((resp) => {
        if (resp.success) {
          setRecords(resp.data);
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
        setLoaded(true);
      });
  };

  const handleRowAction = function(action, id) {
    if (action === "edit") {
      redirect(`/admin/users/${id}`);
    }
  };

  const allowRowAction = function(action, id) {
    return true;
  };

  return (
    <TableCard>
      <TableCardContent>
        { loaded &&
          <ModelTable
            title={t("titles.users")}
            records={records}
            dc={dc}
            allowSelection="none"
            allowFilter={true}
            allowExport={true}
            allowAdd={true}
            addLabel={t("buttons.new_m")}
            handleAdd={handleAdd}
            viewName={viewName}
            onRowAction={handleRowAction}
            allowRowAction={allowRowAction}
            onDoubleClick={(id) => handleRowAction("edit", id)}
            handleRefresh={refreshRecords}
          />
        }
      </TableCardContent>
    </TableCard>
  );
}

export default withRedirect(UsersTable);
