import React, { Fragment } from 'react';

// Material-UI Icons
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

// Material-UI Core Components
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import {
	Drawer
, Hidden
, IconButton
} from "@material-ui/core";

function KomunikatorSingleSidebar(props) {
  const { sidebarOpen, setSidebarOpen, tab } = props;
  const { width } = props; // HOC withWidth
  const classes = useStyles();
  const isSidebarOpen = isWidthUp('md', width) || sidebarOpen;
  //console.log(isWidthUp('md', props.width))
	return (
    <Fragment>
    	<Drawer
        className={tab ? null : classes.drawer}
        variant="persistent"
        open={isSidebarOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
    	>
    		{ props.children }
    	</Drawer>
      {
        !isSidebarOpen ?
        <Drawer
          variant="permanent"
          className={`${classes.drawer} ${classes.hoverDrawer}`}
          classes={{
            paper: classes.hoverDrawerPaper
          }}
        >
          <IconButton
            style={{
              height: "100%",
              width: "100%",
              padding: 0
            }}
            onClick={() => setSidebarOpen(true)}
          >
            <ChevronRightIcon />
          </IconButton>
        </Drawer>
        : null
      }
    </Fragment>
	);
}

const drawerWidth = 400;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    zIndex: 1,
    width: drawerWidth,
    flexShrink: 0,
    position: "fixed",
    height: "100%"
  },
  drawerPaper: {
    width: drawerWidth,
    position: "absolute",
    height: "100%"
  },
  // necessary for content to be below app bar
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  hoverDrawer: {
    zIndex: 1,
    width: "25px",
    backgroundColor: "#fff"
  },
  hoverDrawerPaper: {
    width: "25px",
    position: "absolute",
    height: "100%",
    justifyContent: "center",
  }
}));

export default withWidth()(KomunikatorSingleSidebar);
