import React from "react";
import { grey, orange, blue } from "@material-ui/core/colors";

//Material UI Core
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

import AppPage from "UI/AppPage/AppPage";
import menuPrijevoznici from "Routes/menuPrijevoznici";

import DialogProvider from "UI/DialogContext/DialogProvider";
import DialogContainer from "UI/DialogContext/DialogContainer";

import { Permissions } from "Lib/permissions";

const moduleTheme = createMuiTheme({
  palette: {
    primary: {
      main: grey[800],
      dark: grey[900],
      light: grey[400]
    },
    secondary: {
      main: blue[800],
      dark: blue[900],
      light: blue[700]
    }
  }
});

function PrijevozniciPage(props) {
  return (
    <MuiThemeProvider
      theme={(outerTheme) => ({
        ...outerTheme,
        palette: {
          ...outerTheme.palette,
          primary: moduleTheme.palette.primary,
          secondary: moduleTheme.palette.secondary
        }
      })}
    >
      <DialogProvider>
        <AppPage
          menu={menuPrijevoznici}
          modul="prijevoznici"
          needPermission={[Permissions.PrijevozniciSve, Permissions.PrijevozniciOsnovno]}
        >
          {props.children}
        </AppPage>
        <DialogContainer />
      </DialogProvider>
    </MuiThemeProvider>
  );
}

export default PrijevozniciPage;
