import { coreFields } from "./helpers/coreFields";

export default {
  title: "Evaluacija Naručitelja",
  exportFileName: "Evaluacija narucitelja",
  source: "evaluacija",
  apiPath: "evaluacije/",
  forms: {
    default: {
      fields: [
        "ocjena_ukupno",
        "grupa_cistoca_i_urednost",
        "grupa_tocnost",
        "grupa_nagrada",
        "osoblje_informacije",
        "osoblje_organizacija",
        "osoblje_dostupnost",
        "prateci_priprema",
        "prateci_komunikacija",
        "prateci_agilnost"
      ]
    }
  },
  listViews: {
    default: {
      fields: ["id", "created_by", "created_on", "napomena", "ocjena_ukupno"]
    }
  },
  fields: [
    coreFields.id,
    coreFields.created_by,
    coreFields.created_on,
    {
      title: "napomena",
      source: "napomena",
      ttoken: "evaluacija.napomena",
      type: "text"
    },
    {
      title: "total",
      source: "ocjena_ukupno",
      ttoken: "evaluacija.total",
      type: "rating",
      max: 10,
      precision: 0.5,
      validation: {
        required: true
      }
    },
    {
      title: "grupa_cistoca_i_urednost",
      source: "grupa_cistoca_i_urednost",
      ttoken: "evaluacija.grupa_cistoca_i_urednost",
      type: "rating",
      max: 10,
      validation: {
        required: true
      }
    },
    {
      title: "grupa_tocnost",
      source: "grupa_tocnost",
      ttoken: "evaluacija.grupa_tocnost",
      type: "rating",
      max: 10,
      validation: {
        required: true
      }
    },
    {
      title: "grupa_nagrada",
      source: "grupa_nagrada",
      ttoken: "evaluacija.grupa_nagrada",
      type: "rating",
      max: 10,
      validation: {
        required: true
      }
    },
    {
      title: "osoblje_informacije",
      source: "osoblje_informacije",
      ttoken: "evaluacija.u_osoblje_informacije",
      type: "rating",
      max: 10,
      validation: {
        required: true
      }
    },
    {
      title: "osoblje_organizacija",
      source: "osoblje_organizacija",
      ttoken: "evaluacija.u_osoblje_organizacija",
      type: "rating",
      max: 10,
      validation: {
        required: true
      }
    },
    {
      title: "osoblje_dostupnost",
      source: "osoblje_dostupnost",
      ttoken: "evaluacija.u_osoblje_dostupnost",
      type: "rating",
      max: 10,
      validation: {
        required: true
      }
    },
    {
      title: "prateci_priprema",
      source: "prateci_priprema",
      ttoken: "evaluacija.prateci_priprema",
      type: "rating",
      max: 10,
      validation: {
        required: true
      }
    },
    {
      title: "prateci_komunikacija",
      source: "prateci_komunikacija",
      ttoken: "evaluacija.prateci_komunikacija",
      type: "rating",
      max: 10,
      validation: {
        required: true
      }
    },
    {
      title: "prateci_agilnost",
      source: "prateci_agilnost",
      ttoken: "evaluacija.prateci_agilnost",
      type: "rating",
      max: 10,
      validation: {
        required: true
      }
    }
  ]
};
