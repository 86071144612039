import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Material-UI Icons
import CloseIcon from "@material-ui/icons/Close";
import MinimizeIcon from '@material-ui/icons/Minimize';
import SettingsIcon from '@material-ui/icons/Settings';

// Material-UI Core Components
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import IconButton from "@material-ui/core/IconButton";
import Popover from '@material-ui/core/Popover';

// Custom Components
import ChatContent from "UI/Chat/ChatContent";
import ChatInput from "UI/Chat/ChatInput";
import KomunikatorSettings from "UI/Komunikator/KomunikatorSettings";

import { komunikatorService } from "Services/komunikatorService";
import KomunikatorContext from "Components/KomunikatorContext/KomunikatorContext";
import { sifrarnici } from "Lib/sifrarnici";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import KomunikatorFavButton from "UI/Komunikator/KomunikatorFavButton";

function ChatWindow (props) {
	const { t } = useTranslation()
	const { channelId, onClose, onMinimize } = props;
	const classes = useStyles();
	const KC = useContext(KomunikatorContext);
	const snackbarContext = useContext(SnackbarContext);

	const [anchorEl, setAnchorEl] = useState(null);
	const [value, setValue] = useState("");

	const [channel, setChannel] = useState(null);
	const [img, setImg] = useState(null);
	const [file, setFile] = useState(null);

	useEffect(() => {
		refreshChannel();
	}, []);

	useEffect(() => {
		KC.getMessages(channelId);
	}, [channelId]);

	const refreshChannel = () => {
		return komunikatorService.getChannelData(channelId).then(resp => {
			if (resp.success && resp.data && resp.data.length) {
				setChannel(resp.data[0] || {});
			}
		}).catch((result) => {
			snackbarContext.showNotification(result.error, "error");
		});		
	}

	const handleChange = (evt) => {
		const value = evt.currentTarget.value;
		setValue(value && value.length === 1 ? value.trim() : value);
	}

	const handleSetFile = (newFile) => {
		setFile(newFile);
	}

	const handleSetImg = (newImg) => {
		setImg(newImg);
	}

	const getOlderMessages = () => {
		KC.getOlderMessages(channel.id);
	}

	const handleMinimize = (evt, channel) => {
		evt.preventDefault();
		evt.stopPropagation();
		onMinimize(channel);
	}

	const handleClose = (evt, channel) => {
		evt.preventDefault();
		evt.stopPropagation();
		onClose(channel);
	}

	const handleSettings = (evt) => {
		// no need to prevent default since the event should bubble to set focus to this channel
		setAnchorEl(evt.currentTarget);
	}

	const handleCloseMenu = () => {
		setAnchorEl(null);
	}

	const sendMessage = () => {
		if (file || img) {
			KC.sendMessage({
				message_body: value || value.trim(),
				channel_id: channel.id,
				message_type_id: sifrarnici.msg_types.file,
				message_delivery_type_id: sifrarnici.msg_delivery_types.queued,
				file: file ? JSON.stringify(file) : null,
				img: img ? JSON.stringify(img) : null
			})
		} else if (value && value.trim().length) {
			KC.sendMessage({
				message_body: value.trim(),
				channel_id: channel.id,
				message_type_id: sifrarnici.msg_types.standard,
				message_delivery_type_id: sifrarnici.msg_delivery_types.queued
			});

		}
		setValue("");
		setImg(null);
		setFile(null);
	}
	const isReadOnly = Boolean(channel && (channel.panic && channel.channel_type_id === sifrarnici.channel_types.main 
													|| channel.channel_state_id === sifrarnici.channel_states.blocked));

	return (
		channel ?
		<ClickAwayListener onClickAway={() => {/*KC.setFocused(null)*/}}>
			<Card
				className={classes.fullWidth}
				style={{ paddingTop: 0, width: "320px", marginRight: "10px", pointerEvents: "all" }}
				onClick={() => KC.addFocused(channelId)}
			>
				<CardHeader
					// component={Paper}
					className={classes.cardHeader}
					avatar={
						<Badge
							anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
							color="secondary"
							variant="dot"
							badgeContent={Number(channel.unread)}
							overlap="circle"
						>
							{
								channel.img ?
								<img src={channel.img}/>
								:
								<Avatar className={classes.small} style={{backgroundColor: channel.color}}>
									{t(channel.title)[0]}
								</Avatar>
							}
						</Badge>
					}
					title={t(channel.title)}
					m={1}
					action={
						<Fragment>
              <KomunikatorFavButton channelId={channel.id} />
							<IconButton size="small" disabled={!Boolean(channel) || Boolean(channel.panic)} onClick={(evt) => handleSettings(evt, channel)}>
								<SettingsIcon />
							</IconButton>
							<IconButton size="small" disabled={!Boolean(channel)} onClick={(evt) => handleMinimize(evt, channel)}>
								<MinimizeIcon />
							</IconButton>
							<IconButton size="small" disabled={!Boolean(channel)} onClick={(evt) => handleClose(evt, channel)}>
								<CloseIcon />
							</IconButton>
						</Fragment>
					}
				/>
				<CardContent  className={classes.cardContent}>
					<ChatContent
						// messages={KC.messagesPerConversation[channelId]}
						channelUsers={channel.users}
						messages={KC[channel.id]}
						getOlderMessages={KC.EOM[channel.id] ? () => {} : getOlderMessages}
						channelId={channel.id}
						isReadOnly={isReadOnly}
					/>
					<ChatInput
						channelId={channel.id}
						jobId={channel.posao_id || 0}
						msgInput={value}
						handleChange={handleChange}
						handleSetFile={handleSetFile}
						handleSetImg={handleSetImg}
						sendMessage={sendMessage}
						isReadOnly={isReadOnly}
					/>
				</CardContent>
	      <Popover
	        open={Boolean(anchorEl)}
	        anchorEl={anchorEl}
	        onClose={handleCloseMenu}
	        anchorOrigin={{
	          vertical: 'bottom',
	          horizontal: 'center',
	        }}
	        transformOrigin={{
	          vertical: 'top',
	          horizontal: 'center',
	        }}
	      >
	      	<KomunikatorSettings
	      		channel={channel}
	      		onClose={(data) => {
	      			handleCloseMenu();
	      			if (data.dataChanged) {
	      				refreshChannel().then(() => {
									KC.checkConnection();
	      				});
	      			}
	      		}}
	      	/>
	      </Popover>
			</Card>
		</ClickAwayListener>
		: null
	);
}

export default ChatWindow;

const useStyles = makeStyles((theme) => ({
	cardHeader: {
		borderBottom: "1px solid " + theme.palette.secondary.main,
		padding: theme.spacing(1)
	},
	cardContent: {
		padding: theme.spacing(1),
		paddingBottom: theme.spacing(1) + "px !important",
		paddingTop: 0,
	},
	small: {
		width: theme.spacing(3),
		height: theme.spacing(3),
	},
	fullWidth: {
		minWidth: "320px"
	}
}));
