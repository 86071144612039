import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

//Material UI
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";

//Custom components
import { GridContainer, GridItem } from "UI/Grid";

function IconView(props) {
  const { t } = useTranslation();
  const { icon, value, title, suffix } = props;

  return (
    <GridContainer>
      {icon ? (
        <GridItem>
          <Icon color="secondary">{icon}</Icon>
        </GridItem>
      ) : null}
      <GridItem>
        <Typography>
          {t(title)}
          {value ? ":" : ""}
        </Typography>
      </GridItem>
      {value ? (
        <GridItem>
          <Typography>
            <b>
              {value}
              {suffix ? suffix : ""}{" "}
            </b>
          </Typography>
        </GridItem>
      ) : null}
    </GridContainer>
  );
}

IconView.propTypes = {
  icon: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string
};

export default IconView;
