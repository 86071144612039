import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//Material-UI Icons
import TranslateIcon from "@material-ui/icons/Translate";

//Custom Components
import HeaderMenuButton from "UI/Header/HeaderMenuButton";
import HeaderMenuItem from "UI/Header/HeaderMenuItem";

function LanguageSelector() {
  const { i18n } = useTranslation();

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <HeaderMenuButton text={i18n.language} startIcon={ mdUp ? <TranslateIcon /> : null} showExpandIcon={ mdUp ? true : false} desc="language">
      <HeaderMenuItem
        label="Hrvatski"
        onClick={() => {
          i18n.changeLanguage("hr");
        }}
      />
      <HeaderMenuItem
        label="English"
        onClick={() => {
          i18n.changeLanguage("en");
        }}
      />
    </HeaderMenuButton>
  );
}

export default LanguageSelector;
