import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Custom Components
import UserContext from "Components/UserContext/UserContext";
import ModelTable from "UI/Table/ModelTable";
import TableCard from "UI/Table/TableCard";
import TableCardContent from "UI/Table/TableCardContent";
import DialogContext from "UI/DialogContext/DialogContext";

import model from "Models/upiti";
import dataController from "Lib/dataController";
import withRedirect from "Components/withRedirect";

// TODO:
// subModels cleanup: they are added to the project because Table.jsx can not function without them

function DirektniUpitiTable(props) {
  const dialogContext = useContext(DialogContext);
  const userContext = useContext(UserContext);
  const { t } = useTranslation();

  const [records, setRecords ] = useState([]);

  const { redirect } = props;

  const dc = new dataController(model, "direktni-upiti/");

  useEffect(() => {
    refreshRecords();
  }, []);

  const handleView = (evt, sel) => {
    const selId = parseInt(sel[0], 10);
    const record = records[selId];
    const id = record.id;

    redirect(`/p/direktni-upiti/${id}`);
  };

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords();
    }
    dialogContext.hideDialog();
  };

  const refreshRecords = () => {
    // dummyRecords();
    dc.GetData().then((resp) => {
      if (resp.success) {
        setRecords(resp.data)
      };
    });
  };

  const handleRowAction = function(action, id) {
    if (action === "view") {
      const rec = records.find(x => x.id === id);
      if (rec) {
        if (rec.posao_id) {
          redirect(`/p/voznje/${rec.posao_id}`);
        } else if (rec.ponuda_id) {
          redirect(`/p/ponude/${rec.ponuda_id}`)
        } else {
          redirect(`/p/direktni-upiti/${id}`);
        }
      }
    } else if (action === "view_pp") {
      const rec = records.find(x => x.id === id);
      if (rec && rec.posebna_ponuda_id) {
        redirect(`/p/posebne-ponude/${rec.posebna_ponuda_id}`);
      }
    }
  }

  const allowRowAction = function(action, id) {
    if (action === "view_pp") {
      const rec = records.find(x => x.id === id);
      if (rec && rec.posebna_ponuda_id) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  return (
    <TableCard>
      <TableCardContent>
        <ModelTable
          title={t("titles.prijevoznik_direktni_upiti")}
          records={records}
          dc={dc}
          viewName={"direktni_upiti"}
          allowExport={true}
          allowFilter={true}
          allowAdd={false}
          addLabel={t("buttons.new_m")}
          allowSelection={"none"}
          subModelDefaultValues={{
            prijevoznik_id: userContext.subjectId
          }}
          onRowAction={handleRowAction}
          allowRowAction={allowRowAction}
          onDoubleClick={(id) => handleRowAction("view", id)}
          handleRefresh={refreshRecords}
        />
      </TableCardContent>
    </TableCard>
  );
}

export default withRedirect(DirektniUpitiTable);
