let hereApi = function() {};

const apiKey = process.env.REACT_APP_HERE_APIKEY;

hereApi.prototype.Call = function(path) {

  const url = path + "&apiKey=" + apiKey;

  return fetch(url, {
    method: "GET"
  })
    .then(response => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return response.text()
    })
    .then(text => {
      const data = text && JSON.parse(text);
      return data;
    })
    .then(jsondata => {
      return { success: true, data: jsondata };
    })
    .catch(error => {
      return { success: false, error: error };
    })
    .finally(() => {});
};

export default hereApi;