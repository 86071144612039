import React, { useContext } from 'react'
import { useTranslation } from "react-i18next";

//Custom Components
import confirm from "Lib/confirmDialog";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

import { adminService } from "Services/adminService";

function usePanic(props) {

  const snackbarContext = useContext(SnackbarContext);

  const { t } = useTranslation();

  const { record, doRefresh } = props;

  const conifrmResolvePanic = () => {
    const title = t("cdialogs.panic_resolve.title");
    const text = t("cdialogs.panic_resolve.text");
    const type = "question";
    const confirmButtonText = t("buttons.confirm");
    const cancelButtonText = t("buttons.cancel");

    return confirm(title, text, type, confirmButtonText, cancelButtonText);
  };

  const handleResolvePanic = () => {

    conifrmResolvePanic().then((result) => {
      if (result.confirmed) {
        handleSubmit();
      }
     });
  }

  const handleSubmit = () => {
    const data = {}

    adminService
      .resolvePanic(record.id, data)
      .then((resp) => {
        if (resp.success) {
          snackbarContext.showNotification("action", "success");
          if (doRefresh) {
            doRefresh();
          }
        }
      })
      .catch((resp) => {
        snackbarContext.showNotification(resp.error.message, "error");
      });

  }

  return {
    handleResolvePanic
  }
}

export default usePanic