import { coreFields } from "./helpers/coreFields";
import { commonFields } from "./helpers/commonFields";
import { userFields } from "./helpers/userFields";
import { addressFields } from "./helpers/addressFields";
import { buttonFields } from "./helpers/buttonFields";


export default {
  title: "Vozac",
  exportFileName: "Vozaci",
  apiPath: "",
  forms: {
    default: {
      fields: [
        "username", "tag", "first_name", "last_name", "password", "password_confirm", "email",
        "oib", "mobitel", "telefon", "adr_pbr", "adr_ulica", "adr_grad", "adr_drzava", "datum_rodjenja", "zdravstveno_do", "napomena", "nezgoda_kontakt"
      ]
    },
    insert: {
      fields: [
        "username", "tag", "first_name", "last_name", "password", "password_confirm", "email",
        "oib", "mobitel", "telefon", "adr_pbr", "adr_ulica", "adr_grad", "adr_drzava", "datum_rodjenja", "zdravstveno_do", "napomena", "nezgoda_kontakt"
      ],
      validator: "validatePasswordSet"
    },
    update: {
      fields: [
        {"source": "username", "readonly": true}, "tag", "first_name", "last_name", "email",
        "oib", "mobitel", "telefon", "adr_pbr", "adr_ulica", "adr_grad", "adr_drzava", "datum_rodjenja", "zdravstveno_do", "napomena", "nezgoda_kontakt"
      ]
    }
  },
  listViews: {
    default: {
      fields: [
        "id", "username", "first_name", "last_name", "oib", "mobitel", "tag", "adr_ulica", "adr_pbr", "adr_grad", "adr_drzava", "edit"
      ],
      hidden: ["id"]
    }
  },
  fields: [
    coreFields.id,
    userFields.username,
    userFields.password,
    userFields.password_confirm,
    userFields.first_name,
    userFields.last_name,
    commonFields.email,
    {
      title: "Tag",
      source: "tag",
      type: "text",
      ttoken: "vozaci.tag",
      filter: true,
      validation: {
        required: true,
        maxLength: 15
      }
    },
    commonFields.oib,
    {
      title: "Broj mobitela",
      source: "mobitel",
      type: "text",
      ttoken: "vozaci.mobitel",
      validation: {
        maxLength: 25
      }
    },
    {
      title: "Broj telefone",
      source: "telefon",
      type: "text",
      ttoken: "vozaci.telefon",
      validation: {
        maxLength: 25
      }
    },
    addressFields.adr_ulica,
    addressFields.adr_grad,
    addressFields.adr_pbr,
    addressFields.adr_drzava,
    {
      title: "Datum rođenje",
      source: "datum_rodjenja",
      type: "date",
      ttoken: "vozaci.datum_rodjenja"
    },
    {
      title: "Datum zadnjeg zdravstvenog pregleda",
      source: "zdravstveno_do",
      type: "date",
      ttoken: "vozaci.zdravstveno_do"
    },
    {
      title: "Napomena",
      source: "napomena",
      type: "multiline",
      ttoken: "vozaci.napomena",
      validation: {
        maxLength: 500
      }
    },
    {
      title: "Kontakt broj u slučaju nezgode",
      source: "nezgoda_kontakt",
      type: "text",
      ttoken: "vozaci.nezgoda_kontakt"
    },
    buttonFields.edit
  ]
}
