import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Map, Marker, Polyline, GoogleApiWrapper } from "google-maps-react";

function GoogleMap(props) {
  const { google } = props; //HOC GoogleApiWrapper

  const { markers, routes, height, allowInteraction, zoomIn } = props;

  const [bounds, setBounds] = useState(null);

  const markersHash = Array.isArray(markers) ? markers.map((x) => x.id).join("#") : null;
  const routesHash = Array.isArray(routes) ? routes.map((x) => x.id).join("#") : null;

  useEffect(() => {
    const markersCount = Array.isArray(markers) ? markers.length : 0;
    const routesCount = Array.isArray(routes) ? routes.length : 0;

    const markerCoords = markersCount > 0 ? markers.map((x) => x.coords) : [];
    const routesCoords = routesCount > 0 ? routes.map((x) => x.points).flat(): [];

    const allCoords = markerCoords.concat(routesCoords);

    const allLat = allCoords.map((x) => x.lat);
    const allLng = allCoords.map((x) => x.lng);

    const minLat = Math.min(...allLat);
    const maxLat = Math.max(...allLat);
    const minLng = Math.min(...allLng);
    const maxLng = Math.max(...allLng);

    if (markersCount === 0 && routesCount === 0) {
    } else if (markersCount > 0) {
      const points = [
        { lat: minLat, lng: minLng },
        { lat: minLat, lng: maxLng },
        { lat: maxLat, lng: maxLng },
        { lat: maxLat, lng: minLng }
      ];
      const b = new window.google.maps.LatLngBounds();
      for (let i = 0; i < points.length; i++) {
        b.extend(points[i]);
      }
      setBounds(b);
    }
  }, [markersHash, routesHash]);

  const containerStyle = {
    position: "relative",
    width: "100%",
    height: height + "px"
  };

  return (
    <Map
      google={google}
      zoom={zoomIn ? 10 : 7}
      initialCenter={{
        lat: 45,
        lng: 15
      }}
      draggable={allowInteraction}
      containerStyle={containerStyle}
      mapTypeControl={true}
      bounds={bounds}
    >
      {Array.isArray(markers) && markers.length > 0
        ? markers.map((m,i) => {
            return <Marker id={m.id} name={m.label} position={m.coords} key={`marker-${i}`}/>;
          })
        : null}
      {Array.isArray(routes) && routes.length > 0
        ? routes.map((r,i) => {
            return <Polyline path={r.points} strokeColor="#4D78AE" strokeOpacity={0.8} strokeWeight={6} key={`route-${i}`}/>;
          })
        : null}
    </Map>
  );
}

GoogleMap.defaultProps = {
  allowInteraction: true,
  height: 500
};

GoogleMap.propTypes = {
  markers: PropTypes.array,
  routes: PropTypes.array,
  allowInteraction: PropTypes.bool,
  height: PropTypes.number
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_APIKEY
})(GoogleMap);
