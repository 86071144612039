import { helpers } from "Lib/helpers";

export const localStorageAPI = {
  setValue
, getValue
, setValueEnc
, getValueEnc
, getAllValues
, removeValueEnc
, getSecret
, setSecret
, clearStorage
};

const secret = "uRFZIbtZwfLg";
const xorEncrypt = 0b11011011110101;

function setValue(key, page, newValue) {
  // .getItem returns null if item is not find in localStorage
  // JSON.parse will return null if null is passed to it
  const pageHash = helpers.hash(page);
  const value = JSON.parse(localStorage.getItem(key));
  if (value === null || Object.keys(value).length === 0) {
    localStorage.setItem(key, JSON.stringify({ [pageHash]: newValue }));
  } else {
    value[pageHash] = newValue;
    localStorage.setItem(key, JSON.stringify(value));
  }
}

function getValue(key, page, defaultValue = 0) {
  // .getItem returns null if item is not find in localStorage
  // JSON.parse will return null if null is passed to it
  const pageHash = helpers.hash(page);
  const value = JSON.parse(localStorage.getItem(key));
  if (value === null || Object.keys(value).length === 0 || value[pageHash] === null || value[pageHash] === undefined) {
    setValue(key, page, defaultValue);
    return defaultValue;
  } else {
    return value[pageHash];
  }
}

function removeValueEnc(key, id) {
  const enc = id ^ xorEncrypt;
  const value = JSON.parse(localStorage.getItem(key));
  if (value === null || Object.keys(value).length === 0) {
    return;
  } else {
    delete value[enc];
    localStorage.setItem(key, JSON.stringify(value));
  }
}

function setValueEnc(key, id, newValue) {
  const enc = id ^ xorEncrypt;
  const value = JSON.parse(localStorage.getItem(key));
  if (value === null || Object.keys(value).length === 0) {
    localStorage.setItem(key, JSON.stringify({ [enc]: newValue }));
  } else {
    value[enc] = newValue;
    localStorage.setItem(key, JSON.stringify(value));
  } 
}

function getValueEnc(key, id, defaultValue = 0) {
  // .getItem returns null if item is not find in localStorage
  // JSON.parse will return null if null is passed to it
  const enc = id ^ xorEncrypt;
  const value = JSON.parse(localStorage.getItem(key));
  if (value === null || Object.keys(value).length === 0 || value[enc] === null || value[enc] === undefined) {
    setValue(key, id, defaultValue);
    return defaultValue;
  } else {
    return value[enc];
  }
}

function getAllValues(key) {
  const value = JSON.parse(localStorage.getItem(key));
  if (value === null) {
    return null;
  } else {
    const retVal = {};
    Object.keys(value).forEach(k => {
      retVal[k^xorEncrypt] = value[k];
    });
    return retVal;
  }
}

function getSecret(key) {
  const value = JSON.parse(localStorage.getItem(key));
  return value ? (value[secret] ^ xorEncrypt) : null
}

function setSecret(key, secretValue) {
  const value = JSON.parse(localStorage.getItem(key));

  localStorage.setItem(key, JSON.stringify(
    value ?
      {...value, [secret]: secretValue ^ xorEncrypt }
    : { [secret]: secretValue ^ xorEncrypt }));
}

function clearStorage(key) {
  localStorage.removeItem(key);
}