import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";

//Custom Components
import withFormController from "Components/withFormController";
import DynamicDialog from "UI/Dialog/DynamicDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogContext from "UI/DialogContext/DialogContext";
import FormContent from "Components/FormContent";
import GridContainer from "UI/Grid/GridContainer";

function AdminPojedinacnaVoznjeDialog(props) {
  const { t } = useTranslation();

  const dialogContext = useContext(DialogContext);

  const { dc, record, validation, fields, subModels, mode, form } = props; //HOC withFormController

  const handleClose = (evt) => {
    dialogContext.hideDialog();
  };

  return (
    <DynamicDialog onClose={handleClose} maxWidth={"md"}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogAvatar icon={<AirportShuttleIcon />} />
          <DialogToolbarHeading>{t("titles.pojedinacna_voznja")}</DialogToolbarHeading>
          <DialogToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer>
            <FormContent
              dc={dc}
              record={record}
              validation={validation}
              form={form}
              fields={fields}
              mode={mode}
              subModels={subModels}
              columns={2}
              fieldNames={[
                "opis",
                "ts_from",
                "ts_to",
                "vozilo_naziv",
                "vozac_1_ime_i_prezime",
                "vozac_2_ime_i_prezime",
                "vozac_3_ime_i_prezime",
                "neto_km",
                "neto_min",
                "kooperant_naziv",
                "napomena"
              ]}
            />
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonClose variant="contained" onClick={handleClose} />
      </DialogActions>
    </DynamicDialog>
  );
}

export default withFormController(AdminPojedinacnaVoznjeDialog);
