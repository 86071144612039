import LoginPage from "Views/Auth/LoginPage";
import PasswordResetRequestPage from "Views/Auth/PasswordResetRequestPage";
import PasswordResetPage from "Views/Auth/PasswordResetPage"

const publicRoutes = [
	{ path: "/login", name: "Login", component: LoginPage },
  { path: "/password-reset-request", name: "Password Reset Request", component: PasswordResetRequestPage },
  { path: "/password-reset", name: "Reset Password", component: PasswordResetPage }
];

export default publicRoutes
