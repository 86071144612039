import React from 'react'
import PropTypes from 'prop-types';

//Material-UI Core Components
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';

//Custom Components
import UserConsumer from "Components/UserContext/UserConsumer";

const customButtonStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.black,
    marginLeft: 10,
    marginRight: 0
  }
}));


function TableButton(props) {
  const classes = customButtonStyles();
  const { allow, selection, onClick, needPermission, isEnabledRecord, ...otherProps } = props;

  const isEnabled = function (allow, selection) {
    switch (allow) {
      case 'always':
        return true;
      case 'one':
        return selection.length === 1
      case 'many':
        return selection.length > 0;
      case 'none':
        return false;
      default:
        return false;
    }
  }

  const handleClick = function (evt) {
    onClick(evt, selection);
  }

  const disabled = !isEnabled(allow, selection) || (isEnabledRecord ? !isEnabledRecord(selection) : false) ;

  return (
    <UserConsumer>
      {(userContext) => (
        needPermission && !userContext.hasAnyPermission(needPermission) ? null :
        <Button classes={classes} color="secondary" size="small" {...otherProps} disabled={disabled} onClick={handleClick}>
          {props.children}
        </Button>
      )}
    </UserConsumer>
  );
}


TableButton.defaultProps = {
  allow: 'always',
  selection: []
}

TableButton.propTypes = {
  allow: PropTypes.oneOf(['always', 'one', 'many', 'none']),
  selection: PropTypes.array,
  needPermission: PropTypes.arrayOf(PropTypes.object)
}

export default TableButton;