import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import PropTypes from "prop-types";

//Material UI
import Toolbar from "@material-ui/core/Toolbar";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";

//Material-UI Icons
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PublicIcon from "@material-ui/icons/Public";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";

//Custom components
import withFormController from "Components/withFormController";
import FormContent from "Components/FormContent";
import { GridContainer, GridItem } from "UI/Grid";
import { CardActionButtonClose, CardHeading, CardToolbarButtonBack } from "UI/Card";
import { FormAvatar, FormFillContent, FormHeading, FormHeadingID, FormTab, FormTabPanel, FormTabs } from "UI/Form";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import withRedirect from "Components/withRedirect";
import AdminFinObveze from "./Tabs/AdminFinObveze";
import AdminFinPausalFakture from "./Tabs/AdminFinPausalFakture";
import EuroConversionComment from "Components/EuroConversionComment";

function FinancijeForm(props) {
  const { t } = useTranslation();
  const history = useHistory();

  const snackbarContext = useContext(SnackbarContext);

  const [currentTab, setCurrentTab] = useState(0);

  const { dc, record, recordId, validation, fields, subModels, dataChanged } = props; //HOC withFormController
  const { doUpdate, doClose } = props; //HOC withFormController
  const { onFieldChange } = props; //HOC withFormController
  const { redirect } = props;

  const path = `business-subjects/${recordId}/images`;

  const mode = record && record.active ? "update" : "view";

  const handleClickBack = () => {

    if (doClose) {
      doClose().then(resp => {
        if (resp.success) {
          history.goBack();
        } else if (resp.shouldsave) {
          if (doUpdate) {
            doUpdate().then((result) => {
              if (result.success) {
                snackbarContext.showNotification("update", "success");
                history.goBack();
              }
            }).catch((result) => {
              if (result.success === false && result.validationPass === false) {
                //do nothing
              } else {
                snackbarContext.showNotification(result.error, "error");
              }
            });
          }
        }
      }).catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      });
    }
  };

  const handleTabChange = (evt, value) => {
    setCurrentTab(value);
  };

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels
  };

  const title = record.naziv;

  return (
    <Card>
      <CardHeading>
        <Toolbar variant="dense" disableGutters={true}>
          <FormAvatar ariaLabel="poslovni_subjekt" icon={<AccountBalanceIcon />} />
          <FormHeading header={title} subheaderContent={<FormHeadingID id={recordId} />} dataChanged={dataChanged} />
          <FormFillContent />
          <CardToolbarButtonBack onClick={handleClickBack} />
        </Toolbar>
      </CardHeading>
      <CardContent style={{ paddingTop: 0 }}>
        <FormTabs value={currentTab} onChange={handleTabChange} identifier={dc.getSource()}>
          <FormTab id="finobv" label={t("bs.tabs.fin_obveze")} icon={<MonetizationOnIcon />} aria-label="financijske_obveze" />
          <FormTab id="pausal_obveze" label={t("bs.tabs.pausal_obveze")} icon={<PublicIcon />} aria-label="pausal_obveze" />
          <FormTab id="pausal_fakture" label={t("bs.tabs.pausal_fakture")} icon={<PublicIcon />} aria-label="pausal_fakture" />
        </FormTabs>
        <FormTabPanel value={currentTab} index={0}>
          <AdminFinObveze recordId={recordId} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={1}>
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={2}>
          <AdminFinPausalFakture recordId={recordId} />
        </FormTabPanel>
      </CardContent>
      <CardActions>
        <EuroConversionComment />
        <FormFillContent />
        <CardActionButtonClose variant="contained" onClick={handleClickBack} />
      </CardActions>
    </Card>
  );
}

FinancijeForm.propTypes = {
  dc: PropTypes.object,
  recordId: PropTypes.number,
  mode: PropTypes.string,
  form: PropTypes.string
};

export default withRedirect(withFormController(FinancijeForm));
