import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Icons
import DeleteIcon from "@material-ui/icons/Delete";

//Custom components
import DialogToolbarMenuItem from "UI/Dialog/DialogToolbarMenuItem";

const DialogToolbarMenuItemDelete = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return <DialogToolbarMenuItem label={t("buttons.delete")} icon={<DeleteIcon />} {...props} ref={ref} />;
});

export default DialogToolbarMenuItemDelete;
