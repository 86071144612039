import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import BusinessIcon from "@material-ui/icons/Business";

function PoslovniSubjektIcon(props) {
  return <BusinessIcon {...props}/>;
  // return (
  //   <SvgIcon {...props}>
  //     <path d="m 16,12 h -2 v 2 h 2 m 2,2 h -8 v -2 h 2 v -2 h -2 c -0.00469,-1.216161 -0.00469,-1.475027 -0.00469,-2 l 8.004706,1e-6 M 8,4 H 6 V 2 h 2 m 0,6 H 6 V 6 h 2 m 0,6.0000012 H 6.0000112 v -2.000001 h 2.0000002 m 0,6.000001 H 6.0000112 v -2 H 8.0000114 M 4.0000107,4.0000003 H 2.0000105 V 2.0000001 h 2.0000002 m 0,6.0000012 H 2.0000105 V 6.0000008 h 2.0000002 m 0,6.0000012 H 2.0000105 v -2.000001 h 2.0000002 m 0,6.000001 H 2.0000105 v -2 H 4.0000107 M 9.9953143,7.9930429 10.000001,0 3.7795276e-7,1.8897638e-6 0,18.000003 l 20,-2e-6 -1e-6,-10.0000012 z" />
  //   </SvgIcon>
  // );
}

export default PoslovniSubjektIcon;
