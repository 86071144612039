import { coreFields } from "./helpers/coreFields";
import { commonFields } from "./helpers/commonFields";
import { userFields } from "./helpers/userFields";
import { buttonFields } from "./helpers/buttonFields";

export default {
  title: "Users",
  exportFileName: "Users",
  apiPath: "users/",
  forms: {
    login: {
      fields: [
        {source: "username", focus: true},
        {source: "password", focus: true}
      ]
    },
    insert: {
      fields: [
        "username",
        "first_name",
        "last_name",
        "password",
        "password_confirm",
        "email",
        "tel",
        "remarks",
        "user_role",
        "user_permissions",
        "business_subject_id"
      ],
      validator: "validatePasswordSet"
    },
    insert_bs: {
      fields: [
        "username",
        "first_name",
        "last_name",
        "password",
        "password_confirm",
        "email",
        "tel",
        "remarks",
        "user_role",
        "user_permissions",
        { source: "business_subject_id", readonly: true }
      ]
    },
    update: {
      fields: [
        { source: "username", readonly: true },
        "first_name",
        "last_name",
        "email",
        "tel",
        "remarks",
        "user_role",
        "user_permissions",
        { source: "business_subject_id", readonly: false }
      ]
    },
    view: {
      fields: [
        { source: "username", readonly: true },
        { source: "first_name", readonly: true },
        { source: "last_name", readonly: true },
        { source: "email", readonly: true },
        { source: "remarks", readonly: true },
        { source: "user_role", readonly: true },
        { source: "user_permissions", readonly: true },
        { source: "business_subject_id", readonly: true }
      ]
    },
    password_reset_request: {
      fields: [{ source: "email", readonly: false }]
    },
    password_reset: {
      fields: ["email", "passwordreset_token", "password_new", "password_new_confirm"],
      validator: "validatePasswordReset"
    },
    password_change: {
      fields: [{ source: "password", ttoken: "users.password_current" }, "password_new", "password_new_confirm"],
      validator: "validatePasswordChange"
    },
    activate: {
      fields: ["email", "activation_token", "password", "password_new", "password_new_confirm"],
      validator: "validatePasswordChange"
    },
    search: {
      fields: ["full_name", { source: "email", readonly: false, validation: null }, { source: "oib", validation: null }]
    }
  },
  listViews: {
    default: {
      fields: [
        "id",
        "business_subject_type",
        "business_subject_id",
        "username",
        "user_role",
        "email",
        "first_name",
        "last_name",
        "active",
        "created_by",
        "created_on",
        "edit"
      ],
      hidden: ["id", "created_on", "created_by"]
    },
    exportExcel: {
      fields: [
        { source: "username", width: 25 },
        { source: "email", width: 25 },
        { source: "first_name", width: 25 },
        { source: "last_name", width: 25 },
        { source: "created_on", width: 25 }
      ]
    },
    bs: {
      fields: ["id", "username", "user_role", "email", "first_name", "last_name", "created_by", "created_on", "active", "edit"]
    }
  },
  fields: [
    coreFields.id,
    coreFields.active,
    coreFields.created_on,
    coreFields.created_by,

    userFields.username,
    userFields.first_name,
    userFields.last_name,
    commonFields.email,
    commonFields.oib,
    {
      title: "Status",
      source: "status",
      ttoken: "common.status",
      type: "picker",
      filter: true,
      items: {
        labels: ["users.status_registriran", "users.status_aktivan", "users.status_neaktivan", "users.status_anoniman"],
        values: [10, 20, 30, 40]
      },
      translate: true
    },
    {
      title: "User role",
      source: "user_role",
      ttoken: "users.role",
      type: "picker",
      subModel: {
        apiPath: "user-roles/",
        format: "{label}"
      },
      validation: {
        required: true
      },
      translate: true,
      filter: true
    },
    {
      title: "User Permissions",
      source: "user_permissions",
      ttoken: "users.permissions",
      type: "checkbox",
      subModel: {
        apiPath: "user-permissions/",
        format: "{tag}"
      }
    },
    {
      title: "Business Subject",
      source: "business_subject_id",
      ttoken: "users.business_subject",
      type: "picker",
      subModel: {
        apiPath: "business-subjects/?need_for=picker",
        format: "{label}"
      },
      validation: {
        required: true
      },
      filter: true
    },
    {
      title: "Business Subject Name",
      source: "business_subject",
      ttoken: "users.business_subject",
      readonly: true,
      filter: true,
      validation: {
        required: true
      }
    },
    {
      title: "Business Subject Type",
      source: "business_subject_type",
      ttoken: "users.business_subject_type",
      type: "picker",
      subModel: {
        apiPath: "business-subject-types/",
        format: "{label}"
      },
      translate: true,
      readonly: true
    },
    {
      title: "tel",
      source: "tel",
      ttoken: "users.tel",
      type: "text",
      validation: {
        maxLength: 20
      }
    },
    {
      title: "Remarks",
      source: "remarks",
      ttoken: "users.remarks",
      type: "multiline",
      validation: {
        maxLength: 250
      },
      rows: 5,
      width: "full"
    },
    {
      title: "Aktivacijski Ključ",
      source: "activation_token",
      ttoken: "users.activation_token",
      type: "text",
      readonly: true,
      validation: {
        required: true
      }
    },
    {
      title: "Ključ za izmjenu lozinke",
      source: "passwordreset_token",
      ttoken: "users.passwordreset_token",
      type: "text",
      readonly: true,
      validation: {
        required: true
      }
    },
    userFields.password,
    userFields.password_confirm,
    {
      title: "Nova lozinka",
      source: "password_new",
      ttoken: "users.password_new",
      type: "password",
      validation: {
        required: true
      }
    },
    {
      title: "Potvrda nove lozinke",
      source: "password_new_confirm",
      ttoken: "users.password_new_confirm",
      type: "password",
      validation: {
        required: true
      }
    },
    buttonFields.edit
  ]
};
