import React from 'react';

import Card from "@material-ui/core/Card";

import { GridItem } from "UI/Grid";

function GridItemStatisticsWrapper(props) {
	const { children, sizes } = props;

	return (
		<GridItem {...sizes}>
			<Card style={{ height: "100%" }}>
				{children}
			</Card>
		</GridItem>
	);
}

export default GridItemStatisticsWrapper;