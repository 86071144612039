import React from "react";

//Material-UI Core Components
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";

const useClasses = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    marginRight: "16px",
    width: "50px",
    height: "50px"
  },
  img: {
    color: theme.palette.primary.main,
    width: "35px",
    height: "35px",
    display: "inline"
  }
}));

function FormAvatar(props) {
  const { icon, icon_word, ariaLabel } = props;

  const className = icon ? icon.props.className : null;

  const classes = useClasses();

  return (
    <Avatar aria-label={ariaLabel} classes={classes}>
      <center>
        {icon_word ? (
          <Icon className={classes.img + (className ? " " + className : "")}>{icon_word}</Icon>
        ) : (
          icon ?
            <icon.type className={classes.img + (className ? " " + className : "")} />
            : null
        )}
      </center>
    </Avatar>
  );
}

export default FormAvatar;
