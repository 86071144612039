import React from 'react';
import Card from "@material-ui/core/Card";

function CardColored(props) {
  const { color, ...otherProps} = props;

  const customStyle = {
    border: "2px"
    , borderStyle: "solid"
    , borderColor: color
  }

  return (
      <Card {...otherProps} style={color ? customStyle : undefined}>
        {props.children}
      </Card>
  );
}

export default CardColored;
