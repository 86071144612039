import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Icons
import StarsIcon from "@material-ui/icons/Stars";

//Custom components
import withRedirect from "Components/withRedirect";
import DialogContext from "UI/DialogContext/DialogContext";
import UserContext from "Components/UserContext/UserContext";

import DialogToolbarMenuItem from "UI/Dialog/DialogToolbarMenuItem";
import modelPP from "Models/posebnePonude";
import dataController from "Lib/dataController";
import confirmDialog from "Lib/confirmDialog";
import PosebnaPonudaKruznaDialog from "Views/Prijevoznici/PosebnePonude/PosebnaPonudaKruznaDialog";
import PosebnaPonudaTransferDialog from "Views/Prijevoznici/PosebnePonude/PosebnaPonudaTransferDialog";

const DialogToolbarMenuItemPosebnaPonudaCreate = React.forwardRef((props, ref) => {
  const dialogContext = useContext(DialogContext);
  const userContext = useContext(UserContext);
  const { t } = useTranslation();
  const { redirect, record } = props; //HOW withRedirect

  const confirmPosebnaPonudaType = () => {
    const title = t("cdialogs.posebna_ponuda.title");
    const text = t("cdialogs.posebna_ponuda.text");
    const type = "question";
    const confirmButtonText = t("cdialogs.posebna_ponuda.transfer");
    const cancelButtonText = t("cdialogs.posebna_ponuda.kruzna");

    return confirmDialog(title, text, type, confirmButtonText, cancelButtonText);
  };

  const handleClick = () => {
    const dcPP = new dataController(modelPP);

    confirmPosebnaPonudaType().then((result) => {
      if (!result.canceled) {
        const isKruzno = !result.confirmed;
        const DialogComponent = isKruzno ? PosebnaPonudaKruznaDialog :
          PosebnaPonudaTransferDialog;

        dialogContext.showDialog(DialogComponent, {
          dc: dcPP,
          mode: "insert",
          record: record,
          form: isKruzno ? "prijevoznikKruzni" : "prijevoznikTransfer",
          onClose: handleDialogClose,
          subModelDefaultValues: {
            prijevoznik_id: userContext.subjectId
          },
          defaultValues: {
            cbc_provizija_perc: userContext.subjectCBCProvision
          }
        });
      }
    });
  }

  const handleDialogClose = (result) => {
    if (result.id) {
      redirect(`/p/posebne-ponude/${result.id}`);
    }
    dialogContext.hideDialog();
  }

  return <DialogToolbarMenuItem label={t("buttons.create_posebna_ponuda")} icon={<StarsIcon/>} {...props} ref={ref} onClick={handleClick} />;
})

export default withRedirect(DialogToolbarMenuItemPosebnaPonudaCreate);
