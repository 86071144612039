import React from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(1),
    "&:last-child": {
      paddingBottom: theme.spacing(1)
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    height: "100%",
    maxHeight: "100%",
    overflowY: "auto",
    padding: theme.spacing(1),
    [theme.breakpoints.up("xl")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    [theme.breakpoints.down("lg")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  }
}));

function TableCardContent(props) {
  const classes = useStyles();

  return <CardContent {...props} classes={classes} />;
}

export default TableCardContent;
