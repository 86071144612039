import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

//Custom components
import UserContext from "Components/UserContext/UserContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import DialogContext from "UI/DialogContext/DialogContext";

import { GridContainer, GridItem } from "UI/Grid";
import FormContent from "Components/FormContent";
import ModelTable from "UI/Table/ModelTable";
import FormTabPanel from "UI/Form/FormTabPanel";
import TableButtonView from "UI/Table/ActionButtons/TableButtonView";
import withFormController from "Components/withFormController";

import PojedinacnaVoznjaDialog from "Views/Prijevoznici/Voznje/VoznjaFormTabDialogs/PojedinacnaVoznjaDialog";

import dataController from "Lib/dataController";
import modelVoznja from "Models/pojedinacneVoznje";

import { ponudeService } from "Services/ponudeService";

function TabPanelPojedinacneVoznje(props) {
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);
  const userContext = useContext(UserContext);
  const dialogContext = useContext(DialogContext);

  const [records, setRecords] = useState([]);
  const { t } = useTranslation();

  const { currentTab, tabIndex } = props;
  const { recordId, record, validation, fields, onFieldChange, mode, subModels } = props;

  const dcVoznje = new dataController(modelVoznja, "ponude/" + recordId + "/voznje");

  const fKm = "neto_km";
  const fMin = "neto_min";

  const fKmCalc = "km_calc_voznje";
  const fMinCalc = "min_calc_voznje";

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    ponudeService
      .getPojedinacneVoznje(recordId)
      .then((resp) => {
        if (resp.success) {
          setRecords(resp.data ? resp.data : []);
          const km_total = resp.data && resp.data.length > 0 ? resp.data.filter(x => x[fKm]).map(x => x[fKm]).reduce((a,b) => a + b, 0) : 0;
          const min_total = resp.data && resp.data.length > 0 ? resp.data.filter(x => x[fMin]).map(x => x[fMin]).reduce((a,b) => a + b, 0) : 0;
          onFieldChange(km_total, fKmCalc);
          onFieldChange(min_total, fMinCalc);
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  useEffect(() => {
    if (currentTab === tabIndex && records.length === 0) {
      refreshRecords();
    }
  }, [currentTab]);

  const handleAdd = () => {
    dialogContext.showDialog(PojedinacnaVoznjaDialog, {
      dc: dcVoznje,
      mode: "insert",
      posaoId: recordId,
      ponuda: true,
      // form: "pojedinacne_voznje",
      onClose: handleDialogClose,
      subModelDefaultValues: {
        prijevoznik_id: userContext.subjectId
      }
    });
  };

  const handleView = (evt, sel) => {
    const selId = parseInt(sel[0], 10);
    const record = records[selId];
    dialogContext.showDialog(PojedinacnaVoznjaDialog, {
      dc: dcVoznje,
      mode: "update",
      recordId: record.id,
      onClose: handleDialogClose,
      subModelDefaultValues: {
        prijevoznik_id: userContext.subjectId
      }
    });
  };

  const handleDialogClose = (result) => {
    console.log(result);
    if (result.dataChanged) {
      refreshRecords();
    }
    dialogContext.hideDialog();
  };

  return (
    <FormTabPanel value={currentTab} index={tabIndex}>
      <GridContainer spacing={1}>
        <GridItem sm={12}>
          <ModelTable
            title={t("titles.pojedinacne_voznje")}
            records={records}
            dc={dcVoznje}
            viewName={"ponuda_voznje"}
            search={false}
            allowExport={false}
            allowFilter={false}
            allowAdd={true}
            addLabel={t("buttons.new_f")}
            allowSelection={"one"}
            handleAdd={handleAdd}
            subModelDefaultValues={{
              prijevoznik_id: userContext.subjectId
            }}
          >
            <TableButtonView onClick={handleView} />
          </ModelTable>
        </GridItem>
        <FormContent
          fieldNames={["km_calc_voznje", "min_calc_voznje", "km_manual", "min_manual"]}
          record={record}
          validation={validation}
          onFieldChange={onFieldChange}
          fields={fields}
          mode={mode}
          subModels={subModels}
          columns={2}
        />
      </GridContainer>
    </FormTabPanel>
  );
}

TabPanelPojedinacneVoznje.propTypes = {
  recordId: PropTypes.number,
  record: PropTypes.object,
  validation: PropTypes.object,
  onFieldChange: PropTypes.func,
  fields: PropTypes.array,
  mode: PropTypes.oneOf(["view", "update", "insert"])
};

export default TabPanelPojedinacneVoznje;
