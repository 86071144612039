import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function VoziloIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M 1.8031143,1.6202706 C 2.3046989,0.47878561 2.7378858,0 3.6726573,0 H 19.376819 C 19.750727,0 20,0.2492724 20,0.62318101 V 8.7245341 c 0,0.3739086 -0.249273,0.6231811 -0.623181,0.6231811 H 17.38264 c -0.249273,0.7478168 -0.934772,1.2463618 -1.744907,1.2463618 -0.623181,0 -1.246362,-0.31159 -1.557953,-0.8101351 -0.31159,0.4362271 -0.934771,0.7478171 -1.557952,0.7478171 -0.810136,0 -1.495635,-0.498545 -1.744907,-1.246362 H 6.1653813 c -0.2492724,0.747817 -0.9347715,1.246362 -1.7449068,1.246362 -0.8101353,0 -1.4956344,-0.498545 -1.7449068,-1.246362 L 1.0936509,9.2610805 C 0.71978643,9.2553336 0.03702424,9.11909 0,8.3241865 0.06186853,6.0875661 0.89523849,4.134323 1.8031143,1.6202706 Z m 1.869543,-0.4362267 c -0.3115905,0 -0.5365436,0.3222301 -0.623181,0.5608629 L 1.9155908,4.92313 2.5144526,4.947449 C 3.5107211,4.9879065 3.9219297,4.5492214 4.6074288,3.8637223 4.732065,3.7390861 4.8567012,3.676768 5.0436555,3.676768 H 18.753638 V 1.1840439 Z M 15.637733,9.285397 c 0.373908,0 0.623181,-0.2492724 0.623181,-0.623181 v 0 0 c 0,-0.3739086 -0.249273,-0.623181 -0.623181,-0.623181 -0.373909,0 -0.623181,0.2492724 -0.623181,0.623181 0,0.3739086 0.249272,0.623181 0.623181,0.623181 z m -3.115905,0 c 0.373908,0 0.623181,-0.2492724 0.623181,-0.623181 0,-0.3739086 -0.249273,-0.623181 -0.623181,-0.623181 -0.373909,0 -0.623181,0.2492724 -0.623181,0.623181 0,0.3739086 0.249272,0.623181 0.623181,0.623181 z m -8.1013535,0 c 0.3739086,0 0.623181,-0.2492724 0.623181,-0.623181 0,-0.3739086 -0.2492724,-0.623181 -0.623181,-0.623181 -0.3739086,0 -0.623181,0.2492724 -0.623181,0.623181 0,0.3739086 0.2492724,0.623181 0.623181,0.623181 z" />
    </SvgIcon>
  );
}

export default VoziloIcon;
