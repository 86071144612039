import React from 'react';
import { useTranslation } from "react-i18next";

//Material-UI
import SendIcon from '@material-ui/icons/Send';

//Custom Components
import CardActionButton from "UI/Card/CardActionButton";

function CardActionButtonSend(props) {
  const { t } = useTranslation();

  return (
      <CardActionButton startIcon={<SendIcon />} {...props} aria-label="send" aria-controls="send">
        {t("buttons.send")}
      </CardActionButton>
  );
}

export default CardActionButtonSend;
