import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

// Material UI Core
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

// Material-UI Icons
import DoneAllIcon from "@material-ui/icons/DoneAll";

// Custom Components
import ModelTable from "UI/Table/ModelTable";
import TableButtonEdit from "UI/Table/ActionButtons/TableButtonEdit";
import DialogContext from "UI/DialogContext/DialogContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import KomunikatorContext from "Components/KomunikatorContext/KomunikatorContext";
import UserDialog from "Views/Administration/UsersPage/UserDialog";
import withRedirect from "Components/withRedirect";
import model from "Models/notification";
import dataController from "Lib/dataController";
import TableButton from "UI/Table/TableButton";
import TableButtonDelete from "UI/Table/ActionButtons/TableButtonDelete";
import GridContainer from "UI/Grid/GridContainer"
import GridItem from "UI/Grid/GridItem"
import { notificatorService } from "Services/notificatorService";

function NotificationsTable(props) {
  const dialogContext = useContext(DialogContext);
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);
  const komunikatorContext = useContext(KomunikatorContext);
  const location = useLocation();
  const { t } = useTranslation();

  const [records, setRecords] = useState([]);
  const { redirect } = props; //HOC withRedirect
  const dc = new dataController(model);

  useEffect(() => {
    refreshRecords();
  }, [location.hash])

  const refreshRecords = () => {
    if(location.hash === "#panic") {
      setRecords(location.state.record);
    } else {
      loaderContext.toggleLoading(true);
      dc.GetData()
        .then((resp) => {
          if (resp.success) {
            setRecords(resp.data);
          }
        })
        .catch((result) => {
          snackbarContext.showNotification(result.error, "error");
        })
        .finally(() => {
          loaderContext.toggleLoading(false);
        });
    }
  };

  const handleAllNotifications = () => {
    redirect(location.pathname);
  }

  const handleMarkRead = (evt, selIds) => {
    const intIds = selIds.map((x) => parseInt(x, 10));
    const recordIds = records.filter((x, i) => intIds.findIndex(id => id === i) >= 0).map((x) => x.id);
    komunikatorContext.markReadNotifications(recordIds)
    .then((resp) => {
      if (resp.success) {
        refreshRecords();
        snackbarContext.showNotification("update", "success");
      }
    }).catch((result) => {
      snackbarContext.showNotification(result.error, "error");
    });
  };

  const handleDelete = (evt, selIds) => {
    const intIds = selIds.map((x) => parseInt(x, 10));
    const recordIds = records.filter((x, i) => intIds.findIndex(id => id === i) >= 0).map((x) => x.id);
    komunikatorContext.markDeleteNotifications(recordIds)
    .then((resp) => {
      if (resp.success) {
        refreshRecords();
        snackbarContext.showNotification("update", "success");
      }
    }).catch((result) => {
      snackbarContext.showNotification(result.error, "error");
    });
  };

  const handleRowAction = function(action, id) {
    if (action === "dynamic") {
      const rec = records.find(x => x.id === id);
      if (rec) {
        const link_type = rec["link_type"];
        const link_id = rec["link_id"]
        const redirectLink = komunikatorContext.getNotificationRedirectLink(link_type, link_id);
        komunikatorContext.markReadNotification(id)
        .then(resp => {
          if (resp.success) {
            if (redirectLink) {
              redirect(redirectLink);
            }
          }
        }).catch((result) => {
          snackbarContext.showNotification(result.error, "error");
        });
      }
    }
  };

  return (
    <Card>
      <CardContent>
        <ModelTable
          title={t("titles.notifications")}
          records={records}
          dc={dc}
          allowSelection="many"
          allowFilter={true}
          allowExport={false}
          allowAdd={false}
          viewName="default"
          onRowAction={handleRowAction}
          onDoubleClick={(id) => handleRowAction("dynamic", id)}
        >
          <TableButtonDelete onClick={handleDelete} />
          <TableButton variant="contained" allow="many" startIcon={<DoneAllIcon />} onClick={handleMarkRead}>
            {t("buttons.mark_read")}
          </TableButton>
        </ModelTable>
          <GridContainer direction="row-reverse">
            <GridItem>
              { location.hash === "#panic" ? (
                <TableButton variant="contained" onClick={handleAllNotifications}>
                  {t("buttons.show_all")}
                </TableButton>
              ) : null}
            </GridItem>
          </GridContainer>
      </CardContent>
    </Card>
  );
}

export default withRedirect(NotificationsTable);
