import React, { useContext } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import { makeStyles, ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

//Material-UI Icons
import PersonIcon from "@material-ui/icons/Person";

//Custom Components
import ThemeContext from "Components/ThemeContext/ThemeContext";
import HeaderButton from "UI/Header/HeaderButton";
import HeaderMenuButton from "UI/Header/HeaderMenuButton";
import HeaderMenuItem from "UI/Header/HeaderMenuItem";
import ToolbarFillContent from "UI/Toolbar/ToolbarFillContent";
import ThemeSwitch from "UI/Header/ThemeSwitch";
import LanguageSelector from "UI/Header/LanguageSelector";

import Logo from "Images/eurohop-50h.png";

const makeAppBarStyles = makeStyles((theme) => ({
  cotnainer: {
    flexGrow: 1
  },
  root: {
    backgroundColor: theme.palette.background.paper
  },
  grow: {
    flexGrow: 1,
    textAlign: "center",
    justifyContent: "center"
  },
  link: {
    textDecoration: "none",
    paddingLeft: 10,
    paddingRight: 10
  },
  titleLink: {
    color: "#fff",
    textDecoration: "none"
  },
  activeLink: {
    color: "#FFD33A",
    textDecoration: "underline"
  }
}));

const makeToolbarStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: 0,
      marginBottom: 0,
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    // borderBottom: "2px",
    borderStyle: "solid",
    borderColor: theme.palette.secondary.main
  },
}));


function PublicHeader(props) {
  const themeContext = useContext(ThemeContext);

  const { t } = useTranslation();
  const appBarClasses = makeAppBarStyles();
  const toolbarClasses = makeToolbarStyles();

  const isLightTheme = themeContext.theme === "light" ? true : false;

  return (
    <MuiThemeProvider
      theme={outerTheme => ({
        ...outerTheme,
        palette: {
          ...outerTheme.palette,
          primary: isLightTheme ? {
            main: "#FB471E",
            light: "#fc7f63",
            dark: "#dd2c04"
          } : {
            main: "#FFF"
          },
          text: {
            ...outerTheme.palette.text,
            primary: isLightTheme ? "#FFF" : "#FB471E"
          }
        }
      })}>
      <div id="header" className={appBarClasses.container}>
        <AppBar position="fixed" className={appBarClasses.root}>
          <Toolbar className={toolbarClasses.root} variant="dense">
            <ToolbarFillContent>
              <Typography
                variant="h6"
                component="h2"
                className={appBarClasses.grow}
                style={{ color: "#fff", fontSize: "1.15rem" }}
              >
                <NavLink to="/" className={appBarClasses.titleLink}>
                  <img src={Logo} height={47} />
                </NavLink>
              </Typography>
            </ToolbarFillContent>
            <ThemeSwitch />
            <LanguageSelector />
          </Toolbar>
        </AppBar>
      </div>
    </MuiThemeProvider>
  );
}

PublicHeader.propTypes = {
  menu: PropTypes.array
};

export default PublicHeader;
