import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";

//CBC Icons
import KorisnikIcon from "Icons/KorisnikIcon";

//Custom Components
import withFormController from "Components/withFormController";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButton from "UI/Dialog/DialogActionButton";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogActionButtonAdd from "UI/Dialog/ActionButtons/DialogActionButtonAdd";
import DialogActionButtonSave from "UI/Dialog/ActionButtons/DialogActionButtonSave";
import DialogActionButtonActivate from "UI/Dialog/ActionButtons/DialogActionButtonActivate";
import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItem from "UI/Dialog/DialogToolbarMenuItem";
import DialogToolbarMenuItemDelete from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemDelete";
import DialogToolbarMenuItemActivate from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemActivate";
import DialogToolbarMenuItemDeactivate from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemDeactivate";
import DialogContext from "UI/DialogContext/DialogContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import GridContainer from "UI/Grid/GridContainer";
import FormContent from "Components/FormContent";
import GridItem from "UI/Grid/GridItem";
import { userService } from "Services/userService";
import DynamicDialog from "UI/Dialog/DynamicDialog";

function UserDialog(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const loaderContext = useContext(LoaderContext);
  const { t } = useTranslation();

  const USERROLE_CUSTOM = 990;
  const fUserRole = "user_role";
  const fPermissions = "user_permissions";

  const [dataChanged, setDataChanged] = useState(false);

  const { mode, form, record, validation, fields, subModels, recordId } = props; //HOC withFormController
  const { doInsert, doUpdate, doClose, doDelete } = props; //HOC withFormController
  const { onFieldChange, onClose } = props; //HOC withFormController

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      if (source === fUserRole && value !== null) {
        const subModelRecords = subModels.user_role.records;
        const permissions = subModelRecords.find((record) => record.value === value.value).app_permissions;
        if (value.value === USERROLE_CUSTOM) {
        } else {
          onFieldChange(value, fUserRole);
          onFieldChange(permissions, fPermissions);
          return;
        }
      }

      //if (source === fPermissions && value !== undefined && value !== null) {
      //  const { record } = props;
      //  const prevPermissionsLen = record && record.hasOwnProperty(fPermissions) && record[fPermissions] ? record[fPermissions].length : 0;
      //  const currPermissionsLen = Array.isArray(value) ? value.length : 1;

      //  const checked = currPermissionsLen > prevPermissionsLen;
      //  const intValue = Array.isArray(value) ? value.reduce((acc, curr) => ((curr.value || curr) + acc), 0) : value;
      //}
      onFieldChange(value, source);
    }
  };

  const handleInsert = (evt) => {
    if (doInsert) {
      doInsert().then((result) => {
        if (result.success) {
          close({ dataChanged: true });
          snackbarContext.showNotification("insert", "success");
        }
      }).catch((result) => {
        if (result.success === false && result.validationPass === false) {
          //do nothing
        } else {
          snackbarContext.showNotification(result.error, "error");
        }
      });
    }
  };

  const handleUpdate = (evt) => {
    if (doUpdate) {
      doUpdate().then((result) => {
        if (result.success) {
          close({ dataChanged: true });
          snackbarContext.showNotification("update", "success");
        }
      }).catch((result) => {
        if (result.success === false && result.validationPass === false) {
          //do nothing
        } else {
          snackbarContext.showNotification(result.error, "error");
        }
      });;
    }
  };

  const handleDelete = (evt) => {
    if (doDelete) {
      doDelete().then((result) => {
        if (result.success) {
          close({ dataChanged: true });
          snackbarContext.showNotification("delete", "success");
        }
      }).catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      });;
    }
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const handleActivate = (evt) => {
    userService
      .activateUser(recordId)
      .then((res) => {
        if (res.success) {
          close({ dataChanged: true });
          snackbarContext.showNotification("notifications.activate", "success");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDeactivate = (evt) => {
    userService
      .deactivateUser(recordId)
      .then((res) => {
        if (res.success) {
          close({ dataChanged: true });
          snackbarContext.showNotification("notifications.deactivate", "success");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const close = (result) => {
    if (result.dataChanged || dataChanged) {
      onClose({ dataChanged: true });
    }

    dialogContext.hideDialog();
  };

  const editFields = ["username", "first_name", "last_name", "email", "business_subject_id", "tel", "remarks"];
  const insertFields = [
    "username",
    "password",
    "password_confirm",
    "first_name",
    "last_name",
    "email",
    "business_subject_id",
    "tel",
    "remarks"
  ];

  const commonProps = {
    recordId: recordId,
    record: record,
    validation: validation,
    onFieldChange: handleFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels,
    columns: 1
  };

  const fieldPermissions = fields.find((x) => x.source === fPermissions);
  fieldPermissions.readonly = true;

  if (record && record[fUserRole]) {
    if (record[fUserRole] === USERROLE_CUSTOM || record[fUserRole].value === USERROLE_CUSTOM) {
      fieldPermissions.readonly = false;
    } else {
      fieldPermissions.readonly = true;
    }
  }

  return (
    <DynamicDialog onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogAvatar ariaLabel="" icon={<KorisnikIcon />} />
          <DialogToolbarHeading>
            {mode == "insert" ? t("titles.user_new") : t("titles.user")} {record.id ? " (ID=" + record.id + ")" : ""}
          </DialogToolbarHeading>
          <DialogToolbarFillContent />
          {mode === "update" || mode === "view" ? (
            <DialogToolbarMenuButton>
              {record.active ? (
                <DialogToolbarMenuItemDeactivate onClick={handleDeactivate} />
              ) : (
                <DialogToolbarMenuItemActivate onClick={handleActivate} />
              )}
              <DialogToolbarMenuItemDelete onClick={handleDelete} />
            </DialogToolbarMenuButton>
          ) : null}
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer>
            <GridItem xs={6} sm={6}>
              <FormContent fieldNames={mode === "insert" ? insertFields : editFields} {...commonProps} />
            </GridItem>
            <GridItem xs={6} sm={6}>
              <FormContent fieldNames={["user_role"]} {...commonProps} />
              <FormContent fieldNames={["user_permissions"]} {...commonProps} />
            </GridItem>
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
        {mode === "insert" ? <DialogActionButtonAdd variant="contained" onClick={handleInsert} /> : null}
        {mode === "update" && record.active ? (
          <DialogActionButtonSave variant="contained" onClick={handleUpdate} />
        ) : null}
        {mode === "view" && !record.active ? (
          <DialogActionButtonActivate variant="contained" onClick={handleActivate} />
        ) : null}
      </DialogActions>
    </DynamicDialog>
  );
}

export default withFormController(UserDialog);
