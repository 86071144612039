import React, { useContext } from "react";

//Material UI
import DialogActions from "@material-ui/core/DialogActions";

//Custom Components
import DialogContext from "UI/DialogContext/DialogContext";
import DraggableDialog from "UI/Dialog/DraggableDialog";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import model from "Models/a_prijevoznici";
import dataController from "Lib/dataController";
import PrijevoznikPublicView from "./PrijevoznikPublicView";
import DialogActionButtonSendUpit from "UI/Dialog/ActionButtons/DialogActionButtonSendUpit";
import UpitDialog from "Views/Upiti/UpitDialog";
import withRedirect from "Components/withRedirect";
import upitModel from "Models/upiti";

const styles = {
  body: {
    overflow: "hidden",
    paddingTop: "10px"
  }
};

function PrijevoznikPublicDialog(props) {
  const dialogContext = useContext(DialogContext);

  const { recordId, onClose, sendUpit, direktIdRecord } = props;
  const { redirect } = props;

  const upitDC = new dataController(upitModel);

  const handleClose = () => {
    dialogContext.hideDialog();
    if (onClose) {
      onClose();
    }
  };

  const handleSendDirektanUpit = () => {
    dialogContext.showDialog(UpitDialog, {
      dc: upitDC,
      mode: "insert",
      form: "insert",
      onClose: handleDialogClose,
      direktIdRecord: direktIdRecord,
      external: true
    }, 2);
  }

  const handleDialogClose = (result) => {
    if (result.id){
      redirect(`/a/upiti/${result.id}`)
    }
  };

  const dc = new dataController(model);

  return (
    <DraggableDialog open={true} maxWidth={"md"} onClose={handleClose}>
      <DialogBody style={styles.body}>
        <PrijevoznikPublicView
          dc={dc}
          secondary="business-subjects/__split__/public"
          recordId={recordId}
          form="public"
          mode="simpleView"
        />
      </DialogBody>
      <DialogActions>
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
        {sendUpit ? (
          <DialogActionButtonSendUpit variant="contained" onClick={handleSendDirektanUpit} />
        ) : null}
      </DialogActions>
    </DraggableDialog>
  );
}

export default withRedirect(PrijevoznikPublicDialog);
