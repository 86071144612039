import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import moment from "moment";

//Material UI
import CardContent from "@material-ui/core/CardContent";
import { Card, CardActions } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";

// Material-UI Icons
import InfoIcon from "@material-ui/icons/Info";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import AssignmentIcon from "@material-ui/icons/Assignment";
import MoneyIcon from "@material-ui/icons/Money";

//Custom components
import withFormController from "Components/withFormController";
import TabOsnovniPodaci from "../VoznjaFormTabs/TabOsnovniPodaci";
import TabPojedinacneVoznje from "../VoznjaFormTabs/TabPojedinacneVoznje";
import TabPonuda from "../VoznjaFormTabs/TabPonuda";
import VoznjaAvatar from "UI/Form/Avatars/VoznjaAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import { 
  CardColored, 
  CardHeading, 
  CardToolbarButtonAlarm, 
  CardToolbarButtonBack, 
  CardSOSHeading 
} from "UI/Card";
import { FormTab, FormTabPanel, FormTabs } from "UI/Form";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import TabInternaKalkulacija from "../VoznjaFormTabs/TabInternaKalkulacija";
import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItemCopy from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemCopy";
import DialogToolbarMenuItemInvalidate from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemInvalidate";
import DialogToolbarMenuItemInvalidateCopy from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemInvalidateCopy";
import PrintButton from "UI/Buttons/PrintButton";
import SaveButton from "UI/Buttons/SaveButton";
import LockButton from "UI/Buttons/LockButton";
import { posloviStatuses, retrievePosloviStatusLabel } from "Lib/statuses";
import confirmDialog from "Lib/confirmDialog";
import { posloviService } from "Services/posloviService";
import FormFillContent from "UI/Form/FormFillContent";
import EuroConversionComment from "Components/EuroConversionComment";

function VoznjaForm20(props) {
  const snackbarContext = useContext(SnackbarContext);

  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();

  const [currentTab, setCurrentTab] = useState(0);

  const { dc, record, mode, validation, fields, subModels } = props; //HOC withFormController
  const { onFieldChange, doValidate } = props; //HOC withFormController
  const { handleCopy, handleStorniraj, handleStornirajCopy, handleRefreshForm } = props;
  const { ownerId } = props;

  const fKooperant = "kooperant_id";
  const fVoziloId = "vozilo_id";

  const handleTabChange = (evt, value) => {
    setCurrentTab(value);
  };

  const handleSave = () => {
    const preparedRecord = dc.prepareSendingData(record);
    posloviService.updateInterniPosao(preparedRecord.id, preparedRecord).then((resp) => {
      if (resp.success) {
        snackbarContext.showNotification("update", "success");
        // window.location.reload();
      } else {
        snackbarContext.showNotification(resp.error.message, "error");
      }
    });
  };

  const confirmNextStep = () => {
    const title = t("confirm_voznje_status.voznja20.title");
    const text = t("confirm_voznje_status.voznja20.text");
    const type = "warning";
    const confirmButtonText = t("cdialogs.confirm");
    const cancelButtonText = t("cdialogs.cancel");

    return confirmDialog(title, text, type, confirmButtonText, cancelButtonText);
  };

  const handleLock = () => {
    confirmNextStep().then((result) => {
      if (result.confirmed) {
        if (doValidate) {
          const validated = doValidate();
          if (validated) {
            const preparedRecord = dc.prepareSendingData(record);
            posloviService.updateInterniPosao(preparedRecord.id, preparedRecord).then((resp) => {
              if (resp.success) {
                posloviService.interniPosaoStanje20_21(record.id).then((resp) => {
                  if (resp.success) {
                    snackbarContext.showNotification("insert", "success");
                    if (handleRefreshForm) {
                      handleRefreshForm();
                    }
                  } else {
                    snackbarContext.showNotification(resp.error.message, "error");
                  }
                });
              } else {
                snackbarContext.showNotification(resp.error.message, "error");
              }
            });
          }
        }
      }
    });
  };

  const handleClickBack = () => {
    history.goBack();
  };

  const commonTabProps = {
    currentTab: currentTab,
    dc: dc,
    subModels: subModels,
    record: record,
    mode: mode,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    recordId: record.id
  };

  const title = `${t(retrievePosloviStatusLabel(record.posao_state_id))}  (ST.${record.posao_state_id})(ID:${record.id})`;
  const subtitle = `Rok Važenja Ponude: ${moment(record.st_expire).format("DD.MM.YYYY")}`;

  const identifier = dc.getSource() + record.posao_state_id;
  const isInterni = record["tip_voznje_id"] === 50;
  const isStorno = record["storno"] === true;
  const isAgentura = record.agentura === true;
  const { isPanic } = props;

  const fieldKooperant = fields.find(x => x.source === fKooperant);
  const fieldVozilo = fields.find(x => x.source === fVoziloId);

  const kooperantChosen = record[fKooperant] && record[fKooperant] !== null;
  const voziloChosen = record[fVoziloId] && record[fVoziloId] !== null;

  if (kooperantChosen) {
    fieldVozilo.readonly = true;
  } else if (voziloChosen) {
    fieldKooperant.readonly = true;
  } else {
    fieldVozilo.readonly = false;
    fieldKooperant.readonly = false;
  }

  const customColor = isStorno ? theme.palette.custom.storno :
    isPanic ? theme.palette.custom.panic :
    isAgentura ? theme.palette.custom.agentura :
    undefined;

  const customText = isStorno ? t("messages.storno") :
    isPanic ? t("messages.panic") :
    isAgentura ? t("messages.agentura") :
    undefined;
    
  return (
    <CardColored color={customColor}>
      <CardHeading recordStatus={record.posao_state_id} statuses={posloviStatuses} color={customColor}>
        <Toolbar variant="dense" disableGutters={true}>
          <VoznjaAvatar />
          <DialogToolbarHeading header={title} subheader={subtitle} />
          {/*<CardSOSHeading isPanic={props.isPanic}/>*/}
          <DialogToolbarFillContent />
          {customText ?
            <Typography variant="button">{customText}</Typography>
            : null
          }
          <DialogToolbarFillContent />
          {
            !props.isPanic ?
              <Fragment>
                <CardToolbarButtonAlarm record={record} type="interna" onFieldChange={onFieldChange} />
                <DialogToolbarMenuButton>
                  {
                    isInterni && !isStorno ? (
                      <DialogToolbarMenuItemInvalidate onClick={() => handleStorniraj(record["id"])} />
                    ) : null
                  }
                  {
                    isInterni && !isStorno ? (
                      <DialogToolbarMenuItemInvalidateCopy onClick={() => handleStornirajCopy(record["id"], record)} />
                    ) : null
                  }
                  <DialogToolbarMenuItemCopy onClick={() => handleCopy(record)} />
                </DialogToolbarMenuButton>
              </Fragment>
              : null
          }
          <CardToolbarButtonBack onClick={handleClickBack} />
        </Toolbar>
      </CardHeading>
      <CardContent style={{ paddingTop: 0 }}>
        <FormTabs value={currentTab} onChange={handleTabChange} identifier={identifier}>
          <FormTab id="osnovni-podaci" label={t("poslovi.tabs.osnovni_podaci")} icon={<InfoIcon />} aria-label="osnovni_podaci" />
          <FormTab id="pojedinacne-voznje" label={t("poslovi.tabs.pojedinacne_voznje")} icon={<AirportShuttleIcon />} aria-label="pojedinacne_voznje" />
          <FormTab id="interna-kalkulacija" label={t("poslovi.tabs.interna_kalkulacija")} icon={<MoneyIcon />} aria-label="interna_kalkulacija" />
          <FormTab id="ponuda" label={t("poslovi.tabs.ponuda")} icon={<AssignmentIcon />} aria-label="ponuda" />
        </FormTabs>
        <FormTabPanel value={currentTab} index={0}>
          <TabOsnovniPodaci {...commonTabProps} internal={true} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={1}>
          <TabPojedinacneVoznje {...commonTabProps} tabValue={1} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={2}>
          <TabInternaKalkulacija {...commonTabProps} tabValue={2} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={3}>
          <TabPonuda {...commonTabProps} />
        </FormTabPanel>
      </CardContent>
      <CardActions>
        <EuroConversionComment />
        <FormFillContent />
        <PrintButton
          dc={dc}
          target={currentTab === 3 ? "ponuda" : null}
          record={record}
          text={currentTab === 3 ? t("buttons.ponuda") : t("buttons.print")}
          disabled={currentTab !== 3}
        />
        {
          !props.isPanic ?
            <LockButton
              onClick={handleLock}
              text={t("buttons.lock")}
              disabled={mode === "view" || currentTab !== 3}
            />
            : null
        }
        {
          !props.isPanic ?
            <SaveButton
              onClick={handleSave}
              text={t("buttons.save")}
              disabled={mode === "view"}
            />
            : null
        }

      </CardActions>
    </CardColored>
  );
}

export default withFormController(VoznjaForm20);
