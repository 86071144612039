import React from 'react';
import { useTranslation } from "react-i18next";

//Material-UI
import PublishIcon from '@material-ui/icons/Publish';

//Custom Components
import DialogActionButton from "UI/Dialog/DialogActionButton";

function DialogActionButtonConfirm(props) {
  const { t } = useTranslation();

  return (
      <DialogActionButton startIcon={<PublishIcon />} {...props} aria-label="upload" aria-controls="upload">
        {t("buttons.upload")}
      </DialogActionButton>
  );
}

export default DialogActionButtonConfirm;
