import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Custom Components
import ModelTable from "UI/Table/ModelTable";
import TableCard from "UI/Table/TableCard";
import TableCardContent from "UI/Table/TableCardContent";
import DialogContext from "UI/DialogContext/DialogContext";
import model from "Models/vozila";
import dataController from "Lib/dataController";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import UserContext from "Components/UserContext/UserContext";
import withRedirect from "Components/withRedirect";
import VoziloDialog from "Views/Prijevoznici/Vozila/VoziloDialog";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

function AdminVozilaTable(props) {
  const dialogContext = useContext(DialogContext);
  const loaderContext = useContext(LoaderContext);
  const userContext = useContext(UserContext);
  const snackbarContext = useContext(SnackbarContext);

  const { t } = useTranslation();
  const [records, setRecords] = useState([]);

  const dc = new dataController(model);
  const { redirect } = props;

  useEffect(() => {
    if (userContext.subjectId) {
      refreshRecords();
    }
  }, [userContext.subjectId]);

  const handleAdd = (evt) => {
    dialogContext.showDialog(VoziloDialog, {
      dc: dc,
      mode: "insert",
      form: "insert",
      onClose: handleDialogClose
    });
  };

  const handleDialogClose = (result) => {
    if (result.id) {
      redirect(`/admin/vozila/${result.id}`);
    } else if (result.dataChanged) {
      refreshRecords();
    }
  };

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData()
      .then((resp) => {
        if (resp.success) {
          setRecords(resp.data || []);
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const handleRowAction = function(action, id) {
    if (action === "edit") {
      redirect(`/admin/vozila/${id}`);
    }
  }

  const allowRowAction = function(action, id) {
    return true;
  }

  return (
    <TableCard>
      <TableCardContent>
        <ModelTable
          title={t("titles.vozila")}
          dc={dc}
          records={records}
          allowExport={true}
          allowFilter={true}
          allowSelection="none"
          allowAdd={true}
          handleAdd={handleAdd}
          addLabel={t("buttons.new_n")}
          viewName="admin"
          onRowAction={handleRowAction}
          allowRowAction={allowRowAction}
          onDoubleClick={(id) => handleRowAction("edit", id)}
          handleRefresh={refreshRecords}
        />
      </TableCardContent>
    </TableCard>
  );
}

export default withRedirect(AdminVozilaTable);
