import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

//Material-UI Icons
import FingerprintIcon from "@material-ui/icons/Fingerprint";

//Custom components

function FormHeadingMediator(props) {
  const { t } = useTranslation();

  const { id } = props;

  return (
    <Tooltip title={t("common.id")}>
      <div style={{ display: "flex", alignItems: "center", marginLeft: 10 }}>
        <FingerprintIcon style={{ marginRight: 8 }} />
        <Typography align="left" variant="subtitle2">
          {id}
        </Typography>
      </div>
    </Tooltip>
  );
}

export default FormHeadingMediator;
