// These are core fields that are present in most of the tables in DB
// They can be added to UI models...

const id = {
  title: "ID",
  source: "id",
  ttoken: "common.id",
  type: "text",
  readonly: true,
  idattr: true,
  width: 15
};

const active = {
  title: "Active",
  source: "active",
  ttoken: "common.active",
  type: "active",
  items: {
    labels: ["common.ne", "common.da"],
    values: [false, true]
  },
  readonly: true,
  translate: true
};

const created_on = {
  title: "Created On",
  source: "created_on",
  ttoken: "common.created_on",
  type: "datetime",
  readonly: true,
  width: 20
};

const created_by = {
  title: "Created By",
  source: "created_by",
  ttoken: "common.created_by",
  type: "text",
  readonly: true
};

const modified_on = {
  title: "Modified On",
  source: "modified_on",
  ttoken: "common.modified_on",
  type: "datetime",
  readonly: true
};

const modified_by = {
  title: "Modified By",
  source: "modified_by",
  ttoken: "common.modified_by",
  type: "text",
  readonly: true
};

export const coreFields = {
  id: id,
  active: active,
  created_on: created_on,
  created_by: created_by,
  modified_on: modified_on,
  modified_by: modified_by
};
