import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

// Custom Components
import ModelTable from "UI/Table/ModelTable";
import TableCard from "UI/Table/TableCard";
import TableCardContent from "UI/Table/TableCardContent";
import DialogContext from "UI/DialogContext/DialogContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import UserContext from "Components/UserContext/UserContext";
import model from "Models/poslovniSubjekti";
import dataController from "Lib/dataController";
import PoslovniSubjektDialog from "Views/Administration/PoslovniSubjekti/PoslovniSubjektDialog";
import withRedirect from "Components/withRedirect";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

function PoslovniSubjektiTable(props) {
  const { t } = useTranslation();
  const { state } = useLocation();

  const userContext = useContext(UserContext);
  const dialogContext = useContext(DialogContext);
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);
  const viewName = "default";

  const [records, setRecords] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const { redirect } = props;

  const dc = new dataController(model);

  useEffect(() => {
    if (state) {
      const { active } = state;
      const identifier = dc.getSource() + viewName;
      userContext.setRecentFilters(identifier, 'active', active);
      userContext.setRecent("toggle_filter", identifier, true);
    }
    refreshRecords();
  }, []);

  const handleAdd = (evt) => {
    dialogContext.showDialog(PoslovniSubjektDialog, {
      dc: dc,
      mode: "insert",
      form: "insert",
      onClose: handleDialogClose,
      referrer: "list",
      defaultValues: { permission: 0, user_role: 100 }
    });
  };

  const handleDialogClose = (result) => {
    if (result.id) {
      redirect(`/admin/poslovni-subjekti/${result.id}`);
    } else if (result.dataChanged) {
      refreshRecords();
    }
  };

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData()
      .then((resp) => {
        if (resp.success) {
          setRecords(resp.data);
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
        setLoaded(true);
      });
  };

  const handleRowAction = function(action, id) {
    if (action === "edit") {
      redirect(`/admin/poslovni-subjekti/${id}`);
    }
  };

  const allowRowAction = function(action, id) {
    return true;
  };

  return (
    <TableCard>
      <TableCardContent>
        {
          loaded &&
          <ModelTable
            title={t("titles.businessSubjects")}
            records={records}
            dc={dc}
            allowSelection="none"
            allowFilter={true}
            allowExport={true}
            allowAdd={true}
            addLabel={t("buttons.new_m")}
            handleAdd={handleAdd}
            onRowAction={handleRowAction}
            allowRowAction={allowRowAction}
            onDoubleClick={(id) => handleRowAction("edit", id)}
            handleRefresh={refreshRecords}
          />
        }
      </TableCardContent>
    </TableCard>
  );
}

export default withRedirect(PoslovniSubjektiTable);
