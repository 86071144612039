import React, { useContext, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import moment from 'moment';

//Material-UI Core components
import Divider from "@material-ui/core/Divider";

//Material-UI Icons
import AnnouncementIcon from "@material-ui/icons/Announcement";
import LaunchIcon from '@material-ui/icons/Launch';

//Custom components
import useModuleRedirect from "Components/useModuleRedirect";
import KomunikatorContext from "Components/KomunikatorContext/KomunikatorContext";
import UserContext from "Components/UserContext/UserContext";
import HeaderMenuIconButton from "UI/Header/HeaderMenuIconButton";
import HeaderMenuItem from "UI/Header/HeaderMenuItem";
import HeaderMenuItemExt from "UI/Header/HeaderMenuItemExt";
import { helpers as H } from "Lib/helpers";
import { sifrarnici } from "../../lib/sifrarnici";

function CommunicatorButton(props) {
  const { t } = useTranslation();
  const { moduleRedirect } = useModuleRedirect({ path: "/komunikator-unseen" });
	const KC = useContext(KomunikatorContext);
  const UC = useContext(UserContext);

  const handleOpen = (evt, n) => {
    KC.addNewChat(n);
    KC.addFocused(n.id);
  };

  const handleOpenAll = () => {
    moduleRedirect();
  }

  return (
    <HeaderMenuIconButton
      icon={<AnnouncementIcon />}
      desc="communicator"
      badge={KC.msgNotifications.length}
      badgeColor="error"
    >
      {KC.msgNotifications.map((n, i) => {
        const msg = n.message;
        const label = `${n.title} (${n.unread})`;
        const name = msg.message_type_id !== sifrarnici.msg_types.notice ? `${msg.created_by === UC.userId ? "You" : msg.created_by_full_name}` : null;
        const body = msg.body.length > 20 ? `${t(msg.body).slice(0, 17)}...` : t(msg.body);
        const date = H.formatDate(moment(msg.created_on), moment(new Date));
        const caption = `${name ? name + ": " : ""}${body} · ${date}`;
        return (<HeaderMenuItemExt
          key={i}
          label={label}
          caption={caption}
          icon={<LaunchIcon />} onClick={(evt) => handleOpen(evt, n)}
        />);
      })}
      <Divider />
      <HeaderMenuItem label={t("komunikator.open_all")} icon={<LaunchIcon />} onClick={handleOpenAll} />
    </HeaderMenuIconButton>
  );
}

export default CommunicatorButton;
