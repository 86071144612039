import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//Material UI
import Box from "@material-ui/core/Box";
import { CardMedia, Typography } from "@material-ui/core";

//Custom Components
import withFormController from "Components/withFormController";
import { GridContainer, GridItem } from "UI/Grid";
import PlaceholderLogo from "Images/logoTest.png";
import RatingControl from "Controls/RatingControl";
import { businessSubjectService } from "Services/businessSubjectService";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import FormContent from "Components/FormContent";

const styles = {
  logo: {
    borderRadius: "8px",
    height: "350px",
    objectFit: "contain"
  },
  body: {
    overflow: "hidden",
    paddingTop: "10px"
  }
};

function PrijevoznikPublicView(props) {
  const { t, i18n } = useTranslation();
  const snackbarContext = useContext(SnackbarContext);
  const loaderContext = useContext(LoaderContext);

  const [logo, setLogo] = useState(null);

  const { record, validation, fields, subModels, mode } = props; //HOC withFormController
  const { onFieldChange } = props; //HOC withFormController

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels
  };


  useEffect(() => {
    if (!logo && record && record.id) {
      loaderContext.toggleLoading(true);
      businessSubjectService
        .getFullImage(record.id, record.uuid)
        .then((resp) => {
          if (resp.success) {
            setLogo(resp.data);
          } else {
            snackbarContext.showNotification(resp.error.message, "error");
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          loaderContext.toggleLoading(false);
        });
    }
  }, [record.id]);

  const ratingField = {
    source: "evaluation",
    ttoken: "prijevoznik.evaluacija",
    max: 10,
    precision: 0.5
  };

  const language = i18n.language;
  const description = record ? (language === "hr" ? record.desc_hr : record.desc_en) : "";

  const logoImage = logo ? `data:image/jpeg;base64, ${logo.image}` : null;

  return (
    <Box m={0}>
      <GridContainer direciton="row" justify="center" alignItems="center">
        <GridItem md={6} sm={12}>
          <CardMedia component="img" src={logoImage} style={styles.logo} />
        </GridItem>
        <GridItem md={6} sm={12}>
          <GridContainer direction="column" alignItems="center" justify="center" spacing={2}>
            <GridItem md={12} sm={12}>
              <Typography variant="h3" style={{ marginBottom: "20px" }}>
                <b>{record ? record.naziv : ""}</b>
              </Typography>
            </GridItem>
            <GridItem md={12} sm={12}>
              <Typography variant="subtitle1">{description}</Typography>
            </GridItem>
            <FormContent fieldNames={["adr_full"]} columns={1} {...commonProps} />
            <GridItem md={12} sm={12}>
              <RatingControl field={ratingField} value={record ? record.evaluation : 0} controlMode="view" />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </Box>
  );
}

export default withFormController(PrijevoznikPublicView);
