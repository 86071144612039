import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

//Custom Components
import dataController from "Lib/dataController";
import model from "Models/posebnePonude";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import UserContext from "Components/UserContext/UserContext";
import PosebnaPonudaTransferForm from "Views/Prijevoznici/PosebnePonude/PosebnaPonudaTransferForm";
import PosebnaPonudaKruzniForm from "Views/Prijevoznici/PosebnePonude/PosebnaPonudaKruzniForm";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

function PosebnaPonudaForm(props) {
  const loaderContext = useContext(LoaderContext);
  const userContext = useContext(UserContext);
  const snackbarContext = useContext(SnackbarContext);

  const [formInfo, setFormInfo] = useState({ component: undefined, form: undefined, record: undefined, isPanic: undefined });

  const { id } = props;

  const dc = new dataController(model);

  const getFormInfo = (roundTrip) => {
    if (roundTrip) {
      return { component: PosebnaPonudaKruzniForm, form: "prijevoznikKruzni" };
    } else {
      return { component: PosebnaPonudaTransferForm, form: "prijevoznikTransfer" };
    }
  };

  const retrieveRecord = () => {
    loaderContext.toggleLoading(true);
    dc.GetDataSingle(id)
      .then((resp) => {
        if (resp.success) {
          const record = resp.data;
          const isRoundTrip = record.round_trip;
          const isPanic = record.panic;
          const formInfo = getFormInfo(isRoundTrip);
          setFormInfo({ ...formInfo, record: record, isPanic: isPanic });
        }
      }).catch((result) => {
      snackbarContext.showNotification(result.error, "error");
    })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  useEffect(() => {
    retrieveRecord();
  }, []);

  return formInfo.component
    ? React.createElement(formInfo.component, {
      dc: dc,
      mode: "view",
      form: formInfo.form,
      record: formInfo.record,
      isPanic: formInfo.isPanic,
      subModelDefaultValues: {
        prijevoznik_id: userContext.subjectId
      }
    })
    : null;
}

PosebnaPonudaForm.propTypes = {
  recordId: PropTypes.number
};

export default PosebnaPonudaForm;
