import React from "react";
import { useTranslation } from "react-i18next";

//Custom Components
import { GridContainer } from "UI/Grid";
import FormTabPanel from "UI/Form/FormTabPanel";
import FormContent from "Components/FormContent";

function TabPanelPosebniCjenik(props) {
  const { t } = useTranslation();

  const { currentTab, tabIndex } = props;
  const { record, validation, fields, onFieldChange, mode, subModels, dc } = props;

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels
  };

  return (
      <FormTabPanel value={currentTab} index={tabIndex}>
        <GridContainer>
          <FormContent
              {...commonProps}
              title={t("cjenici.sections.kratkotrajni_najam")}
              fieldNames={["duration_for_transfer", "distance_for_transfer", "price_for_transfer", "cbc_provizija_transfer"]}
              columns={4}
          />
          <FormContent
              {...commonProps}
              title={t("cjenici.sections.poludnevni_najam")}
              fieldNames={["duration_for_half_day", "distance_for_half_day", "price_for_half_day", "cbc_provizija_half_day"]}
              columns={4}
          />
          <FormContent
              {...commonProps}
              title={t("cjenici.sections.cijelodnevni_najam")}
              fieldNames={["duration_for_day", "distance_for_day", "price_for_day", "cbc_provizija_day"]}
              columns={4}
          />
        </GridContainer>
      </FormTabPanel>
  );
}

export default TabPanelPosebniCjenik;
