import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

//Custom components
import CardToolbarMenuItem from "UI/Card/CardToolbarMenuItem";

const CardToolbarMenuItemActivate = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return <CardToolbarMenuItem label={t("buttons.activate")} icon={<CheckCircleIcon />} {...props} ref={ref} />;
});

export default CardToolbarMenuItemActivate;
