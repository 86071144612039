import React, { Fragment, useContext } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';

// Material-UI Icons
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

//Material UI Core Components
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar
, Badge
, Card
, CardContent
, Icon
, IconButton
, Tooltip
, Typography
} from "@material-ui/core";

//Custom components
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import KomunikatorContext from "Components/KomunikatorContext/KomunikatorContext";
import withRedirect from "Components/withRedirect";

function IconLabel(props) {
  const {value, styles, icon, color} = props;

  return (
    <Fragment >
      <Icon {...color}>{icon}</Icon>
      <Typography {...color} align="left" className={styles.paragraph}>{value}</Typography>
    </Fragment>
  );
}


function KomunikatorCard(props) {
  const { job, prefix } = props;
  const { redirect } = props;
  const { t } = useTranslation();
  const KC = useContext(KomunikatorContext);

  const styles = cardStyles();

  const openChatWindow = (evt, channel) => {
    evt.preventDefault();
    evt.stopPropagation();
    KC.addNewChat(channel);
  }

  const color = job && job.panic ? {color: "error"} : {};

  return (
      <Card className={styles.card} onClick={() => redirect(`${prefix}/komunikator/${job.posao_id}`)}>
        <CardContent>
          <Typography 
            {...color} 
            variant="h5" 
            component="h5" 
            align="left" 
            style={{marginBottom: "5px", overflowWrap: "anywhere"}}
           >
             {`${job.partner || ""}`}
           </Typography>
          <Typography 
            {...color} 
            variant="h6" 
            component="h4" 
            align="left" 
            style={{marginBottom: "5px", overflowWrap: "anywhere"}}
           >
             {`${job.from_loc_name} - ${job.to_loc_name}`}
           </Typography>
          <div className={styles.tsContainer}>
            <IconLabel color={color} value={job.from_ts ? moment(job.from_ts).format("YYYY-MM-DD HH:mm") : ""} styles={styles} icon="room"/>
            <TrendingFlatIcon {...color} fontSize="large"/>
            <IconLabel color={color} value={job.to_ts ? moment(job.to_ts).format("YYYY-MM-DD HH:mm") : ""} styles={styles} icon="room"/>
          </div>
          <GridContainer direction="row" alignItems="center" justify="space-evenly">
            {job.channels.map((c, i) => (
              <GridItem key={i}>
                <Tooltip title={t(c.title)}>
                  <Badge
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    color="error"
                    badgeContent={c.unread}
                    overlap="circle"
                  >
                    <IconButton 
                      style={{ padding: 0 }} 
                      onClick={(evt) => openChatWindow(evt, c)}
                    >
                      <Avatar className={styles.avatar} style={{backgroundColor: job.color}}>{t(c.title)[0]}</Avatar>
                    </IconButton>
                  </Badge>
                </Tooltip>
              </GridItem>
            ))}
          </GridContainer>
        </CardContent>
      </Card>
  );
}


const cardStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    height: "100%",
    cursor: "pointer",
    marginRight: "20px",
    marginBottom: "8px",
    WebKitTransform: "perspective(1px) translateZ(0)",
    transform: "perspective(1px) translateZ(0)",
    WebkitTransitionDuration: "0.2s",
    TransitionDuration: "0.2s",
    WebKitTransitionProperty: "transform",
    transitionProperty: "transform",
    "&:hover":{
      WebKitTransform: "scale(0.98)",
      transform: "scale(0.98)"
    },
    "&:focus":{
      WebKitTransform: "scale(0.98)",
      transform: "scale(0.98)"
    },
    "&:active":{
      WebKitTransform: "scale(0.98)",
      transform: "scale(0.98)"
    }
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start"
  },
  paragraph: {
    margin: 0,
    fontSize: "15px"
  },
  arrows: {
    display: "flex",
    justifyContent: "center"
  },
  avatar: {
    width : "28px",
    height : "28px"
  },
  tsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  }
}));

export default withRedirect(KomunikatorCard);
