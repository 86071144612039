import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ThemeContext from "Components/ThemeContext/ThemeContext";

export default (WrappedComponent) => (props) => {
  const { handleClick, series, labels, categories, options, title } = props;
  const themeContext = useContext(ThemeContext);

  const [theme, setTheme] = useState(themeContext.theme);
  const [chartOptions, setChartOptions] = useState(null);
  const [ctx, setCtx] = useState(null);

  const { t } = useTranslation();

  const is = (d) => (d !== null && d !== undefined && Array.isArray(d) && d.length);

  const mergeOpts = (inOpts, opts) => {
  	if (!opts) return inOpts;
  	for (let key in opts) {
  		if (inOpts[key]) {
  			if (typeof opts[key] !== 'object' || Array.isArray(opts[key])) {
  				inOpts[key] = opts[key];
  			} else {
	  			inOpts[key] = mergeOpts(inOpts[key], opts[key]);
  			}
  		} else {
  			inOpts[key] = opts[key];
  		}
  	}
  	return inOpts;
  }

  useEffect(() => {
    if (is(series) && (is(labels) || is(categories))) {
      const initialOpts = {
        stroke: { show: false, curve: 'smooth' },
        plotOptions: { 
          pie: { 
            donut: {labels: {
             	show: true,
             	total: { 
             		show: true,
             		label: t('statistics.total'),
             		fontSize: "28px"
             	},
             	name: {
             		formatter: (v) => t(v)
             	}
            }},
            expandOnClick: handleClick ? false : true
          },
          bar: {
          	horizontal: true
          }
        },
        chart: { 
          events: {
            dataPointSelection: handleClick,
            beforeMount: (c) => setCtx(c)
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 1000,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          }
        },
        dataLabels: { 
          style: {
          	fontSize: "16px"
          },
          formatter: (val) => val === 0 ? '' : val
        },
        legend: {
        	formatter: (v) => t(v),
        	fontSize: "18px"
        },
        tooltip: { 
          y: { title: {
          	formatter: (v) => t(v)
          }},
          fillSeriesColor: false
        },
        title: {
        	text: t(title),
        	style: {
        		fontSize: "18px"
        	}
        },
        labels: labels || [],
        xaxis: {
        	categories: categories || [],
        	labels: {
        		formatter: (v) => t(v),
        		style: { fontSize: "14px" }
        	}
        },
        yaxis: [{
        	showAlways: false,
        	showForNullSeries: false,
        	labels: {
        		formatter: (v) => t(v).split(' '),
        		style: { fontSize: "14px" }
        	}
        }]
      }

      setChartOptions(mergeOpts(initialOpts, options));
      // setChartOptions(initialOpts);
    }
    // console.log(series, labels, categories)
  }, [series, labels, categories]);

  useEffect(() => {
    if (ctx) {
      ctx.updateOptions({
        theme: {
          palette: "palette7",
          mode: themeContext.theme
        }
      }, true, true);
    }
    setTheme(themeContext.theme);
  }, [themeContext.theme, ctx])

	return (
    chartOptions !== null ?
			<WrappedComponent 
				options={chartOptions}
				series={series}
			/>
		: null
	);
}