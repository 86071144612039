import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Icons
import CancelIcon from "@material-ui/icons/Cancel";

//Custom components
import DialogToolbarMenuItem from "UI/Dialog/DialogToolbarMenuItem";

const DialogToolbarMenuItemDeactivate = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return <DialogToolbarMenuItem label={t("buttons.deactivate")} icon={<CancelIcon />} {...props} ref={ref} />;
});

export default DialogToolbarMenuItemDeactivate;
