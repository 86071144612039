import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Divider from "@material-ui/core/Divider";
import FormButton from "UI/Form/FormButton";

//Material-UI Icons
import ClearIcon from "@material-ui/icons/Clear";

//Custom Components
import withFormController from "Components/withFormController";
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import FormContent from "Components/FormContent";
import SchedulerDrawerHeading from "Components/Scheduler/SchedulerDrawerHeading";


function SchedulerFilterForm(props) {

  const { t } = useTranslation();

  const { onFilterChange } = props;
  const { model, mode, form, record, validation, fields, subModels } = props; //HOC withFormController
  const { onFieldChange } = props; //HOC withFormController
  const { doClear } = props; //HOC withFormController
  
  const handleFieldChange = (value, source) => {

    if (onFieldChange) {
      onFieldChange(value, source, (record) => {
        if (onFilterChange) {
          onFilterChange(record);
        }
      });
    }
  };

  const handleClear = (evt) => {
    if (doClear) {
      doClear().then(resp => {
        if (resp.success) {
          if (onFilterChange) {
            onFilterChange({});
          }
        }
      });
    }
  }

  const commonFields = {
    record: record,
    validation: validation,
    fields: fields,
    onFieldChange: handleFieldChange,
    mode: mode,
    subModels: subModels,
    columns: 1
  }

  return (
    <Fragment>
      <SchedulerDrawerHeading>{t("scheduler.filter")}</SchedulerDrawerHeading>
      <Divider variant="fullWidth" />
      <GridContainer>
        <FormContent
          fieldNames={["search", "vozilo_id", "vozac_id"]}
          {...commonFields}
        />
        <GridItem xs={12}>
        <FormButton
            variant="outlined"
            startIcon={<ClearIcon />}
            onClick={handleClear}
          >
            {t("buttons.clear")}
          </FormButton>
        </GridItem>
      </GridContainer>
    </Fragment>
  )

}

export default withFormController(SchedulerFilterForm);