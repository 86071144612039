import React from "react";
import { useTranslation } from "react-i18next";

//Icons
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';

//Custom components
import CardToolbarMenuItem from "UI/Card/CardToolbarMenuItem";

const CardToolbarMenuItemResolvePanic = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return (
    <CardToolbarMenuItem
      label={t("buttons.resolve_panic")}
      icon={<SettingsBackupRestoreIcon />}
      {...props}
      ref={ref}
    />
  );
});

export default CardToolbarMenuItemResolvePanic;
