import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

//Custom components
import withFormController from "Components/withFormController";
import TabPanelUpitJavno from "Views/Upiti/Tabs/TabPanelUpitJavno";

function TabPanelUpit(props) {
  const { t } = useTranslation();

  const { currentTab, tabIndex, dc, recordId, form, internal } = props;
  const { record, fields, mode, subModels } = props; //HOC withFormController
  const { onFieldChange } = props; //HOC withFormController

  return (
    <TabPanelUpitJavno
      currentTab={currentTab}
      tabIndex={tabIndex}
      internal={internal}
      dc={dc}
      mode="view"
      recordId={recordId}
      record={record}
      form={form}
      fields={fields}
      subModels={subModels}
      onFieldChange={onFieldChange}
      showNarucitelj
    />
  );
}

TabPanelUpit.propTypes = {
  tabValue: PropTypes.number,
  tabIndex: PropTypes.number,
  dc: PropTypes.object,
  mode: PropTypes.string,
  recordId: PropTypes.number,
  form: PropTypes.string
};

export default withFormController(TabPanelUpit);
