import React, { Fragment, useContext, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

//Material UI
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

//Custom components
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import TrazilicaResultCard from "./TrazilicaResultCard";
import { searchService } from "Services/searchService";
import TrazilicaShowFilter from "./TrazilicaShowFilter";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import TrazilicaSazetak from "./TrazilicaSazetak";
import withRedirect from "Components/withRedirect";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import TrazilicaSortFilter from "./TrazilicaSortFilter";
import { firstBy } from "thenby";
import makeStyles from "@material-ui/core/styles/makeStyles";
import EuroConversionComment from "Components/EuroConversionComment";

const useStyles = makeStyles((theme) => ({
  resultTitle: {
    textTransform: "uppercase",
    color: theme.palette.secondary.main,
    fontFamily: "Lato"
  }
}));

function TrazilicaResults(props) {
  const { t } = useTranslation();
  const snackbarContext = useContext(SnackbarContext);
  const loaderContext = useContext(LoaderContext);

  const { dc, record, results, onClearSearch, onSelect, onFieldChange, redirect } = props;

  const classes = useStyles();

  const [userShow, setUserShow] = useState({
    posebne_ponude: true,
    obicne_ponude: true,
    slobodno: true,
    rezerviran: true,
    zauzeto: true
  });
  const [userSort, setUserSort] = useState({
    evaluacija: null,
    br_sjedala: null,
    godina_proizvodnje: null
  });
  const [userSearch, setUserSearch] = useState(null);

  const toggleUserShow = (event, fId) => {
    setUserShow({ ...userShow, [fId]: !userShow[fId] });
  };

  const toggleUserSort = (event, fId, value) => {
    setUserSort({ ...userSort, [fId]: value });
  };

  const handleChecked = (value, id, pp_id = null) => {
    let checked = record && Array.isArray(record.vozilo_ids) ? record.vozilo_ids : [];
    let ppIds = record && Array.isArray(record.posebna_ponuda_ids) ? record.posebna_ponuda_ids : [];

    if (value) {
      if (pp_id) {
        onFieldChange(ppIds.concat(pp_id), "posebna_ponuda_ids");
      } else {
        onSelect(checked.concat(id), onFieldChange);
      }
    } else {
      if (pp_id) {
        const updatedCheck = ppIds.filter((x) => x !== pp_id);
        onFieldChange(updatedCheck, "posebna_ponuda_ids");
      } else {
        const updatedCheck = checked.filter((x) => x !== id);
        onSelect(updatedCheck, onFieldChange);
      }
    }
  };

  const handleSend = (evt) => {
    const preparedRecord = dc.prepareSendingData(record);
    loaderContext.toggleLoading(true);
    searchService
      .sendQuote(preparedRecord)
      .then((resp) => {
        if (resp.success) {
          snackbarContext.showNotification("notifications.published_upit", "success");
          redirect("/a/upiti");
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const handleClearSearch = (evt) => {
    if (onClearSearch) {
      onClearSearch(onFieldChange);
    }
  };

  const filterRecords = useCallback((results) => {
    const filtersOn = Object.keys(userShow).some((x) => userShow[x] === true);

    return results && Array.isArray(results)
      ? filtersOn
        ? results.filter(
          (r) =>
            (userShow.posebne_ponude && r.posebna_ponuda) ||
            (userShow.obicne_ponude && !r.posebna_ponuda))
            .filter(r =>
            (userShow.slobodno && r.zauzetost.tip === 1) ||
            (userShow.rezerviran && r.zauzetost.tip === 2) ||
            (userShow.zauzeto && r.zauzetost.tip === 3)
        )
        : results
      : [];
  }, [userShow.posebne_ponude, userShow.obicne_ponude, userShow.slobodno, userShow.rezerviran, userShow.zauzeto]);

  const sortRecords = (results) => {
    const sortsOn = Object.keys(userSort).some((x) => userSort[x] !== null);

    return results && Array.isArray(results)
      ? sortsOn
        ? results.sort(
          firstBy((a, b) => {
            if (userSort.evaluacija === "ascending") {
              return a.evaluacija - b.evaluacija;
            } else if (userSort.evaluacija === "descending") {
              return b.evaluacija - a.evaluacija;
            }
          })
            .thenBy((a, b) => {
              if (userSort.br_sjedala === "ascending") {
                return a.br_sjedala - b.br_sjedala;
              } else if (userSort.br_sjedala === "descending") {
                return b.br_sjedala - a.br_sjedala;
              }
            })
            .thenBy((a, b) => {
              if (userSort.godina_proizvodnje === "ascending") {
                return a.godina_proizvodnje - b.godina_proizvodnje;
              } else if (userSort.godina_proizvodnje === "descending") {
                return b.godina_proizvodnje - a.godina_proizvodnje;
              }
            })
        )
        : results
      : [];
  };

  // .filter(r => //for search
  //     (nonEmpty(userSearch) && nonEmpty(r.opis) && r.opis.toLowerCase().indexOf(userSearch.toLowerCase()) >= 0)

  const rezultati_po_pretragu = t("titles.rezultati_po_pretragu");
  const ostali_rezultati = t("titles.ostali_rezultati");

  const selected_vozilo_ids = record && Array.isArray(record.vozilo_ids) ? record.vozilo_ids : [];
  const pp_ids = record && Array.isArray(record.posebna_ponuda_ids) ? record.posebna_ponuda_ids : [];
  const hasSelectedResults = selected_vozilo_ids.length > 0 || pp_ids.length > 0;

  const maxSelected = selected_vozilo_ids.length + pp_ids.length === 3;
  const hasResults = results.length > 0;

  const filteredResults = filterRecords(results);

  const matchedResults = sortRecords(filteredResults.filter((x) => x.sort === 1 || x.sort === 2));
  const otherResults = sortRecords(filteredResults.filter((x) => x.sort === 3));

  const resultCount = filteredResults ? filteredResults.length : 0;

  return (
    <Fragment>
      <GridContainer direciton="row" justify="flex-start" spacing={1}>
        <GridItem md={2} sm={12}>
          <GridContainer direction="column" justify="flex-start" align="flex-start" spacing={1}>
            <GridItem>
              {/*<TrazilicaSearchFilter count={resultCount} onSearch={setUserSearch}/>*/}
              <TrazilicaSazetak dc={dc} record={record} mode="simpleView" form="trazilicaSazetak" />
            </GridItem>
            <GridItem>
              <TrazilicaShowFilter
                onToggleShow={toggleUserShow}
                state={userShow}
                fieldIds={["posebne_ponude", "obicne_ponude", "slobodno", "zauzeto", "rezerviran"]}
              />
            </GridItem>
            <GridItem>
              <TrazilicaSortFilter
                onToggleSort={toggleUserSort}
                state={userSort}
                fieldIds={["evaluacija", "br_sjedala", "godina_proizvodnje"]}
              />
            </GridItem>
            <GridItem>
              <Fab
                color="secondary"
                variant="extended"
                size="medium"
                onClick={(evt) => handleClearSearch(evt)}
              >
                {t("buttons.clear_search")}
              </Fab>
            </GridItem>
            <GridItem>
              <Fab
                color="secondary"
                variant="extended"
                size="medium"
                onClick={(evt) => handleSend(evt)}
                disabled={!hasSelectedResults}
              >
                {t("buttons.send_offer")}
              </Fab>
            </GridItem>
            <GridItem>
              <EuroConversionComment />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem md={10} sm={12}>
          <Typography variant="h4" align="center" className={classes.resultTitle}>{rezultati_po_pretragu}</Typography>
          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          <GridContainer>
            {matchedResults.map((result) => {
              const checked = selected_vozilo_ids.indexOf(result.id) >= 0;
              const pp_checked = pp_ids.indexOf(result.posebna_ponuda_id) >= 0;
              const disabled = (!checked && !pp_checked && maxSelected) || result.zauzetost.tip !== 1;
              return (
                <GridItem key={"result-" + result.id}>
                  <TrazilicaResultCard
                    result={result}
                    checked={checked || pp_checked}
                    disabled={disabled}
                    onToggleCheck={handleChecked}
                  />
                </GridItem>
              );
            })}
          </GridContainer>
          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          <Typography variant="h4" align="center" className={classes.resultTitle}>{ostali_rezultati}</Typography>
          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          <GridContainer>
            {otherResults.map((result) => {
              const checked = selected_vozilo_ids.indexOf(result.id) >= 0;
              const pp_checked = pp_ids.indexOf(result.posebna_ponuda_id) >= 0;
              const disabled = (!checked && !pp_checked && maxSelected) || result.zauzetost.tip !== 1;
              return (
                <GridItem key={"result-" + result.id}>
                  <TrazilicaResultCard
                    result={result}
                    checked={checked || pp_checked}
                    disabled={disabled}
                    onToggleCheck={handleChecked}
                  />
                </GridItem>
              );
            })}
          </GridContainer>
        </GridItem>
      </GridContainer>
    </Fragment>
  );
}

TrazilicaResults.propTypes = {
  record: PropTypes.object,
  results: PropTypes.array,
  onSelect: PropTypes.func,
  onClearSearch: PropTypes.func,
  onSend: PropTypes.func
};

export default withRedirect(TrazilicaResults);
