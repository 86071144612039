import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function PoslovniSubjektAgencijaIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="m 0,0 v 18.136719 h 12.730469 c -0.100675,-0.232317 -0.175006,-0.476331 -0.19336,-0.734375 -7.05e-4,-0.08001 -0.0013,-0.160222 -0.002,-0.240234 H 4.8808594 C 4.1634925,17.262808 3.4146639,16.796574 3.1738281,16.123047 H 2.015625 v -2.015625 h 1.5800781 c 0.051013,-0.122133 0.1026109,-0.244757 0.1425781,-0.369141 0.2798886,-0.565447 0.3686151,-1.250249 0.8320313,-1.705078 0.00227,-1.725245 -6.711e-4,-3.4518192 0,-5.1777342 -0.096268,-0.827784 0.5760614,-1.647384 1.421875,-1.650391 1.3601365,-0.0029 2.7198385,-0.002 4.0800785,-0.002 L 10.076166,-4.7e-5 Z M 2.015625,2.015625 H 4.03125 V 4.03125 H 2.015625 Z m 4.029297,0 H 8.060547 V 4.03125 H 6.044922 Z M 2.015625,6.0468748 H 4.03125 v 2.013672 H 2.015625 Z m 4.060547,0.158203 c -0.2983696,0 -0.498047,0.197725 -0.498047,0.496094 v 5.9687502 c -0.198913,0 -0.3975374,0.09796 -0.4472656,0.296875 l -0.9941406,2.488281 c -0.049728,0.149185 -0.050628,0.34781 0.048828,0.447266 0.099457,0.09946 0.2492527,0.248047 0.3984375,0.248047 H 12.541016 V 12.669922 H 6.574219 V 7.1992188 h 8.453125 0.498047 0.99414 v -0.498047 c 0,-0.298369 -0.199677,-0.496094 -0.498047,-0.496094 z m 8.951172,1.988282 c -0.84538,0 -1.492188,0.646807 -1.492188,1.492187 v 7.4589842 c 0,0.845381 0.646808,1.492188 1.492188,1.492188 h 3.480468 C 19.353193,18.636719 20,17.989912 20,17.144531 V 9.6855468 c 0,-0.84538 -0.646807,-1.492187 -1.492188,-1.492187 z m 0,0.99414 h 3.480468 c 0.29837,0 0.498047,0.199678 0.498047,0.498047 V 14.658203 H 14.529297 V 9.6855468 c 0,-0.298369 0.199677,-0.498047 0.498047,-0.498047 z M 2.015625,10.076172 H 4.03125 v 2.015625 H 2.015625 Z m 13.164063,6.074219 h 3.175781 c 0.298369,0 0.496094,0.199677 0.496093,0.498047 0,0.298369 -0.197724,0.496093 -0.496093,0.496093 h -3.175781 c -0.29837,0 -0.496094,-0.197724 -0.496094,-0.496093 0,-0.29837 0.197724,-0.498047 0.496094,-0.498047 z" />
    </SvgIcon>
  );
}

export default PoslovniSubjektAgencijaIcon;