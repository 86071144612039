import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";

//Material-UI Icons
import SendIcon from "@material-ui/icons/Send";

//Custom Components
import withFormController from "Components/withFormController";
import DynamicDialog from "UI/Dialog/DynamicDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import UpitAvatar from "UI/Form/Avatars/UpitAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButton from "UI/Dialog/DialogActionButton";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import { googleService } from "Services/googleService";
import GoogleMapRoute from "Components/Google/GoogleMapRoute";
import { GridContainer, GridItem } from "UI/Grid";
import FormContent from "Components/FormContent";
import withRedirect from "Components/withRedirect";
import EuroConversionComment from "Components/EuroConversionComment";

function UpitZaPosebnuPonuduDialog(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const loaderContext = useContext(LoaderContext);
  const { t } = useTranslation();

  const { dc, record, validation, fields, subModels, mode } = props; //HOC withFormController
  const { doClose, doValidate } = props; //HOC withFormController
  const { onFieldChange, onClose, doInsert } = props; //HOC withFormController
  const { posebnaPonudaId } = props;
  const { redirect } = props;

  const fFromLoc = "from_loc";
  const fToLoc = "to_loc";
  const fVia = "stajalista";
  const fRoute = "ruta";

  const fTrajanjeVoznje = "trajanje_voznje";
  const fNetoUdaljenostVoznje = "neto_udaljenost_voznje";
  const fUdaljenostPovratka = "udaljenost_povratka_vozila";

  const handleFieldChange = (value, source) => {
    if (source === fFromLoc) {
      if (record[fToLoc]) {
        calcRoute(value, record[fVia], record[fToLoc]);
      }
    } else if (source === fVia) {
      if (record[fFromLoc] && record[fToLoc]) {
        calcRoute(record[fFromLoc], value, record[fToLoc]);
      }
    } else if (source === fToLoc) {
      if (record[fFromLoc]) {
        calcRoute(record[fFromLoc], record[fVia], value);
      }
    }

    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  useEffect(() => {
    if (record[fFromLoc] && record[fToLoc]) {
      calcRoute(record[fFromLoc], record[fRoute], record[fToLoc]);
    }
  }, [record[fFromLoc], record[fToLoc]]);

  const calcRoute = (from_loc, via, to_loc) => {
    const originCoords = from_loc ? from_loc.coords : null;
    const destCoords = to_loc ? to_loc.coords : null;

    const viaPoints = Array.isArray(via) ? via.filter((x) => x.value) : null;

    if (originCoords && destCoords) {
      googleService.route(from_loc, to_loc, viaPoints).then((resp) => {
        if (resp.success) {
          const duration = resp.data.duration;
          const length = resp.data.length;
          let returnLength = { value: 0, label: "0 km" };

          googleService.route(to_loc, from_loc, [], false).then((respReturn) => {
            if (respReturn.success) {
              returnLength = respReturn.data.length;

              onFieldChange(resp.data.routes, fRoute);
              onFieldChange(duration, fTrajanjeVoznje);
              onFieldChange(length, fNetoUdaljenostVoznje);

              onFieldChange(returnLength, fUdaljenostPovratka);
            }
          });
        }
      });
    } else {
      if (onFieldChange) {
        onFieldChange(null, fRoute);
      }
    }
  };

  const handleCreateUpit = (evt) => {
    if (doInsert) {
      loaderContext.toggleLoading(true);
      doInsert()
        .then((result) => {
          if (result.success) {
            const id = result.id;
            snackbarContext.showNotification("insert", "success");
            redirect(`/a/upiti/${id}#interni-podaci`);
          }
        })
        .catch((result) => {
          snackbarContext.showNotification(result.error, "error");
        })
        .finally(() => {
          loaderContext.toggleLoading(false);
        });
    }
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const close = (result) => {
    if (result.dataChanged) {
      onClose({ dataChanged: true, action: result.action, id: result.id });
    }

    dialogContext.hideDialog();
  };

  const commonProps = {
    record: record,
    mode: mode,
    validation: validation,
    onFieldChange: handleFieldChange,
    fields: fields,
    subModels: subModels
  };

  return (
    <DynamicDialog onClose={handleClose} fixedHeight={true}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <UpitAvatar />
          <DialogToolbarHeading header={t("titles.upit_novi_pp")} />
          <DialogToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <GridContainer>
                <FormContent
                  {...commonProps}
                  title={t("upit.sections.prijevoznik")}
                  fieldNames={["prijevoznik_naziv", "prijevoznik_ocjena"]}
                  columns={2}
                />
                <FormContent
                  {...commonProps}
                  mode="view"
                  title={t("upit.sections.vozilo")}
                  fieldNames={[
                    "vozilo_naziv",
                    "vozilo_broj_sjedala",
                    "vozilo_tip_id",
                    "vozilo_klasa_id",
                    "klasa_motora_id",
                    "vozilo_oprema_ids"
                  ]}
                  columns={2}
                />
                <FormContent
                  {...commonProps}
                  title={"Podaci o upitu"}
                  fieldNames={["vozilo_broj_putnika", "opis"]}
                  columns={2}
                />
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <GridContainer>
                <FormContent
                  {...commonProps}
                  title={t("upit.sections.ruta")}
                  fieldNames={["from_ts", "to_ts", "from_loc", "stajalista", "to_loc"]}
                  columns={1}
                />
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <GoogleMapRoute
                height={600}
                origin={record ? record.from_loc : null}
                destination={record ? record.to_loc : null}
                stops={
                  record && Array.isArray(record.stajalista)
                    ? record.stajalista.filter((x) => x.value).map((x) => x.value)
                    : []
                }
                route={record && record.ruta ? record.ruta[0] : null}
              />
            </GridItem>
            <FormContent
              {...commonProps}
              title={t("upit.sections.podaci_o_putovanju")}
              fieldNames={["trajanje_voznje", "neto_udaljenost_voznje", "udaljenost_povratka_vozila"]}
              columns={3}
            />
            <FormContent
              {...commonProps}
              title={t("upit.sections.ponuda")}
              fieldNames={["cijena_hrk", "rok_za_ponude"]}
              columns={3}
            />
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <EuroConversionComment />
        <DialogToolbarFillContent />
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
        <DialogActionButton variant="contained" onClick={handleCreateUpit} startIcon={<SendIcon />}>
          {t("buttons.send")}
        </DialogActionButton>
      </DialogActions>
    </DynamicDialog>
  );
}

export default withRedirect(withFormController(UpitZaPosebnuPonuduDialog));
