import api from "Lib/api";

export const alarmsService = {
  setAlarm: setAlarm
}

const alarmsApiUrl = 'alarms/';

function setAlarm(object_type, object_id, record){
  const apiInstance = new api();
  const path = alarmsApiUrl;
  const data = {
    object_type: object_type,
    object_id: object_id,
    alarm_ts: record.alarm_ts,
    alarm_active: record.alarm_active,
    alarm_remark: record.alarm_remark
  }
  return apiInstance.Call(path, "put", data);
}
