import React from "react";

//Material-UI Core Components
import { useTheme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Icon } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

//Material-UI Icons
import ErrorIcon from "@material-ui/icons/Error";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

// Custom Components
import SchedulerGridBarIcons from "Components/Scheduler/SchedulerGridBarIcons";

const voznjaStatusColor = {
  20: "#DDDBDF",
  21: "#C7C5C9",
  22: "#FCF2A3",
  23: "#F1D852",
  24: "#91D0C0",
  25: "#5AB786",
  26: "#67A2CB",
  29: "#A1CDE0"
};

const zadaciBorderColor = {
  0: "rgb(236, 64, 122)",
  30: "rgb(0, 85, 160)",
  40: "rgb(244, 143, 177)",
}

function SchedulerGridBar(props) {
  const { r, dayOffset, gridWidth } = props;
  const { onClick, onRangeMove, onCellLeave, handleToggleGrouped, onNeedRefresh } = props;
  const { dcVozilo, dcVozac, dcPosao, dcPojedinacnaVoznja, dcRezervacija } = props;

  // new props:
  const { userDetails, conflicts, res_h } = props;
  const theme = useTheme();

  const getData = (r) => {
    let croppedLeft = false;
    let croppedRight = false;

    let translateX = r.offset + dayOffset;
    let w = translateX < 0 ? r.width + translateX : r.width;
    if (translateX < 0) {
      croppedLeft = true;
      translateX = 0;
    }

    if (w + translateX > gridWidth) {
      croppedRight = true;
      w = gridWidth - translateX;
    }

    return [translateX, w, croppedLeft, croppedRight];
  }

  const getBarStyle = (r) => {
    // const transform = "translateX(" + (r.offset + dayOffset + divisions * (cell_width - 1)) + "px )";

    const [translateX, w] = getData(r);

    const transform = "translateX(" + translateX + "px )";
    const width = w + "px";
    const height = "32px";

    let backgroundColor = theme.palette.common.white;
    let boxShadow = null;

    if (r.maintype === 1) {
      // TODO:
      // change this when you get according data
      if (r.isSubJob) {
        const statusVoznje = r.posao_state_id - 200;
        const color1 = voznjaStatusColor[statusVoznje];
        const color2 = theme.palette.common.white;
        const background = "repeating-linear-gradient( 45deg, " +
          color1 +  ", " +
          color1 + " 6px, " +
          color2 + " 6px, " +
          color2 + " 12px)";

        return {
          transform: transform,
          width: width,
          height: height,
          background: background,
          boxShadow: boxShadow
        };
      } else {
        backgroundColor = voznjaStatusColor[r.posao_state_id];
        return {
          transform: transform,
          width: width,
          height: height,
          backgroundColor: backgroundColor,
          boxShadow: boxShadow
        }
      }
    } else if (r.maintype === 2) {
      const val = Math.floor(r.subtype / 10) * 10;
      const borderColor = zadaciBorderColor[val];

      return {
        transform: transform,
        width: width,
        height: height,
        border: "solid 2px " + borderColor,
        boxShadow: boxShadow
      };
    } else {
      return {
        transform: transform,
        width: width,
        height: height,
        border: "2px solid " + theme.palette.primary.main,
        backgroundColor: theme.palette.common.white
      };
    }
  };

  const handleBarClick = (id) => {
    // probably open some dialog regarding the id passed here
  }

  const handleHide = () => {
    // hide subjobs for a trjob
  }

  const handleIconClick = (dataObj) => {
    // open dialog (or something alike) for the icon clicked
  }

  const cell_width = 15; // what does this do?
  const divisions = Math.floor(24.0 / res_h);
  const { registracija, vozac, opis } = userDetails;
  // vozilo and vozac are submodel for pickers, while trjob and trsubjob are deprecated for a long time now
  //    think how to change those two, and why are they even here
  const modelVozilo = null, modelVozac = null, modelTrJob = null, modelTrSubJob = null;
  // this boy has to do something with that icon dropdown, cant remember what exactly
  const conflictsActive = {
    trjobId: null,
    id: null,
    from: null,
    to: null
  }

  const [translateX, w, croppedLeft, croppedRight] = getData(r);

  const getDCRecord = (r) => {
    switch (r.maintype) {
      case 1:
        return dcPosao;
      case 2:
        return dcRezervacija;
      case 3:
        return dcPojedinacnaVoznja;
      default:
        return null;
    }

  }

  const dcRecord = getDCRecord(r);

  return (
    <span className="scheduler-record">
      <Tooltip
        // title={Array.isArray(r.opis) ? r.opis.map((ln, lni) => (
        //   <div key={lni}>{ln}</div>
        // )) : ""}
        title={r.tooltip_lbl || r.lbl || r.opis || ""}
        key={r.id}
        placement="top-start"
        arrow={true}
      >
        <span
          data-tip
          data-for={"datatip" + r.id}
          className={r.class + " scheduler-span" + (r.panic ? " scheduler-panic" : "")}
          style={{...getBarStyle(r), margin: "8px 0"}}
          onClick={() => onClick(r)}
          onMouseMove={onRangeMove}
          onMouseLeave={onCellLeave}
        >
          <div className="scheduler-span-leftstop"></div>
          <div className="scheduler-span-lbl">
            {/* <div className="scheduler-span-lbl-value" style={{height: "24px"}}>&nbsp;{}</div> */}
            <div className="scheduler-span-lbl-value" style={{ height: "24px" }}>
              {Array.isArray(r.conflict) && r.conflict.length !== 0 && r.icon === "ungroup" ? (
                <ErrorIcon
                  style={{
                    color: theme.palette.error.dark,
                    float: "left",
                    diplay: "inline",
                    marginRight: "5px"
                  }}
                  fontSize="small"
                />
              ) : null}
              {croppedLeft ? <Icon color="primary" fontSize="inherit">chevron_left</Icon> : null}
              {r.lbl}
              {croppedRight ? <Icon color="primary" fontSize="inherit">chevron_right</Icon> : null}
              {/*
                Change this, it renders an object instead of function/component
                !(r.tip >= 220 && r.tip < 230) ? r.content : " "
              */}
            </div>
            <div className="scheduler-span-lbl-hover"></div>
          </div>
          <div className="scheduler-span-rightstop"></div>
        </span>
      </Tooltip>
      <span
        data-tip
        data-for={"datatip" + r.id}
        style={{
          display: "inline-block",
          //transform: `translateX(${r.offset + dayOffset + divisions * (cell_width - 1) + 5}px)`,
          transform: "translateX(" + translateX + "px )",
          height: "32px"
        }}
        className="scheduler-span-extra"
        //onMouseMove={this.range_move.bind(this)}
        //onMouseLeave={onCellLeave}
        onClick={() => handleBarClick(r)}
      >
      <SchedulerGridBarIcons
        content={{ show: (r.tip >= 220 && r.tip < 230), data: r.content }}
        conflicts={conflicts}
        timeSpan={{from: r.ts1, to: r.ts2}}
        icon={r.icon}
        hasSubJobs={r.hasSubJobs}
        grouped={r.grouped}
        handleToggleGrouped={handleToggleGrouped}
        id={r.id || r.trsubjobid} ///*@Piero change this with coop with @Vanja*/
        //handleHide={this.item_group_state.bind(this)}
        showDetails={{
          vozilo: { state: !croppedRight && registracija, content: r.content ? r.content.vozilo : null },
          vozac: { state: !croppedRight && vozac, content: r.content ? r.content.vozaci : null },
          opis: {
            state: !croppedRight && opis /*&& r.tip >= 220 && r.tip < 230*/,
            content: r.content ? r.content.opis : null
          },
          cooperant: { state: true, content: r.content ? r.content.kooperant : null }
        }}
        subjobs={r.tip >= 20 && r.tip < 30 ? r.subjobs || [] : []}
        onIconClick={handleIconClick}
        onTextClick={null}
        allConflicts={conflicts}

        dcVozilo={dcVozilo}
        dcVozac={dcVozac}
        dcRecord={dcRecord}
        dcPojedinacnaVoznja={dcPojedinacnaVoznja}
        posaoId={r.trjob}
        voznjaId={r.maintype === 3 ? r.id : null}
        rezervacijaId={r.maintype === 2 ? r.id : null}
        ponudaId={r.maintype === 4 ? r.id : null}
        onNeedRefresh={onNeedRefresh}
        isPanic={r.panic}
        trjobId={r.trjobId}
        conflictsActive={conflictsActive}
        styleBasedConflict={getBarStyle(r, divisions).boxShadow !== null}
      />
      </span>
    </span>
  );
}

export default SchedulerGridBar;
