import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Custom Components
import ModelTable from "UI/Table/ModelTable";
import TableCard from "UI/Table/TableCard";
import TableCardContent from "UI/Table/TableCardContent";
import DialogContext from "UI/DialogContext/DialogContext";
import model from "Models/eksterniTroskovi";
import dataController from "Lib/dataController";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import UserContext from "Components/UserContext/UserContext";
import TroskoviDialog from "./TroskoviDialog";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

function TroskoviTable(props) {
  const dialogContext = useContext(DialogContext);
  const loaderContext = useContext(LoaderContext);
  const userContext = useContext(UserContext);
  const snackbarContext = useContext(SnackbarContext);

  const { t } = useTranslation();
  const [records, setRecords] = useState([]);

  const dc = new dataController(model);

  useEffect(() => {
    if (userContext.subjectId) {
      refreshRecords();
    }
  }, [userContext.subjectId]);

  const handleAdd = (evt) => {
    dialogContext.showDialog(TroskoviDialog, {
      dc: dc,
      mode: "insert",
      form: "insert",
      onClose: handleDialogClose
    });
  };

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords();
    }
  };

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData()
      .then((resp) => {
        if (resp.success) {
          setRecords(resp.data || []);
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const handleRowAction = function(action, id) {
    if (action === "edit") {
      dialogContext.showDialog(TroskoviDialog, {
        dc: dc,
        recordId: id,
        mode: "update",
        form: "update",
        onClose: handleDialogClose
      });
    }
  };

  const allowRowAction = function(action, id) {
    return true;
  };

  return (
    <TableCard>
      <TableCardContent>
        <ModelTable
          title={t("titles.eksterniTroskovi")}
          dc={dc}
          records={records}
          allowExport={true}
          allowFilter={true}
          allowSelection="none"
          allowAdd={true}
          handleAdd={handleAdd}
          addLabel={t("buttons.new_n")}
          viewName="admin"
          onRowAction={handleRowAction}
          allowRowAction={allowRowAction}
          onDoubleClick={(id) => handleRowAction("edit", id)}
        />
      </TableCardContent>
    </TableCard>
  );
}

export default TroskoviTable;
