//USED IN POSLOVI

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Icons
import AddCircleIcon from "@material-ui/icons/AddCircle";

//Custom components
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import { GridContainer, GridItem } from "UI/Grid";
import { FormButton } from "UI/Form";
import FormContent from "Components/FormContent";
import ModelTable from "UI/Table/ModelTable";
import FinancijskaKalkulacijaDialog from "Views/Prijevoznici/Voznje/VoznjaFormTabDialogs/FinancijskaKalkulacijaDialog";
import { posloviService } from "Services/posloviService";
import dataController from "Lib/dataController";
import model from "Models/fincalc";

function TabInternaKalkulacija(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const loaderContext = useContext(LoaderContext);

  const { t } = useTranslation();
  const [records, setRecords] = useState([]);

  const { currentTab, tabValue } = props;
  const { recordId } = props;
  const { record, validation, fields, onFieldChange, mode, subModels, isAdmin, isPanic, allowAction = true } = props;

  const fEksterniTrosakHelper = "eksterni_trosak_helper";

  const dc = new dataController(model, `poslovi/${record.id}/financijske-kalkulacije`);

  const handleAdd = (evt) => {
    dialogContext.showDialog(FinancijskaKalkulacijaDialog, {
      dc: dc,
      mode: "insert",
      parentMode: mode,
      form: "popis_finCalc",
      onClose: handleDialogClose
    });
  };

  const openFinCalcDialog = (id) => {
    dialogContext.showDialog(FinancijskaKalkulacijaDialog, {
      dc: dc,
      mode: record.posao_state_id === 29 || isPanic || !allowAction ? "view" : "update",
      parentMode: mode,
      form: "popis_finCalc",
      recordId: id,
      onClose: handleDialogClose
    });
  };

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords();
    }
    dialogContext.hideDialog();
  };

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData()
      .then((resp) => {
        if (resp.success) {
          setRecords(Array.isArray(resp.data) ? resp.data : []);
          const iznos = resp.data && resp.data.length > 0
            ? resp.data
                .filter((x) => x["iznos_bruto"])
                .map((x) => x["iznos_bruto"])
                .reduce((a, b) => a + b, 0)
            : null;
          onFieldChange(iznos, "iznos_fincalc");
        } else {
          snackbarContext.showNotification(resp.error, "error");
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const addEksterniTrosak = () => {
    const eksterniTroskovi = subModels.eksterni_trosak_helper.records;
    const selectedPickerElements = record.eksterni_trosak_helper;

    if (selectedPickerElements.length > 0) {
      const chosenRecords = selectedPickerElements
          .map((selected) => {
            const tempItem = eksterniTroskovi.find((current) => current.value === selected.value);
            const inRecords = records.some((record) => record.value === selected.value);
            if (!inRecords) {
              return tempItem;
            }
          })
          .filter((record) => record !== undefined);
      insertExterniTroskovi(chosenRecords);
    }
  };

  const insertExterniTroskovi = (chosenRecords) => {
    const troskovi = chosenRecords.map((record) => {
      return {
        opis: record.label,
        iznos_neto: record.iznos_neto,
        pdv_stopa: record.pdv_stopa,
        pdv_iznos: record.pdv_iznos,
        iznos_bruto: record.iznos_bruto
      }
    });
    loaderContext.toggleLoading(true);
    posloviService.addFromEksterniTroskovi(recordId,troskovi).then((resp) =>{
      if(resp.success){
        refreshRecords()
        onFieldChange(null, fEksterniTrosakHelper);
      }else{
        snackbarContext.showNotification("insert","error")
      }
    }).catch((err) => {
      console.error(err);
    }).finally(() => {
      loaderContext.toggleLoading(false);
    })
  }

  useEffect(() => {
    if (currentTab === tabValue) {
      refreshRecords();
    }
  }, [currentTab]);

  const handleRowAction = function (action, id) {
    if (action === "edit" || action === "view") {
      openFinCalcDialog(id);
    }
  };

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels
  };

  const pickerRecords = record.eksterni_trosak_helper ? record.eksterni_trosak_helper : [];

  return (
    <GridContainer>
      <GridItem xs={12}>
        <GridContainer>
          <FormContent
              title={t("trjob.sections.eksterni_troskovi")}
              {...commonProps}
              fieldNames={["eksterni_trosak_helper"]}
              columns={2}
          />
          <GridItem xs={2} sm={4} md={4}>
            <FormButton variant="outlined" startIcon={<AddCircleIcon />} onClick={addEksterniTrosak} disabled={mode === "view"}>
              {pickerRecords.length === 1 || pickerRecords.length === 0
                  ? t("trjob.add_trosak")
                  : t("trjob.add_troskovi")}
            </FormButton>
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs={12}>
        <ModelTable
          title={t("titles.interna_kalkulacija")}
          records={records}
          dc={dc}
          viewName={record.posao_state_id === 29 || isPanic || !allowAction ? "readonly" : "interna_kalkulacija"}
          allowExport={false}
          allowFilter={false}
          allowAdd={mode === "update" && !isAdmin && !isPanic && allowAction}
          handleAdd={handleAdd}
          addLabel={t("buttons.new_f")}
          allowSelection="none"
          onRowAction={handleRowAction}
        >
          {/* <TableButtonView onClick={handleView} /> */}
        </ModelTable>
      </GridItem>
      <FormContent fieldNames={["iznos_fincalc", "iznos_hrk"]} {...commonProps} columns={2} />
    </GridContainer>
  );
}

export default TabInternaKalkulacija;
