import React from "react";

//Material-UI Core Components
import Box from "@material-ui/core/Box";

//Custom Components
import PrijevozniciPage from "UI/AppPage/PrijevozniciPage";
import NotificationsTable from "Views/Notifications/NotificationsTable";

const PrijevozniciNotificationsPage = React.memo((props) => {

  return (
    <PrijevozniciPage>
      <Box m={2}>
        <NotificationsTable />
      </Box>
    </PrijevozniciPage>
  );
})

export default PrijevozniciNotificationsPage;
