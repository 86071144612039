import api from "Lib/api";

export const statisticsService = {
  getAdminStatistics,
  getPrijevzonikStatistics,
  getJobs,
  getUsers,
  getBS
};

const statisticsApiUrl = 'statistics'
const apiInstance = new api();

function getAdminStatistics(){
  const path = statisticsApiUrl + "/admin";
  return apiInstance.Call(path, "get");
}

function getPrijevzonikStatistics(){
  const path = statisticsApiUrl + "/prijevoznik";
  return apiInstance.Call(path, "get");
}

function getJobs() {
  return apiInstance.Call('admin/voznje', 'get');
}

function getUsers() {
  return apiInstance.Call('users/', 'get');
}

function getBS() {
  return apiInstance.Call('business-subjects/', 'get');
}