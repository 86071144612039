import React from "react";
import { useTranslation } from "react-i18next";

//Materual-UI Icons
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

//Custom Components
import DialogActionButton from "UI/Dialog/DialogActionButton";

function DialogActionButtonDelete(props) {
  const { t } = useTranslation();

  return (
    <DialogActionButton startIcon={<DeleteForeverIcon />} {...props} aria-label="delete" aria-controls="delete">
      {t("buttons.delete")}
    </DialogActionButton>
  );
}

export default DialogActionButtonDelete;
