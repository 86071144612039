import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI
import Switch from "@material-ui/core/Switch";

//Materual-UI Icons
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";

//Custom Components
import CardActionButton from "UI/Card/CardActionButton";

function CardActionButtonShowVoznje(props) {
  const { t } = useTranslation();

  const { showTab, onToggle, ...otherProps } = props;

  const isOn = showTab ? true : false;

  return (
    <CardActionButton
    onClick={onToggle}
    startIcon={<AirportShuttleIcon />}
    endIcon={<Switch checked={isOn} size="small" color="secondary" />}
    aria-label="toggle"
    aria-controls="Toggle"
    variant={"outlined"}
    {...otherProps}
    >
      {isOn ? t("buttons.hide_voznje") : t("buttons.show_voznje")}
    </CardActionButton>
  );
}

export default CardActionButtonShowVoznje;
