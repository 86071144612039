import { coreFields } from "./helpers/coreFields";
import { alarmFields } from "./helpers/alarmFields";
import { buttonFields } from "./helpers/buttonFields";

const sectionVoznje = ["km_calc_voznje", "min_calc_voznje", "km_manual", "min_manual"];
const sectionFinCalc = ["iznos_fincalc", "iznos_fincalc_override", "eksterni_trosak_helper"];
const sectionOsnovniPodaci = [
  "from_ts",
  "to_ts",
  "relacija_opis_skraceni",
  "broj_ponude",
  "iznos_hrk",
  "cbc_provizija_perc",
  "cbc_provizija_iznos",
  "iznos_s_cbc_provizijom",
  "datum_ponude",
  "rok_potvrda",
  "napomena",
  "vozilo_id",
  "vozilo_opis"
];
const sectionAvans = ["rok_avans_uplate", "avans_iznos"];
const sectionSubmitted = ["submitted_on", "submitted_by"];

export default {
  title: "Ponuda",
  exportFileName: "Ponuda",
  apiPath: "ponude/",
  forms: {
    insert: {
      fields: [
        ...sectionVoznje,
        ...sectionFinCalc,
        ...sectionOsnovniPodaci,
        ...sectionAvans
      ],
      validator: "voziloRequired"
    },
    update: {
      fields: [
        ...sectionVoznje,
        ...sectionFinCalc,
        ...sectionOsnovniPodaci,
        ...sectionAvans
      ],
      validator: "voziloRequired"
    },
    status10: {
      fields: [
        ...sectionVoznje,
        ...sectionFinCalc,
        ...sectionOsnovniPodaci,
        ...sectionAvans
      ],
      validator: "voziloRequired"
    },
    status11: {
      fields: [
        ...sectionVoznje,
        ...sectionFinCalc,
        ...sectionOsnovniPodaci,
        ...sectionAvans,
        ...sectionSubmitted
      ]
    },
    status13: {
      fields: [
        ...sectionVoznje,
        ...sectionFinCalc,
        ...sectionOsnovniPodaci,
        ...sectionAvans,
        ...sectionSubmitted,
        "panic"
      ]
    },
    status18: {
      fields: [
        ...sectionVoznje,
        ...sectionFinCalc,
        ...sectionOsnovniPodaci,
        ...sectionAvans,
        ...sectionSubmitted,
        "panic"
      ]
    },
    status19: {
      fields: [
        ...sectionVoznje,
        ...sectionFinCalc,
        ...sectionOsnovniPodaci,
        ...sectionAvans,
        ...sectionSubmitted,
        "panic"
      ]
    },
    admin: {
      fields: [
        "relacija_opis_skraceni",
        "broj_ponude",
        "datum_ponude",
        "rok_potvrda",
        {
          source: "vozilo_id",
          subModel: {
            apiPath: "vozila/?need_for=picker",
            format: "{label}, {registracija}"
          }
        },
        "vozilo_opis",
        "napomena",
        "iznos_hrk",
        "cbc_provizija_perc",
        "cbc_provizija_iznos",
        "iznos_s_cbc_provizijom"
      ]
    },
    public: {
      fields: ["iznos_s_cbc_provizijom", "rok_potvrda", "relacija_opis_skraceni", "napomena", "vozilo_naziv", "vozilo_opis"]
    }
  },
  listViews: {
    moje_ponude: {
      fields: [
        "id",
        "upit_id",
        "upit_published_on",
        "narucitelj_naziv",
        "ponuda_state_id",
        "view_pp",
        "relacija_opis_skraceni",
        "vozilo_combined",
        "from_ts",
        "to_ts",
        "upit_rok_za_ponude",
        "iznos_s_cbc_provizijom",
        "rok_potvrda",
        "active",
        "view",
        "panic"
      ],
      hidden: ["id", "upit_id", "from_ts", "to_ts", "panic"]
    },
    upit_ponude: {
      fields: [
        "id",
        "prijevoznik_naziv",
        "iznos_s_cbc_provizijom",
        "rok_potvrda",
        "ponuda_state_id",
        "vozilo_naziv",
        "evaluacija_prijevoznika",
        "submitted_on",
        "active",
        "view"
      ],
      hidden: ["id", "submitted_on", "active"]
    },
    default: {
      fields: ["id", "iznos_hrk", "rok_potvrda", "ponuda_state_id"]
    }
  },
  fields: [
    coreFields.id,
    coreFields.active,

    alarmFields.alarm_ts,
    alarmFields.alarm_active,
    alarmFields.alarm_remark,
    {
      title: "Ponuda state",
      source: "ponuda_state_id",
      ttoken: "ponuda.ponuda_state_id",
      type: "iconStatus",
      items: {
        labels: [
          "ponuda.statuses.10",
          "ponuda.statuses.11",
          "ponuda.statuses.12",
          "ponuda.statuses.13",
          "ponuda.statuses.14",
          "ponuda.statuses.15",
          "ponuda.statuses.16",
          "ponuda.statuses.18",
          "ponuda.statuses.19",
          "ponuda.statuses.99"
        ],
        values: [10, 11, 12, 13, 14, 15, 16, 18, 19, 99],
        icons: ["drafts", "send", "help", "thumb_up", "assignment_turned_in", "commute", "home", "remove_circle",
          "archive", "thumb_down"]
      },
      customSort: "elementProperty",
      translate: true
    },
    {
      title: "Prijevoznik ID",
      source: "prijevoznik_id",
      ttoken: "ponuda.prijevoznik_id",
      type: "numeric"
    },
    {
      title: "Prijevoznik",
      source: "prijevoznik_naziv",
      ttoken: "common.prijevoznik",
      type: "text",
      readonly: true
    },
    {
      title: "Prijevoznik ocjena",
      source: "prijevoznik_ocjena",
      ttoken: "common.ocjena",
      type: "numeric",
      readonly: true
    },
    {
      title: "Upit ID",
      source: "upit_id",
      ttoken: "ponuda.upit_id",
      type: "numeric"
    },
    {
      title: "Cijena izračunata fincalc",
      source: "iznos_fincalc",
      ttoken: "ponuda.iznos_fincalc",
      tooltip: "ponuda.helpers.iznos_fincalc",
      type: "currency",
      currency: "€",
      readonly: true,
      calculated: true,
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Cijena izračunata fincalc override",
      source: "iznos_fincalc_override",
      ttoken: "ponuda.iznos_fincalc_override",
      tooltip: "ponuda.helpers.iznos_fincalc_override",
      type: "currency",
      currency: "€",
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "",
      source: "eksterni_trosak_helper",
      ttoken: "posebna_ponuda.externi_troskovi",
      type: "picker",
      multi: true,
      subModel: {
        apiPath: "eksterni-troskovi/?need_for=picker",
        format: "'{label}' {iznos_bruto} €"
      }
    },
    {
      title: "Cijena bruto",
      source: "iznos_hrk",
      ttoken: "ponuda.cijena_bruto",
      type: "currency",
      currency: "€",
      validation: {
        required: true,
        maxValue: 99999999
      }
    },
    {
      title: "Postotak CBC provizije",
      source: "cbc_provizija_perc",
      ttoken: "ponuda.cbc_provizija_perc",
      type: "numeric",
      readonly: true,
      validation: {
        required: true
      }
    },
    {
      title: "Iznos CBC provizije",
      source: "cbc_provizija_iznos",
      ttoken: "ponuda.cbc_provizija_iznos",
      type: "currency",
      currency: "€",
      readonly: true,
      validation: {
        required: true,
        maxValue: 99999999
      }
    },
    {
      title: "Cijena ponudjena (bruto + CBC provizija)",
      source: "iznos_s_cbc_provizijom",
      ttoken: "ponuda.cijena_ponudjena",
      type: "currency",
      currency: "€",
      readonly: true,
      validation: {
        required: true,
        maxValue: 99999999
      }
    },
    {
      title: "Napomena",
      source: "napomena",
      ttoken: "ponuda.napomena",
      type: "multiline",
      validation: {
        required: true,
        maxLength: 4000
      },
      rows: 5,
      width: "full"
    },
    {
      title: "Vozilo ID",
      source: "vozilo_id",
      ttoken: "ponuda.vozilo_id",
      type: "picker",
      subModel: {
        apiPath: "prijevoznici/{prijevoznik_id}/vozila?need_for=picker",
        format: "{label}, {registracija}"
      }
      // multi: true
    },
    {
      title: "Vozilo Naziv",
      source: "vozilo_naziv",
      ttoken: "common.vozilo",
      type: "link",
      link: {
        recordIdField: "vozilo_id",
        recordType: "vozilo"
      }
    },
    {
      title: "Vozilo - opis",
      source: "vozilo_opis",
      ttoken: "ponuda.vozilo_opis",
      type: "text",
      validation: {
        maxLength: 100
      }
    },
    {
      title: "Vozilo - combined",
      source: "vozilo_combined",
      ttoken: "common.vozilo",
      type: "text",
      readonly: true
    },
    {
      title: "Evualacijska Ocijena",
      source: "evaluacija_prijevoznika",
      ttoken: "ponuda.evaluacija_ocjena",
      type: "rating",
      max: 10,
      precision: 0.5
    },
    {
      title: "Rok za Avansna Uplata",
      source: "rok_avans_uplate",
      ttoken: "ponuda.rok_avansna_uplata",
      type: "datetime"
    },
    {
      title: "Iznos Avansna",
      source: "avans_iznos",
      ttoken: "ponuda.iznos_avansna",
      type: "currency",
      validation: {
        maxValue: 99999999
      }
    },
    {
      title: "Agencija",
      source: "narucitelj_naziv",
      ttoken: "common.narucitelj",
      type: "text",
      readonly: true
    },
    {
      title: "Upit Published On",
      source: "upit_published_on",
      ttoken: "common.published_on",
      type: "datetime",
      readonly: true
    },
    {
      title: "Rok za Ponude",
      source: "upit_rok_za_ponude",
      ttoken: "upit.rok_za_ponude",
      type: "datetime",
      readonly: true
    },
    {
      title: "Datum početka prijevoza",
      source: "from_ts",
      ttoken: "ponuda.from_ts",
      type: "datetime",
      validation: {
        required: true
      },
      readonly: true
    },
    {
      title: "Datum završetka prijevoza",
      source: "to_ts",
      ttoken: "ponuda.to_ts",
      type: "datetime",
      validation: {
        required: true,
        isAfter: "from_ts",
        isAfterToken: "ponuda.from_ts"
      },
      readonly: true
    },
    {
      title: "Kratki opis relacije",
      source: "relacija_opis_skraceni",
      ttoken: "ponuda.relacija_opis_skraceni",
      type: "text",
      validation: {
        required: true,
        maxLength: 500
      },
      width: "full"
    },
    {
      title: "Broj ponude",
      source: "broj_ponude",
      ttoken: "ponuda.broj_ponude",
      type: "text",
      validation: {
        required: true,
        maxLength: 20
      }
    },
    {
      title: "Datum ponude",
      source: "datum_ponude",
      ttoken: "ponuda.datum_ponude",
      type: "date",
      readonly: true,
      validation: {
        required: true
      }
    },
    {
      title: "Rok za Potvrdu",
      source: "rok_potvrda",
      ttoken: "ponuda.rok_za_potvrdu",
      type: "datetime",
      validation: {
        required: true
      }
    },
    {
      title: "Ukupno km",
      source: "km_calc_voznje",
      ttoken: "ponuda.km_calc_voznje",
      tooltip: "ponuda.helpers.km_calc_voznje",
      type: "wholenum",
      readonly: true,
      calculated: true,
      validation: {
        maxValue: 2147483647
      }
    },
    {
      title: "Ukupno min",
      source: "min_calc_voznje",
      ttoken: "ponuda.min_calc_voznje",
      tooltip: "ponuda.helpers.min_calc_voznje",
      type: "wholenum",
      readonly: true,
      calculated: true,
      validation: {
        maxValue: 2147483647
      }
    },
    {
      title: "Manual km",
      source: "km_manual",
      ttoken: "ponuda.km_manual",
      tooltip: "ponuda.helpers.km_manual",
      type: "wholenum",
      validation: {
        maxValue: 2147483647
      }
    },
    {
      title: "Manual min",
      source: "min_manual",
      ttoken: "ponuda.min_manual",
      tooltip: "ponuda.helpers.min_manual",
      type: "wholenum",
      validation: {
        maxValue: 2147483647
      }
    },
    {
      title: "Submitted On",
      source: "submitted_on",
      ttoken: "ponuda.submitted_on",
      type: "datetime",
      readonly: true
    },
    {
      title: "Submitted By",
      source: "submitted_by",
      ttoken: "ponuda.submitted_by",
      type: "text",
      readonly: true
    },
    {
      title: "Panic",
      source: "panic",
      ttoken: "common.panic",
      type: "panic",
      items: {
        labels: ["common.ne", "common.da"],
        values: [false, true]
      },
      readonly: true,
      translate: true
    },
    buttonFields.edit,
    buttonFields.view,
    buttonFields.view_pp
  ]
};
