import React from "react";
import { useParams } from "react-router-dom";

//Custom components
import AdminPage from "UI/AppPage/AdminPage";
import dataController from "Lib/dataController";
import model from "Models/vozila";
import VoziloForm from "Views/Prijevoznici/Vozila/VoziloForm";
import Box from "@material-ui/core/Box";

const AdminVoziloView = React.memo((props) => {
  const { id } = useParams();

  const dc = new dataController(model);

  return (
    <AdminPage>
      <Box m={2}>
        <VoziloForm dc={dc} recordId={id} form="update" mode="update" />
      </Box>
    </AdminPage>
  );
})

export default AdminVoziloView;
