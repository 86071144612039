import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

//Material UI
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";

//Material-UI Icons
import HelpIcon from "@material-ui/icons/Help";
import InfoIcon from "@material-ui/icons/Info";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import MoneyIcon from "@material-ui/icons/Money";
import AssignmentIcon from "@material-ui/icons/Assignment";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ChatIcon from "@material-ui/icons/Chat";
import GradeIcon from "@material-ui/icons/Grade";

//CBC Icons
import PotvrdaRezervacijeIcon from "Icons/PotvrdaRezervacijeIcon";

//Custom components
import withFormController from "Components/withFormController";
import { FormHeading, FormHeadingID, FormFillContent, FormTab, FormTabPanel, FormTabs } from "UI/Form";

import TabFinancijskeObaveze from "Views/Prijevoznici/Voznje/VoznjaFormTabs/TabFinancijskeObaveze";
import TabInternaKalkulacija from "Views/Prijevoznici/Voznje/VoznjaFormTabs/TabInternaKalkulacija";
import TabPotvrdaRezervacije from "Views/Prijevoznici/Voznje/VoznjaFormTabs/TabPotvrdaRezervacije";
import TabOsnovniPodaci from "Views/Prijevoznici/Voznje/VoznjaFormTabs/TabOsnovniPodaci";
import TabPojedinacneVoznje from "Views/Prijevoznici/Voznje/VoznjaFormTabs/TabPojedinacneVoznje";
import TabPlatforma from "Views/Prijevoznici/Voznje/VoznjaFormTabs/TabPlatforma";
import TabPutniNalozi from "Views/Prijevoznici/Voznje/VoznjaFormTabs/TabPutniNalozi";
import TabFaktura from "Views/Prijevoznici/Voznje/VoznjaFormTabs/TabFaktura";
import TabPonuda from "Views/Prijevoznici/Voznje/VoznjaFormTabs/TabPonuda";
import TabEvaluacije from "Views/Prijevoznici/Voznje/VoznjaFormTabs/TabEvaluacije";

import CardHeading from "UI/Card/CardHeading";
import VoznjaAvatar from "UI/Form/Avatars/VoznjaAvatar";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogToolbarMenuItemCopy from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemCopy";
import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import { CardColored, CardToolbarButtonAlarm, CardToolbarButtonBack } from "UI/Card";
import PrintButton from "UI/Buttons/PrintButton";
import dataController from "Lib/dataController";
import eval_model from "Models/a_evaluacija";
import { posloviStatuses, retrievePosloviStatusLabel } from "Lib/statuses";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import KomunikatorSinglePage from "Views/Komunikator/KomunikatorSinglePage";
import DialogToolbarMenuItemPosebnaPonudaCreate from "UI/Dialog/ToolbarMenuItems/DialogToolbarMenuItemPosebnaPonuda";
import EuroConversionComment from "Components/EuroConversionComment";

function PlatformskiPosaoForm29(props) {
  const snackbarContext = useContext(SnackbarContext);

  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();

  const [currentTab, setCurrentTab] = useState(0);

  const { dc, record, mode, validation, fields, subModels } = props; //HOC withFormController
  const { onFieldChange, doUpdate, doRefresh } = props; //HOC withFormController
  const { handleCopy, handleStorniraj, handleStornirajCopy, handleRefreshForm } = props;
  const { isAdmin, isPanic } = props;
  const { ownerId } = props;

  const eval_dc = new dataController(eval_model, `poslovi/${record.id}/evaluacija-narucitelja`);

  const handleTabChange = (evt, value) => {
    setCurrentTab(value);
  };

  const handlePrint = () => {
    snackbarContext.showNotification("notifications.in_dev", "info");
  };

  const commonTabProps = {
    currentTab: currentTab,
    dc: dc,
    subModels: subModels,
    record: record,
    mode: "view",
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    isAdmin: isAdmin,
    recordId: record.id
  };

  const handleClickBack = () => {
    history.goBack();
  };

  const title = t(retrievePosloviStatusLabel(record.posao_state_id));

  const identifier = dc.getSource() + record.posao_state_id;
  const hasEvaluacija = record.evaluacija_id && record.evaluacija_id !== null;
  const isStorno = record.storno === true;
  const isAgentura = record.agentura === true;

  const printButtonTarget = currentTab === 6 ? "potvrda_rezervacije" : null;
  const printButtonText = currentTab === 6 ? t("buttons.potvrda_rezervacije") : t("buttons.print");
  const printButtonDisabled = !(currentTab === 6);

  const customColor = isStorno ? theme.palette.custom.storno :
    isPanic ? theme.palette.custom.panic :
    isAgentura ? theme.palette.custom.agentura :
    undefined;

  const customText = isStorno ? t("messages.storno") :
    isPanic ? t("messages.panic") :
    isAgentura ? t("messages.agentura") :
    undefined;

  return (
    <CardColored color={customColor}>
      <CardHeading recordStatus={record.posao_state_id} statuses={posloviStatuses} color={customColor}>
        <Toolbar variant="dense" disableGutters={true}>
          <VoznjaAvatar />
          <FormHeading header={title} subheaderContent={<FormHeadingID id={record.id} />} />
          <DialogToolbarFillContent>
            {customText ?
              <Typography variant="button">{customText}</Typography>
              : null
            }
          </DialogToolbarFillContent>
          {
            !props.isPanic ?
              <Fragment>
                <CardToolbarButtonAlarm record={record} type="voznja" onFieldChange={onFieldChange} />
                <DialogToolbarMenuButton>
                  <DialogToolbarMenuItemCopy onClick={() => handleCopy(record)} />
                  <DialogToolbarMenuItemPosebnaPonudaCreate record={record} />
                </DialogToolbarMenuButton>
              </Fragment>
              : null
          }
          <CardToolbarButtonBack onClick={handleClickBack} />
        </Toolbar>
      </CardHeading>
      <CardContent style={{ paddingTop: 0 }}>
        <FormTabs value={currentTab} onChange={handleTabChange} identifier={identifier}>
          <FormTab id="platforma" label={t("poslovi.tabs.platforma")} icon={<HelpIcon />} aria-label="platforma" />
          <FormTab
            id="osnovni-podaci"
            label={t("poslovi.tabs.osnovni_podaci")}
            icon={<InfoIcon />}
            aria-label="osnovni_podaci"
          />
          <FormTab
            id="pojedinacne-voznje"
            label={t("poslovi.tabs.pojedinacne_voznje")}
            icon={<AirportShuttleIcon />}
            aria-label="pojedinacne_voznje"
          />
          <FormTab
            id="interna-kalkulacija"
            label={t("poslovi.tabs.interna_kalkulacija")}
            icon={<MoneyIcon />}
            aria-label="interna_kalkulacija"
          />
          <FormTab
            id="ponuda"
            label={t("poslovi.tabs.ponuda")}
            icon={<AssignmentIcon />}
            aria-label="ponuda"
          />
          <FormTab
            id="financijske-obveze"
            label={t("poslovi.tabs.financijske_obveze")}
            icon={<MonetizationOnIcon />}
            aria-label="financijske_obveze"
          />
          <FormTab
            id="potvrda-rezervacije"
            label={t("poslovi.tabs.potvrda_rezervacije")}
            icon={<PotvrdaRezervacijeIcon />}
            aria-label="potvrda_rezervacije"
          />
          <FormTab
            id="komunikator"
            label={t("poslovi.tabs.komunikator")}
            icon={<ChatIcon />}
            aria-label="komunikator"
          />
          <FormTab
            id="putni-nalozi"
            label={t("poslovi.tabs.putni_nalozi")}
            icon={<AssignmentIndIcon />}
            aria-label="putni_nalozi"
          />
          <FormTab
            id="faktura"
            label={t("poslovi.tabs.faktura")}
            icon={<ReceiptIcon />}
            aria-label="faktura"
          />
          <FormTab
            id="evaluacija"
            label={t("poslovi.tabs.evaluacija")}
            icon={<GradeIcon/>}
            aria-label="evaluacija"
          />
        </FormTabs>
        <FormTabPanel value={currentTab} index={0}>
          <TabPlatforma {...commonTabProps} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={1}>
          <TabOsnovniPodaci {...commonTabProps} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={2}>
          <TabPojedinacneVoznje {...commonTabProps} tabValue={2} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={3}>
          <TabInternaKalkulacija {...commonTabProps} tabValue={3} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={4}>
          <TabPonuda {...commonTabProps} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={5}>
          <TabFinancijskeObaveze {...commonTabProps} tabValue={5} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={6}>
          <TabPotvrdaRezervacije {...commonTabProps} index={6} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={7}>
          <KomunikatorSinglePage recordId={record.id} refreshForm={handleRefreshForm} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={8}>
          <TabPutniNalozi {...commonTabProps} posao_state_id={record.posao_state_id} tabValue={8} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={9}>
          <TabFaktura {...commonTabProps} tabValue={9} />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={10}>
          <TabEvaluacije
            dc={eval_dc}
            mode={"view"}
            form="default"
            refreshParent={doRefresh}
            recordId={hasEvaluacija ? record.evaluacija_id : null}
            hasEvaluacija={true}
            isPosao={true}
            isPanic={isPanic}
            tabValue={10}
            defaultValues={{
              ocjena_ukupno: 5,
              grupa_cistoca_i_urednost: 5,
              grupa_tocnost: 5,
              grupa_nagrada: 5,
              osoblje_informacije: 5,
              osoblje_organizacija: 5,
              osoblje_dostupnost: 5,
              prateci_priprema: 5,
              prateci_komunikacija: 5,
              prateci_agilnost: 5
            }}
          />
        </FormTabPanel>
      </CardContent>
      <CardActions>
        <EuroConversionComment />
        <FormFillContent />
        <PrintButton
          dc={dc}
          target={printButtonTarget}
          record={record}
          text={printButtonText}
          disabled={printButtonDisabled}
          platformskiPosao={true}
        />
      </CardActions>
    </CardColored>
  );
}

export default withFormController(PlatformskiPosaoForm29);
