import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import numbro from "numbro";

//Material UI
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { Card, Typography } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";

//Material-UI Icons
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import PublicIcon from "@material-ui/icons/Public";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ImageIcon from "@material-ui/icons/Image";

//Custom components
import withFormController from "Components/withFormController";
import { GridContainer, GridItem } from "UI/Grid";
import { CardHeading, CardToolbarMenuButton, CardToolbarButtonBack, CardToolbarMenuItemDelete} from "UI/Card";
import { CardActionButtonClose, CardActionButtonAdd, CardActionButtonSave} from "UI/Card";
import { FormTabs, FormTab, FormTabPanel } from "UI/Form";
import { FormAvatar, FormHeading, FormHeadingID, FormFillContent } from "UI/Form";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import TabPanelDodatniCjenici from "./Tabs/TabPanelDodatniCjenici";
import TabPanelRasporedCjenika from "./Tabs/TabPanelRasporedCjenika";
import TabPanelImages from "./Tabs/TabPanelImages";
import VoziloAvatar from "UI/Form/Avatars/VoziloAvatar";
import FormContent from "Components/FormContent";
import { mapIcons } from "Lib/mapIcons";
import { conversions } from "Lib/conversions";
import GoogleMap from "Components/Google/GoogleMap";
import { Permissions } from "Lib/permissions";
import EuroConversionComment from "Components/EuroConversionComment";

function VoziloForm(props) {
  const {t} = useTranslation();
  const history = useHistory();
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);

  const [currentTab, setCurrentTab] = useState(0);
  const [pinPoint, setPinPoint] = useState(null);

  const {dc, record, recordId, validation, fields, subModels, mode, dataChanged} = props; //HOC withFormController
  const {doUpdate, doDelete } = props; //HOC withFormController
  const {onFieldChange} = props; //HOC withFormController

  const fPricePerKm = "price_per_distance";
  const fFullDayDistance = "distance_for_day";
  const fFullDayPrice = "price_for_day";
  const fLocation = "lokacija";

  const handleTabChange = (evt, value) => {
    setCurrentTab(value);
  };

  const handleClickBack = () => {
    history.goBack();
  };

  const handleSetMarker = (data) => {
    const {value, label, coords} = data;

    const icon = mapIcons.pinPointIcon();
    const marker = {id: value, label: label, coords: coords, icon: icon};

    setPinPoint(marker);
  }

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      let price = null;

      if (source === fLocation) {
          handleSetMarker(value)
      }

      if (source === fPricePerKm) {
        if (value && record[fFullDayDistance]) {
          price = numbro(value).value() * numbro(record[fFullDayDistance]).value()
          onFieldChange(price, fFullDayPrice)
        } else {
          onFieldChange(null, fFullDayPrice)
        }
      }

      if (source === fFullDayDistance) {
        if (value && record[fPricePerKm]) {
          price = numbro(value).value() * numbro(record[fPricePerKm]).value()
          onFieldChange(price, fFullDayPrice)
        } else {
          onFieldChange(null, fFullDayPrice)
        }
      }

      onFieldChange(value, source);
    }
  };

  const handleUpdate = (evt) => {
    if (doUpdate) {
      doUpdate().then((result) => {
        if (result.success) {
          close({dataChanged: true, action: "update"});
          snackbarContext.showNotification("update", "success");
        }
      }).catch((result) => {
        if (result.success === false && result.validationPass === false) {
          //do nothing
        } else {
          snackbarContext.showNotification(result.error, "error");
        }
      });
    }
  };

  const handleDelete = (evt) => {
    if (doDelete) {
      doDelete().then((result) => {
        if (result.success) {
          snackbarContext.showNotification("delete", "success");
          history.goBack();
        }
      }).catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      });;
    }
  };

  useEffect(() => {
    if(record['loc_geo'] && record['loc_id'] && record['loc_name']) {
      const marker = {
        value: record['loc_id'],
        label: record['loc_name'],
        coords: conversions.wkt_to_latLng(record['loc_geo'])
      }
      handleFieldChange(marker, "lokacija")
    }
  }, [record])

  const commonTabProps = {
    currentTab: currentTab,
    record: record,
    recordId: recordId,
    subModels: subModels,
    validation: validation,
    fields: fields,
    mode: mode,
    dc: dc
  };

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: handleFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels,
    currentTab: currentTab
  };

  const title = record.marka_tip;

  return (
      <Card>
        <CardHeading>
          <Toolbar variant="dense" disableGutters={true}>
            <VoziloAvatar/>
            <FormHeading header={title} subheaderContent={<FormHeadingID id={recordId} />} dataChanged={dataChanged} />
            <FormFillContent/>
            <CardToolbarMenuButton needPermission={[Permissions.AdminProfilesManage]}>
              <CardToolbarMenuItemDelete onClick={handleDelete} />
            </CardToolbarMenuButton>
            <CardToolbarButtonBack onClick={handleClickBack}/>
          </Toolbar>
        </CardHeading>
        <CardContent style={{paddingTop: 0}}>
          <FormTabs value={currentTab} onChange={handleTabChange} identifier={dc.getSource()}>
            <FormTab
                id="osnovni-podaci"
                label={t("vozila.tabs.osnovni_podaci")}
                icon={<BusinessCenterIcon/>}
                aria-label="osnovni-podaci"
                fields={["business_subject_id", "public", "marka_tip", "reg_oznaka", "br_sjedala", "extra_sjedala", "tip_id", "klasa_id", "klasa_motora_id", "godina_proizvodnje", "vozilo_oprema_ids", "dozvoli_djecje_grupe", "opis_hr", "opis_en"]}
                validation={validation}
            />
            <FormTab
                id="osnovni-cjenik"
                label={t("vozila.tabs.osnovni_cjenik")}
                icon={<MonetizationOnIcon/>}
                aria-label="osnovni-cjenik"
                fields={["price_per_distance", "price_for_transfer", "distance_for_transfer", "duration_for_transfer", "price_for_half_day", "distance_for_half_day", "duration_for_half_day", "price_for_day", "distance_for_day", "duration_for_day"]}
                validation={validation}
            />
            <FormTab id="dodatni-cjenici" label={t("vozila.tabs.cjenici")} icon={<MonetizationOnIcon/>} aria-label="cjenici"/>
            <FormTab id="raspored-cjenika" label={t("vozila.tabs.alokacija_cjenika")} icon={<DateRangeIcon/>} aria-label="alokacija_cjenika"/>
            <FormTab id="images" label={t("vozila.tabs.images")} icon={<ImageIcon/>} aria-label="images"/>
          </FormTabs>
          <FormTabPanel value={currentTab} index={0}>
            <GridContainer>
              <FormContent
                  {...commonProps}
                  title={t("vozila.sections.vlasnistvo")}
                  fieldNames={["business_subject_id", "public"]}
                  columns={2}
              />
              <FormContent
                  {...commonProps}
                  title={t("vozila.sections.podaci")}
                  fieldNames={[
                    "marka_tip",
                    "reg_oznaka",
                    "br_sjedala",
                    "extra_sjedala",
                    "tip_id",
                    "klasa_id",
                    "klasa_motora_id",
                    "godina_proizvodnje",
                    "vozilo_oprema_ids",
                    "dozvoli_djecje_grupe",
                    "opis_hr",
                    "opis_en"
                  ]}
                  columns={2}
              />
            </GridContainer>
          </FormTabPanel>
          <FormTabPanel value={currentTab} index={1}>
            <GridContainer>
              <GridItem md={12} sm={12}>
                <GridContainer direction="row" spacing={2}>
                  <GridItem md={3} sm={3}>
                    <GridContainer direction="column" spacing={0}>
                      <FormContent
                          {...commonProps}
                          title={t("cjenici.sections.lokacija")}
                          fieldNames={["lokacija"]}
                          columns={1}
                      />
                      <FormContent
                          {...commonProps}
                          title={t("cjenici.sections.osnovno")}
                          fieldNames={["price_per_distance"]}
                          columns={1}
                      />
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={9} md={9}>
                    <GoogleMap markers={pinPoint ? [pinPoint] : null} height={280} allowInteraction={true} zoomIn={true}/>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <FormContent
                  {...commonProps}
                  title={t("cjenici.sections.kratkotrajni_najam")}
                  fieldNames={["duration_for_transfer", "distance_for_transfer", "price_for_transfer"]}
                  columns={3}
              />
              <FormContent
                  {...commonProps}
                  title={t("cjenici.sections.poludnevni_najam")}
                  fieldNames={["duration_for_half_day", "distance_for_half_day", "price_for_half_day"]}
                  columns={3}
              />
              <FormContent
                  {...commonProps}
                  title={t("cjenici.sections.cijelodnevni_najam")}
                  fieldNames={["duration_for_day", "distance_for_day", "price_for_day"]}
                  columns={3}
              />
            </GridContainer>
          </FormTabPanel>
          <FormTabPanel value={currentTab} index={2}>
            <TabPanelDodatniCjenici tabIndex={2} {...commonTabProps} recordId={recordId}/>
          </FormTabPanel>
          <FormTabPanel value={currentTab} index={3}>
            <TabPanelRasporedCjenika tabIndex={3} {...commonTabProps} recordId={recordId}/>
          </FormTabPanel>
          <FormTabPanel value={currentTab} index={4}>
            <TabPanelImages tabIndex={4} {...commonTabProps} />
          </FormTabPanel>
          <GridContainer direction="row" alignItems="center" justify="flex-end" spacing={1}></GridContainer>
        </CardContent>
        <CardActions>
          <EuroConversionComment />
          <FormFillContent/>
          <CardActionButtonClose onClick={handleClickBack} variant="outlined"/>
          {mode === "update" ? <CardActionButtonSave onClick={handleUpdate} variant="contained" /> : null}
        </CardActions>
      </Card>
  );
}

export default withFormController(VoziloForm);
