import React, { Fragment, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

//Material-UI Core Components
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";

//Material-UI Icons
import MoneyIcon from "@material-ui/icons/Money";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";

//Custom Components
import withRedirect from "Components/withRedirect";
import withFormController from "Components/withFormController";
import { 
  CardColored, 
  CardActionButtonClose, 
  CardHeading, 
  CardToolbarButtonBack, 
  CardToolbarMenuButton, 
  CardToolbarMenuItemDelete 
} from "UI/Card";
import { FormFillContent, FormHeading, FormTab, FormTabs } from "UI/Form";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import TabPanelFinancijskaKalkulacijaPPTransfer from "Views/Prijevoznici/PosebnePonude/Tabs/TabPanelFinancijskaKalkulacijaPPTransfer";
import TabPanelDetalji from "Views/Prijevoznici/PosebnePonude/Tabs/TabPanelDetalji";
import TabPanelPosebnePonudeUpiti from "Views/Prijevoznici/PosebnePonude/Tabs/TabPanelPosebnePonudeUpiti";
import upitModel from "Models/upiti";
import dataController from "Lib/dataController";
import PosebnaPonudaAvatar from "UI/Form/Avatars/PosebnaPonudaAvatar";
import { posebnaPonudaStatuses } from "Lib/statuses";
import CardSOSHeading from "UI/Card/CardSOSHeading"
import EuroConversionComment from "Components/EuroConversionComment";

function PosebnaPonudaTransferForm(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();

  const snackbarContext = useContext(SnackbarContext);

  const [currentTab, setCurrentTab] = useState(1);


  const { record, mode, validation, fields, subModels, dc } = props; //HOC withFormController
  const { doClose } = props; //HOC withFormController
  const { onFieldChange } = props; //HOC withFormController
  const { redirect } = props; //HOC withRedirect

  const upitDC = new dataController(upitModel);

  record.round_trip = false;

  const handleClickBack = () => {
    history.goBack();
  };

  const handleTabChange = (evt, value) => {
    setCurrentTab(value);
  };

  const handleDelete = () => {
    dc.DeleteRecord(record.id).then((result) => {
      if (result.success) {
        snackbarContext.showNotification("delete", "success");
        redirect("/p/posebne-ponude");
      }
    }).catch((result) => {
      snackbarContext.showNotification(result.error, "error");
    });
    ;
  };

  const commonProps = {
    currentTab: currentTab,
    record: record,
    mode: mode,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    subModels: subModels
  };

  const subtitle = `(ID:${record.id})`;

  const recordStatus = record ? record.posebna_ponuda_state_id : 11;
  const isUgovoren = record.posebna_ponuda_state_id === 13;

  const isStorno = record.storno === true;
  const isAgentura = record.agentura === true;
  const { isPanic } = props;

  const customColor = isStorno ? theme.palette.custom.storno :
    isPanic ? theme.palette.custom.panic :
    isAgentura ? theme.palette.custom.agentura :
    undefined;

  const customText = isStorno ? t("messages.storno") :
    isPanic ? t("messages.panic") :
    isAgentura ? t("messages.agentura") :
    theme.palette.custom.pp;

  return (
    <CardColored color={customColor}>
      <CardHeading recordStatus={recordStatus} statuses={posebnaPonudaStatuses} color={customColor}>
        <Toolbar variant="dense" disableGutters={true}>
          <PosebnaPonudaAvatar />
          <FormHeading header={t("titles.posebna_ponuda_transfer")} subheader={subtitle} />
          {/*<CardSOSHeading isPanic={props.isPanic} />*/}
          <FormFillContent />
          {customText ?
            <Typography variant="button">{customText}</Typography>
            : null
          }
          <FormFillContent />
          {
            !props.isPanic ?
              <Fragment>
                {(mode === "update" || mode === "view") && record.posebna_ponuda_state_id !== 11 ? (
                  <CardToolbarMenuButton>
                    <CardToolbarMenuItemDelete onClick={handleDelete} />
                  </CardToolbarMenuButton>
                ) : null}
              </Fragment>
              : null
          }
          <CardToolbarButtonBack onClick={handleClickBack} />
        </Toolbar>
      </CardHeading>
      <CardContent style={{ padding: 0 }}>
        <FormTabs value={currentTab} onChange={handleTabChange}>
          <FormTab
            value={0}
            id="detalji"
            label={t("posebna_ponuda.tabs.detalji")}
            icon={<ViewModuleIcon />}
            validation={validation}
            fields={["vozilo_id", "naziv", "rok_potvrda", "comment",
              "from_ts", "to_ts",
              "from_loc", "from_loc_radius",
              "to_loc", "to_loc_radius",
              "price_per_distance", "pdv_stopa",
              "pdv_stopa_manual"]}
          />
          <FormTab
            value={1}
            id="fin-calc"
            label={t("posebna_ponuda.tabs.fin_calc")}
            icon={<MoneyIcon />}
          />
          <FormTab
            value={2}
            id="upiti"
            label={t("posebna_ponuda.tabs.upiti")}
            icon={<LocalOfferIcon />}
            validation={validation}
            fields={[]}
          />
        </FormTabs>
        <TabPanelDetalji transfer={true} tabIndex={0} {...commonProps} />
        <TabPanelFinancijskaKalkulacijaPPTransfer tabIndex={1} {...commonProps} recordId={record.id} />
        <TabPanelPosebnePonudeUpiti tabIndex={2} {...commonProps} recordId={record.id} isUgovoren={isUgovoren} />
      </CardContent>
      <CardActions>
        <EuroConversionComment />
        <FormFillContent />
        <CardActionButtonClose variant="outlined" onClick={handleClickBack} />
      </CardActions>
    </CardColored>
  );
}

export default withRedirect(withFormController(PosebnaPonudaTransferForm));
