import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Box from "@material-ui/core/Box";

//Custom Components
import PrijevozniciPage from "UI/AppPage/PrijevozniciPage";
import PrijevoznikStatisticsView from "../Statistics/PrijevoznikStatisticsView";

const PrijevozniciHomePage = React.memo((props) => {

  return (
    <PrijevozniciPage>
      <Box m={2}>
        <PrijevoznikStatisticsView/>
      </Box>
    </PrijevozniciPage>
  );
});

export default PrijevozniciHomePage;
