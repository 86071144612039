import { createContext } from 'react';

const UserContext = createContext({
  loaded: false,
  username: null,
  user: null,
  userId: null,
  userSettings: {},
  roleId: null,
  setUserData: () => {},
  setUserSettings: () => {},
  // hasAnyRole: () => {},
  hasAnyPermission: () => {},
  isFavoritePrijevoznik: () => {},
  isFavoriteAgencija: () => {},
  toggleFavoritePrijevoznik: () => {},
  toggleFavoriteAgencija: () => {},
  getRecent: () => {},
  setRecent: () => {},
  setRecentFilters: () => {},
  getRecentTab: () => {},
  setRecentTab: () => {},
});
UserContext.displayName = 'UserContext';

export default UserContext
