let googleApi = function () {};

const apiKey = process.env.REACT_APP_GOOGLE_APIKEY;

googleApi.prototype.CallDirections = function (origin, destination, via, avoidTolls) {
  // const url = path + "&key=" + apiKey;

  const google = window.google;

  if (google) {
    const directionsService = new google.maps.DirectionsService();

    const promise = directionsService.route({
      origin: { placeId: origin.value },
      destination: { placeId: destination.value },
      waypoints: Array.isArray(via) ? via.map((x) => ({ location: { placeId: x.value.value }, stopover: true })) : [],
      travelMode: google.maps.TravelMode.DRIVING,
      avoidTolls: avoidTolls
    });

    if (promise !== undefined) {
      return promise;
    } else {
      return Promise.reject({status: "CallError"});
    }
  } else {
    console.warn("window.google not defined!");
    return Promise.resolve({status: "Error"});
  }





  // return fetch(url, {
  //   method: "GET",
  //   headers: {
  //     'Access-Control-Allow-Origin': 'http://localhost:9001/'
  //   }
  // })
  //   .then(response => {
  //     if (!response.ok) {
  //       return Promise.reject(response.statusText);
  //     }
  //     return response.text()
  //   })
  //   .then(text => {
  //     const data = text && JSON.parse(text);
  //     return data;
  //   })
  //   .then(jsondata => {
  //     return { success: true, data: jsondata };
  //   })
  //   .catch(error => {
  //     return { success: false, error: error };
  //   })
  //   .finally(() => {});
};

export default googleApi;
