import React from "react";

//Custom Components
import PrijevozniciPage from "UI/AppPage/PrijevozniciPage";
import ResponsiveBox from "UI/Box/ResponsiveBox";
import PrijevozniciKorisniciTable from "Views/Prijevoznici/Korisnici/PrijevozniciKorisniciTable";

const PrijevozniciKorisniciPage = React.memo((props) => {
  return (
    <PrijevozniciPage>
      <ResponsiveBox fillHeight>
        <PrijevozniciKorisniciTable />
      </ResponsiveBox>
    </PrijevozniciPage>
  );
});

export default PrijevozniciKorisniciPage;
