import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";

import FormTabPanel from "UI/Form/FormTabPanel";
import { GridContainer, GridItem } from "UI/Grid";
import FormContent from "Components/FormContent";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import dataController from "Lib/dataController";
import model from "Models/posebnePonude";

export default function TabPanelPosebnaPonuda(props) {
	const {t} = useTranslation();
	const loaderContext = useContext(LoaderContext);
	const { currentTab, tabIndex, recordId } = props;
	const [record, setRecord] = useState(null);
	const [transfer, setTransfer] = useState(false);

	const dc = new dataController(model);

	const retrieveRecord = () => {
    loaderContext.toggleLoading(true);
    dc.GetDataSingle(recordId)
      .then((resp) => {
        if (resp.success) {
          const record = resp.data;
					if(record.to_loc) {
						setTransfer(true)
					}
          setRecord(record);
        }
      }).catch((err) => {
      console.error(err);
    })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

	useEffect(() => {
		retrieveRecord();
	}, [])

	const posebnaPonudaTransferFieldNames = ["price_per_distance", "vozilo_naziv", "akcijska_cijena_uk",
		"iznos_fincalc", "iznos_fincalc_manual", "pdv_iznos", "pdv_stopa_manual", "cijena_s_pdv_iznos", "cijena_s_fincalc_iznos", "cbc_provizija_iznos",
		"uk_bruto_hrk", "uk_bruto_hrk_manual", "rok_potvrda", "interna_napomena", "opis",
		"from_ts", "to_ts", "from_loc_name", "from_loc_radius", "to_loc_name", "to_loc_radius",
		"trajanje_voznje", "neto_udaljenost_voznje", "udaljenost_povratka_vozila", "round_trip"];
	const posebnaPonudaKruznoFieldNames = ["distance_for_day", "distance_for_half_day", "distance_for_transfer", "duration_for_day", "duration_for_half_day", "duration_for_transfer", "price_for_day", "price_for_half_day", "price_for_transfer"];

	const commonPosebnaPonudaTransferProps = {
		dc: dc,
		record: record,
		fields: dc.getFields(posebnaPonudaTransferFieldNames),
		mode: "simpleView",
		columns: 1
	};

	const commonPosebnaPonudaKruzniProps = {
		dc: dc,
		record: record,
		fields: dc.getFields(posebnaPonudaKruznoFieldNames),
		mode: "simpleView",
		columns: 1
	};

	return (
		<FormTabPanel value={currentTab} index={tabIndex}>
			<Box m={2}>
				<GridContainer direction="row">
					<GridItem xs={12} sm={3}>
						<GridContainer>
							<FormContent
								title={t("titles.osnovni_podaci")}
								{...commonPosebnaPonudaTransferProps}
								fieldNames={["round_trip", "price_per_distance", "vozilo_naziv", "interna_napomena", "rok_potvrda", "opis"]}
							/>
						</GridContainer>
					</GridItem>
					{record && record["round_trip"] ? (
						<Fragment>
							<GridItem xs={12} sm={3}>
								<GridContainer>
									<FormContent
										title={t("cjenici.sections.kratkotrajni_najam")}
										fieldNames={["duration_for_transfer", "distance_for_transfer", "price_for_transfer"]}
										{...commonPosebnaPonudaKruzniProps}
									/>
								</GridContainer>
							</GridItem>
							<GridItem xs={12} sm={3}>
								<GridContainer>
									<FormContent
										title={t("cjenici.sections.poludnevni_najam")}
										fieldNames={["duration_for_half_day", "distance_for_half_day", "price_for_half_day"]}
										{...commonPosebnaPonudaKruzniProps}
									/>
								</GridContainer>
							</GridItem>
							<GridItem xs={12} sm={3}>
								<GridContainer>
									<FormContent
										title={t("cjenici.sections.cijelodnevni_najam")}
										fieldNames={["duration_for_day", "distance_for_day", "price_for_day"]}
										{...commonPosebnaPonudaKruzniProps}
									/>
								</GridContainer>
							</GridItem>
						</Fragment>
					) : null}
					<GridItem xs={12} sm={3}>
						<GridContainer>
							<FormContent
								title={t("posebna_ponuda.sections.termin")}
								fieldNames={["from_ts", "to_ts"]}
								{...commonPosebnaPonudaTransferProps}
							/>
						</GridContainer>
					</GridItem>
					<GridItem xs={12} sm={3}>
						<GridContainer>
							<FormContent
								title={t("posebna_ponuda.sections.location")}
								fieldNames={record && record["round_trip"] ? ["from_loc_name", "from_loc_radius"] : ["from_loc_name", "from_loc_radius", "to_loc_name", "to_loc_radius"]}
								{...commonPosebnaPonudaTransferProps}
							/>
						</GridContainer>
					</GridItem>
					{record && record["round_trip"] ? null : (
						<GridItem xs={12} sm={3}>
							<GridContainer>
								<FormContent
									title={t("posebna_ponuda.sections.izracun")}
									fieldNames={["akcijska_cijena_uk", "pdv_iznos", "pdv_stopa_manual", "cijena_s_pdv_iznos", "iznos_fincalc",
										"cijena_s_fincalc_iznos", "cbc_provizija_iznos", "uk_bruto_hrk", "uk_bruto_hrk_manual"]}
									{...commonPosebnaPonudaTransferProps}
								/>
							</GridContainer>
						</GridItem>
					)}
					{record && record["round_trip"] ? null : (
						<GridItem xs={12} sm={3}>
							<GridContainer>
								<FormContent
									{...commonPosebnaPonudaTransferProps}
									title={t("upit.sections.podaci_o_putovanju")}
									fieldNames={["trajanje_voznje", "neto_udaljenost_voznje", "udaljenost_povratka_vozila"]}
								/>
							</GridContainer>
						</GridItem>
					)}
				</GridContainer>
			</Box>
		</FormTabPanel>
	);
}
