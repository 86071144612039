import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";

//Material-UI Core Components
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import { withTheme } from "@material-ui/core/styles";

//Material-UI Icons
import LockIcon from "@material-ui/icons/Lock";

//Custom Components
import withSnackbar from "Components/withSnackbar";
import { fieldFormat } from "Lib/fieldFormat";

// TODO:
// replace this control with Autocomplete from Material-UI

const labelStyles = (theme) => ({
  labelShrinkFocused: {
    backgroundColor: theme.palette.background.primary,
    color: theme.palette.secondary.main,
    padding: "0 6px"
  },
  labelShrink: {
    backgroundColor: theme.palette.background.primary,
    padding: "0 6px",
    color: theme.palette.type === "light" ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.72)"
  }
})

class PickerControl extends React.Component {

  constructor(props) {
    super(props);

    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.applyChange = this.applyChange.bind(this);
    this.calculateStyles = this.calculateStyles.bind(this);

    this.getOption = this.getOption.bind(this);
    this.getOptions = this.getOptions.bind(this);
    this.getFilteredOptions = this.getFilteredOptions.bind(this);
    this.getLabel = this.getLabel.bind(this);

    const formMode = props.formMode ? props.formMode : "form";

    this.state = {
      isFocused: false,
      inputValue: '',
      inFormContainer: formMode === "form",
      inTableContainer: formMode === "table",
      options: [],
      renderOptions: [],
    }

    this.offset = 100;
  }

  componentDidMount(){
    this.getOptions();
  }

  componentDidUpdate(prevProps){
    const { options, renderOptions, inputValue } = this.state;
    if (prevProps.model !== this.props.model || prevProps.refresh !== this.props.refresh){
      this.getOptions();
    }
    if (inputValue.length === 0 && renderOptions.length === 0 && options.length > 0) {
      this.getFilteredOptions();
    }
    return null;
  }

  calculateStyles(){
    const { theme } = this.props;
    const selectStyles =  {
      container: (base, state) => {
        const inFormContainer = state.selectProps.inContainer === "form";
        const height = 56;
        const bkgRadius = inFormContainer ? 4 : 0;
        return {
          ...base,
          minHeight: height,
          color: "rgba(0, 0, 0, 0.54)",
          borderTopLeftRadius: bkgRadius,
          borderTopRightRadius: bkgRadius
        }
      },
      control: (base, state) => {
        const isFocused = state.isFocused;
        const isSelected = state.isSelected;
        return {
          ...base,
          borderColor: isFocused || isSelected ? state.theme.palette.secondary.main : state.theme.palette.type === "light" ? "rgb(180, 180, 180)" : "rgb(133, 133, 133)",
          boxShadow: isFocused ? `0 0 0 1px ${state.theme.palette.secondary.main}` : null,
          backgroundColor: state.theme.palette.background.primary,
          minHeight: 56,
          cursor: "pointer",
          "&:hover": {
            borderColor: isFocused || isSelected ? null : state.theme.palette.text.primary
          }
        }
      },
      option: (base, state) => {
        const isSelected = state.isSelected;
        const isFocused = state.isFocused;
        return {
          ...base,
          fontSize: "13pt",
          marginTop: "3px",
          marginBottom: "5px",
          paddingLeft: "5px",
          backgroundColor: isFocused || isSelected ? theme.palette.type === "light" ? "#deebff" : "#7787a1" : "",
          color: theme.palette.type === "light" ? "hsl(0,0%,20%)": "#fff"
        }
      },
      singleValue: (base, state) => ({
        ...base,
        paddingLeft: "14px",
        color: theme.palette.type === "light" ? "hsl(0,0%,20%)": "#fff"
      }),
      menuPortal: (base) => ({
        ...base,
        zIndex: "9999"
      }),
      menuList: (base) => ({
        ...base,
        backgroundColor: theme.palette.type === "light" ? "#fff" : theme.palette.primary.main
      }),
      input: (base) => ({
        ...base,
        fontSize: "1rem",
        paddingLeft: "14px"
      }),
      multiValue: (base) => ({
        ...base,
        marginLeft: "10px"
      }),
      multiValueLabel: (base) => ({
        ...base,
        backgroundColor: theme.palette.type === "light" ? "#deebff" : "#7787a1",
        borderRadius: 4,
        borderTopLeftRadius: 2,
        borderBottomLeftRadius: 2
      })
    }
    return selectStyles;
  }

  handleFocus(evt) {
    this.setState({ isFocused: true });
  }

  handleBlur(evt) {
    this.setState({ isFocused: false });
  }

  handleChange(option, actionType) {

    switch (actionType.action) {
      case "select-option":
      case "remove-value":
        this.applyChange(option);
        break;
      case "clear":
        this.applyChange(null);
        break;
      default:
        //console.log('action', actionType);
    }
  }

  handleInputChange(value){
    const { onInputChange } = this.props;

    if (onInputChange) {
      onInputChange(value);
    }

    this.setState({ inputValue: value }, () => this.getFilteredOptions());
  }


  applyChange(option) {
    const { field, onChange } = this.props;

    if (onChange) {
      onChange(option, field.source);
    }
  }

  getOptionLabel(obj, format) {
    const { t } = this.props;
    let keys = Object.keys(obj);

    if (format) {
      let label = format;
      if (keys.length > 0) {
        keys.forEach((k) => {
          let re = new RegExp('{' + k + '}');
          label = label.replace(re, obj[k]);
        })
        return t(label);
      } else {
        return null;
      }
    } else {
      return keys.map(x => t(obj[x])).join(', ');
    }
  }

  getLabel() {
    const { field, label, t } = this.props;

    if (field) {
      return field.ttoken ? t(field.ttoken) : field.title;
    } else {
      return label;
    }
  }

  getOption(value) {
    const { field, model, t } = this.props;

    let intValue;
    if (typeof value === 'string') {
      intValue = value;
    } else if (value && value.hasOwnProperty('value') && value.hasOwnProperty('label')) {
      return value; //it's already option object
    } else if (value === null){
      return null
    } else {
      intValue = value;
    }

    if (field && field.subModel && model) {
      if (Array.isArray(value)) {
        return value.map(val => {
          if (val && val.hasOwnProperty('value') && val.hasOwnProperty('label')) {
            return val //it's already option object
          } else if(Number.isInteger(val)) {
            const record = model.getRecordObject(val);
            if (record) {
              return {
                label: field.format ? fieldFormat(record, field.subModel.format, t) : record.label,
                value: val,
              }
            } else {
              return null;
            }
          }
        })
      } else {
        const record = model.getRecordObject(intValue);
        if (record) {
          return {
            label: fieldFormat(record, field.subModel.format, t),
            value: intValue
          }
        }
      }
    } else if (field && field.items && field.items.labels) {
      const index = field.items.values.indexOf(intValue);
      if(field.items.positions){
        return {
          label: t(field.items.labels[index]),
          value: intValue,
          position: field.items.positions[index]
        }
      }else{
        return {
          label: t(field.items.labels[index]),
          value: intValue
        }
      }
    } else {
      return null;
    }
  }

  getFilteredOptions(bottom = false) {
    const { options, renderOptions, inputValue } = this.state;
    const limit = bottom ? (renderOptions.length + this.offset) : this.offset;
    const retOptions = [];

    for (let i = 0; retOptions.length < limit && i < options.length; ++i) {
      if (inputValue && options[i].label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0) {
        retOptions.push( options[i] );
      } else if (!inputValue) {
        retOptions.push( options[i] );
      }
    }

    this.setState({
      renderOptions: retOptions
    });
  }

  getOptions() {
    const { field, model } = this.props;

    if (field && field.subModel && model) {
      const idAttribute = model.fieldId.source;

      model.GetData()
        .then(resp => {
          this.setState({
            options: resp.data && resp.data.length > 0 ? resp.data.map( (r, i) => this.getOption(r[idAttribute]) ) : []
          }, () => this.getFilteredOptions())
        })
    } else if (field && field.items && field.items.values)  {
      this.setState({
        options: field.items.values.map(val => this.getOption(val))
      }, () => this.getFilteredOptions())
    } else {
      return [];
    }
  }

  getHelperText() {
    const { field, helperText, showHelper, t } = this.props;

    return field && field.tooltip ? t(field.tooltip) : (helperText ? helperText : (showHelper ? " " : null));
  }

  render() {
    const { isFocused, options, renderOptions } = this.state;
    const {
      formMode,
      controlMode,
      field,
      value,
      validation,
      model,
      allowNewRecord,
      classes,
      allowMultiSelect,
      allowClear,
      showHelper,
      autoFocus,
      t,
        theme
    } = this.props;
    const isRequired = field && field.validation && field.validation.required;

    const option =  this.getOption(value);

    // TODO:
    // translations
    const loadingText = "Podaci se učitavaju...";
    const noDataText = field && field.external ? "Počnite tipkati za pretragu" : "Nema podataka";

    const hasValue = option !== undefined && option !== null && option !== '';
    const hasError = validation && validation.valid === false;
    const doShowHelper = field && field.hasOwnProperty('showHelper') ?
      (field.showHelper ? true : false)
      : true
      ;

    const isReadOnly = controlMode === "view" || (field && field.readonly);
    const isLoading = (model || field && field.items && field.items.values || field && field.external) ? false : true;
    const hideLabel = formMode === "table";
    const label = this.getLabel();
    const helperText = this.getHelperText();
    const placeholder = field && field.placeholder && field.placeholder.length ? field.placeholder : "";

    let allowAdd = allowNewRecord;
    if (field && field.allowNewRecord !== undefined && field.allowNewRecord === false) {
      allowAdd = false;
    }
    const SelectComponent = allowAdd ? CreatableSelect : Select;

    const isMulti = field && field.multi && Array.isArray(option);
    let displayValue = "";
    if (isMulti){
      const labels = option.map(op => op ? op.label : "");
      displayValue = labels.join('; ');
    }

    return (
        isReadOnly ?
        <TextField
          margin="none"
          fullWidth
          required={isRequired}
          error={hasError}
          label={hideLabel ? null : label}
          placeholder={placeholder ? t(placeholder) : ""}
          id={field.source}
          value={ isMulti ? displayValue : option ? option.label : ''}
          InputProps={{
            readOnly: isReadOnly,
            endAdornment:
              isReadOnly ? <LockIcon style={{ fontSize: 20 }} color="disabled" /> : null
          }}
          helperText={doShowHelper ? (hasError ? validation.msg : t(helperText)) : null}
          variant="outlined"
          color="secondary"
        />
         :
      <FormControl margin="none" fullWidth error={hasError} variant="outlined" >
        {formMode === "form" ? <InputLabel shrink={hasValue || isFocused} required={isRequired} style={{backgroundColor: theme.palette.type === "light" ? "#fff" : "#424242"}} className={ isFocused ? classes.labelShrinkFocused : hasValue ? classes.labelShrink : null}>{t(label)}</InputLabel> : null }
        <SelectComponent
          value={allowMultiSelect || field && field.items && field.items.multi ? option.value : option}
          placeholder=""
          inContainer={formMode}
          autoFocus={autoFocus}

          options={renderOptions}
          noOptionsMessage={() => noDataText}

          isMulti = {allowMultiSelect || field && field.multi}
          isClearable={allowClear}
          theme={theme}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          menuPosition="fixed"
          menuPlacement="auto"
          menuPortalTarget={document.body}
          onMenuScrollToBottom={() => this.getFilteredOptions(true)}
          styles={this.calculateStyles()}
          isLoading={isLoading}
          loadingMessage={() => loadingText}
          //onCreateOption={allowAdd ? this.handleCreateOption : null}
          formatCreateLabel={allowAdd ? (inputValue) => { return 'Dodaj "' + inputValue + '"'; }: null}
          inputProps={{
            readOnly: isReadOnly,
            endAdornment: isReadOnly ? <LockIcon style={{ fontSize: 20 }} color="disabled" /> : null
          }}
        />
        {doShowHelper ?
          <FormHelperText id={field.source + "-helper"}>
            {hasError ? t(validation.msg) : (field.tooltip ? field.tooltip : " ")}
          </FormHelperText>
          : null }
      </FormControl>
    )
  }
}

PickerControl.defaultProps = {
  formMode: "form",
  controlMode: "edit",
  allowNewRecord: false,
  isLoading: true,
  allowMultiSelect: false,
  allowClear: true,
  showHelper: true,
  autoFocus: false
}

PickerControl.propTypes = {
  formMode: PropTypes.oneOf(["form", "table"]),
  controlMode: PropTypes.oneOf(["edit", "view"]),
  field: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.array]),
  validation: PropTypes.object,
  model: PropTypes.object,
  allowNewRecord: PropTypes.bool,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  allowMultiSelect: PropTypes.bool,
  allowClear: PropTypes.bool,
  showHelper: PropTypes.bool,
  autoFocus: PropTypes.bool,
}

export default withTranslation()(withStyles(labelStyles)(withSnackbar(withTheme(PickerControl))));
