import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

// Custom Components
import ModelTable from "UI/Table/ModelTable";
import TableCard from "UI/Table/TableCard";
import TableCardContent from "UI/Table/TableCardContent";
import model from "Models/vozila";
import dataController from "Lib/dataController";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import UserContext from "Components/UserContext/UserContext";
import withRedirect from "Components/withRedirect";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

function VozilaTable(props) {
  const { t } = useTranslation();

  const loaderContext = useContext(LoaderContext);
  const userContext = useContext(UserContext);
  const snackbarContext = useContext(SnackbarContext);

  const [records, setRecords] = useState([]);

  const path = useMemo(() => `prijevoznici/${userContext.subjectId ? userContext.subjectId : -1}/vozila`, [
    userContext.subjectId
  ]);
  const dc = new dataController(model, path);
  const { redirect } = props;

  useEffect(() => {
    if (userContext.subjectId) {
      refreshRecords();
    }
  }, [userContext.subjectId]);

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData(path)
      .then((resp) => {
        if (resp.success) {
          setRecords(resp.data || []);
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const handleRowAction = function(action, id) {
    if (action === "view") {
      redirect(`/p/vozila/${id}`);
    }
  }

  const allowRowAction = function(action, id) {
    return true;
  }

  return (
    <TableCard>
      <TableCardContent>
        <ModelTable
          title={t("titles.vozila")}
          records={records}
          dc={dc}
          allowExport={true}
          allowFilter={true}
          allowAdd={false}
          allowSelection="none"
          viewName="default"
          onRowAction={handleRowAction}
          allowRowAction={allowRowAction}
          onDoubleClick={(id) => handleRowAction("view", id)}
          handleRefresh={refreshRecords}
        />
      </TableCardContent>
    </TableCard>
  );
}

export default withRedirect(VozilaTable);
