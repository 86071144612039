import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

// Custom Components
import ModelTable from "UI/Table/ModelTable";
import TableCard from "UI/Table/TableCard";
import TableCardContent from "UI/Table/TableCardContent";
import UserContext from "Components/UserContext/UserContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import dataController from "Lib/dataController";
import model from "Models/poslovi";
import TableButtonView from "UI/Table/ActionButtons/TableButtonView";
import withRedirect from "Components/withRedirect";
import { posloviService } from "Services/posloviService";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

function AdminVoznjeTable(props) {
  const { t } = useTranslation();
  const { state } = useLocation();

  const userContext = useContext(UserContext);
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);
  const { redirect } = props;
  const viewName = "admin";

  const [records, setRecords] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const dc = new dataController(model, "admin/voznje");

  const handleView = (evt, sel) => {
    const selId = parseInt(sel[0], 10);
    const record = records[selId];
    const id = record.id;

    //history.push(`/p/voznje/${id}`, id);
    redirect(`/admin/voznje/${id}`);
  };

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData().then((resp) => {
      if(resp.success){
        setRecords(resp.data);
      }
    }).catch((result) => {
      snackbarContext.showNotification(result.error, "error");
    }).finally(() => {
      loaderContext.toggleLoading(false);
      setLoaded(true);
    })
  };

  useEffect(() => {
    if (state) {
      const { posao_state_id, panic, mediator_fullname } = state;
      const identifier = dc.getSource() + viewName;
      userContext.setRecentFilters(identifier, null, null, true);

      if (panic) {
        userContext.setRecentFilters(identifier, 'panic', panic);
      } else {
        userContext.setRecentFilters(identifier, 'posao_state_id', posao_state_id);
      }
      if (mediator_fullname) {
        userContext.setRecentFilters(identifier, 'mediator_fullname', mediator_fullname);
      }
      userContext.setRecent("toggle_filter", identifier, true);
      const cols = userContext.getRecent('columns', identifier);
      let wasSet = false
      if (cols && cols.length) {
        const nCols = cols.map(c => {
          if (['posao_state_id', 'panic', 'mediator_fullname'].filter(a => a in state).indexOf(c.source) !== -1) {
            wasSet = true;
            return { source: c.source, hidden: false };
          }
          return c;
        })
        if (!wasSet) {
          userContext.setRecent('columns', identifier,
            nCols
              .concat([{ source: panic ? 'panic': 'posao_state_id', hidden: false }])
              .concat(mediator_fullname ? [{source: 'mediator_fullname', hidden: false}] : [])
          );
        } else {
          userContext.setRecent('columns', identifier, nCols);
        }
      } else {
        userContext.setRecent('columns', identifier,
          [{ source: panic ? 'panic': 'posao_state_id', hidden: false }]
          .concat(mediator_fullname ? [{source: 'mediator_fullname', hidden: false}] : [])
        )
      }
    }

    refreshRecords();
  }, [])

  const handleRowAction = function(action, id) {
    if (action === "view") {
      redirect(`/admin/voznje/${id}`);
    } else if (action === "view_pp") {
      const rec = records.find(x => x.id === id);
      if (rec && rec.posebna_ponuda_id) {
        redirect(`/p/posebne-ponude/${rec.posebna_ponuda_id}`);
      }
    } else if (action === "view_ag") {
      const rec = records.find(x => x.id === id);
      if (rec && rec.agentura_interni_posao_id) {
        redirect(`/p/voznje/${rec.agentura_interni_posao_id}`);
      } else if (rec && rec.agentura_platformski_posao_id) {
        redirect(`/p/voznje/${rec.agentura_platformski_posao_id}`);
      }
    }
  }

  const allowRowAction = function(action, id) {
    if (action === "view_pp") {
      const rec = records.find(x => x.id === id);
      if (rec && rec.posebna_ponuda_id) {
        return true;
      } else {
        return false;
      }
    } else if (action == "view_ag") {
      const rec = records.find(x => x.id === id);
      if (rec && rec.agentura_interni_posao_id) {
        return true;
      } else if (rec && rec.agentura_platformski_posao_id) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  return (
      <TableCard>
        <TableCardContent>
        {
          loaded ?
          <ModelTable
            title={t("titles.admin_voznje")}
            viewName={viewName}
            dc={dc}
            records={records}
            allowSelection="none"
            allowFilter={true}
            allowAdd={false}
            allowExport={true}
            onRowAction={handleRowAction}
            allowRowAction={allowRowAction}
            onDoubleClick={(id) => handleRowAction("view", id)}
            handleRefresh={refreshRecords}
          >
            {/* <TableButtonView onClick={handleView} /> */}
          </ModelTable> : null
        }
        </TableCardContent>
      </TableCard>
  );
}

export default withRedirect(AdminVoznjeTable);
