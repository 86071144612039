import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

//Material UI
import Toolbar from "@material-ui/core/Toolbar";
import MapIcon from "@material-ui/icons/Map";
import DialogActions from "@material-ui/core/DialogActions";
import InfoIcon from "@material-ui/icons/Info";

//Custom components
import DynamicDialog from "UI/Dialog/DynamicDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogContext from "UI/DialogContext/DialogContext";
import DialogActionButtonSave from "UI/Dialog/ActionButtons/DialogActionButtonSave";
import withFormController from "Components/withFormController";
import { FormTabs, FormTab, FormTabPanel } from "UI/Form";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import UserContext from "Components/UserContext/UserContext";
import { posloviService } from "Services/posloviService";
import TabOsnovniPodaci from "./VoznjaFormTabs/TabOsnovniPodaci";
import TabPanelUpitJavno from "Views/Upiti/Tabs/TabPanelUpitJavno";
import VoznjaAvatar from "UI/Form/Avatars/VoznjaAvatar";
import DialogActionButtonSaveContinue from "UI/Dialog/ActionButtons/DialogActionButtonSaveContinue";
import withRedirect from "Components/withRedirect";


function VoznjaDialog(props) {
  const dialogContext = useContext(DialogContext);
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);
  const userContext = useContext(UserContext);

  const [currentTab, setCurrentTab] = useState(0);

  const { t } = useTranslation();

  const { dc, mode, record, validation, fields, subModels } = props; //HOC withFormController
  const { doClose, doInsert, doValidate, onClose } = props; //HOC withFormController
  const { onFieldChange } = props; //HOC withFormController
  const { redirect } = props;

  const fKooperant = "kooperant_id";
  const fVoziloId = "vozilo_id";

  const handleTabChange = (evt, value) => {
    setCurrentTab(value);
  };

  const handleInsert = (evt) => {
    if (doValidate) {
      const valid = doValidate();
      if (valid) {
        const preparedRecord = dc.prepareSendingData(record);
        posloviService.createInterniPosao(preparedRecord).then((resp) => {
          if (resp.success) {
            close({ dataChanged: true, action: "insert" });
            snackbarContext.showNotification("insert", "success");
          } else {
            snackbarContext.showNotification(resp.error.message, "error");
          }
        });
      }
    }
  };

  const handleRedirect = (evt) => {
    if (doValidate) {
      const valid = doValidate();
      if (valid) {
        const preparedRecord = dc.prepareSendingData(record);
        posloviService.createInterniPosao(preparedRecord).then((resp) => {
          if (resp.success) {
            snackbarContext.showNotification("insert", "success");
            dialogContext.hideDialog();
            const id = resp.data.id;
            redirect(`/p/voznje/${id}`);
          } else {
            snackbarContext.showNotification(resp.error.message, "error");
          }
        });
      }
    }
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const close = (result) => {
    if (result.dataChanged) {
      onClose({ dataChanged: true, action: result.action, id: result.id });
    }

    dialogContext.hideDialog();
  };

  const commonTabProps = {
    currentTab: currentTab,
    dc: dc,
    subModels: subModels,
    record: record,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields
  };

  const fieldKooperant = fields.find(x => x.source === fKooperant);
  const fieldVozilo = fields.find(x => x.source === fVoziloId);

  const kooperantChosen = record[fKooperant] && record[fKooperant] !== null;
  const voziloChosen = record[fVoziloId] && record[fVoziloId] !== null;

  if (kooperantChosen) {
    fieldVozilo.readonly = true;
  } else if (voziloChosen) {
    fieldKooperant.readonly = true;
  } else {
    fieldVozilo.readonly = false;
    fieldKooperant.readonly = false;
  }

  return (
    <DynamicDialog onClose={handleClose} fixedHeight={true}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <VoznjaAvatar />
          <DialogToolbarHeading header={t("titles.novi_interni_posao")} />
          <DialogToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <FormTabs value={currentTab} onChange={handleTabChange}>
          <FormTab
            label={t("poslovi.tabs.osnovni_podaci")}
            icon={<InfoIcon />}
            aria-label="osnovni_podaci"
            validation={validation}
            fields={["from_ts", "to_ts",
              "from_loc", "to_loc",
              "relacija_opis_skraceni", "kupac_id", "narucitelj_id", "kupac_kontakt", "narucitelj_kontakt",
              "vozilo_id", "vozac_id", "kooperant_id", "kooperant_napomena", "vozilo_broj_putnika", "vozilo_broj_vodica",
              "napomena_interna"]}
          />
          <FormTab
            label={t("poslovi.tabs.ruta_putovanja")}
            icon={<MapIcon />} aria-label="ruta_putovanja"
            validation={validation}
            fields={["from_ts", "to_ts",
              "from_loc", "stajalista", "to_loc",
              "trajanje_voznje", "neto_udaljenost_voznje", "udaljenost_povratka_vozila"]}
          />
        </FormTabs>
        <FormTabPanel value={currentTab} index={0}>
          <TabOsnovniPodaci {...commonTabProps} internal={true} />
        </FormTabPanel>
        <TabPanelUpitJavno tabIndex={1} {...commonTabProps} internal={true} />
      </DialogBody>
      <DialogActions>
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
        <DialogActionButtonSaveContinue variant="contained" onClick={handleRedirect} />
        <DialogActionButtonSave variant="contained" onClick={handleInsert} />
      </DialogActions>
    </DynamicDialog>
  );
}

export default withRedirect(withFormController(VoznjaDialog));
