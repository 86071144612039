import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Material-UI Icons
import CloseIcon from '@material-ui/icons/Close';
import FavIcon from "@material-ui/icons/Favorite";
import FavBorderIcon from "@material-ui/icons/FavoriteBorder";
 
// Material-UI Core Components
import {
  IconButton
, TextField
} from "@material-ui/core";

let debounceTimeout;
export default function KomunikatorSearch({ filterValue, setFilterValue, favOnly, setFavOnly }) {

	const { t } = useTranslation();
	const [value, setValue] = useState("");

	const onChange = (evt) => {
		setValue(evt.target.value)
	}

	useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    debounceTimeout = setTimeout(() => {
    	setFilterValue(value)
    }, 200);
	}, [value])

	return (
		<TextField 
			value={value}
			onChange={onChange}
	  	autoFocus
	  	fullWidth
	  	size="small"
	  	margin="dense"
	  	color="secondary"
	  	placeholder={t("Filter...")}
	  	InputProps={{
	  		startAdornment: (
	  			<IconButton size="small" onClick={() => setFavOnly(!favOnly)}>
	  				{
	  					favOnly ?
			  			  <FavIcon fontSize="small" color="secondary"/>
			  			:	<FavBorderIcon fontSize="small" color="secondary"/>
	  				}
	  			</IconButton>
		  	),
		  	endAdornment: (
					<IconButton size="small" onClick={() => setFilterValue("") || setValue("")}>
	  				<CloseIcon fontSize="small" color="secondary"/>
	  			</IconButton>
		  	)
	  	}}
		/>
	);
}