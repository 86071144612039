import { coreFields } from "./helpers/coreFields";

export default {
  title: "Kalendar",
  exportFileName: "Kalendar",
  apiPath: "kalendar/",
  forms: {
    default: {
      fields: [
        "day1",
        "day2",
        "opis",
        "usage_rate",
        "djelatnik_id",
        "oprema_id",
        "klijent_id",
        "ponuda_id",
        "ugovor_id",
        "usluga_id",
        "lookup_picker",
        "link_id"
      ],
      validator: "calendarValidator"
    }
  },
  listViews: {
    default: {
      fields: []
    }
  },
  fields: [
    coreFields.id,
    {
      title: "day1",
      source: "day1",
      ttoken: "calendar.day1",
      type: "date",
      validation: {
        required: true
      }
    },
    {
      title: "day2",
      source: "day2",
      ttoken: "calendar.day2",
      type: "date",
      validation: {
        required: true
      }
    },
    {
      title: "opis",
      source: "opis",
      ttoken: "calendar.opis",
      type: "text",
      validation: {
        maxLength: 99,
        required: true
      }
    },
    {
      title: "usage_rate",
      source: "usage_rate",
      ttoken: "calendar.usage_rate",
      type: "wholenum"
    },
    {
      title: "link_id",
      source: "link_id",
      ttoken: "calendar.link_id",
      type: "wholenum"
    },
    {
      title: "djelatnik_id",
      source: "djelatnik_id",
      ttoken: "calendar.djelatnik_id",
      type: "picker",
      subModel: {
        apiPath: "djelatnici/?need_for=picker",
        format: "[{tag}] {opis}"
      }
    },
    {
      title: "title",
      source: "oprema_id",
      ttoken: "calendar.oprema_id",
      type: "picker",
      subModel: {
        apiPath: "oprema/?need_for=picker",
        format: "[{tag}] {opis}"
      }
    },
    {
      title: "klijent_id",
      source: "klijent_id",
      ttoken: "calendar.klijent_id",
      type: "picker",
      subModel: {
        apiPath: "klijenti/?need_for=picker",
        format: "[{tag}] {opis}"
      }
    },
    {
      title: "ponuda_id",
      source: "ponuda_id",
      ttoken: "calendar.ponuda_id",
      type: "picker",
      subModel: {
        apiPath: "ponude/?need_for=picker",
        format: "[{tag}] {opis}"
      }
    },
    {
      title: "ugovor_id",
      source: "ugovor_id",
      ttoken: "calendar.ugovor_id",
      type: "picker",
      subModel: {
        apiPath: "ugovori/?need_for=picker",
        format: "[{tag}] {opis}"
      }
    },
    {
      title: "usluga_id",
      source: "usluga_id",
      ttoken: "calendar.usluga_id",
      type: "picker",
      subModel: {
        apiPath: "usluge/?need_for=picker",
        format: "[{tag}] {opis}"
      }
    },
    {
      title: "lookup_picker",
      source: "lookup_picker",
      ttoken: "calendar.lookup_picker",
      type: "radio",
      row: true,
      items: {
        labels: ["calendar.klijent_id", "calendar.ponuda_id", "calendar.ugovor_id", "calendar.none"],
        values: [1, 2, 4, 16],
        default: 16
      },
      translate: true
    }
  ]
};
