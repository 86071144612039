import React from 'react';

// Custom Components
import KomunikatorChatContent from "UI/Komunikator/KomunikatorChatContent";
import KomunikatorChatHeader from "UI/Komunikator/KomunikatorChatHeader";
import KomunikatorSingleContentEmpty from "UI/Komunikator/KomunikatorSingleContentEmpty";


function KomunikatorSingleContent(props) {
	const { channel, voznjaId, bsId, onClose } = props;

	return (
		channel.id === 0 ?
		<KomunikatorSingleContentEmpty />
		:
		<div style={{height: "100%", width: "100%", position: "relative"}}>
			<KomunikatorChatHeader bsId={bsId} voznjaId={voznjaId} channel={channel} onClose={onClose}/>
			<KomunikatorChatContent channel={channel} />
		</div>
	);
}

export default KomunikatorSingleContent;