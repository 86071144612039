import React from "react";
import { useTranslation } from "react-i18next";

//Icons
import AgenturaIcon from "Icons/AgenturaIcon";

//Custom components
import CardToolbarMenuItem from "UI/Card/CardToolbarMenuItem";

const CardToolbarMenuItemAgentura = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return (
    <CardToolbarMenuItem
      label={t("buttons.agentura")}
      icon={<AgenturaIcon />}
      {...props}
      ref={ref}
    />
  );
});

export default CardToolbarMenuItemAgentura;
