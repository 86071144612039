import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Material-UI Icons
import ErrorIcon from "@material-ui/icons/Error";

//Custom components
import { GridContainer, GridItem } from "UI/Grid";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import { statisticsService } from "Services/statisticsService";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

import UserContext from "Components/UserContext/UserContext";
import BarChart from "UI/Charts/BarChart";
import DonutChart from "UI/Charts/DonutChart";
import InfoChart from "UI/Charts/InfoChart";
import GridItemStatisticsWrapper from "Views/HomePage/GridItemStatisticsWrapper";

import withRedirect from "Components/withRedirect";

const items = {
  labels: [
    "ponuda.statuses.11",
    "trjob.status.priprema_upita",
    "trjob.status.objavljen_upit",
    "trjob.status.prihvacena_ponuda",
    "trjob.status.ugovoreno",
    "trjob.status.potvrdjeno",
    "trjob.status.usluga_u_tijeku",
    "trjob.status.usluga_zavrsena",
    "trjob.status.zatvoreno"
  ],
  values: [11, 20, 21, 22, 23, 24, 25, 26, 29],
}

function AdminStatisticsView(props) {
  const { t } = useTranslation();
  const { redirect } = props;

  const UC = useContext(UserContext);
  const LC = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);

  const [barData, setBarData] = useState({ 
    series: [
      {name: "series-1",data: (new Array(30).fill(0)).map((_, i) => Math.floor(Math.random() * 100))},
      {name: "series-3",data: (new Array(30).fill(0)).map((_, i) => Math.floor(Math.random() * 100))}
    ],
    categories: (new Array(30).fill(0)).map((_, i) => 1990 + i)
  });

  const [users, setUsers] = useState({});
  const [bs, setBs] = useState({});
  const [jobs, setJobs] = useState({});
  const [panic, setPanic] = useState(null);
  const [stEnd, setStEnd] = useState(null);

  const [bsGroup, setBsGroup] = useState('active');
  const [userGroup, setUserGroup] = useState('active');
  const [jobGroup, setJobGroup] = useState('posao_state_id');

  useEffect(() => {
    refreshJobs()
  }, [jobGroup]);

  useEffect(() => {
    refreshUsers();
  }, [userGroup]);

  useEffect(() => {
    refreshBs();
  }, [bsGroup]);

  useEffect(() => {

  }, [])

  const refreshJobs = () => {
    LC.toggleLoading(true);
    statisticsService.getJobs().then(resp => {
      if (resp.success && resp.data && Array.isArray(resp.data)) {
        const res = {}, myRes = {};
        let panic = 0, myPanic = 0;
        let end = 0, myEnd = 0;
        resp.data.forEach(r => {
          const isMe = r.mediator_fullname === `${UC.firstName} ${UC.lastName}`;
          if (res[ r[jobGroup] ]) {
            ++res[ r[jobGroup] ];
          } else {
            res[ r[jobGroup] ] = 1;
          }
          if (r.panic) {
            ++panic;
          }
          if (r[jobGroup] === 29) {
            ++end;
          }
          if (isMe) {
            if (myRes[ r[jobGroup] ]) {
              ++myRes[ r[jobGroup] ];
            } else {
              myRes[ r[jobGroup] ] = 1;
            }
            if (r.panic) {
              ++myPanic;
            }
            if (r[jobGroup] === 29) {
              ++myEnd;
            }
          } else {
            if (!(r[jobGroup] in myRes)) {
              myRes[jobGroup] = 0;
            }
          }
        });
        const data = [], categories = [], myData = [], keys = [];
        Object.keys(res).sort().forEach(k => {
          data.push(res[k]);
          categories.push(k);
          if (myRes[k]) {
            myData.push(myRes[k]);
          } else {
            myData.push(0);
          }
          keys.push(k);
        });
        setJobs({
          series: [{ name: 'statistics.all', data: data }, { name: "statistics.my", data: myData }],
          categories: categories.map(c => { const i = items.values.indexOf(Number(c));  return items.labels[i] }),
          keys
        })
        setPanic({ all: panic, my: myPanic });
        setStEnd({ all: end, my: myEnd });
      }
    }).finally(() => LC.toggleLoading(false));
  }

  const refreshUsers = () => {
    LC.toggleLoading(true);
    statisticsService.getUsers().then(resp => {
      if (resp.success && resp.data && Array.isArray(resp.data)) {
        const res = {};
        resp.data.forEach(r => {
          if (res[ r[userGroup] ]) {
            ++res[ r[userGroup] ];
          } else {
            res[ r[userGroup] ] = 1;
          }
        });
        const series = [], labels = [], keys = [];
        Object.keys(res).sort().forEach(k => {
          series.push(res[k]);
          labels.push(`statistics.${k}`);
          keys.push(k);
        })
        setUsers({ series, labels, keys });
      }
    }).finally(() => LC.toggleLoading(false));
  }

  const refreshBs = () => {
    LC.toggleLoading(true);
    statisticsService.getBS().then(resp => {
      if (resp.success && resp.data && Array.isArray(resp.data)) {
        const res = {};
        resp.data.forEach(r => {
          if (res[ r[bsGroup] ]) {
            ++res[ r[bsGroup] ];
          } else {
            res[ r[bsGroup] ] = 1;
          }
        });
        const series = [], labels = [], keys = [];
        Object.keys(res).sort().forEach(k => {
          series.push(res[k]);
          labels.push(`statistics.${k}`);
          keys.push(k);
        })
        setBs({ series, labels, keys });
      }
    }).finally(() => LC.toggleLoading(false));
  }

  const onUserDonutClick = (evt, ctx, opts) => {
    const { dataPointIndex } = opts;
    redirect('/admin/users', { active: users.keys[dataPointIndex] === 'true' ? t('common.da') : t('common.ne') });
  }

  const onBsDonutClick = (evt, ctx, opts) => {
    const { dataPointIndex } = opts;
    redirect('/admin/poslovni-subjekti', { active: bs.keys[dataPointIndex] === 'true' ? t('common.da') : t('common.ne') });
  }

  const onJobBarClick = (evt, ctx, opts) => {
    const { dataPointIndex, seriesIndex } = opts;
    const isMed = seriesIndex ? { mediator_fullname: `${UC.firstName} ${UC.lastName}` } : {};
    console.log(isMed)
    redirect('/admin/voznje', { posao_state_id: Number(jobs.keys[dataPointIndex]), ...isMed });
  }

  const onPanicAllClick = (evt, ctx, opts) => {
    redirect('/admin/voznje', { panic: t('common.da') });
  }

  const onPanicMyClick = (evt, ctx, opts) => {
    redirect('/admin/voznje', { panic: t('common.da'), mediator_fullname: `${UC.firstName} ${UC.lastName}` });
  }

  return (
    <GridContainer>
      <GridItem xs={12} lg={12} xl={12}>
        <GridContainer>
          <GridItemStatisticsWrapper sizes={{xs: 12, sm: 12, md: 6, lg: 6}}>
            <DonutChart 
              title="statistics.bs"
              handleClick={onBsDonutClick} 
              series={bs.series} 
              labels={bs.labels}
              options={{
                dataLabels: {
                  formatter: (_, { seriesIndex, w }) => {
                    return w.config.series[seriesIndex];
                  }
                }
              }}
            />
          </GridItemStatisticsWrapper>
          <GridItemStatisticsWrapper sizes={{xs: 12, sm: 12, md: 6, lg: 6}}>
            <DonutChart 
              title="statistics.users"
              handleClick={onUserDonutClick} 
              series={users.series} 
              labels={users.labels}
              options={{
                dataLabels: {
                  formatter: (_, { seriesIndex, w }) => {
                    return w.config.series[seriesIndex];
                  }
                }
              }}
            />
          </GridItemStatisticsWrapper>
          <GridItemStatisticsWrapper sizes={{xs: 12, sm: 12, md: 6, lg: 6}}>
            <InfoChart handleClick={onPanicAllClick} title={"statistics.panic"} value={panic ? panic.all : 0} icon={<ErrorIcon color="error"/>}/>
          </GridItemStatisticsWrapper>
          <GridItemStatisticsWrapper sizes={{xs: 12, sm: 12, md: 6, lg: 6}}>
            <InfoChart handleClick={onPanicMyClick} title={"statistics.panic_my"} value={panic ? panic.my : 0} icon={<ErrorIcon color="error"/>}/>
          </GridItemStatisticsWrapper>
        </GridContainer>
      </GridItem>
      <GridItemStatisticsWrapper sizes={{xs: 12, lg: 12, xl: 12}}>
        <BarChart 
          title="statistics.jobs"
          series={jobs.series} 
          categories={jobs.categories} 
          handleClick={onJobBarClick}
          options={{
            grid: {
              xaxis: { lines: {
                show: true
              }},
              yaxis: { lines: {
                show: false
              }}
            },
            legend: { onItemClick: {
                  toggleDataSeries: false
            }},
            plotOptions: { bar: {
              barHeight: "40%"
            }}
          }}
        />
      </GridItemStatisticsWrapper>
      {/*<GridItemStatisticsWrapper sizes={{xs: 12, lg: 12, xl: 12}}>
        <LineChart 
          series={barData.series.map((_, i) => i ? { name: _.name, data: _.data.map(a => a / 100) } : _)} 
          categories={barData.categories} 
          options={{ stroke: { show: true }, yaxis: [{}, {opposite: true}] }}
        />
      </GridItemStatisticsWrapper>*/}
    </GridContainer>
  );
}

export default withRedirect(AdminStatisticsView);
