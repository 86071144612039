import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";

//Custom Components
import withFormController from "Components/withFormController";
import withRedirect from "Components/withRedirect";
import { GridContainer } from "UI/Grid";
import FormContent from "Components/FormContent";
import ModelTable from "UI/Table/ModelTable";

function FinancijskeObvezeForm(props) {
  const { records, mode, dc, fields } = props;
  const { t } = useTranslation();

  const [formRecord, setFormRecord] = useState({});
  const [secondTableRecord, setSecondTableRecord] = useState([]);

  const { redirect } = props; //HOC withRedirect

  useEffect(() => {
    sumTheSaldo();
  }, [records]);

  const afterRowsChange = (rows) => {
    let temp_saldo = 0;
    let sum_duguje = 0;
    let sum_potrazuje = 0;

    // looks even more terrible, but it actually works
    for (let x = 0; x < rows.length; x++) {
      sum_duguje += rows[x].values.owner_duguje_hrk?.props?.unformatedvalue || 0;
      sum_potrazuje += rows[x].values.owner_potrazuje_hrk?.props?.unformatedvalue || 0;
      temp_saldo =
        (rows[x].values.owner_potrazuje_hrk?.props?.unformatedvalue || 0) -
        (rows[x].values.owner_duguje_hrk?.props?.unformatedvalue || 0);
      records[x].vl_saldo_hrk = temp_saldo;
    }
    const saldo = sum_potrazuje - sum_duguje;

    const formRecord = {
      vl_duguje_hrk: sum_duguje,
      vl_potrazuje_hrk: sum_potrazuje,
      vl_saldo_hrk: saldo
    };
    setFormRecord(formRecord);
    setSecondTableRecord(rows);
  };

  const sumTheSaldo = () => {
    let temp_saldo = 0;
    let sum_duguje = 0;
    let sum_potrazuje = 0;

    for (let x = 0; x < records.length; x++) {
      sum_duguje += records[x].owner_duguje_hrk;
      sum_potrazuje += records[x].owner_potrazuje_hrk;
      temp_saldo = records[x].owner_potrazuje_hrk - records[x].owner_duguje_hrk;
      records[x].vl_saldo_hrk = temp_saldo;
    }
    const saldo = sum_potrazuje - sum_duguje;

    const formRecord = {
      vl_duguje_hrk: sum_duguje,
      vl_potrazuje_hrk: sum_potrazuje,
      vl_saldo_hrk: saldo
    };
    setFormRecord(formRecord);
    setSecondTableRecord(records);
  };

  const handleRowAction = (action, finObvezaId) => {
    switch (action) {
      case "view":
        const rec = records.find((x) => x.id === finObvezaId);
        if (rec && rec.posao_id) {
          redirect("/p/voznje/" + rec.posao_id + "#financijske-obveze");
        }
        break;
    }
  };

  return (
    <Fragment>
      <ModelTable
        title={t("titles.financijskeObveze")}
        records={records}
        dc={dc}
        viewName={"obveze_komitent"}
        allowExport={true}
        allowFilter={true}
        allowAdd={false}
        addLabel={t("buttons.new_m")}
        allowSelection={"none"}
        onRowAction={handleRowAction}
        afterRowsChange={afterRowsChange}
      ></ModelTable>
      <GridContainer>
        <FormContent
          record={formRecord}
          fields={fields}
          mode={mode}
          columns={3}
          fieldNames={["vl_duguje_hrk", "vl_potrazuje_hrk", "vl_saldo_hrk"]}
        />
      </GridContainer>
      {/* <GridContainer>
        <ModelTable
          records={secondTableRecord}
          dc={dc}
          viewName={"ukupno_komitent"}
          allowExport={false}
          allowFilter={false}
          allowAdd={false}
          addLabel={t("buttons.new_m")}
          allowSelection={"none"}
        />
      </GridContainer> */}
    </Fragment>
  );
}

export default withFormController(withRedirect(FinancijskeObvezeForm));
