import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

//Material UI
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

//Custom Components
import { GridContainer, GridItem } from "UI/Grid";
import withFormController from "Components/withFormController";
import FormContent from "Components/FormContent";
import BigSearchButton from "UI/Buttons/BigSearchButton";
import { useHistory } from "react-router";
import { googleService } from "Services/googleService";
import { Typography } from "@material-ui/core";

const customStyles = makeStyles((theme) => ({
  searchCard: {
    WebkitBoxShadow: "0px 0px 13px 0px rgba(0,0,0,0.65)",
    boxShadow: "0px 0px 13px 0px rgba(0,0,0,0.65)",
    [theme.breakpoints.up("md")]: {
      width: "1450px"
    }
  }
}));


function BrzaTrazilica(props) {
  const { t } = useTranslation();

  const styles = customStyles();
  const { record, validation, fields, subModels, onFieldChange } = props;//withFormController
  const { doValidate } = props;
  const history = useHistory();

  const fFromTs = "from_ts";
  const fToTs = "to_ts";
  const fFromLoc = "from_loc";
  const fToLoc = "to_loc";
  // const fVia = "stajalista";
  // const fAvoidTolls = "izbegni_cestarine";
  // const fReturnTrip = "povratna_ruta";
  const fRoute = "ruta";
  const fTrajanjeVoznje = "trajanje_voznje";
  const fNetoUdaljenostVoznje = "neto_udaljenost_voznje";
  const fUdaljenostPovratka = "udaljenost_povratka_vozila";

  const continueSearch = (evt) => {
    if (doValidate) {
      const isValid = doValidate();
      if (isValid) {
        let data = {
          from_loc: record[fFromLoc],
          to_loc: record[fToLoc],
          from_ts: record[fFromTs],
          to_ts: record[fToTs]
        };
        googleService.route(data[fFromLoc], data[fToLoc], []).then((resp) => {
          if (resp.success) {
            googleService.route(data[fToLoc], data[fFromLoc], []).then((respReturn) => {
              if (respReturn.success) {
                data[fRoute] = resp.data.routes;
                data[fTrajanjeVoznje] = resp.data.duration;
                data[fNetoUdaljenostVoznje] = resp.data.length;
                data[fUdaljenostPovratka] = respReturn.data.length;

                history.push({
                  pathname: "/a/trazilica",
                  state: { data }
                });
              }
            });
          }
        });
      }
    }
  };

  const commonProps = {
    record: record,
    validation: validation,
    fields: fields,
    onFieldChange: onFieldChange,
    mode: "insert",
    subModels: subModels
  };

  return (
    <Card className={styles.searchCard}>
      <CardContent style={{ padding: "8px" }}>
        <GridContainer direction="row" justify="center" alignItems="center">
          <GridContainer item md={5} sm={12} direction="row" justify="flex-start" style={{paddingTop: "28px"}}>
              <FormContent
                {...commonProps}
                fieldNames={["from_ts", "to_ts"]}
                columns={2}
              />
          </GridContainer>
          <GridContainer item md={5} sm={12} direction="row" justify="flex-start" style={{paddingTop: "28px"}}>
              <FormContent
                {...commonProps}
                fieldNames={["from_loc", "to_loc"]}
                columns={2}
              />
          </GridContainer>
          <GridItem md={2} xs={12}>
            <BigSearchButton
              onClick={(evt) => continueSearch(evt)}
            />
          </GridItem>
        </GridContainer>
      </CardContent>
    </Card>
  );
}

export default withFormController(BrzaTrazilica);
