import React, { Fragment } from 'react';
import { Backdrop, CircularProgress } from "@material-ui/core";
import LoaderConsumer from "Components/LoaderContext/LoaderConsumer";

function LoaderContainer(props) {
  return (
      <Fragment>
        <LoaderConsumer>
          {(loaderContext) => (
              <Backdrop
                  open={Boolean(loaderContext.loading)}
                  style={{
                    zIndex: 99999,
                    color: "#fff"
                  }}
              >
                <CircularProgress disableShrink/>
              </Backdrop>
          )}
        </LoaderConsumer>
      </Fragment>
  );
}

export default LoaderContainer;
