import React from "react";
import Chart from "react-apexcharts";

import withChart from "./withChart";

function DonutChart(props) {
  const { options, series } = props;
  return (
    <Chart
      type="donut"
      options={options}
      series={series}
      height="400px"
    />
  );
}


export default withChart(DonutChart);