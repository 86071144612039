import React from 'react';
import { useTranslation } from "react-i18next";

// Material-UI Icons
import AddAlertIcon from '@material-ui/icons/AddAlert';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import ImageIcon from '@material-ui/icons/Image';
import MicIcon from '@material-ui/icons/Mic';

// Material-UI Core Components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

// Custom Components
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";

const buttonStyle = makeStyles(theme => ({
	iconButton: {
		width: "100%",
		border: "1px solid",
		borderColor: theme.palette.primary.main,
		borderRadius: "5px"
	}
}));

function ChatActionMenu(props) {
	const {
		fileElClick
	, fileDisabled
	, imgElClick
	, imgDisabled
	, addConfirmMsg
	, addVoiceMsg
  , isReadOnly
	} = props;

  const { t } = useTranslation();

	const classes = buttonStyle();

	return (
		<div>
			<GridContainer>
				<GridItem xs={4}>
					<Tooltip title={t("buttons.add_confirm_message")}>
						<IconButton size="small" color="secondary" onClick={addConfirmMsg} disabled={isReadOnly} className={classes.iconButton}>
							<AddAlertIcon fontSize="small"/>
						</IconButton>
					</Tooltip>
				</GridItem>
				<GridItem xs={4}>
					<Tooltip title={t("buttons.add_file")}>
						<IconButton size="small" color="secondary" onClick={fileElClick} disabled={isReadOnly} className={classes.iconButton}>
							<AttachFileIcon fontSize="small"/>
						</IconButton>
					</Tooltip>
				</GridItem>
				<GridItem xs={4}>
					<Tooltip title={t("buttons.add_image")}>
						<IconButton size="small" color="secondary" onClick={imgElClick} disabled={isReadOnly} className={classes.iconButton}>
							<ImageIcon fontSize="small"/>
						</IconButton>
					</Tooltip>
				</GridItem>
{/*				<GridItem xs={3}>
					<Tooltip title={"buttons.add_voice"}>
						<IconButton size="small" onClick={addVoiceMsg} className={classes.iconButton}>
							<MicIcon fontSize="small"/>
						</IconButton>
					</Tooltip>
				</GridItem>		*/}
			</GridContainer>
		</div>
	)
}

export default ChatActionMenu;