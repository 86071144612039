import React, { useState, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import { hashHistory, useLocation } from 'react-router-dom'

//Material-UI Core Components
import withRedirect from "Components/withRedirect";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tabs from "@material-ui/core/Tabs";
import Box from "@material-ui/core/Box";

//Custom Components
import UserContext from "Components/UserContext/UserContext";

// usePrevious is a function to store the previous props or state
function usePrevious(value) {
  const ref = useRef()

  useEffect(() => {
    ref.current = value;
  }, [value])

  return ref.current;
}

const customTabsStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "0px",
    position: "sticky",
    top: "0",
    zIndex: "9",
    borderTop: "1px solid #CCC",
    borderBottom: `1px solid ${theme.palette.primary.main}`
  }
}));

function FormTabs(props) {
  const location = useLocation();
  const { history } = props;
  // added staticContext because Tabs doesn't recieve such prop and it is packed in ...others
  const { children, onChange, identifier, value, redirect, staticContext, ...other } = props;
  const [tabHashes, setTabHashes] = useState({});
  const userContext = useContext(UserContext);
  const classes = customTabsStyles();
  const prevChildren = usePrevious(children);

  const keepTrackOfRecentTab = identifier === undefined ? false : true;

  const handleChangeTab = (evt, value) => {
    onChange(evt, value);
    if(!_.isEmpty(tabHashes)) {
      const hash_id = getTabHash(value);
      if (keepTrackOfRecentTab) {
        userContext.setRecentTab(identifier, hash_id);
      }
      history.replace(location.pathname+hash_id);
    }
  };

  const getTabHash = (value) => {
    return tabHashes[value]
  }

  // Used to compare prev children with current strictly to see if a tab has been toggled
  const compareChildren = (prev, current) => {
    for(const index in current) {
      const compare = (prev[index] === null) === (current[index] === null);
      if(!compare) {
        return compare;
      }
    }
    return true;
  }

  // denote_index: Used for the case of hidden tabs, which do not show up as children of this component
  // Done so to keep tab indexs in sequential order
  const genTabHash = (hash = "default") => {
    const hash_id_object = {};
    let recent_index = 0;
    let denote_index = 0;
    children.forEach((child, i) => {
      if(child) {
        if(child.props.id !== undefined) {
          hash_id_object[i - denote_index] = "#"+child.props.id
          if(hash !== "default" && hash_id_object[i - denote_index] === hash) {
            recent_index = i - denote_index
          }
        }
      } else {
        denote_index++
      }
    })
    if(!_.isEmpty(hash_id_object)) {
      setTabHashes(hash_id_object);
      history.replace(location.pathname+hash_id_object[hash === "default" ? value :recent_index]);
    }
    return hash === "default" ? value : _.isEmpty(hash_id_object) ? value : recent_index;
  }

  useEffect(() => {
    if(prevChildren && !_.isEmpty(tabHashes)) {
      if(!compareChildren(prevChildren, children)) {
        const recent_index = genTabHash(location.hash)
        onChange(null, recent_index)
      }
    }
  }, [children])


  useEffect(() => {
    if(location.hash) {
      const recent_index = genTabHash(location.hash);
      if (keepTrackOfRecentTab) {
        userContext.setRecentTab(identifier, location.hash);
      }
      onChange(null, recent_index);
    } else if (keepTrackOfRecentTab) {
      const recent_tab = userContext.getRecentTab(identifier, 0);
      const recent_index = genTabHash(recent_tab);
      onChange(null, recent_index);
    } else {
      genTabHash();
    }
  }, []);



  return (
    <Box borderColor="grey.200" border={1} borderLeft={0} borderRight={0} borderTop={0}>
      <Tabs
        indicatorColor="secondary"
        textColor="secondary"
        variant="fullWidth"
        classes={classes}
        onChange={(evt, value) => handleChangeTab(evt, value)}
        value={value}
        {...other}
      >
        {children}
      </Tabs>
    </Box>
  );
}

FormTabs.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  identifier: PropTypes.string
}

export default withRedirect(FormTabs);
