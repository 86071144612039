import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

//Material UI
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";

//Custom components
import DialogContext from "UI/DialogContext/DialogContext";
import KomunikatorContext from "Components/KomunikatorContext/KomunikatorContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import UserContext from "Components/UserContext/UserContext";
import modelKomunikator from "Models/komunikator";
import dataController from "Lib/dataController";
import { helpers as H } from "Lib/helpers";
import { sifrarnici } from "Lib/sifrarnici";

// Komunikator
import { komunikatorService as KS } from "Services/komunikatorService";
import KomunikatorChannelDisplay from "UI/Komunikator/KomunikatorChannelDisplay";
import KomunikatorSidebarHeader from "UI/Komunikator/KomunikatorSidebarHeader";
import KomunikatorSingleContent from "UI/Komunikator/KomunikatorSingleContent";
import KomunikatorSingleSidebar from "UI/Komunikator/KomunikatorSingleSidebar";

import KomunikatorDialog from "Views/Komunikator/KomunikatorDialog";


function KomunikatorSinglePage(props) {
  const { t } = useTranslation();
  const { id } = useParams(); // if id == 0 we are in internal communication
  const { recordId, isOnUnseen = false } = props;

  const dialogContext = useContext(DialogContext);
  const komunikatorContext = useContext(KomunikatorContext);
  const loaderContext = useContext(LoaderContext);
  const userContext = useContext(UserContext);
  const snackbarContext = useContext(SnackbarContext);

  const [channels, setChannels] = useState([]);
  const [channelSelected, setChannelSelected] = useState({ id: 0 });
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [voznja, setVoznja] = useState({});
  const [unseen, setUnseen] = useState(new Set());

  const dcKomunikator = new dataController(modelKomunikator);

  const jobID = recordId ? recordId : parseInt(id, 10);

  const refreshChannels = () => {
    if (jobID !== null && jobID !== undefined && !isNaN(jobID) || isOnUnseen) {
      loaderContext.toggleLoading(true);
      const promiseFn = () => ( isOnUnseen ? KS.getAllUnread() : KS.getChannels(jobID) );
      promiseFn()
        .then(resp => {
          if (resp.success && resp.data) {
            const newUnseen = new Set();
            const currChannels = resp.data.map((channel, i) => {
              const { message } = channel;

              // skip first since it will get selected on render and thus seen
              if (isOnUnseen || i
                  && message.message_delivery_type_id < sifrarnici.msg_delivery_types.seen
                  && message.created_by !== userContext.userId) {
                newUnseen.add(channel.id);
              }
              return Object.assign({ color: H.getRandomColor() }, channel);
            })
            setChannels(currChannels);
            setUnseen(newUnseen);
            if (currChannels && currChannels.length) {
              setChannelSelected(currChannels[0]);
              //komunikatorContext.addFocused(currChannels[0].id);
              komunikatorContext.setMainWindowFocused(currChannels[0].id, null);
            }
          }
        })
        .catch((result) => {
          snackbarContext.showNotification(result.error, "error");
        })
        .finally(() => {
          loaderContext.toggleLoading(false);
        });
    }
  }

  const addChannel = () => {
    dialogContext.showDialog(KomunikatorDialog, {
      mode: "insert",
      dc: dcKomunikator,
      id: jobID,
      title: voznja.opis,
      form: parseInt(jobID === 0, 10) ? "channel_insert_internal" : "channel_insert",
      onClose: handleCloseDialog,
      defaultValues: {
        channel_type_id: parseInt(jobID, 10) === 0 ? // if jobId is 0 this is internal communication whose channel type is always standalone
            sifrarnici.channel_types.standalone
          : sifrarnici.channel_types.other,
        posao_id: parseInt(jobID, 10) === 0 ? null : parseInt(jobID, 10)
      },
      subModelDefaultValues: {
        bsId: userContext.subjectId,
        jobId: parseInt(jobID, 10)
      }
    });
  }

  const handleCloseDialog = (data) => {
    const { refreshForm } = props;
    if (data.dataChanged) {
      refreshChannels();
      komunikatorContext.checkConnection();
      if (refreshForm && typeof refreshForm === 'function') {
        refreshForm();
      }
    }
  }

  const markAllAsRead = () => {
    const unseenMsgs = channels
      .map(c => unseen.has(c.id) ? c.message.id : null)
      .filter(mId => mId !== null);
    KS.markAsRead(unseenMsgs)
      .then(() => {
        setUnseen(new Set());
      });
  }

  useEffect(() => {
    refreshChannels();
  }, [jobID]);

  useEffect(() => {
    if (unseen.delete(channelSelected.id)) {
      setUnseen(unseen);
    }
    // return () => komunikatorContext.removeFocused(channelSelected.id);
    return () => komunikatorContext.setMainWindowFocused(null, channelSelected.id);
  }, [channelSelected])

  const inTab = recordId ? true : false;

  return (
    <Box m={0} style={{ height: inTab ? "55vh" : "100%", display: "flex", position: "relative" }}>
      <KomunikatorSingleSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} tab={inTab}>
        <KomunikatorSidebarHeader
          allowAddChannel={!isOnUnseen}
          addChannel={addChannel}
          allowMarkAllAsRead={isOnUnseen && unseen.size > 0}
          markAllAsRead={markAllAsRead}
          setSidebarOpen={setSidebarOpen}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          filterValue={filterValue}
          setFilterValue={setFilterValue}
          title={voznja.opis}
        />
        {
          channels
            .filter(c => c.title.toLowerCase().trim().indexOf(filterValue.toLowerCase().trim()) >= 0)
            .map((c, i) => (
              <KomunikatorChannelDisplay
                key={i}
                dc={dcKomunikator}
                channel={c}
                hasUnseen={unseen.has(c.id)}
                channelSelected={channelSelected.id === c.id || channelSelected.id === 0 && i === 0}
                onChannelClick={() => {
                  //komunikatorContext.removeFocused(channelSelected.id);
                  //komunikatorContext.setMainWindowFocused(null);
                  setChannelSelected(c);
                  setSidebarOpen(false);
                  //komunikatorContext.addFocused(c.id);
                  komunikatorContext.setMainWindowFocused(c.id, null);
                }}
              />
            ))
        }
      </KomunikatorSingleSidebar>
      <Hidden smDown>
        <div style={{minWidth: "400px", maxWidth: "400px"}} ></div>
      </Hidden>
      <Hidden mdUp>
        <div style={{minWidth: "25px", maxWidth: "25px"}} ></div>
      </Hidden>
      <KomunikatorSingleContent
        bsId={userContext.subjectId}
        voznjaId={jobID}
        channel={channelSelected}
        onClose={handleCloseDialog}
      />
    </Box>
  );
}

export default KomunikatorSinglePage;
