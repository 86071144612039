import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI
import CreateIcon from '@material-ui/icons/Create';

//Custom Components
import DialogActionButton from "UI/Dialog/DialogActionButton";

function DialogActionButtonSaveContinue(props) {
  const { t } = useTranslation();

  return (
      <DialogActionButton startIcon={<CreateIcon />} {...props} aria-label="Save" aria-controls="Save">
        {t("buttons.save_continue")}
      </DialogActionButton>
  );
}

export default DialogActionButtonSaveContinue;
