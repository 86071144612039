import React from 'react';
import { useTranslation } from "react-i18next";

// Material-UI Icons
import CloseIcon from '@material-ui/icons/Close';
 
// Material-UI Core Components
import {
  IconButton
, TextField
} from "@material-ui/core";

function KomunikatorSidebarFilter(props) {
	const { t } = useTranslation();
	const { filterValue, setFilterValue, setShowFilter } = props;

	const onChange = (evt, ...a) => {
		setFilterValue(evt.target.value)
	}

	return (
		<TextField 
			value={filterValue}
			onChange={onChange}
	  	autoFocus
	  	fullWidth
	  	size="small"
	  	margin="dense"
	  	color="secondary"
	  	placeholder={t("Filter by name")}
	  	InputProps={{
	  		endAdornment: (
	  			<IconButton 
	  				size="small" 
	  				onClick={() => {setFilterValue(""); setShowFilter(false)}}
	  			>
	  				<CloseIcon fontSize="small" color="secondary" />
	  			</IconButton>
	  		)
	  	}}

		/>
	);
}

export default KomunikatorSidebarFilter;