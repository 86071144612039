import React from "react"

import { IconButton } from "@material-ui/core";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export const renderArrowPrev = (onClickHandler, hasPrev, label) =>
hasPrev && (
  <IconButton size="small" onClick={onClickHandler} className="carousel-arrow-prev"
    style={{
      position: "absolute",
      top: "50%",
      left: 5,
      zIndex: 1,
      backgroundColor: "#494949",
      color: "white",
      border: "1px solid white"
    }}
  >
    <ArrowForwardIosIcon style={{transform: "rotate(180deg) scale(0.7) translate(2px)"}}/>
  </IconButton>
);

export const renderArrowNext = (onClickHandler, hasNext, label) =>
hasNext && (
  <IconButton size="small" onClick={onClickHandler} className="carousel-arrow-next"
  style={{
    position: "absolute",
    top: "50%",
    right: 5,
    zIndex: 1,
    backgroundColor: "#494949",
    color: "white",
    border: "1px solid white"
  }}
  >
    <ArrowForwardIosIcon  style={{transform: " scale(0.7) translate(2px)"}}/>
  </IconButton>
);

