export const schedulerHelpers = {
	getDateFormat,
  getDateName,
  schedulerDays
}

function getDateFormat(resolutionHours) {
  switch (resolutionHours) {
    case 1:
    case 2:
    case 3:
    case 4:  return "DD.MM.YYYY";
    case 6:  return "DD.MM.YY";
    case 8:  return "DD.MM";
    case 12:
    case 24: return "DD";
    default: return "DD.MM";
  }
}

// TODO:
// addTranslations
function getDateName(date, resolutionHours) {
  let names;
  if (resolutionHours == 12 || resolutionHours == 24) {
    names = ["N", "P", "U", "S", "Č", "P", "S"];
  } else if (resolutionHours == 6 || resolutionHours == 8) {
    names = ["NED", "PON", "UTO", "SRI", "ČET", "PET", "SUB"];
  } else {
    names = ["nedjelja", "ponedjeljak", "utorak", "srijeda", "četvrtak", "petak", "subota"];
  }

  return names[date.day()];
}

function schedulerDays(gridWidth, cellWidth, resolutionHours, startDate) {
  return Math.floor(gridWidth / (cellWidth * Math.floor(24.0 / resolutionHours)));
}