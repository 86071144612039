import React from 'react'
import UserContext from 'Components/UserContext/UserContext';
import { Role } from 'Lib/role';
import { helpers } from "Lib/helpers";

import { userService } from "Services/userService";

class UserProvider extends React.Component {
  constructor(props) {
    super(props);

    this.setUserData = this.setUserData.bind(this);
    this.setUserSetting = this.setUserSetting.bind(this);
    this.hasAnyPermission = this.hasAnyPermission.bind(this);
    // this.hasAnyRole = this.hasAnyRole.bind(this);

    this.isFavoritePrijevoznik = this.isFavoritePrijevoznik.bind(this);
    this.isFavoriteAgencija = this.isFavoriteAgencija.bind(this);
    this.isFavoriteChannel = this.isFavoriteChannel.bind(this);

    this.toggleFavoritePrijevoznik = this.toggleFavoritePrijevoznik.bind(this);
    this.toggleFavoriteAgencija = this.toggleFavoriteAgencija.bind(this);
    this.toggleFavoriteChannel = this.toggleFavoriteChannel.bind(this);

    this.setRecentTab = this.setRecentTab.bind(this);
    this.getRecentTab = this.getRecentTab.bind(this);

    this.setRecentFilters = this.setRecentFilters.bind(this);

    this.state = {
      loaded: false,
      username: null,
      firstName: null,
      lastName: null,
      user: null,
      userId: null,
      roleId: null,
      permissionIds: [],
      userSettings: {},
      setUserData: this.setUserData,
      setUserSetting: this.setUserSetting,
      hasAnyPermission: this.hasAnyPermission,
      // hasAnyRole: this.hasAnyRole,

      isFavoritePrijevoznik: this.isFavoritePrijevoznik,
      isFavoriteAgencija: this.isFavoriteAgencija,
      isFavoriteChannel: this.isFavoriteChannel,

      toggleFavoritePrijevoznik: this.toggleFavoritePrijevoznik,
      toggleFavoriteAgencija: this.toggleFavoriteAgencija,
      toggleFavoriteChannel: this.toggleFavoriteChannel,

      getRecent: this.getRecent,
      setRecent: this.setRecent,

      setRecentFilters: this.setRecentFilters,

      getRecentTab: this.getRecentTab,
      setRecentTab: this.setRecentTab,
    };
  }

  checkRoleName(code) {
    const role = Role.getRoleByCode(code)
    switch (role.id) {
      case "ADM_SYS":
      case "ADM_APP":
      case "ADM_REQ":
      case "RESEARCH":
      case "CLERK":
      case "USER":
      case "PUBLIC":
        return role.display
      default:
        return null;
    }
  }

  setUserData(data) {
    const {user_id, username, first_name, last_name, email, subject, subject_id, subject_allow_avans, subject_cbc_provizija, permission_ids, role_id, usersettings, platform_bs_id} = data;

    this.setState({
      loaded: true,
      username: username,
      firstName: first_name,
      lastName: last_name,
      user: first_name + ' '  + last_name,
      email: email,
      subject: subject,
      subjectId: subject_id,
      subjectAllowAvans: subject_allow_avans,
      subjectCBCProvision: subject_cbc_provizija,
      roleId: role_id,
      permissionIds: permission_ids,
      userSettings: usersettings,
      userId: user_id,
      platformBSId: platform_bs_id
    });
  }

  setUserSetting(settings, otherSettings = false, callback) {
    let currentSettings = {};

    this.setState(
      (prevState) => {
        currentSettings = prevState.userSettings || {};
        Object.keys(settings).forEach((key) => {
          if(otherSettings) {
            if (!currentSettings.hasOwnProperty("other_settings") || currentSettings.other_settings === null) {
              currentSettings.other_settings = {};
            }
            currentSettings.other_settings[key] = settings[key];
          } else {
            currentSettings[key] = settings[key];
          }
        });
        return {
          userSettings: currentSettings
        };
      },
      () => {
        userService.setUserSettings(settings);
        if (callback && typeof callback === "function") {
          callback();
        }
      }
    );
  }

  setRecentTab(page, tab) {
    const key = "tabs";
    // .getItem returns null if item is not find in localStorage
    // JSON.parse will return null if null is passed to it
    const recentTabs = JSON.parse(localStorage.getItem(key));
    if (recentTabs === null || Object.keys(recentTabs).length === 0) {
      localStorage.setItem(key, JSON.stringify({ [page]: tab }));
    } else {
      recentTabs[page] = tab;
      localStorage.setItem(key, JSON.stringify(recentTabs));
    }
  }

  getRecentTab(page, defaultTab = 0) {
    const key = "tabs";
    // .getItem returns null if item is not find in localStorage
    // JSON.parse will return null if null is passed to it
    const recentTabs = JSON.parse(localStorage.getItem(key));
    if (recentTabs === null || Object.keys(recentTabs).length === 0 || recentTabs[page] === null || recentTabs[page] === undefined) {
      this.setRecentTab(page, defaultTab);
      return defaultTab;
    } else {
      return recentTabs[page];
    }
  }

  setRecent(key, identifier, details) {
    const recent = JSON.parse(localStorage.getItem(key));
    if(recent === null) {
      localStorage.setItem(key, JSON.stringify({[identifier]: details}));
    } else {
      recent[identifier] = details;
      localStorage.setItem(key, JSON.stringify(recent));
    }
  }

  getRecent(key, identifier, defaultDetail = {}) {
    const recent = JSON.parse(localStorage.getItem(key));
    if (recent === null || recent[identifier] === null || recent[identifier] === undefined) {
      this.setRecent(identifier, defaultDetail);
      return defaultDetail;
    } else {
      return recent[identifier];
    }
  }

  setRecentFilters(identifier, id, filter, clearFilter = false) {
    const recent = JSON.parse(localStorage.getItem("filters"));
    if(clearFilter) {
      if (recent !== null && recent !== undefined) {
        recent[identifier] = undefined
        localStorage.setItem("filters", JSON.stringify(recent));
      }
    } else {
      if(recent === null) {
        localStorage.setItem("filters", JSON.stringify({[identifier]: {[id]: filter}}));
      } else {
        if(recent[identifier] === undefined) {
          recent[identifier] = {[id]: filter}
        } else {
          recent[identifier][id] = filter
        }
        localStorage.setItem("filters", JSON.stringify(recent));
      }
    }
  }

  hasAnyPermission(needAnyPermission) {
    if (needAnyPermission === null || needAnyPermission === undefined) {
      return true;
    } else if (!Array.isArray(needAnyPermission)) {
      needAnyPermission = [needAnyPermission];
    } else if (needAnyPermission.length === 0) {
      return true;
    }
    const { permissionIds } = this.state;
    const permissionCodes = {};
    needAnyPermission.forEach(x => {
      permissionCodes[x.code] = true;
    });

    return permissionIds.find(x => permissionCodes[x] !== undefined) !== undefined;
  }

  // Maybe deprecated (definitely buggy)
  // hasAnyRole(needAnyRole) {
  //   if (needAnyRole === null || needAnyRole === undefined) {
  //     return true;
  //   } else if (!Array.isArray(needAnyRole)) {
  //     needAnyRole = [needAnyRole];
  //   } else if (needAnyRole.length === 0) {
  //     return true;
  //   }

  //   const roleCodes = needAnyRole.map(x => x.code);
  //   return this.roleIds.find(x => roleCodes.indexOf(x) >= 0) !== undefined;
  // }

  isFavoritePrijevoznik(id) {
    const { userSettings } = this.state;

    if (userSettings && userSettings.fav_prijevoznici ) {
      if (Array.isArray(userSettings.fav_prijevoznici) && userSettings.fav_prijevoznici.indexOf(id) >= 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isFavoriteAgencija(id) {
    const { userSettings } = this.state;

    if (userSettings && userSettings.fav_agencije ) {
      if (Array.isArray(userSettings.fav_agencije) && userSettings.fav_agencije.indexOf(id) >= 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isFavoriteChannel(id) {
    const { userSettings } = this.state;

    if (userSettings && userSettings.fav_kanali ) {
      if (Array.isArray(userSettings.fav_kanali) && userSettings.fav_kanali.indexOf(id) >= 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  toggleFavoritePrijevoznik(id, isFavorite = null) {
    const { userSettings } = this.state;

    let currentFavs = [];
    let isCurrentlyFavorite = false;
    let shouldBeFavorite = null;
    let favDefined = false;

    if ( userSettings && userSettings.fav_prijevoznici) {
      if (Array.isArray(userSettings.fav_prijevoznici))
      {
        currentFavs = [...userSettings.fav_prijevoznici];
        if (userSettings.fav_prijevoznici.indexOf(id) >= 0) {
          isCurrentlyFavorite = true;
          favDefined = true;
        }
      }
    }

    if (isFavorite !== null) {
      shouldBeFavorite = isFavorite;
    } else {
      shouldBeFavorite = !isCurrentlyFavorite;
    }

    let newFavs = [];
    if (shouldBeFavorite === false) {
      newFavs = currentFavs.filter(x => x !== id); //remove from current
    }
    else {
      if (currentFavs.indexOf(id) === -1) {
        newFavs = currentFavs.concat([id])
      }
    }

    this.setUserSetting({ fav_prijevoznici: newFavs });
  }

  toggleFavoriteAgencija(id, isFavorite = null) {
    const { userSettings } = this.state;

    let currentFavs = [];
    let isCurrentlyFavorite = false;
    let shouldBeFavorite = null;
    let favDefined = false;

    if ( userSettings && userSettings.fav_agencije) {
      if (Array.isArray(userSettings.fav_agencije))
      {
        currentFavs = [...userSettings.fav_agencije];
        if (userSettings.fav_agencije.indexOf(id) >= 0) {
          isCurrentlyFavorite = true;
          favDefined = true;
        }
      }
    }

    if (isFavorite !== null) {
      shouldBeFavorite = isFavorite;
    } else {
      shouldBeFavorite = !isCurrentlyFavorite;
    }

    let newFavs = [];
    if (shouldBeFavorite === false) {
      newFavs = currentFavs.filter(x => x !== id); //remove from current
    }
    else {
      if (currentFavs.indexOf(id) === -1) {
        newFavs = currentFavs.concat([id])
      }
    }

    this.setUserSetting({ fav_agencije: newFavs })
  }

  toggleFavoriteChannel(id, isFavorite = null) {
    const { userSettings } = this.state;

    let currentFavs = [];
    let isCurrentlyFavorite = false;
    let shouldBeFavorite = null;
    let favDefined = false;

    if ( userSettings && userSettings.fav_kanali) {
      if (Array.isArray(userSettings.fav_kanali))
      {
        currentFavs = [...userSettings.fav_kanali];
        if (userSettings.fav_kanali.indexOf(id) >= 0) {
          isCurrentlyFavorite = true;
          favDefined = true;
        }
      }
    }

    if (isFavorite !== null) {
      shouldBeFavorite = isFavorite;
    } else {
      shouldBeFavorite = !isCurrentlyFavorite;
    }

    let newFavs = [];
    if (shouldBeFavorite === false) {
      newFavs = currentFavs.filter(x => x !== id); //remove from current
    }
    else {
      if (currentFavs.indexOf(id) === -1) {
        newFavs = currentFavs.concat([id])
      }
    }

    this.setUserSetting({ fav_kanali: newFavs })
  }

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>

    );
  }
}

export default UserProvider;
