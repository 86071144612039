import React from 'react';

//Custom components
import AgencijePage from "UI/AppPage/AgencijePage";
import KomunikatorPage from "./KomunikatorPage";

const AgencijeKomunikatorPage = React.memo((props) => {
  return (
    <AgencijePage>
      <KomunikatorPage prefix={"a"} />
    </AgencijePage>
  );
})

export default AgencijeKomunikatorPage;
