import React, { Fragment } from 'react'
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

//Material-UI Core Components
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

//Material-UI Lab components
import Rating from '@material-ui/lab/Rating';

//Material-UI Icons
import LockIcon from '@material-ui/icons/Lock';


class RatingControl extends React.Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    this.getLabel = this.getLabel.bind(this);
    this.setHover = this.setHover.bind(this);

    this.state = {
      hoverValue: 0
    }
  }

  handleChange(evt, value) {
    const { field, onChange } = this.props;
    if (onChange) {
      onChange(value, field.source);
    }
  }

  setHover(value) {
    this.setState({ hoverValue: value === -1 ? 0 : value });
  }

  getLabel() {
    const { field, label, t } = this.props;

    if (field) {
      return field.ttoken ? t(field.ttoken) : field.title;
    } else {
      return label;
    }
  }


  render() {
    const { hoverValue } = this.state;
    const { t } = this.props;
    const { field, value, validation, formMode, controlMode } = this.props;

    const isReadOnly = controlMode === "view" || field === undefined || field.readonly || field.type === "categorization";

    const label = this.getLabel();
    //const valueLabel = t(field.items.labels[selectedIndex]);
    const displayValue = value === null || hoverValue !== 0 ? hoverValue : value;
    return (
      <Fragment>
        <Typography component="legend">{label}</Typography>
        <Rating 
          name={label}
          value={value ? value : 5}
          defaultValue={5}
          max={field.max ? field.max : 5}
          precision={field.precision ? field.precision : 1}
          onChange={(event, newValue) => {
            this.handleChange(event, newValue);
          }}
          onChangeActive={(event, newHover) => {
            this.setHover(newHover);
          }}
          disabled={isReadOnly}
          size="large"
        />
        {<Box style={{ display: "inline-block" }} m={1}>{displayValue === 0 ? 5 : displayValue}</Box>}
      </Fragment>
    );
  }
}

RatingControl.defaultProps = {
  formMode: "form",
  controlMode: "edit"
}

RatingControl.propTypes = {
  formMode: PropTypes.oneOf(["form", "table"]),
  controlMode: PropTypes.oneOf(["edit", "view"]),
  // field: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool])
}

export default withTranslation()(RatingControl);
