
const favorite_a = {
  title: "Favorit",
  source: "favorite_a",
  type: "favorite_a",
  ttoken: "common.favorite",
  customSort: "favorite"
};

const favorite_p = {
  title: "Favorit",
  source: "favorite_p",
  type: "favorite_p",
  customSort: "favorite",
  ttoken: "common.favorite"
};

export const buttonFieldsFav = {
  favorite_a: favorite_a,
  favorite_p: favorite_p
};