import React, { Fragment, useState } from 'react'
import PropTypes from "prop-types";

//Material-UI Core Components
import withTheme from '@material-ui/core/styles/withTheme';
import FormHeaderStatusBreadCrumbs from "./FormHeaderStatusBreadCrumbs";

function FormHeaderStatus(props) {

  const [ height, setHeight] = useState(20);
  const [ showLabels, setShowLabels] = useState(false);


  const { recordStatus, statuses, theme, children, ...other} = props;

  const handleClick = () => {
    setHeight(prevState => prevState === 20 ? 40 : 20 );
    setShowLabels(prevState => !prevState);

  }

  const colorBarStyle = {
    width: "100%",
    minWidth: "100%",
    maxWidth: "100%",
    height: height,
    maxHeight: height,
    minHeight: height,
    backgroundColor: "#F3F5FA",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
    //backgroundColor: theme.palette.trjobstatus[props.recordStatus]
  }

  return (
    recordStatus ?
        <div style={colorBarStyle}>
          <FormHeaderStatusBreadCrumbs recordStatus={recordStatus} statuses={statuses} onClick={handleClick} showLabels={showLabels} />
        </div>
       : null
  );
}

FormHeaderStatus.propsTypes = {
  recordStatus: PropTypes.number,
  statuses: PropTypes.array
}

export default withTheme(FormHeaderStatus);
