import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Material UI Icons
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";

// Custom Components
import DialogContext from "UI/DialogContext/DialogContext";
import FormButton from "UI/Form/FormButton";
import ModelTable from "UI/Table/ModelTable";
import TableCard from "UI/Table/TableCard";
import TableCardContent from "UI/Table/TableCardContent";
import TableButton from "UI/Table/TableButton";
import ToolbarFillContent from "UI/Toolbar/ToolbarFillContent";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import UserContext from "Components/UserContext/UserContext";

import model from "Models/p_korisnici";
import dataController from "Lib/dataController";
import PrijevozniciKorisniciDialog from "Views/Prijevoznici/Korisnici/PrijevozniciKorisniciDialog";


// TODO:
// subModels cleanup: they are added to the project because Table.jsx can not function without them

function PrijevozniciKorisniciTable(props) {
  const dialogContext = useContext(DialogContext);
  const loaderContext = useContext(LoaderContext);
  const userContext = useContext(UserContext);
  const { t } = useTranslation();

  const [records, setRecords] = useState([]);

  const dc = new dataController(model);

  useEffect(() => {
    refreshRecords();
  }, [userContext.subjectId]);

  const handleAdd = (evt) => {
    dialogContext.showDialog(PrijevozniciKorisniciDialog, {
      dc: dc,
      mode: "insert",
      form: "insert",
      onClose: handleDialogClose,
      defaultValues: { permission: 0, user_role: 100 },
      referrer: "list"
    });
  };

  const handleEdit = (evt, sel) => {
    const selId = parseInt(sel[0], 10);
    const record = records[selId];

    const path = "prijevoznici/" + userContext.subjectId + "/korisnici/" + record.id;

    dc.CustomGetAction(path).then(result => {
      if (result.success) {
        dialogContext.showDialog(PrijevozniciKorisniciDialog, {
          dc: dc,
          mode: "update",
          form: "update",
          record: result.data,
          onClose: handleDialogClose
        });
      }
    }).catch((err) => {
      console.error(err);
    })
  };

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords();
    }
  };

  const refreshRecords = () => {
    if (userContext.subjectId) {
      loaderContext.toggleLoading(true);
      const path = "prijevoznici/" + userContext.subjectId + "/korisnici"
      dc.GetData(path)
          .then((resp) => {
            if (resp.success) {
              setRecords(resp.data)
            };
          })
          .finally(() => {
            loaderContext.toggleLoading(false);
          });
    }
  };

  return (
      <TableCard>
        <TableCardContent>
          <ModelTable
              title={t("titles.prijevoznik_korisnici")}
              records={records}
              dc={dc}
              allowSelection="one"
              allowFilter={true}
              allowExport={true}
              allowAdd={true}
              addLabel={t("buttons.new_m")}
              handleAdd={handleAdd}
          >
            <TableButton variant="outlined" allow="one" onClick={handleEdit} startIcon={<EditIcon />}>
              {t("buttons.edit")}
            </TableButton>
          </ModelTable>
        </TableCardContent>
      </TableCard>
  );
}

export default PrijevozniciKorisniciTable;
