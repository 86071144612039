import numbro from "numbro";

export const euroExchangeRate = 7.53450;

export const formatEuro = (value) => {
  if (!value) {
  	return numbro(0).format({ thousandSeparated: true, mantissa: 2 });
  }
  const n = typeof value === 'string' ? value.replace(',', '.') : value;
  const euroValue = parseFloat(n) / euroExchangeRate;
  const numValue = numbro(euroValue);
  if (numValue.value() !== undefined && numValue.value() !== null) {
    return numValue.format({ thousandSeparated: true, mantissa: 2 });
  } else {
    return "";
  }
}

export const formatDisplayValue = (value, t, addHRK = false) => {
  if (!value) return "";
  const hrk = numbro(value).format({ thousandSeparated: true, mantissa: 2 });
  const euro = formatEuro(value);
  return `${hrk}${addHRK ? " HRK" : ""} (${t('common.euro', { value: euro })})`;
}