import React, { useRef, useState } from "react";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";

//Material-UI Icons
import LockIcon from "@material-ui/icons/Lock";

function GooglePlacesAutocomplete(props) {
  const inputRef = useRef(null);

  const [inputValue, setInputValue] = useState(null);

  const { t } = useTranslation();

  const { field, onChange, value, validation, formMode, controlMode, inputProps } = props;

  const handleChange = (opt) => {
    if (opt && opt.place_id) {
      if (onChange) {

        let name = opt.name ? opt.name : "";
        let formatted_address = opt.formatted_address ? opt.formatted_address : "";

        if (formatted_address.indexOf(name) === 0) {
          name = "";
        }
        // const street_number = opt.address_components.find((x) => x.types.indexOf("street_number") >= 0);
        // const ulica = opt.address_components.find((x) => x.types.indexOf("route") >= 0);
        // const locality = opt.address_components.find((x) => x.types.indexOf("locality") >= 0);
        const country = opt.address_components.find((x) => x.types.indexOf("country") >= 0);
        if (country.short_name === "HR" && formatted_address.length > country.long_name.length) {
          const ind = formatted_address.length - country.long_name.length;
          const faCountry = formatted_address.substring(ind);
          if (faCountry === country.long_name) {
            formatted_address = formatted_address.substring(0, ind - 2);
          }
        }

        let label = "";

        label += name ? name : "";
        label += label ? ", " + formatted_address : formatted_address;
        // label += name !== locality.long_name ? (name + ", ") : "";
        // label += ulica ? ulica.long_name : ""
        // label += street_number ? " " + street_number.long_name : "";
        // label += locality ? ( label ? ", ": "") + locality.long_name : "";
        // label += country.short_name === "HR" ? "" : `, ${country.long_name}`;

        const loc = opt.geometry.location;
        const coords = { lat: loc.lat(), lng: loc.lng() };

        onChange(
          {
            value: opt.place_id,
            label: label,
            coords: coords
          },
          field.source
        );
        setInputValue(null);
      }
    } else if (!opt) {
      onChange(undefined, field.source);
    }
  };

  const handleInputChange = (evt) => {
    const val = evt.currentTarget.value;
    setInputValue(val);
  };

  const { ref: materialRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_APIKEY,
    onPlaceSelected: handleChange,
    inputAutocompleteValue: inputValue,
    options: {
      types: "address",
      fields: ["address_components", "geometry.location", "place_id", "formatted_address", "name"]
    },
    language: "hr"
  });

  const isRequired = field.validation && field.validation.required ? true : false;
  const hasError = validation && validation.valid === false;
  const label = field.ttoken ? t(field.ttoken) : field.title;
  const helperText = field.tooltip ? t(field.tooltip) : "";
  const isReadOnly = controlMode === "view" || (field && field.readonly);

  const displayValue = inputValue != null ? inputValue : value && value.label ? value.label : "";

  const endAdornmentIcons = [];
  if (isReadOnly) {
    endAdornmentIcons.push( <LockIcon style={{ fontSize: 20 }} color="disabled" />  );
  }
  const InputProps = Object.assign({
    readOnly: isReadOnly,
    endAdornment: isReadOnly ? <LockIcon style={{ fontSize: 20 }} color="disabled" /> :
                  null
  }, inputProps);


  return (
    <TextField
      margin="none"
      fullWidth
      required={isRequired}
      error={hasError}
      label={formMode === "form" ? t(label) : null}
      color="secondary"
      variant="outlined"
      inputRef={!isReadOnly ? materialRef : undefined}
      InputProps={InputProps}
      value={displayValue}
      helperText={hasError ? validation.msg : helperText}
      onChange={handleInputChange}
    />
  );

  // return (
  //   <Input
  //     fullWidth
  //     color="secondary"
  //     inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
  //       <Autocomplete
  //         varinat="outlined"
  //         apiKey={process.env.REACT_APP_GOOGLE_APIKEY}
  //         {...props}
  //         onPlaceSelected={(selected) => console.log(selected)}
  //       />
  //     )}
  //   />
  // );
}

export default GooglePlacesAutocomplete;
