export {
    dateOptions
}

const dateOptions = [
    {
      title: "datefilter.recent_heading",
      options:  [
        {
          label: "datefilter.tomorrow",
          value: "tomorrow"
        },
        {
          label: "datefilter.today",
          value: "today"
        },
        {
          label: "datefilter.yesterday",
          value: "yesterday"
        },
      ]
    },
    {
      title: "datefilter.week_heading",
      options:  [
        {
          label: "datefilter.next_2_weeks",
          value: "next_2_weeks"
        },
        {
          label: "datefilter.next_week",
          value: "next_week"
        },
        {
          label: "datefilter.next_7_days",
          value: "next_7_days"
        },
        {
          label: "datefilter.this_week",
          value: "this_week"
        },
        {
          label: "datefilter.last_7_days",
          value: "last_7_days"
        },
        {
          label: "datefilter.last_week",
          value: "last_week"
        },
        {
          label: "datefilter.last_2_weeks",
          value: "last_2_weeks"
        },
      ]
    },
    {
      title: "datefilter.month_heading",
      options:  [
        {
          label: "datefilter.next_month",
          value: "next_month"
        },
        {
          label: "datefilter.next_30_days",
          value: "next_30_days"
        },
        {
          label: "datefilter.this_month",
          value: "this_month"
        },
        {
          label: "datefilter.last_30_days",
          value: "last_30_days"
        },
        {
          label: "datefilter.last_month",
          value: "last_month"
        },
      ]
    },
    {
      title: "datefilter.year_heading",
      options: [
        {
          label: "datefilter.next_year",
          value: "next_year"
        },
        {
          label: "datefilter.this_year",
          value: "this_year"
        },
        {
          label: "datefilter.last_year",
          value: "last_year"
        }
      ]
    }
  ]