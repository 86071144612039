import React from "react";

//Custom Components
import AgencijePage from "UI/AppPage/AgencijePage";
import ResponsiveBox from "UI/Box/ResponsiveBox";
import AgencijeKorisniciTable from "./AgencijeKorisniciTable";

const AgencijeKorisniciPage = React.memo((props) => {
  return (
    <AgencijePage>
      <ResponsiveBox fillHeight>
        <AgencijeKorisniciTable />
      </ResponsiveBox>
    </AgencijePage>
  );
});

export default AgencijeKorisniciPage;
