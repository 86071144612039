import { coreFields } from "./helpers/coreFields";
import { buttonFields } from "./helpers/buttonFields";

export default {
  title: "Eksterni troskovi",
  exportFileName: "Eksterni troskovi",
  source: "eksterniTroskovi",
  apiPath: "eksterni-troskovi/",
  forms: {
    default: {
      fields: [
        "id", "opis", "iznos_neto", "pdv_stopa", "pdv_iznos", "iznos_bruto"
      ]
    },
    insert: {
      fields: [
        "id", "opis", "iznos_neto", "pdv_stopa", "pdv_iznos", "iznos_bruto"
      ]
    },
    update: {
      fields: [
        "id", "opis", "iznos_neto", "pdv_stopa", "pdv_iznos", "iznos_bruto",
        "created_on", "created_by"
      ]
    }
  },
  listViews: {
    default: {
      fields: ["id", "opis", "iznos_neto", "pdv_stopa", "pdv_iznos", "iznos_bruto", "edit"],
      hidden: ["id"]
    }
  },
  fields: [
    coreFields.id,
    coreFields.created_by,
    coreFields.created_on,
    {
      title: "Iznos Neto",
      source: "iznos_neto",
      type: "currency",
      ttoken: "financijskeKalkulacija.iznos_neto",
      validation: {
        required: true,
        maxValue: 99999999
      }
    },
    {
      title: "PDV Stopa",
      source: "pdv_stopa",
      ttoken: "financijskeKalkulacija.pdv_stopa",
      type: "picker",
      items: {
        labels: ["25.0%", "0%"],
        values: [25, 0]
      },
      validation: {
        required: true
      }
    },
    {
      title: "PDV Iznos",
      source: "pdv_iznos",
      type: "currency",
      ttoken: "financijskeKalkulacija.pdv_iznos",
      validation: {
        required: true,
        maxValue: 99999999
      },
      calculated: true,
      readonly: true
    },
    {
      title: "Iznos Bruto",
      source: "iznos_bruto",
      type: "currency",
      ttoken: "financijskeKalkulacija.iznos_bruto",
      validation: {
        required: true,
        maxValue: 99999999
      },
      calculated: true,
      readonly: true
    },
    {
      title: "Opis",
      source: "opis",
      ttoken: "financijskeKalkulacija.opis",
      type: "multiline",
      maxrows: 5,
      width: "full",
      bind: true,
      validation: {
        required: true,
        maxLength: 250
      }
    },
    buttonFields.edit
  ]
};
