import React from "react";
import ThemeContext from "Components/ThemeContext/ThemeContext";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { grey, red, purple } from "@material-ui/core/colors";
import CssBaseline from "@material-ui/core/CssBaseline";

const THEME_LIGHT = "light";
const THEME_DARK = "dark";

const lightTheme = createMuiTheme({
  // typography:{
  //   h3:{
  //     fontFamily: "'Fira Sans', sans-serif"
  //   },
  //   h4:{
  //     fontFamily: "'Fira Sans', sans-serif"
  //   },
  //   h5:{
  //     fontFamily: "'Fira Sans', sans-serif"
  //   },
  //   h6:{
  //     fontFamily: "'Fira Sans', sans-serif"
  //   },
  //   body1:{
  //     fontFamily: "'Fira Sans', sans-serif"
  //   },
  //   body2:{
  //     fontFamily: "'Fira Sans', sans-serif"
  //   },
  //   button:{
  //     fontFamily: "'Fira Sans', sans-serif"
  //   },
  //   fontFamily: "'Roboto',sans-serif"
  // },
  palette: {
    primary: {
      main: grey[800],
      dark: grey[900],
      light: grey[700],
      contrastText: "#FFF"
    },
    secondary: {
      main: "#b7c800",
      contrastText: "#000"
    },
    background: {
      default: "#eaeee2",
      primary: "#fff"
    },
    trjobstatus: {
      20: "#DDDBDF",
      21: "#C7C5C9",
      22: "#FCF2A3",
      23: "#F1D852",
      24: "#91D0C0",
      25: "#5AB786",
      26: "#67A2CB",
      29: "#A1CDE0"
    },
    alarm_za: {
      lt_2h: "#d803d8",
      lt_1d: "#ea9999",
      lt_3d: "#f9cb9c",
      lt_1w: "#ffe599",
      lt_2w: "#9fc5e8",
      lt_1m: "transparent"
    },
    custom: {
      "pp": "#D4AF37",
      "panic": "#c62828",
      "agentura": "#e65100",
      "storno": "#7b1fa2"
    }
  },
  overrides: {
    MuiInputBase: {
      input: {
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
          transitionProperty: "background-color, color"
        }
      }
    },
    MuiTableCell: {
      stickyHeader: {
        backgroundColor: grey[200]
      }
    }
  }
});

const darkTheme = createMuiTheme({
  // typography:{
  //   h3:{
  //     fontFamily: "'Fira Sans', sans-serif"
  //   },
  //   h4:{
  //     fontFamily: "'Fira Sans', sans-serif"
  //   },
  //   h5:{
  //     fontFamily: "'Fira Sans', sans-serif"
  //   },
  //   h6:{
  //     fontFamily: "'Fira Sans', sans-serif"
  //   },
  //   body1:{
  //     fontFamily: "'Fira Sans', sans-serif"
  //   },
  //   body2:{
  //     fontFamily: "'Fira Sans', sans-serif"
  //   },
  //   button:{
  //     fontFamily: "'Fira Sans', sans-serif"
  //   },
  //   fontFamily: "'Roboto',sans-serif"
  // },
  palette: {
    type: "dark",
    primary: {
      main: grey[800],
      dark: grey[900],
      light: grey[700],
      contrastText: "#FFF"
    },
    secondary: {
      main: "#b7c800",
      contrastText: "#fff"
    },
    trjobstatus: {
      20: "#DDDBDF",
      21: "#C7C5C9",
      22: "#FCF2A3",
      23: "#F1D852",
      24: "#91D0C0",
      25: "#5AB786",
      26: "#67A2CB",
      29: "#A1CDE0"
    },
    alarm_za: {
      lt_2h: "#d803d8",
      lt_1d: "#ea9999",
      lt_3d: "#f9cb9c",
      lt_1w: "#ffe599",
      lt_2w: "#9fc5e8",
      lt_1m: "transparent"
    },
    custom: {
      "pp": "#D4AF37",
      "panic": "#c62828",
      "agentura": "#e65100",
      "storno": "#7b1fa2"
    }
  },
  background: {
    primary: "#424242"
  },
  overrides: {
    MuiInputBase: {
      input: {
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
          transitionProperty: "background-color, color"
        }
      }
    },
    MuiTableCell: {
      stickyHeader: {
        backgroundColor: grey[700]
      }
    }
  }
});

class ThemeProvider extends React.Component {
  constructor(props) {
    super(props);

    this.setTheme = this.setTheme.bind(this);

    this.state = {
      theme: THEME_LIGHT,
      setTheme: this.setTheme
    };
  }

  componentDidMount() {
    const myCurrentTheme = localStorage.getItem("theme");

    if (myCurrentTheme) {
      this.setState({
        theme: myCurrentTheme
      });
    }
  }


  setTheme(theme) {
    localStorage.setItem("theme", theme);
    if (theme === THEME_LIGHT) {
      this.setState({ theme: THEME_LIGHT });
    } else if (theme === THEME_DARK) {
      this.setState({ theme: THEME_DARK });
    } else {
      this.setState({ theme: THEME_LIGHT });
    }
  }

  getMuiTheme() {
    const { theme } = this.state;
    if (theme === THEME_LIGHT) {
      return lightTheme;
    } else if (theme === THEME_DARK) {
      return darkTheme;
    } else {
      return lightTheme;
    }
  }

  render() {

    const muiTheme = this.getMuiTheme();

    return (
      <ThemeContext.Provider value={this.state}>
        <MuiThemeProvider theme={muiTheme}>
          <CssBaseline />
          {this.props.children}
        </MuiThemeProvider>
      </ThemeContext.Provider>
    );
  }
}

export default ThemeProvider;
