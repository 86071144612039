export default {
    title: "Ugovori",
    exportFileName: "ugovori",
    apiPath: "/ugovori",
    forms: {
        default: {
            fields: ["posao_id", "broj_sjedala", "datum_ugovora", "iznos_hrk"]
        }
    },
    fields: [
        {
            title: "Ugovor ID",
            source: "id",
            type: "numeric",
            idattr: true
        },
        {
            title: "Posao ID",
            source: "posao_id",
            type: "numeric"
        },
        {
            title: "Broj SJedala",
            source: "broj_sjedala",
            ttoken: "ugovori.broj_sjedala",
            type: "wholenum",
            validation: {
                maxValue: 2147483647
            }
        },
        {
            title: "Datum ugovora",
            source: "datum_ugovora",
            ttoken: "ugovori.datum_ugovora",
            type: "date"
        },
        {
            title: "Iznos",
            source: "ugovor_iznos_hrk",
            ttoken: "ugovori.ugovor_iznos",
            type: "currency",
            validation: {
                maxValue: 99999999
            }
        }
    ]

};
