import React from "react";
import { useParams } from "react-router";

//Material UI
import Box from "@material-ui/core/Box";

//Custom components
import PrijevozniciPage from "UI/AppPage/PrijevozniciPage";
import withRedirect from "Components/withRedirect";
import VoznjaForm from "./VoznjaForm";

const VoznjaView = React.memo((props) => {
  const { id } = useParams();
  const recordId = parseInt(id, 10);

  return (
      <PrijevozniciPage>
        <Box m={2}>
          <VoznjaForm id={recordId}/>
        </Box>
      </PrijevozniciPage>
  );
})

export default withRedirect(VoznjaView);
