import React, { useEffect, useState } from "react";
import moment from "moment";

// Material UI icons
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";
import PersonIcon from "@material-ui/icons/Person";
import HelpIcon from "@material-ui/icons/Help";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";

// Custom components
import SchedulerPicker from "Components/Scheduler/SchedulerPicker";
import SchedulerGridBarBox from "Components/Scheduler/SchedulerGridBarBox";

// @Piero
// What the fuck does this do???
class UngroupIcons extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modelRecord: null
    };
  }

  componentDidMount() {
    // this.props.config.fetch_model('trsubjob').then(model => {
    //   this.setState({modelRecord: model});
    // })
  }

  render() {
    const { subjobs, classes, classesConflict, dcVozilo, dcVozac, dcRecord, dcPojedinacnaVoznja, conflictsActive, posaoId, onChange } = this.props;
    const drivers = [];
    const vehicles = [];
    const cooperants = [];

    if (Array.isArray(subjobs)) {
      subjobs.map((item) => {
        const singleDrivers = [];
        let i = 1;

        ["vozac1", "vozac2", "vozac3"].forEach((vozac) => {
          let conflictExists = false;

          if (
            item[vozac] &&
            this.props.allConflicts[item[vozac].toString()] &&
            this.props.allConflicts[item[vozac].toString()].conflicts
          ) {
            conflictExists = this.props.allConflicts[item[vozac].toString()].conflicts > 0;

            if (conflictExists) {
              conflictExists = false;

              const itemTime = {
                from: moment(item.ts1).format("YYYY-MM-DD hh:mm:ss"),
                to: moment(item.ts2).format("YYYY-MM-DD hh:mm:ss")
              };

              const conflictVozac = this.props.allConflicts[item[vozac].toString()];

              if (conflictVozac && Array.isArray(conflictVozac.conflictInterval)) {
                conflictVozac.conflictInterval.forEach((conf) => {
                  if (
                    (conf.from.format("YYYY-MM-DD hh:mm:ss") <= itemTime.from &&
                      itemTime.from < conf.to.format("YYYY-MM-DD hh:mm:ss")) ||
                    (conf.from.format("YYYY-MM-DD hh:mm:ss") < itemTime.to &&
                      itemTime.to <= conf.to.format("YYYY-MM-DD hh:mm:ss")) ||
                    (conf.from.format("YYYY-MM-DD hh:mm:ss") >= itemTime.from &&
                      conf.to.format("YYYY-MM-DD hh:mm:ss") <= itemTime.to)
                  ) {
                    conflictExists = true;
                  }
                });
              }
            }
          }

          let driverClass = classes;
          if (item[vozac] && item[vozac] === conflictsActive.id) {
            driverClass = classesConflict;
          }

          singleDrivers.push({
            value: item[`${vozac}_tag`],
            conflict: conflictExists,
            classes: driverClass,
            id: item[vozac]
          });
        });

        drivers.push(singleDrivers);

        let conflictExists = false;

        if (item.vozilo) {
          const conflictVozilo = this.props.allConflicts[item.vozilo.toString()];

          if (conflictVozilo && conflictVozilo.conflicts) {
            conflictExists = conflictVozilo.conflicts > 0;

            if (conflictExists) {
              conflictExists = false;

              const itemTime = {
                from: moment(item.ts1).format("YYYY-MM-DD hh:mm:ss"),
                to: moment(item.ts2).format("YYYY-MM-DD hh:mm:ss")
              };
              if (Array.isArray(conflictVozilo.conflictInterval)) {
                conflictVozilo.conflictInterval.forEach((conf) => {
                  if (
                    (conf.from.format("YYYY-MM-DD hh:mm:ss") <= itemTime.from &&
                      itemTime.from < conf.to.format("YYYY-MM-DD hh:mm:ss")) ||
                    (conf.from.format("YYYY-MM-DD hh:mm:ss") < itemTime.to &&
                      itemTime.to <= conf.to.format("YYYY-MM-DD hh:mm:ss")) ||
                    (conf.from.format("YYYY-MM-DD hh:mm:ss") >= itemTime.from &&
                      conf.to.format("YYYY-MM-DD hh:mm:ss") <= itemTime.to)
                  ) {
                    conflictExists = true;
                  }
                });
              }
            }
          }
        }

        let vehicleClass = classes;
        if (item.vozilo && item.vozilo === conflictsActive.id) {
          vehicleClass = classesConflict;
        }

        vehicles.push({ value: item.vozilo_tag, conflict: conflictExists, classes: vehicleClass, id: item.vozilo });

        cooperants.push({ value: item.kooperant_tag, conflict: false });
      });
    }

    return Array.isArray(subjobs)
      ? subjobs.map((x, i) => (
        <SchedulerGridBarBox key={"subjob_icons_" + i} >
            {cooperants[i].value ? (
              <div style={{ display: "inline-block" }}>
                <AirportShuttleIcon fontSize="small" />
                {true ? <span style={{ float: "right" }}>&nbsp;{cooperants[i].value}&nbsp;</span> : null}
              </div>
            ) : null}
            {vehicles[i].value && !cooperants[i].value ? (
              <div style={{ display: "inline-block" }}>
                <DirectionsBusIcon
                  fontSize="small"
                  classes={vehicles[i].conflict ? classesConflict : classes}
                  onClick={
                    vehicles[i].conflict
                      ? () =>
                          this.props.onIconClick({
                            trjobId: this.props.trjobId,
                            id: vehicles[i].id,
                            timeSpan: { from: moment(x.ts1), to: moment(x.ts2) }
                          })
                      : null
                  }
                />
                {this.props.registrationState ? (
                  <span style={{ float: "right" }} className={vehicles[i].classes.root}>
                    <SchedulerPicker
                      conflict={vehicles[i].conflict && vehicles[i].id === conflictsActive.id}
                      label={vehicles[i].value}
                      dcRecord={dcPojedinacnaVoznja}
                      dcPicker={dcVozilo}
                      fieldName={"vozilo_id"}
                      fieldValue={vehicles[i].id}
                      recordId={x.trsubjobid}
                      autoFocus={true}
                      posaoId={posaoId}
                      voznjaId={x.id}
                      onChange={onChange}
                    />
                  </span>
                ) : null}
              </div>
            ) : null}
            {drivers[i].map((driver, j) => {
              if (cooperants[i].value) {
                return null;
              } else if (driver.value === "?") {
                return <HelpIcon color="disabled" fontSize="small" key={"vozac-" + i + "-" + j} />;
              } else if (driver.value) {
                return (
                  <div key={`div${j}`} style={{ display: "inline-block" }}>
                    <PersonIcon
                      key={`icon${j}`}
                      fontSize="small"
                      classes={driver.conflict ? classesConflict : classes}
                      onClick={
                        driver.conflict
                          ? () =>
                              this.props.onIconClick({
                                trjobId: this.props.trjobId,
                                id: driver.id,
                                timeSpan: { from: moment(x.ts1), to: moment(x.ts2) }
                              })
                          : null
                      }
                    />
                    {this.props.driverState ? (
                      <div style={{ float: "right" }} key={`text${j}`} className={driver.classes.root}>
                        <SchedulerPicker
                          conflict={driver.conflict && driver.id === conflictsActive.id}
                          label={driver.value}
                          dcRecord={dcPojedinacnaVoznja}
                          dcPicker={dcVozac}
                          fieldName={"vozac_" + (j + 1) + "_id"}
                          fieldValue={driver.id}
                          recordId={x.trsubjobid}
                          autoFocus={true}
                          posaoId={posaoId}
                          voznjaId={x.id}
                          onChange={onChange}
                        />
                      </div>
                    ) : null}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </SchedulerGridBarBox>
        ))
      : null;
  }
}

export default UngroupIcons;
