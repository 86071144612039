import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI
import SaveIcon from "@material-ui/icons/Save";

//Custom Components
import CardActionButton from "UI/Card/CardActionButton";

function CardActionButtonSave(props) {
  const { t } = useTranslation();

  return (
    <CardActionButton startIcon={<SaveIcon />} variant="contained" {...props} aria-label="Save" aria-controls="Save">
      {t("buttons.save")}
    </CardActionButton>
  );
}

export default CardActionButtonSave;
