import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";

//Material UI
import { Card, CardContent, Checkbox, Typography, CardActionArea } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import StarsIcon from "@material-ui/icons/Stars";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import makeStyles from "@material-ui/core/styles/makeStyles";

//Custom components
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import DialogContext from "UI/DialogContext/DialogContext";
import PrijevoznikPublicDialog from "./PrijevoznikPublicDialog";
import Available from "Images/available.svg";
import Reserved from "Images/reserved.svg";
import Sold from "Images/sold.svg";
import VoziloPublicDialog from "./VoziloPublicDialog";
import AwaitingPhoto from "Images/awaiting_photo.jpg";
import StarRatingIcon from "Icons/StarRatingIcon";
import numbro from "numbro";

const cardStyles = makeStyles((theme) => ({
  card: {
    width: "300px",
    height: "100%",
    borderRadius: "8px",
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    WebKitTransform: "perspective(1px) translateZ(0)",
    transform: "perspective(1px) translateZ(0)",
    WebkitTransitionDuration: "0.2s",
    TransitionDuration: "0.2s",
    WebKitTransitionProperty: "transform",
    transitionProperty: "transform",
    "&:hover": {
      WebKitTransform: "scale(0.98)",
      transform: "scale(0.98)"
    },
    "&:focus": {
      WebKitTransform: "scale(0.98)",
      transform: "scale(0.98)"
    },
    "&:active": {
      WebKitTransform: "scale(0.98)",
      transform: "scale(0.98)"
    },
    borderStyle: "solid",
    borderColor: theme.palette.secondary.main
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "120px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "200px 200px",
    paddingBottom: "16px"
  },
  bannerContainer: {
    position: "relative"
  },
  bannerImage: {
    position: "absolute",
    top: "0px",
    left: "0px",
    zIndex: 4,
    width: "100%",
    objectFit: "cover",
    height: "120px",
    cursor: "pointer"
  },
  avatarImage: {
    position: "absolute",
    top: "75px",
    right: "5%",
    zIndex: 5,
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
    cursor: "pointer"
  },
  img: {
    width: "100%",
    height: "100%",
    borderRadius: "4px",
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"
  },
  longText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  vozilo_title: {
    cursor: "pointer",
    "&:hover": {
      color: "#0000FF"
    }
  },
  prijevoznik_title: {
    fontSize: "13px",
    cursor: "pointer",
    zIndex: 10,
    "&:hover": {
      color: "#0000FF"
    },
    position: "absolute",
    top: "140px",
    right: "4%"
  },
  infoIconFontSizeLarge: {
    fontSize: "2.5rem"
  }
}));

function TrazilicaResultCard(props) {
  const dialogContext = useContext(DialogContext);

  const { t } = useTranslation();
  const { result, onToggleCheck, checked, disabled } = props;

  const styles = cardStyles();

  // const zauzetostLabel_T = sifrarnici.zauzetost;

  const cijena_info = t("trazilica.cijena_info") + " " + t("trazilica.copy_cijena_info");
  const bannerImage = result.thumb ? `data:image/jpeg;base64, ${result.thumb}` : AwaitingPhoto;
  const logoImage = result.logo_thumb ? `data:${result.image_mime_type};base64, ${result.logo_thumb}` : "https://via.placeholder.com/150";

  const handleCheck = (evt, id) => {
    if (!disabled && (evt.target.tagName !== "path" && evt.target.tagName !== "svg")) {
      const target = { checked: !checked };
      const value = !checked;
      const pp_id = result.posebna_ponuda_id;
      onToggleCheck(value, id, pp_id);
    }
  };

  const showVoziloDialog = (id) => {
    dialogContext.showDialog(VoziloPublicDialog, {
      recordId: id
    });
  };

  const handleOpenPrijevzonikPublicDialog = (bs_id) => {
    dialogContext.showDialog(PrijevoznikPublicDialog, {
      recordId: bs_id,
      sendUpit: false
    });
  };

  const longToShortString = (string, max) => {
    if (string && string.length > max) {
      return string.substring(0, max - 1) + "...";
    } else {
      return string;
    }
  };

  const getBckColor = () => {
    if (result.posebna_ponuda) {
      return "#D4AF37";
    }
  };

  const getBckImage = (status) => {
    switch (status.tip) {
      case 1:
        return Available;
      case 2:
        return Reserved;
      case 3:
        return Sold;
      default:
        return null;
    }
  };

  const bckColor = getBckColor();
  const bckImage = getBckImage(result.zauzetost);
  const zauzetostTitle = result.zauzetost && result.zauzetost.to_ts ? `${t("trazilica.rezultati.zauzetost_info", { date: moment(result.zauzetost.to_ts).format("DD.MM.YYYY HH:mm") })}` : null;

  return (
    <Card className={styles.card} raised>
      <div className={styles.bannerContainer}>
        <img src={bannerImage} className={styles.bannerImage} onClick={() => showVoziloDialog(result.id)} />
        <img src={logoImage} className={styles.avatarImage} onClick={() => handleOpenPrijevzonikPublicDialog(result.owner_bs_id)} />
        {result.posebna_ponuda ? (
          <Tooltip title={t("trazilica.rezultati.posebna_ponuda")}>
            <StarsIcon style={{ position: "absolute", zIndex: 6, fontSize: 35 }}
                       htmlColor="#D4AF37"
            />
          </Tooltip>
        ) : null}
      </div>
      <CardActionArea onClick={(evt) => handleCheck(evt, result.id)}>
        <Tooltip title={zauzetostTitle} disableHoverListener={result.zauzetost && result.zauzetost.tip !== 2 }>
          <CardContent style={{ backgroundColor: bckColor, backgroundImage: `url(${bckImage})` }} className={styles.cardContent}>
            <GridContainer direction="column" justify="flex-start">
              <GridItem>
                <Typography className={styles.vozilo_title} onClick={() => showVoziloDialog(result.id)}><b>{result.marka_tip}</b></Typography>
              </GridItem>
              <GridItem style={{ paddingBottom: "2px" }}>
                <GridContainer direction="row" justify="space-between" spacing={0}>
                  <GridItem>
                    <Typography>{t("trazilica.rezultati.klasa_vozila")}:</Typography>
                  </GridItem>
                  <GridItem>
                    <Typography><b>{result.klasa_vozila}</b></Typography>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem style={{ paddingBottom: "2px" }}>
                <GridContainer direction="row" justify="space-between" spacing={0}>
                  <GridItem>
                    <Typography>{t("trazilica.rezultati.lokacija")}:</Typography>
                  </GridItem>
                  <GridItem>
                    <Typography><b>{longToShortString(result.loc_name, 25)}</b></Typography>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem style={{ paddingBottom: "2px" }}>
                <GridContainer direction="row" justify="space-between" spacing={0}>
                  <GridItem>
                    <Typography>{t("trazilica.rezultati.klasa_motora")}:</Typography>
                  </GridItem>
                  <GridItem>
                    <Typography><b>{result.klasa_motora}</b></Typography>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem style={{ paddingBottom: "2px" }}>
                <GridContainer direction="row" justify="space-between" spacing={0}>
                  <GridItem>
                    <Typography>{t("trazilica.rezultati.broj_sjedala")}:</Typography>
                  </GridItem>
                  <GridItem>
                    <Typography><b>{result.br_sjedala}</b></Typography>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem style={{ paddingBottom: "2px" }}>
                <GridContainer direction="row" justify="space-between" spacing={0}>
                  <GridItem>
                    <Typography>{t("trazilica.rezultati.godina_proizvodnje")}:</Typography>
                  </GridItem>
                  <GridItem>
                    <Typography><b>{result.godina_proizvodnje}</b></Typography>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem style={{ paddingBottom: "2px" }}>
                <GridContainer direction="row" justify="space-between" spacing={0}>
                  <GridItem>
                    <Typography>{t("trazilica.rezultati.evaluacija_prijevoznika")}:</Typography>
                  </GridItem>
                  <GridItem>
                    <StarRatingIcon count={result.evaluacija} />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem>
                <GridContainer direction="row" justify="space-between" spacing={0}>
                  <GridItem>
                    <GridContainer direction="row" justify="flex-start">
                      <GridItem style={{ paddingRight: "2px", paddingTop: "0" }}>
                        <Tooltip title={cijena_info} placement="top" leaveTouchDelay={1000} arrow>
                          <CopyToClipboard text={t("trazilica.cijena_info")}>
                            <InfoIcon classes={{fontSizeLarge: styles.infoIconFontSizeLarge}} fontSize="large" />
                          </CopyToClipboard>
                        </Tooltip>
                      </GridItem>
                      <GridItem style={{ paddingLeft: 0 }}>
                        <Typography style={{ fontSize: "18px" }}><b>{numbro(result.price).format({ thousandSeparated: true, mantissa: 2 })} €</b></Typography>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem>
                    <Checkbox
                      style={{ padding: 0 }}
                      disabled={disabled}
                      checked={checked}
                      value={result.id}
                      onChange={(evt) => handleCheck(evt, result.id)}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </CardContent>
        </Tooltip>
      </CardActionArea>
    </Card>
  );
}

TrazilicaResultCard.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onToggleCheck: PropTypes.func
};

export default TrazilicaResultCard;
