import React, { useContext } from "react";
import StarIcon from "@material-ui/icons/Star";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import ThemeContext from "../components/ThemeContext/ThemeContext";

const useStyles = makeStyles({
  root: {
    position: "relative",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center"
  },
  icon: {
    fontSize: "2.8em"
  },
  count: {
    position: "absolute",
    lineHeight: 1,
    top: "12px",
    fontSize: "1em"
  }
});

function StarRatingIcon({ size = 12, count = 0 }) {
  const classes = useStyles();
  const themeContext = useContext(ThemeContext);

  const textColor = themeContext.theme === "light" ? "#fff" : "#000";

  return (
    <div className={classes.root} style={{ fontSize: size }}>
      <StarIcon className={classes.icon} />
      <Typography component="span" className={classes.count} style={{ color: textColor }}>
        <b>{count}</b>
      </Typography>
    </div>
  );
}

export default StarRatingIcon;
