import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import numbro from "numbro";

//Material-UI Core Components
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";

//Material-UI Icons
import RateReviewIcon from "@material-ui/icons/RateReview";

//Custom Components
import withFormController from "Components/withFormController";
import { CardActionButtonClose, CardHeading, CardToolbarButtonBack } from "UI/Card";
import { FormFillContent, FormHeading, FormHeadingID, FormTab, FormTabs } from "UI/Form";
import FormContent from "Components/FormContent";
import { GridContainer } from "UI/Grid";
import DialogActionButton from "UI/Dialog/DialogActionButton";
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import upitModel from "Models/upiti";
import dataController from "Lib/dataController";
import UpitZaPosebnuPonuduDialog from "Views/Agencije/PosebnePonude/UpitZaPosebnuPonuduDialog";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import PosebnaPonudaAvatar from "UI/Form/Avatars/PosebnaPonudaAvatar";
import { posebnaPonudaStatuses } from "Lib/statuses";
import EuroConversionComment from "Components/EuroConversionComment";

function PosebnaPonudaTransferFormPublic(props) {
  const { t } = useTranslation();
  const history = useHistory();

  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);

  const [currentTab, setCurrentTab] = useState(1);

  const { record, mode, validation, fields, subModels, dc } = props; //HOC withFormController
  const { doClose } = props; //HOC withFormController
  const { onFieldChange } = props; //HOC withFormController

  const upitDC = new dataController(upitModel, `posebne-ponude/${record.id}/upiti`);

  record.round_trip = false;

  const handleClickBack = () => {
    history.goBack();
  };

  const handleTabChange = (evt, value) => {
    setCurrentTab(value);
  };


  const openUpitDialog = () => {
    dialogContext.showDialog(UpitZaPosebnuPonuduDialog, {
      dc: upitDC,
      mode: "insert",
      form: "posebnaPonuda",
      posebnaPonudaId: record.id,
      onClose: handleClose,
      defaultValues: {
        prijevoznik_naziv: record.bs_owner_id,
        from_loc: record.from_loc,
        to_loc: record.to_loc,
        from_ts: record.from_ts,
        to_ts: record.to_ts,
        vozilo_naziv: record.vozilo_naziv,
        vozilo_id: record.vozilo_id,
        vozilo_tip_id: record.vozilo_tip_id,
        vozilo_klasa_id: record.vozilo_klasa_id,
        klasa_motora_id: record.klasa_motora_id,
        vozilo_oprema_ids: record.vozilo_oprema_ids,
        vozilo_broj_sjedala: record.vozilo_broj_putnika,
        prijevoznik_ocjena: record.prijevoznik_ocjena
      },
      subModelDefaultValues: {
        prijevoznik_id: record.bs_owner_id
      }
    });
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const close = (result) => {
    if (result.dataChanged) {
      onClose({ dataChanged: true, action: result.action });
    }

    dialogContext.hideDialog();
  };

  const commonProps = {
    currentTab: currentTab,
    record: record,
    mode: mode,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    subModels: subModels
  };

  const subtitle = `(ID:${record.id})`;

  const recordStatus = record ? record.posebna_ponuda_state_id : 11;

  const isActive = record ? record.active : false;

  return (
    <Card>
      <CardHeading recordStatus={recordStatus} statuses={posebnaPonudaStatuses}>
        <Toolbar variant="dense" disableGutters={true}>
          <PosebnaPonudaAvatar />
          <FormHeading header={t("titles.posebna_ponuda")} subheaderContent={<FormHeadingID id={record.id} />} />
          <FormFillContent />
          <CardToolbarButtonBack onClick={handleClickBack} />
        </Toolbar>
      </CardHeading>
      <CardContent style={{ padding: 0 }}>
      <Box m={2}>
          <GridContainer>
            <FormContent
              {...commonProps}
              title={t("posebna_ponuda.sections.osnovno")}
              fieldNames={["price_per_distance", "vozilo_naziv", "rok_potvrda", "opis"]}
              columns={2}
            />
            <FormContent
              {...commonProps}
              title={t("posebna_ponuda.sections.termin")}
              fieldNames={["from_ts", "to_ts"]}
              columns={3}
            />
            <FormContent
              {...commonProps}
              title={t("posebna_ponuda.sections.location")}
              fieldNames={["from_loc", "from_loc_radius", "to_loc", "to_loc_radius"]}
              columns={2}
            />
          </GridContainer>
        </Box>
      </CardContent>
      <CardActions>
        <EuroConversionComment />
        <FormFillContent />
        <CardActionButtonClose variant="outlined" onClick={handleClickBack} />
        <DialogActionButton variant="contained" onClick={openUpitDialog} startIcon={<RateReviewIcon />} disabled={!isActive}>
          {t("buttons.upit_create")}
        </DialogActionButton>
      </CardActions>
    </Card>
  );
}

export default withFormController(PosebnaPonudaTransferFormPublic);
