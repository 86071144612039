import React, { Fragment } from 'react';

// Material-UI Icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import TodayIcon from '@material-ui/icons/Today';

// Custom Components
import ToolbarButton from "UI/Toolbar/ToolbarButton";
import ToolbarFillContent from "UI/Toolbar/ToolbarFillContent";


function SchedulerToolbarNavigation(props) {
	const { manualStartChange, setStartToday } = props;

	return (
    <ToolbarFillContent>
      <ToolbarButton color="secondary" onClick={() => manualStartChange(-1, 1)} >
        <FirstPageIcon />
      </ToolbarButton>
      <ToolbarButton color="secondary" onClick={() => manualStartChange(-1, 0)} >
        <ArrowBackIcon />
      </ToolbarButton>
      <ToolbarButton color="secondary" onClick={() => setStartToday()} >
        <TodayIcon />
      </ToolbarButton>
      <ToolbarButton color="secondary" onClick={() => manualStartChange(1, 0)} >
        <ArrowForwardIcon />
      </ToolbarButton>
      <ToolbarButton color="secondary" onClick={() => manualStartChange(1, 1)} >
        <LastPageIcon />
      </ToolbarButton>
    </ToolbarFillContent>
	);
}

export default SchedulerToolbarNavigation;