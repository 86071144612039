import React from "react";

//Custom Components
import PrijevozniciPage from "UI/AppPage/PrijevozniciPage";
import ResponsiveBox from "UI/Box/ResponsiveBox";
import PredlosciCjenikaTable from "Views/Prijevoznici/PredlosciCjenika/PredlosciCjenikaTable";

const PrijevozniciPredlosciCjenikaPage = React.memo((props) => {
  return (
    <PrijevozniciPage>
      <ResponsiveBox fillHeight>
        <PredlosciCjenikaTable />
      </ResponsiveBox>
    </PrijevozniciPage>
  );
});

export default PrijevozniciPredlosciCjenikaPage;
