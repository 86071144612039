import React from "react";
import { useParams, useHistory } from "react-router-dom";

//Custom components
import AdminPage from "UI/AppPage/AdminPage";
import dataController from "Lib/dataController";
import model from "Models/user";
import UserForm from "Views/Administration/UsersPage/UserForm";
import Box from "@material-ui/core/Box";

const UserView = React.memo((props) => {
  const history = useHistory();
  const { id } = useParams();

  const recordId = parseInt(id, 10);

  const dc = new dataController(model);

  const handleClose = () => {
    history.goBack();
  };

  return (
    <AdminPage>
      <Box m={2}>
        <UserForm dc={dc} recordId={recordId} form="update" mode="update" onClose={handleClose} />
      </Box>
    </AdminPage>
  );
})

export default UserView;
