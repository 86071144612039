import moment from "moment";
import { sifrarnici } from "Lib/sifrarnici";


class appValidator {

  constructor(t) {
    this.t = t;

    this.validateModel = this.validateModel.bind(this);
    this.validateField = this.validateField.bind(this);
    this.getValue = this.getValue.bind(this);
    this.mergeValidation = this.mergeValidation.bind(this);
    this.checkIfValid = this.checkIfValid.bind(this);
    this.checkIfRecordChanged = this.checkIfRecordChanged.bind(this);
    this.checkIfFieldChanged = this.checkIfFieldChanged.bind(this);

    //Custom validators
    this.checkRegexEmail = this.checkRegexEmail.bind(this);
    this.checkRegexTel = this.checkRegexTel.bind(this);
    this.checkRegexOIB = this.checkRegexOIB.bind(this);
    this.isComplexPassword = this.isComplexPassword.bind(this);
    this.validatePasswordReset = this.validatePasswordReset.bind(this);
    this.validatePasswordChange = this.validatePasswordChange.bind(this);
    this.validatePasswordSet = this.validatePasswordSet.bind(this);
    this.voziloRequired = this.voziloRequired.bind(this);
    this.upitRequired = this.upitRequired.bind(this);
    this.validateRezervacija = this.validateRezervacija.bind(this);
    this.minimumRequiredCjenikFields = this.minimumRequiredCjenikFields.bind(this);
    this.validateAlarm = this.validateAlarm.bind(this);
    this.trazilicaDateConstraint = this.trazilicaDateConstraint.bind(this);
    this.pdvStopaManualRequired = this.pdvStopaManualRequired.bind(this);

    //Validation messages
    this.msgRequired = this.msgRequired.bind(this);
    this.departureAfterArrival = this.departureAfterArrival.bind(this);
    this.bidAfterDeparture = this.bidAfterDeparture.bind(this);
    this.subSectionRequired = this.subSectionRequired.bind(this);
    this.existingUsername = this.existingUsername.bind(this);
  }

  validateModel = function (record, fields) {
    const validation = {};
    fields.forEach((field) => {
      validation[field.source] = this.validateField(record, field);
    });
    console.log(validation);
    return validation;
  };

  validateField = function (record, field) {
    const fieldValidation = {valid: true, msg: ""};
    const isRequired = field.validation && field.validation.required;
    const isEmail =
        field.validation &&
        field.validation.hasOwnProperty("regex") &&
        field.validation.regex === "email";
    const isTelefon =
        field.validation &&
        field.validation.hasOwnProperty("regex") &&
        field.validation.regex === "tel";
    const isOIB =
        field.validation &&
        field.validation.hasOwnProperty("regex") &&
        field.validation.regex === "oib";
    const isDate =
        field.type === "date" &&
        field.validation &&
        field.validation.hasOwnProperty("maxDate");
    const isDateAfter =
        (field.type === "date" || field.type === "datetime") &&
        field.validation &&
        field.validation.hasOwnProperty("isAfter");
    const hasCharLimit =
        field.validation && field.validation.hasOwnProperty("maxLength");
    const maxLength = hasCharLimit ? field.validation.maxLength : null;
    const hasMinCharsRequired =
        field.validation && field.validation.hasOwnProperty("minLength");
    const value =
        record && record.hasOwnProperty(field.source)
            ? record[field.source]
            : null;
    const minLength = hasMinCharsRequired ? field.validation.minLength : null;
    let hasValue = value !== undefined && value !== null && value !== "";
    if (Array.isArray(value) && value.length === 0) {
      hasValue = false;
    }

    const hasMinValue = field.validation && field.validation.hasOwnProperty("minValue");
    const minValue = hasMinValue ? field.validation.minValue : null;
    const hasMaxValue = field.validation && field.validation.hasOwnProperty("maxValue");
    const maxValue = hasMaxValue ? field.validation.maxValue : null;

    if (isRequired && !hasValue) {
      fieldValidation.valid = false;
      fieldValidation.msg = this.t("validation.required");
    } else if (isEmail && hasValue && this.checkRegexEmail(value) === false) {
      fieldValidation.valid = false;
      fieldValidation.msg = this.t("validation.emailformat");
    } else if (isTelefon && hasValue && this.checkRegexTel(value) === false) {
      fieldValidation.valid = false;
      fieldValidation.msg = this.t("validation.telformat");
    } else if (isOIB && hasValue && this.checkRegexOIB(value) === false) {
      fieldValidation.valid = false;
      fieldValidation.msg = this.t("validation.oibformat");
    } else if (hasCharLimit && hasValue && value.length > maxLength) {
      fieldValidation.valid = false;
      fieldValidation.msg = this.t("validation.max_char", {max_char: maxLength.toString(), curr_char: value.length});
    } else if (hasMinCharsRequired && hasValue && value.length < minLength) {
      fieldValidation.valid = false;
      fieldValidation.msg = this.t("validation.min_char", {min_char: minLength.toString(), curr_char: value.length});
    } else if ((hasMinValue || hasMaxValue) && hasValue) {
      const floatValue = typeof value === "string" ? parseFloat(value.replace(',', '.')) : value;
      if (hasMinValue && floatValue < minValue) {
        fieldValidation.valid = false;
        fieldValidation.msg = this.t("validation.min_value", {min_value: minValue.toString()});
      }
      if (hasMaxValue && floatValue > maxValue) {
        fieldValidation.valid = false;
        fieldValidation.msg = this.t("validation.max_value", {max_value: maxValue.toString()});
      }
    } else if (isDate && moment(value).isAfter(moment(new Date()).add(field.validation.maxDate, 'days'), 'day')) {
      fieldValidation.valid = false;
      fieldValidation.msg = this.t("validation.max_date", {date: moment(new Date()).add(field.validation.maxDate, 'days').format("DD.MM.YYYY.")});
    } else if (isDateAfter && hasValue) {
      const otherFieldId = field.validation.isAfter;
      const otherValue = record[otherFieldId];
      const otherHasValue = otherValue !== undefined && otherValue !== null && otherValue !== ""
      const oherFieldName = this.t(field.validation.isAfterToken)
      if (otherHasValue) {
        if (!moment(value).isAfter(moment(otherValue))) {
          fieldValidation.valid = false;
          fieldValidation.msg = this.t("validation.isafter_date", {field: oherFieldName});
        }
      }

    }

    return fieldValidation;
  };

  mergeValidation = function (
      modelValidation,
      customValidation
  ) {
    let mergedValidation = modelValidation || {};
    if (customValidation !== undefined && customValidation !== null) {
      Object.keys(customValidation).forEach(key => {
        if (mergedValidation.hasOwnProperty(key)) {
          mergedValidation[key] = customValidation[key];
        } else {
          mergedValidation.push(key, customValidation[key]);
        }
      });
    }
    return mergedValidation;
  };

  checkIfValid = function (validation) {
    const validatonFailed = Object.keys(validation)
        .map(key => validation[key].valid)
        .some(x => x === false);
    const isValid = !validatonFailed;
    return isValid;
  };

  checkIfRecordChanged = function (record, originalRecord) {
    const isChanged = Object.keys(record).some(key =>
        this.checkIfFieldChanged(record[key], originalRecord[key])
    );
    return isChanged;
  };

  checkIfFieldChanged = function (a, b) {
    if (a === null && b === null) {
      return false;
    } else if (moment.isMoment(a) && moment.isMoment(b)) {
      if (!a.isValid() && !b.isValid()) {
        return false;
      } else {
        return !a.isSame(b);
      }
    } else {
      return this.getValue(a) !== this.getValue(b);
    }
  };

  getValue = function (x) {
    if (x === null || x === undefined) {
      return null;
    } else if (moment.isMoment(x)) {
      return x.isValid() ? x : null;
    } else if (x.value && typeof x.value === "function") {
      return x.value(); //numbro
    } else if (x.value) {
      return x.value; //option
    } else {
      return x; //other
    }
  };

  returnFormValue = function (record, key) {
    return record && record.hasOwnProperty(key) && record[key] !== null && record[key] !== undefined ?
        (record[key].value || record[key]) : null;
  }

//Validation messages
  msgRequired = function () {
    return {
      valid: false,
      msg: "validation.required"
    };
  }

  subSectionRequired = function () {
    return {
      valid: false,
      msg: "validation.subSection_required"
    };
  }

  bidAfterDeparture = function () {
    return {
      valid: false,
      msg: "validation.bidAfterDeparture"
    };
  }

  departureAfterArrival = function () {
    return {
      valid: false,
      msg: "validation.departureAfterArrival"
    };
  }

  existingUsername = function () {
    return {
      valid: false,
      msg: "validation.existing_username"
    }
  }

//Custom Validators

  checkRegexEmail = function (value) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const matches = value.match(re);
    return matches != null ? true : false;
  };

  checkRegexTel = function (value) {
    const re = /^\+[0-9]{11,15}$/;
    const matches = value.match(re);
    return matches != null ? true : false;
  };

  checkRegexOIB = function (value) {
    const re = /^[0-9]{11}$/;
    const matches = value.match(re);
    return matches != null ? true : false;
  };

  isComplexPassword = function (password) {
    if (password.length < 8) {
      return false;
    }

    var matchedCase = new Array();
    matchedCase.push("[A-Z]"); // Uppercase Letters
    matchedCase.push("[0-9]"); // Numbers
    matchedCase.push("[a-z]"); // Lowercase Letters

    var ctr = 0;
    for (var i = 0; i < matchedCase.length; i++) {
      if (new RegExp(matchedCase[i]).test(password)) {
        ctr++;
      }
    }

    return ctr == 3 ? true : false;
  };

  validatePasswordReset = function (record) {
    const fNewPass = "password_new";
    const fNewPassConfirm = "password_new_confirm";

    const newPass =
        record && record.hasOwnProperty(fNewPass) ? record[fNewPass] : null;
    const newPassConfirm =
        record && record.hasOwnProperty(fNewPassConfirm)
            ? record[fNewPassConfirm]
            : null;

    let validation = {};

    if (newPass && newPass.length > 0 && this.isComplexPassword(newPass) === false) {
      validation[fNewPass] = {
        valid: false,
        msg: "validation.password_complexity"
      };
    }

    if (newPass !== newPassConfirm) {
      validation[fNewPassConfirm] = {
        valid: false,
        msg: "validation.password_confirm_mismatch"
      };
    }

    return validation;
  };

  validatePasswordChange = function (record) {
    const fOldPass = "password";
    const fNewPass = "password_new";
    const fNewPassConfirm = "password_new_confirm";

    const oldPass =
        record && record.hasOwnProperty(fOldPass) ? record[fOldPass] : null;
    const newPass =
        record && record.hasOwnProperty(fNewPass) ? record[fNewPass] : null;
    const newPassConfirm =
        record && record.hasOwnProperty(fNewPassConfirm)
            ? record[fNewPassConfirm]
            : null;

    let validation = {};
    if (newPass && newPass.length > 0 && this.isComplexPassword(newPass) === false) {
      validation[fNewPass] = {
        valid: false,
        msg: "validation.password_complexity"
      };
    }

    if (newPass && newPass.length > 0 && oldPass === newPass) {
      validation[fNewPass] = {
        valid: false,
        msg: "validation.password_equal"
      };
    }

    if (newPass !== newPassConfirm) {
      validation[fNewPassConfirm] = {
        valid: false,
        msg: "validation.password_confirm_mismatch"
      };
    }

    return validation;
  };

  validatePasswordSet = function (record, t) {
    const fPass = "password";
    const fPassConfirm = "password_confirm";

    const pass =
        record && record.hasOwnProperty(fPass) ? record[fPass] : null;
    const passConfirm =
        record && record.hasOwnProperty(fPassConfirm)
            ? record[fPassConfirm]
            : null;

    let validation = {};

    if (pass && pass.length > 0 && this.isComplexPassword(pass) === false) {
      validation[fPass] = {
        valid: false,
        msg: "validation.password_complexity"
      };
    }

    if (pass !== passConfirm) {
      validation[fPassConfirm] = {
        valid: false,
        msg: "validation.password_confirm_mismatch"
      };
    }

    return validation;
  };

  voziloRequired = function (record) {
    const fVoziloPicker = "vozilo_id";
    const fVoziloOpis = "vozilo_opis";

    const voziloPicker = record && record.hasOwnProperty(fVoziloPicker) ? record[fVoziloPicker] : null;
    const voziloOpis = record && record.hasOwnProperty(fVoziloOpis) ? record[fVoziloOpis] : null;

    let validation = {};

    if (voziloPicker === null && voziloOpis === null) {
      validation[fVoziloPicker] = {
        valid: false,
        msg: "Jedno od polja 'Vozilo' i 'Vozilo Opis' mora biti popunjeno"
      };
      validation[fVoziloOpis] = {
        valid: false,
        msg: "Jedno od polja 'Vozilo' i 'Vozilo Opis' mora biti popunjeno"
      }
    }
    return validation;
  }

  upitRequired = function (record) {
    const fBurzaVisible = "burza_visible";
    const fPrijevoznik = "prijevoznici_direkt_ids";
    const fPrijevoznikHelper = "prijevoznici_direkt_ids_helper";
    const fAskAgentura = "ask_agentura"

    const fFromTs = "from_ts";
    const fToTs = "to_ts";
    const fRokPonude = "rok_za_ponude";

    const burzaVisibleSet = record && record.hasOwnProperty(fBurzaVisible) ? record[fBurzaVisible] : false;
    const prijevoznikIds = record && record.hasOwnProperty(fPrijevoznik) ? record[fPrijevoznik] : null;
    const askAgenturaSet = record && record.hasOwnProperty(fAskAgentura) ? record[fAskAgentura] : false;

    const fromTs = moment(this.returnFormValue(record, fFromTs));
    const toTs = moment(this.returnFormValue(record, fToTs));
    const rokPonude = moment(this.returnFormValue(record, fRokPonude));

    const selectedPrijevozniciCount = Array.isArray(prijevoznikIds) ? prijevoznikIds.length : 0;

    let validation = {};

    if (burzaVisibleSet === false && selectedPrijevozniciCount === 0 && askAgenturaSet === false) {
      validation[fBurzaVisible] = {
        valid: false,
        msg: "validation.burza_visible_or_agentura_or_select"
      };
      validation[fPrijevoznikHelper] = {
        valid: false,
        msg: "validation.burza_visible_or_agentura_or_select"
      };
      validation[fAskAgentura] = {
        valid: false,
        msg: "validation.burza_visible_or_agentura_or_select"
      }
    }

    if (rokPonude.isAfter(fromTs)) {
      validation[fFromTs] = this.bidAfterDeparture()
      validation[fRokPonude] = this.bidAfterDeparture()
    }

    if (fromTs.isAfter(toTs)) {
      validation[fFromTs] = this.departureAfterArrival()
      validation[fToTs] = this.departureAfterArrival()
    }

    return validation;
  }

  validateRezervacija = function (record) {
    const fTipId = "tip_id";
    const fVoznjaId = "voznja_id";
    const fVoziloId = "vozilo_id";
    const fVozacId = "vozac_id";

    const tipId = record && record.hasOwnProperty(fTipId) && record[fTipId] !== null && record[fTipId] !== undefined ?
        (record[fTipId].value || record[fTipId]) : null;
    const voznjaId = record && record.hasOwnProperty(fVoznjaId) && record[fVoznjaId] !== null && record[fVoznjaId] !== undefined ?
        (record[fVoznjaId].value || record[fVoznjaId]) : null;
    const voziloId = record && record.hasOwnProperty(fVoziloId) && record[fVoziloId] !== null && record[fVoziloId] !== undefined ?
        (record[fVoziloId].value || record[fVoziloId]) : null;
    const vozacId = record && record.hasOwnProperty(fVozacId) && record[fVozacId] !== null && record[fVozacId] !== undefined ?
        (record[fVozacId].value || record[fVozacId]) : null;

    const validation = {};

    if (tipId !== null && tipId !== 0) {
      const tipIdGroup = Math.floor(tipId / 10);
      if (tipIdGroup === sifrarnici.rezervacija_picker[fVoznjaId] && voznjaId === null) {
        validation[fVoznjaId] = this.msgRequired();
      } else if (tipIdGroup === sifrarnici.rezervacija_picker[fVoziloId] && voziloId === null) {
        validation[fVoziloId] = this.msgRequired();
      } else if (tipIdGroup === sifrarnici.rezervacija_picker[fVozacId] && vozacId === null) {
        validation[fVozacId] = this.msgRequired();
      }
    }

    return validation;
  }

  minimumRequiredCjenikFields = function (record) {
    const fShortTermLease_price = "price_for_transfer";
    const fShortTermLease_distance = "distance_for_transfer";
    const fShortTermLease_duration = "duration_for_transfer";

    const fHalfDayLease_price = "price_for_half_day";
    const fHalfDayLease_distance = "distance_for_half_day";
    const fHalfDayLease_duration = "duration_for_half_day";

    const shortTerm_price = this.returnFormValue(record, fShortTermLease_price);
    const shortTerm_distance = this.returnFormValue(record, fShortTermLease_distance);
    const shortTerm_duration = this.returnFormValue(record, fShortTermLease_duration);

    const halfDay_price = this.returnFormValue(record, fHalfDayLease_price);
    const halfDay_distance = this.returnFormValue(record, fHalfDayLease_distance);
    const halfDay_duration = this.returnFormValue(record, fHalfDayLease_duration);

    const validation = {};
    const shortTermVals = {
      price_for_transfer: shortTerm_price,
      distance_for_transfer: shortTerm_distance, duration_for_transfer: shortTerm_duration
    };
    const halfDayVals = {
      price_for_half_day: halfDay_price,
      distance_for_half_day: halfDay_distance, duration_for_half_day: halfDay_duration
    };

    shortTerm_price !== null || shortTerm_distance !== null || shortTerm_duration !== null ?
        Object.keys(shortTermVals).forEach((key) => {
          if (shortTermVals[key] === null) {
            validation[key] = this.subSectionRequired();
          }
        }) : null;

    halfDay_price !== null || halfDay_distance !== null || halfDay_duration !== null ?
        Object.keys(halfDayVals).forEach((key) => {
          if (halfDayVals[key] === null) {
            validation[key] = this.subSectionRequired();
          }
        }) : null;

    return validation;
  }

  validateAlarm = function (record) {

    const fTs = "alarm_ts";
    const fActive = "alarm_active";

    const ts = this.returnFormValue(record, fTs);
    const active = this.returnFormValue(record, fActive);

    const validation = {};

    if (active && ts === null) {
      validation[fTs] = this.msgRequired()
    }

    return validation;
  }

  trazilicaDateConstraint = function (record) {

    const fFromTs = "from_ts";
    const fToTs = "to_ts";
    const fRokPonude = "rok_za_ponude";

    const fromTs = moment(this.returnFormValue(record, fFromTs));
    const toTs = moment(this.returnFormValue(record, fToTs));
    const rokPonude = moment(this.returnFormValue(record, fRokPonude));

    const validation = {};

    if (rokPonude.isAfter(fromTs)) {
      validation[fFromTs] = this.bidAfterDeparture()
      validation[fRokPonude] = this.bidAfterDeparture()
    }

    if (fromTs.isAfter(toTs)) {
      validation[fFromTs] = this.departureAfterArrival()
      validation[fToTs] = this.departureAfterArrival()
    }

    return validation;
  }

  pdvStopaManualRequired = function (record) {
    const fPdvStopa = "pdv_stopa";
    const fPdvStopaManual = "pdv_stopa_manual";

    const pdv_stopa = this.returnFormValue(record, fPdvStopa);
    const pdv_stopa_manual = this.returnFormValue(record, fPdvStopaManual);

    let validation = {};

    if (pdv_stopa === null && pdv_stopa_manual === null) {
      validation[fPdvStopa] = {
        valid: false,
        msg: "notifications.pdv_stopa_manual_required"
      };
      validation[fPdvStopaManual] = {
        valid: false,
        msg: "notifications.pdv_stopa_manual_required"
      }
    }

    if(pdv_stopa !== null && pdv_stopa_manual !== null) {
      validation[fPdvStopa] = {
        valid: false,
        msg: "notifications.pdv_stopa_manual_one"
      };
      validation[fPdvStopaManual] = {
        valid: false,
        msg: "notifications.pdv_stopa_manual_one"
      }
    }
    return validation;
  }

}

export default appValidator;
