import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Icons
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

//Custom components
import CardToolbarMenuItem from "UI/Card/CardToolbarMenuItem";
import { Permissions } from "Lib/permissions";

const CardToolbarMenuItemClaimMediation = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return (
    <CardToolbarMenuItem
      label={t("buttons.mediation_claim")}
      icon={<AccountCircleIcon />}
      {...props}
      ref={ref}
      needPermission={[Permissions.AdminMediationOwn]}
    />
  );
});

export default CardToolbarMenuItemClaimMediation;
