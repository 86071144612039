import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//Custom components
import withRedirect from "Components/withRedirect";
import ModelTable from "UI/Table/ModelTable";
import model from "Models/fakture";
import dataController from "Lib/dataController";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import DialogContext from "UI/DialogContext/DialogContext";
import UserContext from "Components/UserContext/UserContext";
import FakturaDialog from "Views/Shared/FakturaDialog";
import { fakturaPrint } from "Lib/print/fakturaPrint";

function AdminFinPausalFakture(props) {
  const { t } = useTranslation();
  const snackbarContext = useContext(SnackbarContext);
  const loaderContext = useContext(LoaderContext);
  const dialogContext = useContext(DialogContext);
  const userContext = useContext(UserContext);

  const [records, setRecords] = useState([]);

  const { currentTab, recordId } = props;
  const { redirect } = props; //HOC withRedirect

  const path = "business-subjects/" + recordId + "/pausal-fakture";
  const dc = new dataController(model, path);

  const handleAdd = () => {
    dialogContext.showDialog(FakturaDialog, {
      dc: dc,
      mode: "insert",
      form: "pausal",
      onClose: handleDialogClose,
      title: t("titles.faktura_pausal"),
      defaultValues: {
        izdavatelj_bs_id: userContext.platformBSId,
        kupac_bs_id: recordId
      }
    });
  }

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords();
    }
    dialogContext.hideDialog();
  };

  const handleRowAction = function(action, id) {
    if (action === "view") {
      dialogContext.showDialog(FakturaDialog, {
        dc: dc,
        mode: "view",
        form: "pausal",
        recordId: id,
        onClose: handleDialogClose,
        stavkeApiPath: `business-subjects/${recordId}/pausal-fakture/${id}/stavke`,
        title: t("titles.faktura_pausal")
      });
    } else if (action === "print") {
      const rec = {
        id: id,
        bs_id: recordId
      };
      fakturaPrint.handlePausalPrint(rec, t);
    }
  }

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData(path)
      .then((resp) => {
        if (resp && resp.data && resp.success) {
          setRecords(resp.data);
        }
      })
      .catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  useEffect(() => {
    refreshRecords();
  }, []);

  return (
    <ModelTable
      records={records}
      dc={dc}
      viewName="pausal"
      allowExport={false}
      allowFilter={false}
      allowAdd={true}
      addLabel={t("buttons.new_f")}
      handleAdd={handleAdd}
      allowSelection="none"
      onRowAction={handleRowAction}
      subModelDefaultValues={{
        posao_id: recordId
      }}
    >
      {/* <TableButtonEdit onClick={handleEdit} /> */}
    </ModelTable>
  );
}

export default withRedirect(AdminFinPausalFakture);
