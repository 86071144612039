import api from "Lib/api";

export const vozilaService = {
  createImage,
  getFullImage,
  getImages,
  deleteImage,

  getCjenici,
  getCjenik,
  updateCjenik,
  deleteCjenik,
  createCjenik,

  getAlokacijeCijenka,
  getAlokacijeCjenik,
  createAllocatedCjenik,
  updateAllocatedCijenik,
  deleteAllocatedCijenik
}

const vozilaUrl = "vozila/";

function getCjenici(vozilaId){
  const apiInstance = new api();
  const path = vozilaUrl + vozilaId + "/cjenici";
  return apiInstance.Call(path, "get");
}

function getCjenik(vozilaId, cjenikId){
  const apiInstance = new api();
  const path = vozilaUrl + vozilaId + "/cjenici/" + cjenikId;
  return apiInstance.Call(path, "get");
}

function updateCjenik(vozilaId, cjenikId, data){
  const apiInstance = new api();
  const path = vozilaUrl + vozilaId + "/cjenici/" + cjenikId;
  return apiInstance.Call(path, "put", data);
}

function deleteCjenik(vozilaId, cjenikId){
  const apiInstance = new api();
  const path = vozilaUrl + vozilaId + "/cjenici/" + cjenikId;
  return apiInstance.Call(path, "delete");
}

function createCjenik(vozilaId, data){
  const apiInstance = new api();
  const path = vozilaUrl + vozilaId + "/cjenici";
  return apiInstance.Call(path, "post", data);
}

function getAlokacijeCijenka(vozilaId){
  const apiInstance = new api();
  const path = vozilaUrl + vozilaId + "/alokacije-cjenika";
  return apiInstance.Call(path, "get");
}

function getAlokacijeCjenik(vozilaId, alokacijaId){
  const apiInstance = new api();
  const path = vozilaUrl + vozilaId + "/alokacije-cjenika/" + alokacijaId;
  return apiInstance.Call(path, "get");
}

function createAllocatedCjenik(vozilaId, data){
  const apiInstance = new api();
  const path = vozilaUrl + vozilaId + "/alokacije-cjenika";
  return apiInstance.Call(path, "post", data);
}

function updateAllocatedCijenik(vozilaId, alokacijaId, data){
  const apiInstance = new api();
  const path = vozilaUrl + vozilaId + "/alokacije-cjenika/" + alokacijaId;
  return apiInstance.Call(path, "put", data);
}

function deleteAllocatedCijenik(vozilaId, alokacijaId){
  const apiInstance = new api();
  const path = vozilaUrl + vozilaId + "/alokacije-cjenika/" + alokacijaId;
  return apiInstance.Call(path, "delete");
}

function createImage(vozilaId, data) {
  const apiInstance = new api();
  const path = vozilaUrl + vozilaId + "/images"
  return apiInstance.Call(path, "post", data);
}

function getImages(vozilaId){
  const apiInstance = new api();
  const path = vozilaUrl + vozilaId + "/images"
  return apiInstance.Call(path, "get");
}

function getFullImage(vozilaId, uuid){
  const apiInstance = new api();
  const path = vozilaUrl + vozilaId + "/images/" + uuid;
  return apiInstance.Call(path, "get");
}

function deleteImage(vozilaId, uuid) {
  const apiInstance = new api();
  const path = vozilaUrl + vozilaId + "/images/" + uuid;
  return apiInstance.Call(path, "delete");
}
