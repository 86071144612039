import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function VoznjaIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M 14.5 1 L 14.5 2 L 14 2 C 13.45 2 13 2.45 13 3 L 13 11 C 13 11.55 13.45 12 14 12 L 22 12 C 22.55 12 23 11.55 23 11 L 23 3 C 23 2.45 22.55 2 22 2 L 21.5 2 L 21.5 1 L 20.5 1 L 20.5 2 L 15.5 2 L 15.5 1 L 14.5 1 z M 12 2 C 7.58 2 4 2.5 4 6 L 4 16 C 4 16.88 4.39 17.670703 5 18.220703 L 5 20 C 5 20.55 5.45 21 6 21 L 7 21 C 7.55 21 8 20.55 8 20 L 8 19 L 16 19 L 16 20 C 16 20.55 16.45 21 17 21 L 18 21 C 18.55 21 19 20.55 19 20 L 19 18.220703 C 19.61 17.670703 20 16.88 20 16 L 20 13 L 16 13 L 13 13 C 12 13 12 12 12 12 L 12 11 L 6 11 L 6 6 L 12 6 L 12 2 z M 14 4.5 L 22 4.5 L 22 11 L 14 11 L 14 4.5 z M 7.5 14 C 8.33 14 9 14.67 9 15.5 C 9 16.33 8.33 17 7.5 17 C 6.67 17 6 16.33 6 15.5 C 6 14.67 6.67 14 7.5 14 z M 16.5 14 C 17.33 14 18 14.67 18 15.5 C 18 16.33 17.33 17 16.5 17 C 15.67 17 15 16.33 15 15.5 C 15 14.67 15.67 14 16.5 14 z"/>
    </SvgIcon>
  );
}

export default VoznjaIcon;
