import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import numbro from "numbro";

//Material-UI Core components
import useTheme from "@material-ui/core/styles/useTheme";
import Toolbar from "@material-ui/core/Toolbar";
import DialogActions from "@material-ui/core/DialogActions";
import StarsIcon from "@material-ui/icons/Stars";
import SendIcon from "@material-ui/icons/Send";
import MoneyIcon from "@material-ui/icons/Money";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

//Custom Components
import withFormController from "Components/withFormController";
import DynamicDialog from "UI/Dialog/DynamicDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarCreationDetails from "UI/Dialog/DialogToolbarCreationDetails";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import { FormTab, FormTabs } from "UI/Form";
import DialogActionButton from "UI/Dialog/DialogActionButton";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import { googleService } from "Services/googleService";
import TabPanelFinancijskaKalkulacijaPPTransfer from "Views/Prijevoznici/PosebnePonude/Tabs/TabPanelFinancijskaKalkulacijaPPTransfer";
import TabPanelDetalji from "Views/Prijevoznici/PosebnePonude/Tabs/TabPanelDetalji";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import EuroConversionComment from "Components/EuroConversionComment";

function PosebnaPonudaTransferDialog(props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const loaderContext = useContext(LoaderContext);

  const [currentTab, setCurrentTab] = useState(1);
  const [counter, setCounter] = useState(0);

  const { record, mode, validation, fields, subModels, dc } = props; //HOC withFormController
  const { doInsert, doClose, doValidate } = props; //HOC withFormController
  const { onFieldChange, onClose } = props; //HOC withFormController

  const fPricePerDistance = "price_per_distance";

  const fFromLoc = "from_loc";
  const fToLoc = "to_loc";
  const fPdvStopa = "pdv_stopa";
  const fPdvStopaManual = "pdv_stopa_manual";
  const fPdvIznos = "pdv_iznos";
  const fCijenaSaPDVIznos = "cijena_s_pdv_iznos";
  const fCijenaSaFinCalcIznos = "cijena_s_fincalc_iznos";

  const fCBCProvizijaIznos = "cbc_provizija_iznos";

  const fUkupnoBruto = "uk_bruto_hrk";
  const fIznosFincalc = "iznos_fincalc";
  const fUkupnoAkcijskaCijena = "akcijska_cijena_uk";

  const fTrajanjeVoznje = "trajanje_voznje";
  const fNetoUdaljenostVoznje = "neto_udaljenost_voznje";
  const fUdaljenostPovratka = "udaljenost_povratka_vozila";

  const fUkupnoBrutoManual = "uk_bruto_hrk_manual";

  record.round_trip = false;

  const handleTabChange = (evt, value) => {
    setCurrentTab(value);
  };

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      onFieldChange(value, source);

      switch (source) {
        case fFromLoc:
          calculateRouteInformation(value, record[fToLoc]).then((resp) => {
            calculateTotals(
              record[fPricePerDistance],
              record[fNetoUdaljenostVoznje],
              record[fPdvStopa],
              record[fPdvStopaManual],
              record[fIznosFincalc]
            );
          });
          break;
        case fToLoc:
          calculateRouteInformation(record[fFromLoc], value).then((resp) => {
            calculateTotals(
              record[fPricePerDistance],
              record[fNetoUdaljenostVoznje],
              record[fPdvStopa],
              record[fPdvStopaManual],
              record[fIznosFincalc]
            );
          });
          break;
        case fPricePerDistance:
          calculateTotals(
            value,
            record[fNetoUdaljenostVoznje],
            record[fPdvStopa],
            record[fPdvStopaManual],
            record[fIznosFincalc]
          );
          break;
        case fPdvStopa:
          onFieldChange(null, fPdvStopaManual);
          calculateTotals(
            record[fPricePerDistance],
            record[fNetoUdaljenostVoznje],
            value,
            record[fPdvStopaManual],
            record[fIznosFincalc]
          );
          break;
        case fPdvStopaManual:
          onFieldChange(null, fPdvStopa);
          calculateTotals(
            record[fPricePerDistance],
            record[fNetoUdaljenostVoznje],
            record[fPdvStopa],
            value,
            record[fIznosFincalc]
          );
          break;
        case fIznosFincalc:
          calculateTotals(
            record[fPricePerDistance],
            record[fNetoUdaljenostVoznje],
            record[fPdvStopa],
            record[fPdvStopaManual],
            value
          );
          break;
        case fUkupnoBrutoManual:
          calculateReverse(value);
          break;
      }
    }
  };

  const calculateTotals = (price_per_distance, neto_udaljenost, pdv_stopa, pdv_manual, vanjski_troskovi) => {
    //input values
    const akcijskaCijena = numbro(price_per_distance).value();
    const distance = neto_udaljenost ? Math.floor(neto_udaljenost.value / 1000) : null;

    const pdvStopa = pdv_stopa ? (pdv_stopa.hasOwnProperty("value") ? pdv_stopa.value : pdv_stopa) : null;
    const pdvManual = numbro(pdv_manual).value();
    const vanjskiTroskovi = numbro(vanjski_troskovi).value();

    //other constant values
    const cbcProvizijaPerc = record && record.cbc_provizija_perc ? record.cbc_provizija_perc : 0;

    //calculated values
    const akcijskaCijenaUkupno = akcijskaCijena * distance;
    const pdvIznos = pdvManual ? pdvManual : akcijskaCijenaUkupno * (pdvStopa / 100);
    const cijenaSPDV = akcijskaCijenaUkupno + pdvIznos;
    const cijenaSVanjskimTroskovima = cijenaSPDV + vanjskiTroskovi;

    const cbcProvizijaIznos = cbcProvizijaPerc === 0 ? 0 : cijenaSVanjskimTroskovima * (cbcProvizijaPerc / 100);
    const ukupnaCijena = cijenaSVanjskimTroskovima + cbcProvizijaIznos;

    //change
    onFieldChange(akcijskaCijenaUkupno, fUkupnoAkcijskaCijena);
    onFieldChange(pdvIznos, fPdvIznos);
    onFieldChange(cijenaSPDV, fCijenaSaPDVIznos);
    onFieldChange(cijenaSVanjskimTroskovima, fCijenaSaFinCalcIznos);

    onFieldChange(cbcProvizijaIznos, fCBCProvizijaIznos);
    onFieldChange(ukupnaCijena, fUkupnoBruto);
  };

  const calculateReverse = (ukBrutoManual) => {
    //input values
    const numUkBrutoManual = numbro(ukBrutoManual).value();

    //other constant values
    const cbcProvizijaPerc = record && record.cbc_provizija_perc ? record.cbc_provizija_perc : 0;
    const vanjskiTroskovi = record && record[fIznosFincalc] ? numbro(record[fIznosFincalc]).value() : 0;
    const pdvStopa = record && record[fPdvStopa] ? record[fPdvStopa].value : null;
    const pdvStopaManual = record && record[fPdvStopaManual] ? numbro(record[fPdvStopaManual]).value() : null;
    const distance = record && record[fNetoUdaljenostVoznje] ? record[fNetoUdaljenostVoznje].value / 1000 : null;

    //condition
    const minAllowed = (vanjskiTroskovi + (pdvStopaManual ? pdvStopaManual : 0)) * (1 + cbcProvizijaPerc / 100);

    if (ukBrutoManual > minAllowed) {
      //calculation
      let cijenaSVanjskimTroskovima = numUkBrutoManual / (1 + cbcProvizijaPerc / 100);
      let cbcProvizijaIznos = numUkBrutoManual - cijenaSVanjskimTroskovima;

      let cijenaSPDV = cijenaSVanjskimTroskovima - vanjskiTroskovi;
      if (cijenaSPDV < 0) {
        cijenaSPDV = 0;
      }

      let pdvIznos = pdvStopaManual ? pdvStopaManual : (cijenaSPDV / (1 + pdvStopa / 100)) * (pdvStopa / 100);
      pdvIznos = numbro(pdvIznos).value();

      let akcijskaCijenaUkupno = cijenaSPDV - pdvIznos;
      if (akcijskaCijenaUkupno < 0) {
        akcijskaCijenaUkupno = 0;
      }
      let akcijskaCijena = distance !== 0 ? akcijskaCijenaUkupno / distance : null;
      akcijskaCijena = numbro(akcijskaCijena).value();

      //output values
      onFieldChange(akcijskaCijena, fPricePerDistance);
      onFieldChange(akcijskaCijenaUkupno, fUkupnoAkcijskaCijena);
      onFieldChange(pdvIznos, fPdvIznos);
      onFieldChange(cijenaSPDV, fCijenaSaPDVIznos);
      onFieldChange(cijenaSVanjskimTroskovima, fCijenaSaFinCalcIznos);

      onFieldChange(cbcProvizijaIznos, fCBCProvizijaIznos);
      onFieldChange(numUkBrutoManual, fUkupnoBrutoManual);
    }
  };

  const calculateRouteInformation = (fromLoc, toLoc) => {
    if (fromLoc && toLoc) {
      loaderContext.toggleLoading(true);
      return googleService
        .route(fromLoc, toLoc, [], false)
        .then((resp) => {
          if (resp.success) {
            const duration = resp.data.duration;
            const length = resp.data.length;
            let returnLength = { value: 0, label: "0 km" };

            onFieldChange(duration, fTrajanjeVoznje);
            onFieldChange(length, fNetoUdaljenostVoznje);
            onFieldChange(returnLength, fUdaljenostPovratka);
          } else {
            snackbarContext.showNotification("notifications.action_fail", "error");
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          loaderContext.toggleLoading(false);
        });
    } else {
      onFieldChange(null, fTrajanjeVoznje);
      onFieldChange(null, fNetoUdaljenostVoznje);
      onFieldChange(null, fUdaljenostPovratka);
      return Promise.resolve();
    }
  };

  useEffect(() => {
    if (record[fFromLoc] && record[fToLoc] && counter === 0) {
      setCounter(counter + 1);
      calculateRouteInformation(record[fFromLoc], record[fToLoc]);
    }
  }, [record]);

  const handleInsert = (evt) => {
    if (doInsert) {
      if (doValidate) {
        const validated = doValidate();
        if (validated) {
          const preparedRecord = dc.prepareSendingData(record);
          dc.InsertRecord(preparedRecord, "posebne-ponude/transfer").then((result) => {
            if (result.success) {
              close({ dataChanged: true, action: "insert", id: result.id });
              snackbarContext.showNotification("insert", "success");
            }
          }).catch((resp) => {
            snackbarContext.showNotification(resp.error, "error");
          });
        }
      }
    }
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const close = (result) => {
    if (result.dataChanged) {
      onClose({ dataChanged: true, action: result.action, id: result.id });
    }

    dialogContext.hideDialog();
  };

  const handleContinue = (evt) => {
    setCurrentTab((prevState) => prevState + 1);
  };

  const commonProps = {
    currentTab: currentTab,
    record: record,
    mode: mode,
    validation: validation,
    onFieldChange: handleFieldChange,
    fields: fields,
    subModels: subModels
  };

  //condition
  const cbcProvizijaPerc = record && record.cbc_provizija_perc ? record.cbc_provizija_perc : 0;
  const vanjskiTroskovi = record && record[fIznosFincalc] ? numbro(record[fIznosFincalc]).value() : 0;
  const pdvStopaManual = record && record[fPdvStopaManual] ? numbro(record[fPdvStopaManual]).value() : null;
  const minAllowed = (vanjskiTroskovi + (pdvStopaManual ? pdvStopaManual : 0)) * (1 + cbcProvizijaPerc / 100);
  //TODO: add field validation if manual Uk Bruto < minAllowed

  return (
    <DynamicDialog onClose={handleClose}>
      <DialogHeader color={theme.palette.custom && theme.palette.custom.pp}>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogAvatar ariaLabel="posebna-ponuda" icon={<StarsIcon />} />
          <DialogToolbarHeading>{t("titles.posebna_ponuda_new_transfer")}</DialogToolbarHeading>
          <DialogToolbarFillContent />
          <DialogToolbarCreationDetails record={record} />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <FormTabs value={currentTab} onChange={handleTabChange}>
          <FormTab
            value={1}
            label={t("posebna_ponuda.tabs.detalji")}
            icon={<ViewModuleIcon />}
            validation={validation}
            fields={[
              "vozilo_id",
              "naziv",
              "rok_potvrda",
              "comment",
              "from_ts",
              "to_ts",
              "from_loc",
              "from_loc_radius",
              "to_loc",
              "to_loc_radius",
              "price_per_distance",
              "pdv_stopa",
              "pdv_stopa_manual"
            ]}
          />
          <FormTab value={2} label={t("posebna_ponuda.tabs.fin_calc")} icon={<MoneyIcon />} />
        </FormTabs>
        <TabPanelDetalji transfer={true} tabIndex={1} {...commonProps} />
        <TabPanelFinancijskaKalkulacijaPPTransfer tabIndex={2} {...commonProps} recordId={record.id} />
      </DialogBody>
      <DialogActions>
        <EuroConversionComment />
        <DialogToolbarFillContent />
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
        {currentTab === 2 ? (
          <DialogActionButton variant="contained" onClick={handleInsert} startIcon={<SendIcon />}>
            {t("buttons.publish")}
          </DialogActionButton>
        ) : (
          <DialogActionButton variant="contained" onClick={handleContinue} startIcon={<ArrowForwardIcon />}>
            {t("buttons.continue")}
          </DialogActionButton>
        )}
      </DialogActions>
    </DynamicDialog>
  );
}

export default withFormController(PosebnaPonudaTransferDialog);
