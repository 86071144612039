import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import numbro from "numbro";

//Material UI
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Typography } from "@material-ui/core";

//Custom components
import GridContainer from "UI/Grid/GridContainer";
import FormContent from "Components/FormContent";
import GridItem from "UI/Grid/GridItem";
import ModelTable from "UI/Table/ModelTable";
import DialogContext from "UI/DialogContext/DialogContext";
import FinancijskaKalkulacijaDialog from "Views/Prijevoznici/Voznje//VoznjaFormTabDialogs/FinancijskaKalkulacijaDialog";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import dataController from "Lib/dataController";
import model from "Models/fincalc";
import LoaderContext from "Components/LoaderContext/LoaderContext";
import { FormButton, FormTabPanel } from "UI/Form";

function CalculationView(props) {
  const { title, value } = props;

  return (
    <GridContainer direction="column" align="center" justify="flex-start" spacing={1}>
      <GridItem>
        <Typography variant="caption" align="left" display="inline">
          {title}
        </Typography>
      </GridItem>
      <GridItem>
        <Typography variant="caption" align="right" display="inline">
          <b>{value}</b>
        </Typography>
      </GridItem>
    </GridContainer>
  );
}

function TabPanelFinancijskaKalkulacijaPPTransfer(props) {
  const { t } = useTranslation();
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const loaderContext = useContext(LoaderContext);
  const [records, setRecords] = useState([]);

  const { recordId } = props;
  const { currentTab, tabIndex } = props;
  const { record, validation, fields, onFieldChange, mode, subModels } = props;

  const dc = new dataController(model, `posebne-ponude/${recordId}/financijske-kalkulacije`);

  const fFinCalcDirect = "fin_calc_direct";
  const fIznosFinCalc = "iznos_fincalc";
  const fIznosBruto = "iznos_bruto";
  const fEksterniTrosakHelper = "eksterni_trosak_helper";

  const fPriceForDistance = "price_per_distance";
  const fPdvStopa = "pdv_stopa";
  const fPdvStopaManual = "pdv_stopa_manual";
  const fPdvIznos = "pdv_iznos";
  const fCijenaSaPDVIznos = "cijena_s_pdv_iznos";
  const fCijenaSaFinCalcIznos = "cijena_s_fincalc_iznos";

  const fCBCProvizijaIznos = "cbc_provizija_iznos";
  const fCBCProvizija = "cbc_provizija_perc";

  const fUkupnoBruto = "uk_bruto_hrk";
  const fUkupnoBrutoManual = "uk_bruto_hrk_manual";
  const fIznosFincalc = "iznos_fincalc";
  const fUkupnoAkcijskaCijena = "akcijska_cijena_uk";

  const fNetoUdaljenostVoznje = "neto_udaljenost_voznje";

  const handleAdd = (evt) => {
    dialogContext.showDialog(
      FinancijskaKalkulacijaDialog,
      {
        dc: dc,
        mode: "insert",
        parentMode: mode,
        form: "popis_finCalc",
        onClose: handleDialogClose
      },
      2
    );
  };

  const openFinCalcDialog = (id) => {
    dialogContext.showDialog(
      FinancijskaKalkulacijaDialog,
      {
        dc: dc,
        mode: "update",
        parentMode: mode,
        form: "popis_finCalc",
        recordId: id,
        onClose: handleDialogClose
      },
      2
    );
  };

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      if (result.action === "insert") {
        if (result.record) {
          const length = records.length;
          const rec = result.record;

          const fIznosNeto = "iznos_neto";
          const fPdvStopa = "pdv_stopa";

          rec.id = length + 1;
          rec[fIznosNeto] = numbro(rec[fIznosNeto]).value();
          rec[fPdvStopa] = rec[fPdvStopa].value;
          setVanjskiTroskovi(records.concat(rec));
        }
      } else {
        refreshRecords();
      }
    }
    dialogContext.hideDialog(2);
  };

  const refreshRecords = () => {
    if (mode === "view") {
      loaderContext.toggleLoading(true);
      dc.GetData()
        .then((resp) => {
          if (resp.success) {
            setRecords(resp.data ? resp.data : []);
            const iznos = resp.data && resp.data.length > 0
              ? resp.data
                .filter((x) => x[fIznosBruto])
                .map((x) => x[fIznosBruto])
                .reduce((a, b) => a + b, 0)
              : null;
            onFieldChange(iznos, fIznosFinCalc);
          }
        })
        .catch((result) => {
          snackbarContext.showNotification(result.error, "error");
        })
        .finally(() => {
          loaderContext.toggleLoading(false);
        });
    }
  };

  // const addTrosakPosebnaPonuda = (record) => {
  //   const length = records.length;
  //   record.id = length + 1;
  //   const newRecords = records.concat(record);
  //   setVanjskiTroskovi(newRecords);
  // };

  const addExterniTrosak = () => {
    const currentRecords = subModels.eksterni_trosak_helper.records;
    const selectedRecords = record.eksterni_trosak_helper;

    if (selectedRecords.length > 0) {
      const chosenRecords = selectedRecords
        .map((selected) => {
          const tempItem = currentRecords.find((current) => current.value === selected.value);
          const inRecords = records.some((record) => record.value === selected.value);
          if (!inRecords) {
            return tempItem;
          }
        })
        .filter((record) => record !== undefined);
      insertExterniTroskovi(chosenRecords);
    }
  };

  const insertExterniTroskovi = (chosenRecords) => {
    const troskovi = chosenRecords.map((record, i) => {
      const length = records.length;
      return {
        id: length + 1 + i,
        opis: record.label,
        iznos_neto: record.iznos_neto,
        pdv_stopa: record.pdv_stopa,
        pdv_iznos: record.pdv_iznos,
        iznos_bruto: record.iznos_bruto
      };
    });
    const newRecords = records.concat(troskovi);
    onFieldChange(null, fEksterniTrosakHelper);
    setVanjskiTroskovi(newRecords);
  };

  const setVanjskiTroskovi = (records) => {
    setRecords(records);
    const preparedArray = records.map((record) => {
      return {
        opis: record.opis,
        iznos_neto: record.iznos_neto,
        pdv_stopa: record.pdv_stopa,
        pdv_iznos: record.pdv_iznos,
        iznos_bruto: record.iznos_bruto
      };
    });
    onFieldChange(preparedArray, fFinCalcDirect);
  };

  const round = (value, precision) => {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  };

  const handleRowAction = function(action, id) {
    if (action === "edit") {
      openFinCalcDialog(id);
    }
    if (action === "remove") {
      const newRecords = records.filter((r) => r.id !== id);
      setVanjskiTroskovi(newRecords);
    }
  };

  const allowRowAction = function(action, id) {
    return true;
  };

  useEffect(() => {
    if (currentTab === tabIndex && mode === "view") {
      refreshRecords();
    }
  }, [currentTab]);

  useEffect(() => {
    if (mode === "insert") {
      const iznos = records
        ? records
          .filter((x) => x["iznos_bruto"])
          .map((x) => x["iznos_bruto"])
          .reduce((a, b) => a + b, 0)
        : null;
      onFieldChange(iznos, "iznos_fincalc");
    }
  }, [records.length]);

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: handleFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels
  };

  const pickerRecords = record.eksterni_trosak_helper ? record.eksterni_trosak_helper : [];
  const viewName =
    mode === "insert" ? "posebnePonudaInsert" : mode === "view" ? "posebnePonudaView" : "posebnePonudaUpdate";

  const priceDistance = record[fPriceForDistance] ? `${numbro(record[fPriceForDistance]).format()} kn` : null;
  const netoUdaljenost = record[fNetoUdaljenostVoznje] ?
    `${mode === "insert" ? Math.floor(record[fNetoUdaljenostVoznje].value / 1000) : 
      Math.floor(record[fNetoUdaljenostVoznje].value)} km` : null;
  const ukupnoCijena = record[fUkupnoAkcijskaCijena] ? `${numbro(record[fUkupnoAkcijskaCijena]).format()} kn` : null;

  const ukupnoAkcijskaCijena = record[fUkupnoAkcijskaCijena] ? `${numbro(record[fUkupnoAkcijskaCijena]).format()} kn` : null;

  const pdvStopa = record[fPdvStopa] ? record[fPdvStopa].value : null;
  const pdvIznosManual = record[fPdvStopaManual] ? `${numbro(record[fPdvStopaManual]).format()} kn` : null;
  const pdvIznos = record[fPdvIznos] ? `${numbro(record[fPdvIznos]).format()} kn` : null;

  const hasPdvStopa = pdvStopa ? true : false;
  const hasPdvManual = pdvIznosManual ? true : false;

  const cijenasPDV = record[fCijenaSaPDVIznos] ? `${numbro(record[fCijenaSaPDVIznos]).format()} kn` : null;

  const iznosFinCalc = record[fIznosFincalc] ? `${numbro(record[fIznosFincalc]).format()} kn` : null;

  const cijenasVanjskimTroskovima = record[fCijenaSaFinCalcIznos]
    ? `${numbro(record[fCijenaSaFinCalcIznos]).format()} kn`
    : "0 kn";

  const cbcProvzija = record[fCBCProvizija] ? `${record[fCBCProvizija]}%` : null;
  const cbcIznosProvizija = record[fCBCProvizijaIznos]
    ? `${numbro(record[fCBCProvizijaIznos]).format()} kn (${cbcProvzija})`
    : null;

  const ukupno = record[fUkupnoBrutoManual] ? `${numbro(record[fUkupnoBrutoManual]).format()} kn` :
    record[fUkupnoBruto] ? `${numbro(record[fUkupnoBruto]).format()} kn` : null;

  return (
    <FormTabPanel value={currentTab} index={tabIndex}>
      <GridContainer>
        <GridItem xs={12} md={12}>
          <GridContainer direction="row" align="center" justify="center" spacing={1}>
            <GridItem>
              <CalculationView title={t("posebna_ponuda.akcija_cijena_km")} value={priceDistance} />
            </GridItem>
            <GridItem>x</GridItem>
            <GridItem>
              <CalculationView title={t("trazilica.neto_udaljenost_voznje")} value={netoUdaljenost} />
            </GridItem>
            <GridItem>=</GridItem>
            <GridItem>
              <CalculationView title={t("posebna_ponuda.akcijska_cijena_uk")} value={ukupnoAkcijskaCijena} />
            </GridItem>
          </GridContainer>
        </GridItem>

        <FormContent {...commonProps} fieldNames={["pdv_stopa", "pdv_stopa_manual"]} columns={2} />

        <GridItem xs={12} md={12}>
          <GridContainer direction="row" align="center" justify="center" spacing={1}>
            <GridItem>
              <CalculationView title={t("posebna_ponuda.akcijska_cijena_uk")} value={ukupnoAkcijskaCijena} />
            </GridItem>
            <GridItem>+</GridItem>
            <GridItem>
              <CalculationView title={t("posebna_ponuda.pdv_iznos")} value={pdvIznos} />
            </GridItem>
            <GridItem>=</GridItem>
            <GridItem>
              <CalculationView title={t("posebna_ponuda.cijena_s_pdv_iznos")} value={cijenasPDV} />
            </GridItem>
          </GridContainer>
        </GridItem>

        {mode === "insert" || mode === "update" ? (
          <GridItem xs={12} sm={12}>
            <GridContainer>
              <FormContent
                title={t("trjob.sections.eksterni_troskovi")}
                {...commonProps}
                fieldNames={["eksterni_trosak_helper"]}
                columns={2}
              />
              <GridItem xs={2} sm={4} md={4}>
                <FormButton
                  variant="outlined"
                  startIcon={<AddCircleIcon />}
                  onClick={addExterniTrosak}
                  disabled={mode === "view" || pickerRecords.length === 0 ? true : false}
                >
                  {pickerRecords.length === 1 || pickerRecords.length === 0
                    ? t("trjob.add_trosak")
                    : t("trjob.add_troskovi")}
                </FormButton>
              </GridItem>
            </GridContainer>
          </GridItem>
        ) : null}

        {/* <FormContent
          title={t("posebna_ponuda.sections.izracun")}
          fieldNames={["iznos_fincalc", "iznos_fincalc_manual"]}
          {...commonProps}
          columns={2}
        /> */}

        <GridItem md={12} sm={12}>
          <ModelTable
            records={records}
            dc={dc}
            viewName={viewName}
            allowExport={false}
            allowFilter={false}
            allowAdd={mode === "insert"}
            handleAdd={handleAdd}
            addLabel={t("buttons.new_m")}
            allowSelection="none"
            onRowAction={handleRowAction}
            allowRowAction={allowRowAction}
          />
        </GridItem>

        <GridItem xs={12} md={12}>
          <GridContainer direction="row" align="center" justify="center" spacing={1}>
            <GridItem>
              <CalculationView title={t("posebna_ponuda.cijena_s_pdv_iznos")} value={cijenasPDV} />
            </GridItem>
            <GridItem>+</GridItem>
            <GridItem>
              <CalculationView title={t("posebna_ponuda.vanjski_troskovi")} value={iznosFinCalc} />
            </GridItem>
            <GridItem>=</GridItem>
            <GridItem>
              <CalculationView title={t("posebna_ponuda.cijena_s_fin_calc_iznos")} value={cijenasVanjskimTroskovima} />
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem xs={12} md={12}>
          <GridContainer direction="row" align="center" justify="center" spacing={1}>
            <GridItem>
              <CalculationView title={t("posebna_ponuda.cijena_s_fin_calc_iznos")} value={cijenasVanjskimTroskovima} />
            </GridItem>
            <GridItem>+</GridItem>
            <GridItem>
              <CalculationView title={t("ponuda.cbc_provizija_iznos")} value={cbcIznosProvizija} />
            </GridItem>
            <GridItem>=</GridItem>
            <GridItem>
              <CalculationView title={t("posebna_ponuda.iznos_s_cbc_provizijom")} value={ukupno} />
            </GridItem>
          </GridContainer>
        </GridItem>

        <FormContent
          {...commonProps}
          title={t("posebna_ponuda.sections.bruto")}
          fieldNames={["uk_bruto_hrk", "uk_bruto_manual"]}
          columns={2}
        />
      </GridContainer>
    </FormTabPanel>
  );
}

export default TabPanelFinancijskaKalkulacijaPPTransfer;
