import React, { Fragment } from "react";
import moment from "moment";
import numbro from "numbro";

import { fieldFormat } from "Lib/fieldFormat";
import { formats } from "Lib/formats";
import { formatEuro } from "Lib/euro";

// Material-UI Core Components
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import { grey, lightBlue, lightGreen, orange, red, yellow } from "@material-ui/core/colors";

//Material-UI Lab
import Rating from "@material-ui/lab/Rating";

// Material-UI Icons
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";

//Custom Icons
import DirektanUpitIcon from "Icons/DirektanUpitIcon";
import BurzaIcon from "Icons/BurzaIcon";
import AgenturaIcon from "Icons/AgenturaIcon";

//Custom Components
import { retrieveStatusState, retrieveIconColor } from "Lib/statuses";
import StarRatingIcon from "../icons/StarRatingIcon";

let tableGenerator = function(t, rowActionHandler, allowRowAction) {
  this.t = t;
  const emptyFnc = () => {
  };
  const alwaysAllow = (action, id) => {
    return true;
  };
  this.onAction = rowActionHandler || emptyFnc;
  this.allowAction = allowRowAction || alwaysAllow;
};

tableGenerator.prototype.generateDataRows = function(records, fields, subModels, userContext, themeContext) {
  return Promise.resolve(
    records && records.map ?
      records.map((record) => {
        let rows = {};
        fields.forEach((field) => {
          rows[field.source] = this.generateDataCell(field, record, subModels[field.source], userContext, themeContext);
        });
        return rows;
      })
      : []
  );
};

tableGenerator.prototype.generateDataCell = function(field, record, dc, userContext, themeContext) {
  const val = record[field.source];
  switch (field.type) {
    case "text":
      if (field.items) {
        let pos = field.items.values.indexOf(val);
        const lbl = field.items.labels[pos];
        return field.translate ? this.t(lbl) : lbl;
      } else if (field.format) {
        return fieldFormat(record, field.format, field.translate ? this.t : undefined);
      } else {
        return field.translate ? this.t(val) : val;
      }
    case "date":
      return (
        <div type="date" value={val ? moment.utc(val).toISOString() : ""}>
          {val ? moment.utc(val).local().format(formats.date) : "-"}
        </div>
      );
    case "datetime":
      return (
        <div type="datetime" value={val ? moment.utc(val).toISOString() : ""}>
          {val ? moment.utc(val).local().format(formats.datetime) : ""}
        </div>
      );
    case "currency":
      if (val) {
        if (val._value !== undefined && val._value !== null) {
          const num = numbro(val._value).format({ thousandSeparated: true, mantissa: 2 });
          const euroValue = field.convert2Euro ? formatEuro(val._value) : null;
          return (
            <div align="right" unformatedvalue={val._value} type="currency">
              {num} {euroValue !== null ? `(${this.t("common.euro", { value: euroValue })})` : null}
            </div>
          );
        } else if (val !== undefined && (typeof val === "number" || typeof val === "float")) {
          const num = numbro(val).format({ thousandSeparated: true, mantissa: 2 });
          const euroValue = field.convert2Euro ? formatEuro(val) : null;
          return (
            <div unformatedvalue={val} type="currency">
              {field.currency} {num} {euroValue !== null ? `(${this.t("common.euro", { value: euroValue })})` : null}
            </div>
          );
        }
      } else {
        return null;
      }
    case "active":
    case "mark_new":
    case "panic":
    case "agentura":
      if (field.items) {
        let pos = field.items.values.indexOf(val);
        const lbl = field.items.labels[pos];
        //return this.t(field.items.labels[pos]);
        const renderValue = field.translate ? this.t(lbl) : lbl;
        // this acts as abstract functional programming paradigm
        // e.g.
        // const a = ((b) => (r) => (!b ? r[0] : r.slice(1)))
        // a(0)(a(1)([1, 2, 3]))
        // imagine data not as a value stored in memory but rather as a function which will return a value depending on its argument
        //    read this for more info https://en.wikipedia.org/wiki/List_(abstract_data_type)
        return (test) => (test !== undefined ? val : renderValue);
        // deprecated I think
        //return (
        //  <div type="active" value={val}>
        //    {this.t(field.items.labels[pos])}
        //  </div>
        //);
      }
    case "boolean":
    case "radio":
      if (dc) {
        if (val === null || val === undefined) {
          return "";
        } else {
          const lbl = dc.records.find((x) => x.value === val).label;
          return field.translate ? this.t(lbl) : lbl;
        }
      } else if (field.items) {
        const ind = field.items.values.indexOf(val);
        const lbl = field.items.labels[ind];
        return field.translate ? this.t(lbl) : lbl;
      } else {
        return val;
      }
    case "checkbox":
      if (Number.isInteger(val)) {
        if (dc) {
          const lbl = dc.records.find((x) => x.value == val).label;
          return field.translate ? this.t(lbl) : lbl;
        }
        const codes = val.toString(2).split("").reverse();
        let arr = codes.map((x, i) => field.items.labels[field.items.values.indexOf(Math.pow(2, i))]);
        if (field.translate) {
          arr = arr.map(x => this.t(x));
        }
        return (
          <div property={val}>
            {arr.join(", ")}
          </div>
        );
      } else if (Array.isArray(val)) {
        if (dc) {
          let arr = val.map((x) => dc.records.find((f) => f.value === x || f.value === x.value).label);
          if (field.translate) {
            arr = arr.map(x => this.t(x));
          }
          return (
            <div property={val}>
              {arr.join(", ")}
            </div>
          );
        } else {
          return (
            <div property={val}>
              {val.map((x) => x.value).join(", ")}
            </div>
          );
        }
      } else {
        return val;
      }
    case "picker":
      if (dc) {
        if (val === null || val === undefined) {
          return "";
        } else {
          const rec = dc.records.find((x) => x[dc.fieldId.source] === (val.value || val));
          if (rec) {
            const lbl = rec.label;
            return field.translate ? this.t(lbl) : lbl;
          } else {
            return "";
          }
        }
      } else if (val && val.hasOwnProperty("value")) {
        return val.value;
      } else if (field.items) {
        const ind = field.items.values.indexOf(val);
        const lbl = field.items.labels[ind];
        return field.translate ? this.t(lbl) : lbl;
      } else {
        return val;
      }
    case "array":
      if (Array.isArray(val)) {
        return val.map((x) => this.t(x)).join(", ");
      } else {
        return this.t(val);
      }
    case "favorite_p":
      const isFavP = userContext.isFavoritePrijevoznik(record.id);
      return (
        <IconButton size="small" value={isFavP} onClick={(evt) => {
          evt.stopPropagation();
          userContext.toggleFavoritePrijevoznik(record.id);
        }}>
          {
            isFavP ? <FavoriteIcon color="secondary" fontSize="small" /> : <FavoriteBorderIcon color="secondary" fontSize="small" />
          }
        </IconButton>
      );
    case "favorite_a":
      const isFavA = userContext.isFavoriteAgencija(record.id);
      return (
        <IconButton size="small" value={isFavA} onClick={(evt) => {
          evt.stopPropagation();
          userContext.toggleFavoriteAgencija(record.id);
        }}>
          {
            isFavA ? <FavoriteIcon color="secondary" fontSize="small" /> : <FavoriteBorderIcon color="secondary" fontSize="small" />
          }
        </IconButton>
      );
    case "iconStatus":
      const index = field.items.values.indexOf(val);
      const val_label = this.t(field.items.labels[index]);
      const icon = field.items.icons[index];
      const color = retrieveIconColor(icon);

      return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} value={val_label} label={val_label} property={val}>
          <Icon style={{ color: color }}>{icon}</Icon>
          <div style={{ fontSize: "14px", marginLeft: "4px" }}>
            {val_label}
          </div>
        </div>
      );
    case "rating":
      return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} property={val}>
          <StarRatingIcon count={Math.floor(val)}/>
        </div>
      );
    case "stats":
      if ((val.visible ? val.visible !== false : false) || (val.odabrano ? val.odabrano !== null : false)) {
        return (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            {Object.keys(val).map((key) => (
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} key={"stat-" + key}>
                <Typography variant="caption" component="p">{this.t("tableStats." + key)}:<b>{val[key] === true ? this.t("common.da") : val[key]}</b></Typography>
              </div>
            ))}
          </div>
        );
      } else {
        return "-";
      }
    case "statusstats":
      if (val) {
        const ind2 = field.items.values.indexOf(val.status_upita);
        const val_label2 = this.t(field.items.labels[ind2]);
        const icon2 = field.items.icons[ind2];
        const color2 = retrieveIconColor(icon2);

        const isBurza = val.visible;
        const isAgentura = val.agentura;
        const dp = val.odabrano;
        const pon_d = val.ponude_direktne;
        const odb = val.declined;
        const pon_b = val.ponude_sa_burze;

        const burzaLabel = isBurza ? pon_b : null;
        const agenturaLabel = isAgentura ? (pon_d ? pon_d : 0) : null;
        const dpLabel = dp ? <span><span style={{color: "#2ca25f"}}><b>{pon_d || 0}</b></span> / <span style={{color: "#e34a33"}}>{odb || 0}</span> od {dp}</span> : null;
        return (
         <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} value={val_label2} label={val_label2} property={val.status_upita}>
            <Icon style={{ color: color2 }}>{icon2}</Icon>
            <div style={{ fontSize: "14px", marginLeft: "4px" }}>
              {`${val_label2} `}
            </div>
            <div style={{flexGrow: 1}} />
            {isAgentura
              ? <Chip avatar={<AgenturaIcon />} label={agenturaLabel}></Chip>
              : null }
            {isBurza
              ? <Chip avatar={<BurzaIcon />} label={burzaLabel}></Chip>
              : null }
            { dp && !isAgentura
              ? <Chip avatar={<DirektanUpitIcon />} label={dpLabel}></Chip>
              : null }
          </div>
        );
      } else {
        return '';
      }

    case "button":
      const idAttribute = field.idref ? field.idref : "id";
      const allow = this.allowAction(field.action, record[idAttribute]);
      return allow ? (
        <Tooltip title={this.t(field.tooltip)}>
          <IconButton
            aria-label={field.action}
            size="small"
            disabled={allow ? false : true}
            onClick={(evt) => {
              evt.stopPropagation();
              this.onAction(field.action, record[idAttribute]);
            }}
          >
            <Icon color={field.color ? field.color : "secondary"}>{field.icon}</Icon>
          </IconButton>
        </Tooltip>
      ) : null;
    case "link":
      if (val) {

        const recIdField = field.link ? field.link.recordIdField : null;
        const recType = field.link ? field.link.recordType : null;
        const recId = record.hasOwnProperty(recIdField) ? record[recIdField] : null;
        return (test) => test !== undefined ? val : (
          <div type="text" value={val}>
            {val}
            <IconButton
              aria-label={"mail"}
              size="small"
              onClick={(evt) => {
                evt.stopPropagation();
                this.onAction(recType, recId);
              }}
            >
              <Icon color="secondary">link</Icon>
            </IconButton>
          </div>
        );
      } else {
        return () => ("")
      }
    case "mail":
      if (val) {
        return (
          <div value={val}>
            <IconButton
              aria-label={"mail"}
              size="small"
              onClick={(evt) => {
                evt.stopPropagation();
                window.open("mailto:" + val, "_blank");
              }}
            >
              <Icon color="secondary">mail_outline</Icon>
            </IconButton>
            {val}
          </div>
        );
      }
    default:
      if (val instanceof Object) {
        return;
      }
      return val;
  }
};

export default tableGenerator;
