import React from 'react';
import { useTranslation } from "react-i18next";

//Material-UI
import CheckIcon from '@material-ui/icons/Check';

//Custom Components
import DialogActionButton from "UI/Dialog/DialogActionButton";

function DialogActionButtonConfirm(props) {
  const { t } = useTranslation();

  return (
      <DialogActionButton startIcon={<CheckIcon />} {...props} aria-label="confirm" aria-controls="confirm">
        {t("buttons.confirm")}
      </DialogActionButton>
  );
}

export default DialogActionButtonConfirm;
