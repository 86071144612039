import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Box from "@material-ui/core/Box";

//Custom Components
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import UserContext from "Components/UserContext/UserContext";
import FormTabPanel from "UI/Form/FormTabPanel";
import ModelTable from "UI/Table/ModelTable";
import model from "Models/ponuda";
import dataController from "Lib/dataController";
import { upitService } from "Services/upitService";
import PonudaJavneInformacijeDialog from "Views/Ponude/PonudaJavneInformacijeDialog";
import VoziloPublicDialog from "Views/Agencije/Trazilica/VoziloPublicDialog";

function TabPanelPonude(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const userContext = useContext(UserContext);
  const { t } = useTranslation();

  const [records, setRecords] = useState([]);

  const { currentTab, tabIndex, record, recordId, mode, onConfirm, isAdmin = false } = props;

  const dc = new dataController(model);

  const refreshRecords = () => {
    if(recordId) {
      upitService
        .getQuotesForUpit(recordId)
        .then((resp) => {
          if (resp.success) {
            setRecords(resp.data);
          }
        }).catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      });
    }
  };

  useEffect(() => {
    refreshRecords();
  }, [recordId]);

  const handleRowAction = function (action, id) {
    if (action === "view") {
      openPonudaDialog(id);
    } else if (action === "vozilo") {
      showVoziloDialog(id);
    }
  };

  const allowRowAction = function (action, id) {
    if (action === "view") {
      const rec = records.find((x) => x.id === id);
      if (rec && rec.ponuda_state_id === 99) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const showVoziloDialog = (id) => {
    dialogContext.showDialog(VoziloPublicDialog, {
      recordId: id
    });
  };

  const openPonudaDialog = (id) => {
    const locked = record.upit_state_id === 11 ? false : true;

    dialogContext.showDialog(PonudaJavneInformacijeDialog, {
      dc: dc,
      mode: "view",
      form: "public",
      ponudaId: id,
      upitId: recordId,
      onClose: handleDialogClose,
      locked: locked,
      isAdmin: isAdmin,
      subModelDefaultValues:{
        prijevoznik_id: userContext.subjectId
      }
    });
  };

  const handleDialogClose = (result) => {
    if (result.confirmed) {
      onConfirm();
    }
    dialogContext.hideDialog();
  };

  return (
    <FormTabPanel value={currentTab} index={tabIndex}>
      <Box m={2}>
        <ModelTable
          title={t("titles.ponude")}
          dc={dc}
          viewName={"upit_ponude"}
          allowExport={true}
          allowFilter={true}
          allowAdd={false}
          allowSelection="none"
          records={Array.isArray(records) ? records : []}
          onRowAction={handleRowAction}
          allowRowAction={allowRowAction}
          onDoubleClick={(id) => handleRowAction("view", id)}
          subModelDefaultValues={{
            prijevoznik_id: userContext.subjectId
          }}
        />
      </Box>
    </FormTabPanel>
  );
}

TabPanelPonude.defaultProps = {
  mode: "update"
};

TabPanelPonude.propTypes = {
  currentTab: PropTypes.number,
  tabIndex: PropTypes.number,
  dc: PropTypes.object,
  recordId: PropTypes.number,
  record: PropTypes.object,
  validation: PropTypes.object,
  onFieldChange: PropTypes.func,
  fields: PropTypes.array,
  mode: PropTypes.oneOf(["view", "update"]),
  onConfirm: PropTypes.func
};

export default TabPanelPonude;
