import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function KorisnikAgencijskiIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M 9.9746096,2.38755e-6 A 4.9923169,4.9923169 0 0 0 4.9921875,4.9921899 4.9923169,4.9923169 0 0 0 9.9843746,9.9843774 4.9923169,4.9923169 0 0 0 12.837891,9.0878934 c 0.198361,-0.238392 0.436739,-0.438968 0.705078,-0.59375 A 4.9923169,4.9923169 0 0 0 14.976563,4.9921899 4.9923169,4.9923169 0 0 0 9.9843746,2.38755e-6 a 4.9923169,4.9923169 0 0 0 -0.0098,0 z M 14.800782,9.1582054 c -0.877684,0 -1.548829,0.671144 -1.548829,1.5488276 v 7.744141 c 0,0.877684 0.671145,1.548828 1.548829,1.548828 h 3.613281 c 0.877684,0 1.548828,-0.671144 1.548828,-1.548828 v -7.744141 c 0,-0.8776836 -0.671144,-1.5488276 -1.548828,-1.5488276 z m 0,1.0332026 h 3.613281 c 0.309771,0 0.515625,0.205854 0.515625,0.515625 v 5.16211 h -4.646485 v -5.16211 c 0,-0.309771 0.207808,-0.515625 0.517579,-0.515625 z M 9.9843746,12.480471 C 4.4678648,12.480471 0,14.714406 0,17.472658 v 2.496094 h 12.75 c -0.274014,-0.363677 -0.455877,-0.796202 -0.488281,-1.261719 -0.01898,-2.030617 -0.01229,-4.060663 -0.0098,-6.091797 -0.729423,-0.08474 -1.486398,-0.134765 -2.2675784,-0.134765 z m 4.9746104,4.9375 h 3.294922 c 0.309771,0 0.517578,0.207806 0.517578,0.517578 0,0.309771 -0.207807,0.515625 -0.517578,0.515625 h -3.294922 c -0.30977,0 -0.515625,-0.205854 -0.515625,-0.515625 0,-0.309772 0.205855,-0.517578 0.515625,-0.517578 z" />
    </SvgIcon>
  );
}

export default KorisnikAgencijskiIcon;