import React from "react";

//Custom Components
import PrijevozniciPage from "UI/AppPage/PrijevozniciPage";
import ResponsiveBox from "UI/Box/ResponsiveBox";
import VozilaTable from "Views/Prijevoznici/Vozila/VozilaTable";

const PrijevozniciVozilaPage = React.memo((props) => {
  return (
    <PrijevozniciPage>
      <ResponsiveBox fillHeight>
        <VozilaTable />
      </ResponsiveBox>
    </PrijevozniciPage>
  );
});

export default PrijevozniciVozilaPage;
