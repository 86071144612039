import React, { useState } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Divider from "@material-ui/core/Divider";

//Material-UI Icons
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";

//Custom Components
import withRedirect from "Components/withRedirect";

import HeaderMenuIconButton from "UI/Header/HeaderMenuIconButton";
import HeaderMenuItem from "UI/Header/HeaderMenuItem";

function AccessibilityMenu(props) {
  const [greyscale, setGreyscale] = useState(false);
  const [contrast, setContrast] = useState(false);
  const { t } = useTranslation();

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const html = document.getElementsByTagName("html")[0];

  const fontSizePercentage = (value) => {
    if (value === "") {
      return 100;
    } else if (value.indexOf("%") > 0) {
      const ind = value.indexOf("%");
      const perc = parseInt(value.substr(0, ind), 10);
      return perc;
    } else {
      return 100;
    }
  };

  const handleFontLarger = () => {
    const currentPercSize = fontSizePercentage(html.style.fontSize);
    if (currentPercSize < 200) {
      html.style.fontSize = (currentPercSize + 20).toString() + "%";
    }
  };

  const handleFontSmaller = () => {
    const currentPercSize = fontSizePercentage(html.style.fontSize);
    if (currentPercSize > 40) {
      html.style.fontSize = (currentPercSize - 20).toString() + "%";
    }
  };

  const handleGreyscale = () => {
    html.className = greyscale ? "" : "greyscale";
    setGreyscale((val) => !val);
    setContrast(false);
  };

  const handleContrast = () => {
    html.className = contrast ? "" : "contrast";
    setContrast((val) => !val);
    setGreyscale(false);
  };

  const handleReset = () => {
    html.className = "";
    html.style.fontSize = "";
  };

  return (
    <HeaderMenuIconButton icon={<AccessibilityNewIcon />} desc="a11y" keepOpen={true}>
      <HeaderMenuItem label={t("menu.a11y.povecaj_tekst")} onClick={handleFontLarger} />
      <HeaderMenuItem label={t("menu.a11y.smanji_tekst")} onClick={handleFontSmaller} />
      <Divider />
      <HeaderMenuItem label={t("menu.a11y.greyscale")} onClick={handleGreyscale} />
      <HeaderMenuItem label={t("menu.a11y.kontrast")} onClick={handleContrast} />
      <Divider />
      <HeaderMenuItem label={t("menu.a11y.reset")} onClick={handleReset} />
    </HeaderMenuIconButton>
  );
}

export default withRedirect(AccessibilityMenu);
