import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { formats } from "Lib/formats";

const useStyles = makeStyles((theme) => ({
  info: {
    marginLeft: 10,
    marginRight: 20
  }
}));

function DialogToolbarCreationDetails(props) {
  const { lowercase, record } = props;
  const { t } = useTranslation();

  const classes = useStyles();

  if (!record) {
    return null;
  }

  return (
    <div>
      {/* {record.created_on ? (
        <Typography
          align="right"
          variant="caption"
          component="p"
          className={classes.info}
          style={lowercase ? { textTransform: "none" } : null}
        >
          {t("common.created_by")} {record.created_by} {t("common.on_date")}{" "}
          {record.created_on.local().format(formats.timedate)}
        </Typography>
      ) : null}
      {record.modified_on ? (
        <Typography
          align="right"
          variant="caption"
          component="p"
          className={classes.info}
          style={lowercase ? { textTransform: "none" } : null}
        >
          {t("common.modified_by")} {record.modified_by} {t("common.on_date")}{" "}
          {record.modified_on.local().format(formats.timedate)}
        </Typography>
      ) : null} */}
    </div>
  );
}

DialogToolbarCreationDetails.defaultProps = {
  lowercase: false
};

DialogToolbarCreationDetails.propTypes = {
  lowercase: PropTypes.bool,
  record: PropTypes.object
};

export default DialogToolbarCreationDetails;
