import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Icons
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";

//Custom Components
import TableButton from "UI/Table/TableButton";

function TableButtonView(props) {
  const { t } = useTranslation();

  return (
    <TableButton variant="contained" allow="one" startIcon={<OpenInBrowserIcon />} {...props}>
      {t("buttons.view")}
    </TableButton>
  );
}

export default TableButtonView;
