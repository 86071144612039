import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

//Custom components
import GridContainer from "UI/Grid/GridContainer";
import FormContent from "Components/FormContent";
import { FormTabPanel } from "UI/Form";


function TabPanelDetalji(props) {
  const { t } = useTranslation();

  const { currentTab, tabIndex } = props;
  const { record, validation, fields, onFieldChange, mode, subModels } = props;
  const { transfer } = props;

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    mode: mode,
    subModels: subModels
  };

  return (
    <FormTabPanel value={currentTab} index={tabIndex}>
      <GridContainer>
        <FormContent
          {...commonProps}
          title={t("posebna_ponuda.sections.osnovno")}
          fieldNames={["price_per_distance", "vozilo_id"]}
          columns={2}
        />
        <FormContent
          {...commonProps}
          fieldNames={["interna_napomena", "rok_potvrda"]}
          columns={2}
        />
        <FormContent
          {...commonProps}
          fieldNames={["opis"]}
          columns={1}
        />
        <FormContent
          {...commonProps}
          title={t("posebna_ponuda.sections.termin")}
          fieldNames={["from_ts", "to_ts"]}
          columns={3}
        />
        <FormContent
          {...commonProps}
          title={t("posebna_ponuda.sections.location")}
          fieldNames={["from_loc", "from_loc_radius"]}
          columns={2}
        />
        {transfer ? (
          <Fragment>
            <FormContent
              {...commonProps}
              fieldNames={["to_loc", "to_loc_radius"]}
              columns={2}
            />
            <FormContent
              {...commonProps}
              title={t("upit.sections.podaci_o_putovanju")}
              fieldNames={["trajanje_voznje", "neto_udaljenost_voznje", "udaljenost_povratka_vozila"]}
              columns={3}
            />
          </Fragment>
        ) : null}
      </GridContainer>
    </FormTabPanel>
  );
}

export default TabPanelDetalji;
