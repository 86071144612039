import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";

//Custom Components
import withFormController from "Components/withFormController";
import DynamicDialog from "UI/Dialog/DynamicDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarCreationDetails from "UI/Dialog/DialogToolbarCreationDetails";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogActionButtonAdd from "UI/Dialog/ActionButtons/DialogActionButtonAdd";
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import OsnovniKomitentiForm from "Views/Prijevoznici/Komitenti/OsnovniKomitentiForm";

function KomitentiDialog(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const {t} = useTranslation();


  const [dataChanged, setDataChanged] = useState(false);

  const {record, mode, validation, fields, subModels, recordId, path} = props; //HOC withFormController
  const {doInsert, doClose} = props; //HOC withFormController
  const {onFieldChange, onClose} = props; //HOC withFormController

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleInsert = (evt) => {
    if (doInsert) {
      doInsert().then((result) => {
        if (result.success) {
          close({dataChanged: true, action: "insert"});
          snackbarContext.showNotification("insert", "success");
        }
      }).catch((result) => {
        if (result.success === false && result.validationPass === false) {
          //do nothing
        } else {
          snackbarContext.showNotification(result.error, "error");
        }
      });
    }
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({dataChanged: false});
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const close = (result) => {
    if (result.dataChanged || dataChanged) {
      onClose({dataChanged: true, action: result.action});
    }

    dialogContext.hideDialog();
  };

  return (
      <DynamicDialog onClose={handleClose}>
        <DialogHeader>
          <Toolbar variant="dense" disableGutters={true}>
            <DialogAvatar ariaLabel="pregled_komitenti" icon_word="contact_phone"/>
            <DialogToolbarHeading>{t("titles.komitenti")}</DialogToolbarHeading>
            <DialogToolbarFillContent/>
            <DialogToolbarCreationDetails record={record}/>
            <DialogToolbarButtonClose onClick={handleClose}/>
          </Toolbar>
        </DialogHeader>
        <DialogBody>
          <Box m={2}>
            <OsnovniKomitentiForm
                recordId={recordId}
                mode={mode}
                record={record}
                validation={validation}
                fields={fields}
                subModels={subModels}
                onFieldChange={handleFieldChange}
            />
          </Box>
        </DialogBody>
        <DialogActions>
          <DialogActionButtonClose variant="outlined" onClick={handleClose}/>
          <DialogActionButtonAdd variant="contained" onClick={handleInsert}/>
        </DialogActions>
      </DynamicDialog>
  );
}

export default withFormController(KomitentiDialog);
