import React from "react";

//Custom Components
import AdminPage from "UI/AppPage/AdminPage";
import ResponsiveBox from "UI/Box/ResponsiveBox";
import AdminUpitiTable from "Views/Administration/AdminUpiti/AdminUpitiTable";

const AdminUpitiPage = React.memo((props) => {
  return (
    <AdminPage>
      <ResponsiveBox fillHeight>
        <AdminUpitiTable />
      </ResponsiveBox>
    </AdminPage>
  );
});

export default AdminUpitiPage;
