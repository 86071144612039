import React, { useContext } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";

//Material UI
import DialogActions from "@material-ui/core/DialogActions";

//Custom Components
import DraggableDialog from "UI/Dialog/DraggableDialog";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButtonClose from "UI/Dialog/ActionButtons/DialogActionButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import model from "Models/vozila";
import dataController from "Lib/dataController";
import DialogContext from "UI/DialogContext/DialogContext";
import VoziloPublicView from "./VoziloPublicView";
import EuroConversionComment from "Components/EuroConversionComment";

const styles = {
  body: {
    overflow: "hidden",
    paddingTop: "0px"
  }
};

function VoziloPublicDialog(props) {
  const dialogContext = useContext(DialogContext);
  const { recordId, onClose } = props;

  const dc = new dataController(model);

  const handleClose = () => {
    dialogContext.hideDialog();
    if (onClose) {
      onClose();
    }
  };

  return (
    <DraggableDialog open={true} maxWidth={"lg"} onClose={handleClose}>
      <DialogBody style={styles.body}>
        <VoziloPublicView
          dc={dc}
          secondary="vozila/__split__/public"
          recordId={recordId}
          form="public"
          mode="simpleView"
        />
      </DialogBody>
      <DialogActions>
        <EuroConversionComment />
        <DialogToolbarFillContent />
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
      </DialogActions>
    </DraggableDialog>
  );
}

export default VoziloPublicDialog;
