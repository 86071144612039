import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Elements
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";

//Custom Components
import withRedirect from "Components/withRedirect";
import KomunikatorContext from "Components/KomunikatorContext/KomunikatorContext";

function NotificationAlert(props) {
  const KC = useContext(KomunikatorContext);
  const { t } = useTranslation();
  const { redirect } = props; //HOC withRedirect;
  const { notification } = props;

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    const link = KC.getNotificationRedirectLink(notification.link_type, notification.link_id);
    if (link) {
      KC.markReadNotification(notification.id || notification.notif_id);
      redirect(link);
    }
  };

  return (
    <Snackbar open={true} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
      <Alert
        elevation={6}
        variant="filled"
        severity="warning"
        onClose={handleCloseSnackbar}
        icon={KC.getNotificationIcon(notification.link_type)}
        action={
          <Button color="inherit" size="small" onClick={handleCloseSnackbar}>
            {t("buttons.open")}
          </Button>
        }
      >
        {notification.tip_ttoken ? t(notification.tip_ttoken) : notification.tip}
      </Alert>
    </Snackbar>
  );
}

export default withRedirect(NotificationAlert);

