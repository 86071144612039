import React, { Fragment } from 'react';
import { Backdrop, CircularProgress } from "@material-ui/core";
import KomunikatorConsumer from "Components/KomunikatorContext/KomunikatorConsumer";

function KomunikatorContainer(props) {
  return (
      <Fragment>
        <KomunikatorConsumer>
          {(komunikatorContext) => (
            <div id="HELLOTHERE" style={{
              position: "fixed",
              width: "100%",
              bottom: 0,
              height: "500px",
              overflow: "visible",
              padding: "0 56px 0 49px",
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "flex-end",
              pointerEvents: "none"
            }}>
              {komunikatorContext.chatWindows.map(chat => chat.element)}
            </div>
          )}
        </KomunikatorConsumer>
      </Fragment>
  );
}

export default KomunikatorContainer;
