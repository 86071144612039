import { handleResponse } from "Services/helpers/handleResponse";
import { standardHeadersWithAuth } from 'Services/helpers/standardHeadersWithAuth';
import { Role } from "Lib/role";

import api from "Lib/api";

// The authentication service is used to login and logout of the application
export const authService = {
  //register,
  //check,
  //activate,
  login,
  logout,
  changePassword,
  resetPasswordRequest,
  resetPassword,
  whoAmI
};

const authApiUrl = process.env.REACT_APP_APIPATH + "auth/";
const appKey = process.env.REACT_APP_APPKEY;

// function register(userData) {
//   const tip = userData.tip.value ? userData.tip.value : userData.tip;

//   let bodyData = {
//     tip: tip,
//     ime: userData.ime,
//     prezime: userData.prezime,
//     email: userData.email,
//     oib: userData.oib,
//     telefon: userData.telefon,
//     adresa: userData.adresa,
//     appkey: appKey,
//   };

//   if (tip === "P") {
//     bodyData["p_naziv"] = userData.p_naziv;
//     bodyData["p_odgovornaosoba"] = userData.p_odgovornaosoba;
//     bodyData["p_opisdjelatnosti"] = userData.p_opisdjelatnosti;
//   }

//   const requestOptions = {
//     method: "POST",
//     headers: { "Content-Type": "application/json", Accept: "application/json" },
//     body: JSON.stringify(bodyData),
//   };

//   const url = authApiUrl + "register";

//   return fetch(url, requestOptions)
//     .then(handleResponse)
//     .then((data) => {
//       return data;
//     });
// }

// function check(token) {
//   const requestOptions = {
//     method: "GET",
//     headers: { "Content-Type": "application/json", Accept: "application/json" },
//   };

//   const url = authApiUrl + "activate-check/" + token;

//   return fetch(url, requestOptions)
//     .then(handleResponse)
//     .then((data) => {
//       return data;
//     });
// }

// function activate(data) {
//   const requestOptions = {
//     method: "PUT",
//     headers: { "Content-Type": "application/json", Accept: "application/json" },
//     body: JSON.stringify({
//       email: data.email,
//       activationToken: data.activation_token,
//       password: data.password,
//       passwordNew: data.password_new,
//       appkey: appKey,
//     }),
//   };

//   const url = authApiUrl + "activate";

//   return fetch(url, requestOptions)
//     .then(handleResponse)
//     .then((data) => {
//       if (data.auth_token) {
//         // store user details and basic auth credentials in local storage
//         // to keep user logged in between page refreshes
//         localStorage.setItem("auth_token", data.access_token);
//       }
//       return data;
//     });
// }

function changePassword(data) {
  const apiInstance = new api();
  const path = "auth/password-change";
  return apiInstance.Call(path, "post", data);
}

function resetPasswordRequest(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify({
      email: data.email,
      appkey: appKey,
    }),
  };

  const url = authApiUrl + "password-reset-request";

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function resetPassword(data) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify({
      email: data.email,
      passwordreset_token: data.passwordreset_token,
      password: data.password_new,
      appkey: appKey,
    }),
  };

  const url = authApiUrl + "password-reset";

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function wait(ms){
  return new Promise(resolve => setTimeout(resolve, ms));
}

function dummyLoginDelay() {
  return wait(1000).then(() =>{
    return dummyLogin();
  })
}

function dummyLogin(username,passowrd) {
  localStorage.setItem("auth_token", "123");
  //return Promise.resolve({});
  return {
    code: 0,
    access_token: "123",
    token_type: "bearer",
    username: "vanja",
    data: {
      username: "vanja",
      email: "vanja@prehnit.hr",
      firs_name: "Vanja",
      last_name: "Suhina",
      role_codes: [90],
    }};
}

function login(username, password) {
  const requestOptions = {
    method: "POST",
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Accept': 'application/json' },
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify({
      username: username,
      password: password
    }),
  };

  const url = authApiUrl + "login";

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((resp) => {
      // console.log(resp);
      //login successful if there's a user in the response
      if (resp.access_token) {
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        localStorage.setItem("auth_token", resp.access_token);
      }
      return resp;
    });
}

function logout() {

  const apiInstance = new api();
  return apiInstance.Call("auth/logout", "post")
  .then(resp => {
    if (resp.success) {
      localStorage.removeItem("auth_token");
      return resp;
    }
  })
  .catch(() => {
    localStorage.removeItem("auth_token");
  });
}

function whoAmI(){
  const apiInstance = new api();
  return apiInstance.Call("auth/who-am-i", "get");
}
