import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

//Material-UI Core Components
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

//CBC Icons
import KorisnikIcon from "Icons/KorisnikIcon";

//Custom Components
import withFormController from "Components/withFormController";
import { GridContainer, GridItem } from "UI/Grid";
import { CardHeading, CardToolbarMenuButton, CardToolbarButtonBack } from "UI/Card";
import { CardToolbarMenuItemDelete, CardToolbarMenuItemActivate, CardToolbarMenuItemDeactivate } from "UI/Card";
import { CardActionButtonClose, CardActionButtonAdd, CardActionButtonSave, CardActionButtonActivate } from "UI/Card";
import { FormAvatar, FormHeading, FormHeadingID, FormFillContent } from "UI/Form";

import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import FormContent from "Components/FormContent";
import { userService } from "Services/userService";

function UserForm(props) {
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();
  const history = useHistory();

  const USERROLE_CUSTOM = 990;
  const fUserRole = "user_role";
  const fPermissions = "user_permissions";

  const { mode, form, record, validation, fields, subModels, recordId, dataChanged } = props; //HOC withFormController
  const { doInsert, doUpdate, doClose, doDelete, doRefresh } = props; //HOC withFormController
  const { onFieldChange, onClose } = props; //HOC withFormController

  const handleClickBack = () => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          history.goBack();
        } else {
          if (result.shouldsave) {
            if (doUpdate) {
              doUpdate().then((result) => {
                if (result.success) {
                  snackbarContext.showNotification("update", "success");
                  history.goBack();
                }
              }).catch((result) => {
                if (result.success === false && result.validationPass === false) {
                  //do nothing
                } else {
                  snackbarContext.showNotification(result.error, "error");
                }
              });
            }
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      if (source === fUserRole && value !== null) {
        const subModelRecords = subModels.user_role.records;
        const permissions = subModelRecords.find((record) => record.value === value.value).app_permissions;
        if (value.value === USERROLE_CUSTOM) {
        } else {
          onFieldChange(value, fUserRole);
          onFieldChange(permissions, fPermissions);
          return;
        }
      }

      //if (source === fPermissions && value !== undefined && value !== null) {
      //  const { record } = props;
      //  const prevPermissionsLen = record && record.hasOwnProperty(fPermissions) && record[fPermissions] ? record[fPermissions].length : 0;
      //  const currPermissionsLen = Array.isArray(value) ? value.length : 1;

      //  const checked = currPermissionsLen > prevPermissionsLen;
      //  const intValue = Array.isArray(value) ? value.reduce((acc, curr) => ((curr.value || curr) + acc), 0) : value;
      //}
      onFieldChange(value, source);
    }
  };

  const handleInsert = (evt) => {
    if (doInsert) {
      doInsert().then((result) => {
        if (result.success) {
          snackbarContext.showNotification("insert", "success");
        }
      }).catch((result) => {
        if (result.success === false && result.validationPass === false) {
          //do nothing
        } else {
          snackbarContext.showNotification(result.error, "error");
        }
      });
    }
  };

  const handleUpdate = (evt) => {
    if (doUpdate) {
      doUpdate().then((result) => {
        if (result.success) {
          close({ dataChanged: true });
          snackbarContext.showNotification("update", "success");
        }
      }).catch((result) => {
        if (result.success === false && result.validationPass === false) {
          //do nothing
        } else {
          snackbarContext.showNotification(result.error, "error");
        }
      });
    }
  };

  const handleDelete = (evt) => {
    if (doDelete) {
      doDelete().then((result) => {
        if (result.success) {
          close({ dataChanged: true });
          snackbarContext.showNotification("delete", "success");
        }
      }).catch((result) => {
        snackbarContext.showNotification(result.error, "error");
      });
    }
  };

  const handleActivate = (evt) => {
    userService
      .activateUser(recordId)
      .then((res) => {
        if (res.success) {
          if (doRefresh) {
            doRefresh();
          }
          snackbarContext.showNotification("notifications.activate", "success");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDeactivate = (evt) => {
    userService
      .deactivateUser(recordId)
      .then((res) => {
        if (res.success) {
          close({ dataChanged: true });
          snackbarContext.showNotification("notifications.deactivate", "success");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const close = (result) => {
    if (result.dataChanged || dataChanged) {
      onClose({ dataChanged: true });
    }
  };

  const editFields = ["username", "first_name", "last_name", "email", "business_subject_id", "tel", "remarks"];
  const insertFields = [
    "username",
    "password",
    "password_confirm",
    "first_name",
    "last_name",
    "email",
    "business_subject_id",
    "tel",
    "remarks"
  ];

  const fieldPermissions = fields.find((x) => x.source === fPermissions);
  fieldPermissions.readonly = true;

  if (record && record[fUserRole]) {
    if (record[fUserRole] === USERROLE_CUSTOM || record[fUserRole].value === USERROLE_CUSTOM) {
      fieldPermissions.readonly = false;
    } else {
      fieldPermissions.readonly = true;
    }
  }

  const isActive = record && record.active ? true : false;

  const currentMode = isActive ? mode : "view";

  const commonProps = {
    recordId: recordId,
    record: record,
    validation: validation,
    onFieldChange: handleFieldChange,
    fields: fields,
    mode: currentMode,
    subModels: subModels,
    columns: 1
  };

  return (
    <Card>
      <CardHeading>
        <Toolbar variant="dense" disableGutters={true}>
          <FormAvatar icon={<KorisnikIcon />} />
          <FormHeading header={t("titles.user")} subheaderContent={<FormHeadingID id={recordId} />} dataChanged={dataChanged} />
          <FormFillContent />
          {currentMode === "update" || currentMode === "view" ? (
            <CardToolbarMenuButton>
              {record.active ? (
                <CardToolbarMenuItemDeactivate onClick={handleDeactivate} />
              ) : (
                <CardToolbarMenuItemActivate onClick={handleActivate} />
              )}
              <CardToolbarMenuItemDelete onClick={handleDelete} />
            </CardToolbarMenuButton>
          ) : null}
          <CardToolbarButtonBack onClick={handleClickBack} />
        </Toolbar>
      </CardHeading>
      <CardContent>
        <Box m={2}>
          <GridContainer>
            <GridItem xs={6} sm={6}>
              <FormContent fieldNames={currentMode === "insert" ? insertFields : editFields} {...commonProps} />
            </GridItem>
            <GridItem xs={6} sm={6}>
              <FormContent fieldNames={["user_role"]} {...commonProps} />
              <FormContent fieldNames={["user_permissions"]} {...commonProps} />
            </GridItem>
          </GridContainer>
        </Box>
      </CardContent>
      <CardActions>
        <FormFillContent />
        <CardActionButtonClose variant="outlined" onClick={handleClickBack} />
        {currentMode === "insert" ? <CardActionButtonAdd variant="contained" onClick={handleInsert} /> : null}
        {currentMode === "update" && record.active ? (
          <CardActionButtonSave variant="contained" onClick={handleUpdate} />
        ) : null}
      </CardActions>
    </Card>
  );
}

export default withFormController(UserForm);
