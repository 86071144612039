import React from 'react';
import { useTranslation } from "react-i18next";

//Material-UI
import SendIcon from '@material-ui/icons/Send';

//Custom Components
import DialogActionButton from "UI/Dialog/DialogActionButton";

function DialogActionButtonSend(props) {
  const { t } = useTranslation();

  return (
      <DialogActionButton startIcon={<SendIcon />} {...props} aria-label="send" aria-controls="send">
        {t("buttons.send")}
      </DialogActionButton>
  );
}

export default DialogActionButtonSend;
