import React from "react";
import { useTranslation } from "react-i18next";

//Materual-UI Icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

//Custom Components
import CardActionButton from "UI/Card/CardActionButton";

function CardActionButtonActivate(props) {
  const { t } = useTranslation();

  return (
    <CardActionButton startIcon={<CheckCircleIcon />} {...props} aria-label="Activate" aria-controls="Activate">
      {t("buttons.activate")}
    </CardActionButton>
  );
}

export default CardActionButtonActivate;
