import { coreFields } from "./helpers/coreFields";

export default {
  title: "Evaluacija Prijevoznika",
  exportFileName: "Evaluacija prijevoznika",
  source: "evaluacija",
  apiPath: "evaluacije/",
  forms: {
    default: {
      fields: [
        "ocjena_ukupno",
        "vozilo_cistoca",
        "osoblje_urednost",
        "vozilo_ispravnost",
        "vozilo_udobnost",
        "dogovor_komunikacija",
        "vrijednost_za_novac",
        "osoblje_priprema",
        "osoblje_komunikacija",
        "brzina_reakcije",
        "vozilo_oprema"
      ]
    }
  },
  listViews: {
    default: {
      fields: ["id", "created_by", "created_on", "napomena"]
    }
  },
  fields: [
    coreFields.id,
    coreFields.created_by,
    coreFields.created_on,
    {
      title: "napomena",
      source: "napomena",
      ttoken: "evaluacija.napomena",
      type: "text"
    },
    {
      title: "total",
      source: "ocjena_ukupno",
      ttoken: "evaluacija.total",
      type: "rating",
      max: 10,
      precision: 0.5,
      validation: {
        required: true
      }
    },
    {
      title: "vozilo_cistoca",
      source: "vozilo_cistoca",
      ttoken: "evaluacija.vozilo_cistoca",
      type: "rating",
      max: 10,
      validation: {
        required: true
      }
    },
    {
      title: "vozilo_oprema",
      source: "vozilo_oprema",
      ttoken: "evaluacija.vozilo_oprema",
      type: "rating",
      max: 10,
      validation: {
        required: true
      }
    },
    {
      title: "vozilo_udobnost",
      source: "vozilo_udobnost",
      ttoken: "evaluacija.vozilo_udobnost",
      type: "rating",
      max: 10,
      validation: {
        required: true
      }
    },
    {
      title: "vozilo_ispravnost",
      source: "vozilo_ispravnost",
      ttoken: "evaluacija.vozilo_ispravnost",
      type: "rating",
      max: 10,
      validation: {
        required: true
      }
    },
    {
      title: "osoblje_urednost",
      source: "osoblje_urednost",
      ttoken: "evaluacija.d_osoblje_urednost",
      type: "rating",
      max: 10,
      validation: {
        required: true
      }
    },
    {
      title: "osoblje_priprema",
      source: "osoblje_priprema",
      ttoken: "evaluacija.d_osoblje_priprema",
      type: "rating",
      max: 10,
      validation: {
        required: true
      }
    },
    {
      title: "dogovor_komunikacija",
      source: "dogovor_komunikacija",
      ttoken: "evaluacija.dogovor_komunikacija",
      type: "rating",
      max: 10,
      validation: {
        required: true
      }
    },
    {
      title: "osoblje_komunikacija",
      source: "osoblje_komunikacija",
      ttoken: "evaluacija.d_osoblje_komunijacija",
      type: "rating",
      max: 10,
      validation: {
        required: true
      }
    },
    {
      title: "brzina_reakcije",
      source: "brzina_reakcije",
      ttoken: "evaluacija.brzina_reakcije",
      type: "rating",
      max: 10,
      validation: {
        required: true
      }
    },
    {
      title: "vrijednost_za_novac",
      source: "vrijednost_za_novac",
      ttoken: "evaluacija.vrijednost_za_novac",
      type: "rating",
      max: 10,
      validation: {
        required: true
      }
    }
  ]
};
